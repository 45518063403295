import React from 'react';
import { compileAvatarUrl } from '../../utils/avatar.utils';
import { UploadedMediaInterface } from '../../state/media/media.types';

interface Props {
  media: UploadedMediaInterface[];
}

const MediaSearchResults: React.FC<Props> = (props: Props) => {
  const { media } = props;
  return (
    <div className="searchResults--block">
      <h5 className="heading__sm heading__sm-black font-semibold">Photos & Renderings</h5>
      <div className="mt-4">
        <div className="photos--search block-flex">
          <div className="block--search-row photos--search-row block--scroll">
            {media?.map((image, index) => (
              <div className="block--search-col" key={index}>
                <div className="photos--box">
                  <img src={compileAvatarUrl(image.url)} alt={image.name} className="img--cover" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaSearchResults;
