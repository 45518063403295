import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { useMe } from '../../../state/me/me.hook';
import { User } from '../../../state/listing/listing.types';
import Loader from '../../../components/common/loader';

import TourWrapper from '../../../components/subdomain/tour/tourWrapper';
import { useGetTour } from '../../../state/subdomain/tour/tour.hook';
import { buildRoomUrl, changeTourInfo } from '../../../state/subdomain/tour/tour.action';
import { NODE_ENV, USER_ROLES } from '../../../common/constants';
import { AppStateInterface } from '../../../state';
import { showNotification } from '../../../utils/misc.utils';
import {
  errorCodeExtractor,
  errorResponseExtractor,
  errorExtractor,
} from '../../../utils/error.utils';
import BrokerTourExpired from '../../../components/subdomain/tour/brokerTourExpired';
import ParticipantTourExpired from '../../../components/subdomain/tour/participantTourExpired';
import env from '../../../utils/env.utils';
import { FullStoryAPI } from 'react-fullstory';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { IntercomAPI } from 'react-intercom';
import { compact } from 'lodash';

const Tour: React.FC = () => {
  const { tourId } = useParams();
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );

  const { loading: meLoading, data: me } = useMe();
  const { tourRes, getTour } = useGetTour();

  useEffect(() => {
    if (tourId) getTour(tourId);
  }, [tourId]);

  useEffect(() => {
    if (me && env.nodeEnv === NODE_ENV.PRODUCTION) {
      IntercomAPI('update', {
        name: `${me.firstName} ${me.lastName}`,
        email: me.email,
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_id: me._id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_at: me.createdAt,
        company: {
          id: me?.company?._id || 'Unknown',
          name: me?.company?.name || 'Unknown',
        },
      });
      FullStoryAPI('identify', me._id, {
        displayName: `${me.firstName} ${me.lastName}`,
        email: me.email,
        uid: me._id,
        companyName: me?.company?.name || 'Unknown',
        companyId: me?.company?._id || 'Unknown',
        subdomain: me?.company?.domain || 'Unknown',
      });
    }
  }, [me]);
  useEffect(() => {
    if (!tourRes.loading && tourRes.hasData && !tourRes.error && tourRes.data) {
      const data = tourRes.data;
      const listings = compact(data.listings);
      data.listings = listings;
      changeTourInfo(data);
    }
  }, [tourRes]);

  const isListingBrokerOrAdmin = (): boolean => {
    if (!me) return false;
    if (me.role === USER_ROLES.ADMIN && company?._id === me?.company?._id) return true;

    const brokers = tourRes.data.additionalUsers;
    if (!brokers) return false;
    return brokers.find((broker: User) => broker._id === me._id);
  };

  const roomUrl = buildRoomUrl(tourRes.data);

  if (tourRes.error && !tourRes.loading) {
    if (errorCodeExtractor(tourRes.error) === 404) {
      const error = errorResponseExtractor(tourRes.error);
      if (me && me?.company?._id === company?._id && !meLoading) {
        return <BrokerTourExpired listingId={error?.data?.listing?._id} />;
      } else if (!meLoading) {
        return (
          <ParticipantTourExpired
            title={`${error?.data?.listing?.building?.location?.split(',')[0]} - ${
              error?.data?.listing?.suiteNo
            }`}
            brokers={error?.data?.listing?.users}
          />
        );
      } else {
        return <Loader />;
      }
    } else {
      showNotification('error', 'Error', errorExtractor(tourRes.error));
      return <Redirect to="/"></Redirect>;
    }
  } else if (!roomUrl || meLoading || !tourRes.data) {
    return <Loader />;

    // check if we should render joiner (and which style)
    // check if tour ended
    // check which tour to show (as host or tenant rn )
  } else {
    return <TourWrapper roomUrl={roomUrl} isBroker={isListingBrokerOrAdmin()} me={me} />;
  }
};

export default Tour;

// initiate call
