import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import { TourBuildingInterface } from '../../../../../state/tour/tour.types';

const ListingAmenities: React.FC = () => {
  const listing = useSelector((state: AppStateInterface) => state.tour.tourListing);
  const [building, setBuilding] = useState({} as TourBuildingInterface);
  useEffect(() => {
    if (listing) {
      const listingData = listing.listing;
      if (listingData.building) setBuilding(listingData.building);
    }
  }, [listing]);

  return (
    <div className="liveTour--building-main liveTour--amenities-main">
      <div className="row row--space-5 justify-content-center justify-content-lg-start">
        {building?.buildingAmenities &&
          building.buildingAmenities.map(amenityName => (
            <div className="col col-6 col-md-4 col-xl-3" key={amenityName}>
              <div className="building--widget amenities--widget specs--widget-alt">
                <h6 className="bw__heading">{amenityName}</h6>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ListingAmenities;
