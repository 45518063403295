import { GetStateInterface } from '../../types/GetStateInterface';

export interface AmenityMatterPortLink {
  name?: string;
  url: string;
}
export interface AmenityMatterPortLinkWithError extends AmenityMatterPortLink {
  error: boolean;
}

export interface BuildingDataInterface {
  location?: string;
  latitude?: string;
  longitude?: string;
  fullLocation?: any;
  subMarket?: string;
  imageUrl?: string;
  yearBuilt?: number;
  yearRenovated?: number;
  floorsCount?: number;
  buildingSize?: number;
  averageFloor?: number;
  parkingRatio?: string;
  columnSpacingHeight?: number;
  columnSpacingWidth?: number;
  slabToSlabHeight?: string;
  passengerElevators?: string;
  freightElevators?: string;
  telecomProviders?: string;
  buildingHours?: string[];
  energyStarCertification?: string;
  leedCertification?: string;
  buildingAmenities?: string[];
  buildingAmenitiesMatterportLinks?: AmenityMatterPortLinkWithError[];
  website?: string;
  other?: string;
}

export interface BuildingDataPayloadInterface {
  location: string;
  latitude: string;
  longitude: string;
  fullLocation?: any;
  subMarket: string;
  imageUrl: string;
  yearBuilt: number;
  yearRenovated: number;
  floorsCount: number;
  buildingSize: number;
  averageFloor: number;
  parkingRatio?: string;
  columnSpacingHeight?: number;
  columnSpacingWidth?: number;
  slabToSlabHeight?: string;
  passengerElevators: string;
  freightElevators?: string;
  telecomProviders?: string;
  buildingHours: string[];
  energyStarCertification?: string;
  leedCertification?: string;
  buildingAmenities?: string[];
  buildingAmenitiesMatterportLinks?: AmenityMatterPortLink[];
  company: string;
  users: string[];
  website: string;
  other: string;
}

export interface UpdateBuildingDataPayloadInterface {
  location?: string;
  latitude?: number;
  longitude?: number;
  fullLocation?: any;
  subMarket?: string;
  imageUrl?: string;
  yearBuilt?: number;
  yearRenovated?: number;
  floorsCount?: number;
  buildingSize?: number;
  averageFloor?: number;
  parkingRatio?: string;
  columnSpacingHeight?: number;
  columnSpacingWidth?: number;
  slabToSlabHeight?: string;
  passengerElevators?: string;
  freightElevators?: string;
  telecomProviders?: string;
  buildingHours?: string[];
  energyStarCertification?: string;
  leedCertification?: string;
  buildingAmenities?: string[];
  buildingAmenitiesMatterportLinks?: AmenityMatterPortLink[];
  website?: string;
  other?: string;
}

export interface BuildingDataErrorInterface {
  location?: string;
  latitude?: string;
  longitude?: string;
  fullLocation?: string;
  subMarket?: string;
  imageUrl?: string;
  yearBuilt?: string;
  yearRenovated?: string;
  floorsCount?: string;
  buildingSize?: string;
  averageFloor?: string;
  parkingRatio?: string;
  columnSpacing?: string;
  slabToSlabHeight?: string;
  passengerElevators?: string;
  freightElevators?: string;
  telecomProviders?: string;
  buildingHours?: string;
  energyStarCertification?: string;
  leedCertification?: string;
  buildingAmenities?: string;
  website?: string;
  other?: string;
}

export interface BuildingDataRequiredFieldsInterface {
  location: boolean;
  latitude: boolean;
  longitude: boolean;
  fullLocation?: boolean;
  subMarket: boolean;
  imageUrl: boolean;
  yearBuilt: boolean;
  yearRenovated: boolean;
  floorsCount: boolean;
  buildingSize: boolean;
  averageFloor: boolean;
  parkingRatio?: boolean;
  columnSpacingHeight?: boolean;
  columnSpacingWidth?: boolean;
  slabToSlabHeight?: boolean;
  passengerElevators: boolean;
  freightElevators?: boolean;
  telecomProviders?: boolean;
  buildingHours: boolean;
  energyStarCertification?: boolean;
  leedCertification?: boolean;
  buildingAmenities?: boolean;
  website?: boolean;
  other?: boolean;
}

export interface GetBuildingsListResponseInterface {
  _id: string;
  location: string;
}

export interface GetAllBuildingsResponseInterface {
  data: GetAllBuildingsDataInterface[];
  hasMore: boolean;
  skip: number;
}

export interface GetAllBuildingsSingleBuildingInterface {
  _id: string;
  imageUrl: string;
  location: string;
  fullLocation: any;
  updatedAt: string;
  totalListings: number;
  totalListingsDetails: {
    totalSquareFoot: number;
  };
}

export interface GetAllBuildingsDataInterface {
  _id: string;
  market: string;
  subMarket: string;
  buildings: Array<GetAllBuildingsSingleBuildingInterface>;
}

export interface BuildingStateInterface {
  allBuildings: GetStateInterface<GetAllBuildingsResponseInterface>;
}

export enum BuildingActions {
  GET_ALL_BUILDINGS = 'GET_ALL_BUILDING',
  GET_ALL_BUILDINGS_CLEAR = 'GET_ALL_BUILDING_CLEAR',
  GET_ALL_BUILDINGS_INIT = 'GET_ALL_BUILDING_INIT',
  GET_ALL_BUILDINGS_SUCCESSFUL = 'GET_ALL_BUILDING_SUCCESSFUL',
  GET_ALL_BUILDINGS_FAILURE = 'GET_ALL_BUILDING_FAILURE',

  DELETE_BUILDING = 'DELETE_BUILDING',
}
