import React, { useEffect, useRef, useState } from 'react';
import { Dropdown, Form, Button, Accordion, Card } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { useGetBuildingsList } from '../../../../state/building/building.hook';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { useGetSubMarketsList } from '../../../../state/submarket/submarket.hook';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import env from '../../../../utils/env.utils';
import {
  BuildingDataErrorInterface,
  BuildingDataInterface,
} from '../../../../state/building/building.types';
import SmallLoader from '../../../common/small.loader';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import { useUploadMedia } from '../../../../state/media/media.hook';
import { errorExtractor } from '../../../../utils/error.utils';
import { GetStateInterface } from '../../../../types/GetStateInterface';
import HamBurger from '../../../leftSidebar/hamburger';

interface Props {
  onContinue: (e: any) => void;
  setDataField: (field: string, value: any) => void;
  setBuildingData: (callback: (prevState: BuildingDataInterface) => BuildingDataInterface) => void;
  data: BuildingDataInterface;
  selectedBuilding: string;
  setSelectedBuilding: (value: string) => void;
  errors: BuildingDataErrorInterface;
  setError: (field: string, value: string) => void;
  locationFilled: boolean;
  setLocationFilled: (value: boolean) => void;
  setStep: (value: 1 | 2) => void;
  buildingDetailsResponse: GetStateInterface;
  onSave: (e: any) => void;
  updateBuildingResponse: GetStateInterface;
}

const BuildingDetailsForm: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { buildingId } = useParams();
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const [openedMarket, setOpenedMarket] = useState<number | null>(null);
  const { res: buildingsListResponse, getBuildingsList } = useGetBuildingsList();
  const { res: subMarketsListResponse, getSubMarketsList } = useGetSubMarketsList();
  const { res: imageUploadResponse, uploadSingleMedia } = useUploadMedia();

  const handleClickOutsideDropdown = (event: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (!imageUploadResponse.loading) {
      if (imageUploadResponse.data) {
        props.setDataField('imageUrl', imageUploadResponse.data.url);
        props.setError('imageUrl', '');
      }
      if (imageUploadResponse.error) {
        props.setError('imageUrl', errorExtractor(imageUploadResponse.error));
      }
    }
  }, [imageUploadResponse]);

  const onSelectAddress = async (selectedPlace: any) => {
    const geoCodeAddresses = await geocodeByPlaceId(selectedPlace.place_id);
    if (geoCodeAddresses && geoCodeAddresses.length) {
      const geoCodeAddress = JSON.parse(JSON.stringify(geoCodeAddresses[0]));
      props.setBuildingData((prevState: BuildingDataInterface) => ({
        ...prevState,
        location: selectedPlace?.description,
        longitude: geoCodeAddress?.geometry?.location?.lng?.toString(),
        latitude: geoCodeAddress?.geometry?.location?.lat?.toString(),
        fullLocation: {
          selectedPlace,
          geoCodeAddress,
        },
      }));
      props.setError('location', '');
    } else {
      props.setError(
        'location',
        'This address does not exist. Please select one from suggestions.',
      );
    }
  };

  useEffect(() => {
    if (me?.company?._id) {
      if (!buildingId) {
        getBuildingsList(me.company._id);
      }
      getSubMarketsList(me.company._id);
    }
  }, [me]);

  const uploadImage = (e: any) => {
    const files = e.target.files;
    if (files && files.length) {
      uploadSingleMedia({ name: '', data: files[0] });
    }
  };

  const onDropdownSelect = (selectedValue: string) => {
    props.setDataField('subMarket', selectedValue);
    props.setError('subMarket', '');
    setShowDropdown(false);
  };

  const findSubMarketName = (id: string): string => {
    let allSubMarkets: any[] = [];
    subMarketsListResponse.data?.forEach(
      (market: any) => (allSubMarkets = allSubMarkets.concat(market.docs)),
    );
    return allSubMarkets.find((subMarket: any) => subMarket._id === id)?.name;
  };

  const findBuildingName = (id: string): string | undefined => {
    return buildingsListResponse.data?.find((building: any) => building._id === id)?.location;
  };

  return (
    <div className="step--content step--content-1">
      <div className="dashboard--opt">
        <a
          onClick={() => {
            if (buildingId) {
              history.push(`/building/${buildingId}/listings`);
            } else if (history.action === 'PUSH') {
              history.goBack();
            } else {
              history.push('/dashboard');
            }
          }}
          className="back__btn"
        >
          <span className="back__btn-icon">
            <i className="icon-chevron-left"></i>
          </span>
          Back
        </a>
        <HamBurger className="menu__toggle" />
      </div>
      {buildingId && (
        <div className="dashboard--head editListing--head">
          <h3 className="dashboard__heading d-flex align-items-center">Edit Building</h3>
          <div className="editListing--head-btn">
            <a
              onClick={() => history.push(`/building/${buildingId}/listings`)}
              className="outline__button underline__button sm__button"
            >
              Cancel
            </a>
            <button
              className="admin__button underline__button sm__button"
              disabled={
                props.buildingDetailsResponse.loading || props.updateBuildingResponse.loading
              }
              onClick={props.onSave}
            >
              {props.updateBuildingResponse.loading ? 'Saving' : 'Save'}
              {props.updateBuildingResponse.loading && <SmallLoader />}
            </button>
          </div>
        </div>
      )}
      {!buildingId && (
        <div className="dashboard--head">
          <h3 className="dashboard__heading d-flex align-items-center">Add Building</h3>
          <div className="step--head">
            <span className="step__text">Step 1/4:</span>
            <span className="step__info">Building Details</span>
          </div>
        </div>
      )}
      {!buildingId && (
        <p className="dashboard__paragraph mb-28">Create a new building for your listings</p>
      )}
      <Form>
        <div className="step--upload-block">
          {!props.data.imageUrl && !imageUploadResponse.loading && (
            <label className="custom__file">
              <input onChange={uploadImage} type="file" />
              Upload Building Photo *
              <span className="custom__file-icon">
                <i className="icon-upload"></i>
              </span>
              {props.errors.imageUrl && <span className="error__msg">{props.errors.imageUrl}</span>}
            </label>
          )}
          {imageUploadResponse.loading && (
            <span>
              Uploading <SmallLoader />
            </span>
          )}
          {props.data.imageUrl && (
            <div className="file--uploaded">
              <div className="uploaded--img-block d-flex align-items-center flex-wrap">
                <div className="uploaded--img">
                  <img src={compileAvatarUrl(props.data.imageUrl)} alt="Img" />
                </div>
                <a onClick={() => props.setDataField('imageUrl', '')} className="uploaded-trash">
                  <i className="icon-trash"></i>
                </a>
              </div>
            </div>
          )}
        </div>
        <div className="row row--space-12">
          <div className="col-12 col-sm-6">
            {buildingId && <Form.Label>Address*</Form.Label>}
            <Form.Group className="forms--group mb-66 spinner--group">
              <GooglePlacesAutocomplete
                apiKey={env.googleApiKey}
                loader={<SmallLoader />}
                initialValue={props.data.location}
                inputClassName={props.errors.location ? 'error' : ''}
                placeholder="Address *"
                onSelect={onSelectAddress}
                withSessionToken
                suggestionsClassNames={{
                  container: 'google--suggestions--container',
                  suggestion: 'google--places--suggestions',
                }}
                renderInput={(inputProps: any) => {
                  return (
                    <Form.Control
                      {...inputProps}
                      className={props.errors.location ? 'error' : ''}
                      onChange={(e: any) => {
                        props.setError('location', '');
                        if (e.target.value) {
                          props.setLocationFilled(true);
                        } else {
                          props.setError('location', 'This field is required');
                          props.setLocationFilled(false);
                        }
                        props.setDataField('location', '');
                        inputProps.onChange(e);
                      }}
                      onBlur={(e: any) => {
                        if (
                          e.target.value &&
                          (!props.data.location || !props.data.longitude || !props.data.latitude)
                        ) {
                          props.setError(
                            'location',
                            'This address does not exist. Please select one from suggestions.',
                          );
                          props.setLocationFilled(true);
                        }
                      }}
                    />
                  );
                }}
              />
              {props.errors.location && <span className="error__msg">{props.errors.location}</span>}
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6 building--dropdown">
            {buildingId && <Form.Label>Select Submarket*</Form.Label>}
            <Dropdown
              ref={dropdownRef}
              show={showDropdown}
              className="listing--dropdown listing--dropdown-pad mb-66 spinner--group"
            >
              <Dropdown.Toggle
                onClick={() => setShowDropdown(prevState => !prevState)}
                id="dropdown-basic"
                className={props.errors.subMarket ? 'error' : ''}
              >
                {props.data.subMarket
                  ? findSubMarketName(props.data.subMarket)
                  : 'Select Submarket *'}
                <span className="select__caret-down">
                  <i className="icon-caret-down"></i>
                </span>
                {subMarketsListResponse.loading && <SmallLoader />}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {subMarketsListResponse.data && subMarketsListResponse.data.length > 0 && (
                  <Accordion className="listing--accordian">
                    {subMarketsListResponse.data.map((market: any, index: number) => {
                      return (
                        <Card className="listing--card" key={index}>
                          <Card.Header>
                            <Accordion.Toggle
                              as={Button}
                              variant="link"
                              eventKey={index.toString()}
                              onClick={() =>
                                setOpenedMarket(prevState =>
                                  prevState || prevState === 0 ? null : index,
                                )
                              }
                            >
                              {`All ${market._id} (${market.docs.length})`}
                              <span className="accordian__icon">
                                {openedMarket === index ? (
                                  <i className="icon-minus"></i>
                                ) : (
                                  <i className="icon-plus"></i>
                                )}
                              </span>
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey={index.toString()}>
                            <Card.Body>
                              {market.docs?.map((subMarket: any, index: number) => {
                                return (
                                  <Dropdown.Item
                                    key={index}
                                    eventKey={subMarket._id}
                                    onSelect={onDropdownSelect}
                                    active={subMarket._id === props.data.subMarket}
                                  >
                                    {subMarket.name}
                                  </Dropdown.Item>
                                );
                              })}
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      );
                    })}
                  </Accordion>
                )}
              </Dropdown.Menu>
              {props.errors.subMarket && (
                <span className="error__msg">{props.errors.subMarket}</span>
              )}
            </Dropdown>
          </div>
        </div>
        {!buildingId && (
          <React.Fragment>
            <p className="dashboard__paragraph mb-48">or select one from the dropdown</p>
            <div className="row row--space-12">
              <div className="col-12 col-sm-6">
                <Dropdown className="listing--dropdown listing--dropdown-pad mb-66 spinner--group">
                  <Dropdown.Toggle id="dropdown-basic">
                    {props.selectedBuilding
                      ? findBuildingName(props.selectedBuilding)
                      : 'Select one'}
                    <span className="select__caret-down">
                      <i className="icon-caret-down"></i>
                    </span>
                    {buildingsListResponse.loading && <SmallLoader />}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onSelect={() => props.setSelectedBuilding('')}
                      active={!props.selectedBuilding}
                    >
                      Select one
                    </Dropdown.Item>
                    {buildingsListResponse.data &&
                      Array.isArray(buildingsListResponse.data) &&
                      buildingsListResponse.data.map((building: any, index: number) => {
                        return (
                          <Dropdown.Item
                            eventKey={building._id}
                            key={index}
                            onSelect={(eventKey: string) => props.setSelectedBuilding(eventKey)}
                            active={building._id === props.selectedBuilding}
                          >
                            {building.location}
                          </Dropdown.Item>
                        );
                      })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </React.Fragment>
        )}
        {!buildingId && (
          <div className="stepBtn-canvas stepBtn-canvas-1">
            <Button
              type="submit"
              className="admin__button underline__button step__button"
              disabled={buildingsListResponse.loading || subMarketsListResponse.loading}
              onClick={props.onContinue}
            >
              Continue
              <i className="icon-right-arrow"></i>
            </Button>
          </div>
        )}
      </Form>
      {buildingId && (
        <div className="prev--next-option">
          <a className="pages__option prev__option"></a>
          <a className="pages__option next__option" onClick={() => props.setStep(2)}>
            <span className="pages__option-text">Next</span>
            <span className="pages__option-icon">
              <i className="icon-arrow-right"></i>
            </span>
          </a>
        </div>
      )}
    </div>
  );
};

export default BuildingDetailsForm;
