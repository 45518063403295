import React, { useState, useEffect } from 'react';
import { UploadedMediaInterface } from '../../../state/media/media.types';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import { DOWNLOAD_GAP } from '../../../common/constants';
import { GetListingObject } from '../../../state/listing/listing.types';

interface Props {
  listing: GetListingObject;
}
const TourDocs: React.FC<Props> = props => {
  const { listing } = props;
  const [pdfs, setPdfs] = useState([] as UploadedMediaInterface[]);
  useEffect(() => {
    if (listing.pdfs?.length) setPdfs(listing.pdfs);
  }, [props]);

  const downloadPdfs = () => {
    let i = 0;
    const interval = setInterval(() => {
      document.getElementById(pdfs[i].name)?.click();
      if (++i === pdfs.length) clearInterval(interval);
    }, DOWNLOAD_GAP * 1000);
  };

  const listingTitle = (): string => {
    const streetAddress = listing.building?.location?.split(',');
    return `${streetAddress} - ${listing.suiteNo}`;
  };

  return (
    <div className="col-12">
      <button className="admin__button w-100 mb-3" onClick={downloadPdfs}>
        Download {listingTitle()} PDF
      </button>
      {pdfs.map(file => (
        <a id={file.name} href={compileAvatarUrl(file.url)} download key={file.name} hidden />
      ))}
    </div>
  );
};

export default TourDocs;
