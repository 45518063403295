import React, { useEffect, useRef, useState } from 'react';
import SubDomainHamBurger from './hamburger';
import { Button, Accordion, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import {
  MenuInterface,
  SubMenuInterface,
  SubSubMenuInterface,
  SubSubSubMenuInterface,
} from '../../../state/subdomain/company/company.types';

export const SubDomainRightSidebar: React.FC = () => {
  const [show, setShow] = useState(false);
  const [firstLevelId, setFirstLevelId] = useState('');
  const [secondLevelId, setSecondLevelId] = useState('');
  const [thirdLevelId, setThirdLevelId] = useState('');
  const sidebarRef = useRef<any>(null);
  const fullMenu = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data?.settings?.menu,
  );

  const handleClickOutsideSidebar = (event: any) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideSidebar, false);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSidebar, false);
    };
  }, [sidebarRef]);

  return (
    <React.Fragment>
      {fullMenu && fullMenu?.length > 0 && <SubDomainHamBurger onClick={() => setShow(true)} />}
      <div className={`drawer--right ${show ? 'drawer--right-show' : ''}`} ref={sidebarRef}>
        <Button className="drawer__cross" onClick={() => setShow(false)}>
          <i className="icon-cross"></i>
        </Button>
        {fullMenu && fullMenu?.length > 0 && (
          <div className="drawer--canvas">
            <Accordion id="accordian-1" className="accordian--first" activeKey={firstLevelId}>
              {fullMenu?.map((menu: MenuInterface) => {
                const menuId = menu?._id || 'menu';
                if (menu?.subMenu && menu?.subMenu?.length > 0) {
                  return (
                    <React.Fragment key={menuId}>
                      <Card
                        className={`drawer--card card--icon ${
                          firstLevelId === menuId ? 'card--active-first' : ''
                        }`}
                      >
                        <Card.Header>
                          <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey={menuId}
                            onClick={() => {
                              setFirstLevelId(prevState => (prevState === menuId ? '' : menuId));
                              setSecondLevelId('');
                              setThirdLevelId('');
                            }}
                          >
                            {menu?.name}
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={menuId}>
                          <Card.Body>
                            <div className="drawer--inner">
                              <Accordion
                                id="accrodian-2"
                                className="accordian--second"
                                activeKey={secondLevelId}
                              >
                                {menu?.subMenu?.map((subMenu: SubMenuInterface) => {
                                  const subMenuId = subMenu?._id || 'subMenu';
                                  if (subMenu?.subMenu && subMenu?.subMenu?.length > 0) {
                                    return (
                                      <React.Fragment key={subMenuId}>
                                        <Card
                                          className={`drawer--card card--icon ${
                                            secondLevelId === subMenuId ? 'card--active-second' : ''
                                          }`}
                                        >
                                          <Card.Header>
                                            <Accordion.Toggle
                                              as={Button}
                                              variant="link"
                                              eventKey={subMenuId}
                                              onClick={() => {
                                                setSecondLevelId(prevState =>
                                                  prevState === subMenuId ? '' : subMenuId,
                                                );
                                                setThirdLevelId('');
                                              }}
                                            >
                                              {subMenu?.name}
                                            </Accordion.Toggle>
                                          </Card.Header>
                                          <Accordion.Collapse eventKey={subMenuId}>
                                            <Card.Body>
                                              <div className="drawer--inner">
                                                <Accordion
                                                  id="accrodian-3"
                                                  className="accordian--third"
                                                  activeKey={thirdLevelId}
                                                >
                                                  {subMenu?.subMenu?.map(
                                                    (subSubMenu: SubSubMenuInterface) => {
                                                      const subSubMenuId =
                                                        subSubMenu?._id || 'subSubMenu';
                                                      if (
                                                        subSubMenu?.subMenu &&
                                                        subSubMenu?.subMenu?.length > 0
                                                      ) {
                                                        return (
                                                          <React.Fragment key={subSubMenuId}>
                                                            <Card
                                                              className={`drawer--card card--icon ${
                                                                thirdLevelId === subSubMenuId
                                                                  ? 'card--active-second'
                                                                  : ''
                                                              }`}
                                                            >
                                                              <Card.Header>
                                                                <Accordion.Toggle
                                                                  as={Button}
                                                                  variant="link"
                                                                  eventKey={subSubMenuId}
                                                                  onClick={() =>
                                                                    setThirdLevelId(prevState =>
                                                                      prevState === subSubMenuId
                                                                        ? ''
                                                                        : subSubMenuId,
                                                                    )
                                                                  }
                                                                >
                                                                  {subSubMenu?.name}
                                                                </Accordion.Toggle>
                                                              </Card.Header>
                                                              <Accordion.Collapse
                                                                eventKey={subSubMenuId}
                                                              >
                                                                <Card.Body>
                                                                  <div className="drawer--inner">
                                                                    <Accordion
                                                                      id="accrodian-4"
                                                                      className="accordian--fourth"
                                                                    >
                                                                      {subSubMenu?.subMenu?.map(
                                                                        (
                                                                          subSubSubMenu: SubSubSubMenuInterface,
                                                                        ) => {
                                                                          const subSubSubMenuId =
                                                                            subSubSubMenu._id ||
                                                                            'subSubSubMenuId';
                                                                          return (
                                                                            <React.Fragment
                                                                              key={subSubSubMenuId}
                                                                            >
                                                                              <Card className="drawer--card">
                                                                                <Card.Header>
                                                                                  <a
                                                                                    href={
                                                                                      subSubSubMenu?.url ||
                                                                                      '#'
                                                                                    }
                                                                                    className="btn btn-link"
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                  >
                                                                                    {
                                                                                      subSubSubMenu?.name
                                                                                    }
                                                                                  </a>
                                                                                </Card.Header>
                                                                              </Card>
                                                                            </React.Fragment>
                                                                          );
                                                                        },
                                                                      )}
                                                                    </Accordion>
                                                                  </div>
                                                                </Card.Body>
                                                              </Accordion.Collapse>
                                                            </Card>
                                                          </React.Fragment>
                                                        );
                                                      } else {
                                                        return (
                                                          <React.Fragment key={subSubMenuId}>
                                                            <Card className="drawer--card">
                                                              <Card.Header>
                                                                <a
                                                                  href={subSubMenu?.url || '#'}
                                                                  className="btn btn-link"
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                >
                                                                  {subSubMenu?.name}
                                                                </a>
                                                              </Card.Header>
                                                            </Card>
                                                          </React.Fragment>
                                                        );
                                                      }
                                                    },
                                                  )}
                                                </Accordion>
                                              </div>
                                            </Card.Body>
                                          </Accordion.Collapse>
                                        </Card>
                                      </React.Fragment>
                                    );
                                  } else {
                                    return (
                                      <React.Fragment key={subMenuId}>
                                        <Card className="drawer--card">
                                          <Card.Header>
                                            <a
                                              href={subMenu?.url || '#'}
                                              className="btn btn-link"
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {subMenu?.name}
                                            </a>
                                          </Card.Header>
                                        </Card>
                                      </React.Fragment>
                                    );
                                  }
                                })}
                              </Accordion>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={menuId}>
                      <Card className="drawer--card">
                        <Card.Header>
                          <a
                            href={menu?.url || '#'}
                            className="btn btn-link"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {menu?.name}
                          </a>
                        </Card.Header>
                      </Card>
                    </React.Fragment>
                  );
                }
              })}
            </Accordion>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SubDomainRightSidebar;
