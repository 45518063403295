import React, { useEffect } from 'react';
import TourListingFilters from './tourListingFilters';
import {
  ListingFilters,
  GetListingObject,
} from '../../../../state/subdomain/listing/listing.types';
import { useGetSubMarketsList } from '../../../../state/submarket/submarket.hook';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import TourSingleListing from './tourSingleListing';
import { changeTourListing } from '../../../../state/subdomain/tour/tour.action';
import Loader from '../../../common/loader';
import SmallLoader from '../../../common/small.loader';
import { ListingInfo } from '../../../../state/subdomain/tour/tour.types';

interface Props {
  handleClose: () => void;
  data: GetListingObject[];
  search: string;
  openSearch: () => void;
  handleSubmit: (text: string, filter?: ListingFilters) => void;
  resetScroll: boolean;
  loading: boolean;
  firstFetch: boolean;
  searched: boolean;
}

const TourListingDetails: React.FC<Props> = (props: Props) => {
  const {
    handleClose,
    data,
    openSearch,
    search,
    handleSubmit,
    resetScroll,
    loading,
    firstFetch,
    searched,
  } = props;
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );
  const tourInfo = useSelector((state: AppStateInterface) => state.subdomain.tour.tourInfo);

  const { res: subMarketsListResponse, getSubMarketsList } = useGetSubMarketsList();
  const findSubMarketName = (id: string): string => {
    let allSubMarkets: any[] = [];
    subMarketsListResponse.data?.forEach(
      (market: any) => (allSubMarkets = allSubMarkets.concat(market.docs)),
    );
    return allSubMarkets.find((subMarket: any) => subMarket._id === id)?.name;
  };
  const handleClick = (listing: GetListingObject) => {
    let data = tourInfo.listings.find(data => data.listing._id === listing._id);
    if (!data) {
      data = {
        _id: 'string',
        listing: listing,
        additionalMedia: [],
        additionalFloorPlans: [],
      } as ListingInfo;
    }
    changeTourListing(data);
    handleClose();
  };

  useEffect(() => {
    if (company?._id) getSubMarketsList(company._id);
  }, [company]);

  useEffect(() => {
    if (resetScroll) {
      const doc = document.getElementById('TourListingParent');
      if (doc) {
        if (doc.scrollTo) {
          doc.scrollTo(0, 0);
        } else {
          doc.scrollLeft = 0;
          doc.scrollTop = 0;
        }
      }
    }
  }, [resetScroll]);

  return (
    <div className="liveTour--listing">
      <div className={`container-fluid position-relative ${loading ? 'container--fixed' : ''}`}>
        {firstFetch && loading && <Loader className="tourListingLoading" />}
        <div className="ls--top">
          <div className="ls--head ls--left">
            <span className="ls__icon">
              <i className="icon-building"></i>
            </span>
            <h3 className="ls__heading">Listings</h3>
          </div>
          {data && data.length > 0 && (
            <TourListingFilters
              isSearched={searched}
              handleChange={filter => handleSubmit(search, filter)}
            />
          )}
          <a className="ls__cross" onClick={handleClose}>
            <i className="icon-cross"></i>
          </a>
        </div>
        <div className="ls--bottom">
          <div className={`ls--opt ${search.length ? 'ls--left' : ''}`}>
            <span className="ls__search-icon" onClick={openSearch}>
              <i className="icon-search"></i>
            </span>
            {search.length > 0 && <span className="ls__search-result">{search}</span>}
            {search.length > 0 && (
              <span className="search__cross" onClick={() => handleSubmit('')}>
                <i className="icon-cross-cirlce"> </i>
              </span>
            )}
          </div>
          <div className="ls--listings ls--right" id="TourListingParent">
            <div className="row ls--row">
              {data &&
                data?.map((listing, index) => (
                  <div className="col ls--col" key={index} onClick={() => handleClick(listing)}>
                    <TourSingleListing listing={listing} subMarket={findSubMarketName} />
                  </div>
                ))}
              {data && data?.length === 0 && (
                <div className="col ls--col">
                  <p>No Record Found</p>
                </div>
              )}
              {!firstFetch && loading && (
                <div className="loading--more">
                  <span>Loading More</span> <SmallLoader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TourListingDetails;
