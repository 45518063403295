import { getSingleTourAPI } from './tour.api';
import { TourActions, ListingInfo, AdditionalUser, GetTourInfo } from './tour.types';
import { ErrorTypes } from '../../types/error.types';
import fetchActions from '../../utils/state.utils';
import { _dataValid } from '../../utils/apiValidatior';
import axios from 'axios';
import { TOUR_TYPES } from '../../common/constants';
import { UploadedMediaInterface } from '../media/media.types';
import { compact } from 'lodash';

export const getSingleTour = async (roomId: string) => {
  const actionName = TourActions.GET_SINGLE_TOUR;

  fetchActions.callCT(actionName);
  fetchActions.init(actionName);

  try {
    const res = await getSingleTourAPI(roomId, fetchActions.setCT(actionName));
    if (_dataValid(res.data)) {
      const data = res.data;
      const listings = compact(data.listings);
      data.listings = listings;
      fetchActions.success(actionName, data);
    } else {
      fetchActions.failure(actionName, ErrorTypes.invalidResponse);
      throw ErrorTypes.invalidResponse;
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      fetchActions.failure(actionName, error);
    }
  }
};

export const changeTourListing = (listing: ListingInfo | null) => {
  fetchActions.dispatch({
    type: TourActions.CHANGE_TOUR_LISTING,
    payload: listing,
  });
};

export const removeTour = () => {
  fetchActions.dispatch({
    type: TourActions.DELETE_SINGLE_TOUR,
  });
};

export const changeTourTypeAction = (e: TOUR_TYPES) => {
  fetchActions.dispatch({
    type: TourActions.CHANGE_TOUR_TYPE,
    payload: e,
  });
};

export const changeTourListingImages = (
  images: UploadedMediaInterface[],
  listings: ListingInfo[],
) => {
  fetchActions.dispatch({
    type: TourActions.CHANGE_LISTING_IMAGES,
    payload: images,
    listings,
  });
};

export const changeTourListingPlans = (
  images: UploadedMediaInterface[],
  listings: ListingInfo[],
) => {
  fetchActions.dispatch({
    type: TourActions.CHANGE_LISTING_PLANS,
    payload: images,
    listings,
  });
};

export const changeTourUsersAction = (users: AdditionalUser[]) => {
  fetchActions.dispatch({
    type: TourActions.CHANGE_TOUR_USERS,
    payload: users,
  });
};

export const changeTourListingAction = (tourInfo: GetTourInfo) => {
  fetchActions.dispatch({
    type: TourActions.CHANGE_TOUR_LISTINGS,
    payload: tourInfo,
  });
};
