import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { TourForm } from '../../../../../state/subdomain/listing/listing.form';
import { errorMsg } from '../../listing.component';
import InputMask from 'react-input-mask';

const PlaceHolder = {
  [TourForm.firstName]: 'First Name*',
  [TourForm.lastName]: 'Last Name*',
  [TourForm.email]: 'Email*',
  [TourForm.phone]: 'Phone Number*',
  [TourForm.companyName]: 'Client’s Company Name*',
  [TourForm.questions]: 'Do you have any questions?',
  [TourForm.brokerCompany]: 'Brokerage Company Name',
  [TourForm.time]: 'time',
};
interface Props {
  reg: any;
  name: TourForm;
  full?: boolean;
  options?: boolean;
  err?: any;
  phone?: boolean;
}
const FormInput: React.FC<Props> = props => {
  const { name, reg, full, options, err, phone } = props;
  const [phoneText, setPhoneText] = useState('');

  return (
    <React.Fragment>
      {options ? (
        <div className="col col-12">
          <Form.Group className="forms--group forms--group-radius select--group w-100 mb-4">
            <Form.Control as="select" ref={reg} name={TourForm.time} defaultValue="">
              <option value="" disabled>
                Time of Day Preference*
              </option>
              <option>Morning</option>
              <option>Noon</option>
              <option>Afternoon</option>
              <option>Evening</option>
            </Form.Control>
            <span className="select__caret-down">
              <i className="icon-caret-down"></i>
            </span>
            {errorMsg(err)}
          </Form.Group>
        </div>
      ) : (
        <div className={`col col-12 ${!full ? 'col-sm-6' : ''}`}>
          <Form.Group className="forms--group forms--group-radius mb-4">
            {phone ? (
              <InputMask
                type="text"
                placeholder="Company Phone *"
                inputRef={reg}
                name={name}
                value={phoneText}
                className={err ? 'form-control error' : 'form-control'}
                onChange={(e: any) => setPhoneText(e.target.value)}
                mask="(999) 999-9999"
                maskChar="_"
              />
            ) : (
              <Form.Control
                as={full ? 'textarea' : 'input'}
                type="text"
                placeholder={PlaceHolder[name]}
                name={name}
                ref={reg}
                className={err ? 'form-control error' : 'form-control'}
              />
            )}
            {errorMsg(err)}
          </Form.Group>
        </div>
      )}
    </React.Fragment>
  );
};
export default FormInput;
