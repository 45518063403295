import envUtils from './env.utils';
export function getAvatar(seed?: string): string {
  if (!seed) {
    return `https://avatars.dicebear.com/v2/gridy/${Math.floor(Date.now() / 1000)}.svg`;
  }
  return `https://avatars.dicebear.com/v2/initials/${seed}.svg?options[background]=%23c2c0c3`;
}

export function compileAvatarUrl(avatar: string) {
  return envUtils.mediaBaseUrl + avatar;
}
