import React, { useState, useEffect } from 'react';

import GoogleMaps from '../../location';
import { GetListingObject } from '../../../state/listing/listing.types';

interface Props {
  listing: GetListingObject;
  className?: string;
}
interface Location {
  lat: number;
  lng: number;
}

const ListingTourLocation: React.FC<Props> = (props: Props) => {
  const { listing, className } = props;
  const [location, setLocation] = useState({} as Location);

  useEffect(() => {
    const e = listing.building;
    if (e.latitude && e.longitude)
      setLocation({ lat: parseFloat(e.latitude), lng: parseFloat(e.longitude) });
  }, [listing]);

  return (
    <div className={className} style={{ width: '100%', height: '100%' }}>
      <GoogleMaps
        location={{
          lat: location.lat,
          lng: location.lng,
        }}
        marker={[
          {
            lat: location.lat,
            lng: location.lng,
          },
        ]}
        selected={0}
      />
    </div>
  );
};

export default ListingTourLocation;
