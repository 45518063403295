import React, { useEffect } from 'react';
import ReviewVideo from '../../../components/listing/review';
import { useParams } from 'react-router';
import { getSingleListing } from '../../../state/listing/listing.action';
import { useClearSingleListing } from '../../../state/listing/listing.hook';

function ReviewVideoPage() {
  const { listingId } = useParams();
  const { clearSingleListing } = useClearSingleListing();

  useEffect(() => {
    if (listingId) getSingleListing(listingId);
    else window.location.href = `${window.location.origin}/buildings`;
  }, [listingId]);

  useEffect(() => {
    return () => {
      clearSingleListing();
    };
  }, []);

  return (
    <div className="off--canvas">
      <div className="listing">
        <ReviewVideo />
      </div>
    </div>
  );
}

export default ReviewVideoPage;
