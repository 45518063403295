import { meAPI } from './me.api';
import { MeActions } from './me.types';
import { ErrorTypes } from '../../types/error.types';
import fetchActions from '../../utils/state.utils';
import { _dataValid } from '../../utils/apiValidatior';
import axios from 'axios';

export const getMe = async () => {
  const actionName = MeActions.ME;

  fetchActions.callCT(actionName);
  fetchActions.init(actionName);

  try {
    const res = await meAPI(fetchActions.setCT(actionName));
    if (_dataValid(res.data)) {
      fetchActions.success(actionName, res.data);
      return res.data;
    } else {
      fetchActions.failure(actionName, ErrorTypes.invalidResponse);
      throw ErrorTypes.invalidResponse;
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      fetchActions.failure(actionName, error);
    }
  }
};
