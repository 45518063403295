import React from 'react';

const AboutTeam: React.FC = () => {
  return (
    <div className="team--component" id={'team'}>
      <div className="container container--expand">
        <h2 className="heading__component heading__component-blue mb-4 pb-3">Meet the team</h2>
        <div className="team--content">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="team--box">
                <div className="team--img">
                  <img src="/images/Zak.svg" alt="Img" />
                </div>
                <h4 className="team__name">Zak Kidd</h4>
                <p className="team__designation">CEO</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="team--box">
                <div className="team--img">
                  <img src="/images/Stephen.svg" alt="Img" />
                </div>
                <h4 className="team__name">Stephen Hogsten</h4>
                <p className="team__designation">Engineering Manager</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="team--box">
                <div className="team--img">
                  <img src="/images/Pablo.svg" alt="Img" />
                </div>
                <h4 className="team__name">Pablo Montoya</h4>
                <p className="team__designation">Product Manager</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="team--box">
                <div className="team--img">
                  <img src="/images/Martin.svg" alt="Img" />
                </div>
                <h4 className="team__name">Martin Ringlin</h4>
                <p className="team__designation">Strategist</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="team--box">
                <div className="team--img">
                  <img src="/images/Qasim.svg" alt="Img" />
                </div>
                <h4 className="team__name">Qasim Asad Salam</h4>
                <p className="team__designation">Technical Advisor</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="team--box">
                <div className="team--img">
                  <img src="/images/Talha.svg" alt="Img" />
                </div>
                <h4 className="team__name">Talha</h4>
                <p className="team__designation">Technical Advisor</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="team--box">
                <div className="team--img">
                  <img src="/images/Afzaal.svg" alt="Img" />
                </div>
                <h4 className="team__name">Afzaal</h4>
                <p className="team__designation">Full Stack Developer</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="team--box">
                <div className="team--img">
                  <img src="/images/Hibba.svg" alt="Img" />
                </div>
                <h4 className="team__name">Hibba</h4>
                <p className="team__designation">Project Manager</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="team--box">
                <div className="team--img">
                  <img src="/images/Zain.svg" alt="Img" />
                </div>
                <h4 className="team__name">Zain</h4>
                <p className="team__designation">Back End Developer</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="team--box">
                <div className="team--img">
                  <img src="/images/Georgi.svg" alt="Img" />
                </div>
                <h4 className="team__name">Georgi Petrov</h4>
                <p className="team__designation">Media Specialist</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="team--box">
                <div className="team--img">
                  <img src="/images/Hayley.svg" alt="Img" />
                </div>
                <h4 className="team__name">Hayley Kidd</h4>
                <p className="team__designation">Marketing Representative</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="team--box">
                <div className="team--img">
                  <img src="/images/Marissa.svg" alt="Img" />
                </div>
                <h4 className="team__name">Marissa Wood</h4>
                <p className="team__designation">Full Stack Developer</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="team--box">
                <div className="team--img">
                  <img src="/images/Austin.svg" alt="Img" />
                </div>
                <h4 className="team__name">Austin Regan</h4>
                <p className="team__designation">Full Stack Developer</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="team--box">
                <div className="team--img">
                  <img src="/images/Ali.svg" alt="Img" />
                </div>
                <h4 className="team__name">Ali Raza Chishti</h4>
                <p className="team__designation">Front End Developer</p>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
              <div className="team--box">
                <div className="team--img">
                  <img src="/images/Umar.svg" alt="Img" />
                </div>
                <h4 className="team__name">Umar Dawood</h4>
                <p className="team__designation">UI/UX Developer</p>
              </div>
            </div>
          </div>
        </div>
        <div id={'contact'}></div>
      </div>
    </div>
  );
};

export default AboutTeam;
