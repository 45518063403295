import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  setInfo: (e: string) => void;
}
const ClientInfo: React.FC<Props> = props => {
  const { setInfo } = props;
  return (
    <Form.Group className="forms--group forms--group-radius mb-3">
      <Form.Control
        type="text"
        placeholder="Johnson & Associates"
        onChange={e => setInfo(e.target.value)}
      />
    </Form.Group>
  );
};

export default ClientInfo;
