import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import qs from 'query-string';
import AmenityMatterPortPanel from './amenityMatterPortPanel';

const BuildingAmenitiesMatterPorts: React.FC = () => {
  const listingInfo = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing,
  );
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [openedMatterPort, setOpenedMatterPort] = useState<string | null>(null);

  useLayoutEffect(() => {
    const elements = document.getElementsByClassName('subTours--video');
    if (elements.length) {
      setHeight(elements[0].clientHeight);
      setWidth(elements[0].clientWidth);
    }
  }, []);

  return (
    <div className="row row--space-10 sdl--row align-items-center">
      <div className="col-12 col-lg-5 d-flex flex-column">
        <div className="sdl--l text-center text-lg-left mb-20 mb-md-4 pb-md-4">
          <h2 className="dashboard__heading dashboard__heading-alt font-semibold mb-4 mb-lg-0">
            Amenities 3D Tours
          </h2>
        </div>
      </div>
      <div className="col-12 col-lg-7 d-flex flex-column">
        <div className="row row--space-10">
          {listingInfo?.data?.building?.buildingAmenitiesMatterportLinks?.map(
            (amenityMatterPort, index) => {
              try {
                const amenityUrl = new URL(amenityMatterPort.url);
                const queryParams = qs.parse(amenityUrl.search);
                return (
                  <div className="col col-6 col-md-4 col-lg-6 col-xl-4" key={index}>
                    <div className="subTours--block">
                      <a
                        className="subTours--video"
                        style={{
                          backgroundImage:
                            'url("https://my.matterport.com/api/v1/player/models/' +
                            queryParams?.m +
                            '/thumb?width=' +
                            width +
                            '&height=' +
                            height +
                            '&dpr=1&disable=upscale")',
                        }}
                        onClick={() => setOpenedMatterPort(amenityMatterPort.url)}
                      >
                        <span className="subToursVideo--overlay">
                          <span className="subToursVideo__play">
                            <i className="icon-play-circle"></i>
                          </span>
                        </span>
                      </a>
                      <p className="para__md-dark mb-0">{amenityMatterPort?.name}</p>
                    </div>
                  </div>
                );
              } catch (e) {
                console.error(e);
                return <></>;
              }
            },
          )}
        </div>
      </div>
      {openedMatterPort && (
        <AmenityMatterPortPanel close={() => setOpenedMatterPort(null)} url={openedMatterPort} />
      )}
    </div>
  );
};

export default BuildingAmenitiesMatterPorts;
