import React from 'react';
import { UploadedMediaInterface } from '../../state/media/media.types';
import { compileAvatarUrl } from '../../utils/avatar.utils';

interface Props {
  pdfs: UploadedMediaInterface[];
}

const PdfsSearchResults: React.FC<Props> = (props: Props) => {
  const { pdfs } = props;
  return (
    <div className="searchResults--block">
      <h5 className="heading__sm heading__sm-black font-semibold">PDFs</h5>
      <div className="mt-4">
        <div className="pdf--search">
          <div className="row psf--search-row">
            {pdfs?.map((pdf, index) => (
              <div className="col col-12 col-sm-6 col-md-4 col-xl-3" key={index}>
                <a
                  className="pdf__btn"
                  href={compileAvatarUrl(pdf.url)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span className="pdf__btn-icon">
                    <i className="icon-pdf"></i>
                  </span>
                  <span className="pdf__btn-text">{pdf?.name}</span>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PdfsSearchResults;
