import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  TOUR_SWITCHES as SWITCH,
  TourListingInterface,
} from '../../../../../state/tour/tour.types';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import { MatterPortContext } from '../index';
import { getInitials } from '../../../../../utils/misc.utils';

interface Props {
  type: SWITCH;
  onClick: (e: SWITCH) => void;
  currentListing: string;
  currentTab: string;
  listing: TourListingInterface;
}
const OptionBox: React.FC<Props> = props => {
  const { type, onClick, currentListing, listing, currentTab } = props;
  const [showTourOptions, setShowTourOptions] = useState(true);
  const { matterPortLink, setMatterPortLink } = useContext(MatterPortContext);

  const toggleOptions = () => {
    if (currentTab === SWITCH.TOUR && currentListing === listing._id) {
      setShowTourOptions(prevState => !prevState);
    } else {
      setShowTourOptions(true);
    }
  };

  return (
    <div className="tel--btns-col">
      <div
        className={`btn btn-primary tel__btn ${
          currentListing === listing._id && currentTab === type ? 'active' : ''
        } ${
          currentTab === SWITCH.TOUR && showTourOptions && currentListing === listing._id
            ? 'link__active'
            : ''
        }`}
        onClick={() => {
          onClick(type);
          if (listing._id !== currentListing) {
            setMatterPortLink(listing.matterPortLink);
          }
          toggleOptions();
        }}
      >
        <span className="tel__btn-icon">
          {type === SWITCH.HOME && <i className="icon-home"></i>}
          {type === SWITCH.DETAILS && <i className="icon-details"></i>}
          {type === SWITCH.LOCATION && <i className="icon-location"></i>}
          {type === SWITCH.GALLERY && <i className="icon-gallery"></i>}
          {type === SWITCH.FLOOR_PLANS && <i className="icon-floorplan"></i>}
          {type === SWITCH.TOUR && <i className="icon-tour"></i>}
          {type === SWITCH.SPECS && <i className="icon-building"></i>}
        </span>
        {type === SWITCH.DETAILS && <span className="tel__btn-text ml-2">Details</span>}
        {type === SWITCH.LOCATION && <span className="tel__btn-text ml-2">Location</span>}
        {type === SWITCH.GALLERY && <span className="tel__btn-text ml-2">Gallery</span>}
        {type === SWITCH.FLOOR_PLANS && <span className="tel__btn-text ml-2">Floor Plan</span>}
        {type === SWITCH.TOUR && (
          <React.Fragment>
            <span className="tel__btn-text ml-2">Tour</span>
            {listing?.building?.buildingAmenitiesMatterportLinks?.length > 0 && (
              <div className="tour--btn-canvas tour--btn-nowrap ml-2">
                <Button
                  type="button"
                  className={`tour__btn tour__btn-gray ${
                    matterPortLink === listing?.matterPortLink ? 'active' : ''
                  }`}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setMatterPortLink(listing.matterPortLink);
                  }}
                >
                  <span className="tour__btn-desktop">Main Tour</span>
                  <span className="tour__btn-mobile">MT</span>
                </Button>
                {listing?.building?.buildingAmenitiesMatterportLinks?.map((amenityTour, index) => (
                  <Button
                    key={index}
                    type="button"
                    className={`tour__btn tour__btn-gray ${
                      matterPortLink === amenityTour.url ? 'active' : ''
                    }`}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      setMatterPortLink(amenityTour.url);
                    }}
                  >
                    <span className="tour__btn-desktop">
                      {amenityTour?.name || `Amenity ${index + 1}`}
                    </span>
                    <span className="tour__btn-mobile">
                      {amenityTour?.name ? getInitials(amenityTour.name) : `A${index + 1}`}
                    </span>
                  </Button>
                ))}
              </div>
            )}
          </React.Fragment>
        )}
        {type === SWITCH.SPECS && <span className="tel__btn-text ml-2">Specs</span>}
      </div>
    </div>
  );
};

export default OptionBox;
