import React from 'react';

const NoListingFound: React.FC = () => {
  return (
    <div className="dashboard--notfound notfound--result">
      <span className="notfound__result-img"></span>
      <p className="para__lg para__lg-dark">Sorry, there are no listings that match your search</p>
      <p className="para__md-gray mb-0">Please try again.</p>
    </div>
  );
};

export default NoListingFound;
