import * as React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { useMe } from '../../state/me/me.hook';
import { useEffect } from 'react';
import fetchActions from '../../utils/state.utils';
import Loader from '../../components/common/loader';
import { showNotification } from '../../utils/misc.utils';
import { useLogout } from '../../state/login/login.hook';
import Dashboard from '../dashboard';
import ListingCreatePage from '../listing/form';
import ListingsView from '../listing/view/allListing';
import SingleListingsView from '../listing/view/singleListing';
import GreatJob from '../notice/greatJob';
import BuildingsView from '../building/view/allBuildings';
import AddAndEditBuildingPage from '../building/form/index';
import Settings from '../setting/setting';
import ListingReviewPage from '../listing/review';
import env from '../../utils/env.utils';
import { DEFAULT_INTERCOM_CUSTOM_LAUNCHER_ID, NODE_ENV } from '../../common/constants';
import { FullStoryAPI } from 'react-fullstory';
// eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-ignore
// @ts-ignore
import { IntercomAPI } from 'react-intercom';
import ViewToursPage from '../tour/view';
import CreateTourPage from '../tour/create';
import EditTourPage from '../tour/edit';
import SearchPage from '../search';

const GreatJobPage = {
  path: '/greatJob',
  component: GreatJob,
};
const dashboard = {
  path: '/dashboard',
  component: Dashboard,
};
const buildings = {
  path: '/buildings',
  component: BuildingsView,
};
const createListing = {
  path: '/building/:buildingId/listings/create',
  component: ListingCreatePage,
};
const viewListing = {
  path: '/building/:buildingId/listings',
  component: ListingsView,
};
const editListing = {
  path: '/listing/:listingId/edit',
  component: ListingCreatePage,
};

const reviewListing = {
  path: '/listing/review/:listingId',
  component: ListingReviewPage,
};

const createBuilding = {
  path: '/building/create',
  component: AddAndEditBuildingPage,
  exact: true,
};

const editBuilding = {
  path: '/building/:buildingId/edit',
  component: AddAndEditBuildingPage,
  exact: true,
};

const listingDetail = {
  path: '/listing/:listingId',
  component: SingleListingsView,
  exact: true,
};

const settings = {
  path: '/settings',
  component: Settings,
  exact: true,
};

const viewTours = {
  path: '/tours/:type',
  exact: true,
  component: ViewToursPage,
};

const createTour = {
  path: '/tour/create',
  exact: true,
  component: CreateTourPage,
};

const editTour = {
  path: '/tour/:tourId/edit',
  exact: true,
  component: EditTourPage,
};

const quickSearch = {
  path: '/search',
  exact: true,
  component: SearchPage,
};

const Layout: React.FC = () => {
  useEffect(() => {
    fetchActions.resetState();
  }, []);

  const history = useHistory();

  const { error: meError, data: meData, loading: meLoading } = useMe();
  const { logOut } = useLogout();

  useEffect(() => {
    if (!meLoading && meError) {
      history.push(`/login?return_url=${encodeURIComponent(window.location.href)}`);
    }
  }, [meError, meLoading]);

  useEffect(() => {
    if (meData && meData?.role === 'super_admin') {
      showNotification('warning', 'Not Allowed', 'Super Admin login is not allowed');
      logOut();
      history.push('/login');
    }
    if (meData && env.nodeEnv === NODE_ENV.PRODUCTION) {
      IntercomAPI('update', {
        name: `${meData.firstName} ${meData.lastName}`,
        email: meData.email,
        // eslint-disable-next-line @typescript-eslint/camelcase
        user_id: meData._id,
        // eslint-disable-next-line @typescript-eslint/camelcase
        created_at: meData.createdAt,
        company: {
          id: meData?.company?._id || 'Unknown',
          name: meData?.company?.name || 'Unknown',
        },
        // eslint-disable-next-line @typescript-eslint/camelcase
        custom_launcher_selector: '#' + DEFAULT_INTERCOM_CUSTOM_LAUNCHER_ID,
        // eslint-disable-next-line @typescript-eslint/camelcase
        hide_default_launcher: true,
      });
      FullStoryAPI('identify', meData._id, {
        displayName: `${meData.firstName} ${meData.lastName}`,
        email: meData.email,
        uid: meData._id,
        companyName: meData?.company?.name || 'Unknown',
        companyId: meData?.company?._id || 'Unknown',
        subdomain: meData?.company?.domain || 'Unknown',
      });
    }
  }, [meData]);

  const routes: any[] = [
    dashboard,
    createListing,
    viewListing,
    editListing,
    listingDetail,
    buildings,
    GreatJobPage,
    reviewListing,
    createBuilding,
    editBuilding,
    settings,
    viewTours,
    createTour,
    editTour,
    quickSearch,
  ];

  return (
    <React.Fragment>
      <React.Suspense fallback={<Loader />}>
        <Switch>
          {routes.map((route: any) => (
            <Route key={route.path} {...route} />
          ))}
          <Redirect to="/404" />
        </Switch>
      </React.Suspense>
    </React.Fragment>
  );
};

export default Layout;
