import React from 'react';
import { Form } from 'react-bootstrap';
import { ListingForm as list } from '../../../../state/listing/listing.form';
import { ListingInterface, ListingErrorInterface } from '../../../../state/listing/listing.types';
import { errorMsg } from '../../listing.component';
import Photos from './photos';
import PDFs from './pdfs';
import Plans from './plans';
import Renderings from './renderings';

interface Props {
  register: any;
  errors: any;
  data: ListingInterface;
  dataError: ListingErrorInterface;
  setOutput: (key: string, value: any) => void;
  showLabels?: boolean;
}
const ListingMedia: React.FC<Props> = props => {
  const { data, errors, dataError, setOutput, showLabels } = props;

  return (
    <React.Fragment>
      {showLabels && <Form.Label>Matterport Link*</Form.Label>}
      <Form.Group className="forms--group">
        {errorMsg(errors[list.matterPortLink])}
        <div className="row row--space-12 align-items-sm-center">
          <div className="col-12 col-sm-6">
            <Form.Control
              type="text"
              placeholder="Matterport link*"
              name={list.matterPortLink}
              ref={props.register}
            />
          </div>
          <div className="col-12 col-sm-6">
            <p className="dashboard__paragraph text__gray mt-2 mt-sm-0 mb-0">
              This URL will be turned into a 90s film
            </p>
          </div>
        </div>
      </Form.Group>
      <div className="row row--space-12">
        <div className="col-12">
          {showLabels && <Form.Label>Video Description</Form.Label>}
          <Form.Group className="forms--group">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="What would you like to highlight on the 90s tour video? (Optional)"
              name={list.videoDescription}
              ref={props.register}
            />
            {errorMsg(errors[list.videoDescription])}
          </Form.Group>
        </div>
      </div>

      <Photos data={data} setOutput={setOutput} dataError={dataError} />
      <Plans data={data} setOutput={setOutput} dataError={dataError} />
      <Renderings data={data} setOutput={setOutput} dataError={dataError} />
      <PDFs data={data} setOutput={setOutput} dataError={dataError} />
    </React.Fragment>
  );
};

export default ListingMedia;
