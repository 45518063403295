import moment from 'moment';
import { notification } from 'antd';
import 'antd/lib/notification/style/index.css';
import { SEPARATORS } from '../common/constants';
import copy from 'copy-to-clipboard';

export const getHumanizeTime = (e: string): string => {
  return `${moment.duration(new Date(e).getTime() - new Date().getTime()).humanize()} ago`;
};

export const getProtectedEmail = (e: string): string => {
  if (!e.length) return e;
  const parts = e.split('@');
  const moreParts = parts[1] ? parts[1].split('.') : [''];
  let name = parts[0];
  let type = moreParts[0];
  if (name.length)
    name = name.slice(0, 1) + name.slice(1, name.length).replace(/[0-9a-zA-Z.]/g, '*');
  if (type.length)
    type = type.slice(0, 1) + type.slice(1, type.length).replace(/[a-z0-9A-Z.]/g, '*');
  const remain = moreParts.slice(1, moreParts.length).join('.');
  let final = name;
  if (type.length) final += '@' + type;
  if (remain.length) final += '.' + remain;
  return final;
};

export const showNotification = (
  type: 'error' | 'success' | 'info' | 'warn' | 'warning',
  message: string,
  description: string,
  additionalConfig: any = {},
) => {
  const obj = { ...additionalConfig, message, description };
  switch (type) {
    case 'error':
      notification.error(obj);
      break;

    case 'success':
      notification.success(obj);
      break;

    case 'info':
      notification.info(obj);
      break;

    case 'warn':
      notification.warn(obj);
      break;

    case 'warning':
      notification.warning(obj);
      break;

    default:
      break;
  }
};

export const noToAlpha = (num: number) => {
  let s = '',
    t;

  while (num > 0) {
    t = (num - 1) % 26;
    s = String.fromCharCode(65 + t) + s;
    num = ((num - t) / 26) | 0;
  }
  return s || undefined;
};

export const errorToString = (e: any): string => {
  let output = 'Please fix the following issues first (';
  const res = [];
  for (const i in e) {
    if (e[i].length) res.push(i);
  }
  if (res.length) {
    output += res.join(SEPARATORS.COMMA + SEPARATORS.SPACE) + ')';
    return output;
  } else return '';
};

export const injectScriptIntoHead = (
  src?: string,
  async?: boolean,
  text?: string,
  type?: string,
) => {
  const script = document.createElement('script');
  if (src) script.src = src;
  if (async) script.async = true;
  if (text) script.text = text;
  if (type) script.type = type;
  document.head.appendChild(script);
  return () => {
    document.head.removeChild(script);
  };
};

export const compileGoogleMapsScriptSrc = (key: string) => {
  return `https://maps.googleapis.com/maps/api/js?key=${key}&v=3.exp&libraries=geometry,drawing,places`;
};

export const getDomainFromSubdomain = (path: string) => {
  const host = window.location.host.split('.');
  host?.shift();
  const newHost = host?.join('.');
  return `${window.location.protocol}//${newHost + path}`;
};

export const validateUrl = (e: string) => {
  try {
    const url = new URL(e);
    if (url) {
      const pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i',
      ); // fragment locator
      return !!pattern.test(e);
    }
  } catch (err) {
    return false;
  }
};

export const generateRandomString = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const silverMapDesign = [
  {
    elementType: 'geometry',
    stylers: [{ color: '#f5f5f5' }],
  },
  {
    elementType: 'labels.icon',
    stylers: [{ visibility: 'off' }],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [{ color: '#f5f5f5' }],
  },
  {
    featureType: 'administrative.land_parcel',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#bdbdbd' }],
  },
  {
    featureType: 'poi',
    elementType: 'geometry',
    stylers: [{ color: '#eeeeee' }],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [{ color: '#e5e5e5' }],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [{ color: '#ffffff' }],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#757575' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [{ color: '#dadada' }],
  },
  {
    featureType: 'road.highway',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#616161' }],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
  {
    featureType: 'transit.line',
    elementType: 'geometry',
    stylers: [{ color: '#e5e5e5' }],
  },
  {
    featureType: 'transit.station',
    elementType: 'geometry',
    stylers: [{ color: '#eeeeee' }],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [{ color: '#c9c9c9' }],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [{ color: '#9e9e9e' }],
  },
];

export const getInitials = (name: string) => {
  const initials = name ? name?.match(/\b\w/g) || [] : [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
};

export const getTourLink = (
  tourId: string,
  expiredAt: number,
  domain: string,
  tourUrl?: string,
) => {
  const host = window.location.host?.split(SEPARATORS.DOT);
  if (host?.length && host[0] === 'www') {
    host.shift();
  }
  const newHost = host.join(SEPARATORS.DOT);
  const link = tourUrl || `${window.location.protocol}//${domain}.${newHost}/tour/${tourId}`;
  const expiryInMinutes = Math.floor(
    moment
      .duration(moment.unix(expiredAt).diff(moment.unix(moment(Date.now()).unix())))
      .asMinutes(),
  );
  let expiry = expiryInMinutes;
  let format = expiryInMinutes > 1 ? 'minutes' : 'minute';
  if (expiryInMinutes > 1439) {
    expiry = Math.floor(expiryInMinutes / 1440);
    format = expiry > 1 ? 'days' : 'day';
  } else if (expiryInMinutes > 59) {
    expiry = Math.floor(expiryInMinutes / 60);
    format = expiry > 1 ? 'hours' : 'hour';
  }
  return { link, expiry, format };
};

export const copyTourLinkToClipboard = (
  tourId: string,
  expiredAt: number,
  subdomain?: string,
  tourUrl?: string,
) => {
  if (subdomain) {
    const { link, format, expiry } = getTourLink(tourId, expiredAt, subdomain, tourUrl);
    copy(link);
    showNotification(
      'success',
      'Link copied to the clipboard',
      `This link will expire in ${expiry} ${format}`,
    );
    document.body.click();
  } else {
    showNotification('info', 'Information', 'Please wait. Request in progress, try in few minutes');
  }
};

export const checkIfNumber = (val: any): boolean => {
  if (!val) return false;
  else return !isNaN(val) && isFinite(val);
};
