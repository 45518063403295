import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { ListingForm as list } from '../../../../state/listing/listing.form';
import {
  ListingInterface,
  ListingErrorInterface,
  PhotoInterface,
} from '../../../../state/listing/listing.types';
import UploadPhotos from './uploads/media';
import { UploadedMediaInterface } from '../../../../state/media/media.types';
import SingleMediaTile from '../../../media/singleTile';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import { errorMsg } from '../../listing.component';

interface Props {
  data: ListingInterface;
  dataError: ListingErrorInterface;
  setOutput: (key: string, value: any) => void;
}
const Photos: React.FC<Props> = props => {
  const [modalPhotoShow, setModalPhotoShow] = useState(false);
  const { data, dataError, setOutput } = props;

  useEffect(() => {
    if (data.media && data.media.length) {
      const media = data.media;
      const primaryFound = media.find(image => image.isPrimary === true);
      if (!primaryFound) {
        media[0].isPrimary = true;
        setOutput(list.media, media);
      }
    }
  }, [data]);

  const handleImages = (images: UploadedMediaInterface[]) => {
    let alreadyImages = [] as PhotoInterface[];
    const newImages = images as PhotoInterface[];

    if (data.media) alreadyImages = data.media;
    newImages.map(image => (image.isPrimary = false));
    if (!alreadyImages.length) newImages[0].isPrimary = true;

    setOutput(list.media, [...alreadyImages, ...newImages]);
    setModalPhotoShow(false);
  };

  const removeImage = (index: number) => {
    if (data.media && data.media.length) {
      const media = data.media;
      media.splice(index, 1);
      setOutput(list.media, media);
    }
  };

  const changePrimary = (e: any) => {
    const url = e.target.value;
    if (url) {
      const images = [...data.media];
      images.forEach(image => (image.isPrimary = image.url === url));
      setOutput(list.media, images);
    }
  };

  return (
    <React.Fragment>
      <div className="file--uploaded ">
        {data.media &&
          data.media.map((file, index) => (
            <div className="uploaded--img-block d-flex align-items-center flex-wrap" key={index}>
              <div className="uploaded--img-outer">
                <SingleMediaTile
                  url={compileAvatarUrl(file.url)}
                  remove={() => removeImage(index)}
                  col
                />
                <label className="custom__radioBtn">
                  Make Primary
                  <input
                    type="radio"
                    name="primary_photo"
                    checked={file.isPrimary === true}
                    value={file.url}
                    onChange={changePrimary}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          ))}
      </div>
      <div className="mb-3 forms--group errorMsg--set">
        <Button onClick={() => setModalPhotoShow(true)} className="upload__button mb-4">
          Upload {data.media?.length > 0 && 'More '}Photos
          <span className="upload__button-icon">
            <i className="icon-upload"></i>
          </span>
        </Button>
        {errorMsg({}, dataError[list.media], 'mb-3')}
        {modalPhotoShow && (
          <UploadPhotos
            handleClose={() => setModalPhotoShow(false)}
            handleChanges={handleImages}
            alreadyFiles={data.media ? data.media.length : 0}
            title="photo"
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Photos;
