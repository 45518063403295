import React from 'react';
import GoogleMaps from '../../../../location';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';

interface Location {
  lat: number;
  lng: number;
}
const BuildingLocation: React.FC = () => {
  const info = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing.data,
  );

  return (
    <React.Fragment>
      <h2 className="dashboard__heading dashboard__heading-alt text-center font-semibold mb-5">
        Map
      </h2>
      {info?.building.latitude && info?.building.longitude && (
        <div className="map--box">
          <GoogleMaps
            location={{
              lat: parseFloat(info?.building.latitude),
              lng: parseFloat(info?.building.longitude),
            }}
            marker={[
              {
                lat: parseFloat(info?.building.latitude),
                lng: parseFloat(info?.building.longitude),
              },
            ]}
            selected={0}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default BuildingLocation;
