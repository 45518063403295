import React from 'react';
import { Form } from 'react-bootstrap';
import { debounce } from 'lodash';

interface Props {
  onSubmit: (e: string) => void;
}
const ListingSearchBox: React.FC<Props> = props => {
  const { onSubmit } = props;

  const onSearchChange = debounce((value: string) => {
    onSubmit(value);
  }, 300);

  return (
    <Form.Group className="form--group-alt search--group-alt">
      <Form.Control
        type="search"
        placeholder="Type address here"
        onChange={e => onSearchChange(e.target.value)}
      />
      <span className="search__icon">
        <i className="icon-search"></i>
      </span>
    </Form.Group>
  );
};

export default ListingSearchBox;
