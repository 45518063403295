import { getAllListingsAPI, getSingleListingAPI } from './listing.api';
import { ListingActions } from './listing.types';
import fetchActions from '../../utils/state.utils';
import axios from 'axios';
import { LISTING_STATUS, VIDEO_STATUS } from '../../common/constants';

export const getAllListings = async (
  buildingId: string,
  limit: number,
  skip: number,
  prev: any = [],
) => {
  const actionName = ListingActions.GET_ALL_LISTINGS;

  fetchActions.callCT(actionName);
  fetchActions.init(actionName);

  try {
    const res = await getAllListingsAPI(buildingId, limit, skip, fetchActions.setCT(actionName));
    if (prev.length) {
      fetchActions.success(actionName, {
        ...res.data,
        data: [...prev, ...res.data.data],
      });
    } else {
      fetchActions.success(actionName, res.data);
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      fetchActions.failure(actionName, error);
    }
  }
};

export const clearAllListings = () => {
  fetchActions.dispatch({
    type: ListingActions.GET_ALL_LISTINGS_CLEAR,
    payload: {},
  });
};

export const getSingleListing = async (listingId: string) => {
  const actionName = ListingActions.GET_SINGLE_LISTING;

  fetchActions.callCT(actionName);
  fetchActions.init(actionName);

  try {
    const res = await getSingleListingAPI(listingId, fetchActions.setCT(actionName));
    fetchActions.success(actionName, res.data);
  } catch (error) {
    if (!axios.isCancel(error)) {
      fetchActions.failure(actionName, error);
    }
  }
};

export const clearSingleListing = () => {
  fetchActions.dispatch({
    type: ListingActions.GET_SINGLE_LISTING_CLEAR,
    payload: {},
  });
};

export const changeListingStatus = (status: LISTING_STATUS) => {
  fetchActions.dispatch({
    type: ListingActions.LISTING_UPDATE,
    payload: status,
  });
};
export const changeVideoListingStatus = (status: VIDEO_STATUS) => {
  fetchActions.dispatch({
    type: ListingActions.VIDEO_UPDATE,
    payload: status,
  });
};
