import React, { useEffect, useState } from 'react';
import HandleTourType from './../tourType';
import { TOUR_SWITCHES, TourListingInterface } from '../../../../../state/tour/tour.types';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import DraggableListing from './draggable';

interface Props {
  changeTab: (e: TOUR_SWITCHES) => void;
  changeListing: (e: string) => void;
  addListing: () => void;
}
const TourEditController: React.FC<Props> = props => {
  const { changeTab, changeListing, addListing } = props;
  const listingData = useSelector((state: AppStateInterface) => state.tour.tourListing);
  const tour = useSelector((state: AppStateInterface) => state.tour.tourInfo.data);
  const [listings, setListings] = useState([] as TourListingInterface[]);
  const [currentListing, updateListing] = useState('');
  const [currentTab, updateTab] = useState<TOUR_SWITCHES>(TOUR_SWITCHES.HOME);

  useEffect(() => {
    if (listingData?.listing) updateListing(listingData.listing._id || '');
  }, [listingData]);
  useEffect(() => {
    if (tour?.listings?.length) {
      const listingArray = [] as TourListingInterface[];
      for (const listing of tour.listings) {
        if (listing?.listing) listingArray.push(listing.listing);
        setListings(listingArray);
      }
    }
  }, [tour]);

  const handleSwitch = (listingId: string, switchTab: TOUR_SWITCHES) => {
    if (currentListing !== listingId) {
      changeListing(listingId);
      updateListing(listingId);
    }
    if (currentTab !== switchTab) {
      changeTab(switchTab);
      updateTab(switchTab);
    }
  };

  return (
    <React.Fragment>
      {!listingData && (
        <div className="white--rounded-box tourEdit--noListings mb-20">
          <div className="row row--space-10 align-items-md-center">
            {tour?.tourType && (
              <HandleTourType total={listings.length} currentType={tour.tourType} />
            )}
          </div>
          <div className="dashboard--notfound tourEdit--nl pt-sm-4">
            <span className="notfound__listing-img"></span>
            <p className="para__lg para__lg-dark">Oh oh, there are no listings in this tour</p>
            <p className="para__md-gray mb-4 pb-2">
              You won’t be able to start a tour unless you add listings
            </p>
            <button className="admin__button admin__button-sm button__radius" onClick={addListing}>
              Add Listing
              <i className="icon-plus ml-2"></i>
            </button>
          </div>
        </div>
      )}
      {listingData && (
        <div className="tourEdit--bottom mt-4">
          <div className="row row--space-10 align-items-md-center">
            {tour?.tourType && (
              <HandleTourType total={listings.length} currentType={tour.tourType} />
            )}
            <div className="col col-12 col-md-4 col-lg-5 col-xl-6">
              <div className="text-md-right mt-3 mt-md-0">
                <a className="link__blue" onClick={addListing}>
                  Add Listing +
                </a>
              </div>
            </div>
          </div>
          <DraggableListing
            onClick={handleSwitch}
            listings={listings}
            currentListing={currentListing}
            currentTab={currentTab}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default TourEditController;
