import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface Props {
  close: () => void;
  url: string;
}
const AmenityMatterPortPanel: React.FC<Props> = props => {
  const { close, url } = props;

  return (
    <Modal show onHide={close} className="video--modal" contained-modal-title-vcenter="true">
      <Modal.Body>
        <div className="video--canvas-expand">
          <div className="video--box-expand">
            <iframe
              src={`${url}&play=1`}
              allowFullScreen={true}
              height="100%"
              width="100%"
              style={{
                border: '#000',
                borderRadius: '20px',
              }}
            />
          </div>
          <Button className="cross__circle-modal" onClick={close}>
            <i className="icon-cross"></i>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default AmenityMatterPortPanel;
