import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { showNotification } from '../../../utils/misc.utils';
import { errorExtractor } from '../../../utils/error.utils';
import { Building } from '../../../state/listing/listing.types';
import { useHistory, useParams } from 'react-router';
import { RECORDING_STATE, REVIEW_STATE, VIDEO_STATUS } from '../../../common/constants';
import DeleteRecording from '../../modals/recordWarning';
import { changeAudioStatus, changeVideoStatus } from '../../../state/review/review.action';
import SmallLoader from '../../common/small.loader';

interface Props {
  onSubmit: () => void;
  loadingState: boolean;
  submitEnabled: boolean;
}
const ReviewBasic: React.FC<Props> = props => {
  const { loadingState: loading, onSubmit: submit, submitEnabled } = props;
  const history = useHistory();
  const { listingId } = useParams();
  const listingData = useSelector((state: AppStateInterface) => state.listing.singleListing);
  const review = useSelector((state: AppStateInterface) => state.review);

  const [listing, setListing] = useState(listingData.data);
  const [building, setBuilding] = useState({} as Building);
  const [backWarn, updateBackWarn] = useState(false);
  const [submitting, setSubmit] = useState(false);

  const goBack = () => {
    window.location.href = `${window.location.origin}/listing/${listingId}`;
  };
  const onDelete = () => {
    changeAudioStatus(RECORDING_STATE.IDLE);
    changeVideoStatus(REVIEW_STATE.STOP);
  };

  const invokeClose = () => {
    if (review.recordingState === RECORDING_STATE.RECORD) {
      updateBackWarn(true);
      changeVideoStatus(REVIEW_STATE.PAUSE);
    } else goBack();
  };
  const handleSubmit = () => {
    submit();
    setSubmit(true);
  };

  useEffect(() => {
    return () => {
      changeVideoStatus(REVIEW_STATE.IDLE);
      changeAudioStatus(RECORDING_STATE.IDLE);
    };
  }, []);
  useEffect(() => {
    if (review.reviewState === REVIEW_STATE.IDLE && backWarn) goBack();
  }, [review.reviewState]);
  useEffect(() => {
    if (listingData.error)
      showNotification('error', 'Fetching error', errorExtractor(listingData.error));
    else if (listingData.data) {
      setListing(listingData.data);
      if (listingData.data.building) setBuilding(listingData.data.building);
    }
  }, [listingData]);

  const showSubmitButton =
    listing?.videoStatus === VIDEO_STATUS.UPLOADED ||
    listing?.videoStatus === VIDEO_STATUS.ACCEPTED;
  return (
    <React.Fragment>
      <a className="back__btn" onClick={invokeClose}>
        <span className="back__btn-icon">
          <i className="icon-chevron-left"></i>
        </span>
        Back
      </a>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="listing--head">
            <div className="heading--group position-relative mt-1">
              <h3 className="dashboard__heading mb-0">
                {building?.location?.split(',')[0]} - {listing?.suiteNo}
              </h3>
            </div>
          </div>
        </div>
        {showSubmitButton && submitEnabled && (
          <div className="col-12 offset-lg-3 col-lg-3">
            <button
              className="admin__button lb__arrow w-100 mb-4 mb-lg-0"
              onClick={handleSubmit}
              disabled={loading}
            >
              {submitting ? (
                <React.Fragment>
                  Submitting <SmallLoader />
                </React.Fragment>
              ) : (
                'Submit Changes'
              )}
            </button>
          </div>
        )}
      </div>
      {backWarn && (
        <DeleteRecording handleCancel={() => updateBackWarn(false)} handleSuccess={onDelete} />
      )}
    </React.Fragment>
  );
};

export default ReviewBasic;
