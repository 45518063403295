import React, { useEffect, useRef, useState } from 'react';
import ReviewAllComments from './view';
import ReviewCreateComment from './create';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { CommentsInterface } from '../../../../state/listing/listing.types';
import { sortComments } from '../../../../state/review/review.hook';

interface Props {
  currentTime: number;
  comments: CommentsInterface[];
  setComments: (
    value: CommentsInterface[] | ((prevState: CommentsInterface[]) => CommentsInterface[]),
  ) => void;
  canEdit: boolean;
}

const Comments: React.FC<Props> = (props: Props) => {
  const { currentTime, comments, setComments, canEdit } = props;
  const initialComments = useSelector(
    (state: AppStateInterface) => state.listing.singleListing.data?.voiceOverComments,
  );
  const [topCommentRef, setTopCommentRef] = useState<any>(null);
  const allCommentsRef = useRef<any>(null);

  useEffect(() => {
    setComments(sortComments(initialComments));
  }, [initialComments]);

  return (
    <div className="col-12 col-lg-6" ref={allCommentsRef}>
      {comments?.length > 0 && (
        <h3 className="heading__medium heading__medium-black mb-3">
          {canEdit ? 'Comment:' : 'Comments:'}
        </h3>
      )}
      {canEdit && (
        <ReviewCreateComment
          currentTime={currentTime}
          setComments={setComments}
          topCommentRef={topCommentRef}
          allCommentsRef={allCommentsRef}
        />
      )}
      <ReviewAllComments
        currentTime={currentTime}
        comments={comments}
        setComments={setComments}
        setTopCommentRef={setTopCommentRef}
        canEdit={canEdit}
      />
    </div>
  );
};

export default Comments;
