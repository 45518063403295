import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  CSSProperties,
  useLayoutEffect,
} from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';

import { BrowserInterface, VideoCallInterface } from '../../../state/subdomain/tour/tour.types';
import Participant from './participant';
import withUnsupportedBrowserAlertForParticipant from './withUnsupportedBrowserAlertForParticipant';

interface Props {
  videoCall: VideoCallInterface;
  leaveCall: () => void;
  browser: BrowserInterface | null;
}

const FullVideo: React.FC<Props> = (props: Props) => {
  const {
    videoCall: { host, leaveCall, tenants, brokers, muteAudio, muteVideo },
  } = props;
  const videoEl = useRef(null);
  const [videoStyle, setVideoStyle] = useState<CSSProperties>({
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center top',
  });
  const [areControlsCollapsed, setAreControlsCollapsed] = useState(false);
  const [leftRowInitialMarginRight, setLeftRowInitialMarginRight] = useState<number>(50);
  const [rightRowInitialMarginLeft, setRightRowInitialMarginLeft] = useState<number>(50);

  const videoMask = useCallback(
    (el: HTMLElement | null) => {
      if (!el) return;
      if (
        !host?.screenHeight ||
        !host?.screenWidth ||
        !host?.shareableWidth ||
        !host.shareableHeight
      ) {
        // request it
        return;
      }

      setVideoStyle({
        height: (100 * host.screenHeight) / host.shareableHeight + '%',
        width: '100%',
        objectFit: 'cover',
        objectPosition: 'center top',
      });
    },
    [
      host?.sessionId,
      host?.screenHeight,
      host?.screenWidth,
      host?.shareableHeight,
      host?.shareableWidth,
    ],
  );

  const me = brokers.concat(tenants).find(participant => participant.isMe) || {
    hasAudio: true,
    hasVideo: true,
  };

  useEffect(() => {
    if (!videoEl.current) return;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    videoEl.current.srcObject = new MediaStream([host?.screenTrack]);
  }, [host?.screenTrack]);

  const tenantElements = tenants.map(tenant => (
    <Participant
      participant={tenant}
      key={tenant.sessionId}
      additionalClasses="left--user--block"
    />
  ));

  const brokerElements = brokers.map(broker => (
    <Participant
      participant={broker}
      key={broker.sessionId}
      additionalClasses="right--user--block"
    />
  ));

  useLayoutEffect(() => {
    const leftRowEl = document.getElementById('users-left-row');
    const rightRowEl = document.getElementById('users-right-row');
    if (leftRowEl && rightRowEl) {
      setLeftRowInitialMarginRight(
        parseFloat(window.getComputedStyle(leftRowEl).getPropertyValue('margin-right')),
      );
      setRightRowInitialMarginLeft(
        parseFloat(window.getComputedStyle(rightRowEl).getPropertyValue('margin-left')),
      );
    }
  }, []);

  useLayoutEffect(() => {
    const usersEl = document.getElementById('users');
    const leftRowEl = document.getElementById('users-left-row');
    const rightRowEl = document.getElementById('users-right-row');
    const leftUsers = document.getElementsByClassName('left--user--block');
    const rightUsers = document.getElementsByClassName('right--user--block');
    if (usersEl && leftRowEl && rightRowEl) {
      leftRowEl.style.width = '100%';
      rightRowEl.style.width = '100%';
      leftRowEl.style.marginRight = leftRowInitialMarginRight + 'px';
      rightRowEl.style.marginLeft = rightRowInitialMarginLeft + 'px';
      if (leftUsers?.length === 0 || rightUsers?.length === 0) {
        leftRowEl.style.width = 'auto';
        rightRowEl.style.width = 'auto';
        leftRowEl.style.marginRight = '0';
        rightRowEl.style.marginLeft = '0';
        if (leftUsers?.length === 0) {
          rightRowEl.style.maxWidth = usersEl.clientWidth + 'px';
        }
        if (rightUsers?.length === 0) {
          leftRowEl.style.maxWidth = usersEl.clientWidth + 'px';
        }
      } else {
        const usersWidth = parseFloat(window.getComputedStyle(usersEl).getPropertyValue('width'));
        const leftRowMarginRight = parseFloat(
          window.getComputedStyle(leftRowEl).getPropertyValue('margin-right'),
        );
        const rightRowMarginLeft = parseFloat(
          window.getComputedStyle(rightRowEl).getPropertyValue('margin-left'),
        );
        if (
          leftUsers?.length < rightUsers?.length &&
          leftRowEl.clientWidth === leftRowEl.scrollWidth &&
          rightRowEl.clientWidth < rightRowEl.scrollWidth
        ) {
          leftRowEl.style.width = 'auto';
          rightRowEl.style.width = 'auto';
          leftRowEl.style.maxWidth = leftRowEl.scrollWidth + 'px';
          rightRowEl.style.maxWidth =
            usersWidth - (leftRowMarginRight + rightRowMarginLeft + leftRowEl.scrollWidth) + 'px';
        }
        if (
          leftUsers?.length > rightUsers?.length &&
          rightRowEl.clientWidth === rightRowEl.scrollWidth &&
          leftRowEl.clientWidth < leftRowEl.scrollWidth
        ) {
          leftRowEl.style.width = 'auto';
          rightRowEl.style.width = 'auto';
          rightRowEl.style.maxWidth = rightRowEl.scrollWidth + 'px';
          leftRowEl.style.maxWidth =
            usersWidth - (leftRowMarginRight + rightRowMarginLeft + rightRowEl.scrollWidth) + 'px';
        }
      }
    }
  }, [brokers, tenants]);

  return (
    <div className="liveTour--page">
      <div
        className="liveTour--top liveTour--top-tenant"
        ref={videoMask}
        style={{ overflow: 'hidden', boxShadow: 'none' }}
      >
        <video ref={videoEl} autoPlay playsInline style={videoStyle} />
        {host && (
          <div className="liveTour--placement placement--centered">
            <Participant participant={host!} additionalClasses="users--broker" size="lg" />
          </div>
        )}
      </div>
      <div className="liveTour--content liveTour--content-tenant">
        <div className="container-fluid">
          <div className="users--content users--content-tenant" id="users">
            <div className="users--row users--row-left" id="users-left-row">
              {tenantElements}
            </div>
            <div className="users--row users--row-right" id="users-right-row">
              {brokerElements}
            </div>
          </div>
          <div className="liveTour--last d-flex align-items-center justify-content-center">
            <Accordion defaultActiveKey="0">
              <Card className={`liveTour--card ${areControlsCollapsed ? 'card--spacing' : ''}`}>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    onClick={() => setAreControlsCollapsed(prevState => !prevState)}
                  >
                    {!areControlsCollapsed && <i className="icon-chevron-bottom"></i>}
                    {areControlsCollapsed && <i className="icon-chevron-top"></i>}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <ul className="liveTour--options">
                      <li>
                        {me.hasVideo ? (
                          <a
                            className="liveTourOpt__box liveTourOpt__box-gray"
                            onClick={() => {
                              muteVideo(false);
                            }}
                          >
                            <span className="liveTourOpt__icon">
                              <i className="icon-video"></i>
                            </span>
                            <span className="liveTourOpt__text">Turn Off</span>
                          </a>
                        ) : (
                          <a
                            className="liveTourOpt__box liveTourOpt__box-gray"
                            onClick={() => {
                              muteVideo(true);
                            }}
                          >
                            <span className="liveTourOpt__icon">
                              <i className="icon-video-slash"></i>
                            </span>
                            <span className="liveTourOpt__text">Turn On</span>
                          </a>
                        )}
                      </li>
                      <li>
                        {me.hasAudio ? (
                          <a
                            className="liveTourOpt__box liveTourOpt__box-gray"
                            onClick={() => {
                              muteAudio(false);
                            }}
                          >
                            <span className="liveTourOpt__icon">
                              <i className="icon-microphone"></i>
                            </span>
                            <span className="liveTourOpt__text">Mute</span>
                          </a>
                        ) : (
                          <a
                            className="liveTourOpt__box liveTourOpt__box-gray"
                            onClick={() => {
                              muteAudio(true);
                            }}
                          >
                            <span className="liveTourOpt__icon">
                              <i className="icon-microphone-slash"></i>
                            </span>
                            <span className="liveTourOpt__text">Unmute</span>
                          </a>
                        )}
                      </li>
                      <li>
                        <a
                          className="liveTourOpt__box liveTourOpt__box-red"
                          onClick={() => leaveCall(true)}
                        >
                          <span className="liveTourOpt__icon">
                            <i className="icon-logout"></i>
                          </span>
                          <span className="liveTourOpt__text">Leave Tour</span>
                        </a>
                      </li>
                    </ul>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUnsupportedBrowserAlertForParticipant<Props>(FullVideo);
