import React, { useEffect, useState } from 'react';
import { Building } from '../../../../state/listing/listing.types';
import { mergeBuildingHours, mergeWithAnd } from '../../listing.component';
import { SEPARATORS } from '../../../../common/constants';

interface Props {
  info: Building;
}
const BuildingDetail: React.FC<Props> = props => {
  const [building, setBuilding] = useState({} as Building);
  useEffect(() => setBuilding(props.info), [props.info]);

  return (
    <div className="row row--space-10">
      <div className="col-12 col-sm-6">
        {building.location && (
          <div className="spec--data">
            <label className="small__heading">Location</label>
            <p className="small__paragraph">{building.location}</p>
          </div>
        )}

        {building.yearBuilt && building.yearBuilt > 0 && (
          <div className="spec--data">
            <label className="small__heading">Year Built</label>
            <p className="small__paragraph">{building.yearBuilt}</p>
          </div>
        )}

        {building.yearRenovated && building.yearRenovated > 0 && (
          <div className="spec--data">
            <label className="small__heading">Year Renovated</label>
            <p className="small__paragraph">{building.yearRenovated}</p>
          </div>
        )}

        {building.floorsCount && building.floorsCount > 0 && (
          <div className="spec--data">
            <label className="small__heading">Number Of Floors</label>
            <p className="small__paragraph">{building.floorsCount.toLocaleString()}</p>
          </div>
        )}

        {building.buildingSize && building.buildingSize > 0 && (
          <div className="spec--data">
            <label className="small__heading">Building Size</label>
            <p className="small__paragraph">{`${building.buildingSize.toLocaleString()} SF`}</p>
          </div>
        )}

        {building.averageFloor && building.averageFloor > 0 && (
          <div className="spec--data">
            <label className="small__heading">Average Floor</label>
            <p className="small__paragraph">{`${building.averageFloor.toLocaleString()} SF`}</p>
          </div>
        )}

        {building.parkingRatio &&
          building.parkingRatio.length > 0 &&
          building.parkingRatio.split(':').length === 2 && (
            <div className="spec--data">
              <label className="small__heading">Parking Ratio</label>
              <p className="small__paragraph">
                {`${building.parkingRatio
                  .split(':')[0]
                  .toLocaleString()} : ${building.parkingRatio.split(':')[1].toLocaleString()}
                 SF`}
              </p>
            </div>
          )}

        {building.columnSpacingHeight &&
          building.columnSpacingWidth &&
          building.columnSpacingHeight > 0 &&
          building.columnSpacingWidth > 0 && (
            <div className="spec--data">
              <label className="small__heading">Column Spacing</label>
              <p className="small__paragraph">
                {`${building.columnSpacingWidth.toLocaleString()}' X ${building.columnSpacingHeight.toLocaleString()}'`}
              </p>
            </div>
          )}

        {building.slabToSlabHeight && building.slabToSlabHeight.length > 0 && (
          <div className="spec--data">
            <label className="small__heading">Slab To Slab Height</label>
            <p className="small__paragraph">{building.slabToSlabHeight}</p>
          </div>
        )}
      </div>
      <div className="col-12 col-sm-6">
        {building.passengerElevators && building.passengerElevators.length > 0 && (
          <div className="spec--data">
            <label className="small__heading">Passenger Elevators</label>
            <p className="small__paragraph">{building.passengerElevators}</p>
          </div>
        )}

        {building.freightElevators && building.freightElevators.length > 0 && (
          <div className="spec--data">
            <label className="small__heading">Freight Elevators</label>
            <p className="small__paragraph">{building.freightElevators}</p>
          </div>
        )}

        {building.telecomProviders && building.telecomProviders.length > 0 && (
          <div className="spec--data">
            <label className="small__heading">Telecom / Data Providers</label>
            <p className="small__paragraph">{building.telecomProviders}</p>
          </div>
        )}

        {building.buildingHours && building.buildingHours.length > 0 && (
          <div className="spec--data">
            <label className="small__heading">Building Hours</label>
            <p className="small__paragraph">
              {mergeBuildingHours(
                building.buildingHours,
                true,
                SEPARATORS.SEMI_COLON + SEPARATORS.SPACE,
              )}
            </p>
          </div>
        )}

        {building.energyStarCertification && building.energyStarCertification.length > 0 && (
          <div className="spec--data">
            <label className="small__heading">Energy Star Certification</label>
            <p className="small__paragraph">{building.energyStarCertification}</p>
          </div>
        )}

        {building.leedCertification && building.leedCertification.length > 0 && (
          <div className="spec--data">
            <label className="small__heading">Leed Certification</label>
            <p className="small__paragraph">{building.leedCertification}</p>
          </div>
        )}

        {building.buildingAmenities && building.buildingAmenities.length > 0 && (
          <div className="spec--data">
            <label className="small__heading">Building Amenities</label>
            <p className="small__paragraph">{mergeWithAnd(building.buildingAmenities)}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuildingDetail;
