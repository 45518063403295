import React from 'react';
import ReactPlayer from 'react-player';

interface Props {
  videoUrls: string[];
}

const VideoSearchResults: React.FC<Props> = (props: Props) => {
  const { videoUrls } = props;
  return (
    <div className="searchResults--block">
      <h5 className="heading__sm heading__sm-black font-semibold">Videos</h5>
      <div className="mt-4">
        <div className="video--search block-flex">
          <div className="block--search-row videos--search-row block--scroll">
            {videoUrls.map((videoUrl, index) => (
              <div className="block--search-col" key={index}>
                <div className="video--box">
                  <ReactPlayer
                    url={videoUrl}
                    width="100%"
                    height="100%"
                    config={{
                      wistia: {
                        options: {
                          playerColor: '#15235C',
                        },
                      },
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VideoSearchResults;
