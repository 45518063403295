import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import SelectedTourListings from './selectedListings';
import ListingSearchBox from './searchBox';
import ListingBox from './listingBox';
import { RecentListing } from '../../../../state/listing/listing.types';
import { TourListingsSelected } from '../../../../state/subdomain/tour/tour.types';
import { debounce, xor } from 'lodash';
import { GetStateInterface } from '../../../../types/GetStateInterface';
import { useChangeListingTour } from '../../../../state/tour/tour.hook';
import { useParams } from 'react-router';
import { showNotification } from '../../../../utils/misc.utils';
import { errorExtractor } from '../../../../utils/error.utils';
import { changeTourListingAction } from '../../../../state/tour/tour.action';
import SmallLoader from '../../../common/small.loader';
import { GetTourInfo } from '../../../../state/tour/tour.types';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';

interface Props {
  onSearch: (e: string) => void;
  data: RecentListing[];
  selected: TourListingsSelected[];
  onSelect: (e: RecentListing) => void;
  onRemove: (e: string) => void;
  loading: boolean;
  firstFetch: boolean;
  listingRes: GetStateInterface;
  fetchMore: () => void;
  close: () => void;
}
const AddListingPanel: React.FC<Props> = props => {
  const {
    onSearch,
    data,
    selected,
    onSelect,
    onRemove,
    loading,
    firstFetch,
    listingRes,
    fetchMore,
    close,
  } = props;

  const { tourId } = useParams();
  const tourInfo = useSelector((state: AppStateInterface) => state.tour.tourInfo.data);
  const { res: changeRes, changeListing } = useChangeListingTour();
  const [disableSubmit, handleDisable] = useState(true);

  useEffect(() => {
    const container = document.getElementById('tourListingPanel');
    if (container) {
      container.onscroll = debounce(() => {
        if (
          listingRes.error ||
          listingRes.loading ||
          (listingRes.data && !listingRes?.data?.hasMore)
        )
          return <></>;
        if (
          Math.abs(
            Math.floor(container.scrollHeight - container.scrollTop) - container.clientHeight,
          ) < Math.floor(container.clientHeight * 0.2)
        ) {
          fetchMore();
        }
      }, 100);
    }
  });
  useEffect(() => {
    if (!changeRes.loading && changeRes.hasData) {
      if (changeRes.error)
        showNotification('error', 'Add Listing Error', errorExtractor(changeRes.error));
      else {
        if (changeRes.data) {
          const newTourData = changeRes.data as GetTourInfo;
          changeTourListingAction(newTourData);
          close();
        }
      }
    }
  }, [changeRes]);
  useEffect(() => {
    if (tourInfo?.listings && selected?.length) {
      const selectedIds = selected.map(e => e.id);
      const alreadyIds = tourInfo.listings.map(list => list.listing._id);
      handleDisable(
        selectedIds.length === alreadyIds.length && xor(selectedIds, alreadyIds).length === 0,
      );
    }
  }, [tourInfo, selected]);

  const submitData = () => {
    const ids = selected.map(e => e.id);
    changeListing(tourId, ids);
  };

  return (
    <div className="componentEdit--scrollable">
      <p className="para__md-gray mb-4">Select as many as you want.</p>
      <ListingSearchBox onSubmit={onSearch} />
      <div className="badge--row badge--row-alt d-flex align-items-center flex-wrap">
        {selected && <SelectedTourListings data={selected} onRemove={onRemove} />}
      </div>
      <ListingBox
        data={data}
        selected={selected}
        onSelect={onSelect}
        loading={loading}
        firstFetch={firstFetch}
        fetchMore={fetchMore}
      />
      <div className="tourEdit__btn-canvas text-center text-sm-right mt-4">
        <Button
          type="button"
          className="admin__button admin__button-sm admin__button-radius"
          onClick={submitData}
          disabled={changeRes.loading || selected.length === 0 || disableSubmit}
        >
          {changeRes.loading ? (
            <React.Fragment>
              Adding <SmallLoader />
            </React.Fragment>
          ) : (
            <React.Fragment>
              Add Listings <i className="icon-plus ml-2"></i>
            </React.Fragment>
          )}
        </Button>
      </div>
    </div>
  );
};

export default AddListingPanel;
