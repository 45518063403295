import React from 'react';

const AboutStrive: React.FC = () => {
  return (
    <div className="strive--component" id={'strive'}>
      <div className="container container--expand">
        <div className="strive--content">
          <div className="row text-center justify-content-center mx-0">
            <div className="col-12 col-md-10 col-xl-9 px-0">
              <h2 className="heading__component heading__component-blue mb-4 pb-3">
                What we strive for
              </h2>
              <p className="landing__para">
                HomeTour gives landlords and developers the ability to conduct first time viewings
                and follow up tours with prospective tenants for a wide range of spaces ranging from
                spec-suites to entire available floors. The application limits the travel time
                needed for brokers and prospects who wish to visit multiple spaces, which will make
                it a convenient tool long after the current crisis subsides.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutStrive;
