import React from 'react';
import { BrowserInterface } from '../../../state/subdomain/tour/tour.types';
import { ALLOWED_BROWSERS_FOR_HOST } from '../../../common/constants';
import UnsupportedBrowserAlertForHost from './unsupportedBrowserAlertForHost';

interface Props {
  [key: string]: any;
  leaveCall: () => void;
  browser: BrowserInterface | null;
}

const withUnsupportedBrowserAlertForHost = <T extends Props>(WrappedComponent: React.FC<T>) => {
  return function(props: T) {
    const { leaveCall, browser } = props;

    if (browser?.name && !ALLOWED_BROWSERS_FOR_HOST.includes(browser.name)) {
      leaveCall();
      return <UnsupportedBrowserAlertForHost />;
    } else {
      return <WrappedComponent {...props} />;
    }
  };
};

export default withUnsupportedBrowserAlertForHost;
