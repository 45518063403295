import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';

interface Props {
  handleClose: () => void;
  handleSubmit: (text: string) => void;
  handleCancel: () => void;
}

const TourListingSearch: React.FC<Props> = (props: Props) => {
  const { handleCancel, handleSubmit, handleClose } = props;
  const [searchText, handleSearchText] = useState('');

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (searchText.length) {
      handleSubmit(searchText);
      handleCancel();
    }
  };

  return (
    <div className="liveTour--listing liveTour--listing-search">
      <div className="container-fluid">
        <div className="ls--top">
          <div className="ls--head ls--left">
            <span className="ls__icon">
              <i className="icon-building"></i>
            </span>
            <h3 className="ls__heading">Listings</h3>
          </div>
          <a className="ls__cross" onClick={handleClose}>
            <i className="icon-cross"></i>
          </a>
        </div>
        <div className="ls--bottom">
          <Form className="w-100" onSubmit={onSubmit}>
            <div className="row justify-content-center">
              <div className="col-12 col-md-10 col-lg-6">
                <div className="search__block">
                  <span className="ls__search-icon">
                    <i className="icon-search"></i>
                  </span>
                  <div className="w-100">
                    <Form.Group className="listing--group">
                      <Form.Control
                        type="text"
                        placeholder="Search by address or location"
                        onChange={(e: any) => handleSearchText(e.target.value)}
                      />
                      <Button
                        className="admin__button enter__btn"
                        disabled={!searchText.length}
                        type={'submit'}
                      >
                        Enter
                      </Button>
                    </Form.Group>
                    <div className="text-right" onClick={handleCancel}>
                      <a className="cancel__link">Cancel</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default TourListingSearch;
