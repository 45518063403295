import { AxiosPromise, CancelToken } from 'axios';
import api from '../../../utils/api.utils';
import { ExistingSubMarketListResponseInterface } from './submarket.types';

export const getExistingSubMarketsListAPI = (
  companyId: string,
  ct?: CancelToken,
): AxiosPromise<Array<ExistingSubMarketListResponseInterface>> => {
  return api.get(`/listing/group/submarkets/${companyId}`, {
    cancelToken: ct,
  });
};
