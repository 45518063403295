import React, { useState, useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import {
  UploadMediaInterface,
  UploadedMediaInterface,
} from '../../../../../state/media/media.types';
import { DOC_LIMIT } from '../../../../../common/constants';
import { useUploadMedia } from '../../../../../state/media/media.hook';
import ListingMediaTile from '../../../../media/listingTile';
import { useDropzone } from 'react-dropzone';

interface Props {
  handleClose: () => void;
  handleChanges: (docs: UploadedMediaInterface[]) => void;
  alreadyFiles: number;
}
interface UploadableDocs extends UploadMediaInterface {
  uploaded: boolean;
  error: boolean;
  uploading: number;
}
const mediaInitial: UploadableDocs = {
  name: '',
  data: {},
  dataView: {},
  uploaded: false,
  error: false,
  uploading: 0,
};

const UploadDocs: React.FC<Props> = props => {
  const { handleClose, handleChanges, alreadyFiles } = props;

  const [oldMedia, setOldMedia] = useState([] as UploadedMediaInterface[]);
  const [newMedia, setNewMedia] = useState([] as UploadableDocs[]);
  const [noOfImageError, setImageError] = useState('');
  const [processing, setProcessing] = useState(0);
  const [processed, setProcessed] = useState(0);

  const { progress: progressBar, res: uploadRes, uploadSingleMedia } = useUploadMedia();

  useEffect(() => {
    if (uploadRes.hasData && !uploadRes.loading) {
      const docs = newMedia;
      const data = uploadRes.data as UploadedMediaInterface;
      const doc = docs.find(doc => doc.name === data.name);
      if (doc) {
        if (uploadRes.error) doc.error = true;
        else if (uploadRes.data) {
          setOldMedia([...oldMedia, data]);
          doc.uploaded = true;
        }
        setNewMedia(docs);
      }
      setProcessed(processed + 1);
    }
  }, [uploadRes]);
  useEffect(() => {
    const images = newMedia;
    const fill = progressBar.progressBar;
    const image = newMedia.find(image => image.name === progressBar.name);
    if (image) image.uploading = fill === 100 ? 99 : progressBar.progressBar;
    setNewMedia(images);
  }, [progressBar]);

  const removeDoc = (name: string) => {
    const newDocs = newMedia;
    const oldDocs = oldMedia;
    const newIndex = newDocs.findIndex(doc => doc.name === name);
    const oldIndex = oldDocs.findIndex(doc => doc.name === name);
    if (newIndex > -1) newDocs.splice(newIndex, 1);
    if (oldIndex > -1) oldDocs.splice(oldIndex, 1);
    setNewMedia([...newDocs]);
    setOldMedia([...oldDocs]);
    setProcessing(processing - 1);
  };

  const fileUpload = (e: any) => {
    if (noOfImageError.length) setImageError('');
    if (e.length) {
      const uploadingLimit = DOC_LIMIT - (newMedia.length + alreadyFiles);
      const maxLimit = Math.min(uploadingLimit, e.length);
      if (e.length > uploadingLimit) setImageError(`Only ${DOC_LIMIT} files are allowed to upload`);
      const files = [] as UploadableDocs[];
      for (let i = 0; i < maxLimit; i++) {
        const file = e[i];
        if (file) {
          const reader = new FileReader();
          reader.onload = (a: any) => {
            files.push({ ...mediaInitial, data: file, dataView: a.target.result, name: file.name });
            uploadSingleMedia({ data: file, name: file.name }, true);
            if (files.length === maxLimit) {
              setNewMedia([...newMedia, ...files]);
              setProcessing(processing + files.length);
            }
          };
          reader.readAsDataURL(file);
        }
      }
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: fileUpload,
    accept: '.pdf',
    multiple: true,
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    handleChanges(oldMedia);
    handleClose();
  };

  const closeModal = () => {
    if (processed < processing) {
      const response = confirm(
        'Some documents are under processing. Do you want to leave this page ?',
      );
      if (response) handleClose();
    } else handleClose();
  };

  const disableSubmit = oldMedia.length === 0 || processing > processed;
  return (
    <React.Fragment>
      <Modal
        show={true}
        onHide={closeModal}
        animation={false}
        centered
        scrollable
        className="modal--uploadPhotos"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">Upload PDFs</Modal.Title>
          <Button className="modal__cross" onClick={closeModal}>
            <i className="icon-cross"></i>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <div className="row uploadPhoto--component row--space-10">
              <div className={`col-12 col-md-${newMedia.length > 0 ? `6` : `12`}`}>
                <label className="custom__file-modal" {...getRootProps()} onClick={e => e}>
                  <input {...getInputProps()} value={''} />
                  <span className="cf__modal-heading">
                    {isDragActive
                      ? 'Release to drop the files here'
                      : 'Drag file here, or click to browse'}
                  </span>
                  <p className="cf__modal-para">(Only .pdf files)</p>
                </label>
                {noOfImageError.length > 0 && <label>{noOfImageError}</label>}
              </div>
              <div className={`col-12 col-md-6`}>
                <div className="uploadPhoto--block">
                  {newMedia.length > 0 &&
                    newMedia.map((file, index) => (
                      <ListingMediaTile
                        url={'/images/pdf.png'}
                        name={file.name}
                        error={file.error}
                        fill={file.uploaded}
                        filling={file.uploading}
                        remove={() => removeDoc(file.name)}
                        key={index}
                      />
                    ))}
                </div>
              </div>
            </div>
            <div className="text-sm-right modal--btn-canvas mt-4">
              <Button
                type="button"
                className="admin__button underline__button"
                onClick={handleSubmit}
                disabled={disableSubmit}
              >
                Upload Dcoument(s)
                <i className="icon-upload ml-2"></i>
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default UploadDocs;
