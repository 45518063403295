import React, { useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import useForm from 'react-hook-form';
import {
  ForgotPasswordFormFields,
  ForgotPasswordFormSchema,
} from '../../state/login/forms/login.form';
import { useForgotAndResetPassword } from '../../state/login/login.hook';
import SmallLoader from '../common/small.loader';
import { showNotification } from '../../utils/misc.utils';
import { errorExtractor } from '../../utils/error.utils';

const ForgotPasswordForm = () => {
  const { register, handleSubmit, errors, reset } = useForm({
    validationSchema: ForgotPasswordFormSchema,
  });
  const { res: forgotPasswordRes, forgotPassword } = useForgotAndResetPassword();

  useEffect(() => {
    if (!forgotPasswordRes.loading) {
      if (forgotPasswordRes.data) {
        reset();
        showNotification(
          'success',
          'Success',
          'An email has been sent to you. Please check your email for further instructions to reset your password',
        );
      }
      if (forgotPasswordRes.error) {
        showNotification('error', 'Error', errorExtractor(forgotPasswordRes.error));
      }
    }
  }, [forgotPasswordRes]);

  const onSubmit = (data: any, e: any) => {
    forgotPassword({ email: data.email });
  };

  return (
    <Form className="mt-sixty" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="forms--group">
        <Form.Control
          ref={register}
          type="email"
          placeholder="Email"
          name={ForgotPasswordFormFields.EMAIL}
          className={errors[ForgotPasswordFormFields.EMAIL] ? 'error' : ''}
        />
        {errors[ForgotPasswordFormFields.EMAIL] && (
          <span className="error__msg">{errors[ForgotPasswordFormFields.EMAIL]?.message}</span>
        )}
      </Form.Group>
      <div className="text-sm-right forms--btn-canvas forgotPassword--btn-canvas">
        <Button
          type="submit"
          className="app__button underline__button"
          disabled={forgotPasswordRes.loading}
        >
          {forgotPasswordRes.loading ? (
            <React.Fragment>
              Submitting <SmallLoader />
            </React.Fragment>
          ) : (
            <React.Fragment>
              Submit <i className="icon-user-right ml-3"></i>
            </React.Fragment>
          )}
        </Button>
      </div>
    </Form>
  );
};

export default ForgotPasswordForm;
