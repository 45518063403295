import { UploadedMediaInterface } from '../media/media.types';
import { MenuInterface } from '../subdomain/company/company.types';

export interface SettingsDataInterface {
  avatar: string;
  firstName: string;
  lastName: string;
  name: string;
  domain: string;
  officialWebsite: string;
  officialAddress: string;
  officialPhone: string;
  whiteIcon: UploadedMediaInterface | null;
  darkIcon: UploadedMediaInterface | null;
  whiteLogo: UploadedMediaInterface | null;
  darkLogo: UploadedMediaInterface | null;
  emailLogo: UploadedMediaInterface | null;
  primaryColor: string;
  secondaryColor: string;
  contactEmail: string;
}

export interface SettingsErrorInterface {
  avatar: string;
  firstName: string;
  lastName: string;
  name: string;
  domain: string;
  officialWebsite: string;
  officialAddress: string;
  officialPhone: string;
  whiteIcon: string;
  darkIcon: string;
  whiteLogo: string;
  darkLogo: string;
  emailLogo: string;
  primaryColor: string;
  secondaryColor: string;
  contactEmail: string;
}

export interface SettingRequiredFieldsInterface {
  avatar: boolean;
  firstName: boolean;
  lastName: boolean;
  name: boolean;
  domain: boolean;
  officialWebsite: boolean;
  officialAddress: boolean;
  officialPhone: boolean;
  whiteIcon: boolean;
  darkIcon: boolean;
  whiteLogo: boolean;
  emailLogo: boolean;
  darkLogo: boolean;
  primaryColor: boolean;
  secondaryColor: boolean;
  contactEmail: boolean;
}

export interface PutUserPayloadInterface {
  avatar: string;
  firstName: string;
  lastName: string;
}

export interface PutCompanyPayloadInterface {
  name: string;
  domain: string;
  settings: {
    primaryColor: string;
    secondaryColor: string;
    whiteIcon: UploadedMediaInterface;
    darkIcon: UploadedMediaInterface;
    whiteLogo: UploadedMediaInterface;
    darkLogo: UploadedMediaInterface;
    emailLogo: UploadedMediaInterface;
    officialAddress: string;
    officialPhone: string;
    officialWebsite: string;
    contactEmail: string;
    menu?: MenuInterface[];
    adminBackGroundImageUrl?: UploadedMediaInterface;
    suddomainLandingImageUrl?: UploadedMediaInterface;
    loaderColor?: string;
    careersUrl?: string | null;
    termsUrl?: string | null;
    privacyUrl?: string | null;
  };
}

export enum SettingActions {
  PUT_USER = 'PUT_USER',
  PUT_COMPANY = 'PUT_COMPANY',
}
