import React, { useState, useEffect } from 'react';
import { GetListingObject } from '../../../../state/listing/listing.types';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import { AttachmentLightBox } from '../../../lightbox';
import { GALLERY_IMAGES } from '../../../../common/constants';
import { Carousel } from 'react-bootstrap';
import { UploadedMediaInterface } from '../../../../state/media/media.types';

interface Props {
  info: GetListingObject;
  tourBox?: (e: number) => void;
}
const ListingGallery: React.FC<Props> = props => {
  const [images, updateImages] = useState([] as UploadedMediaInterface[]);
  const [openLightbox, setLightBox] = useState(false);
  const [index, setIndex] = useState(0);
  const { tourBox } = props;
  useEffect(() => {
    if (props.info.media) {
      const files = props.info.media;
      const plans = props.info.floorPlans || [];
      const render = props.info.renderings || [];
      files.sort((x, y) => (x.isPrimary === y.isPrimary ? 0 : x.isPrimary ? -1 : 1));
      updateImages([...files, ...plans, ...render]);
    }
  }, [props.info]);

  const openImage = (index: number) => {
    if (tourBox) tourBox(index);
    else {
      setIndex(index);
      setLightBox(true);
    }
  };

  const imageTile = (image: UploadedMediaInterface, index: number): JSX.Element => {
    return (
      <div
        className="col col-6 col-md-4 col-lg-6 col-xl-4"
        key={index}
        onClick={() => openImage(index)}
      >
        <div className="gallery--box">
          <img src={compileAvatarUrl(image.url)} alt={image.name} />
        </div>
      </div>
    );
  };
  const makeGallery = () => {
    const output = [];
    for (let i = 0; i < images.length; i += GALLERY_IMAGES) {
      const imageParts = images.slice(i, i + GALLERY_IMAGES);
      output.push(
        <Carousel.Item key={i}>
          <div className="row row--space-10">
            {imageParts.map((image, index) => imageTile(image, i + index))}
          </div>
        </Carousel.Item>,
      );
    }
    return output;
  };

  const needCarousel = images.length > GALLERY_IMAGES;
  return (
    <div className="row row--space-10">
      {images.length > 0 && (
        <React.Fragment>
          {needCarousel && (
            <Carousel className="gallery--carousel" interval={null}>
              {makeGallery()}
            </Carousel>
          )}
          {!needCarousel && images.map((image, index) => imageTile(image, index))}
        </React.Fragment>
      )}
      {openLightbox && (
        <AttachmentLightBox data={images} index={index} close={() => setLightBox(false)} />
      )}
    </div>
  );
};

export default ListingGallery;
