import React from 'react';
import { useHistory } from 'react-router';

const ListingBackButton: React.FC = () => {
  const history = useHistory();

  const goBack = () => {
    if (history.action === 'PUSH') {
      history.goBack();
    } else {
      history.push('/');
    }
  };

  return (
    <a className="back__btn" onClick={goBack}>
      <span className="back__btn-icon">
        <i className="icon-chevron-left"></i>
      </span>
      Back
    </a>
  );
};

export default ListingBackButton;
