import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { CommentsInterface } from '../../../../state/listing/listing.types';
import { getHumanizeTime } from '../../../../utils/misc.utils';
import { UploadedMediaInterface } from '../../../../state/media/media.types';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import { AttachmentLightBox } from '../../../lightbox';

interface Props {
  comments: CommentsInterface[];
}
const PrevReviewComments: React.FC<Props> = props => {
  const { comments } = props;
  const [lightBoxMedia, setLightBoxMedia] = useState<UploadedMediaInterface[]>([]);

  return (
    <React.Fragment>
      {comments?.map((comment: CommentsInterface) => (
        <div className="comment--attach comment--submission d-flex flex-column flex-md-row">
          <div className="voice--info mb-3 mb-md-0">
            <span className="voiceInfo__name">
              {comment?.commentBy
                ? `${comment.commentBy.firstName} ${comment.commentBy.lastName}`
                : 'Unknown'}
            </span>
            {comment?.videoTimestamp && (
              <span className="voiceInfo__time">{comment.videoTimestamp}</span>
            )}
          </div>
          <div className="ca--inner w-100">
            <div className="ca--group-main w-100 mb-3">
              <div className="ca--group">
                <p className="ca__para">{comment?.content?.text || ''}</p>
              </div>
              {comment?.isEdited && comment?.lastEditedBy && (
                <p className="edited__msg text-right mt-1">
                  Last Edited By {comment?.lastEditedBy?.firstName}
                  {comment?.updatedAt && (
                    <React.Fragment> ({getHumanizeTime(comment.updatedAt)})</React.Fragment>
                  )}
                </p>
              )}
            </div>
            <div
              className="ca--last d-flex flex-column flex-sm-row flex-lg-column flex-xl-row
         align-items-center justify-content-between mb-4 pb-1 mt-3"
            >
              {comment?.content?.media && (
                <div
                  className="ca--cover ca--cover-sm"
                  onClick={() => {
                    setLightBoxMedia([
                      (comment.content.media as unknown) as UploadedMediaInterface,
                    ]);
                  }}
                >
                  <div className="ca--cover-img">
                    <img src={compileAvatarUrl(comment.content.media.url)} alt="Img" />
                  </div>
                  <div className="ca--cover-name">{comment.content.media.name}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      {lightBoxMedia?.length > 0 && (
        <AttachmentLightBox
          data={lightBoxMedia}
          index={0}
          close={() => setLightBoxMedia([])}
          showTitles={true}
        />
      )}
    </React.Fragment>
  );
};

export default PrevReviewComments;
