import { getAllSubDomainListingsAPI, getSingleListingAPI } from './listing.api';
import {
  GetSubDomainListings,
  ListingActions,
  orderByTypes,
  sortByTypes,
  GetListingObject,
} from './listing.types';
import fetchActions from './../../../utils/state.utils';
import axios from 'axios';
import { cloneDeep } from 'lodash';

export const mergeNewDataIntoPrevious = (
  prev: Array<GetSubDomainListings>,
  next: Array<GetSubDomainListings>,
): Array<GetSubDomainListings> => {
  if (prev.length && next.length) {
    const mergedData: Array<GetSubDomainListings> = [...cloneDeep(prev)];
    for (const market of next) {
      const marketExistInPrevious = prev.find(
        (prevMarket: any) => prevMarket.subMarket === market.subMarket,
      );
      if (marketExistInPrevious) {
        const marketIndexInMergedData = mergedData.findIndex(
          (mergedMarket: any) => mergedMarket.subMarket === market.subMarket,
        );
        if (marketIndexInMergedData > -1) {
          mergedData[marketIndexInMergedData].listings = [
            ...marketExistInPrevious.listings,
            ...market.listings,
          ];
        }
      } else {
        mergedData.push(market);
      }
    }
    return mergedData;
  }
  if (prev.length && !next.length) {
    return prev;
  }
  if (!prev.length && next.length) {
    return next;
  }
  return [];
};

export const getAllListings = async (
  companyId: string,
  limit: number,
  skip: number,
  search?: string,
  sortBy?: sortByTypes,
  orderBy?: orderByTypes,
  subMarket?: string,
  prev: GetSubDomainListings[] = [],
) => {
  const actionName = ListingActions.SD_GET_ALL_LISTINGS;
  fetchActions.callCT(actionName);
  fetchActions.init(actionName);

  try {
    const res = await getAllSubDomainListingsAPI(
      companyId,
      limit,
      skip,
      search,
      sortBy,
      orderBy,
      subMarket,
      fetchActions.setCT(actionName),
    );
    if (prev.length) {
      fetchActions.success(actionName, {
        ...res.data,
        data: mergeNewDataIntoPrevious(prev, res.data.data),
      });
    } else {
      fetchActions.success(actionName, res.data);
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      fetchActions.failure(actionName, error);
    }
  }
};

export const clearAllListings = () => {
  fetchActions.dispatch({
    type: ListingActions.SD_GET_ALL_LISTINGS_CLEAR,
    payload: {},
  });
};

export const getSingleListing = async (listingId: string) => {
  const actionName = ListingActions.SD_GET_SINGLE_LISTING;

  fetchActions.callCT(actionName);
  fetchActions.init(actionName);

  try {
    const res = await getSingleListingAPI(listingId, fetchActions.setCT(actionName));
    fetchActions.success(actionName, res.data);
  } catch (error) {
    if (!axios.isCancel(error)) {
      fetchActions.failure(actionName, error);
    }
  }
};

export const clearSingleListing = () => {
  fetchActions.dispatch({
    type: ListingActions.SD_GET_SINGLE_LISTING_CLEAR,
    payload: {},
  });
};

export const changeTourListing = (listing: GetListingObject) => {
  fetchActions.dispatch({
    type: ListingActions.CHANGE_TOUR_LISTING,
    payload: listing,
  });
};
