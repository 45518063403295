import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../state';
import { COLOR_OPTIONS, USER_ROLES } from '../../common/constants';
import ProfileInfo from './profileInfo';
import CompanyInfo from './companyInfo';
import PlatformInfo from './platformInfo';
import HamBurger from '../leftSidebar/hamburger';
import { useHistory } from 'react-router';
import {
  SettingRequiredFieldsInterface,
  SettingsDataInterface,
  SettingsErrorInterface,
} from '../../state/setting/setting.types';
import { cleanObject } from '../../utils/apiValidatior';
import { showNotification, errorToString } from '../../utils/misc.utils';
import { useGetSubDomainStatus } from '../../state/subdomain/landing/landing.hook';
import { usePutCompany, usePutUser } from '../../state/setting/setting.hook';
import { UploadedMediaInterface } from '../../state/media/media.types';
import SmallLoader from '../common/small.loader';
import { errorExtractor } from '../../utils/error.utils';
import { getMe } from '../../state/me/me.action';

const SettingsForm: React.FC = () => {
  const history = useHistory();
  const [data, setData] = useState({} as SettingsDataInterface);
  const [errors, setErrors] = useState({} as SettingsErrorInterface);
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const required = me?.role === USER_ROLES.ADMIN;
  const requiredFields: SettingRequiredFieldsInterface = {
    avatar: true,
    firstName: true,
    lastName: true,
    name: required,
    domain: required,
    whiteIcon: required,
    darkIcon: required,
    whiteLogo: required,
    darkLogo: required,
    emailLogo: required,
    officialAddress: required,
    officialPhone: required,
    officialWebsite: required,
    primaryColor: required,
    secondaryColor: required,
    contactEmail: required,
  };
  const { res: subDomainAvailabilityRes, getSubDomainAvailability } = useGetSubDomainStatus();
  const { res: putUserRes, putUser } = usePutUser();
  const { res: putCompanyRes, putCompany } = usePutCompany();

  useEffect(() => {
    if (me?.role !== USER_ROLES.ADMIN && !putUserRes.loading && putUserRes.data) {
      showNotification('success', 'Success', 'Saved successfully');
      getMe();
    }
  }, [putUserRes]);

  useEffect(() => {
    if (!putUserRes.loading && !putCompanyRes.loading && putUserRes.data && putCompanyRes.data) {
      showNotification('success', 'Success', 'Saved successfully');
      getMe();
    }
    if (!putUserRes.loading && putUserRes.error) {
      showNotification('error', 'Error', errorExtractor(putUserRes.error));
    }
    if (!putCompanyRes.loading && putCompanyRes.error) {
      showNotification('error', 'Error', errorExtractor(putCompanyRes.error));
    }
  }, [putCompanyRes, putUserRes]);

  useEffect(() => {
    if (me) {
      setData({
        avatar: me.avatar,
        firstName: me.firstName,
        lastName: me.lastName,
        name: me?.company?.name || '',
        domain: me?.company?.domain || '',
        whiteIcon: me?.company?.settings?.whiteIcon || null,
        darkIcon: me?.company?.settings?.darkIcon || null,
        whiteLogo: me?.company?.settings?.whiteLogo || null,
        darkLogo: me?.company?.settings?.darkLogo || null,
        emailLogo: me?.company?.settings?.emailLogo || null,
        officialAddress: me?.company?.settings?.officialAddress || '',
        officialPhone: me?.company?.settings?.officialPhone || '',
        officialWebsite: me?.company?.settings?.officialWebsite || '',
        contactEmail: me?.company?.settings?.contactEmail || '',
        primaryColor: me?.company?.settings?.primaryColor
          ? COLOR_OPTIONS.find(color => color.value === me?.company?.settings.primaryColor)
            ? me.company.settings.primaryColor
            : ''
          : '',
        secondaryColor: me?.company?.settings?.secondaryColor
          ? COLOR_OPTIONS.find(color => color.value === me?.company?.settings.secondaryColor)
            ? me.company.settings.secondaryColor
            : ''
          : '',
      });
    }
  }, [me]);

  const setSettingsData = (field: string, value: any) => {
    setData(prevState => ({
      ...prevState,
      [field]: value,
    }));
  };

  const setSettingsError = (field: string, value: string) => {
    setErrors(prevState => ({ ...prevState, [field]: value }));
  };

  const goBack = () => {
    if (history.action === 'PUSH') {
      history.goBack();
    } else {
      history.push('/dashboard');
    }
  };

  const checkRequiredFields = () => {
    let error = false;
    for (const key in requiredFields) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (key && !data[key]) {
        const errorMessage =
          key === 'avatar' ? 'Profile photo is required' : 'This field is required';
        setSettingsError(key, errorMessage);
        error = true;
      }
    }
    if (error) {
      return error;
    } else {
      const cleanedErrors = cleanObject(errors);
      return !!Object.keys(cleanedErrors).length;
    }
  };

  useEffect(() => {
    if (
      !subDomainAvailabilityRes.loading &&
      subDomainAvailabilityRes.hasData &&
      !subDomainAvailabilityRes.data
    ) {
      setSettingsError('domain', 'Not available. Please choose another');
    }
    if (!subDomainAvailabilityRes.loading && subDomainAvailabilityRes.data) {
      putUser({
        avatar: data.avatar,
        firstName: data.firstName,
        lastName: data.lastName,
      });
      putCompany(me?.company?._id as string, {
        name: data.name,
        domain: data.domain,
        settings: {
          whiteIcon: data.whiteIcon as UploadedMediaInterface,
          darkIcon: data.darkIcon as UploadedMediaInterface,
          whiteLogo: data.whiteLogo as UploadedMediaInterface,
          darkLogo: data.darkLogo as UploadedMediaInterface,
          emailLogo: data.emailLogo as UploadedMediaInterface,
          officialAddress: data.officialAddress,
          officialPhone: data.officialPhone,
          officialWebsite: data.officialWebsite,
          primaryColor: data.primaryColor,
          secondaryColor: data.secondaryColor,
          contactEmail: data.contactEmail,
          menu: me?.company?.settings?.menu || [],
          careersUrl: me?.company?.settings?.careersUrl || null,
          privacyUrl: me?.company?.settings?.termsUrl || null,
          termsUrl: me?.company?.settings?.termsUrl || null,
        },
      });
    }
  }, [subDomainAvailabilityRes]);

  const onSave = (e: any) => {
    e.preventDefault();
    if (checkRequiredFields()) {
      showNotification('error', 'Error', errorToString(errors));
      return;
    }
    if (me?.role === USER_ROLES.ADMIN) {
      getSubDomainAvailability(data.domain);
    } else {
      putUser({
        avatar: data.avatar,
        lastName: data.lastName,
        firstName: data.firstName,
      });
    }
  };

  return (
    <div className="step--content step--content-1">
      <div className="dashboard--opt">
        <HamBurger className="menu__toggle" />
      </div>
      <div className="dashboard--head editListing--head">
        <h3 className="dashboard__heading d-flex align-items-center">Settings</h3>
        <div className="editListing--head-btn">
          <a className="outline__button underline__button sm__button" onClick={goBack}>
            Cancel
          </a>
          <a className="admin__button underline__button sm__button" onClick={onSave}>
            {putUserRes.loading || putCompanyRes.loading || subDomainAvailabilityRes.loading
              ? 'Saving'
              : 'Save'}
            {!putUserRes.loading && !putCompanyRes.loading && !subDomainAvailabilityRes.loading && (
              <span className="ml-2 saveBtn__icon">
                <i className="icon-save"></i>
              </span>
            )}
            {(putUserRes.loading || putCompanyRes.loading || subDomainAvailabilityRes.loading) && (
              <SmallLoader />
            )}
          </a>
        </div>
      </div>
      <Form>
        <ProfileInfo
          data={data}
          setData={setData}
          setSettingsData={setSettingsData}
          errors={errors}
          setErrors={setErrors}
          setSettingsError={setSettingsError}
        />
        {me?.role === USER_ROLES.ADMIN && (
          <React.Fragment>
            <CompanyInfo
              data={data}
              setData={setData}
              setSettingsData={setSettingsData}
              errors={errors}
              setErrors={setErrors}
              setSettingsError={setSettingsError}
            />
            <PlatformInfo
              data={data}
              setData={setData}
              setSettingsData={setSettingsData}
              errors={errors}
              setErrors={setErrors}
              setSettingsError={setSettingsError}
            />
          </React.Fragment>
        )}
      </Form>
    </div>
  );
};

export default SettingsForm;
