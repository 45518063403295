import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
import {
  RoomListingsInterface,
  TourOrderBy,
  TourSortAndOrder,
  TourSortBy,
} from '../../../state/tour/tour.types';
import { debounce } from 'lodash';
import SubMarketFilter from '../../common/submarket.filter';
import TourFilters from './filters/filters';
import TourSearch from './filters/search';
import {
  DEFAULT_LISTING_COUNT_IN_TOUR,
  DEFAULT_TOUR_ORDER_BY,
  DEFAULT_TOUR_SORT_BY,
  SEPARATORS,
  TOUR_TYPES,
} from '../../../common/constants';
import { useGetSubMarketsList } from '../../../state/submarket/submarket.hook';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import HamBurger from '../../leftSidebar/hamburger';
import { useGetAllTours } from '../../../state/tour/tour.hook';
import { checkIfNumber, copyTourLinkToClipboard } from '../../../utils/misc.utils';
import qs from 'query-string';
import TourBlockShimmer from './loading';

const DEFAULT_SORT_AND_ORDER = {
  sortBy: DEFAULT_TOUR_SORT_BY,
  orderBy: DEFAULT_TOUR_ORDER_BY,
};

const ViewTours: React.FC = () => {
  const { type } = useParams();
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const meLoading = useSelector((state: AppStateInterface) => state.me.me.loading);
  const [search, setSearch] = useState('');
  const [showCount, setShowCount] = useState(DEFAULT_LISTING_COUNT_IN_TOUR);
  const [subMarket, setSubMarket] = useState('');
  const [sortAndOrder, setSortAndOrder] = useState<TourSortAndOrder>(DEFAULT_SORT_AND_ORDER);
  const [scrolled, setScrolled] = useState(false);
  const [firstFetch, setFirstFetch] = useState(true);
  const { res: subMarketsListResponse, getSubMarketsList } = useGetSubMarketsList();
  const { res: allToursResponse, getAllTours } = useGetAllTours();
  const { loading, data: tours, hasData, error } = allToursResponse;

  useEffect(() => {
    setSearch(
      decodeURIComponent(
        Array.isArray(queryParams?.search) && queryParams?.search?.length
          ? queryParams?.search[0]
          : typeof queryParams?.search === 'string'
          ? queryParams.search
          : '',
      ),
    );
    setSubMarket('');
    setSortAndOrder(DEFAULT_SORT_AND_ORDER);
  }, [type]);

  useEffect(() => {
    setFirstFetch(true);
    getAllTours({
      search,
      subMarket,
      ...sortAndOrder,
      active: type !== 'history' ? 1 : 0,
    });
  }, [type, search, subMarket, sortAndOrder]);

  useEffect(() => {
    const content = document.getElementById('content');
    const contentBody = document.getElementById('contentBody');
    if (content && contentBody) {
      const callback = () => {
        const header = document.getElementById('header');
        if (header && contentBody) {
          if (
            window.pageYOffset ||
            content.scrollTop >
              header.scrollHeight +
                parseFloat(window.getComputedStyle(content).getPropertyValue('padding-top'))
          ) {
            setScrolled(true);
            contentBody.scrollTop = 1;
          }
          if (contentBody.scrollTop === 0) {
            setScrolled(false);
          }
        }
      };
      const contentBodyCallback = () => {
        callback();
        if (error || loading || (tours && !tours?.hasMore)) return <></>;

        if (
          Math.abs(
            Math.floor(contentBody.scrollHeight - contentBody.scrollTop) - contentBody.clientHeight,
          ) < Math.floor(contentBody.clientHeight * 0.2) &&
          !loading
        ) {
          setFirstFetch(false);
          getAllTours(
            {
              subMarket,
              search,
              skip: tours?.skip || 0,
              ...sortAndOrder,
              active: type !== 'history' ? 1 : 0,
            },
            tours?.data,
          );
        }
      };
      content.onscroll = debounce(callback, 10);
      contentBody.onscroll = debounce(contentBodyCallback, 10);
    }
  });

  useEffect(() => {
    if (me?.company?._id) {
      getSubMarketsList(me.company._id);
    }
  }, [me]);

  const onSearchChange = debounce((value: string) => {
    if (queryParams && queryParams.search && !value) {
      history.push(`/tours/${type}`);
    }
    setSearch(value);
  }, 300);

  const onFilterSelect = (selectedValue: string) => {
    const value = selectedValue.split(SEPARATORS.SLASH);
    if (value.length === 2) {
      setSortAndOrder({
        sortBy: value[0] as TourSortBy,
        orderBy: value[1] as TourOrderBy,
      });
    }
  };

  return (
    <div
      className={`dashboard--content dashboard--content-new ${scrolled ? 'dashboard--sticky' : ''}`}
      id="content"
    >
      <div className="dashboard--off">
        <div className="dashboard--canvas">
          <div className="dashboard--opt">
            <HamBurger />
          </div>
          {scrolled ? (
            <div className="white--rounded-box dashboard--bar mb-20" id="header">
              <div className="row row--space-10">
                <div className="col col-12 col-md-4">
                  <TourSearch setSearch={onSearchChange} defaultSearch={search} />
                </div>
                <div className="col col-12 col-md-4">
                  <SubMarketFilter
                    subMarket={subMarket}
                    setSubMarket={setSubMarket}
                    fetchedResponse={subMarketsListResponse}
                  />
                </div>
                <div className="col col-12 col-md-4">
                  <TourFilters
                    onSelect={onFilterSelect}
                    sortBy={sortAndOrder?.sortBy}
                    orderBy={sortAndOrder?.orderBy}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="white--rounded-box dashboard--bar mb-20" id="header">
              <div className="row row--space-10 align-items-md-center">
                <div className="col col-12 col-lg-4 col-xl-5">
                  <h3 className="dashboard__heading font-semibold mb-0">
                    {type === 'history' ? 'History of Tours' : 'Active Tours'}
                  </h3>
                </div>
                {!(
                  !search &&
                  !subMarket &&
                  sortAndOrder?.orderBy === DEFAULT_TOUR_ORDER_BY &&
                  sortAndOrder?.sortBy === DEFAULT_TOUR_SORT_BY &&
                  tours?.data?.length === 0
                ) && (
                  <React.Fragment>
                    {!meLoading ? (
                      <div className="col col-12 col-lg-8 col-xl-7">
                        <div className="dashbaord--opt">
                          <TourSearch setSearch={onSearchChange} defaultSearch={search} />
                          <Link
                            className="admin__button admin__button-sm button__radius"
                            to="/tour/create"
                          >
                            Create Tour
                            <i className="icon-tour ml-2"></i>
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div className="col col-12 col-lg-8 col-xl-7">
                        <div className="dashbaord--opt">
                          <div className="search--shimmer">
                            <span className="searchShimmer__icon animate--gray"></span>
                            <span className="searchShimmer__text animate--gray"></span>
                          </div>
                          <span className="button__shimmer button__shimmer-sm">
                            <span className="button__shimmer-text animate--gray"></span>
                            <span className="button__shimmer-icon animate--gray"></span>
                          </span>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          )}
          <div
            className={`white--rounded-box dashbaord--sec ${
              !loading &&
              tours?.data?.length === 0 &&
              (search ||
                subMarket ||
                sortAndOrder?.sortBy !== DEFAULT_TOUR_SORT_BY ||
                sortAndOrder?.orderBy !== DEFAULT_TOUR_ORDER_BY ||
                hasData)
                ? 'not--tours--box box--expand'
                : ''
            }`}
            id="contentBody"
          >
            {!scrolled &&
              (!tours ||
                tours?.data?.length > 0 ||
                search ||
                loading ||
                subMarket ||
                sortAndOrder?.sortBy !== DEFAULT_TOUR_SORT_BY ||
                sortAndOrder?.orderBy !== DEFAULT_TOUR_ORDER_BY) && (
                <React.Fragment>
                  <div className="row row--space-10" id="innerOptions">
                    {!meLoading ? (
                      <React.Fragment>
                        <div className="col-12 col-lg-10 col-xl-8">
                          <div className="row row--space-10">
                            <div className="col-12 col-sm-6 col-md-6">
                              <SubMarketFilter
                                subMarket={subMarket}
                                setSubMarket={setSubMarket}
                                fetchedResponse={subMarketsListResponse}
                              />
                            </div>
                            <div className="col-12 col-sm-6 col-md-6">
                              <TourFilters
                                onSelect={onFilterSelect}
                                sortBy={sortAndOrder?.sortBy}
                                orderBy={sortAndOrder?.orderBy}
                              />
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="col-12 col-sm-6 col-md-6">
                          <div className="dropdown--shimmer mb-3 mb-sm-0 w-100">
                            <span className="dropdownShimmer__text animate"></span>
                            <span className="dropdownShimmer__arrow">
                              <i className="icon-caret-down"></i>
                            </span>
                          </div>
                        </div>
                        <div className="col-12 col-sm-6 col-md-6">
                          <div className="dropdown--shimmer mb-3 mb-sm-0 w-100">
                            <span className="dropdownShimmer__text animate"></span>
                            <span className="dropdownShimmer__arrow">
                              <i className="icon-caret-down"></i>
                            </span>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </React.Fragment>
              )}
            {(loading || !tours || tours?.data?.length > 0) && (
              <div className="upcomingTour--canvas mt-md-3 pt-1">
                {firstFetch && loading && (
                  <React.Fragment>
                    <TourBlockShimmer />
                    <TourBlockShimmer />
                    <TourBlockShimmer />
                    <TourBlockShimmer />
                  </React.Fragment>
                )}
                {(!loading || !firstFetch) &&
                  tours?.data?.map((tour, index) => (
                    <div className="upcomingTour--block mb-20" key={index}>
                      <div className="upcomingTour--main">
                        <div className="upcomingTour--left">
                          {tour?.tourType === TOUR_TYPES.LIVE && (
                            <React.Fragment>
                              <span className="upComTour__icon">
                                <i className="icon-live-integrate"></i>
                              </span>
                              <span className="upComTour__name">
                                Live Integrated
                                <br /> Video
                              </span>
                            </React.Fragment>
                          )}
                          {tour?.tourType === TOUR_TYPES.PRESENT && (
                            <React.Fragment>
                              <span className="upComTour__icon">
                                <i className="icon-tv-alt"></i>
                              </span>
                              <span className="upComTour__name">
                                Tour Only
                                <br /> (Presentation Style)
                              </span>
                            </React.Fragment>
                          )}
                        </div>
                        <div className="upcomingTour--right">
                          <ul className="upComTour__list">
                            {tour?.clientInfo && tour?.clientInfo?.company && (
                              <li>
                                <span className="upComTour__list-icon">
                                  <i className="icon-schedule-dashboard"></i>
                                </span>
                                <span className="upComTour__list-text">
                                  {tour?.clientInfo?.firstName} {tour?.clientInfo?.lastName}{' '}
                                  {tour?.clientInfo?.brokerCompany
                                    ? `@${tour?.clientInfo?.brokerCompany}, Representing `
                                    : '@'}
                                  {tour?.clientInfo?.company && (
                                    <a className="link__dark">{tour?.clientInfo?.company}</a>
                                  )}
                                </span>
                              </li>
                            )}
                            <li>
                              <span className="upComTour__list-icon">
                                <i className="icon-floorplan"></i>
                              </span>
                              {tour?.listings.length === 0 &&
                                'There are no listings added to this tour'}
                              {tour?.listings?.length > 0 &&
                                tour?.listings?.map(
                                  (
                                    listing: RoomListingsInterface,
                                    index: number,
                                    tourListings: RoomListingsInterface[],
                                  ) =>
                                    index < showCount ? (
                                      <span className="upComTour__list-text" key={index}>
                                        {listing?.building?.location?.split(SEPARATORS.COMMA)[0]},{' '}
                                        {checkIfNumber(listing?.suiteNo)
                                          ? `Suite ${listing?.suiteNo}`
                                          : listing?.suiteNo}
                                        {tourListings?.length > showCount &&
                                          index === DEFAULT_LISTING_COUNT_IN_TOUR - 1 && (
                                            <a
                                              className="link__blue-sm ml-2"
                                              onClick={() =>
                                                setShowCount(
                                                  tourListings?.length ||
                                                    DEFAULT_LISTING_COUNT_IN_TOUR,
                                                )
                                              }
                                            >
                                              + {tourListings.length - showCount} More
                                            </a>
                                          )}
                                        {tourListings?.length === showCount &&
                                          tourListings?.length > DEFAULT_LISTING_COUNT_IN_TOUR &&
                                          index + 1 === showCount && (
                                            <a
                                              className="link__blue-sm ml-2"
                                              onClick={() =>
                                                setShowCount(DEFAULT_LISTING_COUNT_IN_TOUR)
                                              }
                                            >
                                              Show Less
                                            </a>
                                          )}
                                      </span>
                                    ) : (
                                      <React.Fragment key={index}></React.Fragment>
                                    ),
                                )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      {type !== 'history' && (
                        <div className="upComTour--btn-canvas">
                          <Button
                            className="link__btn link__btn-blue"
                            onClick={() =>
                              copyTourLinkToClipboard(
                                tour._id,
                                tour.expiredAt,
                                me?.company?.domain,
                                tour?.tourUrl,
                              )
                            }
                          >
                            Copy Tour Link
                            <i className="icon-link"></i>
                          </Button>
                          <Link className="link__btn link__btn-gray" to={`/tour/${tour._id}/edit`}>
                            Edit
                            <i className="icon-edit-alt"></i>
                          </Link>
                        </div>
                      )}
                    </div>
                  ))}
                {!firstFetch && loading && (
                  <React.Fragment>
                    <TourBlockShimmer />
                    <TourBlockShimmer />
                  </React.Fragment>
                )}
              </div>
            )}
            {!loading && tours?.data?.length === 0 && (
              <React.Fragment>
                {search ||
                subMarket ||
                sortAndOrder?.sortBy !== DEFAULT_TOUR_SORT_BY ||
                sortAndOrder?.orderBy !== DEFAULT_TOUR_ORDER_BY ? (
                  <React.Fragment>
                    <div className="dashboard--notfound notfound--result notfound--result-tours mt-auto mb-auto pt-4 pt-md-5">
                      <span className="notfound__result-img"></span>
                      <p className="para__lg para__lg-dark">
                        Sorry, there are no tours that match your search
                      </p>
                      <p className="para__md-gray mb-0">Please try again.</p>
                    </div>
                  </React.Fragment>
                ) : hasData ? (
                  <React.Fragment>
                    <div className="dashboard--newAccount no--tours my-auto">
                      <div className="container--xs">
                        <p className="para__md-gray mb-3 pb-1">
                          {type === 'history'
                            ? 'There is no history of tours. You will be able to see history once ' +
                              'you have completed some tours.'
                            : 'There are currently no upcoming tours in your account. You will see ' +
                              'more information here once you receive requests, or when you create ' +
                              'a tour.'}
                        </p>
                        <Link className="admin__button button__radius" to="/tour/create">
                          Create Tour
                          <i className="icon-tour ml-2"></i>
                        </Link>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <></>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewTours;
