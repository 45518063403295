import React, { useEffect, useRef, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { CommentsInterface } from '../../../../../state/listing/listing.types';
import {
  useDeleteComment,
  useEditComment,
  usePauseRecording,
} from '../../../../../state/review/review.hook';
import SmallLoader from '../../../../common/small.loader';
import { UploadedMediaInterface } from '../../../../../state/media/media.types';
import { compileAvatarUrl } from '../../../../../utils/avatar.utils';
import { isEqual } from 'lodash';
import {
  ALLOWED_COMMENT_IMAGE_MAX_SIZE_IN_MB,
  ALLOWED_COMMENT_IMAGE_MIME_TYPES,
  ALLOWED_COMMENT_IMAGE_TYPES,
  COMMENT_REQUIRED_ERROR,
} from '../../../../../common/constants';
import { useParams } from 'react-router';
import { useUploadMedia } from '../../../../../state/media/media.hook';
import { errorExtractor } from '../../../../../utils/error.utils';
import CommentDeleteWarning from './deleteWarning';
import { getHumanizeTime, showNotification } from '../../../../../utils/misc.utils';
import { AttachmentLightBox } from '../../../../lightbox';
import { convertSecToString } from '../../../listing.component';

interface Props {
  currentTime: number;
  comments: CommentsInterface[];
  setComments: (
    value: CommentsInterface[] | ((prevState: CommentsInterface[]) => CommentsInterface[]),
  ) => void;
  setTopCommentRef: (node: any) => void;
  canEdit: boolean;
}

const ReviewAllComments: React.FC<Props> = (props: Props) => {
  const { comments, setComments, currentTime, setTopCommentRef, canEdit } = props;
  const { listingId } = useParams();
  const [videoTimestamp, setVideoTimestamp] = useState('');
  const { loading: editing, editComment } = useEditComment(setComments);
  const { loading: deleting, deleteComment } = useDeleteComment(setComments);
  const { res: uploadPhotoRes, uploadSingleMedia: uploadPhoto } = useUploadMedia();
  const [editedComment, setEditedComment] = useState<string>('');
  const [editedText, setEditedText] = useState<string>('');
  const [editedMedia, setEditedMedia] = useState<UploadedMediaInterface | undefined>(undefined);
  const [editedVideTimestamp, setEditedVideoTimestamp] = useState<string>('');
  const [editedError, setEditedError] = useState<string>('');
  const [showDelModal, setShowDelModal] = useState(false);
  const [lightBoxMedia, setLightBoxMedia] = useState<UploadedMediaInterface[]>([]);
  const [deletedComment, setDeletedComment] = useState<string>('');
  const [formRef, setFormRef] = useState<any>(null);
  const [attachVideoTimestamp, setAttachVideoTimestamp] = useState(false);
  const { pauseRecording } = usePauseRecording();

  useEffect(() => {
    setVideoTimestamp(convertSecToString(currentTime, true));
  }, [currentTime]);

  useEffect(() => {
    if (!uploadPhotoRes.loading && uploadPhotoRes.data) {
      setEditedMedia(uploadPhotoRes.data);
    }
    if (!uploadPhotoRes.loading && uploadPhotoRes.error) {
      setEditedError(errorExtractor(uploadPhotoRes.error));
    }
  }, [uploadPhotoRes]);

  const closeEditing = () => {
    setEditedComment('');
    setEditedText('');
    setEditedMedia(undefined);
    setEditedError('');
    setAttachVideoTimestamp(false);
    setEditedVideoTimestamp('');
  };

  const invokeClose = (prevText?: string, prevMedia?: UploadedMediaInterface) => {
    if (editedText !== prevText || !isEqual(editedMedia, prevMedia)) {
      const response = confirm('You have unsaved changes that will be lost.');
      if (response) {
        closeEditing();
      }
    } else {
      closeEditing();
    }
  };

  const handleSave = (prevText?: string, prevMedia?: UploadedMediaInterface) => {
    const trimmedEditedText = editedText.trim();
    if (
      trimmedEditedText === prevText &&
      isEqual(editedMedia, prevMedia) &&
      !attachVideoTimestamp
    ) {
      closeEditing();
      return;
    }
    if (editedError) {
      return;
    }
    if (!trimmedEditedText && !editedMedia) {
      setEditedError(COMMENT_REQUIRED_ERROR);
      return;
    }
    editComment(listingId, editedComment, {
      content: {
        text: trimmedEditedText,
        media: editedMedia,
      },
      videoTimestamp: attachVideoTimestamp ? videoTimestamp : editedVideTimestamp,
    })
      .then(() => {
        closeEditing();
        showNotification('success', 'Success', 'Saved successfully');
      })
      .catch((error: any) => {
        showNotification('error', 'Error', errorExtractor(error));
      });
  };

  const onUpload = (e: any) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (!ALLOWED_COMMENT_IMAGE_MIME_TYPES.includes(file.type)) {
        setEditedError(`Allowed media types: ${ALLOWED_COMMENT_IMAGE_TYPES}`);
        return;
      }
      const fileSizeInMb = file.size / 1000000;
      if (fileSizeInMb > ALLOWED_COMMENT_IMAGE_MAX_SIZE_IN_MB) {
        setEditedError(`Max size allowed: ${ALLOWED_COMMENT_IMAGE_MAX_SIZE_IN_MB} MB`);
        return;
      }
      uploadPhoto({ name: file.name, data: file });
      setEditedError('');
    }
  };

  const invokeDelete = (commentId: string) => {
    setDeletedComment(commentId);
    setShowDelModal(true);
  };

  const handleDelete = (value: boolean) => {
    if (value && deletedComment) {
      deleteComment(listingId, deletedComment)
        .then(() => {
          setShowDelModal(false);
          setDeletedComment('');
          showNotification('success', 'Success', 'Deleted successfully');
        })
        .catch((error: any) => {
          showNotification('error', 'Error', errorExtractor(error));
        });
    } else {
      setShowDelModal(false);
    }
  };

  return (
    <React.Fragment>
      {Array.isArray(comments) &&
        comments?.length > 0 &&
        comments?.map((comment: CommentsInterface, index: number) => (
          <div
            key={index}
            ref={(node: any) => {
              if (index === 0) setTopCommentRef(node);
            }}
            className="comment--attach d-flex flex-column flex-md-row"
          >
            <div className="voice--info mb-3 mb-md-0">
              <span className="voiceInfo__name">
                {comment?.commentBy
                  ? `${comment.commentBy.firstName} ${comment.commentBy.lastName}`
                  : 'Unknown'}
              </span>
              {comment?.videoTimestamp && (
                <span className="voiceInfo__time">{comment.videoTimestamp}</span>
              )}
            </div>
            <div className="ca--inner w-100">
              <Form
                ref={(node: any) => {
                  if (comment._id === editedComment) {
                    setFormRef(node);
                  }
                }}
                onSubmit={(e: any) => {
                  e.preventDefault();
                  pauseRecording();
                  handleSave(comment?.content?.text, comment?.content?.media);
                }}
              >
                <div className="ca--group-main w-100">
                  {editedComment === comment._id ? (
                    <React.Fragment>
                      <Form.Group className="forms--group comment--group mb-0">
                        <Form.Control
                          as="textarea"
                          rows={5}
                          disabled={editing}
                          onKeyPress={(e: any) => {
                            if (e?.key === 'Enter' && !e?.shiftKey) {
                              e.preventDefault();
                              formRef?.dispatchEvent(new Event('submit'));
                            }
                          }}
                          onChange={(e: any) => {
                            const trimmedText = e.target.value.trim();
                            if (!trimmedText) {
                              setEditedText(trimmedText);
                            } else {
                              setEditedText(e.target.value);
                            }
                            if ((!e.target.value || !trimmedText) && !editedMedia) {
                              setEditedError(COMMENT_REQUIRED_ERROR);
                            } else {
                              setEditedError('');
                            }
                          }}
                          value={editedText}
                          onFocus={pauseRecording}
                        />
                      </Form.Group>
                      {editedError && <p className="error__msg">{editedError}</p>}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="ca--group">
                        <pre className="ca__para">{comment?.content?.text || ''}</pre>
                      </div>
                      {comment?.isEdited && comment?.lastEditedBy && (
                        <p className="edited__msg text-right mt-1">
                          Last Edited By {comment?.lastEditedBy?.firstName}
                          {comment?.updatedAt && (
                            <React.Fragment> ({getHumanizeTime(comment.updatedAt)})</React.Fragment>
                          )}
                        </p>
                      )}
                    </React.Fragment>
                  )}
                </div>
                {editedComment === comment._id && (
                  <div className="d-flex flex-column-reverse flex-sm-row align-items-sm-center justify-content-between mb-4 pb-2">
                    <label className="checkbox--set checkbox--set-dark mt-3">
                      Attach to current timestamp ({videoTimestamp}) minutes
                      <input
                        type="checkbox"
                        onChange={(e: any) => {
                          setAttachVideoTimestamp(e.target.checked);
                        }}
                        checked={attachVideoTimestamp}
                        onFocus={pauseRecording}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                )}
                <div
                  className={`ca--last d-flex flex-column flex-sm-row flex-lg-column flex-xl-row align-items-center justify-content-between mb-4 pb-1 ${
                    editedComment !== comment._id ? 'mt-3' : ''
                  }`}
                >
                  {editedComment !== comment._id && comment?.content?.media && (
                    <div
                      className="ca--cover ca--cover-sm"
                      onClick={() => {
                        pauseRecording();
                        setLightBoxMedia([
                          (comment.content.media as unknown) as UploadedMediaInterface,
                        ]);
                      }}
                    >
                      <div className="ca--cover-img">
                        <img src={compileAvatarUrl(comment.content.media.url)} alt="Img" />
                      </div>
                      <div className="ca--cover-name">{comment.content.media.name}</div>
                    </div>
                  )}
                  {editedComment === comment._id && (
                    <React.Fragment>
                      {editedMedia ? (
                        <div
                          className="ca--cover ca--cover-sm"
                          onClick={() => {
                            pauseRecording();
                            setLightBoxMedia([editedMedia]);
                          }}
                        >
                          <div className="ca--cover-img">
                            <img src={compileAvatarUrl(editedMedia.url)} alt="Img" />
                          </div>
                          <div className="ca--cover-name">{editedMedia.name}</div>
                          <span
                            className="cover__cross"
                            onClick={(e: any) => {
                              pauseRecording();
                              e.stopPropagation();
                              setEditedMedia(undefined);
                              if (!editedText) {
                                setEditedError(COMMENT_REQUIRED_ERROR);
                              }
                            }}
                          >
                            <i className="icon-cross-cirlce"></i>
                          </span>
                        </div>
                      ) : (
                        <label className="custom__file upload__button upload__media d-inline-flex align-items-center mr-auto">
                          <input
                            type="file"
                            onChange={onUpload}
                            onClick={pauseRecording}
                            disabled={uploadPhotoRes.loading}
                          />
                          {uploadPhotoRes.loading ? (
                            <React.Fragment>
                              Uploading <SmallLoader />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              Upload Media
                              <span className="upload__button-icon">
                                <i className="icon-upload"></i>
                              </span>
                            </React.Fragment>
                          )}
                        </label>
                      )}
                    </React.Fragment>
                  )}
                  {canEdit && (
                    <div className="ca__btn-canvas mt-3 mt-sm-0 mt-lg-3 mt-xl-0 ml-auto">
                      <div className="row row--space-5 pl-sm-3 pl-lg-0 pl-xl-3">
                        {editedComment === comment._id ? (
                          <React.Fragment>
                            <div className="col-12 col-sm-6">
                              <Button
                                type="button"
                                className={`outline__button ca__btn ca__btn-sm ${
                                  uploadPhotoRes.loading && editedComment === comment._id
                                    ? 'disabled'
                                    : ''
                                }`}
                                disabled={
                                  (editing || uploadPhotoRes.loading) &&
                                  editedComment === comment._id
                                }
                                onClick={() => {
                                  pauseRecording();
                                  invokeClose(comment?.content?.text, comment?.content?.media);
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                            <div className="col-12 col-sm-6">
                              <Button
                                type="submit"
                                className={`admin__button ca__btn ca__btn-sm mt-3 mt-sm-0 ${
                                  ((!editedText && !editedMedia) || uploadPhotoRes.loading) &&
                                  editedComment === comment._id
                                    ? 'disabled'
                                    : ''
                                }`}
                                disabled={
                                  (editing || uploadPhotoRes.loading) &&
                                  editedComment === comment._id
                                }
                              >
                                {editing && editedComment === comment._id ? (
                                  <React.Fragment>
                                    Saving <SmallLoader />
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>Save</React.Fragment>
                                )}
                              </Button>
                            </div>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <div className="col-12 col-sm-6">
                              <Button
                                type="button"
                                className="outline__button ca__btn ca__btn-sm"
                                onClick={() => {
                                  pauseRecording();
                                  setEditedComment(comment._id || '');
                                  setEditedText(comment?.content?.text || '');
                                  setEditedMedia(comment?.content?.media);
                                  setEditedVideoTimestamp(comment?.videoTimestamp || '');
                                }}
                              >
                                Edit
                              </Button>
                            </div>
                            <div className="col-12 col-sm-6">
                              <Button
                                type="button"
                                className="admin__button ca__btn ca__btn-sm mt-3 mt-sm-0"
                                disabled={deleting && comment._id === deletedComment}
                                onClick={() => {
                                  pauseRecording();
                                  invokeDelete((comment._id as unknown) as string);
                                }}
                              >
                                {deleting && comment._id === deletedComment ? (
                                  <React.Fragment>
                                    Deleting <SmallLoader />
                                  </React.Fragment>
                                ) : (
                                  <React.Fragment>Delete</React.Fragment>
                                )}
                              </Button>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Form>
            </div>
          </div>
        ))}
      <CommentDeleteWarning
        show={showDelModal}
        handleClose={() => setShowDelModal(false)}
        confirm={handleDelete}
        loading={deleting}
      />
      {lightBoxMedia?.length > 0 && (
        <AttachmentLightBox
          data={lightBoxMedia}
          index={0}
          close={() => setLightBoxMedia([])}
          showTitles={true}
        />
      )}
    </React.Fragment>
  );
};

export default ReviewAllComments;
