import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { convertSecToString } from '../listing.component';
import { VIDEO_STATUS } from '../../../common/constants';

interface Props {
  duration: number;
}
const ReviewSegmentInfo: React.FC<Props> = props => {
  const { duration } = props;
  const listing = useSelector((state: AppStateInterface) => state.listing.singleListing.data);
  const allAudios = useSelector((state: AppStateInterface) => state.review.audios);

  const [segDuration, setDuration] = useState('');

  useEffect(() => {
    setDuration(convertSecToString(duration));
  }, [duration]);

  return (
    <div className="key--details key--details-record mb-3 mt-0">
      <div className="d-flex align-items-center">
        <h6 className="keyDetails__heading d-flex align-items-center flex-wrap">
          Video Segment
          {/* : {numToString(currentSeg)} /{numToString(REVIEW_SEGMENTS)} */}
          {/* <div className="segment__option-box d-flex align-items-center ml-1">
            <a className="segment__option segment__option-prev mx-2">
              <i className="icon-chevron-left"></i>
            </a>
            <a className="segment__option segment__option-next mx-2">
              <i className="icon-chevron-right"></i>
            </a>
          </div> */}
        </h6>
      </div>
      <div className="row">
        <div className="col-6 col-sm-4">
          <div className="key--box">
            <div className="keyBox--head">
              <i className="icon-clock"></i>
              <label className="small__heading">Duration</label>
            </div>
            <p className="small__paragraph">{segDuration} Min</p>
          </div>
        </div>
        <div className="col-6 col-sm-4">
          <div className="key--box">
            <div className="keyBox--head">
              <i className="icon-play-circle"></i>
              <label className="small__heading">Video Format</label>
            </div>
            <p className="small__paragraph">Matterport</p>
          </div>
        </div>
        <div className="col-6 col-sm-4">
          <div className="key--box">
            <div className="keyBox--head">
              <i className="icon-volume"></i>
              <label className="small__heading">Audio Status</label>
            </div>
            <p className="small__paragraph">
              {!allAudios?.length ? 'Not' : ''} Recorded{' '}
              {allAudios?.length &&
                (listing?.videoStatus !== VIDEO_STATUS.VOICEOVER_SUBMITTED
                  ? '(Not Submitted)'
                  : '(Submitted)')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewSegmentInfo;
