import React, { useState, useEffect } from 'react';
import { GetListingObject, User } from '../../../../state/listing/listing.types';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import { DEFAULT_AVATAR } from '../../../../common/constants';

interface Props {
  info: GetListingObject;
}
const ListingBrokers: React.FC<Props> = props => {
  const [brokers, setBrokers] = useState([] as User[]);
  useEffect(() => {
    if (props.info.users) setBrokers(props.info.users);
  }, [props.info]);

  return (
    <div className="customer--block mt-0">
      {brokers.map((user, index) => {
        return (
          <React.Fragment key={user._id}>
            {index < 4 && (
              <div className="customer--img">
                <img
                  src={user?.avatar ? compileAvatarUrl(user.avatar) : DEFAULT_AVATAR}
                  alt="Img"
                />
              </div>
            )}
          </React.Fragment>
        );
      })}
      {brokers?.length > 4 && <a className="customer--add">+ {brokers?.length - 4}</a>}
    </div>
  );
};
export default ListingBrokers;
