import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { AppStateInterface } from '../../../state';
import { GetListingObject } from '../../../state/listing/listing.types';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import ListingTourSpecCard from './listingTourSpecCard';
import { mergeBuildingHours } from '../../listing/listing.component';
import { startCase } from 'lodash';

interface Props {
  listing: GetListingObject;
  className?: string;
}
enum View {
  AMENITIES = 'AMENITIES',
  SPECIFICATIONS = 'SPECIFICATIONS',
}

const ListingTourSpecs: React.FC<Props> = (props: Props) => {
  const { listing, className } = props;
  const { building } = listing;

  const [activeView, setActiveView] = useState<View>(View.SPECIFICATIONS);

  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );

  return (
    <div className={`liveTour--custom liveTour--wide bg-white ${className}`}>
      <div className="container liveTour--container">
        <div className="liveTour--head d-flex align-items-center justify-content-between">
          <a className="liveTour__logo listing__logo">
            <img
              src={
                company?.settings.darkLogo
                  ? compileAvatarUrl(company.settings.darkLogo.url)
                  : '/images/HomeTour_Dark_Logo.svg'
              }
              alt="Logo"
            />
          </a>
          <h5 className="liveTour__heading text__gray">
            {building?.location?.split(',')[0]} {listing.suiteNo}
          </h5>
        </div>
        {activeView === View.SPECIFICATIONS && (
          <div className="liveTour--building liveTour--specs">
            <div className="liveTour--building-top liveTour--specs-top d-flex align-items-center">
              <h3 className="dashboard__heading d-flex align-items-center">
                Building Specifications
              </h3>
              <ul
                className="specs__list"
                style={{ cursor: 'pointer' }}
                onClick={() => setActiveView(View.AMENITIES)}
              >
                <li>Amenities</li>
              </ul>
            </div>
            <div className="liveTour--building-main liveTour--specs-main">
              <div className="row row--space-5 justify-content-center justify-content-lg-start">
                <ListingTourSpecCard
                  iconClass="icon-location"
                  heading="Location"
                  details={building.subMarket?.name ? [building.subMarket.name] : ['N/A']}
                />
                <ListingTourSpecCard
                  iconClass="icon-building-alt"
                  heading="Building Size"
                  details={
                    listing.size && listing.size > 0
                      ? [listing.size.toLocaleString() + ' SF']
                      : ['N/A']
                  }
                />
                <ListingTourSpecCard
                  iconClass="icon-layers"
                  heading="Number of Floors"
                  details={
                    building.floorsCount && building.floorsCount > 0
                      ? [building.floorsCount.toLocaleString()]
                      : ['N/A']
                  }
                />
                <ListingTourSpecCard
                  iconClass="icon-average-floor"
                  heading="Average Floor"
                  details={
                    building.averageFloor && building.averageFloor > 0
                      ? [building.averageFloor.toLocaleString() + ' SF']
                      : ['N/A']
                  }
                />
                <ListingTourSpecCard
                  iconClass="icon-telecom"
                  heading="Telecom/Data Providers"
                  details={
                    building.telecomProviders && building.telecomProviders.length > 0
                      ? [building.telecomProviders]
                      : ['N/A']
                  }
                />
                <ListingTourSpecCard
                  iconClass="icon-clock-alt"
                  heading="Building Hours"
                  details={
                    building.buildingHours
                      ? (mergeBuildingHours(building.buildingHours) as string[])
                      : ['N/A']
                  }
                />
                <ListingTourSpecCard
                  iconClass="icon-passenger-elevator"
                  heading="Passenger Elevators"
                  details={
                    building.passengerElevators && building.passengerElevators.length > 0
                      ? [building.passengerElevators]
                      : ['N/A']
                  }
                />
                <ListingTourSpecCard
                  iconClass="icon-freight-elevator"
                  heading="Freight Elevators"
                  details={
                    building.freightElevators && building.freightElevators.length > 0
                      ? [building.freightElevators]
                      : ['N/A']
                  }
                />
                <ListingTourSpecCard
                  iconClass="icon-spacing-vertical"
                  heading="Slab to Slab Height"
                  details={
                    building.slabToSlabHeight && building.slabToSlabHeight.length > 0
                      ? [building.slabToSlabHeight]
                      : ['N/A']
                  }
                />
                <ListingTourSpecCard
                  iconClass="icon-spacing-horizontal"
                  heading="Column Spacing"
                  details={
                    building.columnSpacingHeight &&
                    building.columnSpacingWidth &&
                    building.columnSpacingHeight > 0 &&
                    building.columnSpacingWidth > 0
                      ? [
                          `${building.columnSpacingWidth.toLocaleString()}' X ${building.columnSpacingHeight.toLocaleString()}'`,
                        ]
                      : ['N/A']
                  }
                />
                <ListingTourSpecCard
                  iconClass="icon-year-built"
                  heading="Year Built"
                  details={
                    building.yearBuilt && building.yearBuilt > 0
                      ? [String(building.yearBuilt)]
                      : ['N/A']
                  }
                />
                <ListingTourSpecCard
                  iconClass="icon-renovate"
                  heading="Year Renovated"
                  details={
                    building.yearRenovated && building.yearRenovated > 0
                      ? [String(building.yearRenovated)]
                      : ['N/A']
                  }
                />
                <ListingTourSpecCard
                  iconClass="icon-parking-ratio"
                  heading="Parking Ratio"
                  details={
                    building.parkingRatio &&
                    building.parkingRatio.length > 0 &&
                    building.parkingRatio.split(':').length === 2
                      ? [
                          `
                      ${building.parkingRatio
                        .split(':')[0]
                        .toLocaleString()} : ${building.parkingRatio.split(':')[1].toLocaleString()}
                        SF
                      `,
                        ]
                      : ['N/A']
                  }
                />
                {listing?.building.energyStarCertification === 'Yes' && (
                  <div className="col col-6 col-sm-3">
                    <div className="building--widget specs--widget certification--widget">
                      <span className="bw__icon">
                        <img
                          src="/images/star-certification.png"
                          alt="Img"
                          className="img--cover"
                        />
                      </span>
                      <div className="bw__detail mr-auto">
                        <h6 className="bw__heading">
                          Energy Star <br />
                          Certification
                        </h6>
                      </div>
                    </div>
                  </div>
                )}
                {building.leedCertification && building.leedCertification.length > 0 && (
                  <div className="col col-6 col-sm-3">
                    <div className="building--widget specs--widget certification--widget">
                      <span className="bw__icon">
                        <img
                          src={`/images/Leed-${startCase(building.leedCertification)}.png`}
                          alt="Img"
                          className="img--cover"
                        />
                      </span>
                      <div className="bw__detail mr-auto">
                        <h6 className="bw__heading">
                          Leed {startCase(listing.building.leedCertification)} <br />
                          Certification
                        </h6>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {activeView === View.AMENITIES && (
          <div className="liveTour--building liveTour--amenities">
            <div className="liveTour--building-top liveTour--amenities-top d-flex align-items-center">
              <h3 className="dashboard__heading d-flex align-items-center">Building Amenities</h3>
              <ul
                className="specs__list"
                style={{ cursor: 'pointer' }}
                onClick={() => setActiveView(View.SPECIFICATIONS)}
              >
                <li>Specifications</li>
              </ul>
            </div>
            <div className="liveTour--building-main liveTour--amenities-main">
              <div className="row row--space-5 justify-content-center justify-content-lg-start">
                {building.buildingAmenities &&
                  building.buildingAmenities.map(amenityName => (
                    <div className="col col-6 col-sm-4 col-lg-3" key={amenityName}>
                      <div className="building--widget amenities--widget">
                        <h6 className="bw__heading">{amenityName}</h6>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListingTourSpecs;
