import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import { compileAvatarUrl } from '../../../../../utils/avatar.utils';
import { SEPARATORS } from '../../../../../common/constants';
import { checkIfNumber } from '../../../../../utils/misc.utils';

interface Props {
  showForm: () => void;
}
const ListingFixHeader: React.FC<Props> = props => {
  const { showForm: show } = props;
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );
  const info = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing.data,
  );

  const [contactHref, setContactHref] = useState<string>();

  const setContactHrefFromEmail = (emails: string) => {
    setContactHref(
      `mailto:${emails}?subject=${info?.building?.location?.split(SEPARATORS.COMMA)[0]}%20-%20${
        checkIfNumber(info?.suiteNo) ? 'Suite%20' : ''
      }${info?.suiteNo}`,
    );
  };

  useEffect(() => {
    const emails = info?.users?.map(user => user.email);
    if (emails?.length) setContactHrefFromEmail(emails.join(SEPARATORS.COMMA));
    else if (company?.settings?.contactEmail)
      setContactHrefFromEmail(company.settings.contactEmail);
    else setContactHref('#');
  }, [info, company]);

  return (
    <div className="subdomain--btns-fixed">
      <div className="container container--large">
        <div className="row row--space-10 align-items-lg-center">
          <div className="col-12 col-lg-4 col-xl-6">
            <div className="heading--usual">
              <a
                className="headingUsual__logo"
                href={company?.settings?.officialWebsite || '#'}
                target={company?.settings?.officialWebsite ? '_blank' : '_self'}
                rel="noopener noreferrer"
              >
                <img
                  src={
                    company?.settings?.darkIcon
                      ? compileAvatarUrl(company.settings.darkIcon.url)
                      : '/images/HomeTour_Dark_Logo.svg'
                  }
                  alt="Logo"
                />
              </a>
              <span className="headingUsual__text">
                {info?.building?.location?.split(SEPARATORS.COMMA)[0]} -{' '}
                {checkIfNumber(info?.suiteNo) ? 'Suite ' : ''}
                {info?.suiteNo}
              </span>
            </div>
          </div>
          <div className="col-12 col-lg-8 col-xl-6">
            <div className="row row--space-10 flex-md-row-reverse">
              <div className="col-12 col-md">
                <button
                  className="admin__button admin__button-mini button__radius w-100 mt-3 mt-lg-0"
                  onClick={show}
                >
                  Request a Guided Virtual Tour
                  <i className="icon-schedule-alt ml-2"></i>
                </button>
              </div>
              {((info?.users && info?.users?.length > 0) || company?.settings?.contactEmail) && (
                <div className="col-12 col-md">
                  <button
                    className="outline__button outline__button-sm button__radius w-100 mt-3 mt-lg-0"
                    onClick={() => window.open(contactHref, '_blank')}
                  >
                    Contact our Broker Team
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingFixHeader;
