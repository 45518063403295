import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import SingleListing from '../../../components/listing/view/singleListing';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import {
  useClearSingleListing,
  useGetSingleListingAction,
} from '../../../state/listing/listing.hook';
import Loader from '../../../components/common/loader';
import { Link } from 'react-router-dom';

function SingleListingsView() {
  const history = useHistory();
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const listingInfo = useSelector((state: AppStateInterface) => state.listing.singleListing);
  const { listingId } = useParams();
  const { clearSingleListing } = useClearSingleListing();
  const { getSingleListing } = useGetSingleListingAction();
  if (!listingId) history.push('/buildings');
  useEffect(() => {
    if (listingId) getSingleListing(listingId);
  }, [listingId]);

  useEffect(() => {
    return () => {
      clearSingleListing();
    };
  }, []);

  const goBack = () => {
    if (history.action === 'PUSH') {
      history.goBack();
    } else {
      history.push(`/building/${listingInfo.data?.building?._id}/listings`);
    }
  };

  return (
    <div className="off--canvas">
      <div className="listing">
        {listingInfo.loading && <Loader />}
        <div className="container container--large">
          <Link className="listing__logo" to={'/dashboard'}>
            <img
              src={
                me?.company?.settings?.darkLogo
                  ? compileAvatarUrl(me?.company?.settings?.darkLogo.url)
                  : '/images/HomeTour_Dark_Logo.svg'
              }
              alt="Logo"
            />
          </Link>
          <div className="listing--content">
            <a className="back__btn" onClick={goBack}>
              <span className="back__btn-icon">
                <i className="icon-chevron-left"></i>
              </span>
              Back
            </a>
            <SingleListing id={listingId} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SingleListingsView;
