import React from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';

const BuildingAmenities: React.FC = () => {
  const listingInfo = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing,
  );

  return (
    <div className="row row--space-10 sdl--row align-items-center flex-md-row-reverse">
      <div className="col-12 col-md-5 d-flex flex-column align-items-md-end">
        <div className="sdl--l text-center text-md-left mb-20">
          <h2 className="dashboard__heading dashboard__heading-alt font-semibold mb-4 mb-md-0">
            Amenities
          </h2>
        </div>
      </div>
      <div className="col-12 col-md-7 d-flex flex-column">
        <div className="row row--space-10 justify-content-center justify-content-md-start">
          {listingInfo?.data?.building?.buildingAmenities?.map(amenityName => (
            <div className="col col-12 col-sm-6" key={amenityName}>
              <div className="specs--box specs--box-center">
                <div className="specsBox__head">
                  <span className="specsBox__head-text">{amenityName}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BuildingAmenities;
