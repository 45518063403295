import { AxiosPromise, CancelToken } from 'axios';
import api from '../../utils/api.utils';
import { QuickSearchResultsInterface } from './search.types';

export const quickSearchAPI = (
  searchTerm: string,
  ct?: CancelToken,
): AxiosPromise<QuickSearchResultsInterface> => {
  return api.get('/quick-search', {
    cancelToken: ct,
    params: {
      searchTerm,
    },
  });
};
