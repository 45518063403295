import { UploadedMediaInterface } from '../media/media.types';
import { GetStateInterface } from '../../types/GetStateInterface';
import { AmenityMatterPortLink } from '../building/building.types';
import { LISTING_STATUS } from '../../common/constants';

export interface ListingStateInterface {
  allListings: GetStateInterface<GetAllListingsResponseInterface>;
  singleListing: GetStateInterface<GetListingObject>;
}

export enum ListingActions {
  POST_LISTING = 'POST_LISTING',
  LISTING_STATUS = 'LISTING_STATUS',
  VIDEO_STATUS = 'VIDEO_STATUS',
  LISTING_UPDATE = 'LISTING_UPDATE',
  VIDEO_UPDATE = 'VIDEO_UPDATE',
  GET_TOUR_URL = 'GET_TOUR_URL',
  DELETE_LISTING = 'DELETE_LISTING',
  GET_ALL_RECENT_LISTINGS = 'GET_ALL_RECENT_LISTINGS',
  EXPORT_LISTINGS = 'EXPORT_LISTINGS',

  GET_ALL_LISTINGS = 'GET_ALL_LISTINGS',
  GET_ALL_LISTINGS_INIT = 'GET_ALL_LISTINGS_INIT',
  GET_ALL_LISTINGS_CLEAR = 'GET_ALL_LISTINGS_CLEAR',
  GET_ALL_LISTINGS_FAILURE = 'GET_ALL_LISTINGS_FAILURE',
  GET_ALL_LISTINGS_SUCCESSFUL = 'GET_ALL_LISTINGS_SUCCESSFUL',

  GET_SINGLE_LISTING = 'GET_SINGLE_LISTING',
  GET_SINGLE_LISTING_INIT = 'GET_SINGLE_LISTING_INIT',
  GET_SINGLE_LISTING_CLEAR = 'GET_SINGLE_LISTING_CLEAR',
  GET_SINGLE_LISTING_FAILURE = 'GET_SINGLE_LISTING_FAILURE',
  GET_SINGLE_LISTING_SUCCESSFUL = 'GET_SINGLE_LISTING_SUCCESSFUL',
}

export interface PhotoInterface extends UploadedMediaInterface {
  isPrimary: boolean;
}
export interface ListingInterface {
  spaceUse: string;
  suiteNo: string;
  size: number;
  monthlyRent: number | null;
  capacity: string;
  condition: string;
  metroProximity: string;
  description: string;
  matterPortLink: string;
  videoDescription: string;

  available: string;
  media: PhotoInterface[];
  floorPlans: UploadedMediaInterface[];
  renderings: UploadedMediaInterface[];
  pdfs: UploadedMediaInterface[];
  users: string[];

  building: string;
}

export interface ListingErrorInterface {
  available: string;
  media: string;
  pdfs: string;
  floorPlans: string;
  renderings: string;
}

export interface User {
  _id: string;
  avatar: string;
  firstName: string;
  lastName: string;
  phone: string;
  role: string;
  email: string;
}

export interface AudioAndCommentUser {
  _id: string;
  avatar: string;
  firstName: string;
  lastName: string;
}

export interface SubContentInterface {
  text?: string;
  media?: UploadedMediaInterface;
}

export interface CommentsInterface {
  _id?: string;
  videoTimestamp?: string;
  audioTimestamp?: string;
  audioVoiceOverId?: string;
  content: SubContentInterface;
  commentBy?: AudioAndCommentUser;
  isActive?: boolean;
  isEdited?: boolean;
  lastEditedBy?: AudioAndCommentUser;
  createdAt?: string;
  updatedAt?: string;
  isPrevious?: boolean;
}

export interface AudioVoiceOverInterface {
  _id?: string;
  segment: number;
  name: string;
  url: string;
  playVideoStartTimestamp: string;
  isActive?: boolean;
  voiceOverBy: AudioAndCommentUser;
  createdAt?: string;
  updatedAt?: string;
  isPrevious?: boolean;
}

export interface GetListingObject {
  _id: string;
  videoUrl?: string;
  videoStatus: string;
  isActive: boolean;
  status: string;
  users: User[];
  createdBy: CreatedBy;
  available: string;
  media: PhotoInterface[];
  renderings: UploadedMediaInterface[];
  floorPlans: UploadedMediaInterface[];
  videoDescription: string;
  matterPortLink: string;
  description: string;
  metroProximity: string;
  condition: string;
  capacity: string;
  monthlyRent: number | null;
  size: number;
  suiteNo: string;
  spaceUse: string;
  building: Building;
  pdfs: UploadedMediaInterface[];
  audioVoiceOver?: AudioVoiceOverInterface[];
  voiceOverComments?: CommentsInterface[];
  createdAt: string;
  updatedAt: string;
}

interface CreatedBy {
  _id: string;
  avatar: string;
  firstName: string;
  lastName: string;
}

export interface GetAllListings {
  status: string;
  users: User[];
  _id: string;
  createdBy: User;
  media: PhotoInterface[];
  capacity: string;
  monthlyRent: number;
  size: number;
  suiteNo: string;
  building: Building;
}

export interface GetAllListingsResponseInterface {
  data: GetAllListings[];
  skip: number;
  hasMore: boolean;
}

export interface Building {
  _id: string;
  location: string;
  subMarket: SubMarket;
  latitude?: string;
  longitude?: string;
  fullLocation?: any;
  yearBuilt?: number;
  yearRenovated?: number;
  floorsCount?: number;
  buildingSize?: number;
  averageFloor?: number;
  parkingRatio?: string;
  columnSpacingHeight?: number;
  columnSpacingWidth?: number;
  slabToSlabHeight?: string;
  passengerElevators?: string;
  freightElevators?: string;
  telecomProviders?: string;
  buildingHours?: string[];
  energyStarCertification?: string;
  leedCertification?: string;
  buildingAmenities?: string[];
  buildingAmenitiesMatterportLinks?: AmenityMatterPortLink[];
}

interface SubMarket {
  isActive: boolean;
  _id: string;
  name: string;
  market: string;
}

export interface GetTourUrlResponseInterface {
  _id: string;
  tourUrl: string;
  expiryInDays: number;
}

export interface AudioAndCommentUser {
  _id: string;
  avatar: string;
  firstName: string;
  lastName: string;
}
export interface SubContentInterface {
  text?: string;
  media?: UploadedMediaInterface;
}
export interface CommentsInterface {
  _id?: string;
  videoTimestamp?: string;
  audioTimestamp?: string;
  audioVoiceOverId?: string;
  content: SubContentInterface;
  commentBy?: AudioAndCommentUser;
  isActive?: boolean;
  isEdited?: boolean;
  lastEditedBy?: AudioAndCommentUser;
  createdAt?: string;
  updatedAt?: string;
}
export interface AudioVoiceOverInterface {
  _id?: string;
  segment: number;
  name: string;
  url: string;
  playVideoStartTimestamp: string;
  isActive?: boolean;
  voiceOverBy: AudioAndCommentUser;
  createdAt?: string;
  updatedAt?: string;
}

export interface HandlerConditions {
  copy: boolean;
  accept: boolean;
  record: boolean;
  recordWhite: boolean;
}

export type RecentSortBy =
  | 'updatedAt'
  | 'monthlyRent'
  | 'size'
  | 'availableNow'
  | 'availableInNinety'
  | 'createdAt';

export type RecentOrderBy = 'ascending' | 'descending';

export type RecentSortAndOrder = {
  sortBy?: RecentSortBy;
  orderBy?: RecentOrderBy;
};

export interface GetRecentListingsQueryInterface extends RecentSortAndOrder {
  subMarket?: string;
  search?: string;
  limit?: number;
  skip?: number;
  status?: LISTING_STATUS;
}

export interface RecentListingBuildingInterface {
  _id: string;
  location: string;
}

export interface RecentListingSubMarketInterface {
  name: string;
  market: string;
}

export interface RecentListing {
  _id: string;
  status: string;
  media: PhotoInterface[];
  monthlyRent: number;
  size: number;
  suiteNo: string;
  capacity: string;
  building: RecentListingBuildingInterface;
  subMarket: RecentListingSubMarketInterface;
}

export interface GetRecentListingsResponseInterface {
  hasMore: boolean;
  skip: number;
  data: Array<RecentListing>;
}
