import { PutCompanyPayloadInterface, PutUserPayloadInterface } from './setting.types';
import { CancelToken } from 'axios';
import api from '../../utils/api.utils';

export const putUserAPI = (payload: PutUserPayloadInterface, ct?: CancelToken) => {
  return api.put('/user', payload, {
    cancelToken: ct,
  });
};

export const putCompanyAPI = (
  companyId: string,
  payload: PutCompanyPayloadInterface,
  ct?: CancelToken,
) => {
  return api.put(`/company/${companyId}`, payload, {
    cancelToken: ct,
  });
};
