import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import {
  BuildingDataErrorInterface,
  BuildingDataInterface,
  AmenityMatterPortLinkWithError,
} from '../../../../state/building/building.types';
import { cloneDeep } from 'lodash';
import SmallLoader from '../../../common/small.loader';
import { GetStateInterface } from '../../../../types/GetStateInterface';
import HamBurger from '../../../leftSidebar/hamburger';
import { MAXIMUM_AMENITIES_ALLOWED } from '../../../../common/constants';
import { validateUrl } from '../../../../utils/misc.utils';

interface Props {
  goBack: () => void;
  onContinue: (e: any) => void;
  setDataField: (field: string, value: any) => void;
  data: BuildingDataInterface;
  errors: BuildingDataErrorInterface;
  setError: (field: string, value: string) => void;
  addBuildingLoading: boolean;
  setStep: (value: 1 | 2) => void;
  buildingDetailsResponse: GetStateInterface;
  onSave: (e: any) => void;
  updateBuildingResponse: GetStateInterface;
}

const AmenityMatterPortInitial = (): AmenityMatterPortLinkWithError => {
  return { name: '', url: '', error: false };
};
const BuildingSpecificationsForm: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const initialBuildingHours = {
    openingDay: 'Mon',
    closingDay: 'Fri',
    openingTime: '9am',
    closingTime: '5pm',
  };
  const { buildingId } = useParams();
  const [amenities, setAmenities] = useState(['', '', '', '']);
  const [amenitiesMatterportLinks, setAmenitiesMatterportLinks] = useState([
    AmenityMatterPortInitial(),
    AmenityMatterPortInitial(),
  ]);
  const [buildingHours, setBuildingHours] = useState([initialBuildingHours]);

  useEffect(() => {
    if (!props.buildingDetailsResponse.loading && props.buildingDetailsResponse.data) {
      const savedBuildingAmenities = props.buildingDetailsResponse.data?.buildingAmenities;
      const savedBuildingAmenitiesLength = savedBuildingAmenities?.length;
      const savedBuildingAmenitiesMatterportLinks =
        props.buildingDetailsResponse.data?.buildingAmenitiesMatterportLinks;
      const savedBuildingAmenitiesMatterportLinksLength =
        savedBuildingAmenitiesMatterportLinks?.length;
      const savedBuildingHours = props.buildingDetailsResponse.data.buildingHours;
      if (Array.isArray(savedBuildingAmenities) && savedBuildingAmenitiesLength) {
        if (savedBuildingAmenitiesLength < 4) {
          const newAmenities = [...savedBuildingAmenities];
          for (let itr = 0; itr < 4 - savedBuildingAmenitiesLength; itr++) {
            newAmenities.push('');
          }
          setAmenities(newAmenities);
        } else {
          setAmenities(savedBuildingAmenities);
        }
      }
      if (
        Array.isArray(savedBuildingAmenitiesMatterportLinks) &&
        savedBuildingAmenitiesMatterportLinksLength
      ) {
        if (savedBuildingAmenitiesMatterportLinksLength < 2) {
          const links = savedBuildingAmenitiesMatterportLinks as AmenityMatterPortLinkWithError[];
          links.map(link => (link.error = false));
          for (let itr = 0; itr < 2 - savedBuildingAmenitiesMatterportLinksLength; itr++)
            links.push(AmenityMatterPortInitial());
          setAmenitiesMatterportLinks([...links]);
        } else {
          const links = savedBuildingAmenitiesMatterportLinks as AmenityMatterPortLinkWithError[];
          links.map(link => (link.error = false));
          setAmenitiesMatterportLinks([...links]);
        }
      }
      if (Array.isArray(savedBuildingHours) && savedBuildingHours.length) {
        const breakedBuildingHours = [];
        for (const buildingHour of savedBuildingHours) {
          const daysAndTimes = buildingHour.split(' ');
          if (daysAndTimes.length === 2) {
            const days = daysAndTimes[0].split('-');
            const times = daysAndTimes[1].split('-');
            if (days.length === 2 && times.length === 2) {
              breakedBuildingHours.push({
                openingDay: days[0],
                closingDay: days[1],
                openingTime: times[0],
                closingTime: times[1],
              });
            }
          }
        }
        if (breakedBuildingHours.length) {
          setBuildingHours(breakedBuildingHours);
        }
      }
    }
  }, [props.buildingDetailsResponse]);

  useEffect(() => {
    props.setDataField('buildingAmenities', amenities);
  }, [amenities]);

  useEffect(() => {
    props.setDataField('buildingAmenitiesMatterportLinks', amenitiesMatterportLinks);
  }, [amenitiesMatterportLinks]);

  useEffect(() => {
    const buildingHoursCombined: string[] = [];
    for (const buildingHour of buildingHours) {
      buildingHoursCombined.push(
        `${buildingHour.openingDay}-${buildingHour.closingDay} ${buildingHour.openingTime}-${buildingHour.closingTime}`,
      );
    }
    if (buildingHoursCombined.length) {
      props.setDataField('buildingHours', buildingHoursCombined);
    }
  }, [buildingHours]);

  const handleYearChange = (field: string, value: number) => {
    if (isNaN(value)) {
      props.setDataField(field, '');
      props.setError(field, 'This field is required');
    } else {
      props.setDataField(field, value);
      props.setError(field, '');
    }
    if (value > 2020) {
      props.setError(field, 'Value must be less than or equal to 2020');
    }
    if (value < 1800) {
      props.setError(field, 'Value must be greater than or equal to 1800');
    }
  };

  const handleNumberChanges = (field: string, value: number) => {
    if (field !== 'slabToSlabHeight') {
      if (isNaN(value)) {
        props.setError(field, 'This field is required');
      } else {
        props.setError(field, '');
      }
    } else if (isNaN(value)) {
      props.setError(field, '');
    }
    if (field === 'floorsCount' && value < 0) {
      props.setError(field, 'Value must be greater than or equal to 0');
    } else if (field !== 'floorsCount' && value < 1) {
      props.setError(field, 'Value must be greater than or equal to 1');
    }
    if (isNaN(value)) {
      props.setDataField(field, '');
    } else {
      props.setDataField(field, value);
    }
  };

  const handleAmenityInputChange = (value: string, index: number) => {
    setAmenities(prevState => {
      const newState = cloneDeep(prevState);
      newState[index] = value;
      return newState;
    });
  };

  const handleAmenityMatterportLinkInputChange = (
    value: string,
    index: number,
    key: 'name' | 'url',
  ) => {
    setAmenitiesMatterportLinks(prevState => {
      const newState = cloneDeep(prevState);
      newState[index][key] = value;
      if (key === 'url' && value.length && newState[index].error) newState[index].error = false;
      return newState;
    });
  };

  const renderTimeOptions = (): JSX.Element => {
    return (
      <React.Fragment>
        <option value="Closed">Closed</option>
        <option value="12am">12:00 am</option>
        <option value="1am">1:00 am</option>
        <option value="2am">2:00 am</option>
        <option value="3am">3:00 am</option>
        <option value="4am">4:00 am</option>
        <option value="5am">5:00 am</option>
        <option value="6am">6:00 am</option>
        <option value="7am">7:00 am</option>
        <option value="8am">8:00 am</option>
        <option value="9am">9:00 am</option>
        <option value="10am">10:00 am</option>
        <option value="11am">11:00 am</option>
        <option value="12pm">12:00 pm</option>
        <option value="1pm">1:00 pm</option>
        <option value="2pm">2:00 pm</option>
        <option value="3pm">3:00 pm</option>
        <option value="4pm">4:00 pm</option>
        <option value="5pm">5:00 pm</option>
        <option value="6pm">6:00 pm</option>
        <option value="7pm">7:00 pm</option>
        <option value="8pm">8:00 pm</option>
        <option value="9pm">9:00 pm</option>
        <option value="10pm">10:00 pm</option>
        <option value="11pm">11:00 pm</option>
      </React.Fragment>
    );
  };

  const renderDayOptions = (): JSX.Element => {
    return (
      <React.Fragment>
        <option value="Mon">Monday</option>
        <option value="Tue">Tuesday</option>
        <option value="Wed">Wednesday</option>
        <option value="Thu">Thursday</option>
        <option value="Fri">Friday</option>
        <option value="Sat">Saturday</option>
        <option value="Sun">Sunday</option>
      </React.Fragment>
    );
  };

  const handleBuildingHoursChange = (
    value: string,
    index: number,
    field: 'openingDay' | 'openingTime' | 'closingTime' | 'closingDay',
  ) => {
    setBuildingHours(prevState => {
      const newState = cloneDeep(prevState);
      newState[index][field] = value;
      return newState;
    });
  };

  const handleColumnSpacingChange = (
    value: number,
    field: 'columnSpacingHeight' | 'columnSpacingWidth',
  ) => {
    if (field === 'columnSpacingHeight' && isNaN(value) && props.data.columnSpacingWidth) {
      props.setError('columnSpacing', 'Either fill both height and width or none');
    }
    if (field === 'columnSpacingWidth' && isNaN(value) && props.data.columnSpacingHeight) {
      props.setError('columnSpacing', 'Either fill both height and width or none');
    }
    if (!isNaN(value)) {
      if (value < 1) {
        props.setError('columnSpacing', 'Both height and width must be greater than or equal to 1');
      } else {
        props.setError('columnSpacing', '');
      }
      props.setDataField(field, value);
    } else {
      props.setDataField(field, null);
    }
    if (
      field === 'columnSpacingHeight' &&
      isNaN(value) &&
      isNaN(parseInt((props.data.columnSpacingWidth as unknown) as string))
    ) {
      props.setError('columnSpacing', '');
    }
    if (
      field === 'columnSpacingWidth' &&
      isNaN(value) &&
      isNaN(parseInt((props.data.columnSpacingHeight as unknown) as string))
    ) {
      props.setError('columnSpacing', '');
    }
  };

  const handleChangeRatioScale = (value: string, pos: 0 | 1) => {
    let values = props.data?.parkingRatio ? props.data?.parkingRatio.split(':') : ['', ''];
    values = values.length === 2 ? values : ['', ''];
    if (pos === 0 && values[1]) {
      props.setError('parkingRatio', 'Either fill both ratio fields or none');
      values[0] = '';
      props.setDataField('parkingRatio', values?.join(':'));
    }
    if (pos === 1 && values[0]) {
      props.setError('parkingRatio', 'Either fill both ratio fields or none');
      values[1] = '';
      props.setDataField('parkingRatio', values?.join(':'));
    }
    if (!isNaN(parseFloat(value))) {
      if (parseFloat(value) <= 0) {
        props.setError('parkingRatio', 'Both ratio values must be greater than or equal to 0');
      } else {
        props.setError('parkingRatio', '');
      }
      if (value.split('.').length > 2) {
        values[pos] = parseFloat(value).toString();
      } else {
        values[pos] = value;
      }
      props.setDataField('parkingRatio', values?.join(':'));
    }
    if (pos === 0 && !value && !values[1]) {
      props.setError('parkingRatio', '');
      props.setDataField('parkingRatio', '');
    }
    if (pos === 1 && !value && !values[0]) {
      props.setError('parkingRatio', '');
      props.setDataField('parkingRatio', '');
    }
  };

  const validateParkingRatioOnBlur = () => {
    if (props.data.parkingRatio) {
      const ratioValues = props.data.parkingRatio.split(':');
      if (ratioValues.length !== 2) {
        props.setError('parkingRatio', 'Either fill both ratio values or none');
      } else {
        if ((ratioValues[0] && !ratioValues[1]) || (!ratioValues[0] && ratioValues[1])) {
          props.setError('parkingRatio', 'Either fill both ratio values or none');
        }
      }
    }
  };

  const validateHeightAndWidthOnBlur = () => {
    if (
      (props.data.columnSpacingHeight && !props.data.columnSpacingWidth) ||
      (!props.data.columnSpacingHeight && props.data.columnSpacingWidth)
    ) {
      props.setError('columnSpacing', 'Either fill both height and width or none');
    }
  };

  const validateWebsite = (e: string) => {
    if (!validateUrl(e)) props.setError('website', 'Website must be a valid URL');
    else props.setError('website', '');
  };

  return (
    <div className="step--content step--content-2">
      <div className="dashboard--opt">
        <a onClick={props.goBack} className="back__btn">
          <span className="back__btn-icon">
            <i className="icon-chevron-left"></i>
          </span>
          Back
        </a>
        <HamBurger className="menu__toggle" />
      </div>
      {buildingId && (
        <div className="dashboard--head editListing--head">
          <h3 className="dashboard__heading d-flex align-items-center">
            Edit Building Specifications
          </h3>
          <div className="editListing--head-btn">
            <a
              onClick={() => history.push(`/building/${buildingId}/listings`)}
              className="outline__button underline__button sm__button"
            >
              Cancel
            </a>
            <button
              className="admin__button underline__button sm__button"
              disabled={
                props.buildingDetailsResponse.loading || props.updateBuildingResponse.loading
              }
              onClick={props.onSave}
            >
              {props.updateBuildingResponse.loading ? 'Saving' : 'Save'}
              {props.updateBuildingResponse.loading && <SmallLoader />}
            </button>
          </div>
        </div>
      )}
      {!buildingId && (
        <div className="dashboard--head pb-2">
          <h3 className="dashboard__heading d-flex align-items-center">Building Specifications</h3>
          <div className="step--head">
            <span className="step__text">Step 2/4:</span>
            <span className="step__info">Building Specifications</span>
          </div>
        </div>
      )}
      <Form>
        <div className="row row--space-12">
          <div className="col-12 col-sm-6">
            {buildingId && <Form.Label>Year built*</Form.Label>}
            <Form.Group className="forms--group">
              <Form.Control
                type="text"
                placeholder="Year built *"
                value={props.data.yearBuilt}
                onChange={(e: any) => handleYearChange('yearBuilt', parseInt(e.target.value))}
                className={props.errors.yearBuilt ? 'error' : ''}
              />
              {props.errors.yearBuilt && (
                <span className="error__msg">{props.errors.yearBuilt}</span>
              )}
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            {buildingId && <Form.Label>Year renovated*</Form.Label>}
            <Form.Group className="forms--group">
              <Form.Control
                type="text"
                placeholder="Year renovated *"
                value={props.data.yearRenovated}
                onChange={(e: any) => handleYearChange('yearRenovated', parseInt(e.target.value))}
                className={props.errors.yearRenovated ? 'error' : ''}
              />
              {props.errors.yearRenovated && (
                <span className="error__msg">{props.errors.yearRenovated}</span>
              )}
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            {buildingId && <Form.Label>Number of floors*</Form.Label>}
            <Form.Group className="forms--group">
              <Form.Control
                type="text"
                placeholder="Number of floors *"
                value={props.data.floorsCount}
                onChange={(e: any) => handleNumberChanges('floorsCount', parseInt(e.target.value))}
                className={props.errors.floorsCount ? 'error' : ''}
              />
              {props.errors.floorsCount && (
                <span className="error__msg">{props.errors.floorsCount}</span>
              )}
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            {buildingId && <Form.Label>Building size*</Form.Label>}
            <Form.Group className="forms--group sf--group">
              <Form.Control
                type="text"
                placeholder="Building size *"
                value={props.data.buildingSize}
                onChange={(e: any) => handleNumberChanges('buildingSize', parseInt(e.target.value))}
                className={props.errors.buildingSize ? 'error' : ''}
              />
              {props.errors.buildingSize && (
                <span className="error__msg">{props.errors.buildingSize}</span>
              )}
              <span className="sf__text">sf</span>
            </Form.Group>
          </div>
          <div className="col-12 col-md-6">
            {buildingId && <Form.Label>Average floor*</Form.Label>}
            <Form.Group className="forms--group sf--group">
              <Form.Control
                type="text"
                placeholder="Average floor *"
                value={props.data.averageFloor}
                onChange={(e: any) => handleNumberChanges('averageFloor', parseInt(e.target.value))}
                className={props.errors.averageFloor ? 'error' : ''}
              />
              {props.errors.averageFloor && (
                <span className="error__msg">{props.errors.averageFloor}</span>
              )}
              <span className="sf__text">sf</span>
            </Form.Group>
          </div>
          <div className="col-12 col-md-6">
            <div className="combine--group">
              <div className="w-100">
                {buildingId && <Form.Label>Parking ratio scale</Form.Label>}
                <Form.Group className="forms--group">
                  <Form.Control
                    type="text"
                    placeholder="Parking ratio scale"
                    value={
                      props.data.parkingRatio
                        ? props.data.parkingRatio.split(':').length === 2
                          ? props.data.parkingRatio.split(':')[0]
                          : ''
                        : ''
                    }
                    onChange={(e: any) => {
                      handleChangeRatioScale(e.target.value, 0);
                    }}
                    onBlur={validateParkingRatioOnBlur}
                    className={props.errors.parkingRatio ? 'error' : ''}
                  />
                </Form.Group>
              </div>
              <span
                className="combine__group-cross"
                style={buildingId ? { marginTop: '30px' } : {}}
              >
                <i className="icon-cross"></i>
              </span>
              <div className="w-100">
                {buildingId && <Form.Label>Parking ratio scale</Form.Label>}
                <Form.Group className="forms--group">
                  <Form.Control
                    type="text"
                    placeholder="Parking ratio scale"
                    value={
                      props.data.parkingRatio
                        ? props.data.parkingRatio.split(':').length === 2
                          ? props.data.parkingRatio.split(':')[1]
                          : ''
                        : ''
                    }
                    onChange={(e: any) => {
                      handleChangeRatioScale(e.target.value, 1);
                    }}
                    onBlur={validateParkingRatioOnBlur}
                    className={props.errors.parkingRatio ? `error ${buildingId ? 'edit' : ''}` : ''}
                  />
                </Form.Group>
              </div>
              {props.errors.parkingRatio && (
                <span className={`error__msg ${buildingId ? 'edit' : ''}`}>
                  {props.errors.parkingRatio}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="combine--group">
              <div className="w-100">
                {buildingId && <Form.Label>Column spacing height</Form.Label>}
                <Form.Group className="forms--group">
                  <Form.Control
                    type="text"
                    placeholder="Column spacing height"
                    value={props.data.columnSpacingHeight}
                    onChange={(e: any) =>
                      handleColumnSpacingChange(parseInt(e.target.value), 'columnSpacingHeight')
                    }
                    onBlur={validateHeightAndWidthOnBlur}
                    className={props.errors.columnSpacing ? 'error' : ''}
                  />
                </Form.Group>
              </div>
              <span
                className="combine__group-cross"
                style={buildingId ? { marginTop: '30px' } : {}}
              >
                <i className="icon-cross"></i>
              </span>
              <div className="w-100">
                {buildingId && <Form.Label>Column spacing width</Form.Label>}
                <Form.Group className="forms--group">
                  <Form.Control
                    type="text"
                    placeholder="Column spacing width"
                    value={props.data.columnSpacingWidth}
                    onChange={(e: any) =>
                      handleColumnSpacingChange(parseInt(e.target.value), 'columnSpacingWidth')
                    }
                    onBlur={validateHeightAndWidthOnBlur}
                    className={props.errors.columnSpacing ? 'error' : ''}
                  />
                </Form.Group>
              </div>
              {props.errors.columnSpacing && (
                <span className={`error__msg ${buildingId ? 'edit' : ''}`}>
                  {props.errors.columnSpacing}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            {buildingId && <Form.Label>Slab to slab height</Form.Label>}
            <Form.Group className="forms--group">
              <Form.Control
                type="text"
                placeholder="Slab to slab height e.g. 2'22&quot;"
                value={props.data.slabToSlabHeight}
                onChange={(e: any) => props.setDataField('slabToSlabHeight', e.target.value)}
                className={props.errors.slabToSlabHeight ? 'error' : ''}
              />
              {props.errors.slabToSlabHeight && (
                <span className="error__msg">{props.errors.slabToSlabHeight}</span>
              )}
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            {buildingId && <Form.Label>Passenger elevators*</Form.Label>}
            <Form.Group className="forms--group">
              <Form.Control
                type="text"
                placeholder="Passenger elevators *"
                value={props.data.passengerElevators}
                onChange={(e: any) => {
                  const value = e.target.value;
                  if (!value) {
                    props.setError('passengerElevators', 'This field is required');
                  } else {
                    props.setError('passengerElevators', '');
                  }
                  props.setDataField('passengerElevators', value);
                }}
                className={props.errors.passengerElevators ? 'error' : ''}
              />
              {props.errors.passengerElevators && (
                <span className="error__msg">{props.errors.passengerElevators}</span>
              )}
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            {buildingId && <Form.Label>Freight elevators</Form.Label>}
            <Form.Group className="forms--group">
              <Form.Control
                type="text"
                placeholder="Freight elevators"
                value={props.data.freightElevators}
                onChange={(e: any) => {
                  props.setDataField('freightElevators', e.target.value);
                }}
                className={props.errors.freightElevators ? 'error' : ''}
              />
              {props.errors.freightElevators && (
                <span className="error__msg">{props.errors.freightElevators}</span>
              )}
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            {buildingId && <Form.Label>Telecom/Data providers</Form.Label>}
            <Form.Group className="forms--group">
              <Form.Control
                type="text"
                placeholder="Telecom/Data providers"
                value={props.data.telecomProviders}
                onChange={(e: any) => {
                  props.setDataField('telecomProviders', e.target.value);
                }}
                className={props.errors.telecomProviders ? 'error' : ''}
              />
              {props.errors.telecomProviders && (
                <span className="error__msg">{props.errors.telecomProviders}</span>
              )}
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            {buildingId && <Form.Label>Leed Certification</Form.Label>}
            <Form.Group className="forms--group select--group w-100">
              <Form.Control
                as="select"
                value={props.data.leedCertification}
                onChange={(e: any) => props.setDataField('leedCertification', e.target.value)}
              >
                <option value="">Leed Certification</option>
                <option value="Certified">Certified</option>
                <option value="Silver">Silver</option>
                <option value="Gold">Gold</option>
                <option value="Platinum">Platinum</option>
              </Form.Control>
              <span className="select__caret-down">
                <i className="icon-caret-down"></i>
              </span>
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            {buildingId && <Form.Label>Energy star certification</Form.Label>}
            <Form.Group className="forms--group select--group">
              <Form.Control
                as="select"
                value={props.data.energyStarCertification}
                onChange={(e: any) => props.setDataField('energyStarCertification', e.target.value)}
              >
                <option value="">Energy star certification</option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Control>
              <span className="select__caret-down">
                <i className="icon-caret-down"></i>
              </span>
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            {buildingId && <Form.Label>Building website link</Form.Label>}
            <Form.Group className="forms--group">
              <Form.Control
                type="text"
                placeholder="Building website link"
                value={props.data.website}
                onChange={(e: any) => {
                  props.setDataField('website', e.target.value);
                  validateWebsite(e.target.value);
                }}
                onBlur={(e: any) => validateWebsite(e.target.value)}
                className={props.errors.website ? 'error' : ''}
              />
              {props.errors.website && <span className="error__msg">{props.errors.website}</span>}
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            {buildingId && <Form.Label>Other</Form.Label>}
            <Form.Group className="forms--group">
              <Form.Control
                type="text"
                placeholder="Other"
                value={props.data.other}
                onChange={(e: any) => {
                  props.setDataField('other', e.target.value);
                }}
                className={props.errors.other ? 'error' : ''}
              />
              {props.errors.other && <span className="error__msg">{props.errors.other}</span>}
            </Form.Group>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12">
            <p className="dashboard__paragraph">Building Hours *</p>
            <div className="listing--schedule-block">
              {buildingHours.map((buildingTime: any, index: number) => {
                return (
                  <div className="listing--schedule" key={index}>
                    <div className="listing--schedule-col listing--schedule-left">
                      <div className="week--time">
                        <Form.Group className="forms--group select--group">
                          <Form.Control
                            as="select"
                            value={buildingTime.openingDay}
                            onChange={(e: any) => {
                              handleBuildingHoursChange(e.target.value, index, 'openingDay');
                            }}
                          >
                            {renderDayOptions()}
                          </Form.Control>
                          <span className="select__caret-down">
                            <i className="icon-caret-down"></i>
                          </span>
                        </Form.Group>
                      </div>
                      <div className="hours--time">
                        <Form.Group className="forms--group select--group">
                          <Form.Control
                            as="select"
                            value={buildingTime.openingTime}
                            onChange={(e: any) => {
                              handleBuildingHoursChange(e.target.value, index, 'openingTime');
                            }}
                          >
                            {renderTimeOptions()}
                          </Form.Control>
                          <span className="select__caret-down">
                            <i className="icon-caret-down"></i>
                          </span>
                        </Form.Group>
                      </div>
                    </div>
                    <span className="hours__change">To</span>
                    <div className="listing--schedule-col listing--schedule-right">
                      <div className="week--time">
                        <Form.Group className="forms--group select--group">
                          <Form.Control
                            as="select"
                            value={buildingTime.closingDay}
                            onChange={(e: any) => {
                              handleBuildingHoursChange(e.target.value, index, 'closingDay');
                            }}
                          >
                            {renderDayOptions()}
                          </Form.Control>
                          <span className="select__caret-down">
                            <i className="icon-caret-down"></i>
                          </span>
                        </Form.Group>
                      </div>
                      <div className="hours--time">
                        <Form.Group className="forms--group select--group">
                          <Form.Control
                            as="select"
                            value={buildingTime.closingTime}
                            onChange={(e: any) => {
                              handleBuildingHoursChange(e.target.value, index, 'closingTime');
                            }}
                          >
                            {renderTimeOptions()}
                          </Form.Control>
                          <span className="select__caret-down">
                            <i className="icon-caret-down"></i>
                          </span>
                        </Form.Group>
                      </div>
                    </div>
                    {buildingHours.length > 1 && (
                      <a
                        className="adminSettings--upload-del hours__del"
                        onClick={() => {
                          setBuildingHours(prevState => {
                            const newState = cloneDeep(prevState);
                            newState.splice(index, 1);
                            return newState;
                          });
                        }}
                      >
                        <i className="icon-trash"></i>
                      </a>
                    )}
                  </div>
                );
              })}
              <a
                onClick={() => {
                  setBuildingHours(prevState => {
                    const newState = cloneDeep(prevState);
                    newState.push(initialBuildingHours);
                    return newState;
                  });
                }}
                className="add__options-button"
              >
                Add More Hours +
              </a>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 listing--schedule-block">
            <p className="dashboard__paragraph">Building Amenities</p>
            <div className="row row--space-12">
              {amenities.map((amenity: string, index: number) => {
                return (
                  <div className="col-12 col-sm-6" key={index}>
                    {buildingId && <Form.Label>Amenity {index + 1}</Form.Label>}
                    <Form.Group className="forms--group">
                      <Form.Control
                        type="text"
                        value={amenity}
                        placeholder={`Amenity ${index + 1}`}
                        onChange={(e: any) => {
                          handleAmenityInputChange(e.target.value, index);
                        }}
                      />
                    </Form.Group>
                  </div>
                );
              })}
            </div>
            {amenities.length < MAXIMUM_AMENITIES_ALLOWED && (
              <a
                onClick={() => {
                  setAmenities(prevState => {
                    const newState = cloneDeep(prevState);
                    newState.push('');
                    return newState;
                  });
                }}
                className="add__options-button"
              >
                Add Amenity +
              </a>
            )}
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 listing--schedule-block">
            <p className="dashboard__paragraph">Building Amenities Matterport Links</p>
            <div className="row row--space-12">
              {amenitiesMatterportLinks.map(
                (amenityMatterportLink: AmenityMatterPortLinkWithError, index: number) => {
                  return (
                    <div className="col-12 col-sm-12" key={index}>
                      {buildingId && <Form.Label>Amenity Matterport link {index + 1}</Form.Label>}
                      <div className="row">
                        <div className="col-12 col-sm-4" key={index}>
                          <Form.Group className="forms--group">
                            <Form.Control
                              type="text"
                              value={amenityMatterportLink.name}
                              placeholder={`Amenity Matterport Name ${index + 1}`}
                              onChange={(e: any) => {
                                handleAmenityMatterportLinkInputChange(
                                  e.target.value,
                                  index,
                                  'name',
                                );
                              }}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-12 col-sm-8" key={index}>
                          <Form.Group className="forms--group">
                            <Form.Control
                              type="text"
                              value={amenityMatterportLink.url}
                              placeholder={`Amenity Matterport link ${index + 1}`}
                              onChange={(e: any) => {
                                handleAmenityMatterportLinkInputChange(
                                  e.target.value,
                                  index,
                                  'url',
                                );
                              }}
                              className={amenityMatterportLink.error ? 'error' : ''}
                            />
                            {amenityMatterportLink.error && (
                              <span className="error__msg">This field is required</span>
                            )}
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  );
                },
              )}
            </div>
            {amenitiesMatterportLinks.length < MAXIMUM_AMENITIES_ALLOWED && (
              <a
                onClick={() => {
                  setAmenitiesMatterportLinks(prevState => {
                    const newState = cloneDeep(prevState);
                    newState.push(AmenityMatterPortInitial());
                    return newState;
                  });
                }}
                className="add__options-button"
              >
                Add Link +
              </a>
            )}
          </div>
        </div>
        {!buildingId && (
          <div className="stepBtn-canvas stepBtn-canvas-2">
            <Button
              type="submit"
              className="admin__button underline__button step__button"
              onClick={props.onContinue}
              disabled={props.addBuildingLoading}
            >
              {props.addBuildingLoading ? 'Creating' : 'Continue'}
              {props.addBuildingLoading && <SmallLoader />}
              <i className="icon-right-arrow"></i>
            </Button>
          </div>
        )}
      </Form>
      {buildingId && (
        <div className="prev--next-option">
          <a className="pages__option prev__option" onClick={() => props.setStep(1)}>
            <span className="pages__option-icon">
              <i className="icon-arrow-left"></i>
            </span>
            <span className="pages__option-text">Previous</span>
          </a>
          <a className="pages__option next__option"></a>
        </div>
      )}
    </div>
  );
};

export default BuildingSpecificationsForm;
