import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { useClearAllBuildings, useGetAllBuildings } from '../../../../state/building/building.hook';
import { getHumanizeTime } from '../../../../utils/misc.utils';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import SmallLoader from '../../../common/small.loader';
import Loader from '../../../common/loader';
import {
  GetAllBuildingsDataInterface,
  GetAllBuildingsSingleBuildingInterface,
} from '../../../../state/building/building.types';
import { debounce } from 'lodash';
import HamBurger from '../../../leftSidebar/hamburger';

const AllBuildings: React.FC = () => {
  const history = useHistory();
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const { data: allBuildings, error, hasData, loading, fetchMore } = useGetAllBuildings(
    (me?.company?._id as unknown) as string,
  );
  const { clearAllBuildings } = useClearAllBuildings();

  useEffect(() => {
    const container = document.getElementById('BuildingsParentScrollContainer');
    if (container) {
      container.onscroll = debounce(() => {
        if (error || loading || (allBuildings && !allBuildings?.hasMore)) return <></>;

        if (
          Math.abs(
            Math.floor(container.scrollHeight - container.scrollTop) - container.clientHeight,
          ) < Math.floor(container.clientHeight * 0.2)
        ) {
          fetchMore();
        }
      }, 100);
    }
  });

  useEffect(() => {
    return () => {
      clearAllBuildings();
    };
  }, []);

  return (
    <div className="dashboard--content" id="BuildingsParentScrollContainer">
      {loading && !allBuildings && <Loader />}
      <div className="dashboard--container">
        <div className="dashboard--canvas">
          <div className="step--content step--content-1">
            <div className="dashboard--opt">
              <HamBurger className="menu__toggle" />
            </div>
            {allBuildings?.data && allBuildings?.data?.length > 0 && (
              <React.Fragment>
                <div className="dashboard--head mb-4 pb-2">
                  <h3 className="dashboard__heading">Buildings</h3>
                  <div className="bl--head-btn">
                    <button
                      className="admin__button underline__button w-100"
                      onClick={() => history.push('/building/create')}
                    >
                      Add Building +
                    </button>
                  </div>
                </div>
                {allBuildings?.data?.map(
                  (subMarket: GetAllBuildingsDataInterface, index: number) => {
                    return (
                      <React.Fragment key={index}>
                        <h3 className="al__heading-medium mb-3 pb-1 pt-3">{subMarket.subMarket}</h3>
                        <div className="buildingListing--inner buildings--inner pt-0">
                          {subMarket.buildings?.length &&
                            subMarket.buildings?.map(
                              (building: GetAllBuildingsSingleBuildingInterface, index: number) => {
                                return (
                                  <div
                                    className="al--block buildings--block"
                                    key={index}
                                    onClick={() =>
                                      history.push(`/building/${building._id}/listings`)
                                    }
                                  >
                                    <div className="al--block-img">
                                      <img src={compileAvatarUrl(building.imageUrl)} alt="Img" />
                                    </div>
                                    <div className="al--block-detail">
                                      <div className="ald--inner al--adress-component">
                                        <label className="al__address">
                                          {building.location.split(',')[0]}
                                        </label>
                                        <div className="al--listing-box">
                                          <div className="al--listing">
                                            <span className="al__icon">
                                              <i className="icon-map-marker-light"></i>
                                            </span>
                                            <span className="al__text">{subMarket.subMarket}</span>
                                          </div>
                                        </div>
                                        <span className="modified__text mb-3 mb-lg-0">
                                          <span className="modified__text-icon">
                                            <i className="icon-schedule-alt"></i>
                                          </span>
                                          Modified {getHumanizeTime(building.updatedAt)}
                                        </span>
                                      </div>
                                      <div className="ald--inner bb-sf">
                                        <div className="bb--inner b--bl">
                                          <span className="b--sf-heading">
                                            {building?.totalListings
                                              ? building.totalListings
                                              : 'N/A'}
                                          </span>
                                          <span className="b--sf-text">Listings</span>
                                        </div>
                                        <div className="bb--inner b--sf">
                                          <span className="b--sf-heading">
                                            {building.totalListingsDetails.totalSquareFoot
                                              ? building.totalListingsDetails.totalSquareFoot.toLocaleString()
                                              : 'N/A'}
                                          </span>
                                          <span className="b--sf-text">Square Feet</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              },
                            )}
                        </div>
                      </React.Fragment>
                    );
                  },
                )}
              </React.Fragment>
            )}
            {allBuildings?.data && allBuildings?.data?.length === 0 && (
              <React.Fragment>
                <div className="heading--group position-relative pr-0">
                  <h3 className="dashboard__heading mb-0">Buildings</h3>
                </div>
                <div className="empty--box empty--buidlings">
                  <div className="empty--box-content">
                    <span className="emptyBox__img mb-4">
                      <img src="/images/empty-building.svg" alt="Empty Building" />
                    </span>
                    <h3 className="heading__medium heading__medium-black mb-3">
                      Oh oh, there are no buildings here yet!
                    </h3>
                    <p className="em__para text__gray mb-4 pb-3">
                      Start adding your buildings today.
                    </p>
                    <button
                      className="admin__button underline__button"
                      onClick={() => history.push('/building/create')}
                    >
                      Add Building +
                    </button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
          {loading && allBuildings && (
            <div className="loading--more">
              <span>Loading More</span>
              <SmallLoader />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllBuildings;
