import React, { useEffect, useState } from 'react';
import TourInfo from './tourInfo';
import HandleTourEdit from './editTour';
import TourBrokers from './tourBrokers';
import DeleteTour from './deleteTour';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { showNotification } from '../../../utils/misc.utils';
import { errorExtractor } from '../../../utils/error.utils';
import { useChangeTourListing } from '../../../state/tour/tour.hook';
import { useHistory } from 'react-router';
import AddListing from './addListing';
import AddBroker from './addBroker';
import TourEditLoadingShimmer from './loading';

const EditTour: React.FC = () => {
  const history = useHistory();
  const tourInfo = useSelector((state: AppStateInterface) => state.tour.tourInfo);
  const meLoading = useSelector((state: AppStateInterface) => state.me.me.loading);
  const shouldChange = useSelector((state: AppStateInterface) => state.tour.changeTour);
  const { changeTourListing } = useChangeTourListing();
  useEffect(() => {
    if (!tourInfo.loading && tourInfo.hasData) {
      if (tourInfo.error) {
        showNotification('error', 'Tour Error', errorExtractor(tourInfo.error));
        history.push('/tours/active');
      } else if (tourInfo.data && shouldChange) {
        const data = tourInfo.data;
        if (data.listings.length && data.listings[0]) changeTourListing(data.listings[0]);
        else changeTourListing(null);
      }
    }
  }, [tourInfo, shouldChange]);

  const [showListings, handleListing] = useState(false);
  const [showBrokers, handleBroker] = useState(false);

  const close = () => {
    handleListing(false);
    handleBroker(false);
  };

  return (
    <React.Fragment>
      {(meLoading || tourInfo.loading) && <TourEditLoadingShimmer />}
      {!meLoading && !tourInfo.loading && tourInfo.data && (
        <React.Fragment>
          <TourInfo />
          <HandleTourEdit showListings={() => handleListing(true)} />
          <TourBrokers showBrokers={() => handleBroker(true)} />
          <DeleteTour />
          {showListings && <AddListing close={close} />}
          {showBrokers && <AddBroker close={close} />}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default EditTour;
