import { AxiosPromise, CancelToken } from 'axios';
import api from '../../utils/api.utils';
import {
  GetAllToursQueryInterface,
  GetAllToursResponseInterface,
  GetTourInfo,
  EditTourListingMedia,
} from './tour.types';
import { cleanObject } from '../../utils/apiValidatior';
import { TOUR_TYPES } from '../../common/constants';

export const getAllToursAPI = (
  query: GetAllToursQueryInterface,
  ct?: CancelToken,
): AxiosPromise<GetAllToursResponseInterface> => {
  return api.get('/room/get/all', {
    cancelToken: ct,
    params: cleanObject(query),
  });
};

export const getSingleTourAPI = (id: string, ct?: CancelToken): AxiosPromise<GetTourInfo> => {
  return api.get(`/room/${id}`, {
    cancelToken: ct,
  });
};

export const deleteSingleTourAPI = (id: string, ct?: CancelToken) => {
  return api.delete(`/room/${id}`, {
    cancelToken: ct,
  });
};

export const changeTourTypeAPI = (roomId: string, type: TOUR_TYPES, ct?: CancelToken) => {
  return api.patch(`/room/${roomId}/type/${type}`, {
    cancelToken: ct,
  });
};

export const changeTourUsersAPI = (roomId: string, userIds: string[], ct?: CancelToken) => {
  return api.patch(`/room/users`, { roomId, userIds }, { cancelToken: ct });
};

export const changeTourListingsAPI = (roomId: string, listingIds: string[], ct?: CancelToken) => {
  return api.patch(`/room/listings`, { roomId, listingIds }, { cancelToken: ct });
};

export const changeTourListingMediaAPI = (payload: EditTourListingMedia, ct?: CancelToken) => {
  return api.patch(`/room/listing/media`, payload, { cancelToken: ct });
};
