import { AxiosPromise, CancelToken } from 'axios';
import api from '../../utils/api.utils';
import {
  ListingInterface,
  GetListingObject,
  GetAllListingsResponseInterface,
  GetTourUrlResponseInterface,
  GetRecentListingsQueryInterface,
  GetRecentListingsResponseInterface,
} from './listing.types';
import { cleanObject } from '../../utils/apiValidatior';
import { LISTING_STATUS, VIDEO_STATUS } from '../../common/constants';

export const createListingAPI = (payload: ListingInterface, ct?: CancelToken): AxiosPromise => {
  return api.post('/listing', payload, {
    cancelToken: ct,
  });
};

export const editListingAPI = (
  payload: ListingInterface,
  id: string,
  ct?: CancelToken,
): AxiosPromise => {
  return api.put(`/listing/${id}`, payload, {
    cancelToken: ct,
  });
};

export const getSingleListingAPI = (
  id: string,
  ct?: CancelToken,
): AxiosPromise<GetListingObject> => {
  return api.get(`listing/${id}`, { cancelToken: ct });
};

export const getAllListingsAPI = (
  buildingId: string,
  limit: number,
  skip: number,
  ct?: CancelToken,
): AxiosPromise<GetAllListingsResponseInterface> => {
  return api.get('listing/all/building', {
    cancelToken: ct,
    params: cleanObject({
      buildingId,
      limit,
      skip,
    }),
  });
};

export const changeStatusAPI = (
  listingId: string,
  status: LISTING_STATUS,
  ct?: CancelToken,
): AxiosPromise => {
  return api.patch(
    `/listing/status`,
    { listingId, status },
    {
      cancelToken: ct,
    },
  );
};

export const changeVideoStatusAPI = (
  listingId: string,
  status: VIDEO_STATUS,
  ct?: CancelToken,
): AxiosPromise => {
  return api.patch(
    `/listing/video/status`,
    { listingId, status },
    {
      cancelToken: ct,
    },
  );
};

export const getTourUrlAPI = (
  listingId: string,
  ct?: CancelToken,
): AxiosPromise<GetTourUrlResponseInterface> => {
  return api.post(
    '/room',
    { listingId },
    {
      cancelToken: ct,
    },
  );
};

export const deleteListingAPI = (listingId: string, ct?: CancelToken) => {
  return api.delete(`/listing/${listingId}`, {
    cancelToken: ct,
  });
};

export const getAllRecentListingsAPI = (
  query: GetRecentListingsQueryInterface,
  ct?: CancelToken,
): AxiosPromise<GetRecentListingsResponseInterface> => {
  return api.get('/listing/all/company', {
    cancelToken: ct,
    params: cleanObject(query),
  });
};

export const exportListingsAPI = (ct?: CancelToken): AxiosPromise => {
  return api.get('/export-csv/listing', {
    cancelToken: ct,
  });
};
