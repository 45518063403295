import React from 'react';
import LeftSidebar from '../../../components/leftSidebar';
import HamBurger from '../../../components/leftSidebar/hamburger';
import CreateTour from '../../../components/tour/create';

const CreateTourPage: React.FC = () => {
  return (
    <div className="dashboard--page buildings bg--gray">
      <LeftSidebar active="tour" />
      <div className="dashboard--content dashboard--content-new">
        <div className="dashboard--off">
          <div className="dashboard--opt">
            <HamBurger />
          </div>
          <CreateTour />
        </div>
      </div>
    </div>
  );
};

export default CreateTourPage;
