import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { User } from '../../../../state/listing/listing.types';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import { DEFAULT_AVATAR, SEPARATORS } from '../../../../common/constants';
import { checkIfNumber } from '../../../../utils/misc.utils';

const ListingBrokers: React.FC = () => {
  const info = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing.data,
  );
  const [brokers, setBrokers] = useState([] as User[]);
  useEffect(
    () => (info?.users && Array.isArray(info?.users) ? setBrokers(info.users) : undefined),
    [info],
  );

  return (
    <div className="row row--space-10">
      {brokers.map(broker => (
        <div className="col-12 col-md-6 col-xl-3" key={broker._id}>
          <div className="team--block team--block-alt">
            <div className="teamBlock--img">
              <img
                src={broker?.avatar ? compileAvatarUrl(broker.avatar) : DEFAULT_AVATAR}
                alt={broker.firstName || 'Img'}
                className="img--cover"
              />
            </div>
            <div
              className="teamBlock--detail text-md-left d-flex flex-column
                align-items-center align-items-md-start"
            >
              <h6 className="teamBlock__user">
                {broker.firstName} {broker.lastName}
              </h6>
              <a
                className="teamBlock__email"
                target="_blank"
                rel="noopener noreferrer"
                href={`mailto:${broker.email}&subject=${
                  info?.building?.location?.split(SEPARATORS.COMMA)[0]
                }%20-%20${checkIfNumber(info?.suiteNo) ? 'Suite%20' : ''}${info?.suiteNo}`}
              >
                {broker.email}
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
export default ListingBrokers;
