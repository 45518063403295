import React from 'react';
import LeftSidebar from '../../../components/leftSidebar';
import AllBuildings from '../../../components/building/view/allBuildings';

const BuildingsViewPage: React.FC = () => {
  return (
    <div className="dashboard--page buildings">
      <LeftSidebar active="buildings" />
      <AllBuildings />
    </div>
  );
};

export default BuildingsViewPage;
