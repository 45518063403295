import { GetState, GetStateInterface } from '../../types/GetStateInterface';
import {
  GetAllToursDataInterface,
  GetAllToursQueryInterface,
  GetAllToursResponseInterface,
  TourActions,
  EditTourListingMedia,
} from './tour.types';
import fetchActions from '../../utils/state.utils';
import axios from 'axios';
import { useCallback, useState, useLayoutEffect, useRef } from 'react';
import {
  getAllToursAPI,
  deleteSingleTourAPI,
  changeTourTypeAPI,
  changeTourListingsAPI,
  changeTourUsersAPI,
  changeTourListingMediaAPI,
} from './tour.api';
import {
  DEFAULT_TOUR_ORDER_BY,
  DEFAULT_TOUR_SORT_BY,
  DEFAULT_TOURS_LIMIT,
  DEFAULT_UPCOMING_TOURS_LIMIT,
  TOUR_TYPES,
  DEFAULT_LISTING_LIMIT,
} from '../../common/constants';
import {
  getSingleTour,
  changeTourListing,
  removeTour,
  changeTourTypeAction,
  changeTourListingImages,
  changeTourListingPlans,
} from './tour.action';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '..';
import { getAllListings } from '../listing/listing.action';
import { UploadedMediaInterface } from '../media/media.types';
import { debounce } from 'lodash';

export const useGetAllTours = () => {
  const [res, setRes] = useState<GetStateInterface<GetAllToursResponseInterface>>(GetState);

  const getAllUpcomingTours = useCallback(
    async (query: GetAllToursQueryInterface, prev: GetAllToursDataInterface[] = []) => {
      setRes(prevState => ({ ...prevState, loading: true }));
      fetchActions.callCT(TourActions.GET_ALL_TOURS);
      try {
        const response = await getAllToursAPI(
          {
            sortBy: DEFAULT_TOUR_SORT_BY,
            orderBy: DEFAULT_TOUR_ORDER_BY,
            limit: DEFAULT_UPCOMING_TOURS_LIMIT,
            skip: 0,
            active: 1,
            ...query,
          },
          fetchActions.setCT(TourActions.GET_ALL_TOURS),
        );
        setRes({
          data:
            prev?.length > 0
              ? { ...response.data, data: [...prev, ...(response?.data?.data || [])] }
              : response.data,
          loading: false,
          error: null,
          hasData: true,
        });
      } catch (err) {
        if (!axios.isCancel(err)) {
          setRes({ data: null, loading: false, error: err, hasData: true });
        }
      }
    },
    [],
  );

  const getAllTours = useCallback(
    async (query: GetAllToursQueryInterface, prev: GetAllToursDataInterface[] = []) => {
      setRes(prevState => ({ ...prevState, loading: true }));
      fetchActions.callCT(TourActions.GET_ALL_TOURS);
      try {
        const response = await getAllToursAPI(
          {
            sortBy: DEFAULT_TOUR_SORT_BY,
            orderBy: DEFAULT_TOUR_ORDER_BY,
            limit: DEFAULT_TOURS_LIMIT,
            skip: 0,
            active: 1,
            ...query,
          },
          fetchActions.setCT(TourActions.GET_ALL_TOURS),
        );
        setRes({
          data:
            prev?.length > 0
              ? { ...response.data, data: [...prev, ...(response?.data?.data || [])] }
              : response.data,
          loading: false,
          error: null,
          hasData: true,
        });
      } catch (err) {
        if (!axios.isCancel(err)) {
          setRes({ data: null, loading: false, error: err, hasData: true });
        }
      }
    },
    [],
  );

  return { res, getAllUpcomingTours, getAllTours };
};

export const useGetSingleTour = () => {
  return { getSingleTour };
};
export const useChangeTourListing = () => {
  return { changeTourListing };
};
export const useChangeTourMedia = () => {
  const tourInfo = useSelector((state: AppStateInterface) => state.tour.tourInfo.data);
  const changeTourImages = useCallback(
    async (listingId: string, media: UploadedMediaInterface[]) => {
      if (tourInfo) {
        const allListings = tourInfo.listings;
        const idx = allListings.findIndex(list => list._id === listingId);
        if (idx > -1) allListings[idx].additionalMedia = media;
        changeTourListingImages(media, allListings);
      }
    },
    [],
  );
  return { changeTourImages };
};
export const useChangeTourPlans = () => {
  const tourInfo = useSelector((state: AppStateInterface) => state.tour.tourInfo.data);
  const changeTourPlans = useCallback(
    async (listingId: string, media: UploadedMediaInterface[]) => {
      if (tourInfo) {
        const allListings = tourInfo.listings;
        const idx = allListings.findIndex(list => list._id === listingId);
        if (idx > -1) allListings[idx].additionalFloorPlans = media;
        changeTourListingPlans(media, allListings);
      }
    },
    [],
  );
  return { changeTourPlans };
};
export const useDeleteTour = () => {
  const [res, setRes] = useState(GetState);
  const deleteTour = useCallback(async (roomId: string) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(TourActions.DELETE_SINGLE_TOUR);
    try {
      const res = await deleteSingleTourAPI(
        roomId,
        fetchActions.setCT(TourActions.DELETE_SINGLE_TOUR),
      );
      setRes({ data: res.data, loading: false, error: null, hasData: true });
      removeTour();
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);
  return { res, deleteTour };
};
export const useChangeListingTour = () => {
  const [res, setRes] = useState(GetState);
  const changeListing = useCallback(async (roomId: string, listingIds: string[]) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(TourActions.CHANGE_TOUR_LISTINGS);
    try {
      const res = await changeTourListingsAPI(
        roomId,
        listingIds,
        fetchActions.setCT(TourActions.CHANGE_TOUR_LISTINGS),
      );
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);
  return { res, changeListing };
};
export const useChangeUserTour = () => {
  const [res, setRes] = useState(GetState);
  const changeUsers = useCallback(async (roomId: string, userIds: string[]) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(TourActions.CHANGE_TOUR_USERS);
    try {
      const res = await changeTourUsersAPI(
        roomId,
        userIds,
        fetchActions.setCT(TourActions.CHANGE_TOUR_USERS),
      );
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);
  return { res, changeUsers };
};
export const useChangeTourType = () => {
  const [res, setRes] = useState(GetState);
  const changeTourType = useCallback(async (roomId: string, type: TOUR_TYPES) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(TourActions.CHANGE_TOUR_TYPE);
    try {
      const res = await changeTourTypeAPI(
        roomId,
        type,
        fetchActions.setCT(TourActions.CHANGE_TOUR_TYPE),
      );
      setRes({ data: res.data, loading: false, error: null, hasData: true });
      changeTourTypeAction(type);
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);
  return { res, changeTourType };
};
export const useChangeTourListingMedia = () => {
  const [res, setRes] = useState(GetState);
  const changeMedia = useCallback(async (payload: EditTourListingMedia) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(TourActions.CHANGE_TOUR_USERS);
    try {
      const res = await changeTourListingMediaAPI(
        payload,
        fetchActions.setCT(TourActions.CHANGE_TOUR_USERS),
      );
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);
  return { res, changeMedia };
};

export const useChangeShadowEffect = (axis: 'X' | 'Y') => {
  const [showShadowEffect, setShowShadowEffect] = useState<boolean>(true);
  const containerRef = useRef<any>(null);

  const calculateShadowEffect = useCallback(
    (
      scrollHeight = 0,
      scrollWidth = 0,
      clientHeight = 0,
      clientWidth = 0,
      scrollTop = 0,
      scrollLeft = 0,
    ) => {
      setShowShadowEffect(
        !(
          (axis === 'X' && scrollWidth === clientWidth + scrollLeft) ||
          (axis === 'Y' && scrollHeight === clientHeight + scrollTop)
        ),
      );
    },
    [],
  );

  const onScroll = (event: any) => {
    calculateShadowEffect(
      event?.target?.scrollHeight,
      event?.target?.scrollWidth,
      event?.target?.clientHeight,
      event?.target?.clientWidth,
      event?.target?.scrollTop,
      event?.target?.scrollLeft,
    );
  };

  useLayoutEffect(() => {
    calculateShadowEffect(
      containerRef?.current?.scrollHeight,
      containerRef?.current?.scrollWidth,
      containerRef?.current?.clientHeight,
      containerRef?.current?.clientWidth,
      containerRef?.current?.scrollTop,
      containerRef?.current?.scrollLeft,
    );
    containerRef?.current?.addEventListener('scroll', onScroll);
    return () => {
      containerRef?.current?.removeEventListener('scroll', onScroll);
    };
  });

  return { containerRef, showShadowEffect };
};
