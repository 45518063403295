import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { TourOrderBy, TourSortBy } from '../../../../state/tour/tour.types';
import { DEFAULT_TOUR_OPTION_VALUE, TOURS_SORTING_OPTIONS } from '../../../../common/constants';

interface Props {
  sortBy?: TourSortBy;
  orderBy?: TourOrderBy;
  onSelect: (selectedValue: string) => void;
}

const TourFilters: React.FC<Props> = (props: Props) => {
  const { sortBy, orderBy, onSelect } = props;
  const findSelectedOption = () => {
    return (
      TOURS_SORTING_OPTIONS?.find(option => option.eventKey === `${sortBy}/${orderBy}`)?.value ||
      DEFAULT_TOUR_OPTION_VALUE
    );
  };
  return (
    <Dropdown className="listing--dropdown listing--dropdown-update listing--dropdown-pad dropdown--radius mb-3 mb-md-0">
      <Dropdown.Toggle id="dropdown-basic">
        {findSelectedOption()}
        <span className="select__caret-down">
          <i className="icon-caret-down"></i>
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {TOURS_SORTING_OPTIONS?.map((option, index) => (
          <Dropdown.Item
            key={index}
            eventKey={option.eventKey}
            onSelect={onSelect}
            active={`${sortBy}/${orderBy}` === option.eventKey}
          >
            {option.value}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default TourFilters;
