import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { MeResponseInterface } from '../../../state/me/me.types';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import VideoModal from './videoModal';
import { HOST_TOUR_WISTIA_ID } from '../../../common/constants';

interface Props {
  title: string;
  me: MeResponseInterface;
  onJoin: (name: string, email: string) => void;
  onStart: (name: string, email: string) => void;
  showStart?: boolean;
}

const JoinTour: React.FC<Props> = (props: Props) => {
  const { title, me, showStart, onJoin, onStart } = props;
  const fullName = me.firstName + ' ' + me.lastName;
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div className="virtualTour--combine virtualTour--combine-alt">
      <div className="container container--large">
        <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between">
          <a className="listing__logo vtl-logo">
            <img
              src={
                company?.settings?.darkLogo
                  ? compileAvatarUrl(company.settings.darkLogo.url)
                  : '/images/HomeTour_Dark_Logo.svg'
              }
              alt="Logo"
            />
          </a>
          <p className="schedule__paragraph font-weight-normal mb-0 ml-auto mt-3 mt-sm-0">
            {title}
          </p>
        </div>
      </div>
      <div className="vt--combine vt--combine-alt m-0">
        <div className="vt--container">
          <div className="vt--combine-main pt-5 pt-lg-4 pb-4">
            <div className="adminSettings--head">
              <span className="adminSettings__text">Broker team</span>
            </div>
            <p className="schedule__paragraph text-left">
              Are you hosting this meeting or just joining? Please make sure you select the right
              option. Only one broker can be the host. For more details play the video tutorial.
            </p>
            <div className="meeting--canvas">
              <div className="row row--space-10">
                {showStart && (
                  <div className="col-12 col-sm-6">
                    <div className="meeting--block" onClick={() => onStart(fullName, me.email)}>
                      <div className="meetingBlock--top">
                        <span className="meetingBlock__icon">
                          <img src="/images/chat-user.svg" alt="Img" />
                        </span>
                        <h3 className="heading__medium heading__medium-black">Host Meeting</h3>
                      </div>
                      <p className="meetingBlock__para">
                        Select this option if you are the broker leading this tour. Your screen will
                        have to be shared during the live tour.
                      </p>
                    </div>
                  </div>
                )}
                <div className="col-12 col-sm-6">
                  <div className="meeting--block" onClick={() => onJoin(fullName, me.email)}>
                    <div className="meetingBlock--top">
                      <span className="meetingBlock__icon">
                        <i className="icon-join"></i>
                      </span>
                      <h3 className="heading__medium heading__medium-black">Join Meeting</h3>
                    </div>
                    <p className="meetingBlock__para">
                      Select this option if you are the just joining the tour. Joining will let you
                      see what the hosting broker presents.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="video--player mt-5">
              <a className="video__link" onClick={() => setShowModal(true)}>
                <span className="video__link-icon">
                  <i className="icon-play-circle"></i>
                </span>
                <span className="video__link-text">Play Video Tutorial</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <VideoModal
        show={showModal}
        videoId={HOST_TOUR_WISTIA_ID}
        close={() => setShowModal(false)}
      />
    </div>
  );
};

JoinTour.defaultProps = {
  showStart: false,
};

export default JoinTour;
