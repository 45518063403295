import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const PrivacyPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="terms">
      <div className="container container--expand">
        <Link className="terms__logo d-inline-block" to="/">
          <img src="/images/HomeTour_Dark_Logo.svg" alt="Logo" />
        </Link>
        <div className="terms--content">
          <h3 className="dashboard__heading text-center">Privacy Policy</h3>
          <ul className="terms__list">
            <li>
              <label className="terms__label">Introduction</label>
              <p className="text__gray mb-4">
                Welcome to <strong>Dupont Studios, Inc</strong>
              </p>
              <p className="text__gray mb-4">
                Dupont Studios, Inc (“us”, “we”, or “our”) operates{' '}
                <a className="text__blue">http://www.hometour.com</a> (hereinafter referred to as “
                <strong>Service</strong>”).
              </p>
              <p className="text__gray mb-4">
                Our Privacy Policy governs your visit to{' '}
                <a className="text__blue">http://www.hometour.com</a>, and explains how we collect,
                safeguard and disclose information that results from your use of our Service.
              </p>
              <p className="text__gray mb-4">
                We use your data to provide and improve Service. By using Service, you agree to the
                collection and use of information in accordance with this policy. Unless otherwise
                defined in this Privacy Policy, the terms used in this Privacy Policy have the same
                meanings as in our Terms and Conditions.
              </p>
              <p className="text__gray mb-4">
                Our Terms and Conditions (“<strong>Terms</strong>”) govern all use of our Service
                and together with the Privacy Policy constitutes your agreement with us (“
                <strong>agreement</strong>”).
              </p>
            </li>
            <li>
              <label className="terms__label">Definitions</label>
              <p className="text__gray mb-4">
                <strong>SERVICE</strong> means the{' '}
                <a className="text__blue">http://www.hometour.com</a> website operated by Dupont
                Studios, Inc
              </p>
              <p className="text__gray mb-4">
                <strong>PERSONAL DATA</strong> means data about a living individual who can be
                identified from those data (or from those and other information either in our
                possession or likely to come into our possession).
              </p>
              <p className="text__gray mb-4">
                <strong>USAGE DATA</strong> is data collected automatically either generated by the
                use of Service or from Service infrastructure itself (for example, the duration of a
                page visit).
              </p>
              <p className="text__gray mb-4">
                <strong>COOKIES</strong> are small files stored on your device (computer or mobile
                device).
              </p>
              <p className="text__gray mb-4">
                <strong>DATA CONTROLLER</strong> means a natural or legal person who (either alone
                or jointly or in common with other persons) determines the purposes for which and
                the manner in which any personal data are, or are to be, processed. For the purpose
                of this Privacy Policy, we are a Data Controller of your data.
              </p>
              <p className="text__gray mb-4">
                <strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any natural or legal
                person who processes the data on behalf of the Data Controller. We may use the
                services of various Service Providers in order to process your data more
                effectively.
              </p>
              <p className="text__gray mb-4">
                <strong>DATA SUBJECT</strong> is any living individual who is the subject of
                Personal Data.
              </p>
              <p className="text__gray mb-4">
                <strong>THE USER</strong> is the individual using our Service. The User corresponds
                to the Data Subject, who is the subject of Personal Data.
              </p>
            </li>
            <li>
              <label className="terms__label">Information Collection and Use</label>
              <p className="text__gray mb-4">
                We collect several different types of information for various purposes to provide
                and improve our Service to you.
              </p>
            </li>
            <li>
              <label className="terms__label">Types of Data Collected</label>
              <p className="text__gray mb-4">
                <span className="d-inline-block w-100 font-semibold">Personal Data</span>
                While using our Service, we may ask you to provide us with certain personally
                identifiable information that can be used to contact or identify you (“
                <strong>Personal Data</strong>”). Personally identifiable information may include,
                but is not limited to:
              </p>
              <ul className="alpha__list">
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Cookies and Usage Data</li>
              </ul>
              <p className="text__gray mb-4">
                We may use your Personal Data to contact you with newsletters, marketing or
                promotional materials and other information that may be of interest to you. You may
                opt out of receiving any, or all, of these communications from us by following the
                unsubscribe link or by emailing at{' '}
                <a href="mailto:info@hometour.com" className="text__blue">
                  info@hometour.com
                </a>
                .
              </p>
              <p className="text__gray mb-4">
                <span className="d-inline-block w-100 font-semibold">Usage Data</span> We may also
                collect information that your browser sends whenever you visit our Service or when
                you access Service by or through a mobile device (“<strong>Usage Data</strong>”).
              </p>
              <p className="text__gray mb-4">
                This Usage Data may include information such as your computer{"'"}s Internet
                Protocol address (e.g. IP address), browser type, browser version, the pages of our
                Service that you visit, the time and date of your visit, the time spent on those
                pages, unique device identifiers and other diagnostic data.
              </p>
              <p className="text__gray mb-4">
                When you access Service with a mobile device, this Usage Data may include
                information such as the type of mobile device you use, your mobile device unique ID,
                the IP address of your mobile device, your mobile operating system, the type of
                mobile Internet browser you use, unique device identifiers and other diagnostic
                data.
              </p>
              <p className="text__gray mb-4">
                <span className="d-inline-block w-100 font-semibold">Tracking Cookies Data</span> We
                use cookies and similar tracking technologies to track the activity on our Service
                and we hold certain information.
              </p>
              <p className="text__gray mb-4">
                Cookies are files with a small amount of data which may include an anonymous unique
                identifier. Cookies are sent to your browser from a website and stored on your
                device. Other tracking technologies are also used such as beacons, tags and scripts
                to collect and track information and to improve and analyze our Service.
              </p>
              <p className="text__gray mb-4">
                You can instruct your browser to refuse all cookies or to indicate when a cookie is
                being sent. However, if you do not accept cookies, you may not be able to use some
                portions of our Service.
              </p>
              <p className="text__gray mb-4">Examples of Cookies we use:</p>
              <ul className="alpha__list">
                <li>
                  <span className="font-semibold">Session Cookies:</span> We use Session Cookies to
                  operate our Service.
                </li>
                <li>
                  <span className="font-semibold">Preference Cookies:</span> We use Preference
                  Cookies to remember your preferences and various settings.
                </li>
                <li>
                  <span className="font-semibold">Security Cookies:</span> We use Security Cookies
                  for security purposes.
                </li>
                <li>
                  <span className="font-semibold">Advertising Cookies: </span> Advertising Cookies
                  are used to serve you with advertisements that may be relevant to you and your
                  interests.
                </li>
              </ul>
              <p className="text__gray mb-4">
                <span className="d-inline-block w-100 font-semibold">Other Data</span> While using
                our Service, we may also collect the following information: sex, age, date of birth,
                place of birth, passport details, citizenship, registration at place of residence
                and actual address, telephone number (work, mobile), details of documents on
                education, qualification, professional training, employment agreements,
                non-disclosure agreements, information on bonuses and compensation, information on
                marital status, family members, social security (or other taxpayer identification)
                number, office location and other data.
              </p>
            </li>
            <li>
              <label className="terms__label">Use of Data</label>
              <p className="text__gray mb-4">
                Dupont Studios, Inc uses the collected data for various purposes:
              </p>
              <ul className="alpha__list">
                <li>to provide and maintain our Service;</li>
                <li>to notify you about changes to our Service; </li>
                <li>
                  to allow you to participate in interactive features of our Service when you choose
                  to do so;
                </li>
                <li>to provide customer support; </li>
                <li>
                  to gather analysis or valuable information so that we can improve our Service
                </li>
                <li>to monitor the usage of our Service;</li>
                <li>to detect, prevent and address technical issues;</li>
                <li>to fulfill any other purpose for which you provide it;</li>
                <li>
                  to carry out our obligations and enforce our rights arising from any contracts
                  entered into between you and us, including for billing and collection;
                </li>
                <li>
                  to provide you with notices about your account and/or subscription, including
                  expiration and renewal notices, email-instructions, etc.;
                </li>
                <li>
                  to provide you with news, special offers and general information about other
                  goods, services and events which we offer that are similar to those that you have
                  already purchased or enquired about unless you have opted not to receive such
                  information;
                </li>
                <li>in any other way we may describe when you provide the information;</li>
                <li>in any other way we may describe when you provide the information;</li>
              </ul>
            </li>
            <li>
              <label className="terms__label">Retention of Data</label>
              <p className="text__gray mb-4">
                We will retain your Personal Data only for as long as is necessary for the purposes
                set out in this Privacy Policy. We will retain and use your Personal Data to the
                extent necessary to comply with our legal obligations (for example, if we are
                required to retain your data to comply with applicable laws), resolve disputes, and
                enforce our legal agreements and policies.
              </p>
              <p className="text__gray mb-4">
                We will also retain Usage Data for internal analysis purposes. Usage Data is
                generally retained for a shorter period, except when this data is used to strengthen
                the security or to improve the functionality of our Service, or we are legally
                obligated to retain this data for longer time periods.
              </p>
            </li>
            <li>
              <label className="terms__label">Transfer of Data</label>
              <p className="text__gray mb-4">
                Your information, including Personal Data, may be transferred to – and maintained on
                – computers located outside of your state, province, country or other governmental
                jurisdiction where the data protection laws may differ from those of your
                jurisdiction.
              </p>
              <p className="text__gray mb-4">
                If you are located outside United States and choose to provide information to us,
                please note that we transfer the data, including Personal Data, to United States and
                process it there.
              </p>
              <p className="text__gray mb-4">
                Your consent to this Privacy Policy followed by your submission of such information
                represents your agreement to that transfer.
              </p>
              <p className="text__gray mb-4">
                Dupont Studios, Inc will take all the steps reasonably necessary to ensure that your
                data is treated securely and in accordance with this Privacy Policy and no transfer
                of your Personal Data will take place to an organisation or a country unless there
                are adequate controls in place including the security of your data and other
                personal information.
              </p>
            </li>
            <li>
              <label className="terms__label">Disclosure of Data</label>
              <p className="text__gray mb-4">
                We may disclose personal information that we collect, or you provide:
              </p>
              <ul className="alpha__list">
                <li>
                  <span className="d-inline-block w-100 font-semibold">
                    Disclosure for Law Enforcement.
                  </span>
                  Under certain circumstances, we may be required to disclose your Personal Data if
                  required to do so by law or in response to valid requests by public authorities.
                </li>
                <li>
                  <span className="d-inline-block w-100 font-semibold">Business Transaction.</span>
                  If we or our subsidiaries are involved in a merger, acquisition or asset sale,
                  your Personal Data may be transferred.
                </li>
                <li>
                  <span className="d-inline-block w-100 font-semibold mb-4">
                    Other cases. We may disclose your information also:
                  </span>
                  <ul className="roman__list">
                    <li>to our subsidiaries and affiliates;</li>
                    <li>
                      to contractors, service providers, and other third parties we use to support
                      our business;
                    </li>
                    <li>to fulfill the purpose for which you provide it;</li>
                    <li>for the purpose of including your company{"'"}s logo on our website;</li>
                    <li>for any other purpose disclosed by us when you provide the information;</li>
                    <li>with your consent in any other cases;</li>
                    <li>
                      if we believe disclosure is necessary or appropriate to protect the rights,
                      property, or safety of the Company, our customers, or others.
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <label className="terms__label">Security of Data</label>
              <p className="text__gray mb-4">
                The security of your data is important to us but remember that no method of
                transmission over the Internet or method of electronic storage is 100% secure. While
                we strive to use commercially acceptable means to protect your Personal Data, we
                cannot guarantee its absolute security.
              </p>
            </li>
            <li>
              <label className="terms__label">
                Your Data Protection Rights Under General Data Protection Regulation (GDPR)
              </label>
              <p className="text__gray mb-4">
                If you are a resident of the European Union (EU) and European Economic Area (EEA),
                you have certain data protection rights, covered by GDPR. – See more at{' '}
                <a className="text__blue">https://eur-lex.europa.eu/eli/reg/2016/679/oj</a>
              </p>
              <p className="text__gray mb-4">
                We aim to take reasonable steps to allow you to correct, amend, delete, or limit the
                use of your Personal Data.
              </p>
              <p className="text__gray mb-4">
                If you wish to be informed what Personal Data we hold about you and if you want it
                to be removed from our systems, please email us at{' '}
                <a href="mailto:info@hometour.com" className="text__blue">
                  info@hometour.com
                </a>
                .
              </p>
              <p className="text__gray mb-4">
                In certain circumstances, you have the following data protection rights:
              </p>
              <ul className="alpha__list">
                <li>the right to access, update or to delete the information we have on you;</li>
                <li>
                  the right of rectification. You have the right to have your information rectified
                  if that information is inaccurate or incomplete;
                </li>
                <li>
                  the right to object. You have the right to object to our processing of your
                  Personal Data;
                </li>
                <li>
                  the right of restriction. You have the right to request that we restrict the
                  processing of your personal information;
                </li>
                <li>
                  the right to data portability. You have the right to be provided with a copy of
                  your Personal Data in a structured, machine-readable and commonly used format;
                </li>
                <li>
                  the right to withdraw consent. You also have the right to withdraw your consent at
                  any time where we rely on your consent to process your personal information;
                </li>
              </ul>
              <p className="text__gray mb-4">
                Please note that we may ask you to verify your identity before responding to such
                requests.
              </p>
              <p className="text__gray mb-4">
                Please note, we may not able to provide Service without some necessary data.
              </p>
              <p className="text__gray mb-4">
                You have the right to complain to a Data Protection Authority about our collection
                and use of your Personal Data. For more information, please contact your local data
                protection authority in the European Economic Area (EEA).
              </p>
            </li>
            <li>
              <label className="terms__label">
                Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)
              </label>
              <p className="text__gray mb-4">
                CalOPPA is the first state law in the nation to require commercial websites and
                online services to post a privacy policy. The law{"'"}s reach stretches well beyond
                California to require a person or company in the United States (and conceivable the
                world) that operates websites collecting personally identifiable information from
                California consumers to post a conspicuous privacy policy on its website stating
                exactly the information being collected and those individuals with whom it is being
                shared, and to comply with this policy. – See more at:{' '}
                <a className="text__blue">
                  https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/
                </a>
              </p>
              <p className="text__gray mb-4">According to CalOPPA we agree to the following:</p>
              <ul className="alpha__list">
                <li>users can visit our site anonymously;</li>
                <li>
                  our Privacy Policy link includes the word “<strong>Privacy</strong>”, and can
                  easily be found on the page specified above on the home page of our website;
                </li>
                <li>
                  users will be notified of any privacy policy changes on our Privacy Policy Page;
                </li>
                <li>
                  users are able to change their personal information by emailing us at{' '}
                  <a href="mailto:info@hometour.com" className="text__blue">
                    info@hometour.com
                  </a>
                  .
                </li>
              </ul>
              <p className="text__gray mb-4">Our Policy on “Do Not Track” Signals:</p>
              <p className="text__gray mb-4">
                We honor Do Not Track signals and do not track, plant cookies, or use advertising
                when a Do Not Track browser mechanism is in place. Do Not Track is a preference you
                can set in your web browser to inform websites that you do not want to be tracked.
              </p>
              <p className="text__gray mb-4">
                You can enable or disable Do Not Track by visiting the Preferences or Settings page
                of your web browser.
              </p>
            </li>
            <li>
              <label className="terms__label">Service Providers</label>
              <p className="text__gray mb-4">
                We may employ third party companies and individuals to facilitate our Service (“
                <strong>Service Providers</strong>”), provide Service on our behalf, perform
                Service-related services or assist us in analysing how our Service is used.
              </p>
              <p className="text__gray mb-4">
                These third parties have access to your Personal Data only to perform these tasks on
                our behalf and are obligated not to disclose or use it for any other purpose.
              </p>
            </li>
            <li>
              <label className="terms__label">Analytics</label>
              <p className="text__gray mb-4">
                We may use third-party Service Providers to monitor and analyze the use of our
                Service.
              </p>
              <p className="text__gray mb-4">
                <span className="d-inline-block w-100 font-semibold">Google Analytics</span>
                Google Analytics is a web analytics service offered by Google that tracks and
                reports website traffic. Google uses the data collected to track and monitor the use
                of our Service. This data is shared with other Google services. Google may use the
                collected data to contextualise and personalise the ads of its own advertising
                network.
              </p>
              <p className="text__gray mb-4">
                For more information on the privacy practices of Google, please visit the Google
                Privacy Terms web page:{' '}
                <a className="text__blue">https://policies.google.com/privacy?hl=en</a>.
              </p>
              <p className="text__gray mb-4">
                We also encourage you to review the Google{"'"}s policy for safeguarding your data:{' '}
                <a className="text__blue">https://support.google.com/analytics/answer/6004245</a>.
              </p>
            </li>
            <li>
              <label className="terms__label">CI/CD tools</label>
              <p className="text__gray mb-4">
                We may use third-party Service Providers to automate the development process of our
                Service.
              </p>
              <p className="text__gray mb-4">
                <span className="font-semibold">GitHub</span>
              </p>
              <p className="text__gray mb-4">GitHub is provided by GitHub, Inc.</p>
              <p className="text__gray mb-4">
                GitHub is a development platform to host and review code, manage projects, and build
                software.
              </p>
              <p className="text__gray mb-4">
                For more information on what data GitHub collects for what purpose and how the
                protection of the data is ensured, please visit GitHub Privacy Policy page:{' '}
                <a className="text__blue">
                  https://help.github.com/en/articles/github-privacy-statement
                </a>
                .
              </p>
            </li>
            <li>
              <label className="terms__label">Behavioral Remarketing</label>
              <p className="text__gray mb-4">
                Dupont Studios, Inc uses remarketing services to advertise on third party websites
                to you after you visited our Service. We and our third-party vendors use cookies to
                inform, optimise and serve ads based on your past visits to our Service.
              </p>
              <p className="text__gray mb-4">
                <span className="d-inline-block w-100 font-semibold">Google Ads (AdWords)</span>
                Google Ads (AdWords) remarketing service is provided by Google Inc.
              </p>
              <p className="text__gray mb-4">
                You can opt-out of Google Analytics for Display Advertising and customize the Google
                Display Network ads by visiting the Google Ads Settings page:{' '}
                <a className="text__blue">http://www.google.com/settings/ads</a>.
              </p>
              <p className="text__gray mb-4">
                Google also recommends installing the Google Analytics Opt-out Browser Add-on –{' '}
                <a className="text__blue">https://tools.google.com/dlpage/gaoptout</a> – for your
                web browser. Google Analytics Opt-out Browser Add-on provides visitors with the
                ability to prevent their data from being collected and used by Google Analytics.
              </p>
              <p className="text__gray mb-4">
                For more information on the privacy practices of Google, please visit the Google
                Privacy Terms web page:{' '}
                <a className="text__blue">https://policies.google.com/privacy?hl=en</a>.
              </p>
              <p className="text__gray mb-4">
                <span className="d-inline-block w-100 font-semibold">Twitter</span>
                Twitter remarketing service is provided by Twitter Inc.
              </p>
              <p className="text__gray mb-4">
                You can opt-out from Twitter{"'"}s interest-based ads by following their
                instructions:{' '}
                <a className="text__blue">https://support.twitter.com/articles/20170405</a>.
              </p>
              <p className="text__gray mb-4">
                You can learn more about the privacy practices and policies of Twitter by visiting
                their Privacy Policy page: <a className="text__blue">https://twitter.com/privacy</a>
                .
              </p>
              <p className="text__gray mb-4">
                <span className="d-inline-block w-100 font-semibold">Facebook</span>
                Facebook remarketing service is provided by Facebook Inc.
              </p>
              <p className="text__gray mb-4">
                You can learn more about interest-based advertising from Facebook by visiting this
                page: <a className="text__blue">https://www.facebook.com/help/164968693837950</a>.
              </p>
              <p className="text__gray mb-4">
                To opt-out from Facebook{"'"}s interest-based ads, follow these instructions from
                Facebook:{' '}
                <a className="text__blue">https://www.facebook.com/help/568137493302217</a>.
              </p>
              <p className="text__gray mb-4">
                Facebook adheres to the Self-Regulatory Principles for Online Behavioural
                Advertising established by the Digital Advertising Alliance. You can also opt-out
                from Facebook and other participating companies through the Digital Advertising
                Alliance in the USA <a className="text__blue">http://www.aboutads.info/choices/</a>,
                the Digital Advertising Alliance of Canada in Canada{' '}
                <a className="text__blue">http://youradchoices.ca/</a>
                or the European Interactive Digital Advertising Alliance in Europe{' '}
                <a className="text__blue">http://www.youronlinechoices.eu/</a>, or opt-out using
                your mobile device settings.
              </p>
              <p className="text__gray mb-4">
                For more information on the privacy practices of Facebook, please visit Facebook
                {"'"}s Data Policy:{' '}
                <a className="text__blue">https://www.facebook.com/privacy/explanation</a>.
              </p>
            </li>
            <li>
              <label className="terms__label">Payments</label>
              <p className="text__gray mb-4">
                We may provide paid products and/or services within Service. In that case, we use
                third-party services for payment processing (e.g. payment processors).
              </p>
              <p className="text__gray mb-4">
                We will not store or collect your payment card details. That information is provided
                directly to our third-party payment processors whose use of your personal
                information is governed by their Privacy Policy. These payment processors adhere to
                the standards set by PCI-DSS as managed by the PCI Security Standards Council, which
                is a joint effort of brands like Visa, Mastercard, American Express and Discover.
                PCI-DSS requirements help ensure the secure handling of payment information.
              </p>
              <p className="text__gray mb-4">The payment processors we work with are:</p>
              <p className="text__gray mb-4">
                <span className="d-inline-block w-100 font-semibold">Stripe:</span>
                Their Privacy Policy can be viewed at:{' '}
                <a className="text__blue">https://stripe.com/us/privacy</a>.
              </p>
            </li>
            <li>
              <label className="terms__label">Links to Other Sites</label>
              <p className="text__gray mb-4">
                Our Service may contain links to other sites that are not operated by us. If you
                click a third party link, you will be directed to that third party{"'"}s site. We
                strongly advise you to review the Privacy Policy of every site you visit.
              </p>
              <p className="text__gray mb-4">
                We have no control over and assume no responsibility for the content, privacy
                policies or practices of any third party sites or services.
              </p>
            </li>
            <li>
              <label className="terms__label">Children{"'"}s Privacy</label>
              <p className="text__gray mb-4">
                Our Services are not intended for use by children under the age of 13 (“
                <strong>Children</strong>”).
              </p>
              <p className="text__gray mb-4">
                We do not knowingly collect personally identifiable information from Children under
                13. If you become aware that a Child has provided us with Personal Data, please
                contact us. If we become aware that we have collected Personal Data from Children
                without verification of parental consent, we take steps to remove that information
                from our servers.
              </p>
            </li>
            <li>
              <label className="terms__label">Changes to This Privacy Policy</label>
              <p className="text__gray mb-4">
                We may update our Privacy Policy from time to time. We will notify you of any
                changes by posting the new Privacy Policy on this page.
              </p>
              <p className="text__gray mb-4">
                We will let you know via email and/or a prominent notice on our Service, prior to
                the change becoming effective and update “<strong>effective date</strong>” at the
                top of this Privacy Policy.
              </p>
              <p className="text__gray mb-4">
                You are advised to review this Privacy Policy periodically for any changes. Changes
                to this Privacy Policy are effective when they are posted on this page.
              </p>
            </li>
            <li>
              <label className="terms__label">Contact Us</label>
              <p className="text__gray mb-4">
                If you have any questions about this Privacy Policy, please contact us:
              </p>
              <p className="text__gray mb-4">
                By email:{' '}
                <a href="mailto:info@hometour.com" className="text__blue">
                  info@hometour.com
                </a>
                .
              </p>
            </li>
          </ul>
        </div>{' '}
      </div>
    </div>
  );
};

export default PrivacyPage;
