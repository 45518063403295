export enum InvitationActions {
  ACCEPT_INVITE = 'ACCEPT_INVITE',
}

export interface AcceptInviteInterface {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  verificationCode: string;
  memberId: string;
  role: string;
  avatar?: string;
}
