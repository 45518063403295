import api from '../../utils/api.utils';
import { ScheduleDemoInterface } from './landing.types';
import { AxiosPromise, CancelToken } from 'axios';
import { cleanObject } from '../../utils/apiValidatior';

export const scheduleDemoAPI = (body: ScheduleDemoInterface): AxiosPromise<boolean> => {
  return api.post('/tour/schedule/demo', body);
};

export const getPublicListingsAPI = (limit: number, skip: number, ct?: CancelToken) => {
  return api.get('/listing/public/all', {
    cancelToken: ct,
    params: cleanObject({
      limit,
      skip,
    }),
  });
};
