import { AxiosPromise, CancelToken } from 'axios';
import api from './../../../utils/api.utils';
import {
  GetListingObject,
  GetAllListingsResponseInterface,
  sortByTypes,
  orderByTypes,
  TourFormInterface,
  GetTourListingRequest,
  GetTourListings,
  GetTourUrlResponseInterface,
} from './listing.types';
import { cleanObject } from './../../../utils/apiValidatior';

export const postTourRequestAPI = (
  payload: TourFormInterface,
  ct?: CancelToken,
): AxiosPromise<GetTourUrlResponseInterface> => {
  return api.post('/room/create/tenant', payload, {
    cancelToken: ct,
  });
};

export const getSingleListingAPI = (
  id: string,
  ct?: CancelToken,
): AxiosPromise<GetListingObject> => {
  return api.get(`listing/${id}`, { cancelToken: ct });
};

export const getAllSubDomainListingsAPI = (
  companyId: string,
  limit: number,
  skip: number,
  search?: string,
  sortBy?: sortByTypes,
  orderBy?: orderByTypes,
  subMarket?: string,
  ct?: CancelToken,
): AxiosPromise<GetAllListingsResponseInterface> => {
  return api.get('listing/all/group', {
    cancelToken: ct,
    params: cleanObject({
      companyId,
      limit,
      skip,
      search,
      sortBy,
      orderBy,
      subMarket,
    }),
  });
};

export const getAllTourListingsAPI = (
  payload: GetTourListingRequest,
  ct?: CancelToken,
): AxiosPromise<GetTourListings> => {
  return api.get('listing/tour/all', {
    cancelToken: ct,
    params: cleanObject(payload),
  });
};
