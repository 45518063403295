import React from 'react';
import ListBox from './listingBox';
import { GetListingObject } from '../../../../state/subdomain/listing/listing.types';

interface Props {
  mapView: boolean;
  market: string;
  subMarket: string;
  listings: GetListingObject[];
  setCenter: (id: string) => void;
}
const ListGroup: React.FC<Props> = (props: Props) => {
  return (
    <div className="available--listing-off">
      <h3 className="al__heading-medium">{`${props.subMarket} - ${props.market}`}</h3>
      {props.listings &&
        props.listings.length > 0 &&
        props.listings.map((listing: GetListingObject, index: number) => {
          return (
            <ListBox
              key={index}
              setCenter={props.setCenter}
              isMapShow={props.mapView}
              data={listing}
              market={props.market}
              subMarket={props.subMarket}
            />
          );
        })}
    </div>
  );
};

export default ListGroup;
