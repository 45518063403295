import { ListingActions, ListingStateInterface } from './listing.types';
import { GetState } from '../../types/GetStateInterface';

const defaultState: ListingStateInterface = {
  allListings: GetState,
  singleListing: GetState,
};

export default function ListingReducer(state = defaultState, action: any) {
  switch (action.type) {
    case ListingActions.GET_ALL_LISTINGS_INIT:
      return {
        ...state,
        allListings: {
          ...state.allListings,
          loading: true,
          error: null,
          data: null,
          hasData: false,
        },
      };
    case ListingActions.GET_ALL_LISTINGS_FAILURE:
      return {
        ...state,
        allListings: {
          ...state.allListings,
          loading: false,
          error: action.payload,
          hasData: true,
        },
      };
    case ListingActions.GET_ALL_LISTINGS_SUCCESSFUL:
      return {
        ...state,
        allListings: {
          ...state.allListings,
          loading: false,
          error: null,
          data: action.payload,
          hasData: true,
        },
      };
    case ListingActions.GET_ALL_LISTINGS_CLEAR:
      return {
        ...state,
        allListings: {
          ...state.allListings,
          loading: false,
          error: null,
          data: null,
          hasData: false,
        },
      };

    case ListingActions.GET_SINGLE_LISTING_INIT:
      return {
        ...state,
        singleListing: {
          ...state.singleListing,
          loading: true,
          error: null,
          data: null,
          hasData: false,
        },
      };
    case ListingActions.GET_SINGLE_LISTING_FAILURE:
      return {
        ...state,
        singleListing: {
          ...state.singleListing,
          loading: false,
          error: action.payload,
          hasData: true,
          data: null,
        },
      };
    case ListingActions.GET_SINGLE_LISTING_SUCCESSFUL:
      return {
        ...state,
        singleListing: {
          ...state.singleListing,
          loading: false,
          error: null,
          data: action.payload,
          hasData: true,
        },
      };
    case ListingActions.GET_SINGLE_LISTING_CLEAR:
      return {
        ...state,
        singleListing: {
          ...state.singleListing,
          loading: false,
          error: null,
          data: null,
          hasData: false,
        },
      };
    case ListingActions.LISTING_UPDATE:
      return {
        ...state,
        singleListing: {
          ...state.singleListing,
          data: state.singleListing.data
            ? {
                ...state.singleListing.data,
                status: action.payload,
              }
            : null,
        },
      };
    case ListingActions.VIDEO_UPDATE:
      return {
        ...state,
        singleListing: {
          ...state.singleListing,
          data: state.singleListing.data
            ? {
                ...state.singleListing.data,
                videoStatus: action.payload,
              }
            : null,
        },
      };
    default:
      return state;
  }
}
