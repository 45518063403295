import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { convertSecToString } from '../../../listing.component';
import { CommentsInterface } from '../../../../../state/listing/listing.types';
import { useAddComment, usePauseRecording } from '../../../../../state/review/review.hook';
import { AttachmentLightBox } from '../../../../lightbox';
import {
  ALLOWED_COMMENT_IMAGE_MAX_SIZE_IN_MB,
  ALLOWED_COMMENT_IMAGE_MIME_TYPES,
  ALLOWED_COMMENT_IMAGE_TYPES,
  COMMENT_REQUIRED_ERROR,
} from '../../../../../common/constants';
import { errorExtractor } from '../../../../../utils/error.utils';
import { useUploadMedia } from '../../../../../state/media/media.hook';
import { compileAvatarUrl } from '../../../../../utils/avatar.utils';
import { UploadedMediaInterface } from '../../../../../state/media/media.types';
import { useParams } from 'react-router';
import SmallLoader from '../../../../common/small.loader';
import { showNotification } from '../../../../../utils/misc.utils';
import { cleanObject } from '../../../../../utils/apiValidatior';

interface Props {
  currentTime: number;
  setComments: (
    value: CommentsInterface[] | ((prevState: CommentsInterface[]) => CommentsInterface[]),
  ) => void;
  topCommentRef: any;
  allCommentsRef: any;
}
const ReviewCreateComment: React.FC<Props> = props => {
  const { currentTime, setComments, topCommentRef, allCommentsRef } = props;
  const { listingId } = useParams();
  const formRef = useRef<any>(null);
  const [videoTimestamp, setVideoTimestamp] = useState('');
  const { loading: adding, addComment } = useAddComment(setComments);
  const { res: uploadPhotoRes, uploadSingleMedia: uploadPhoto } = useUploadMedia();
  const [commentText, setCommentText] = useState<string>('');
  const [commentError, setCommentError] = useState<string>('');
  const [commentMedia, setCommentMedia] = useState<UploadedMediaInterface | undefined>(undefined);
  const [showLightBox, setShowLightBox] = useState(false);
  const [attachVideoTimestamp, setAttachVideoTimestamp] = useState(false);
  const [moveToTop, setMoveToTop] = useState<boolean>(false);
  const { pauseRecording } = usePauseRecording();

  useEffect(() => {
    setVideoTimestamp(convertSecToString(currentTime, true));
  }, [currentTime]);

  useLayoutEffect(() => {
    if (moveToTop && topCommentRef && allCommentsRef?.current) {
      if (
        allCommentsRef.current.offsetTop +
          topCommentRef.offsetTop +
          topCommentRef.clientHeight -
          pageYOffset >
        window.innerHeight
      ) {
        window.scrollTo({
          top:
            allCommentsRef.current.offsetTop +
            topCommentRef.offsetTop +
            topCommentRef.clientHeight -
            window.innerHeight,
          behavior: 'smooth',
        });
      }
      setMoveToTop(false);
    }
  }, [moveToTop, topCommentRef]);

  useEffect(() => {
    if (!uploadPhotoRes.loading && uploadPhotoRes.data) {
      setCommentMedia(uploadPhotoRes.data);
    }
    if (!uploadPhotoRes.loading && uploadPhotoRes.error) {
      setCommentError(errorExtractor(uploadPhotoRes.error));
    }
  }, [uploadPhotoRes]);

  const onUpload = (e: any) => {
    pauseRecording();
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (!ALLOWED_COMMENT_IMAGE_MIME_TYPES.includes(file.type)) {
        setCommentError(`Allowed media types: ${ALLOWED_COMMENT_IMAGE_TYPES}`);
        return;
      }
      const fileSizeInMb = file.size / 1000000;
      if (fileSizeInMb > ALLOWED_COMMENT_IMAGE_MAX_SIZE_IN_MB) {
        setCommentError(`Max size allowed: ${ALLOWED_COMMENT_IMAGE_MAX_SIZE_IN_MB} MB`);
        return;
      }
      uploadPhoto({ name: file.name, data: file });
      setCommentError('');
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    pauseRecording();
    const trimmedEditedText = commentText.trim();
    if (commentError) {
      return;
    }
    if (!trimmedEditedText && !commentMedia) {
      setCommentError(COMMENT_REQUIRED_ERROR);
      return;
    }
    addComment(
      cleanObject({
        videoTimestamp: attachVideoTimestamp ? videoTimestamp : undefined,
        content: {
          text: trimmedEditedText,
          media: commentMedia,
        },
      }) as CommentsInterface,
      listingId,
    )
      .then(() => {
        setCommentError('');
        setAttachVideoTimestamp(false);
        setCommentText('');
        setCommentMedia(undefined);
        setMoveToTop(true);
        showNotification('success', 'Success', 'Posted successfully');
      })
      .catch((error: any) => {
        showNotification('error', 'Error', errorExtractor(error));
      });
  };

  return (
    <React.Fragment>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Form.Group className="forms--group comment--group mb-0">
          <Form.Control
            as="textarea"
            rows={3}
            value={commentText}
            onChange={(e: any) => {
              const trimmedText = e.target.value.trim();
              if (!trimmedText) {
                setCommentText(trimmedText);
              } else {
                setCommentText(e.target.value);
              }
              if ((!e.target.value || !trimmedText) && !commentMedia) {
                setCommentError(COMMENT_REQUIRED_ERROR);
              } else {
                setCommentError('');
              }
            }}
            onKeyPress={(e: any) => {
              if (e?.key === 'Enter' && !e?.shiftKey) {
                e.preventDefault();
                formRef?.current?.dispatchEvent(new Event('submit'));
              }
            }}
            onFocus={pauseRecording}
          />
          {commentError && <p className="error__msg position-static">{commentError}</p>}
        </Form.Group>
        <div className="d-flex flex-column-reverse flex-sm-row align-items-sm-center justify-content-between mb-4 pb-2">
          <label className="checkbox--set checkbox--set-dark mt-3">
            Comment at current timestamp ({videoTimestamp} minutes)
            <input
              type="checkbox"
              onChange={(e: any) => {
                setAttachVideoTimestamp(e.target.checked);
              }}
              checked={attachVideoTimestamp}
              onFocus={pauseRecording}
            />
            <span className="checkmark"></span>
          </label>
          {!commentMedia && (
            <label className="custom__file upload__button upload__media d-inline-flex align-items-center mt-4 mt-sm-3 ml-sm-2 mb-3 mb-sm-0">
              <input type="file" onChange={onUpload} onClick={pauseRecording} />
              {uploadPhotoRes.loading ? (
                <React.Fragment>
                  Uploading <SmallLoader />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  Upload Media
                  <span className="upload__button-icon">
                    <i className="icon-upload"></i>
                  </span>
                </React.Fragment>
              )}
            </label>
          )}
        </div>
        <div className="c--post-canvas d-flex flex-column flex-sm-row align-items-center justify-content-between mb-4 pb-1">
          {commentMedia && (
            <div
              className="ca--cover ca--cover-large"
              onClick={() => {
                pauseRecording();
                setShowLightBox(true);
              }}
            >
              <div className="ca--cover-img">
                <img src={compileAvatarUrl(commentMedia.url)} alt="Img" />
              </div>
              <div className="ca--cover-name">{commentMedia.name}</div>
              <span
                className="cover__cross"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setCommentMedia(undefined);
                  if (!commentText) {
                    setCommentError(COMMENT_REQUIRED_ERROR);
                  }
                }}
              >
                <i className="icon-cross-cirlce"></i>
              </span>
            </div>
          )}
          <Button
            type="submit"
            className={`admin__button mt-3 mt-sm-0 ml-auto ${
              (!commentText && !commentMedia) || uploadPhotoRes.loading ? 'disabled' : ''
            }`}
            disabled={(!commentText && !commentMedia) || uploadPhotoRes.loading}
          >
            {adding ? (
              <React.Fragment>
                Posting <SmallLoader />
              </React.Fragment>
            ) : (
              <React.Fragment>Post Comment</React.Fragment>
            )}
          </Button>
        </div>
      </Form>
      {showLightBox && commentMedia && (
        <AttachmentLightBox
          data={[commentMedia]}
          index={0}
          close={() => setShowLightBox(false)}
          showTitles={true}
        />
      )}
    </React.Fragment>
  );
};

export default ReviewCreateComment;
