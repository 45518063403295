import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import { Modal, Button } from 'react-bootstrap';

interface Props {
  close: () => void;
}
const VideoPanel: React.FC<Props> = props => {
  const { close } = props;
  const listing = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing.data,
  );

  return (
    <Modal show onHide={close} className="video--modal" contained-modal-title-vcenter="true">
      <Modal.Body>
        <div className="video--canvas-expand">
          <div className="video--box-expand">
            <ReactPlayer
              url={listing?.videoUrl}
              width="100%"
              height="100%"
              config={{
                wistia: {
                  options: {
                    fullscreenButton: false,
                    fullscreenOnRotateToLandscape: false,
                    autoPlay: true,
                  },
                },
              }}
            />
          </div>
          <Button className="cross__circle-modal" onClick={close}>
            <i className="icon-cross"></i>
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VideoPanel;
