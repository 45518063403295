import React from 'react';
import { compileAvatarUrl } from '../../../../../utils/avatar.utils';

interface Props {
  zoom?: boolean;
  extraZoom?: boolean;
  onClose?: (e: string) => void;
  onClick: (e: string) => void;
  src: string;
}

const ImageZoom: React.FC<Props> = props => {
  const { zoom, extraZoom, onClose, src, onClick } = props;

  return (
    <React.Fragment>
      {zoom && (
        <div className="liveTour--container gallery--large-canvas">
          <div className="liveTour--gallery-large liveTour--wide" onClick={() => onClick(src)}>
            <img src={compileAvatarUrl(src)} alt="Img" className="img--cover" />
          </div>
          <span className="gallery__cross" onClick={() => onClose && onClose('')}>
            <i className="icon-cross"></i>
          </span>
        </div>
      )}

      {extraZoom && (
        <div className="liveTour--gallery-expanded liveTour--wide" onClick={() => onClick('')}>
          <img src={compileAvatarUrl(src)} alt="Img" className="img--cover" />
        </div>
      )}
    </React.Fragment>
  );
};

export default ImageZoom;
