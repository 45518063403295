import React from 'react';

interface Props {
  onClick: (e: any) => void;
}

const SubDomainHamBurger: React.FC<Props> = (props: Props) => {
  return (
    <a className="sd__bars" onClick={props.onClick}>
      <i className="icon-bars"></i>
    </a>
  );
};

export default SubDomainHamBurger;
