import { AxiosPromise, CancelToken } from 'axios';
import api from '../../utils/api.utils';
import {
  BuildingDataPayloadInterface,
  GetAllBuildingsResponseInterface,
  GetBuildingsListResponseInterface,
  UpdateBuildingDataPayloadInterface,
} from './building.types';
import { cleanObject } from '../../utils/apiValidatior';

export const getBuildingDetailsAPI = (buildingId: string, ct?: CancelToken): AxiosPromise => {
  return api.get(`/building/${buildingId}`, {
    cancelToken: ct,
  });
};

export const getBuildingsListAPI = (
  companyId: string,
  ct?: CancelToken,
): AxiosPromise<Array<GetBuildingsListResponseInterface>> => {
  return api.get(`/building/company/${companyId}`, {
    cancelToken: ct,
  });
};

export const addBuildingAPI = (
  data: BuildingDataPayloadInterface,
  ct?: CancelToken,
): AxiosPromise => {
  return api.post('/building', data, {
    cancelToken: ct,
  });
};

export const getAllBuildingsAPI = (
  companyId: string,
  limit: number,
  skip: number,
  ct?: CancelToken,
): AxiosPromise<GetAllBuildingsResponseInterface> => {
  return api.get('/building/all/company', {
    cancelToken: ct,
    params: cleanObject({
      companyId,
      limit,
      skip,
    }),
  });
};

export const updateBuildingAPI = (
  buildingId: string,
  data: UpdateBuildingDataPayloadInterface,
  ct?: CancelToken,
): AxiosPromise => {
  return api.put(`/building/${buildingId}`, data, {
    cancelToken: ct,
  });
};

export const deleteBuildingAPI = (buildingId: string, ct?: CancelToken) => {
  return api.delete(`/building/${buildingId}`, {
    cancelToken: ct,
  });
};
