import React from 'react';

interface Props {
  showMap: boolean;
  setMapView: (e: boolean) => void;
}
const ViewByFilter: React.FC<Props> = props => {
  return (
    <React.Fragment>
      {props.showMap && (
        <span className="viewBy__marker ml-3">
          <i className="icon-map-marker-light"></i>
        </span>
      )}
      {!props.showMap && (
        <span className="viewBy__marker disabled ml-3" onClick={() => props.setMapView(true)}>
          <i className="icon-map-marker-light"></i>
        </span>
      )}
      <div className="grid__options ml-3 d-flex align-items-center">
        {props.showMap && (
          <span className="grid__view grid__horizontal" onClick={() => props.setMapView(false)}>
            <i className="icon-grid-horizontal"></i>
          </span>
        )}
        {!props.showMap && (
          <span className="grid__view grid__vertical">
            <i className="icon-grid-vertical"></i>
          </span>
        )}
      </div>
    </React.Fragment>
  );
};

export default ViewByFilter;
