import React, { useState } from 'react';
import { compileAvatarUrl } from '../../../../../utils/avatar.utils';
import { AttachmentLightBox } from '../../../../lightbox';
import { UploadedMediaInterface } from '../../../../../state/media/media.types';

interface Props {
  images: UploadedMediaInterface[];
  imagePanelRef: any;
}
const ListingImagePanel: React.FC<Props> = props => {
  const { images, imagePanelRef: imagePanel } = props;
  const [openLightbox, setLightBox] = useState(false);
  const [index, setIndex] = useState(0);

  const openImage = (index: number) => {
    setIndex(index);
    setLightBox(true);
  };

  return (
    <React.Fragment>
      <div className="block--search-row photos--row-lg block--scroll" ref={imagePanel}>
        {images.map((image, index) => (
          <div className="block--search-col" key={image.url}>
            <div className="photos--box photos--box-lg" onClick={() => openImage(index)}>
              <img src={compileAvatarUrl(image.url)} alt={image.name} className="img--cover" />
            </div>
          </div>
        ))}
      </div>
      {openLightbox && (
        <AttachmentLightBox data={images} index={index} close={() => setLightBox(false)} />
      )}
    </React.Fragment>
  );
};

export default ListingImagePanel;
