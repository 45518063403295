import React from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import QuillPad from '../../../common/quillPad';

const ListingDescription: React.FC = () => {
  const info = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing.data,
  );

  return (
    <div className="row row--space-10 sdl--row align-items-center">
      <div className="col-12 col-md-5 d-flex flex-column">
        <div className="sdl--l text-center text-md-left">
          <h2 className="dashboard__heading dashboard__heading-alt font-semibold mb-5 mb-md-0">
            Description
          </h2>
        </div>
      </div>
      <div className="col-12 col-md-7 d-flex flex-column">
        <div className="sdl--r text-center text-md-left">
          {info?.description && (
            <QuillPad
              element="p"
              content={info?.description}
              className="small__paragraph description__para"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ListingDescription;
