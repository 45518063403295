import React, { useEffect } from 'react';
import LeftSidebar from '../../../components/leftSidebar';
import HamBurger from '../../../components/leftSidebar/hamburger';
import { useParams, useHistory } from 'react-router';
import EditTour from '../../../components/tour/edit';
import { useGetSingleTour } from '../../../state/tour/tour.hook';

const EditTourPage: React.FC = () => {
  const { tourId } = useParams();
  const history = useHistory();

  const { getSingleTour } = useGetSingleTour();
  useEffect(() => {
    if (tourId) getSingleTour(tourId);
    else history.push('/dashboard');
  }, [tourId]);

  return (
    <div className="dashboard--page buildings bg--gray">
      <LeftSidebar active="tour" />
      <div className="dashboard--content dashboard--content-new">
        <div className="dashboard--off">
          <div className="dashboard--canvas">
            <div className="dashboard--opt">
              <HamBurger />
            </div>
            <EditTour />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditTourPage;
