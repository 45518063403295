import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Card, Button, Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SmallLoader from './small.loader';
import { AppStateInterface } from '../../state';
import { useGetSubMarketsList } from '../../state/submarket/submarket.hook';
import {
  SubMarketInterface,
  SubMarketListResponseInterface,
} from '../../state/submarket/submarket.types';
import { GetStateInterface } from '../../types/GetStateInterface';

interface Props {
  subMarket: string;
  setSubMarket: (subMarket: string) => void;
  fetchedResponse?: GetStateInterface<Array<SubMarketListResponseInterface>>;
}

const SubMarketFilter: React.FC<Props> = (props: Props) => {
  const { subMarket: selectedSubMarket, setSubMarket, fetchedResponse } = props;
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const { res: subMarketsListResponse, getSubMarketsList } = useGetSubMarketsList(fetchedResponse);
  const [subMarkets, setSubMarkets] = useState<Array<SubMarketInterface>>([]);
  const [openedMarket, setOpenedMarket] = useState<number | null>(null);

  const handleClickOutsideDropdown = (event: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenedMarket(null);
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (me?.company?._id && !fetchedResponse) {
      getSubMarketsList(me.company._id);
    }
  }, [me]);

  useEffect(() => {
    if (!subMarketsListResponse.loading && subMarketsListResponse.data) {
      let allSubMarkets: Array<SubMarketInterface> = [];
      subMarketsListResponse.data?.forEach(
        (market: SubMarketListResponseInterface) =>
          (allSubMarkets = allSubMarkets.concat(market.docs)),
      );
      setSubMarkets(allSubMarkets);
    }
  }, [subMarketsListResponse]);

  const findSubMarket = (subMarketId: string) => {
    return subMarkets.find((subMarket: SubMarketInterface) => subMarket._id === subMarketId)?.name;
  };

  const onDropdownSelect = (selectedValue: string) => {
    setSubMarket(selectedValue);
    setOpenedMarket(null);
    setShowDropdown(false);
  };

  return (
    <Dropdown
      ref={dropdownRef}
      show={showDropdown}
      className="listing--dropdown listing--dropdown-update listing--dropdown-pad dropdown--radius mb-3 mb-md-0 mb-0 spinner--group"
    >
      <Dropdown.Toggle id="dropdown-basic" onClick={() => setShowDropdown(prevState => !prevState)}>
        {selectedSubMarket && findSubMarket(selectedSubMarket)
          ? findSubMarket(selectedSubMarket)
          : 'Select Location (All)'}
        <span className="select__caret-down">
          <i className="icon-caret-down"></i>
        </span>
        {subMarketsListResponse.loading && <SmallLoader />}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <label className="checkbox--set dropdown--checkbox" onClick={() => onDropdownSelect('')}>
          All
          <input type="checkbox" disabled checked={!selectedSubMarket} />
          <span className="checkmark"></span>
        </label>
        {subMarketsListResponse.data && subMarketsListResponse.data.length > 0 && (
          <Accordion className="listing--accordian">
            {subMarketsListResponse.data.map(
              (market: SubMarketListResponseInterface, index: number) => {
                return (
                  <Card className="listing--card" key={index}>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={index.toString()}
                        onClick={() =>
                          setOpenedMarket(prevState =>
                            prevState || prevState === 0 ? null : index,
                          )
                        }
                      >
                        {`All ${market._id} (${market.docs.length})`}
                        <span className="accordian__icon">
                          {openedMarket === index ? (
                            <i className="icon-minus"></i>
                          ) : (
                            <i className="icon-plus"></i>
                          )}
                        </span>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={index.toString()}>
                      <Card.Body>
                        {market.docs?.map((subMarket: SubMarketInterface, index: number) => {
                          return (
                            <Dropdown.Item
                              key={index}
                              eventKey={subMarket._id}
                              onSelect={onDropdownSelect}
                              active={subMarket._id === selectedSubMarket}
                            >
                              {subMarket.name}
                            </Dropdown.Item>
                          );
                        })}
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                );
              },
            )}
          </Accordion>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SubMarketFilter;
