import React from 'react';
import LeftSidebar from '../../../components/leftSidebar';
import BuildingForm from '../../../components/building/form';

const CreateBuildingPage: React.FC = () => {
  return (
    <div className="dashboard--page">
      <LeftSidebar active="buildings" />
      <div className="dashboard--content">
        <BuildingForm />
      </div>
    </div>
  );
};

export default CreateBuildingPage;
