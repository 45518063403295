import React from 'react';
import { Button, Modal } from 'react-bootstrap';

interface Props {
  handleSuccess: () => void;
  handleCancel: () => void;
}

const DeleteRecording: React.FC<Props> = props => {
  const { handleCancel, handleSuccess } = props;

  return (
    <Modal show onHide={handleCancel} className="office-modal" contained-modal-title-vcenter="true">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Warning!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="em__para text__gray">
          Are you sure you want to delete this ongoing recording ?
        </p>
        <div className="row row--space-12 flex-sm-row-reverse pt-sm-4 mb-3 pb-2">
          <div className="col-12 col-sm-6">
            <Button
              type="button"
              className={`admin__button underline__button w-100 mb-4 mb-sm-0`}
              onClick={handleSuccess}
            >
              Yes, Delete it
            </Button>
          </div>
          <div className="col-12 col-sm-6">
            <Button onClick={handleCancel} className="outline__button underline__button w-100">
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteRecording;
