import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import { compileAvatarUrl } from '../../utils/avatar.utils';
import { UploadedMediaInterface } from '../../state/media/media.types';

interface Props {
  data: UploadedMediaInterface[];
  index: number;
  close: () => void;
  showTitles?: boolean;
}

export const AttachmentLightBox: React.FC<Props> = props => {
  const { showTitles } = props;
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([] as string[]);
  const [imageTitles, setImageTitles] = useState([] as string[]);
  useEffect(() => {
    setPhotoIndex(props.index);
    if (props.data.length) {
      const data = [] as string[];
      const dataTitle = [] as string[];
      for (const i in props.data) {
        data.push(compileAvatarUrl(props.data[i].url));
        dataTitle.push(props.data[i].name);
      }
      setImages(data);
      setImageTitles(dataTitle);
    }
  }, [props.data]);

  return (
    <React.Fragment>
      {images.length > 0 && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images.length > 1 ? images[(photoIndex + 1) % images.length] : undefined}
          prevSrc={
            images.length > 1 ? images[(photoIndex + images.length - 1) % images.length] : undefined
          }
          onCloseRequest={() => props.close()}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          imageTitle={showTitles ? imageTitles[photoIndex] : ''}
        />
      )}
    </React.Fragment>
  );
};
