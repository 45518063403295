import React from 'react';
import NumberFormat from 'react-number-format';
import {
  SEPARATORS,
  REVIEW_SEGMENTS as total,
  LISTING_STATUS as LS,
  VIDEO_STATUS as VS,
} from '../../common/constants';
import { GetListingObject, HandlerConditions } from '../../state/listing/listing.types';

export const suiteOptions = () => {
  return (
    <React.Fragment>
      <option value="" key={-1} className="placeholder">
        Space Use*
      </option>
      <option>Home</option>
      <option>Retail</option>
      <option>Office</option>
    </React.Fragment>
  );
};

export const capacityOptions = () => {
  return (
    <React.Fragment>
      <option value="" key={-1} className="placeholder">
        Capacity
      </option>
      <option value="1-8">1-8 Employees</option>
      <option value="1-15">1-15 Employees</option>
      <option value="1-30">1-30 Employees</option>
      <option value="1-50">1-50 Employees</option>
      <option value="1-100">1-100 Employees</option>
      <option value="1-100+">1-100+ Employees</option>
    </React.Fragment>
  );
};

export const conditionOptions = () => {
  return (
    <React.Fragment>
      <option value="" key={-1} className="placeholder">
        Condition
      </option>
      <option value="Full-Blown Build Out">Full-Blown Build Out</option>
      <option value="Partial Build Out">Partial Build Out</option>
      <option value="Wipe-Off">Wipe-Off</option>
      <option value="Spec Suite">Spec Suite</option>
      <option value="Second Generation">Second Generation</option>
    </React.Fragment>
  );
};

export const errorMsg = (error: any, errorMessage?: string, className?: string) => {
  if (errorMessage) return <p className={`error__msg ${className || ``}`}>{errorMessage}</p>;
  return error ? <p className="error__msg">{error.message}</p> : null;
};

export const commaSeparated = (e: number) => {
  return <NumberFormat value={e} displayType="text" thousandSeparator />;
};

export const mergeBuildingHours = (
  hours: string[],
  join?: boolean,
  separator?: string,
): string | string[] => {
  const output = [] as string[];
  for (const hour of hours) {
    const parts = hour.split(SEPARATORS.SPACE);
    const dayParts = parts[0].split(SEPARATORS.DASH);
    const timeParts = parts[1].split(SEPARATORS.DASH);
    if (dayParts.length > 1 && dayParts[0] === dayParts[1]) {
      if (timeParts.length > 1 && (timeParts[0] === 'Closed' || timeParts[1] === 'Closed')) {
        output.push(dayParts[1] + SEPARATORS.SPACE + 'Closed');
      } else {
        output.push(dayParts[1] + SEPARATORS.SPACE + parts[1]);
      }
    } else {
      if (timeParts.length > 1 && (timeParts[0] === 'Closed' || timeParts[1] === 'Closed')) {
        output.push(parts[0] + SEPARATORS.SPACE + 'Closed');
      } else {
        output.push(hour);
      }
    }
  }
  return join ? output.join(separator || SEPARATORS.SEMI_COLON + SEPARATORS.SPACE) : output;
};

export const mergeWithAnd = (e: string[]): string => {
  if (e.length <= 1) return e.join('');
  const lastOne = e.pop();
  return e.join(SEPARATORS.COMMA + SEPARATORS.SPACE) + ' and ' + lastOne;
};

export const numToString = (e: number): string => {
  return e < 10 ? `0${e}` : e.toString();
};

export const splitDurations = (e: number): number[] => {
  const duration = Math.round(e / total);
  const remain = e - duration * total;
  const output = new Array(duration).fill(duration);
  output[output.length - 1] = duration + remain;
  return output;
};

export const convertSecToString = (e: number, showMilli?: boolean): string => {
  let time = Math.floor(e);
  const milli = parseInt(((e - time) * 100).toString(), 10);
  const min = Math.floor(time / 60);
  time -= min * 60;
  let output = '';
  output += `${numToString(min)}:${numToString(time)}`;
  if (showMilli) output += `:${numToString(milli)}`;
  return output;
};

export const wistiaVideoConfigs = {
  options: {
    controlsVisibleOnLoad: false,
    copyLinkAndThumbnailEnabled: false,
    fakeFullscreen: false,
    fullscreenButton: false,
    fullscreenOnRotateToLandscape: false,
    muted: true,
    settingsControl: false,
    playbutton: false,
    playSuspendedOffScreen: false,
    silentAutoPlay: true,
    smallPlayButton: false,
    volume: 0,
    volumeControl: false,
    endVideoBehavior: 'auto',
    autoPlay: false,
    playbar: true,
  },
};

export const renderHandlers = (listing: GetListingObject): HandlerConditions => {
  const output = {
    copy: false,
    accept: false,
    record: false,
    recordWhite: false,
  };
  if (!listing.videoUrl) {
    // if (listing.status === LS.PUBLISHED) output.copy = true;
    return output;
  } else {
    if (listing.status === LS.DRAFT) return output;
    output.record = true;
    if (listing.videoStatus === VS.UPLOADED) output.accept = true;
    // if (listing.status === LS.PUBLISHED) output.copy = true;

    if (
      (listing.status === LS.REVIEW && listing.videoStatus !== VS.PENDING) ||
      listing.status === LS.PUBLISHED
    )
      output.recordWhite = true;

    return output;
  }
};
