import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ListingForm as list } from '../../../../state/listing/listing.form';
import { ListingInterface, ListingErrorInterface } from '../../../../state/listing/listing.types';
import UploadPhotos from './uploads/media';
import { UploadedMediaInterface } from '../../../../state/media/media.types';
import SingleMediaTile from '../../../media/singleTile';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import { errorMsg } from '../../listing.component';

interface Props {
  data: ListingInterface;
  dataError: ListingErrorInterface;
  setOutput: (key: string, value: any) => void;
}
const Plans: React.FC<Props> = props => {
  const [modalPhotoShow, setModalPhotoShow] = useState(false);
  const { data, dataError, setOutput } = props;

  const handleImages = (images: UploadedMediaInterface[]) => {
    let alreadyImages = [] as UploadedMediaInterface[];
    if (data.floorPlans) alreadyImages = data.floorPlans;
    setOutput(list.floorPlans, [...alreadyImages, ...images]);
    setModalPhotoShow(false);
  };

  const removeImage = (index: number) => {
    if (data.floorPlans && data.floorPlans.length) {
      const media = data.floorPlans;
      media.splice(index, 1);
      setOutput(list.floorPlans, media);
    }
  };

  return (
    <React.Fragment>
      <div className="file--uploaded ">
        {data.floorPlans &&
          data.floorPlans.map((file, index) => (
            <SingleMediaTile
              key={index}
              url={compileAvatarUrl(file.url)}
              remove={() => removeImage(index)}
            />
          ))}
      </div>
      <div className="mb-3 forms--group errorMsg--set">
        <Button onClick={() => setModalPhotoShow(true)} className="upload__button mb-4 mt-2">
          Upload {data.floorPlans?.length > 0 && 'More '}Floor Plans
          <span className="upload__button-icon">
            <i className="icon-upload"></i>
          </span>
        </Button>
        {errorMsg({}, dataError[list.floorPlans], 'mb-3')}
        {modalPhotoShow && (
          <UploadPhotos
            handleClose={() => setModalPhotoShow(false)}
            handleChanges={handleImages}
            alreadyFiles={data.floorPlans ? data.floorPlans.length : 0}
            title="floor plan"
            limit={1}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Plans;
