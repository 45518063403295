import { AxiosPromise, CancelToken } from 'axios';
import api from '../../../utils/api.utils';

export const getSubDomainStatusAPI = (
  subdomain: string,
  ct?: CancelToken,
): AxiosPromise<boolean> => {
  return api.get(`/company/check/${subdomain}`, {
    cancelToken: ct,
  });
};

export const getSubDomainAvailabilityAPI = (
  subdomain: string,
  ct?: CancelToken,
): AxiosPromise<boolean> => {
  return api.get(`/company/available/${subdomain}`, {
    cancelToken: ct,
  });
};
