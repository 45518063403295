import React from 'react';

interface Props {
  url: string;
  name: string;
  fill?: boolean;
  filling?: number;
  error?: boolean;
  remove: () => void;
}
const ListingMediaTile: React.FC<Props> = props => {
  const { url, name, fill, filling, error, remove } = props;

  return (
    <div className="uploadPhoto--row">
      <div className="uploadPhoto--img">
        <img src={url} alt="Img" />
      </div>
      <div className="uploadPhoto--detail">
        <div className="uploadPhoto--inner">
          <span className="uploadPhoto__name">{name}</span>
          <div className="uploadPhoto--progress">
            <span className={`uploadPhoto__bar ${error ? `uploadPhoto__error` : ``}`}>
              {!error && (
                <span
                  className="bar__fill"
                  style={{ width: `${fill ? 100 : filling && filling > -1 ? filling : 0}%` }}
                ></span>
              )}
            </span>
            {error && <span className="uploadPhoto__text">0%</span>}
            {!error && (
              <React.Fragment>
                {fill && <span className="uploadPhoto__text">100%</span>}
                {!fill && filling !== undefined && filling > -1 && (
                  <span className="uploadPhoto__text">{filling}%</span>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        <a className="uploadPhoto__delete" onClick={remove}>
          <i className="icon-trash"></i>
        </a>
      </div>
    </div>
  );
};

export default ListingMediaTile;
