import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import SingleListing from './../../../components/subdomain/listing/listDetail';
import {
  useClearSingleListing,
  useGetSingleListingAction,
} from '../../../state/subdomain/listing/listing.hook';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import SubDomainRightSidebar from '../../../components/subdomain/rightSidebar';
import SubdomainFooter from '../../../components/subdomain/footer';
import { isEmpty } from 'lodash';
import SingleListingShimmer from './singleListingShimmer';
import { CompanyInfoInterface } from '../../../state/subdomain/company/company.types';

const SingleListingsView: React.FC = () => {
  const history = useHistory();
  const companyInfo = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo,
  );
  const listingInfo = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing,
  );
  const { listingId } = useParams();
  const [company, setCompany] = useState({} as CompanyInfoInterface);
  const { clearSingleListing } = useClearSingleListing();
  const { getSingleListing } = useGetSingleListingAction();
  if (!listingId) history.push('/buildings');

  useEffect(() => {
    if (listingId) getSingleListing(listingId);
  }, [listingId]);
  useEffect(() => {
    if (!companyInfo.loading && companyInfo.hasData && companyInfo.data)
      setCompany(companyInfo.data);
  }, [companyInfo]);
  useEffect(() => {
    return () => clearSingleListing();
  }, []);

  return (
    <div className="off--canvas">
      {!companyInfo.loading && !listingInfo.loading ? (
        <React.Fragment>
          <div className="listing subdomain--listing availabelListing--sticky">
            <div className="container container--large off--canvas-content">
              <div className="d-flex align-items-center justify-content-between">
                <a
                  className="available--listing-logo"
                  href={company?.settings?.officialWebsite || '#'}
                  target={company?.settings?.officialWebsite ? '_blank' : '_self'}
                  rel="noopener noreferrer"
                >
                  <img
                    src={
                      company?.settings?.darkLogo
                        ? compileAvatarUrl(company.settings.darkLogo.url)
                        : '/images/HomeTour_Dark_Logo.svg'
                    }
                    alt="Logo"
                  />
                </a>
                <SubDomainRightSidebar />
              </div>
              <SingleListing />
            </div>
          </div>
          <SubdomainFooter />
        </React.Fragment>
      ) : (
        <SingleListingShimmer />
      )}
    </div>
  );
};

export default SingleListingsView;
