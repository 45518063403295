import { useState } from 'react';
import { GetState } from '../../types/GetStateInterface';
import { ContactUsInterface } from './about.types';
import { contactUsAPI } from './about.api';

export const useContactUs = () => {
  const [res, setRes] = useState(GetState);
  const contact = async (data: ContactUsInterface) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    try {
      const res = await contactUsAPI(data);
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      setRes({ data: null, loading: false, error: error, hasData: true });
    }
  };
  return { contact, res };
};
