import React from 'react';

const ListingBlockShimmer: React.FC = () => {
  return (
    <div className="al--block al--block-md al--block-alt alb--alt-shimmer">
      <div className="al--block-img animate"></div>
      <div className="al--block-detail">
        <div className="ald--inner al--adress-component">
          <div className="d-flex flex-column">
            <span className="al__address animate"></span>
            <span className="al__address animate"></span>
          </div>
          <div className="al--listing-box al--listing-box-sh">
            <div className="al--listing">
              <span className="al__icon animate"></span>
              <span className="al__text animate"></span>
            </div>
          </div>
        </div>
        <div className="ald--inner al--listings-component">
          <div className="al--listing-box al--listing-box-sh">
            <div className="al--listing">
              <span className="al__icon animate"></span>
              <span className="al__text animate"></span>
            </div>
            <div className="al--listing">
              <span className="al__icon animate"></span>
              <span className="al__text animate"></span>
            </div>
            <div className="al--listing">
              <span className="al__icon animate"></span>
              <span className="al__text animate"></span>
            </div>
          </div>
        </div>
        <div className="ald--inner al--btn-component">
          <span className="buildingBtn__shimmer animate"></span>
          <span className="buildingBtn__shimmer animate"></span>
        </div>
        <span className="buildings__next">
          <i className="icon-arrow-right"></i>
        </span>
      </div>
    </div>
  );
};

export default ListingBlockShimmer;
