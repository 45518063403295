import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router';
import { notification } from 'antd';
import ResizeObserver from 'resize-observer-polyfill';

import { GetListingObject } from '../../../state/listing/listing.types';
import { ListingInfo, VideoCallInterface } from '../../../state/subdomain/tour/tour.types';
import { getInitials, showNotification } from '../../../utils/misc.utils';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import { useDeleteTour } from '../../../state/subdomain/tour/tour.hook';
import Participant from './participant';
import ListingTourHome from './listingTourHome';
import ListingTourDetails from './listingTourDetails';
import ListingTourMatterport from './listingTourMatterport';
import ListingTourLocation from './listingTourLocation';
import ListingTourGallery from './listingTourGallery';
import ListingTourSpecs from './listingTourSpecs';
import { Button } from 'react-bootstrap';
import ScrollContainer from 'react-indiana-drag-scroll';
import TourAllListings from './changeListing';
import { AmenityMatterPortLink } from '../../../state/building/building.types';
import { isEmpty } from 'lodash';
import TourImageHandler from './imageHandler';
import { TOUR_TYPES } from '../../../common/constants';

const MAX_SHAREABLE_CONTAINER_RATIO = 16 / 7;
const MS_BETWEEN_RESET_DIMENSIONS = 1000;

// these breakpoints are for the size of the shareable container - NOT the screen
const SHAREABLE_BREAKPOINTS = {
  '0': 'sm',
  '900': 'lg',
};

function findBreakpointName(width: number): string {
  let selectedBreakpointName = 'xs';
  for (const [breakpointWidth, breakpointName] of Object.entries(SHAREABLE_BREAKPOINTS)) {
    if (width < parseInt(breakpointWidth)) break;
    selectedBreakpointName = breakpointName;
  }
  return selectedBreakpointName;
}

enum Section {
  HOME = 'HOME',
  DETAILS = 'DETAILS',
  TOURS = 'TOURS',
  LOCATION = 'LOCATION',
  GALLERY = 'GALLERY',
  SPECS = 'SPECS',
  FLOOR_PLANS = 'FLOOR_PLANS',
}

interface Props {
  videoCall: VideoCallInterface;
  listingData: ListingInfo;
  tourType: TOUR_TYPES;
  isBroker: boolean;
}

const SCREEN_NOTIFICATION_KEY = 'SCREEN_NOTIFICATION_KEY';
const SCREEN_NOTIFICATION_MESSAGE = 'Screenshare Stopped';
const SCREEN_NOTIFICATION_DESCRIPTION =
  "You are currently not sharing your presentation! Click 'Switch Tab' at the bottom to choose a tab.";

const ListingTourView: React.FC<Props> = (props: Props) => {
  const {
    listingData,
    videoCall: {
      leaveCall,
      muteAudio,
      muteVideo,
      updateAllHostDimensions,
      isRecording,
      tenants,
      brokers,
      host,
      reselectTab,
    },
    tourType,
    isBroker,
  } = props;
  const [activeSection, setActiveSection] = useState<Section>(Section.HOME);
  const [galleryIsExpand, galleryOnExpand] = useState(false);
  const [fPIsExpand, fPOnExpand] = useState(false);
  const [galleryIndex, galleryChangeIndex] = useState(-1);
  const [fPIndex, fPChangeIndex] = useState(-1);
  const [currentMatterPort, updateCurrentMatterPort] = useState('');
  const [shareableBreakpoint, setShareableBreakpoint] = useState('xl');
  const [leftRowInitialMarginRight, setLeftRowInitialMarginRight] = useState<number>(50);
  const [rightRowInitialMarginLeft, setRightRowInitialMarginLeft] = useState<number>(50);
  const [showListings, handleListings] = useState(false);
  const [showTourOptions, setTourOptions] = useState(true);
  const [showGalleryOptions, setGalleryOptions] = useState(true);
  const [showFloorPlanOptions, setFloorPlanOptions] = useState(true);
  const [listing, setListing] = useState({} as GetListingObject);
  const [topStyle, setTopStyle] = useState<CSSProperties>({
    width: '100%',
    margin: '0 auto',
  });

  useEffect(() => {
    setActiveSection(Section.HOME);
    updateCurrentMatterPort(listingData.listing.matterPortLink);
    setListing(listingData.listing);
  }, [listingData]);
  // use a ref so we call the updated version in callbacks
  const updateAllHostDimensionsRef = useRef(updateAllHostDimensions);
  useEffect(() => {
    updateAllHostDimensionsRef.current = updateAllHostDimensions;
  }, [updateAllHostDimensions]);

  useEffect(() => {
    if (activeSection) {
      galleryOnExpand(false);
      fPOnExpand(false);
    }
  }, [activeSection]);

  const shareableContainer = useCallback((el: HTMLElement | null) => {
    // below triggered only each time el is reassigned
    if (!el) return;

    let lastMaxShareableWidth = 0;
    const ro = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      window.requestAnimationFrame(() => {
        const newDims = {
          screenWidth: document.body.clientWidth,
          screenHeight: document.body.clientHeight,
          shareableHeight: el.clientHeight,
          shareableWidth: el.clientWidth,
        };
        // don't allow wider than 16:7
        const maxShareableWidth = newDims.shareableHeight * MAX_SHAREABLE_CONTAINER_RATIO;
        if (maxShareableWidth !== lastMaxShareableWidth) {
          lastMaxShareableWidth = maxShareableWidth;
          setTopStyle(lastStyle => {
            return {
              ...lastStyle,
              maxWidth: maxShareableWidth + 'px',
            };
          });
          setShareableBreakpoint(findBreakpointName(newDims.shareableWidth));
        }
        updateAllHostDimensionsRef.current(newDims);
      });
    });
    ro.observe(el);
  }, []);

  // show error if no screen
  useEffect(() => {
    if (!host?.hasScreenTrack && tourType === TOUR_TYPES.LIVE) {
      showNotification('error', SCREEN_NOTIFICATION_MESSAGE, SCREEN_NOTIFICATION_DESCRIPTION, {
        duration: null,
        key: SCREEN_NOTIFICATION_KEY,
      });
    } else {
      notification.close(SCREEN_NOTIFICATION_KEY);
    }
  }, [host?.hasScreenTrack]);

  // preload primary image
  useEffect(() => {
    if (!isEmpty(listing)) {
      const primaryImage = listing.media.find(image => image.isPrimary) || listing.media[0];
      if (!primaryImage) return;

      const img = new Image();
      img.src = compileAvatarUrl(primaryImage?.url);
    }
  }, [listing]);

  const { tourId } = useParams();
  const { deleteTour } = useDeleteTour();

  const endTour = () => {
    leaveCall(true);
    if (isBroker) {
      deleteTour(tourId);
    }
  };

  // TODO: we need to enable recordings in the backend (prob local)
  // depends on pricing plan as well
  let recClass = 'vvOptions-rec';
  if (isRecording) recClass += ' vvOptions-rec--recording';

  const tenantElements = tenants.map(tenant => (
    <Participant
      participant={tenant}
      key={tenant.sessionId}
      additionalClasses="left--user--block"
    />
  ));

  const brokerElements = brokers.map(broker => (
    <Participant
      participant={broker}
      key={broker.sessionId}
      additionalClasses="right--user--block"
    />
  ));

  useLayoutEffect(() => {
    const leftRowEl = document.getElementById('users-left-row');
    const rightRowEl = document.getElementById('users-right-row');
    if (leftRowEl && rightRowEl) {
      setLeftRowInitialMarginRight(
        parseFloat(window.getComputedStyle(leftRowEl).getPropertyValue('margin-right')),
      );
      setRightRowInitialMarginLeft(
        parseFloat(window.getComputedStyle(rightRowEl).getPropertyValue('margin-left')),
      );
    }
  }, []);

  useLayoutEffect(() => {
    const usersEl = document.getElementById('users');
    const leftRowEl = document.getElementById('users-left-row');
    const rightRowEl = document.getElementById('users-right-row');
    const leftUsers = document.getElementsByClassName('left--user--block');
    const rightUsers = document.getElementsByClassName('right--user--block');
    if (usersEl && leftRowEl && rightRowEl) {
      leftRowEl.style.width = '100%';
      rightRowEl.style.width = '100%';
      leftRowEl.style.marginRight = leftRowInitialMarginRight + 'px';
      rightRowEl.style.marginLeft = rightRowInitialMarginLeft + 'px';
      if (leftUsers?.length === 0 || rightUsers?.length === 0) {
        leftRowEl.style.width = 'auto';
        rightRowEl.style.width = 'auto';
        leftRowEl.style.marginRight = '0';
        rightRowEl.style.marginLeft = '0';
        if (leftUsers?.length === 0) {
          rightRowEl.style.maxWidth = usersEl.clientWidth + 'px';
        }
        if (rightUsers?.length === 0) {
          leftRowEl.style.maxWidth = usersEl.clientWidth + 'px';
        }
      } else {
        const usersWidth = parseFloat(window.getComputedStyle(usersEl).getPropertyValue('width'));
        const leftRowMarginRight = parseFloat(
          window.getComputedStyle(leftRowEl).getPropertyValue('margin-right'),
        );
        const rightRowMarginLeft = parseFloat(
          window.getComputedStyle(rightRowEl).getPropertyValue('margin-left'),
        );
        if (
          leftUsers?.length < rightUsers?.length &&
          leftRowEl.clientWidth === leftRowEl.scrollWidth &&
          rightRowEl.clientWidth < rightRowEl.scrollWidth
        ) {
          leftRowEl.style.width = 'auto';
          rightRowEl.style.width = 'auto';
          leftRowEl.style.maxWidth = leftRowEl.scrollWidth + 'px';
          rightRowEl.style.maxWidth =
            usersWidth - (leftRowMarginRight + rightRowMarginLeft + leftRowEl.scrollWidth) + 'px';
        }
        if (
          leftUsers?.length > rightUsers?.length &&
          rightRowEl.clientWidth === rightRowEl.scrollWidth &&
          leftRowEl.clientWidth < leftRowEl.scrollWidth
        ) {
          leftRowEl.style.width = 'auto';
          rightRowEl.style.width = 'auto';
          rightRowEl.style.maxWidth = rightRowEl.scrollWidth + 'px';
          leftRowEl.style.maxWidth =
            usersWidth - (leftRowMarginRight + rightRowMarginLeft + rightRowEl.scrollWidth) + 'px';
        }
      }
    }
  }, [brokers, tenants]);

  const changePhotoSection = (section: Section) => {
    setActiveSection(section);
    galleryOnExpand(false);
    galleryChangeIndex(-1);
  };
  const handleMatterPort = (link: string) => {
    if (link !== currentMatterPort) updateCurrentMatterPort(link);
  };

  const OnlyMatterPortLinks = Array.isArray(listing.building?.buildingAmenitiesMatterportLinks)
    ? listing.building.buildingAmenitiesMatterportLinks.map(link => link.url)
    : [];

  return (
    <React.Fragment>
      {listing._id && (
        <div
          className={
            tourType === TOUR_TYPES.PRESENT ? 'liveTour--page tourPre--page' : 'liveTour--page'
          }
        >
          <div
            className={`liveTour--top liveTour--top-broker liveTour--${shareableBreakpoint} ${
              tourType === TOUR_TYPES.PRESENT ? 'tourPre--top' : ''
            } ${
              [Section.SPECS, Section.DETAILS].includes(activeSection)
                ? 'tourPre--top-' + activeSection.toLowerCase()
                : [Section.GALLERY, Section.FLOOR_PLANS].includes(activeSection)
                ? 'tourPre--top-gallery'
                : 'tourPre--top-home'
            }`}
            ref={shareableContainer}
            style={topStyle}
          >
            <ListingTourHome
              listing={listing}
              className={activeSection === Section.HOME ? '' : 'hide--section'}
            />
            <ListingTourDetails
              listing={listing}
              className={activeSection === Section.DETAILS ? '' : 'hide--section'}
            />
            {[listing.matterPortLink, ...OnlyMatterPortLinks].map(
              (matterPortLink: string, index: number) => (
                <ListingTourMatterport
                  key={index}
                  matterPortLink={matterPortLink}
                  className={
                    activeSection === Section.TOURS && matterPortLink === currentMatterPort
                      ? ''
                      : 'hide--section'
                  }
                />
              ),
            )}
            <ListingTourLocation
              listing={listing}
              className={activeSection === Section.LOCATION ? '' : 'hide--section'}
            />
            <ListingTourGallery
              onExpanded={galleryOnExpand}
              isExpanded={galleryIsExpand}
              listing={listing}
              currentIndex={galleryIndex}
              changeIndex={galleryChangeIndex}
              className={activeSection === Section.GALLERY ? '' : 'hide--section'}
              additionalImages={listingData?.additionalMedia || []}
            />
            {listingData.additionalFloorPlans.length + listing.floorPlans.length > 0 && (
              <ListingTourGallery
                onExpanded={fPOnExpand}
                isExpanded={fPIsExpand}
                listing={listing}
                currentIndex={fPIndex}
                changeIndex={fPChangeIndex}
                floorPlans={true}
                className={activeSection === Section.FLOOR_PLANS ? '' : 'hide--section'}
                additionalImages={listingData?.additionalFloorPlans || []}
              />
            )}
            {activeSection === Section.SPECS && <ListingTourSpecs listing={listing} />}
          </div>
          <div
            className={`liveTour--content liveTour--content-broker ${
              tourType === TOUR_TYPES.PRESENT ? 'tourPre--content pt-4 pt-md-0 pb-2 pb-md-0' : ''
            } ${showListings ? 'tourPre--content-listing' : ''}`}
          >
            <TourAllListings
              close={() => handleListings(false)}
              showSection={showListings ? '' : 'hide--section'}
            />
            <div className="container-fluid">
              {tourType === TOUR_TYPES.LIVE && (
                <div className="users--content" id="users">
                  <div className="users--row users--row-left" id="users-left-row">
                    {tenantElements}
                  </div>

                  <div className="users--row users--row-right" id="users-right-row">
                    {brokerElements}
                    {host && (
                      <Participant
                        participant={host}
                        skipAudio={true}
                        additionalClasses="right--user--block"
                      />
                    )}
                  </div>
                </div>
              )}
              <div className="liveTour--last d-flex flex-column-reverse flex-md-row align-items-center align-items-md-end justify-content-between">
                <ScrollContainer
                  nativeMobileScroll={true}
                  horizontal={true}
                  style={{
                    overflowX: 'auto',
                    cursor: 'grab',
                  }}
                  className="w-100"
                >
                  <ul
                    className={`liveTour__list ${
                      tourType === TOUR_TYPES.PRESENT ? 'justify-content-center' : ''
                    }`}
                  >
                    <li>
                      <a
                        className={`lt__list-box ${activeSection === Section.HOME ? 'active' : ''}`}
                        onClick={() => {
                          setActiveSection(Section.HOME);
                        }}
                      >
                        <span className="lt__list-icon">
                          <i className="icon-home"></i>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        className={`lt__list-box ${
                          activeSection === Section.DETAILS ? 'active' : ''
                        }`}
                        onClick={() => {
                          setActiveSection(Section.DETAILS);
                        }}
                      >
                        <span className="lt__list-icon">
                          <i className="icon-details"></i>
                        </span>
                        <span className="lt__list-text">Details</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className={`lt__list-box ${
                          activeSection === Section.LOCATION ? 'active' : ''
                        }`}
                        onClick={() => {
                          setActiveSection(Section.LOCATION);
                        }}
                      >
                        <span className="lt__list-icon">
                          <i className="icon-location"></i>
                        </span>
                        <span className="lt__list-text">Location</span>
                      </a>
                    </li>
                    <TourImageHandler
                      active={activeSection === Section.GALLERY}
                      changeOptions={setGalleryOptions}
                      showOptions={showGalleryOptions}
                      setActive={() => changePhotoSection(Section.GALLERY)}
                      index={galleryIndex}
                      changeIndex={galleryChangeIndex}
                      closeExpand={() => galleryOnExpand(false)}
                      data={listing?.media || []}
                      additionalData={listingData?.additionalMedia || []}
                      name={Section.GALLERY}
                    />
                    <TourImageHandler
                      active={activeSection === Section.FLOOR_PLANS}
                      changeOptions={setFloorPlanOptions}
                      showOptions={showFloorPlanOptions}
                      setActive={() => changePhotoSection(Section.FLOOR_PLANS)}
                      index={fPIndex}
                      changeIndex={fPChangeIndex}
                      closeExpand={() => fPOnExpand(false)}
                      data={listing?.floorPlans || []}
                      additionalData={listingData?.additionalFloorPlans || []}
                      name={Section.FLOOR_PLANS}
                    />
                    {listing.matterPortLink && (
                      <li>
                        <a
                          className={`lt__list-box ${
                            activeSection === Section.TOURS ? 'active' : ''
                          }`}
                          onClick={() => {
                            if (activeSection !== Section.TOURS) {
                              updateCurrentMatterPort(listing.matterPortLink);
                              setActiveSection(Section.TOURS);
                              setTourOptions(true);
                            }
                          }}
                        >
                          <span
                            className="lt__list_span"
                            onClick={() =>
                              setTourOptions(prevState =>
                                activeSection === Section.TOURS ? !prevState : true,
                              )
                            }
                          >
                            <span className="lt__list-icon">
                              <i className="icon-tour"></i>
                            </span>
                            <span className="lt__list-text">Tour</span>
                          </span>
                          {showTourOptions &&
                            listing?.building?.buildingAmenitiesMatterportLinks &&
                            listing?.building?.buildingAmenitiesMatterportLinks?.length > 0 && (
                              <div className="tour--btn-canvas active--content ml-2">
                                <Button
                                  className={`tour__btn ${
                                    currentMatterPort === listing.matterPortLink ? 'active' : ''
                                  }`}
                                  onClick={() => handleMatterPort(listing.matterPortLink)}
                                >
                                  <span className="tour__btn-desktop">Main Tour</span>
                                  <span className="tour__btn-mobile">MT</span>
                                </Button>
                                {listing?.building?.buildingAmenitiesMatterportLinks?.map(
                                  (amenity: AmenityMatterPortLink, index: number) => (
                                    <Button
                                      key={index}
                                      className={`tour__btn ${
                                        currentMatterPort === amenity.url ? 'active' : ''
                                      }`}
                                      onClick={() => handleMatterPort(amenity.url)}
                                    >
                                      <span className="tour__btn-desktop">
                                        {amenity?.name || `Amenity ${index + 1}`}
                                      </span>
                                      <span className="tour__btn-mobile">
                                        {amenity?.name
                                          ? getInitials(amenity.name)
                                          : `A${index + 1}`}
                                      </span>
                                    </Button>
                                  ),
                                )}
                              </div>
                            )}
                        </a>
                      </li>
                    )}
                    <li>
                      <a
                        className={`lt__list-box ${
                          activeSection === Section.SPECS ? 'active' : ''
                        }`}
                        onClick={() => {
                          setActiveSection(Section.SPECS);
                        }}
                      >
                        <span className="lt__list-icon">
                          <i className="icon-building"></i>
                        </span>
                        <span className="lt__list-text">Specs</span>
                      </a>
                    </li>
                  </ul>
                </ScrollContainer>
                <ul
                  className={`liveTour--options ${
                    tourType === TOUR_TYPES.PRESENT ? 'mt-2 mt-md-0' : ''
                  }`}
                >
                  {tourType === TOUR_TYPES.LIVE && (
                    <React.Fragment>
                      <li>
                        <a className="liveTourOpt__box liveTourOpt__box-gray" onClick={reselectTab}>
                          <span className="liveTourOpt__icon">
                            <i className="icon-share-screen"></i>
                          </span>
                          <span className="liveTourOpt__text">Switch Tab</span>
                        </a>
                      </li>
                      <li>
                        {host?.hasVideo ? (
                          <a
                            className="liveTourOpt__box liveTourOpt__box-gray"
                            onClick={() => {
                              muteVideo(false);
                            }}
                          >
                            <span className="liveTourOpt__icon">
                              <i className="icon-video"></i>
                            </span>
                            <span className="liveTourOpt__text">Turn Off</span>
                          </a>
                        ) : (
                          <a
                            className="liveTourOpt__box liveTourOpt__box-gray"
                            onClick={() => {
                              muteVideo(true);
                            }}
                          >
                            <span className="liveTourOpt__icon">
                              <i className="icon-video-slash"></i>
                            </span>
                            <span className="liveTourOpt__text">Turn On</span>
                          </a>
                        )}
                      </li>
                      <li>
                        {host?.hasAudio ? (
                          <a
                            className="liveTourOpt__box liveTourOpt__box-gray"
                            onClick={() => {
                              muteAudio(false);
                            }}
                          >
                            <span className="liveTourOpt__icon">
                              <i className="icon-microphone"></i>
                            </span>
                            <span className="liveTourOpt__text">Mute</span>
                          </a>
                        ) : (
                          <a
                            className="liveTourOpt__box liveTourOpt__box-gray"
                            onClick={() => {
                              muteAudio(true);
                            }}
                          >
                            <span className="liveTourOpt__icon">
                              <i className="icon-microphone-slash"></i>
                            </span>
                            <span className="liveTourOpt__text">Unmute</span>
                          </a>
                        )}
                      </li>
                    </React.Fragment>
                  )}
                  <li onClick={() => handleListings(true)}>
                    <a className="liveTourOpt__box liveTourOpt__box-blue">
                      <span className="liveTourOpt__icon">
                        <i className="icon-building"></i>
                      </span>
                      <span className="liveTourOpt__text">Listings</span>
                    </a>
                  </li>
                  <li>
                    <a className="liveTourOpt__box liveTourOpt__box-red" onClick={endTour}>
                      <span className="liveTourOpt__icon">
                        <i className="icon-logout"></i>
                      </span>
                      <span className="liveTourOpt__text">{isBroker ? 'End' : 'Leave'} Tour</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ListingTourView;
