import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { OverlayTrigger, Popover, Accordion, Card, Button } from 'react-bootstrap';
import { AppStateInterface } from '../../state';
import { useLeftSidebarToggle } from '../../state/leftSidebar/leftSidebar.hook';
import { startCase } from 'lodash';
import { compileAvatarUrl } from '../../utils/avatar.utils';
import { DEFAULT_AVATAR, DEFAULT_INTERCOM_CUSTOM_LAUNCHER_ID } from '../../common/constants';
import { useHistory } from 'react-router';
import { useLogout } from '../../state/login/login.hook';
import SmallLoader from '../common/small.loader';
import { Link } from 'react-router-dom';

interface Props {
  active?: string;
}

const LeftSidebar: React.FC<Props> = (props: Props) => {
  const { active } = props;
  const history = useHistory();
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const meLoading = useSelector((state: AppStateInterface) => state.me.me.loading);
  const showLeftSidebar = useSelector((state: AppStateInterface) => state.leftSidebar.show);
  const { res: logOutResponse, logOut } = useLogout();

  useEffect(() => {
    if (!logOutResponse.loading && logOutResponse.hasData) {
      history.push('/login');
    }
  }, [logOutResponse]);

  const { toggleLeftSidebar } = useLeftSidebarToggle();

  const goTo = (to: string) => {
    if (history.location.pathname !== to) {
      history.push(to);
    }
  };

  const logMeOut = () => {
    if (!logOutResponse.loading) {
      logOut();
    }
  };

  const handleSetting = () => {
    goTo('/settings');
    document.body.click();
  };

  if (meLoading) {
    return (
      <div
        className={
          showLeftSidebar ? 'sidebar sidebar--shimmer' : 'sidebar sidebar--shimmer sidebar--hide'
        }
      >
        <div className="sidebar--canvas"></div>
      </div>
    );
  } else {
    return (
      <div className={showLeftSidebar ? 'sidebar' : 'sidebar sidebar--hide'}>
        <div className="sidebar--canvas">
          <div className="sidebar--head">
            <Link className="sidebar__logo" to="/dashboard">
              <img
                src={
                  me?.company?.settings?.whiteLogo
                    ? compileAvatarUrl(me?.company?.settings?.whiteLogo.url)
                    : '/images/HomeTour_White_Logo.svg'
                }
                alt="Logo"
              />
            </Link>
            <Link className="sidebar__logo-alt" to="/dashboard">
              <img
                src={
                  me?.company?.settings?.whiteIcon
                    ? compileAvatarUrl(me?.company?.settings?.whiteIcon.url)
                    : '/images/HomeTour_White_Icon.svg'
                }
                alt="Logo"
              />
            </Link>
          </div>
          <div className="sidebar--content">
            <ul className="sidebar__list">
              <li>
                <a onClick={() => goTo('/search')} className={active === 'search' ? 'active' : ''}>
                  <i className="icon-search"></i>
                  <span className="sidebar__list-text">Quick Find</span>
                </a>
              </li>
              <li>
                <a
                  onClick={() => goTo('/dashboard')}
                  className={active === 'dashboard' ? 'active' : ''}
                >
                  <i className="icon-dashboard"></i>
                  <span className="sidebar__list-text">Dashboard</span>
                </a>
              </li>
              <li>
                <Accordion
                  defaultActiveKey={
                    active === 'activeTours' || active === 'toursHistory' ? '1' : '0'
                  }
                >
                  <Card className="sidebar--card">
                    <Card.Header>
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey="1"
                        className={
                          active === 'activeTours' || active === 'toursHistory' || active === 'tour'
                            ? 'active'
                            : ''
                        }
                        onClick={() => goTo('/tours/active')}
                      >
                        <i className="icon-tour"></i>
                        <span className="sidebar__list-text">Tours</span>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>
                        <ul className="sidebar__list sidebar__list-sub mb-0">
                          <li>
                            <Link
                              className={active === 'activeTours' ? 'active' : ''}
                              to="/tours/active"
                            >
                              <span className="sidebar__list-text">Active Tours</span>
                            </Link>
                          </li>
                          <li>
                            <Link
                              className={active === 'toursHistory' ? 'active' : ''}
                              to="/tours/history"
                            >
                              <span className="sidebar__list-text">History of Tours</span>
                            </Link>
                          </li>
                        </ul>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </li>
              <li>
                <a
                  onClick={() => goTo('/buildings')}
                  className={props?.active === 'buildings' ? 'active' : ''}
                >
                  <i className="icon-building"></i>
                  <span className="sidebar__list-text">Buildings</span>
                </a>
              </li>
            </ul>
            <ul className="sidebar__list sidebar__list-alt">
              <li>
                <a id={DEFAULT_INTERCOM_CUSTOM_LAUNCHER_ID}>
                  <i className="icon-help"></i>
                  <span className="sidebar__list-text">Help</span>
                </a>
              </li>
            </ul>
          </div>
          <OverlayTrigger
            trigger="click"
            placement="right"
            rootClose={true}
            rootCloseEvent="click"
            overlay={
              <Popover
                id="popover-basic"
                className={`sidebar--popover ${showLeftSidebar ? '' : 'sidebar--popover-sm'}`}
              >
                <Popover.Content>
                  <ul className="options__list">
                    <li>
                      <a onClick={handleSetting}>
                        <span className="options__list-icon">
                          <i className="icon-setting"></i>
                        </span>
                        Settings
                      </a>
                    </li>
                    <li>
                      <a onClick={logMeOut}>
                        <span className="options__list-icon">
                          <i className="icon-user-right"></i>
                        </span>
                        {logOutResponse.loading ? (
                          <React.Fragment>
                            Signing Out <SmallLoader />
                          </React.Fragment>
                        ) : (
                          <React.Fragment>Sign Out</React.Fragment>
                        )}
                      </a>
                    </li>
                  </ul>
                </Popover.Content>
              </Popover>
            }
          >
            <div className="sidebar--user-canvas">
              <div className="sidebar--user">
                <div className="sidebar--img">
                  <img src={me?.avatar ? compileAvatarUrl(me.avatar) : DEFAULT_AVATAR} alt="Img" />
                </div>
                <div className="sidebar--detail">
                  <div className="d-flex justify-content-between align-items-center pr-2">
                    <h5 className="sidebar__name">
                      {startCase(me?.firstName)} {startCase(me?.lastName)}
                    </h5>
                    <span className="dropdown__item-span">
                      <i className="icon-chevron-right"></i>
                    </span>
                  </div>
                  {me?.company?.name && (
                    <h6 className="sidebar__mention">@{me?.company?.name?.toUpperCase()}</h6>
                  )}
                </div>
              </div>
            </div>
          </OverlayTrigger>
        </div>
        <button onClick={toggleLeftSidebar} className="sidebar__toggle">
          <i className="icon-chevron-left"></i>
        </button>
      </div>
    );
  }
};

export default LeftSidebar;
