import React from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';

const ContactLine: React.FC = () => {
  const companyData = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );
  return (
    <React.Fragment>
      {companyData?.settings?.contactEmail && (
        <div className="pb-4">
          <p className="para__md-gray mb-3">
            Listings below do not reflect the extent of our entire inventory. If you are interested
            in seeing other available options, please{' '}
            <a
              className="link__blue ml-1"
              href={`mailto:${companyData?.settings.contactEmail}?subject=Contact%20Us`}
              target="_blank"
              rel="noopener noreferrer"
            >
              contact us
            </a>
          </p>
        </div>
      )}
    </React.Fragment>
  );
};

export default ContactLine;
