import React from 'react';
import withUnsupportedBrowserAlertForHost from './withUnsupportedBrowserAlertForHost';
import { BrowserInterface } from '../../../state/subdomain/tour/tour.types';

interface Props {
  reselectTab: () => void;
  leaveCall: () => void;
  browser: BrowserInterface | null;
}

const ScreenshareInstructions: React.FC<Props> = (props: Props) => {
  const { reselectTab } = props;

  return (
    <div className="vt--share share--realistic">
      <div className="container container--large">
        <div className="share--content d-flex flex-column flex-lg-row justify-content-center">
          <div className="share--middle text-center px-lg-3">
            <span className="share__img">text</span>
            <h3 className="heading__medium heading__medium-black font-weight-normal">Oh oh!</h3>
            <p className="em__para text__gray">
              <a className="av__link text-nowrap" onClick={reselectTab}>
                Click here
              </a>{' '}
              to open the screen sharing menu again
            </p>
          </div>
          <div className="share--options">
            <div className="share--row option--row-1">
              <span className="digit__box">1</span>
              <div className="share--detail">
                <h6 className="share__heading">Choose Chrome Tab</h6>
                <p className="share__paragraph">(Furthest to the right)</p>
              </div>
            </div>
            <div className="share--row option--row-2">
              <span className="digit__box">2</span>
              <div className="share--detail">
                <h6 className="share__heading">Select “Live Virtual Tour...”</h6>
                <p className="share__paragraph">
                  (Tab name includes listing
                  <br /> address being toured)
                </p>
              </div>
            </div>
            <div className="share--row option--row-3">
              <span className="digit__box">3</span>
              <div className="share--detail">
                <h6 className="share__heading">Click Share</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUnsupportedBrowserAlertForHost<Props>(ScreenshareInstructions);
