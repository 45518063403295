import { UploadedMediaInterface } from '../media/media.types';
import { PhotoInterface } from '../listing/listing.types';
import { SubClientInterface } from '../tour/tour.types';
import { TOUR_TYPES } from '../../common/constants';

export enum SEARCH_ACTIONS {
  QUICK_SEARCH = 'QUICK_SEARCH',
}

export interface SearchBuildingListingInterface {
  _id: string;
  suiteNo: string;
  status: string;
}

export interface SearchTourListingInterface {
  _id: string;
  suiteNo: string;
  building: {
    location: string;
  };
  subMarket: {
    name: string;
    market: string;
  };
}

export interface SearchBuildingInterface {
  _id: string;
  listings: SearchBuildingListingInterface[];
  location: string;
  subMarket: {
    _id: string;
    name: string;
    market: string;
  };
  imageUrl: string;
}

export interface SearchTourInterface {
  _id: string;
  listings: SearchTourListingInterface[];
  clientInfo?: SubClientInterface;
  tourType: TOUR_TYPES;
  expiredAt: number;
  tourUrl?: string;
}

export interface QuickSearchResultsInterface {
  buildings: SearchBuildingInterface[];
  tours: SearchTourInterface[];
  media: PhotoInterface[];
  pdfs: UploadedMediaInterface[];
  renderings: UploadedMediaInterface[];
  tourUrls: string[];
  videoUrls: string[];
}
