import React from 'react';

const ListingSearchBox: React.FC = () => {
  return (
    <div className="row row--space-10 row-availableListings">
      <div className="col-12 col-lg-4">
        <div className="search--shimmer mb-3 mb-lg-0">
          <span className="searchShimmer__icon animate--gray"></span>
          <span className="searchShimmer__text animate--gray"></span>
        </div>
      </div>
      <div className="col-12 col-lg-8">
        <div className="row row--space-10 align-items-md-center">
          <div className="col-12 col-md-9 col-lg-9">
            <div className="row row--space-10">
              <div className="col-12 col-sm-6 col-md-6">
                <div className="dropdown--shimmer mb-3 mb-md-0 w-100">
                  <span className="dropdownShimmer__text animate"></span>
                  <span className="dropdownShimmer__arrow">
                    <i className="icon-caret-down"></i>
                  </span>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6">
                <div className="dropdown--shimmer mb-3 mb-md-0 w-100">
                  <span className="dropdownShimmer__text animate"></span>
                  <span className="dropdownShimmer__arrow">
                    <i className="icon-caret-down"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 col-lg-3">
            <div className="viewBy--shimmer d-flex align-items-center justify-content-end justify-content-md-start">
              <span className="viewBy__text shimmer__style"></span>
              <span className="viewBy__marker shimmer__style ml-3"></span>
              <div className="grid--shimmer ml-3 d-flex align-items-center">
                <span className="grid__view shimmer__style animate"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingSearchBox;
