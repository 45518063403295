import React from 'react';

export const TourEditFooterBeltShimmer: React.FC = () => {
  return (
    <div className="tourEdit--listing tourEdit--listing-shimmer">
      {/*<div className="tel--cursor">
        <i className="icon-cursor"></i>
      </div>*/}
      <div className="tel--inner">
        <div className="tel--top">
          <span className="telTop__icon animate"></span>
          <span className="telTop__text animate"></span>
        </div>
        <div className="tel--bottom">
          <div className="tel--btns-row">
            <div className="tel--btns-col">
              <span className="telBtn__shimmer">
                <span className="telBtn__shimmer-icon animate"></span>
              </span>
            </div>
            <div className="tel--btns-col">
              <span className="telBtn__shimmer">
                <span className="telBtn__shimmer-icon animate"></span>
                <span className="telBtn__shimmer-text animate ml-2"></span>
              </span>
            </div>
            <div className="tel--btns-col">
              <span className="telBtn__shimmer">
                <span className="telBtn__shimmer-icon animate"></span>
                <span className="telBtn__shimmer-text animate ml-2"></span>
              </span>
            </div>
            <div className="tel--btns-col">
              <span className="telBtn__shimmer">
                <span className="telBtn__shimmer-icon animate"></span>
                <span className="telBtn__shimmer-text animate ml-2"></span>
              </span>
            </div>
            <div className="tel--btns-col">
              <span className="telBtn__shimmer">
                <span className="telBtn__shimmer-icon animate"></span>
                <span className="telBtn__shimmer-text animate ml-2"></span>
              </span>
            </div>
            <div className="tel--btns-col">
              <span className="telBtn__shimmer">
                <span className="telBtn__shimmer-icon animate"></span>
                <span className="telBtn__shimmer-text animate ml-2"></span>
              </span>
            </div>
            <div className="tel--btns-col">
              <span className="telBtn__shimmer">
                <span className="telBtn__shimmer-icon animate"></span>
                <span className="telBtn__shimmer-text animate ml-2"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const TourEditLoadingShimmer: React.FC = () => {
  return (
    <React.Fragment>
      <div className="white--rounded-box dashboard--bar dashboard--bar-shimmer mb-20">
        <div className="subDashboard--bar">
          <div className="sd--main d-flex flex-column">
            <div className="breadcrumb--shimmer">
              <span className="breadcrumbShimmer__span animate"></span>
              <span className="breadcrumbShimmer__span animate"></span>
            </div>
            <div className="db--preview animate"></div>
          </div>
          <div
            className="db--btns-shimmer btns-mobile-expand d-flex flex-column flex-sm-row align-items-center
                      justify-content-between mt-3 mt-xl-0 ml-xl-2"
          >
            <span className="db__btns animate"></span>
            <span className="db__btns animate ml-sm-20 mt-3 mt-sm-0"></span>
          </div>
        </div>
      </div>
      <div className="white--rounded-box tourEdit--home mb-20">
        <div className="tourEdit--top tourEditTop--shimmer animate">
          <div className="liveTour--placement placement--centered">
            <div className="container liveTour--container">
              <div className="placement--block placement--block-alt placement--shimmer-alt animate--dark"></div>
            </div>
          </div>
        </div>
        <div className="tourEdit--bottom mt-4">
          <div className="row row--space-10 align-items-md-center">
            <div className="col col-12 col-md-8 col-lg-7 col-xl-6">
              <div className="d-flex flex-column flex-sm-row align-items-sm-center pr-xl-4">
                <div className="dropdown--shimmer mb-3 mb-sm-0 w-100">
                  <span className="dropdownShimmer__icon animate"></span>
                  <span className="dropdownShimmer__text animate"></span>
                  <span className="dropdownShimmer__arrow">
                    <i className="icon-caret-down"></i>
                  </span>
                </div>
                <span className="lt__shimmer animate"></span>
              </div>
            </div>
            <div className="col col-12 col-md-4 col-lg-5 col-xl-6">
              <div className="text-md-right mt-3 mt-md-0">
                <a className="link__blue">Add Listing +</a>
              </div>
            </div>
          </div>
          <div className="tourEdit--listing-off tourEdit--listing-shadow-effect">
            <div className="tourEdit--listing-canvas">
              <TourEditFooterBeltShimmer />
              <TourEditFooterBeltShimmer />
            </div>
          </div>
        </div>
      </div>
      <div className="white--rounded-box mb-20">
        <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between">
          <h3 className="heading__medium heading__medium-black font-semibold mb-3 mb-sm-0">
            Your Team
          </h3>
          <a className="link__blue">Add Broker+</a>
        </div>
        <div className="team--main team--main-alt mt-4 pt-2">
          <div className="row row--space-10">
            <div className="col-12 col-md-6 col-xl-4">
              <div className="team--block team--block-alt teamBlock--shimmer">
                <div className="teamBlock--img animate"></div>
                <div
                  className="teamBlock--detail text-md-left d-flex flex-column
                           align-items-center align-items-md-start"
                >
                  <span className="teamBlock__user animate"></span>
                  <span className="teamBlock__email animate"></span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <div className="team--block team--block-alt teamBlock--shimmer">
                <div className="teamBlock--img animate"></div>
                <div
                  className="teamBlock--detail text-md-left d-flex flex-column
                           align-items-center align-items-md-start"
                >
                  <span className="teamBlock__user animate"></span>
                  <span className="teamBlock__email animate"></span>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4">
              <div className="team--block team--block-alt teamBlock--shimmer">
                <div className="teamBlock--img animate"></div>
                <div
                  className="teamBlock--detail text-md-left d-flex flex-column
                           align-items-center align-items-md-start"
                >
                  <span className="teamBlock__user animate"></span>
                  <span className="teamBlock__email animate"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center mt-4 pt-3 mb-2">
        <a className="link__blue">Delete Tour</a>
      </div>
    </React.Fragment>
  );
};

export default TourEditLoadingShimmer;
