import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import AllListings from './allListings';
import { TourListingsSelected } from '../../../../../state/subdomain/tour/tour.types';
import SelectedListings from './selectedListings';
import SmallLoader from '../../../../common/small.loader';
import { useHistory } from 'react-router';

interface Props {
  setData: (e: string[]) => void;
  loading: boolean;
}
const SelectListings: React.FC<Props> = props => {
  const history = useHistory();
  const { setData, loading } = props;
  const [selected, setSelected] = useState([] as TourListingsSelected[]);

  const submitData = () => {
    setData(selected.map(e => e.id));
  };
  const onRemove = (e: string) => {
    setSelected(prev => prev.filter(listing => listing.id !== e));
  };

  return (
    <div className="tour--canvas">
      <div className="white--rounded-box">
        <div className="ct--head mb-3">
          <h3 className="dashboard__heading font-semibold mb-0">Add Listings To Tour</h3>
          <span className="steps__text">Step 2/2</span>
        </div>
        <p className="para__lg">
          Add the listings you would like to showcase during this tour. Select as many as you want.
        </p>
        {selected && <SelectedListings data={selected} onRemove={onRemove} />}
        <AllListings
          selected={selected}
          onSelect={(e: TourListingsSelected) => setSelected(prevState => [...prevState, e])}
        />
        <div
          className="tour__btn-canvas tourListing__btn-canvas mt-4 pt-3 d-flex
       flex-column-reverse flex-sm-row align-items-center
        justify-content-between"
        >
          <Button
            type="button"
            className="outline__btn-gray outline__btn-gray-md outline__btn-radius"
            onClick={() => history.push('/tour/active')}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            className="admin__button admin__button-sm admin__button-radius mb-3 mb-sm-0"
            onClick={submitData}
            disabled={!selected.length || loading}
          >
            {loading ? (
              <React.Fragment>
                Creating <SmallLoader />
              </React.Fragment>
            ) : (
              <React.Fragment>
                Continue<i className="icon-arrow-right"></i>
              </React.Fragment>
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectListings;
