import React from 'react';
import { Link } from 'react-router-dom';

const AboutFooter: React.FC = () => {
  return (
    <React.Fragment>
      <div className="footer--top d-flex flex-column flex-md-row align-items-center justify-content-between">
        <div className="ft--f d-flex flex-column flex-sm-row align-items-center">
          <a className="footer__logo d-inline-block mb-3 mb-sm-0">
            <img src="/images/HomeTour_White_Icon.svg" alt="Logo" />
          </a>
          <span className="footer--copyright mb-3 mb-sm-0">
            <i className="icon-copyright"></i> 2020 HomeTour. All Rights Reserved
          </span>
        </div>
        {/*<ul className="social__list social__list-f">
          <li>
            <a
              href="https://www.instagram.com/officetour/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="icon-instagram"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/in/office-tour-a551941aa/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="icon-linkedin-square"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.facebook.com/OfficeTour-107015984357012"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="icon-facebook-square"></i>
            </a>
          </li>
          <li>
            <a href="https://twitter.com/officetourhq" target="_blank" rel="noopener noreferrer">
              <i className="icon-twitter"></i>
            </a>
          </li>
        </ul>*/}
      </div>
      <div className="footer--bottom d-flex flex-column flex-md-row align-items-center justify-content-between">
        <ul className="f__list f__list-left">
          {/*<li>
            <a>News</a>
          </li>*/}
          <li>
            <a onClick={() => window.scrollTo(0, 0)}>About</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
        <ul className="f__list f__list-right">
          <li>
            <Link to="/privacy">Privacy</Link>
          </li>
          <li>
            <Link to="/terms">Terms</Link>
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default AboutFooter;
