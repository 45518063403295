import { AxiosPromise, CancelToken } from 'axios';
import api from '../../utils/api.utils';
import { SubMarketListResponseInterface } from './submarket.types';

export const getSubMarketsListAPI = (
  companyId: string,
  ct?: CancelToken,
): AxiosPromise<Array<SubMarketListResponseInterface>> => {
  return api.get(`/sub-market/${companyId}`, {
    cancelToken: ct,
  });
};
