import React from 'react';
import LeftSidebar from '../../components/leftSidebar';
import SettingsForm from '../../components/setting';

const SettingsPage: React.FC = () => {
  return (
    <div className="dashboard--page">
      <LeftSidebar />
      <div className="dashboard--content">
        <div className="dashboard--container">
          <div className="dashboard--canvas">
            <SettingsForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
