import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import RequestForm from './form';
import RequestComplete from './completion';

interface Props {
  hideForm: () => void;
}
const ListingRequestForm: React.FC<Props> = props => {
  const { hideForm: hide } = props;
  const [complete, handleComplete] = useState(false);

  return (
    <Modal show onHide={hide} className="listing--modal" contained-modal-title-vcenter="true">
      <Modal.Body>
        {!complete && <RequestForm hide={hide} complete={() => handleComplete(true)} />}
        {complete && <RequestComplete />}
      </Modal.Body>
    </Modal>
  );
};
export default ListingRequestForm;
