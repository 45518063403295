import * as jwt from 'jsonwebtoken';
function decodeJwt<T = any>(token: string): T {
  let decoded: T;
  try {
    decoded = jwt.decode(token, { json: true }) as T;
    if (decoded) {
      return decoded;
    }
    throw new Error('Invalid Token!');
  } catch (e) {
    // report the error to our central logging system
    throw e;
  }
}

export { decodeJwt };
