import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppStateInterface } from '../../../state';
import { GetListingObject } from '../../../state/listing/listing.types';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import TourDocs from './pdfButton';
import { useHistory } from 'react-router';
import { AdditionalUser } from '../../../state/subdomain/tour/tour.types';

interface Props {
  listing: GetListingObject;
  users?: AdditionalUser[];
  title: string;
}

const ParticipantTourEnded: React.FC<Props> = (props: Props) => {
  const { listing, users, title } = props;
  const history = useHistory();
  const [contactHref, setContactHref] = useState<string>();

  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );

  useEffect(() => {
    if (users) {
      const emails = users?.map(user => user.email);
      if (emails.length && emails[0] !== undefined)
        setContactHref(`mailto:${emails?.join(',')}?subject=Contact%20Us`);
      else setContactHref('#');
    }
  }, [listing]);

  return (
    <div className="sec--vertical sec--vertical-md">
      <div className="container container--large">
        <Link className="listing__logo d-inline-block" to="/">
          <img
            src={
              company?.settings.darkLogo
                ? compileAvatarUrl(company.settings.darkLogo.url)
                : '/images/HomeTour_Dark_Logo.svg'
            }
            alt="Logo"
          />
        </Link>
        <div className="tourEnd--content tourEnd--tenant sec--vertical-middle">
          <div className="container--xs">
            <div className="text-center">
              <span className="sub__logo mb-48">
                <img
                  src={
                    company?.settings.darkIcon
                      ? compileAvatarUrl(company.settings.darkIcon.url)
                      : '/images/HomeTour_Dark_Icon.svg'
                  }
                  alt="Logo"
                />
              </span>
              <h3 className="dashboard__heading mb-4">Thank you for touring with us!</h3>
              <p className="form__para mb-4 pb-1">
                It was a pleasure to show you around {title}.<br />
                We look forward to hearing more from you.
              </p>
              <p className="form__para mb-4 pb-1">
                We are here to assist you. Please feel free to reach out with any questions or
                comments.
              </p>
              <div className="d-inline-block w-100 mt-3 mb-4 mb-sm-5 pb-xl-4">
                <div className="row row--space-10">
                  {listing.pdfs?.length > 0 && <TourDocs {...props} />}
                  <div className="col-12 col-sm-6">
                    <button
                      type="button"
                      className="outline__button w-100 mb-3 mb-sm-0"
                      onClick={() => history.push('/')}
                    >
                      Check Out More Listings
                    </button>
                  </div>
                  <div className="col-12 col-sm-6">
                    <a
                      type="button"
                      className="outline__button w-100"
                      href={contactHref}
                      target={contactHref === '#' ? '_self' : '_blank'}
                    >
                      Contact Us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantTourEnded;
