import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import {
  REVIEW_STATE as revState,
  RECORDING_STATE as recState,
  VIDEO_STATUS,
} from '../../../common/constants';
import { changeVideoStatus } from '../../../state/review/review.action';
import SmallLoader from '../../common/small.loader';
import { AudioVoiceOverInterface } from '../../../state/listing/listing.types';

interface Props {
  loadingState: boolean;
  onSubmit: () => void;
  submitEnabled: boolean;
}
const ReviewHandlers: React.FC<Props> = props => {
  const { loadingState: loading, onSubmit: submit, submitEnabled } = props;
  const review = useSelector((state: AppStateInterface) => state.review);
  const listing = useSelector((state: AppStateInterface) => state.listing.singleListing.data);

  const [submitting, setSubmit] = useState(false);
  const [rec, setRec] = useState(recState.IDLE);
  const [rev, setRev] = useState(revState.IDLE);
  const [newAudios, setNewAudios] = useState([] as AudioVoiceOverInterface[]);

  const handleSubmit = () => {
    submit();
    setSubmit(true);
  };
  useEffect(() => {
    setRec(review.recordingState);
    setRev(review.reviewState);
  }, [review]);
  useEffect(() => {
    if (review.audios?.length) setNewAudios([...review.audios.filter(audio => !audio.isPrevious)]);
    else setNewAudios([]);
  }, [review.audios]);

  const showSubmitButton = listing?.videoStatus === VIDEO_STATUS.PENDING;
  return (
    <React.Fragment>
      {rev !== revState.ERROR && rec !== recState.ERROR && rev !== revState.DISABLE && (
        <React.Fragment>
          {rev === revState.INITIALIZE && rec === recState.READY && (
            <div className={`row row--space-10 ${!newAudios.length && 'mt-4 pt-1'}`}>
              {!newAudios.length && (
                <div className="col-12 col-sm-6">
                  <button
                    className="outline__button listing__button lb__record w-100"
                    onClick={() => changeVideoStatus(revState.READY)}
                    disabled={loading}
                  >
                    <span className="lb__record-icon">
                      <i className="icon-dot-circle"></i>
                    </span>
                    {loading ? (
                      <React.Fragment>
                        Loading <SmallLoader />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>Begin Recording</React.Fragment>
                    )}
                  </button>
                </div>
              )}
              {newAudios.length > 0 && (
                <div className="col-12 col-lg-7 col-xl-6">
                  <button
                    className="outline__button listing__button lb__record w-100"
                    onClick={() => changeVideoStatus(revState.READY)}
                    disabled={loading}
                  >
                    <span className="lb__record-icon">
                      <i className="icon-dot-circle"></i>
                    </span>
                    {loading ? (
                      <React.Fragment>
                        Loading <SmallLoader />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>Add Other Recording +</React.Fragment>
                    )}
                  </button>
                </div>
              )}
              {showSubmitButton && submitEnabled && (
                <div className="col-12 col-sm">
                  <button
                    className="admin__button listing__button lb__arrow w-100"
                    onClick={handleSubmit}
                  >
                    {submitting ? (
                      <React.Fragment>
                        Submitting <SmallLoader />
                      </React.Fragment>
                    ) : review?.audios?.length > 1 ? (
                      'Submit All Recordings'
                    ) : (
                      'Submit Recording'
                    )}
                  </button>
                </div>
              )}
            </div>
          )}
          {(rec === recState.RECORD || rec === recState.STOP) && (
            <div className="row row--space-10">
              <div className="col-12 col-sm">
                {(rev === revState.START || rev === revState.RESUME) && (
                  <button
                    className="outline__button listing__button lb__record w-100"
                    onClick={() => changeVideoStatus(revState.PAUSE)}
                    disabled={loading}
                  >
                    <span className="lb__record-icon">
                      <i className="icon-pause-circle"></i>
                    </span>
                    Pause Recording
                  </button>
                )}
                {rev === revState.PAUSE && (
                  <button
                    className="outline__button listing__button lb__record w-100"
                    onClick={() => changeVideoStatus(revState.RESUME)}
                    disabled={loading}
                  >
                    <span className="lb__record-icon">
                      <i className="icon-play-circle"></i>
                    </span>
                    Resume Recording
                  </button>
                )}
              </div>
              <div className="col-12 col-sm">
                <button
                  className="admin__button listing__button lb__record lb__pause__resume w-100"
                  onClick={() => changeVideoStatus(revState.STOP)}
                  disabled={loading}
                >
                  <span className="lb__record-icon">
                    <i className="icon-stop-circle"></i>
                  </span>
                  {loading ? (
                    <React.Fragment>
                      Saving <SmallLoader />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>Stop Recording</React.Fragment>
                  )}
                </button>
              </div>
            </div>
          )}
        </React.Fragment>
      )}
      {rec === recState.ERROR && (
        <p className="small__paragraph">
          Your microphone is disabled, please check your browser settings to enable it
        </p>
      )}
      {rev === revState.DISABLE && (
        <p className="small__paragraph">To start recording, please pause the video.</p>
      )}
    </React.Fragment>
  );
};

export default ReviewHandlers;
