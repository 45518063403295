import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { SearchTourInterface } from '../../state/search/search.types';
import { DEFAULT_LISTING_COUNT_IN_TOUR, SEPARATORS, TOUR_TYPES } from '../../common/constants';
import { Link } from 'react-router-dom';
import { checkIfNumber, copyTourLinkToClipboard } from '../../utils/misc.utils';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../state';

interface Props {
  tours: SearchTourInterface[];
}

const TourSearchResults: React.FC<Props> = (props: Props) => {
  const { tours } = props;
  const [showCount, setShowCount] = useState(DEFAULT_LISTING_COUNT_IN_TOUR);
  const me = useSelector((state: AppStateInterface) => state.me.me.data);

  return (
    <div className="searchResults--block">
      <h5 className="heading__sm heading__sm-black font-semibold">Tours</h5>
      {tours?.map((tour: SearchTourInterface) => (
        <div className="mt-4" key={tour._id}>
          <div className="upcomingTour--block mb-20">
            <div className="upcomingTour--main">
              <div className="upcomingTour--left">
                {tour?.tourType === TOUR_TYPES.LIVE && (
                  <React.Fragment>
                    <span className="upComTour__icon">
                      <i className="icon-live-integrate"></i>
                    </span>
                    <span className="upComTour__name">
                      Live Integrated
                      <br /> Video
                    </span>
                  </React.Fragment>
                )}
                {tour?.tourType === TOUR_TYPES.PRESENT && (
                  <React.Fragment>
                    <span className="upComTour__icon">
                      <i className="icon-tv-alt"></i>
                    </span>
                    <span className="upComTour__name">
                      Tour Only
                      <br /> (Presentation Style)
                    </span>
                  </React.Fragment>
                )}
              </div>
              <div className="upcomingTour--right">
                <ul className="upComTour__list">
                  {tour?.clientInfo && tour?.clientInfo?.company && (
                    <li>
                      <span className="upComTour__list-icon">
                        <i className="icon-schedule-dashboard"></i>
                      </span>
                      <span className="upComTour__list-text">
                        {tour?.clientInfo?.firstName} {tour?.clientInfo?.lastName}{' '}
                        {tour?.clientInfo?.brokerCompany
                          ? `@${tour?.clientInfo?.brokerCompany}, Representing `
                          : '@'}
                        {tour?.clientInfo?.company && (
                          <a className="link__dark">{tour?.clientInfo?.company}</a>
                        )}
                      </span>
                    </li>
                  )}
                  <li>
                    <span className="upComTour__list-icon">
                      <i className="icon-floorplan"></i>
                    </span>
                    {tour?.listings.length === 0 && 'There are no listings added to this tour'}
                    {tour?.listings.length > 0 &&
                      tour?.listings?.map((listing, index, tourListings) =>
                        index < showCount ? (
                          <span className="upComTour__list-text" key={index}>
                            {listing?.building?.location?.split(SEPARATORS.COMMA)[0]},{' '}
                            {checkIfNumber(listing?.suiteNo)
                              ? `Suite ${listing?.suiteNo}`
                              : listing?.suiteNo}
                            {tourListings?.length > showCount &&
                              index === DEFAULT_LISTING_COUNT_IN_TOUR - 1 && (
                                <a
                                  className="link__blue-sm ml-2"
                                  onClick={() =>
                                    setShowCount(
                                      tourListings?.length || DEFAULT_LISTING_COUNT_IN_TOUR,
                                    )
                                  }
                                >
                                  + {tourListings.length - showCount} More
                                </a>
                              )}
                            {tourListings?.length === showCount &&
                              tourListings?.length > DEFAULT_LISTING_COUNT_IN_TOUR &&
                              index + 1 === showCount && (
                                <a
                                  className="link__blue-sm ml-2"
                                  onClick={() => setShowCount(DEFAULT_LISTING_COUNT_IN_TOUR)}
                                >
                                  Show Less
                                </a>
                              )}
                          </span>
                        ) : (
                          <React.Fragment key={index}></React.Fragment>
                        ),
                      )}
                  </li>
                </ul>
              </div>
            </div>
            <div className="upComTour--btn-canvas">
              <Button
                className="link__btn link__btn-blue"
                onClick={() =>
                  copyTourLinkToClipboard(
                    tour._id,
                    tour.expiredAt,
                    me?.company?.domain,
                    tour.tourUrl,
                  )
                }
              >
                Copy Tour Link
                <i className="icon-link"></i>
              </Button>
              <Link className="link__btn link__btn-gray" to={`/tour/${tour._id}/edit`}>
                Edit
                <i className="icon-edit-alt"></i>
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TourSearchResults;
