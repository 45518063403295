import { useCallback, useState, useEffect } from 'react';
import {
  ListingActions as actionName,
  TourFormInterface,
  orderByTypes,
  sortByTypes,
  GetTourListingRequest,
  GetTourListings,
  GetTourUrlResponseInterface,
} from './listing.types';
import { postTourRequestAPI, getAllTourListingsAPI } from './listing.api';
import fetchActions from './../../../utils/state.utils';
import { GetState, GetStateInterface } from '../../../types/GetStateInterface';
import { useSelector } from 'react-redux';
import { AppStateInterface } from './../..';
import {
  clearAllListings,
  clearSingleListing,
  getAllListings,
  getSingleListing,
} from './listing.action';
import { DEFAULT_LISTING_LIMIT } from './../../../common/constants';
import axios from 'axios';

export const useGetAllSdListings = () => {
  const res = useSelector((state: AppStateInterface) => state.subdomain.listing.allListings);

  const getAllFilteredListings = (
    companyId: string,
    search?: string,
    sortBy?: sortByTypes,
    orderBy?: orderByTypes,
    subMarket?: string,
  ) => {
    getAllListings(companyId, DEFAULT_LISTING_LIMIT, 0, search, sortBy, orderBy, subMarket);
  };

  const fetchMore = (
    companyId: string,
    search?: string,
    sortBy?: sortByTypes,
    orderBy?: orderByTypes,
    subMarket?: string,
  ) => {
    if (res.data?.hasMore && !res.loading) {
      getAllListings(
        companyId,
        DEFAULT_LISTING_LIMIT,
        res.data?.skip || 0,
        search,
        sortBy,
        orderBy,
        subMarket,
        res.data?.data || [],
      );
    }
  };

  return { res, getAllFilteredListings, fetchMore };
};

export const useClearAllListings = () => {
  return { clearAllListings };
};

export const useGetSingleListingAction = () => {
  return { getSingleListing };
};

export const useClearSingleListing = () => {
  return { clearSingleListing };
};

export const useTourRequest = () => {
  const [res, setRes] = useState<GetStateInterface<GetTourUrlResponseInterface>>(GetState);
  const postTourRequest = useCallback(async (payload: TourFormInterface) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.SUBMIT_TOUR_FORM);
    try {
      const res = await postTourRequestAPI(
        payload,
        fetchActions.setCT(actionName.SUBMIT_TOUR_FORM),
      );
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      setRes({ data: null, loading: false, error: error, hasData: true });
    }
  }, []);
  return { res, postTourRequest };
};

export const useTourListing = (companyId: string) => {
  const [res, setRes] = useState<GetStateInterface<GetTourListings>>(GetState);
  const getTourListings = useCallback(async (payload: GetTourListingRequest) => {
    if (companyId.length) {
      payload.limit = 10;
      payload.companyId = companyId;
      if (!payload.skip) payload.skip = 0;
      setRes(prevState => ({ ...prevState, loading: true }));
      fetchActions.callCT(actionName.GET_TOUR_LISTINGS);
      try {
        const res = await getAllTourListingsAPI(
          payload,
          fetchActions.setCT(actionName.GET_TOUR_LISTINGS),
        );
        setRes({ data: res.data, loading: false, error: null, hasData: true });
      } catch (error) {
        if (!axios.isCancel(error)) {
          setRes({ data: null, loading: false, error: error, hasData: true });
        }
      }
    }
  }, []);
  return { res, getTourListings };
};
