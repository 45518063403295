import { AxiosPromise, CancelToken } from 'axios';
import api from '../../../utils/api.utils';
import { PopulatedRoomInterface, CreateTourInterface } from './tour.types';
import { GetTourUrlResponseInterface } from '../../listing/listing.types';

export const tourAPI = (tourId: string): AxiosPromise<PopulatedRoomInterface> => {
  return api.get(`/room/${tourId}`);
};

export const deleteTourAPI = (tourId: string): AxiosPromise => {
  return api.delete(`/room/${tourId}`);
};

export const createTourAPI = (
  payload: CreateTourInterface,
  ct?: CancelToken,
): AxiosPromise<GetTourUrlResponseInterface> => {
  return api.post('/room', payload, {
    cancelToken: ct,
  });
};
