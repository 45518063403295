import React from 'react';
import { Navbar, Button, Nav } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const AboutHeader: React.FC = () => {
  const history = useHistory();

  return (
    <Navbar sticky="top" expand="lg" className="navbar__header landing__header landing__header-n">
      <div className="container-fluid fluid--expand">
        <Navbar.Brand>
          <Link to="/">
            <img src="/images/HomeTour_Dark_Logo.svg" alt="Logo" />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggler--n">
          <span></span>
          <span></span>
          <span></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="navbar__list navbar__list-n">
            <Nav.Link href="#strive">The Product</Nav.Link>
            <Nav.Link href="#team">Team</Nav.Link>
            <Nav.Link href="#contact">Contact Us</Nav.Link>
          </Nav>
          <div className="navbar--btns">
            {/*<Button className="nav__signin-button navSignin__btn mr-lg-4">
              For Tenants{' '}
              <span className="key__span">
                <i className="icon-key"></i>
              </span>
            </Button>*/}
            <Button
              className="schedule__btn-outline button__radius"
              onClick={() => history.push('/')}
            >
              For Brokers{' '}
              <span className="key__span">
                <i className="icon-business"></i>
              </span>
            </Button>
          </div>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
};

export default AboutHeader;
