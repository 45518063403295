import React from 'react';
import LeftSidebar from '../../components/leftSidebar';
import Dashboard from '../../components/dashboard';

const DashboardPage: React.FC = () => {
  return (
    <div className="dashboard--page buildings bg--gray">
      <LeftSidebar active="dashboard" />
      <Dashboard />
    </div>
  );
};

export default DashboardPage;
