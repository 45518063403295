import React from 'react';

const ExistingTourAddBrokerBlockShimmer: React.FC = () => {
  return (
    <div className="tourListing--block tourListing--block-alt tl--alt-shimmer tourListing--block-plus">
      <span className="tourListing__img animate"></span>
      <div className="tourListing--row">
        <p className="para__md-dark animate"></p>
        <span className="plus__shimmer animate">
          <i className="icon-plus"></i>
        </span>
      </div>
    </div>
  );
};

export default ExistingTourAddBrokerBlockShimmer;
