import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { AppStateInterface } from '../../../state';
import { GetListingObject, PhotoInterface } from '../../../state/listing/listing.types';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import { UploadedMediaInterface } from '../../../state/media/media.types';

interface Props {
  listing: GetListingObject;
  isExpanded: boolean;
  onExpanded: (e: boolean) => void;
  currentIndex: number;
  changeIndex: (e: number) => void;
  floorPlans?: boolean;
  className?: string;
  additionalImages: UploadedMediaInterface[];
}

interface Image {
  src: string;
  alt: string;
}

const ListingTourGallery: React.FC<Props> = (props: Props) => {
  const {
    listing,
    isExpanded,
    onExpanded,
    currentIndex,
    changeIndex,
    floorPlans,
    className,
    additionalImages: more,
  } = props;
  const [imageList, setImageList] = useState<Image[]>([]);

  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );

  useEffect(() => {
    let images: UploadedMediaInterface[] = [];
    if (floorPlans) images = [...listing.floorPlans, ...more];
    else {
      const photos = listing.media;
      const primaryIdx = photos.findIndex(image => image.isPrimary);
      if (primaryIdx !== -1) {
        const primaryImage = photos[primaryIdx];
        photos.splice(primaryIdx, 1);
        photos.unshift(primaryImage);
      }
      images = [...photos, ...more];
    }
    if (images.length === 1) {
      changeIndex(0);
      onExpanded(false);
    }
    setImageList(
      images.map(image => {
        return {
          src: compileAvatarUrl(image.url),
          alt: image.name,
        };
      }),
    );
  }, [listing, floorPlans, className]);

  return (
    <React.Fragment>
      <div
        className={`liveTour--gallery liveTour--custom liveTour--wide ${className} ${
          floorPlans && currentIndex > -1 ? 'bg-white' : ''
        }`}
      >
        <div className="container liveTour--container">
          <div className="liveTour--head d-flex align-items-center justify-content-between">
            <a className="liveTour__logo listing__logo">
              <img
                src={
                  company?.settings.whiteLogo
                    ? compileAvatarUrl(company.settings.whiteLogo.url)
                    : '/images/HomeTour_White_Logo.svg'
                }
                alt="Logo"
              />
            </a>
            <h5 className="liveTour__heading text-white">
              {listing.building?.location?.split(',')[0]} {listing.suiteNo}
            </h5>
          </div>

          <div className={`liveTour--gallery-content ${!isExpanded ? '' : 'hide--section'}`}>
            <div className={`liveTour--gallery-row ${currentIndex === -1 ? '' : 'hide--section'}`}>
              {imageList.map((image, idx) => (
                <div className="liveTour--gallery-col" onClick={() => changeIndex(idx)} key={idx}>
                  <div className="liveTour--gallery-img">
                    <img src={image.src} alt={image.alt} className="img--cover" />
                  </div>
                </div>
              ))}
            </div>
            {imageList?.map((image, index) => (
              <div
                key={index}
                className={`liveTour--container gallery--large-canvas ${
                  currentIndex > -1 && currentIndex === index ? '' : 'hide--section'
                }`}
              >
                <div
                  className={`liveTour--gallery-large liveTour--wide ${
                    floorPlans ? 'bg-white' : ''
                  }`}
                  onClick={() => onExpanded(true)}
                >
                  <img src={image?.src} alt={image?.alt} className="img--cover" />
                </div>
                <span
                  className={`gallery__cross ${
                    currentIndex > -1 && imageList?.length > 1
                      ? floorPlans
                        ? 'text-dark'
                        : ''
                      : 'hide--section'
                  }`}
                  onClick={() => changeIndex(-1)}
                >
                  <i className="icon-cross"></i>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {imageList?.map((image, index) => (
        <div
          key={index}
          className={`liveTour--gallery-expanded liveTour--wide ${
            isExpanded && currentIndex === index ? '' : 'hide--section'
          }`}
          onClick={() => onExpanded(false)}
        >
          <img src={image.src} alt={image.alt} className="img--cover" />
        </div>
      ))}
    </React.Fragment>
  );
};

export default ListingTourGallery;
