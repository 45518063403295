import React, { useEffect } from 'react';
import { Form, Button, Modal } from 'react-bootstrap';
import useForm from 'react-hook-form';
import {
  ChangePasswordFormFields,
  ChangePasswordFormSchema,
} from '../../state/login/forms/login.form';
import { useChangePassword } from '../../state/login/login.hook';
import { showNotification } from '../../utils/misc.utils';
import { errorExtractor } from '../../utils/error.utils';
import SmallLoader from '../common/small.loader';

interface Props {
  show: boolean;
  setShow: (value: boolean) => void;
}

const ChangePasswordModal: React.FC<Props> = (props: Props) => {
  const { show, setShow } = props;
  const { register, reset, handleSubmit, errors } = useForm({
    validationSchema: ChangePasswordFormSchema,
  });
  const { res: changePasswordRes, changePassword } = useChangePassword();

  useEffect(() => {
    if (!changePasswordRes.loading && changePasswordRes.data) {
      reset();
      setShow(false);
      showNotification('success', 'Success', 'Password changed successfully');
    }
  }, [changePasswordRes]);

  const handleClose = (value: boolean) => {
    reset();
    setShow(value);
  };

  const onChangePassword = (data: any, e: any) => {
    e.preventDefault();
    changePassword({
      ...data,
    });
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="modal-settings"
      contained-modal-title-vcenter="true"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onChangePassword)}>
          <div className="modalSetting-canvas">
            <Form.Group className="forms--group">
              <Form.Control
                ref={register}
                name={ChangePasswordFormFields.CURRENT_PASSWORD}
                type="password"
                placeholder="Type Current Password"
                className={errors[ChangePasswordFormFields.CURRENT_PASSWORD] ? 'error' : ''}
              />
              {!changePasswordRes.loading && changePasswordRes.error && (
                <span className="error__msg">{errorExtractor(changePasswordRes.error)}</span>
              )}
              {errors[ChangePasswordFormFields.CURRENT_PASSWORD] && (
                <span className="error__msg">
                  {errors[ChangePasswordFormFields.CURRENT_PASSWORD]?.message}
                </span>
              )}
            </Form.Group>
            <Form.Group className="forms--group">
              <Form.Control
                ref={register}
                name={ChangePasswordFormFields.NEW_PASSWORD}
                type="password"
                placeholder="Type New Password"
                className={errors[ChangePasswordFormFields.NEW_PASSWORD] ? 'error' : ''}
              />
              {errors[ChangePasswordFormFields.NEW_PASSWORD] && (
                <span className="error__msg">
                  {errors[ChangePasswordFormFields.NEW_PASSWORD]?.message}
                </span>
              )}
            </Form.Group>
            <Form.Group className="forms--group">
              <Form.Control
                ref={register}
                name={ChangePasswordFormFields.CONFIRM_NEW_PASSWORD}
                type="password"
                placeholder="Confirm New Password"
                className={errors[ChangePasswordFormFields.CONFIRM_NEW_PASSWORD] ? 'error' : ''}
              />
              {errors[ChangePasswordFormFields.CONFIRM_NEW_PASSWORD] && (
                <span className="error__msg">
                  {errors[ChangePasswordFormFields.CONFIRM_NEW_PASSWORD]?.message}
                </span>
              )}
            </Form.Group>
            <div className="row row--space-12 flex-sm-row-reverse pt-sm-4 mb-4 pb-2">
              <div className="col-12 col-sm-6">
                <Button
                  type="submit"
                  className="admin__button underline__button w-100 mb-4 mb-sm-0"
                  disabled={changePasswordRes.loading}
                >
                  {changePasswordRes.loading ? 'Changing' : 'Change Password'}
                  {changePasswordRes.loading && <SmallLoader />}
                </Button>
              </div>
              <div className="col-12 col-sm-6">
                <Button
                  onClick={() => handleClose(false)}
                  className="outline__button underline__button w-100"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ChangePasswordModal;
