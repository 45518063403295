import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { useDeleteTour } from '../../../state/tour/tour.hook';
import { showNotification } from '../../../utils/misc.utils';
import { errorExtractor } from '../../../utils/error.utils';
import { useHistory } from 'react-router';
import { Modal, Button } from 'react-bootstrap';
import SmallLoader from '../../common/small.loader';

const DeleteTour: React.FC = () => {
  const history = useHistory();
  const tourInfo = useSelector((state: AppStateInterface) => state.tour.tourInfo.data);
  const { res: delRes, deleteTour } = useDeleteTour();

  const [showWarning, handleShow] = useState(false);
  useEffect(() => {
    if (!delRes.loading && delRes.hasData) {
      if (delRes.error) showNotification('error', 'Delete Error', errorExtractor(delRes.error));
      else if (delRes.data) {
        showNotification('success', 'Success', 'Tour deleted successfully');
        history.push('/tours/active');
      }
    }
  }, [delRes]);

  return (
    <React.Fragment>
      {tourInfo && (
        <React.Fragment>
          <Modal
            show={showWarning}
            onHide={() => handleShow(false)}
            className="office-modal"
            contained-modal-title-vcenter
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">Delete Tour</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="em__para text__gray">
                Are you sure you want to delete this tour? You will lose all information within it.
              </p>
              <div className="row row--space-12 flex-sm-row-reverse pt-sm-4 mb-3 pb-2">
                <div className="col-12 col-sm-6">
                  <Button
                    type="button"
                    className={`admin__button underline__button w-100 mb-4 mb-sm-0`}
                    onClick={() => deleteTour(tourInfo._id)}
                    disabled={delRes.loading}
                  >
                    {delRes.loading ? (
                      <React.Fragment>
                        Deleting <SmallLoader />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>Delete</React.Fragment>
                    )}
                  </Button>
                </div>
                <div className="col-12 col-sm-6">
                  <Button
                    onClick={() => handleShow(false)}
                    className="outline__button underline__button w-100"
                    disabled={delRes.loading}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <div className="text-center mt-4 pt-3 mb-2" onClick={() => handleShow(true)}>
            <a className="link__blue">Delete Tour</a>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default DeleteTour;
