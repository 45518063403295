import React, { useState, useEffect } from 'react';
import GoogleMaps from './../../../location';
import { GetSubDomainListings } from '../../../../state/subdomain/listing/listing.types';
import { DEFAULT_LOC } from '../../../../common/constants';
import { isEmpty } from 'lodash';

interface Props {
  data: GetSubDomainListings[];
  center: string;
}
interface Location {
  lat: number;
  lng: number;
}
const ListingMap: React.FC<Props> = props => {
  const [locations, setLocations] = useState([] as Location[]);
  const [center, setCenter] = useState({} as Location);
  const [centerIndex, setCenterIndex] = useState(0);

  useEffect(() => {
    if (props.data.length) {
      const locs = [] as Location[];
      const data = props.data;
      const center = props.center;
      let count = -1;
      for (const j in data) {
        const listings = data[j].listings;
        for (const i in listings) {
          if (listings[i].building.latitude && listings[i].building.longitude) {
            count++;
            if (listings[i]._id === center) {
              setCenter({
                lat: parseFloat(listings[i].building.latitude || ''),
                lng: parseFloat(listings[i].building.longitude || ''),
              });
              setCenterIndex(count);
            }
            locs.push({
              lat: parseFloat(listings[i].building.latitude || ''),
              lng: parseFloat(listings[i].building.longitude || ''),
            });
          }
        }
      }
      setLocations(locs);
    } else setLocations([DEFAULT_LOC]);
  }, [props]);

  return (
    <div className="available--listing-map listing-map-view">
      <div className="listing-map-view">
        {locations.length > 0 && (
          <GoogleMaps
            zoom={props.center.length ? 17 : 13}
            location={isEmpty(center) ? locations[0] : center}
            marker={locations}
            selected={centerIndex}
          />
        )}
      </div>
    </div>
  );
};

export default ListingMap;
