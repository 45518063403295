import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { SortAndOrderInterface } from '../../../../../state/subdomain/listing/listing.types';
import { SEPARATORS } from '../../../../../common/constants';

interface Props {
  sortAndOrder: SortAndOrderInterface;
  setSortAndOrder: (sortAndOrder: SortAndOrderInterface) => void;
}

const AvailableFilter: React.FC<Props> = (props: Props) => {
  const { sortAndOrder, setSortAndOrder } = props;
  const onSelect = (e: any) => {
    const selectedValue = e.target.value.split(SEPARATORS.SLASH);
    if (selectedValue.length === 2) {
      setSortAndOrder({
        sortBy: selectedValue[0] === 'clear' ? undefined : selectedValue[0],
        orderBy: selectedValue[1] === 'clear' ? undefined : selectedValue[1],
      });
    }
  };

  return (
    <Form.Group className="forms--group form-option--radius select--group mb-3 mb-lg-0">
      <Form.Control
        as="select"
        value={`${sortAndOrder.sortBy}/${sortAndOrder.orderBy}`}
        onChange={onSelect}
      >
        <option value="clear/clear">
          {sortAndOrder.sortBy && sortAndOrder.orderBy ? 'Clear Filter' : 'Select Filter'}
        </option>
        <option value="availableNow/ascending">Available Now</option>
        <option value="availableInNinety/descending">Available in 90 Days</option>
        <option value="size/descending">SF High to Low</option>
        <option value="size/ascending">SF Low to High</option>
        <option value="monthlyRent/descending">Price High to Low</option>
        <option value="monthlyRent/ascending">Price Low to High</option>
      </Form.Control>
      <span className="select__caret-down">
        <i className="icon-caret-down"></i>
      </span>
    </Form.Group>
  );
};

export default AvailableFilter;
