import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { GetListingObject } from '../../../state/listing/listing.types';
import { compileAvatarUrl } from '../../../utils/avatar.utils';

const BrokerTourEnded: React.FC = () => {
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );

  return (
    <div className="sec--vertical sec--vertical-md">
      <div className="container container--large">
        <Link className="listing__logo d-inline-block" to="/">
          <img
            src={
              company?.settings.darkLogo
                ? compileAvatarUrl(company.settings.darkLogo.url)
                : '/images/HomeTour_Dark_Logo.svg'
            }
            alt="Logo"
          />
        </Link>
        <div className="tourEnd--content tourEnd--broker sec--vertical-middle">
          <div className="container--xs">
            <div className="text-center">
              <span className="sub__logo mb-48">
                <img
                  src={
                    company?.settings.darkIcon
                      ? compileAvatarUrl(company.settings.darkIcon.url)
                      : '/images/HomeTour_Dark_Icon.svg'
                  }
                  alt="Logo"
                />
              </span>
              <h3 className="dashboard__heading mb-4">Tour is over.</h3>
              <p className="form__para mb-4">
                We suggest you follow up with the prospective tenant and provide anything they
                requested, or information that will help close this deal.
              </p>
              <div className="d-inline-block w-100 mt-3 mb-4 mb-sm-5 pb-xl-4">
                <div className="row row--space-10">
                  {/*
                    <div className="col-12">
                      <button type="submit" className="admin__button w-100">
                        Email Prospective Tenant
                      </button>
                    </div>
                    */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrokerTourEnded;
