import React from 'react';
import { Link } from 'react-router-dom';
import { User } from '../../../state/listing/listing.types';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import { DEFAULT_AVATAR } from '../../../common/constants';

interface Props {
  title: string;
  brokers?: User[];
}

const ParticipantTourExpired: React.FC<Props> = (props: Props) => {
  const { brokers, title } = props;
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );
  return (
    <div className="link--expired sec--vertical sec--vertical-md">
      <div className="container container--large">
        <Link className="listing__logo" to="/">
          <img
            src={
              company?.settings?.darkLogo
                ? compileAvatarUrl(company.settings.darkLogo.url)
                : '/images/HomeTour_Dark_Logo.svg'
            }
            alt="Logo"
          />
        </Link>
        <div className="linkEx--content linkEx--content-tenant sec--vertical-middle">
          <div className="md--canvas">
            <div className="linkEx--detail text-center mb-5 pb-2">
              <span className="linkEx__img">text</span>
              <h3 className="heading__medium heading__medium-black font-weight-normal">
                Sorry, this URL is no longer available
              </h3>
              <p className="em__para text__gray mb-0">
                Contact the broker’s team to request a new one
              </p>
            </div>
            {Array.isArray(brokers) && brokers.length > 0 && (
              <div className="team--canvas">
                <div className="team--head">
                  <span className="teamHead__img">
                    <img
                      src={
                        company?.settings?.darkIcon
                          ? compileAvatarUrl(company.settings.darkIcon.url)
                          : '/images/HomeTour_Dark_Logo.svg'
                      }
                      alt="Icon"
                    />
                  </span>
                  <h3 className="heading__medium heading__medium-black">Broker Team</h3>
                </div>

                <div className="team--main">
                  <div className="row row--space-10">
                    {brokers?.map((broker: User, index: number) => (
                      <div className="col-12 col-md-4" key={index}>
                        <div className="team--block">
                          <div className="teamBlock--img">
                            <img
                              src={
                                broker?.avatar ? compileAvatarUrl(broker.avatar) : DEFAULT_AVATAR
                              }
                              alt="Img"
                              className="img--cover"
                            />
                          </div>
                          <h6 className="teamBlock__user">
                            {broker?.firstName} {broker?.lastName}
                          </h6>
                          {broker?.email && (
                            <a
                              href={`mailto:${
                                broker.email
                              }?subject=Live%20Tour%20Contact:%20${encodeURIComponent(title)}`}
                              className="teamBlock__email"
                            >
                              {broker.email}
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParticipantTourExpired;
