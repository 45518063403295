import { useState } from 'react';
import { GetState, GetStateInterface } from '../../types/GetStateInterface';
import {
  addCommentAPI,
  deleteBlobAPI,
  deleteCommentAPI,
  editCommentAPI,
  postBlobAPI,
} from './review.api';
import { uploadMediaAPI } from '../media/media.api';
import { PostBlobInterface } from './review.types';
import { AudioVoiceOverInterface, CommentsInterface } from '../listing/listing.types';
import { orderBy } from 'lodash';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../index';
import { changeVideoStatus } from './review.action';
import { REVIEW_STATE } from '../../common/constants';

export const useUploadBlob = () => {
  const [res, setRes] = useState(GetState);

  const uploadBlob = async (media: any) => {
    if (media) {
      const data = new FormData();
      data.append('file', media);
      setRes(prevState => ({ ...prevState, loading: true }));
      try {
        const apiCall = await uploadMediaAPI(data);
        setRes({
          data: apiCall.data,
          loading: false,
          error: null,
          hasData: true,
        });
      } catch (error) {
        setRes({ data: null, loading: false, error, hasData: true });
      }
    }
  };

  return { res, uploadBlob };
};
export const usePostBlob = () => {
  const [res, setRes] = useState<GetStateInterface<Array<AudioVoiceOverInterface>>>(GetState);

  const postBlob = async (data: PostBlobInterface) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    try {
      const apiCall = await postBlobAPI(data);
      setRes({
        data: apiCall.data,
        loading: false,
        error: null,
        hasData: true,
      });
    } catch (error) {
      setRes({ data: null, loading: false, error, hasData: true });
    }
  };

  return { res, postBlob };
};
export const useDeleteBlob = () => {
  const [res, setRes] = useState(GetState);
  const deleteBlob = async (listingId: string, blobId: string) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    try {
      const apiCall = await deleteBlobAPI(listingId, blobId);
      setRes({
        data: apiCall.data ? blobId : '',
        loading: false,
        error: null,
        hasData: true,
      });
    } catch (error) {
      setRes({ data: null, loading: false, error, hasData: true });
    }
  };
  return { res, deleteBlob };
};
export const sortComments = (comments: any) => {
  return Array.isArray(comments) && comments?.length > 0
    ? orderBy(comments, ['createdAt'], ['desc'])
    : [];
};
export const useAddComment = (
  setComments: (
    value: CommentsInterface[] | ((prevState: CommentsInterface[]) => CommentsInterface[]),
  ) => void,
) => {
  const [loading, setLoading] = useState(false);

  const addComment = async (comment: CommentsInterface, listingId: string) => {
    try {
      setLoading(true);
      const res = await addCommentAPI(comment, listingId);
      setComments(sortComments(res.data));
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { loading, addComment };
};
export const useEditComment = (
  setComments: (
    value: CommentsInterface[] | ((prevState: CommentsInterface[]) => CommentsInterface[]),
  ) => void,
) => {
  const [loading, setLoading] = useState(false);

  const editComment = async (listingId: string, commentId: string, comment: CommentsInterface) => {
    try {
      setLoading(true);
      const res = await editCommentAPI(listingId, commentId, comment);
      setComments(sortComments(res.data));
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { loading, editComment };
};
export const useDeleteComment = (
  setComments: (
    value: CommentsInterface[] | ((prevState: CommentsInterface[]) => CommentsInterface[]),
  ) => void,
) => {
  const [loading, setLoading] = useState(false);

  const deleteComment = async (listingId: string, commentId: string) => {
    try {
      setLoading(true);
      const res = await deleteCommentAPI(listingId, commentId);
      setComments((prevState: CommentsInterface[]) =>
        prevState.filter((comment: CommentsInterface) => comment._id !== commentId),
      );
    } catch (error) {
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return { loading, deleteComment };
};

export const usePauseRecording = () => {
  const isRecording = useSelector((state: AppStateInterface) => state.review.isRecording);

  const pauseRecording = () => {
    if (isRecording) {
      changeVideoStatus(REVIEW_STATE.PAUSE);
    }
  };

  return { pauseRecording };
};
