import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import { startCase } from 'lodash';
import SpecCard from './specCard';
import { mergeBuildingHours } from '../../listing.component';

const BuildingSpecification: React.FC = () => {
  const listing = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing.data,
  );

  return (
    <div className="sdl--combine specs--combine">
      <div className="row row--space-10 sdl--row align-items-center">
        <div className="col-12 col-md-5 d-flex flex-column">
          <div className="sdl--l text-center text-md-left mb-20">
            <h2 className="dashboard__heading dashboard__heading-alt font-semibold mb-4 mb-md-0">
              Specifications
            </h2>
          </div>
        </div>
        <div className="col-12 col-md-7 d-flex flex-column">
          <div className="row row--space-10 justify-content-center justify-content-md-start">
            <div className="row row--space-5 justify-content-center justify-content-lg-start">
              <SpecCard
                iconClass="icon-location"
                heading="Location"
                details={listing?.building.location ? [listing?.building.location] : ['N/A']}
              />
              <SpecCard
                iconClass="icon-building-alt"
                heading="Building Size"
                details={
                  listing?.building?.buildingSize && listing?.building?.buildingSize > 0
                    ? [listing?.building?.buildingSize.toLocaleString() + ' SF']
                    : ['N/A']
                }
              />
              <SpecCard
                iconClass="icon-layers"
                heading="Number of Floors"
                details={
                  listing?.building.floorsCount && listing?.building.floorsCount > 0
                    ? [listing?.building.floorsCount.toLocaleString()]
                    : ['N/A']
                }
              />
              <SpecCard
                iconClass="icon-average-floor"
                heading="Average Floor"
                details={
                  listing?.building.averageFloor && listing?.building.averageFloor > 0
                    ? [listing?.building.averageFloor.toLocaleString() + ' SF']
                    : ['N/A']
                }
              />
              <SpecCard
                iconClass="icon-telecom"
                heading="Telecom/Data Providers"
                details={
                  listing?.building.telecomProviders &&
                  listing?.building.telecomProviders.length > 0
                    ? [listing?.building.telecomProviders]
                    : ['N/A']
                }
              />
              <SpecCard
                iconClass="icon-clock-alt"
                heading="Building Hours"
                details={
                  listing?.building.buildingHours
                    ? (mergeBuildingHours(listing?.building.buildingHours) as string[])
                    : ['N/A']
                }
              />
              <SpecCard
                iconClass="icon-passenger-elevator"
                heading="Passenger Elevators"
                details={
                  listing?.building.passengerElevators &&
                  listing?.building.passengerElevators.length > 0
                    ? [listing?.building.passengerElevators]
                    : ['N/A']
                }
              />
              <SpecCard
                iconClass="icon-freight-elevator"
                heading="Freight Elevators"
                details={
                  listing?.building.freightElevators &&
                  listing?.building.freightElevators.length > 0
                    ? [listing?.building.freightElevators]
                    : ['N/A']
                }
              />
              <SpecCard
                iconClass="icon-spacing-vertical"
                heading="Slab to Slab Height"
                details={
                  listing?.building.slabToSlabHeight &&
                  listing?.building.slabToSlabHeight.length > 0
                    ? [listing?.building.slabToSlabHeight]
                    : ['N/A']
                }
              />
              <SpecCard
                iconClass="icon-spacing-horizontal"
                heading="Column Spacing"
                details={
                  listing?.building.columnSpacingHeight &&
                  listing?.building.columnSpacingWidth &&
                  listing?.building.columnSpacingHeight > 0 &&
                  listing?.building.columnSpacingWidth > 0
                    ? [
                        `${listing?.building.columnSpacingWidth.toLocaleString()}' X ${listing?.building.columnSpacingHeight.toLocaleString()}'`,
                      ]
                    : ['N/A']
                }
              />
              <SpecCard
                iconClass="icon-year-built"
                heading="Year Built"
                details={
                  listing?.building.yearBuilt && listing?.building.yearBuilt > 0
                    ? [String(listing?.building.yearBuilt)]
                    : ['N/A']
                }
              />
              <SpecCard
                iconClass="icon-renovate"
                heading="Year Renovated"
                details={
                  listing?.building.yearRenovated && listing?.building.yearRenovated > 0
                    ? [String(listing?.building.yearRenovated)]
                    : ['N/A']
                }
              />
              <SpecCard
                iconClass="icon-parking-ratio"
                heading="Parking Ratio"
                details={
                  listing?.building.parkingRatio &&
                  listing?.building.parkingRatio.length > 0 &&
                  listing?.building.parkingRatio.split(':').length === 2
                    ? [
                        `
                      ${listing?.building.parkingRatio
                        .split(':')[0]
                        .toLocaleString()} : ${listing?.building.parkingRatio
                          .split(':')[1]
                          .toLocaleString()}
                        SF
                      `,
                      ]
                    : ['N/A']
                }
              />
              {listing?.building.energyStarCertification === 'Yes' && (
                <div className="col col-12 col-sm-6 col-xl-4">
                  <div className="specs--box specs--box-center">
                    <div className="specsBox__head">
                      <span className="specsBox__head-img">
                        <img
                          src="/images/star-certification.png"
                          alt="Img"
                          className="img--cover"
                        />
                      </span>
                      <span className="specsBox__head-text">Energy Star Certification</span>
                    </div>
                  </div>
                </div>
              )}
              {listing?.building.leedCertification &&
                listing?.building.leedCertification.length > 0 && (
                  <div className="col col-12 col-sm-6 col-xl-4">
                    <div className="specs--box specs--box-center">
                      <div className="specsBox__head">
                        <span className="specsBox__head-img">
                          <img
                            src={`/images/Leed-${startCase(
                              listing.building.leedCertification,
                            )}.png`}
                            alt="Img"
                            className="img--cover"
                          />
                        </span>
                        <span className="specsBox__head-text">
                          Leed {startCase(listing.building.leedCertification)} Certification
                        </span>
                      </div>
                    </div>
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingSpecification;
