import * as yup from 'yup';

export enum CompanyInfoFormFields {
  NAME = 'name',
  OFFICIAL_WEBSITE = 'officialWebsite',
  OFFICIAL_PHONE = 'officialPhone',
  OFFICIAL_ADDRESS = 'officialAddress',
  DOMAIN = 'domain',
  CONTACT_EMAIL = 'contactEmail',
}

export const CompanyInfoFormSchema = yup.object().shape({
  [CompanyInfoFormFields.NAME]: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
    .required('This field is required'),
  [CompanyInfoFormFields.DOMAIN]: yup
    .string()
    .trim()
    .lowercase()
    .test(
      CompanyInfoFormFields.DOMAIN,
      'Minimum length must be 3 characters',
      value => value.length > 2,
    )
    .matches(/^[a-z]*$/, 'Only alphabets are allowed')
    .required('This field is required'),
  [CompanyInfoFormFields.OFFICIAL_PHONE]: yup
    .string()
    .trim()
    .matches(/^\([0-9]{3}\)\s[0-9]{3}[-][0-9]{4}$/i, 'Allowed format: (234) 456-7890')
    .required('This field is required'),
  [CompanyInfoFormFields.OFFICIAL_ADDRESS]: yup
    .string()
    .trim()
    .required('This field is required'),
  [CompanyInfoFormFields.OFFICIAL_WEBSITE]: yup
    .string()
    .trim()
    .url('Please enter valid url')
    .required('This field is required'),
  [CompanyInfoFormFields.CONTACT_EMAIL]: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('This field is required'),
});
