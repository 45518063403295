import React from 'react';
import { TourListingsSelected } from '../../../../../state/subdomain/tour/tour.types';

interface Props {
  data: TourListingsSelected[];
  onRemove: (e: string) => void;
}
const SelectedListings: React.FC<Props> = props => {
  const { data, onRemove: remove } = props;

  return (
    <div className="badge--row d-flex align-items-center flex-wrap pt-3">
      {data &&
        data.map(entry => (
          <div className="badge--col" key={entry.id}>
            <div className="tour__badge">
              {entry.name}
              <span className="tour__badge-cross" onClick={() => remove(entry.id)}>
                <i className="icon-cross-alt"></i>
              </span>
            </div>
          </div>
        ))}
    </div>
  );
};

export default SelectedListings;
