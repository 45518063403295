import React, { useState, useEffect } from 'react';
import AddBrokerPanel from './addBrokerPanel';
import { showNotification } from '../../../../utils/misc.utils';
import { errorExtractor } from '../../../../utils/error.utils';
import { TourListingsSelected } from '../../../../state/subdomain/tour/tour.types';
import Loader from '../../../common/loader';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { getBrokers } from '../../../../state/users/users.action';
import { UsersInterface } from '../../../../state/users/users.types';

interface Props {
  close: () => void;
}
const AddBroker: React.FC<Props> = props => {
  const { close } = props;
  const tour = useSelector((state: AppStateInterface) => state.tour.tourInfo.data);
  const brokers = useSelector((state: AppStateInterface) => state.users.brokers);
  const me = useSelector((state: AppStateInterface) => state.me.me.data);

  const [selected, setSelected] = useState([] as TourListingsSelected[]);
  const [list, setList] = useState([] as UsersInterface[]);

  const onSelect = (e: UsersInterface) => {
    setSelected([...selected, { name: `${e.firstName} ${e.lastName}`, id: e._id }]);
  };
  const onRemove = (e: string) => {
    setSelected(prev => prev.filter(broker => broker.id !== e));
  };

  useEffect(() => {
    if (me?.company?._id) getBrokers(me.company._id);
  }, [me]);
  useEffect(() => {
    const allUsers = tour?.additionalUsers;
    if (allUsers) {
      const ids = allUsers.map(user => ({
        id: user._id,
        name: `${user.firstName} ${user.lastName}`,
      }));
      setSelected(ids);
    }
  }, [tour]);
  useEffect(() => {
    if (!brokers.loading && brokers.hasData) {
      if (brokers.error) showNotification('error', 'Fetching Error', errorExtractor(brokers.error));
      else if (brokers.data) setList(brokers.data);
    }
  }, [brokers]);

  return (
    <div className="component--edit-overlay">
      <div className="component--edit">
        <div className="componentEdit--head">
          <div className="d-flex justify-content-between align-items-center mb-0">
            <h3 className="heading__medium heading__medium-black font-semibold mb-0">
              Add Brokers
            </h3>
            <span className="ls__cross ml-3" onClick={close}>
              <i className="icon-cross"></i>
            </span>
          </div>
        </div>
        <AddBrokerPanel
          close={close}
          data={list}
          selected={selected}
          onSelect={onSelect}
          onRemove={onRemove}
        />
      </div>
    </div>
  );
};
export default AddBroker;
