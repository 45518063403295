import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { UploadedMediaInterface } from '../../../../state/media/media.types';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import { TourBuildingInterface } from '../../../../state/tour/tour.types';

const HandleTourHome: React.FC = () => {
  const listing = useSelector((state: AppStateInterface) => state.tour.tourListing);
  const me = useSelector((state: AppStateInterface) => state.me.me.data);

  const [building, setBuilding] = useState({} as TourBuildingInterface);
  const [icon, setIcon] = useState({} as UploadedMediaInterface);
  const [primaryImage, setPrimary] = useState({} as UploadedMediaInterface);
  useEffect(() => {
    if (listing) {
      const listingData = listing.listing;
      let primary = listingData.media.find(image => image.isPrimary);
      if (!primary) primary = listingData.media[0];
      setPrimary(primary);
      if (listingData.building) setBuilding(listingData.building);
    }
  }, [listing]);
  useEffect(() => {
    if (me?.company?.settings.darkLogo) setIcon(me.company.settings.darkLogo);
  }, [me]);

  const resizeLocationFontSize = () => {
    const containerEl = document.getElementById('container');
    const blockEl = document.getElementById('placement-block');
    const locationEl = document.getElementById('location');
    if (containerEl && blockEl && locationEl) {
      const textFontSize = parseFloat(
        window.getComputedStyle(locationEl).getPropertyValue('font-size'),
      );
      const textLineHeight = parseFloat(
        window.getComputedStyle(locationEl).getPropertyValue('line-height'),
      );
      if (
        blockEl.scrollHeight + 40 > containerEl.scrollHeight ||
        locationEl.clientWidth < locationEl.scrollWidth
      ) {
        locationEl.style.fontSize = `${textFontSize - 1}px`;
        locationEl.style.lineHeight = `${textLineHeight - 1.2}px`;
        resizeLocationFontSize();
      }
    }
  };

  useLayoutEffect(() => {
    resizeLocationFontSize();
  }, [listing]);

  return (
    <div className="tourEdit--top tourEdit--top-home">
      <img
        src={compileAvatarUrl(primaryImage.url)}
        alt={primaryImage.name}
        className="img--cover"
      />
      <div className="liveTour--placement placement--centered" id="container">
        <div className="container liveTour--container">
          <div className="placement--block placement--block-alt" id="placement-block">
            <a className="listing__logo mb-sm-3">
              <img src={compileAvatarUrl(icon.url)} alt="Img" />
            </a>
            <h1 className="placement__heading" id="location">
              {building?.location?.split(',')[0]} {listing?.listing.suiteNo}
            </h1>
            <div className="placement--location">
              <span className="placementLocation__icon">
                <i className="icon-location"></i>
              </span>
              <span className="placementLocation__text">
                {building?.location
                  ?.split(',')
                  .slice(1, 3)
                  .join(', ')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HandleTourHome;
