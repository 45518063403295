import React, { useState, useEffect } from 'react';
import { Dropdown, Form, Button, Modal, Accordion, Card } from 'react-bootstrap';
import { DatePicker } from 'antd';
import 'antd/lib/date-picker/style/index.css';
import moment from 'moment';
import { suiteOptions, capacityOptions, conditionOptions, errorMsg } from '../listing.component';
import { ListingInterface, ListingErrorInterface } from '../../../state/listing/listing.types';
import { ListingForm as list } from '../../../state/listing/listing.form';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { getBrokers } from '../../../state/users/users.action';
import Loader from '../../common/loader';

interface Props {
  data: ListingInterface;
  dataError: ListingErrorInterface;
  setOutput: (...args: any[]) => void;
  showLabels?: boolean;
}
const ListingBrokers: React.FC<Props> = props => {
  const me = useSelector((state: AppStateInterface) => state.me.me);
  const brokers = useSelector((state: AppStateInterface) => state.users.brokers);
  const [users, setUsers] = useState([] as string[]);
  const [options, setOptions] = useState([] as any);
  const [value, setValue] = useState([] as any);

  useEffect(() => {
    if (props.data.users) setUsers(props.data.users);
    else setUsers([]);
  }, [props.data]);
  useEffect(() => {
    if (me.data && !me.loading && me.data.company) getBrokers(me.data.company._id);
  }, [me]);
  useEffect(() => {
    const data = brokers.data;
    if (data) {
      const options = [];
      for (const i in data) options.push({ label: data[i].firstName, value: data[i]._id });
      setOptions(options);
      if (users.length) {
        const val = [];
        for (const i in users) {
          const user = data.find(e => e._id === users[i]);
          if (user) val.push({ label: user.firstName, value: user._id });
        }
        setValue(val);
      } else setValue([]);
    }
  }, [brokers, users]);

  const handleChange = (e: any) => {
    setValue(e);
    let users = [];
    if (e) users = e.map((val: any) => val.value);
    props.setOutput(list.users, users);
  };

  return (
    <div className="row row--space-12">
      {brokers.loading && <Loader />}
      <div className="col-12 col-sm-6">
        {props.showLabels && <Form.Label>Select Broker(s)</Form.Label>}
        <Form.Group className="forms--group select--group mb-28">
          <Select onChange={handleChange} options={options} value={value} isMulti />
          {/* {errorMsg(errors[list.spaceUse])} */}
        </Form.Group>
      </div>
    </div>
  );
};

export default ListingBrokers;
