import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import { Form, Button } from 'react-bootstrap';
import useForm from 'react-hook-form';
import {
  TourFormSchema,
  TourForm as TF,
} from '../../../../../state/subdomain/listing/listing.form';
import FormInput from './input';
import { useParams } from 'react-router';
import { TourFormInterface } from '../../../../../state/subdomain/listing/listing.types';
import { useTourRequest } from '../../../../../state/subdomain/listing/listing.hook';
import { showNotification } from '../../../../../utils/misc.utils';
import { errorExtractor } from '../../../../../utils/error.utils';
import copy from 'copy-to-clipboard';
import SmallLoader from '../../../../common/small.loader';

interface Props {
  complete: () => void;
  hide: () => void;
}
const RequestForm: React.FC<Props> = props => {
  const { complete: handleComplete, hide } = props;
  const { listingId } = useParams();
  const { res: requestRes, postTourRequest } = useTourRequest();
  const { register, handleSubmit, errors, formState } = useForm({
    validationSchema: TourFormSchema,
    mode: 'onChange',
  });

  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );
  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    if (listingId) {
      const finalData = { listingId, ...data } as TourFormInterface;
      postTourRequest(finalData);
    }
  };

  useEffect(() => {
    if (!requestRes.loading && requestRes.hasData) {
      if (requestRes.error)
        showNotification('error', 'Tour Request Error', errorExtractor(requestRes.error));
      else if (requestRes.data) {
        handleComplete();
      }
    }
  }, [requestRes]);

  const disableSubmit = !formState.isValid;
  return (
    <div className="listingModal--tour">
      <h3 className="heading__medium heading__medium-black mb-4 pb-3 font-semibold">
        Request a {company?.name.toUpperCase()} Guided Virtual Tour
      </h3>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-100">
          <div className="row row--space-10">
            <FormInput reg={register} name={TF.firstName} err={errors[TF.firstName]} />
            <FormInput reg={register} name={TF.lastName} err={errors[TF.lastName]} />
            <FormInput reg={register} name={TF.email} err={errors[TF.email]} />
            <FormInput reg={register} name={TF.phone} err={errors[TF.phone]} phone />
            <FormInput reg={register} name={TF.companyName} err={errors[TF.companyName]} />
            <FormInput reg={register} name={TF.brokerCompany} err={errors[TF.brokerCompany]} />
            <FormInput reg={register} name={TF.time} options err={errors[TF.time]} />
            <FormInput reg={register} name={TF.questions} full />
          </div>
          <div className="row row--space-10 flex-sm-row-reverse btn--canvas-sl">
            <div className="col col-12 col-sm-6">
              <Button
                type="submit"
                className={`admin__button button__radius w-100 mb-3 mb-sm-0 ${
                  disableSubmit ? 'disabled' : ''
                }`}
                disabled={disableSubmit || requestRes.loading}
              >
                {!requestRes.loading && 'Submit Request'}
                {requestRes.loading && (
                  <React.Fragment>
                    Submitting <SmallLoader />
                  </React.Fragment>
                )}
              </Button>
            </div>
            <div className="col col-12 col-sm-6">
              <Button onClick={hide} className="outline__button button__radius w-100">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
export default RequestForm;
