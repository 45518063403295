import { useCallback, useEffect, useState } from 'react';
import {
  GetRecentListingsQueryInterface,
  GetRecentListingsResponseInterface,
  GetTourUrlResponseInterface,
  ListingActions as actionName,
  ListingInterface,
  RecentListing,
} from './listing.types';
import {
  changeStatusAPI,
  changeVideoStatusAPI,
  createListingAPI,
  editListingAPI,
  getSingleListingAPI,
  getTourUrlAPI,
  deleteListingAPI,
  getAllRecentListingsAPI,
  exportListingsAPI,
} from './listing.api';
import fetchActions from '../../utils/state.utils';
import { GetState, GetStateInterface } from '../../types/GetStateInterface';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '..';
import {
  changeListingStatus,
  changeVideoListingStatus,
  clearAllListings,
  clearSingleListing,
  getAllListings,
  getSingleListing,
} from './listing.action';
import axios from 'axios';
import { DEFAULT_LISTING_LIMIT, LISTING_STATUS, VIDEO_STATUS } from '../../common/constants';

export const useCreateListing = () => {
  const [res, setRes] = useState(GetState);
  const createListing = useCallback(async (payload: ListingInterface) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.POST_LISTING);
    try {
      const res = await createListingAPI(payload, fetchActions.setCT(actionName.POST_LISTING));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      setRes({ data: null, loading: false, error: error, hasData: true });
    }
  }, []);
  const editListing = useCallback(async (payload: ListingInterface, listingId: string) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.POST_LISTING);
    try {
      const res = await editListingAPI(
        payload,
        listingId,
        fetchActions.setCT(actionName.POST_LISTING),
      );
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      setRes({ data: null, loading: false, error: error, hasData: true });
    }
  }, []);
  return { res, createListing, editListing };
};

export const useGetSingleListing = () => {
  const [res, setRes] = useState(GetState);
  const getSingleListing = useCallback(async (id: string) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.GET_SINGLE_LISTING);
    try {
      const res = await getSingleListingAPI(id, fetchActions.setCT(actionName.GET_SINGLE_LISTING));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      setRes({ data: null, loading: false, error: error, hasData: true });
    }
  }, []);
  return { res, getSingleListing };
};

export const useChangeListingStatus = () => {
  const [res, setRes] = useState(GetState);
  const changeStatus = useCallback(async (id: string, status: LISTING_STATUS) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.LISTING_STATUS);
    try {
      const res = await changeStatusAPI(id, status, fetchActions.setCT(actionName.LISTING_STATUS));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
      changeListingStatus(status);
    } catch (error) {
      setRes({ data: null, loading: false, error: error, hasData: true });
    }
  }, []);
  return { res, changeStatus };
};

export const useChangeVideoStatus = () => {
  const [res, setRes] = useState(GetState);
  const changeVideoStatus = useCallback(async (id: string, status: VIDEO_STATUS) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.VIDEO_STATUS);
    try {
      const res = await changeVideoStatusAPI(
        id,
        status,
        fetchActions.setCT(actionName.VIDEO_STATUS),
      );
      setRes({ data: res.data, loading: false, error: null, hasData: true });
      changeVideoListingStatus(status);
      if (status === VIDEO_STATUS.ACCEPTED) {
        changeListingStatus(LISTING_STATUS.PUBLISHED);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);
  return { res, changeVideoStatus };
};

export const useGetAllListings = (buildingId: string) => {
  const { loading, hasData, data, error } = useSelector(
    (state: AppStateInterface) => state.listing.allListings,
  );

  useEffect(() => {
    getAllListings(buildingId, DEFAULT_LISTING_LIMIT, 0);
  }, [buildingId]);

  const fetchMore = () => {
    if (data?.hasMore && !loading) {
      getAllListings(buildingId, DEFAULT_LISTING_LIMIT, data?.skip || 0, data?.data);
    }
  };

  return { loading, hasData, data, error, fetchMore };
};

export const useClearAllListings = () => {
  return { clearAllListings };
};

export const useGetSingleListingAction = () => {
  return { getSingleListing };
};

export const useClearSingleListing = () => {
  return { clearSingleListing };
};

export const useGetTourUrl = () => {
  const [res, setRes] = useState<GetStateInterface<GetTourUrlResponseInterface>>(GetState);
  const getTourUrl = useCallback(async (listingId: string) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.GET_TOUR_URL);
    try {
      const res = await getTourUrlAPI(listingId, fetchActions.setCT(actionName.GET_TOUR_URL));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);

  return { res, getTourUrl };
};

export const useDeleteListing = () => {
  const [res, setRes] = useState(GetState);
  const deleteListing = useCallback(async (listingId: string) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.DELETE_LISTING);
    try {
      const res = await deleteListingAPI(listingId, fetchActions.setCT(actionName.DELETE_LISTING));
      setRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);
  return { res, deleteListing };
};

export const useGetRecentListings = () => {
  const [res, setRes] = useState<GetStateInterface<GetRecentListingsResponseInterface>>(GetState);

  const getRecentListings = useCallback(
    async (query: GetRecentListingsQueryInterface, prev: Array<RecentListing> = []) => {
      setRes(prevState => ({ ...prevState, loading: true }));
      fetchActions.callCT(actionName.GET_ALL_RECENT_LISTINGS);
      try {
        const response = await getAllRecentListingsAPI(
          query,
          fetchActions.setCT(actionName.GET_ALL_RECENT_LISTINGS),
        );
        setRes({
          data:
            prev?.length > 0
              ? { ...response.data, data: [...prev, ...(response?.data?.data || [])] }
              : response.data,
          hasData: true,
          loading: false,
          error: null,
        });
      } catch (err) {
        if (!axios.isCancel(err)) {
          setRes({ data: null, error: err, hasData: true, loading: false });
        }
      }
    },
    [],
  );

  return { res, getRecentListings };
};

export const useExportListings = () => {
  const [res, setRes] = useState(GetState);

  const exportListings = useCallback(async () => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.EXPORT_LISTINGS);
    try {
      const response = await exportListingsAPI(fetchActions.setCT(actionName.EXPORT_LISTINGS));
      setRes({ loading: false, error: null, hasData: true, data: response.data });
    } catch (error) {
      setRes({ loading: false, error: error, data: null, hasData: true });
    }
  }, []);

  return { res, exportListings };
};
