import React, { useState, useEffect } from 'react';
import SubDomainAllLists from '../../../components/subdomain/listing/allListings';
import SubDomainFooter from '../../../components/subdomain/footer';
import SubDomainFooterShimmer from '../../../components/subdomain/footer/shimmer';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import SubDomainRightSidebar from '../../../components/subdomain/rightSidebar';
import { CompanyInfoInterface } from '../../../state/subdomain/company/company.types';

function SubDomainAllListings() {
  const [company, setCompany] = useState({} as CompanyInfoInterface);
  const companyInfo = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo,
  );
  useEffect(() => {
    if (!companyInfo.loading && companyInfo.hasData && companyInfo.data)
      setCompany(companyInfo.data);
  }, [companyInfo]);

  return (
    <React.Fragment>
      <div className="available--listing pb-0 availabelListing--sticky">
        <div className="container container--large off--canvas-content">
          {!companyInfo.loading ? (
            <div className="d-flex align-items-center justify-content-between">
              <a
                className="available--listing-logo"
                href={company?.settings?.officialWebsite || '#'}
                target={company?.settings?.officialWebsite ? '_blank' : '_self'}
                rel="noopener noreferrer"
              >
                <img
                  src={
                    company?.settings?.darkLogo
                      ? compileAvatarUrl(company.settings.darkLogo.url)
                      : '/images/HomeTour_Dark_Logo.svg'
                  }
                  alt="Logo"
                />
              </a>
              <SubDomainRightSidebar />
            </div>
          ) : (
            <div className="shimmer--header d-flex align-items-center justify-content-between">
              <span className="header__logo-shimmer shimmer__style animate"></span>
              <span className="header__bars-shimmer shimmer__style animate"></span>
            </div>
          )}
          <div className="available--listing-content">
            <h1 className="al__heading dashboard__heading">Office Listings Available</h1>
            <SubDomainAllLists />
          </div>
        </div>
      </div>
      {!companyInfo.loading ? <SubDomainFooter /> : <SubDomainFooterShimmer />}
    </React.Fragment>
  );
}

export default SubDomainAllListings;
