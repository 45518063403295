import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ListingForm as list } from '../../../../state/listing/listing.form';
import { ListingInterface, ListingErrorInterface } from '../../../../state/listing/listing.types';
import UploadPhotos from './uploads/media';
import { UploadedMediaInterface } from '../../../../state/media/media.types';
import SingleMediaTile from '../../../media/singleTile';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import { errorMsg } from '../../listing.component';

interface Props {
  data: ListingInterface;
  dataError: ListingErrorInterface;
  setOutput: (key: string, value: any) => void;
}
const Renderings: React.FC<Props> = props => {
  const [modalPhotoShow, setModalPhotoShow] = useState(false);
  const { data, dataError, setOutput } = props;

  const handleImages = (images: UploadedMediaInterface[]) => {
    let alreadyImages = [] as UploadedMediaInterface[];
    if (data.renderings) alreadyImages = data.renderings;
    setOutput(list.renderings, [...alreadyImages, ...images]);
    setModalPhotoShow(false);
  };

  const removeImage = (index: number) => {
    if (data.renderings && data.renderings.length) {
      const media = data.renderings;
      media.splice(index, 1);
      setOutput(list.renderings, media);
    }
  };

  return (
    <React.Fragment>
      <div className="file--uploaded ">
        {data.renderings &&
          data.renderings.map((file, index) => (
            <SingleMediaTile
              key={index}
              url={compileAvatarUrl(file.url)}
              remove={() => removeImage(index)}
            />
          ))}
      </div>
      <div className="mb-3 forms--group errorMsg--set">
        <Button onClick={() => setModalPhotoShow(true)} className="upload__button mb-4 mt-2">
          Upload {data.renderings?.length > 0 && 'More '}Renderings
          <span className="upload__button-icon">
            <i className="icon-upload"></i>
          </span>
        </Button>
        {errorMsg({}, dataError[list.renderings], 'mb-3')}
        {modalPhotoShow && (
          <UploadPhotos
            handleClose={() => setModalPhotoShow(false)}
            handleChanges={handleImages}
            alreadyFiles={data.renderings ? data.renderings.length : 0}
            title="rendering"
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Renderings;
