import { useCallback, useState } from 'react';
import {
  SignInRequestInterface,
  ForgetStateInterface,
  ResetStateInterface,
  LoginActions as actionName,
  ChangePasswordInterface,
} from './login.types';
import {
  loginAPI,
  logoutAPI,
  forgetPasswordAPI,
  resetPasswordAPI,
  changePasswordAPI,
} from './login.api';
import fetchActions from '../../utils/state.utils';
import { GetState } from '../../types/GetStateInterface';
import axios from 'axios';

export const useLogin = () => {
  const [res, setRes] = useState(GetState);

  const login = useCallback(async (payload: SignInRequestInterface) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.LOGIN);
    try {
      const res = await loginAPI(payload, fetchActions.setCT(actionName.LOGIN));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);

  return { res, login };
};

export const useLogout = () => {
  const [res, setRes] = useState(GetState);

  const logOut = useCallback(async () => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.LOGOUT);
    try {
      const res = await logoutAPI(fetchActions.setCT(actionName.LOGOUT));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
    fetchActions.dispatch({
      type: actionName.LOGOUT,
    });
  }, []);

  return { res, logOut };
};

export const useForgotAndResetPassword = () => {
  const [res, setRes] = useState(GetState);

  const forgotPassword = useCallback(async (payload: ForgetStateInterface) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.FORGOT_PASSWORD);
    try {
      const res = await forgetPasswordAPI(payload, fetchActions.setCT(actionName.FORGOT_PASSWORD));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);

  const resetPassword = useCallback(async (payload: ResetStateInterface) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.RESET_PASSWORD);
    try {
      const res = await resetPasswordAPI(payload, fetchActions.setCT(actionName.RESET_PASSWORD));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);

  return { res, forgotPassword, resetPassword };
};

export const useChangePassword = () => {
  const [res, setRes] = useState(GetState);

  const changePassword = useCallback(async (payload: ChangePasswordInterface) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.CHANGE_PASSWORD);
    try {
      const res = await changePasswordAPI(payload, fetchActions.setCT(actionName.CHANGE_PASSWORD));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);

  return { res, changePassword };
};
