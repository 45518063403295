import React, { useState, useEffect, useRef } from 'react';
import LeftSidebar from './../../../components/leftSidebar';
import HamBurger from './../../../components/leftSidebar/hamburger';
import CreateList from './../../../components/listing/form';
import { useHistory } from 'react-router';

const ListingCreatePage: React.FC = () => {
  const history = useHistory();
  const pageStart = useRef<HTMLDivElement>(null);
  const [getParent, setParent] = useState(false);

  useEffect(() => {
    if (!getParent && pageStart.current) setParent(true);
  }, [pageStart]);

  return (
    <div className="dashboard--page">
      <LeftSidebar active="buildings" />
      <div className="dashboard--content" ref={pageStart}>
        <div className="dashboard--container">
          <div className="dashboard--canvas">
            <div className="step--content step--content-3">
              <div className="dashboard--opt">
                <a className="back__btn" onClick={() => history.goBack()}>
                  <span className="back__btn-icon">
                    <i className="icon-chevron-left"></i>
                  </span>
                  Back
                </a>
                <HamBurger className="menu__toggle" />
              </div>
              {parent && <CreateList parent={pageStart.current} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingCreatePage;
