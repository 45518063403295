import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactAudioPlayer from 'react-audio-player';
import { AppStateInterface } from '../../../state';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import { saveAudios } from '../../../state/review/review.action';
import DeleteVoiceOver from '../../modals/voiceOverWarning';
import SmallLoader from '../../common/small.loader';
import { AUDIO_TIME_INVERVAL } from '../../../common/constants';
import { convertSecToString } from '../listing.component';
import { usePauseRecording } from '../../../state/review/review.hook';
import { AudioVoiceOverInterface } from '../../../state/listing/listing.types';

enum AudioStates {
  PLAY = 'play',
  LOADING = 'loading',
  PAUSE = 'pause',
}
interface Props {
  canEdit: boolean;
}
const ReviewAllAudios: React.FC<Props> = props => {
  const { canEdit } = props;
  const audios = useSelector((state: AppStateInterface) => state.review.audios);

  const [currentAudio, setCurrentAudio] = useState('');
  const [delId, setDelId] = useState('');
  const [audioSpan, setAudioSpan] = useState(0);
  const [allAudios, setAllAudios] = useState([] as AudioVoiceOverInterface[]);
  const [audioState, setAudioState] = useState(AudioStates.PAUSE);
  const { pauseRecording } = usePauseRecording();

  const handleSuccess = () => {
    const audios = [...allAudios.filter(audio => audio._id !== delId)];
    saveAudios(audios);
    setDelId('');
  };
  const deleteAudio = (url: string, id: string) => {
    setDelId(id);
    if (url === currentAudio) setCurrentAudio('');
  };
  const playAudio = (e: string) => {
    if (e === currentAudio) setCurrentAudio('');
    else {
      setCurrentAudio(e);
      setAudioState(AudioStates.LOADING);
    }
    setAudioSpan(0);
  };

  useEffect(() => {
    if (audios?.length) {
      const audioParts = [...audios.filter(audio => !audio?.isPrevious)];
      setAllAudios(audioParts);
    } else setAllAudios([]);
  }, [audios]);

  return (
    <div className="voiceRecord--block mt-4 pt-1">
      {allAudios.length > 0 &&
        allAudios.map(audio => {
          const active = currentAudio === audio.url;
          return (
            <div className="voiceRecord--box" key={audio._id}>
              <a
                className="record__voice"
                onClick={() => {
                  pauseRecording();
                  playAudio(audio.url);
                }}
              >
                {active && audioState === AudioStates.LOADING && <SmallLoader />}
                {active && audioState === AudioStates.PLAY && <i className="icon-pause"></i>}
                {(!active || (active && audioState === AudioStates.PAUSE)) && (
                  <i className="icon-play"></i>
                )}
              </a>
              <span className="record__time">
                {active ? convertSecToString(audioSpan) : audio.name}
              </span>
              {canEdit && (
                <a
                  className="record__trash"
                  onClick={() => {
                    pauseRecording();
                    deleteAudio(audio.url, audio._id || '');
                  }}
                >
                  <i className="icon-trash"></i>
                </a>
              )}
            </div>
          );
        })}
      {currentAudio.length > 0 && (
        <ReactAudioPlayer
          src={compileAvatarUrl(currentAudio)}
          autoPlay
          controls={false}
          onCanPlayThrough={() => setAudioState(AudioStates.PLAY)}
          onEnded={() => setCurrentAudio('')}
          onListen={e => setAudioSpan(Math.round(parseFloat(e.toString())))}
          listenInterval={AUDIO_TIME_INVERVAL}
        />
      )}
      {delId.length > 0 && (
        <DeleteVoiceOver
          id={delId}
          type="recording"
          name="Voice Over"
          handleSuccess={handleSuccess}
          handleCancel={() => setDelId('')}
        />
      )}
    </div>
  );
};

export default ReviewAllAudios;
