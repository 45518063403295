import { useCallback, useState } from 'react';
import { AcceptInviteInterface, InvitationActions as actionName } from './invitation.types';
import { GetState } from '../../types/GetStateInterface';
import fetchActions from '../../utils/state.utils';
import { acceptInviteAPI } from './invitation.api';

export const useAcceptInvite = () => {
  const [res, setRes] = useState(GetState);

  const acceptInvite = useCallback(async (data: AcceptInviteInterface) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.ACCEPT_INVITE);
    try {
      const res = await acceptInviteAPI(data, fetchActions.setCT(actionName.ACCEPT_INVITE));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      setRes({ data: null, loading: false, error: error, hasData: true });
    }
  }, []);

  return { acceptInvite, res };
};
