import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import Loader from '../../../common/loader';
import SingleListing from './singleListingView';
import { useClearAllListings, useGetAllListings } from '../../../../state/listing/listing.hook';
import { useGetBuildingDetails } from '../../../../state/building/building.hook';
import { debounce } from 'lodash';
import SmallLoader from '../../../common/small.loader';
import DeleteWarning from '../../../modals/deleteWarning';

const ViewList: React.FC = () => {
  const { buildingId } = useParams();
  const history = useHistory();
  const [showDelModal, handleDelModal] = useState(false);
  const { data: allListings, loading, hasData, error, fetchMore } = useGetAllListings(buildingId);
  const { res: getBuildingDetailsRes, getBuildingDetails } = useGetBuildingDetails();
  const { clearAllListings } = useClearAllListings();

  useEffect(() => {
    getBuildingDetails(buildingId);
  }, [buildingId]);

  useEffect(() => {
    return () => {
      clearAllListings();
    };
  }, []);

  useEffect(() => {
    const container = document.getElementById('ListingsParentScrollContainer');
    if (container) {
      container.onscroll = debounce(() => {
        if (error || loading || (allListings && !allListings?.hasMore)) return <></>;

        if (
          Math.abs(
            Math.floor(container.scrollHeight - container.scrollTop) - container.clientHeight,
          ) < Math.floor(container.clientHeight * 0.2)
        ) {
          fetchMore();
        }
      }, 100);
    }
  });

  return (
    <React.Fragment>
      {((loading && !allListings) || getBuildingDetailsRes.loading) && <Loader />}
      {allListings?.data && allListings?.data?.length > 0 && (
        <React.Fragment>
          <div className="dashboard--head mb-4 pb-2">
            <div className="heading--group position-relative mb-md-0">
              <h3 className="dashboard__heading">
                {getBuildingDetailsRes.data?.location?.split(',')[0]}
              </h3>
              <a
                className="heading__edit"
                onClick={() => history.push(`/building/${buildingId}/edit`)}
              >
                <i className="icon-edit-alt"></i>
              </a>
              <a className="heading__edit heading__trash" onClick={() => handleDelModal(true)}>
                <i className="icon-trash"></i>
              </a>
            </div>
            <div className="bl--head-btn">
              <button
                className="admin__button underline__button w-100"
                onClick={() => history.push(`/building/${buildingId}/listings/create`)}
              >
                Add Listing +
              </button>
            </div>
          </div>
          <p className="form__paragraph">
            Here you will find all available listings for property{' '}
            {getBuildingDetailsRes.data?.location?.split(',')[0]}.
          </p>
          <div className="buildingListing--inner">
            {allListings.data?.map(list => (
              <SingleListing listing={list} key={list._id} />
            ))}
          </div>
          {loading && allListings && (
            <div className="loading--more">
              <span>Loading More</span>
              <SmallLoader />
            </div>
          )}
        </React.Fragment>
      )}
      {allListings?.data && allListings?.data?.length === 0 && (
        <React.Fragment>
          <div className="heading--group position-relative pr-0">
            <h3 className="dashboard__heading mb-0">
              {getBuildingDetailsRes.data?.location?.split(',')[0]}
            </h3>
            <a
              className="heading__edit"
              onClick={() => history.push(`/building/${buildingId}/edit`)}
            >
              <i className="icon-edit-alt"></i>
            </a>
            <a className="heading__edit heading__trash" onClick={() => handleDelModal(true)}>
              <i className="icon-trash"></i>
            </a>
          </div>
          <div className="empty--box empty--listings">
            <div className="empty--box-content">
              <span className="emptyBox__img mb-4 mt-1">
                <img src="/images/empty-listing.svg" alt="Empty Listing" />
              </span>
              <h3 className="heading__medium heading__medium-black mb-3">
                Oh oh, there are no listings here yet!
              </h3>
              <p className="em__para text__gray mb-4 pb-3">Start adding your listings today.</p>
              <button
                className="admin__button underline__button"
                onClick={() => history.push(`/building/${buildingId}/listings/create`)}
              >
                Add Listing +
              </button>
            </div>
          </div>
        </React.Fragment>
      )}
      {showDelModal && (
        <DeleteWarning
          id={buildingId}
          name={`${getBuildingDetailsRes.data?.location?.split(',')[0]}`}
          type="building"
          handleCancel={() => handleDelModal(false)}
        />
      )}
    </React.Fragment>
  );
};

export default ViewList;
