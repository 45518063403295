import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { startCase } from 'lodash';
import { useParams } from 'react-router';
import { showNotification } from '../../utils/misc.utils';
import { errorExtractor } from '../../utils/error.utils';
import SmallLoader from '../common/small.loader';
import { useDeleteBlob } from '../../state/review/review.hook';

interface Props {
  type: string;
  name: string;
  id: string;
  handleSuccess: (id: string) => void;
  handleCancel: () => void;
}

const DeleteVoiceOver: React.FC<Props> = props => {
  const { name, type, handleCancel, id, handleSuccess } = props;
  const { listingId } = useParams();
  const { res: voiceRes, deleteBlob } = useDeleteBlob();

  useEffect(() => {
    const e = voiceRes;
    if (!e.loading && e.hasData) {
      if (e.data) handleSuccess(id);
      else if (e.error) {
        showNotification('error', 'Deletion Error', errorExtractor(e.error));
        handleCancel();
      }
    }
  }, [voiceRes]);

  const handleDelete = () => {
    deleteBlob(listingId, id);
  };

  const disableDelete = voiceRes.loading || !id.length;
  return (
    <Modal show onHide={handleCancel} className="office-modal" contained-modal-title-vcenter="true">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Delete {name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="em__para text__gray">
          Are you sure you want to delete this {type}? You will lose all information within it.
        </p>
        <div className="row row--space-12 flex-sm-row-reverse pt-sm-4 mb-3 pb-2">
          <div className="col-12 col-sm-6">
            <Button
              type="button"
              className={`admin__button underline__button w-100 mb-4 mb-sm-0`}
              onClick={handleDelete}
              disabled={disableDelete}
            >
              {voiceRes.loading ? (
                <React.Fragment>
                  Deleting <SmallLoader />
                </React.Fragment>
              ) : (
                <React.Fragment>Delete {startCase(type)}</React.Fragment>
              )}
            </Button>
          </div>
          <div className="col-12 col-sm-6">
            <Button onClick={handleCancel} className="outline__button underline__button w-100">
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteVoiceOver;
