import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player';

interface Props {
  show: boolean;
  videoId: string;
  close: () => void;
}

const VideoModal = (props: Props) => {
  return (
    <Modal
      show={props.show}
      onHide={props.close}
      animation={false}
      centered
      scrollable
      className="modal--video"
    >
      <Modal.Body>
        <Button className="mv__cross" onClick={props.close}>
          <i className="icon-cross"></i>
        </Button>
        <ReactPlayer
          url={`https://spacetrics.wistia.com/medias/${props.videoId}`}
          className="modal--video-set"
          playing
          config={{
            wistia: {
              options: {
                playerColor: '#0c30af',
              },
            },
          }}
        />
      </Modal.Body>
    </Modal>
  );
};

export default VideoModal;
