import React, { useState, useEffect } from 'react';
import TourListingSearch from './tourListingSearch';
import TourListingDetails from './tourListingDetails';
import {
  ListingFilters,
  GetTourListings,
  sortByTypes,
} from '../../../../state/subdomain/listing/listing.types';
import { useTourListing } from '../../../../state/subdomain/listing/listing.hook';
import {
  GetTourListingRequest,
  GetListingObject,
} from '../../../../state/subdomain/listing/listing.types';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { showNotification } from '../../../../utils/misc.utils';
import { errorExtractor } from '../../../../utils/error.utils';
import { debounce, cloneDeep } from 'lodash';
import Loader from '../../../common/loader';
import { ListingInfo } from '../../../../state/subdomain/tour/tour.types';

interface Props {
  close: () => void;
  showSection: string;
}

const TourAllListings: React.FC<Props> = (props: Props) => {
  const { close, showSection } = props;
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );
  const tourInfo = useSelector((state: AppStateInterface) => state.subdomain.tour.tourInfo);
  const currentListing = useSelector(
    (state: AppStateInterface) => state.subdomain.tour.tourListing,
  );

  const { res: listingRes, getTourListings } = useTourListing(company?._id || '');

  const [shouldSearch, setShouldSearch] = useState(true);
  const [isSearchedData, setSearchedData] = useState(true);
  const [listingData, setListingData] = useState([] as GetListingObject[]);
  const [data, setData] = useState({} as GetTourListings);
  const [showSearchBox, setSearchBox] = useState(false);
  const [search, setSearch] = useState('');
  const [addition, setAddition] = useState(false);
  const [firstFetch, setFirstFetch] = useState(true);

  const handleClose = () => {
    close();
    setSearchBox(false);
  };
  const loadAlreadyListings = (textLength?: boolean, filter?: ListingFilters) => {
    if (!shouldSearch && tourInfo && currentListing) {
      const listings = tourInfo.listings.filter(listing => listing._id !== currentListing._id);
      if (filter) {
        listings.sort((a, b) => {
          if (filter.sortBy === 'monthlyRent') {
            if (filter?.orderBy === 'descending')
              return (b.listing.monthlyRent || 0) - (a.listing.monthlyRent || 0);
            else return (a.listing.monthlyRent || 0) - (b.listing.monthlyRent || 0);
          } else if (filter.sortBy === 'size') {
            if (filter?.orderBy === 'descending') return b.listing.size - a.listing.size;
            else return a.listing.size - b.listing.size;
          } else return (a.listing.monthlyRent || 0) - (b.listing.monthlyRent || 0);
        });
      }
      if (!textLength) {
        setListingData(listings.map(list => list.listing));
        setSearchedData(false);
      }
    }
  };
  const handleSubmit = (text: string, filter?: ListingFilters) => {
    setSearch(text);
    setFirstFetch(true);
    const payload = {} as GetTourListingRequest;
    if (text.length) payload.search = text;
    if (filter) {
      payload.sortBy = filter.sortBy;
      if (filter.orderBy) payload.orderBy = filter.orderBy;
    }
    if (shouldSearch || text.length) getTourListings(payload);
    else loadAlreadyListings(false, filter);
  };

  useEffect(() => {
    setListingData(data.data);
    setSearchedData(true);
  }, [data]);
  useEffect(() => loadAlreadyListings(search.length > 0), [shouldSearch, tourInfo, currentListing]);
  useEffect(() => {
    if (!listingRes.loading && listingRes.hasData) {
      if (listingRes.error)
        showNotification('error', 'Listing Fetching Error', errorExtractor(listingRes.error));
      else if (listingRes.data) {
        if (addition)
          setData(prevState => {
            if (prevState && listingRes.data) {
              const newState = cloneDeep(prevState);
              const data = listingRes.data;
              return {
                data: [...newState.data, ...data.data],
                skip: data.skip,
                hasMore: data.hasMore,
              };
            }
            return { data: [], skip: 0, hasMore: false };
          });
        else setData(listingRes.data);
        setAddition(false);
      }
    }
  }, [listingRes]);
  useEffect(() => {
    if (addition) getTourListings({ skip: data.skip } as GetTourListingRequest);
  }, [addition]);
  useEffect(() => {
    if (tourInfo?.listings && tourInfo.listings.length === 1)
      getTourListings({} as GetTourListingRequest);
    setShouldSearch(tourInfo.listings.length === 1);
  }, [tourInfo]);

  useEffect(() => {
    const container = document.getElementById('TourListingParent');
    if (container) {
      container.onscroll = debounce(() => {
        if (
          listingRes.error ||
          listingRes.loading ||
          (listingRes.data && !listingRes.data?.hasMore) ||
          !shouldSearch
        )
          return <></>;

        if (
          Math.abs(
            Math.floor(container.scrollWidth - container.scrollLeft) - container.clientWidth,
          ) < Math.floor(container.clientWidth * 0.2)
        ) {
          setFirstFetch(false);
          setAddition(true);
        }
      }, 100);
    }
  });

  return (
    <div className={`${showSection}`}>
      {!showSearchBox && (
        <TourListingDetails
          handleClose={handleClose}
          data={listingData}
          openSearch={() => setSearchBox(true)}
          search={search}
          handleSubmit={handleSubmit}
          resetScroll={listingRes.loading && !addition}
          loading={listingRes.loading}
          firstFetch={firstFetch}
          searched={isSearchedData}
        />
      )}
      {showSearchBox && (
        <TourListingSearch
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          handleCancel={() => setSearchBox(false)}
        />
      )}
    </div>
  );
};

export default TourAllListings;
