import React from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import { compileAvatarUrl } from '../../../../../utils/avatar.utils';

const RequestComplete: React.FC = () => {
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );

  return (
    <div className="listingModal--request">
      <a className="request__logo mb-4">
        <img
          src={
            company?.settings?.darkIcon
              ? compileAvatarUrl(company.settings.darkIcon.url)
              : '/images/HomeTour_Dark_Icon.svg'
          }
          alt={company?.settings?.darkIcon?.name || 'Icon'}
        />
      </a>
      <h3 className="heading__medium heading__medium-black mb-3 font-semibold">
        Thank you. Your request has been sent!
      </h3>
      <p className="para__md-gray mb-0">
        <span className="para__separator">Please check your email for confirmation of your</span>{' '}
        request and next steps.
      </p>
    </div>
  );
};
export default RequestComplete;
