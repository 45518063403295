import React, { useEffect, useRef, useState } from 'react';

import { SimpleParticipant } from '../../../state/subdomain/tour/tour.types';
import { getInitials } from '../../../utils/misc.utils';

interface Props {
  participant: SimpleParticipant;
  skipAudio?: boolean;
  additionalClasses?: string;
  size?: string;
}

const Participant: React.FC<Props> = (props: Props) => {
  const { participant, skipAudio: skipAudioProp, size, additionalClasses } = props;
  const videoEl = useRef(null);
  const audioEl = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  const skipAudio = Boolean(skipAudioProp || participant.isMe);

  const isActive = participant.isSpeaking || isHovered;

  useEffect(() => {
    if (!videoEl.current) return;

    if (participant.hasVideo) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      videoEl.current.srcObject = new MediaStream([participant.videoTrack]);
    }
  }, [participant.hasVideo, participant.videoTrack]);

  useEffect(() => {
    if (skipAudio) return;
    if (!audioEl.current) return;

    if (participant.hasAudio) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      audioEl.current.srcObject = new MediaStream([participant.audioTrack]);
    }
  }, [skipAudio, participant.hasAudio, participant.audioTrack]);

  let boxClasses = 'users--box';
  if (isActive) {
    boxClasses += ' active';
  }

  return (
    <React.Fragment>
      {!skipAudio && participant.hasAudio && <audio autoPlay playsInline ref={audioEl} />}
      <div
        className={`users--block users--block-${size} ${additionalClasses}`}
        onMouseOver={() => setIsHovered(true)}
        onMouseOut={() => setIsHovered(false)}
      >
        {participant.hasVideo ? (
          <div
            className={boxClasses}
            style={{
              transform: 'translate3d(0,0,0)',
              borderWidth: 0,
            }}
          >
            <video
              autoPlay
              muted={true}
              playsInline
              ref={videoEl}
              style={{
                background: 'black',
                height: '100%',
                width: '100%',
                transform: participant.isMe ? 'rotateY(180deg)' : '',
                objectFit: 'cover',
              }}
            />
          </div>
        ) : (
          <div className="users--box users--alt">
            <div className="users--alt-inner">
              <h3 className="usersAlt__heading">{getInitials(participant.userName)}</h3>
              <h6 className="usersAlt__subHeading">{participant.userName}</h6>
            </div>
          </div>
        )}
        {isActive && (
          <div className="users--ov">
            <span className="users--ov-icon">
              <i className={participant.hasAudio ? 'icon-microphone' : 'icon-microphone-slash'}></i>
            </span>
            <span className="users--ov-name">{participant.userName}</span>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

Participant.defaultProps = {
  skipAudio: false,
  additionalClasses: '',
  size: 'md',
};

export default Participant;
