import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { REVIEW_STATE, RECORDING_STATE } from '../../../common/constants';
import Recorder from './recorder';
import {
  changeVideoStatus,
  changeAudioStatus,
  saveAudios,
} from '../../../state/review/review.action';
import { useUploadBlob, usePostBlob } from '../../../state/review/review.hook';
import { showNotification } from '../../../utils/misc.utils';
import { errorExtractor } from '../../../utils/error.utils';
import { useParams } from 'react-router';
import getBlobTime from 'get-blob-duration';
import { convertSecToString } from '../listing.component';

interface Props {
  setLoading: (e: boolean) => void;
}
const ReviewAudioHandler: React.FC<Props> = props => {
  const { listingId } = useParams();
  const reviewData = useSelector((state: AppStateInterface) => state.review);

  const { res: uploadRes, uploadBlob } = useUploadBlob();
  const { res: postRes, postBlob } = usePostBlob();

  const [reviewState, handleReviewState] = useState(REVIEW_STATE.IDLE);
  const [audioName, setAudioName] = useState('');

  useEffect(() => {
    handleReviewState(reviewData.reviewState);
  }, [reviewData]);
  useEffect(() => {
    if (uploadRes.loading) props.setLoading(true);
    if (!uploadRes.loading && uploadRes.hasData) {
      if (uploadRes.error)
        showNotification('error', 'Upload Error', errorExtractor(uploadRes.error));
      else if (uploadRes.data) {
        const media = uploadRes.data.media;
        if (media?.length) {
          const finalObj = {
            ...media[0],
            name: audioName,
            listingId,
            segment: 1,
            playVideoStartTimestamp: reviewData.videoTimeStamp,
          };
          postBlob(finalObj);
        }
      }
    }
  }, [uploadRes]);
  useEffect(() => {
    if (!postRes.loading && postRes.hasData) {
      props.setLoading(false);
      if (postRes.error)
        showNotification('error', 'Post Audio Error', errorExtractor(postRes.error));
      else if (postRes.data) saveAudios(postRes.data);
      changeAudioStatus(RECORDING_STATE.READY);
      changeVideoStatus(REVIEW_STATE.INITIALIZE);
    }
  }, [postRes]);

  const onDone = () => {
    switch (reviewState) {
      case REVIEW_STATE.INITIALIZE:
        changeAudioStatus(RECORDING_STATE.READY);
        break;
      case REVIEW_STATE.START:
        changeAudioStatus(RECORDING_STATE.RECORD);
        break;
      default:
        break;
    }
  };
  const onError = (e: string) => {
    if (e.length) {
      changeVideoStatus(REVIEW_STATE.ERROR);
      changeAudioStatus(RECORDING_STATE.ERROR);
    }
  };
  const onStop = (e: any) => {
    if (reviewData.recordingState !== RECORDING_STATE.IDLE) {
      changeVideoStatus(REVIEW_STATE.FINISH);
      changeAudioStatus(RECORDING_STATE.STOP);
      getBlobTime(e).then(duration => setAudioName(convertSecToString(duration)));
      uploadBlob(e);
    } else {
      if (reviewData.reviewState !== REVIEW_STATE.IDLE) changeVideoStatus(REVIEW_STATE.IDLE);
    }
  };

  return (
    <React.Fragment>
      {reviewState !== REVIEW_STATE.IDLE && reviewState !== REVIEW_STATE.FINISH && (
        <Recorder onDone={onDone} onError={onError} onStop={onStop} state={reviewState} />
      )}
    </React.Fragment>
  );
};

export default ReviewAudioHandler;
