import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import SmallLoader from '../../../../common/small.loader';

interface Props {
  show: boolean;
  handleClose: () => void;
  confirm: (value: boolean) => void;
  loading: boolean;
}

const CommentDeleteWarning: React.FC<Props> = props => {
  const { show, handleClose, confirm, loading } = props;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      className="office-modal"
      contained-modal-title-vcenter="true"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Delete Comment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="em__para text__gray">Are you sure you want to delete this comment?</p>
        <div className="row row--space-12 flex-sm-row-reverse pt-sm-4 mb-3 pb-2">
          <div className="col-12 col-sm-6">
            <Button
              type="button"
              className={`admin__button underline__button w-100 mb-4 mb-sm-0`}
              onClick={() => confirm(true)}
              disabled={loading}
            >
              {loading ? (
                <React.Fragment>
                  Deleting <SmallLoader />
                </React.Fragment>
              ) : (
                <React.Fragment>Delete</React.Fragment>
              )}
            </Button>
          </div>
          <div className="col-12 col-sm-6">
            <Button
              onClick={() => confirm(false)}
              className="outline__button underline__button w-100"
              disabled={loading}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CommentDeleteWarning;
