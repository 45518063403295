import React from 'react';
import withUnsupportedBrowserAlertForParticipant from './withUnsupportedBrowserAlertForParticipant';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import { BrowserInterface } from '../../../state/subdomain/tour/tour.types';

interface Props {
  title: string;
  leaveCall: () => void;
  browser: BrowserInterface | null;
}

const ParticipantStandBy: React.FC<Props> = (props: Props) => {
  const { title } = props;
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );
  return (
    <div className="sec--vertical-permanent sec--vertical sec--vertical-md">
      <div className="container container--large">
        <a className="listing__logo">
          <img
            src={
              company?.settings?.darkLogo
                ? compileAvatarUrl(company.settings.darkLogo.url)
                : '/images/HomeTour_Dark_Logo.svg'
            }
            alt="Logo"
          />
        </a>
        <div className="tourEnd--content standby--content sec--vertical-middle">
          <div className="container--xs">
            <div className="text-left pt-5">
              <span className="sub__logo ml-0 mb-48">
                <img
                  src={
                    company?.settings?.darkIcon
                      ? compileAvatarUrl(company.settings.darkIcon.url)
                      : '/images/HomeTour_Dark_Icon.svg'
                  }
                  alt="Logo"
                />
              </span>
              <h3 className="dashboard__heading mb-4">{title}</h3>
              <p className="form__para mb-4 mb-sm-5">
                The broker has paused the session. Please standby until the session is resumed.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUnsupportedBrowserAlertForParticipant<Props>(ParticipantStandBy);
