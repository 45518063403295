import React from 'react';

interface Props {
  tourUrls: string[];
}

const ThreeDTourSearchResults: React.FC<Props> = (props: Props) => {
  const { tourUrls } = props;
  return (
    <div className="searchResults--block">
      <h5 className="heading__sm heading__sm-black font-semibold">3D Tours</h5>
      <div className="mt-4">
        <div className="video--search block-flex">
          <div className="block--search-row videos--search-row block--scroll">
            {tourUrls?.map((tourUrl, index) => (
              <div className="block--search-col" key={index}>
                <div className="video--box">
                  <iframe src={tourUrl} allowFullScreen={true} height="100%" width="100%" />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeDTourSearchResults;
