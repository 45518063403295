import React, { useEffect } from 'react';
import AboutHeader from './header';
import AboutQoute from './qoute';
import AboutGifs from './gifs';
import AboutStrive from './strive';
import AboutTeam from './team';
import AboutFooter from './footer';
import AboutForm from '../../components/aboutForm';

const About: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="off--canvas">
      <AboutHeader />
      <AboutQoute />
      <AboutGifs />
      <AboutStrive />
      <AboutTeam />
      <footer className="footer footer-n footer-about mt-0 pt-0">
        <div className="container-fluid fluid--expand">
          <div className="text-center">
            <div className="personalize--block personalize--block-about">
              <h2 className="heading__component heading__component-black">
                We’d love to hear from you
              </h2>
              <AboutForm />
            </div>
          </div>
          <AboutFooter />
        </div>
      </footer>
    </div>
  );
};

export default About;
