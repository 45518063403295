import React, { useState, useEffect } from 'react';
import {
  ListingFilters,
  sortByTypes,
  orderByTypes,
} from '../../../../state/subdomain/listing/listing.types';

interface Props {
  handleChange: (filter: ListingFilters) => void;
  isSearched: boolean;
}

const TourListingFilters: React.FC<Props> = (props: Props) => {
  const { handleChange, isSearched } = props;
  const [sort, setSort] = useState('availableNow' as sortByTypes);
  const [order, setOrder] = useState('' as orderByTypes);

  const onChange = (sortBy: sortByTypes, orderBy?: orderByTypes) => {
    setSort(sortBy);
    if (orderBy) {
      handleChange({ sortBy, orderBy });
      setOrder(orderBy);
    } else handleChange({ sortBy });
  };

  useEffect(() => {
    if (!isSearched) setSort('updatedAt');
  }, [isSearched]);

  return (
    <div className="ls--sort ls--right">
      <label className="ls__label">Sort By:</label>
      <ul className="ls__list">
        <li onClick={() => onChange('availableNow')} className={isSearched ? '' : 'd-none'}>
          <a className={'availableNow' === sort ? 'active' : ''}>Available Now</a>
        </li>
        <li onClick={() => onChange('monthlyRent', 'descending')}>
          <a className={'monthlyRent' === sort && 'descending' === order ? 'active' : ''}>
            Price (High)-(Low)
          </a>
        </li>
        <li onClick={() => onChange('monthlyRent', 'ascending')}>
          <a className={'monthlyRent' === sort && 'ascending' === order ? 'active' : ''}>
            Price (Low)-(High)
          </a>
        </li>
        <li onClick={() => onChange('size', 'descending')}>
          <a className={'size' === sort && 'descending' === order ? 'active' : ''}>
            SF (High)-(Low)
          </a>
        </li>
        <li onClick={() => onChange('size', 'ascending')}>
          <a className={'size' === sort && 'ascending' === order ? 'active' : ''}>
            SF (Low)-(High)
          </a>
        </li>
      </ul>
    </div>
  );
};

export default TourListingFilters;
