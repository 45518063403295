import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { startCase } from 'lodash';
import { useDeleteBuilding } from '../../state/building/building.hook';
import { useDeleteListing } from '../../state/listing/listing.hook';
import { useHistory } from 'react-router';
import { showNotification } from '../../utils/misc.utils';
import { errorExtractor } from '../../utils/error.utils';
import SmallLoader from '../common/small.loader';

interface Props {
  type: string;
  name: string;
  id: string;
  handleCancel: () => void;
  backId?: string;
}

const DeleteWarning: React.FC<Props> = props => {
  const { name, type, handleCancel, id, backId } = props;
  const history = useHistory();

  const { res: buildRes, deleteBuilding } = useDeleteBuilding();
  const { res: listRes, deleteListing } = useDeleteListing();

  useEffect(() => {
    const e = buildRes;
    if (!e.loading && e.hasData) {
      if (e.data) history.push('/buildings');
      else if (e.error) {
        showNotification('error', 'Deletion Error', errorExtractor(e.error));
        handleCancel();
      }
    }
  }, [buildRes]);
  useEffect(() => {
    const e = listRes;
    if (!e.loading && e.hasData && backId) {
      if (e.data) history.push(`/building/${backId}/listings`);
      else if (e.error) {
        showNotification('error', 'Deletion Error', errorExtractor(e.error));
        handleCancel();
      }
    }
  }, [listRes]);

  const handleDelete = () => {
    if (type === 'building') deleteBuilding(id);
    if (type === 'listing' && backId?.length) deleteListing(id);
  };

  let disableDelete = listRes.loading || buildRes.loading || !id.length;
  if (!disableDelete) disableDelete = type === 'listing' && (!backId || !backId.length);

  return (
    <Modal show onHide={handleCancel} className="office-modal" contained-modal-title-vcenter="true">
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Delete {name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="em__para text__gray">
          Are you sure you want to delete this {type}? You will lose all information within it.
        </p>
        <div className="row row--space-12 flex-sm-row-reverse pt-sm-4 mb-3 pb-2">
          <div className="col-12 col-sm-6">
            <Button
              type="button"
              className={`admin__button underline__button w-100 mb-4 mb-sm-0`}
              onClick={handleDelete}
              disabled={disableDelete}
            >
              {listRes.loading || buildRes.loading ? (
                <React.Fragment>
                  Deleting <SmallLoader />
                </React.Fragment>
              ) : (
                <React.Fragment>Delete {startCase(type)}</React.Fragment>
              )}
            </Button>
          </div>
          <div className="col-12 col-sm-6">
            <Button
              onClick={handleCancel}
              className="outline__button underline__button w-100"
              disabled={disableDelete}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteWarning;
