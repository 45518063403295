export interface ScheduleDemoInterface {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  totalPortfolio: number | null;
  listingsCount: number | null;
  brokersCount: number | null;
  matterPortInfo: string | null;
}

export enum LandingActions {
  GET_PUBLIC_LISTINGS = 'GET_PUBLIC_LISTINGS',
}
