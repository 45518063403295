import React from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { compileAvatarUrl } from '../../../utils/avatar.utils';

const SingleListingsFooter: React.FC = () => {
  const companyInfo = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );

  return (
    <footer className="subdomain__footer">
      <div className="container container--large">
        <div className="row justify-content-md-between">
          <div className="col-12 col-sm-5 col-lg-5">
            <div className="sd__footer-l">
              <a className="available--listing-logo mb-4">
                <img
                  src={
                    companyInfo?.settings?.whiteLogo
                      ? compileAvatarUrl(companyInfo.settings.whiteLogo.url)
                      : 'images/HomeTour_White_Logo.svg'
                  }
                  alt="Logo"
                />
              </a>
              <p className="sd__footer-para mb-0">
                <i className="icon-copyright mr-1"></i> 2020 {companyInfo?.name?.toUpperCase()}{' '}
                Properties
              </p>
            </div>
          </div>
          <div className="col-12 col-sm-7 col-lg-5">
            {(companyInfo?.settings?.termsUrl ||
              companyInfo?.settings?.careersUrl ||
              companyInfo?.settings?.privacyUrl) && (
              <ul className="sd__footer-list mb-4 mb-sm-5 mt-4 mt-sm-0">
                {companyInfo?.settings?.careersUrl && (
                  <li>
                    <a
                      href={companyInfo.settings.careersUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Careers
                    </a>
                  </li>
                )}
                {companyInfo?.settings?.privacyUrl && (
                  <li>
                    <a
                      href={companyInfo.settings.privacyUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </li>
                )}
                {companyInfo?.settings?.termsUrl && (
                  <li>
                    <a
                      href={companyInfo.settings.termsUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms & Conditions
                    </a>
                  </li>
                )}
              </ul>
            )}

            <p className="sd__footer-para mb-0 mb-sm-5 pb-sm-1">
              {companyInfo?.name && (
                <React.Fragment>
                  <span className="font-semibold">
                    {companyInfo?.name?.toUpperCase()} Properties
                  </span>
                  <br />
                </React.Fragment>
              )}
              {companyInfo?.settings.officialAddress && (
                <React.Fragment>
                  {companyInfo?.settings.officialAddress}
                  <br />
                </React.Fragment>
              )}
              {companyInfo?.settings?.officialPhone && (
                <React.Fragment>Phone: {companyInfo?.settings?.officialPhone}</React.Fragment>
              )}
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default SingleListingsFooter;
