import React from 'react';
import { Navbar } from 'react-bootstrap';
import LoginForm from '../../components/login/login.view';
import { Link } from 'react-router-dom';

const LoginPage: React.FC = () => {
  return (
    <div className="form--page signin--page">
      <div className="form--banner">
        <div className="formBanner--canvas">
          <Navbar.Brand className="form__brand">
            <Link to="/">
              <img src="/images/HomeTour_White_Logo.svg" alt="Logo" />
            </Link>
          </Navbar.Brand>
          <h1 className="form__heading form__heading-green">
            {"It's good to"} <span className="form__heading-white">see you back</span>
          </h1>
          <span className="formBanner--img">
            <img src="/images/signin-img.svg" alt="Img" />
          </span>
        </div>
      </div>
      <div className="form--content signin--content">
        <div className="signin--canvas container--xs">
          <h1 className="form__heading pb-sm-5">Sign In</h1>
          <LoginForm />
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
