import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import { TourBuildingInterface, TourListingInterface } from '../../../../../state/tour/tour.types';
import SpecCard from './specCard';
import { mergeBuildingHours } from '../../../../listing/listing.component';

const ListingSpecifications: React.FC = () => {
  const data = useSelector((state: AppStateInterface) => state.tour.tourListing);

  const [building, setBuilding] = useState({} as TourBuildingInterface);
  const [listing, setListing] = useState({} as TourListingInterface);
  useEffect(() => {
    if (data && data.listing) {
      const listingData = data.listing;
      setListing(listingData);
      if (listingData.building) setBuilding(listingData.building);
    }
  }, [data]);

  return (
    <div className="liveTour--building-main liveTour--specs-main">
      <div className="row row--space-5 justify-content-center justify-content-lg-start">
        <SpecCard
          iconClass="icon-location"
          heading="Location"
          details={building.location ? [building.location] : ['N/A']}
        />
        <SpecCard
          iconClass="icon-building-alt"
          heading="Building Size"
          details={
            listing.size && listing.size > 0 ? [listing.size.toLocaleString() + ' SF'] : ['N/A']
          }
        />
        <SpecCard
          iconClass="icon-layers"
          heading="Number of Floors"
          details={
            building.floorsCount && building.floorsCount > 0
              ? [building.floorsCount.toLocaleString()]
              : ['N/A']
          }
        />
        <SpecCard
          iconClass="icon-average-floor"
          heading="Average Floor"
          details={
            building.averageFloor && building.averageFloor > 0
              ? [building.averageFloor.toLocaleString() + ' SF']
              : ['N/A']
          }
        />
        <SpecCard
          iconClass="icon-telecom"
          heading="Telecom/Data Providers"
          details={
            building.telecomProviders && building.telecomProviders.length > 0
              ? [building.telecomProviders]
              : ['N/A']
          }
        />
        <SpecCard
          iconClass="icon-clock-alt"
          heading="Building Hours"
          details={
            building.buildingHours
              ? (mergeBuildingHours(building.buildingHours) as string[])
              : ['N/A']
          }
        />
        <SpecCard
          iconClass="icon-passenger-elevator"
          heading="Passenger Elevators"
          details={
            building.passengerElevators && building.passengerElevators.length > 0
              ? [building.passengerElevators]
              : ['N/A']
          }
        />
        <SpecCard
          iconClass="icon-freight-elevator"
          heading="Freight Elevators"
          details={
            building.freightElevators && building.freightElevators.length > 0
              ? [building.freightElevators]
              : ['N/A']
          }
        />
        <SpecCard
          iconClass="icon-spacing-vertical"
          heading="Slab to Slab Height"
          details={
            building.slabToSlabHeight && building.slabToSlabHeight.length > 0
              ? [building.slabToSlabHeight]
              : ['N/A']
          }
        />
        <SpecCard
          iconClass="icon-spacing-horizontal"
          heading="Column Spacing"
          details={
            building.columnSpacingHeight &&
            building.columnSpacingWidth &&
            building.columnSpacingHeight > 0 &&
            building.columnSpacingWidth > 0
              ? [
                  `${building.columnSpacingWidth.toLocaleString()}' X ${building.columnSpacingHeight.toLocaleString()}'`,
                ]
              : ['N/A']
          }
        />
        <SpecCard
          iconClass="icon-year-built"
          heading="Year Built"
          details={
            building.yearBuilt && building.yearBuilt > 0 ? [String(building.yearBuilt)] : ['N/A']
          }
        />
        <SpecCard
          iconClass="icon-renovate"
          heading="Year Renovated"
          details={
            building.yearRenovated && building.yearRenovated > 0
              ? [String(building.yearRenovated)]
              : ['N/A']
          }
        />
        <SpecCard
          iconClass="icon-parking-ratio"
          heading="Parking Ratio"
          details={
            building.parkingRatio &&
            building.parkingRatio.length > 0 &&
            building.parkingRatio.split(':').length === 2
              ? [
                  `
                      ${building.parkingRatio
                        .split(':')[0]
                        .toLocaleString()} : ${building.parkingRatio.split(':')[1].toLocaleString()}
                        SF
                      `,
                ]
              : ['N/A']
          }
        />
        {building.energyStarCertification && building.energyStarCertification.length > 0 && (
          <div className="col col-6 col-md-4 col-xl-3">
            <div className="building--widget specs--widget specs--widget-alt certification--widget">
              <span className="bw__icon">
                <img src="/images/star-certification.png" alt="Img" className="img--cover" />
              </span>
              <div className="bw__detail mr-auto">
                <h6 className="bw__heading">
                  Energy Star <br />
                  Certification
                </h6>
              </div>
            </div>
          </div>
        )}
        {building.leedCertification && building.leedCertification.length > 0 && (
          <div className="col col-6 col-md-4 col-xl-3">
            <div className="building--widget specs--widget specs--widget-alt certification--widget">
              <span className="bw__icon">
                <img src="/images/gold-certification.png" alt="Img" className="img--cover" />
              </span>
              <div className="bw__detail mr-auto">
                <h6 className="bw__heading">
                  Leed Gold <br />
                  Certification
                </h6>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ListingSpecifications;
