import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const TermsPage: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="terms">
      <div className="container container--expand">
        <Link className="terms__logo d-inline-block" to="/">
          <img src="/images/HomeTour_Dark_Logo.svg" alt="Logo" />
        </Link>
        <div className="terms--content">
          <h3 className="dashboard__heading text-center">Terms of Service</h3>
          <ul className="terms__list">
            <li>
              <label className="terms__label">Introduction</label>
              <p className="text__gray mb-4">
                Welcome to <strong>Dupont Studios, Inc</strong> (“<strong>Company</strong>”, “
                <strong>we</strong>”, “<strong>our</strong>”, “<strong>us</strong>”)! As you have
                just clicked our Terms of Service, please pause, grab a cup of coffee and carefully
                read the following pages. It will take you approximately 20 minutes.
              </p>
              <p className="text__gray mb-4">
                These Terms of Service (“<strong>Terms</strong>”, “<strong>Terms of Service</strong>
                ”) govern your use of our web pages located at{' '}
                <a className="text__blue">http://www.hometour.com</a> operated by Dupont Studios,
                Inc
              </p>
              <p className="text__gray mb-4">
                Our Privacy Policy also governs your use of our Service and explains how we collect,
                safeguard and disclose information that results from your use of our web pages.
                Please read it here [●].
              </p>
              <p className="text__gray mb-4">
                Your agreement with us includes these Terms and our Privacy Policy (“
                <strong>Agreements</strong>”). You acknowledge that you have read and understood
                Agreements, and agree to be bound of them.
              </p>
              <p className="text__gray mb-4">
                If you do not agree with (or cannot comply with) Agreements, then you may not use
                the Service, but please let us know by emailing at{' '}
                <a href="mailto:info@hometour.com" className="text__blue">
                  info@hometour.com
                </a>{' '}
                so we can try to find a solution. These Terms apply to all visitors, users and
                others who wish to access or use Service.
              </p>
              <p className="text__gray mb-4">Thank you for being responsible.</p>
            </li>
            <li>
              <label className="terms__label">Communications</label>
              <p className="text__gray mb-4">
                By creating an Account on our Service, you agree to subscribe to newsletters,
                marketing or promotional materials and other information we may send. However, you
                may opt out of receiving any, or all, of these communications from us by following
                the unsubscribe link or by emailing at.
              </p>
            </li>
            <li>
              <label className="terms__label">Subscriptions</label>
              <p className="text__gray mb-4">
                Some parts of Service are billed on a subscription basis (“
                <strong>Subscription(s)</strong>”). You will be billed in advance on a recurring and
                periodic basis (“<strong>Billing Cycle</strong>”). Billing cycles are set either on
                a monthly or annual basis, depending on the type of subscription plan you select
                when purchasing a Subscription.
              </p>
              <p className="text__gray mb-4">
                At the end of each Billing Cycle, your Subscription will automatically renew under
                the exact same conditions unless you cancel it or Dupont Studios, Inc cancels it.
                You may cancel your Subscription renewal either through your online account
                management page or by contacting Dupont Studios, Inc customer support team.
              </p>
              <p className="text__gray mb-4">
                A valid payment method, including credit card, is required to process the payment
                for your subscription. You shall provide Dupont Studios, Inc with accurate and
                complete billing information including full name, address, state, zip code,
                telephone number, and a valid payment method information. By submitting such payment
                information, you automatically authorize Dupont Studios, Inc to charge all
                Subscription fees incurred through your account to any such payment instruments.
              </p>
              <p className="text__gray mb-4">
                Should automatic billing fail to occur for any reason, Dupont Studios, Inc will
                issue an electronic invoice indicating that you must proceed manually, within a
                certain deadline date, with the full payment corresponding to the billing period as
                indicated on the invoice.
              </p>
            </li>
            <li>
              <label className="terms__label">Fee Changes</label>
              <p className="text__gray mb-4">
                Dupont Studios, Inc, in its sole discretion and at any time, may modify Subscription
                fees for the Subscriptions. Any Subscription fee change will become effective at the
                end of the then-current Billing Cycle.
              </p>
              <p className="text__gray mb-4">
                Dupont Studios, Inc will provide you with a reasonable prior notice of any change in
                Subscription fees to give you an opportunity to terminate your Subscription before
                such change becomes effective.
              </p>
              <p className="text__gray mb-4">
                Your continued use of Service after Subscription fee change comes into effect
                constitutes your agreement to pay the modified Subscription fee amount.
              </p>
            </li>
            <li>
              <label className="terms__label">Refunds</label>
              <p className="text__gray mb-4">
                Except when required by law, paid Subscription fees are non-refundable.
              </p>
            </li>
            <li>
              <label className="terms__label">Content</label>
              <p className="text__gray mb-4">
                Our Service allows you to post, link, store, share and otherwise make available
                certain information, text, graphics, videos, or other material (“
                <strong>Content</strong>”). You are responsible for Content that you post on or
                through Service, including its legality, reliability, and appropriateness.
              </p>
              <p className="text__gray mb-4">
                By posting Content on or through Service, You represent and warrant that: (i)
                Content is yours (you own it) and/or you have the right to use it and the right to
                grant us the rights and license as provided in these Terms, and (ii) that the
                posting of your Content on or through Service does not violate the privacy rights,
                publicity rights, copyrights, contract rights or any other rights of any person or
                entity. We reserve the right to terminate the account of anyone found to be
                infringing on a copyright.
              </p>
              <p className="text__gray mb-4">
                You retain any and all of your rights to any Content you submit, post or display on
                or through Service and you are responsible for protecting those rights. We take no
                responsibility and assume no liability for Content you or any third party posts on
                or through Service. However, by posting Content using Service you grant us the right
                and license to use, modify, publicly perform, publicly display, reproduce, and
                distribute such Content on and through Service. You agree that this license includes
                the right for us to make your Content available to other users of Service, who may
                also use your Content subject to these Terms.
              </p>
              <p className="text__gray mb-4">
                Dupont Studios, Inc has the right but not the obligation to monitor and edit all
                Content provided by users.
              </p>
              <p className="text__gray mb-4">
                In addition, Content found on or through this Service are the property of Dupont
                Studios, Inc or used with permission. You may not distribute, modify, transmit,
                reuse, download, repost, copy, or use said Content, whether in whole or in part, for
                commercial purposes or for personal gain, without express advance written permission
                from us.
              </p>
            </li>
            <li>
              <label className="terms__label">Prohibited Uses</label>
              <p className="text__gray mb-4">
                You may use Service only for lawful purposes and in accordance with Terms. You agree
                not to use Service:
              </p>
              <ul className="alpha__list">
                <li>
                  In any way that violates any applicable national or international law or
                  regulation.
                </li>
                <li>
                  For the purpose of exploiting, harming, or attempting to exploit or harm minors in
                  any way by exposing them to inappropriate content or otherwise.
                </li>
                <li>
                  To transmit, or procure the sending of, any advertising or promotional material,
                  including any “junk mail”, “chain letter,” “spam,” or any other similar
                  solicitation.
                </li>
                <li>
                  To impersonate or attempt to impersonate Company, a Company employee, another
                  user, or any other person or entity.
                </li>
                <li>
                  In any way that infringes upon the rights of others, or in any way is illegal,
                  threatening, fraudulent, or harmful, or in connection with any unlawful, illegal,
                  fraudulent, or harmful purpose or activity.
                </li>
                <li>
                  To engage in any other conduct that restricts or inhibits anyone{"'"}s use or
                  enjoyment of Service, or which, as determined by us, may harm or offend Company or
                  users of Service or expose them to liability.
                </li>
              </ul>
              <p className="text__gray mb-4">Additionally, you agree not to:</p>
              <ul className="alpha__list">
                <li>
                  Use Service in any manner that could disable, overburden, damage, or impair
                  Service or interfere with any other party{"'"}s use of Service, including their
                  ability to engage in real time activities through Service.
                </li>
                <li>
                  Use any robot, spider, or other automatic device, process, or means to access
                  Service for any purpose, including monitoring or copying any of the material on
                  Service.
                </li>
                <li>
                  Use any manual process to monitor or copy any of the material on Service or for
                  any other unauthorized purpose without our prior written consent.
                </li>
                <li>
                  Use any device, software, or routine that interferes with the proper working of
                  Service.
                </li>
                <li>
                  Introduce any viruses, trojan horses, worms, logic bombs, or other material which
                  is malicious or technologically harmful.
                </li>
                <li>
                  Attempt to gain unauthorized access to, interfere with, damage, or disrupt any
                  parts of Service, the server on which Service is stored, or any server, computer,
                  or database connected to Service.
                </li>
                <li>
                  Attack Service via a denial-of-service attack or a distributed denial-of-service
                  attack.
                </li>
                <li>Take any action that may damage or falsify Company rating.</li>
                <li>Otherwise attempt to interfere with the proper working of Service.</li>
              </ul>
            </li>
            <li>
              <label className="terms__label">Analytics</label>
              <p className="text__gray mb-4">
                We may use third-party Service Providers to monitor and analyze the use of our
                Service.
              </p>
              <p className="text__gray mb-4">
                <span className="d-inline-block w-100 font-semibold">Google Analytics</span>
                Google Analytics is a web analytics service offered by Google that tracks and
                reports website traffic. Google uses the data collected to track and monitor the use
                of our Service. This data is shared with other Google services. Google may use the
                collected data to contextualise and personalise the ads of its own advertising
                network.
              </p>
              <p className="text__gray mb-4">
                For more information on the privacy practices of Google, please visit the Google
                Privacy Terms page:{' '}
                <a className="text__blue">https://policies.google.com/privacy?hl=en</a>.
              </p>
              <p className="text__gray mb-4">
                We also encourage you to review the Google{"'"}s policy for safeguarding your data:
                <a className="text__blue">https://support.google.com/analytics/answer/6004245</a>.
              </p>
            </li>
            <li>
              <label className="terms__label">No Use By Minors</label>
              <p className="text__gray mb-4">
                Service is intended only for access and use by individuals at least eighteen (18)
                years old. By accessing or using any of Company, you warrant and represent that you
                are at least eighteen (18) years of age and with the full authority, right, and
                capacity to enter into this agreement and abide by all of the terms and conditions
                of Terms. If you are not at least eighteen (18) years old, you are prohibited from
                both the access and usage of Service.
              </p>
            </li>
            <li>
              <label className="terms__label">Accounts</label>
              <p className="text__gray mb-4">
                When you create an account with us, you guarantee that you are above the age of 18,
                and that the information you provide us is accurate, complete, and current at all
                times. Inaccurate, incomplete, or obsolete information may result in the immediate
                termination of your account on Service.
              </p>
              <p className="text__gray mb-4">
                You are responsible for maintaining the confidentiality of your account and
                password, including but not limited to the restriction of access to your computer
                and/or account. You agree to accept responsibility for any and all activities or
                actions that occur under your account and/or password, whether your password is with
                our Service or a third-party service. You must notify us immediately upon becoming
                aware of any breach of security or unauthorized use of your account.
              </p>
              <p className="text__gray mb-4">
                You may not use as a username the name of another person or entity or that is not
                lawfully available for use, a name or trademark that is subject to any rights of
                another person or entity other than you, without appropriate authorization. You may
                not use as a username any name that is offensive, vulgar or obscene.
              </p>
              <p className="text__gray mb-4">
                We reserve the right to refuse service, terminate accounts, remove or edit content,
                or cancel orders in our sole discretion.
              </p>
            </li>
            <li>
              <label className="terms__label">Intellectual Property</label>
              <p className="text__gray mb-4">
                Service and its original content (excluding Content provided by users), features and
                functionality are and will remain the exclusive property of Dupont Studios, Inc and
                its licensors. Service is protected by copyright, trademark, and other laws of the
                United States. Our trademarks and trade dress may not be used in connection with any
                product or service without the prior written consent of Dupont Studios, Inc.
              </p>
            </li>
            <li>
              <label className="terms__label">Copyright Policy</label>
              <p className="text__gray mb-4">
                We respect the intellectual property rights of others. It is our policy to respond
                to any claim that Content posted on Service infringes on the copyright or other
                intellectual property rights (“<strong>Infringement</strong>”) of any person or
                entity.
              </p>
              <p className="text__gray mb-4">
                If you are a copyright owner, or authorized on behalf of one, and you believe that
                the copyrighted work has been copied in a way that constitutes copyright
                infringement, please submit your claim via email to{' '}
                <a href="mailto:info@hometour.com" className="text__blue">
                  info@hometour.com
                </a>
                , with the subject line: “Copyright Infringement” and include in your claim a
                detailed description of the alleged Infringement as detailed below, under “DMCA
                Notice and Procedure for Copyright Infringement Claims”
              </p>
              <p className="text__gray mb-4">
                You may be held accountable for damages (including costs and attorneys{"'"} fees)
                for misrepresentation or bad-faith claims on the infringement of any Content found
                on and/or through Service on your copyright.
              </p>
            </li>
            <li>
              <label className="terms__label">
                DMCA Notice and Procedure for Copyright Infringement Claims
              </label>
              <p className="text__gray mb-4">
                You may submit a notification pursuant to the Digital Millennium Copyright Act
                (DMCA) by providing our Copyright Agent with the following information in writing
                (see 17 U.S.C 512(c)(3) for further detail):
              </p>
              <ul className="alpha__list">
                <li>
                  an electronic or physical signature of the person authorized to act on behalf of
                  the owner of the copyright{"'"}s interest;
                </li>
                <li>
                  a description of the copyrighted work that you claim has been infringed, including
                  the URL (i.e., web page address) of the location where the copyrighted work exists
                  or a copy of the copyrighted work;
                </li>
                <li>
                  identification of the URL or other specific location on Service where the material
                  that you claim is infringing is located;
                </li>
                <li>your address, telephone number, and email address;</li>
                <li>
                  a statement by you that you have a good faith belief that the disputed use is not
                  authorized by the copyright owner, its agent, or the law;
                </li>
                <li>
                  a statement by you, made under penalty of perjury, that the above information in
                  your notice is accurate and that you are the copyright owner or authorized to act
                  on the copyright owner{"'"}s behalf.
                </li>
              </ul>
              <p className="text__gray mb-4">
                You can contact our Copyright Agent via email at{' '}
                <a href="mailto:info@hometour.com" className="text__blue">
                  info@hometour.com
                </a>
              </p>
            </li>
            <li>
              <label className="terms__label">Error Reporting and Feedback</label>
              <p className="text__gray mb-4">
                You may provide us either directly at{' '}
                <a href="mailto:info@hometour.com" className="text__blue">
                  info@hometour.com
                </a>{' '}
                or via third party sites and tools with information and feedback concerning errors,
                suggestions for improvements, ideas, problems, complaints, and other matters related
                to our Service (“
                <strong>Feedback</strong>”). You acknowledge and agree that: (i) you shall not
                retain, acquire or assert any intellectual property right or other right, title or
                interest in or to the Feedback; (ii) Company may have development ideas similar to
                the Feedback; (iii) Feedback does not contain confidential information or
                proprietary information from you or any third party; and (iv) Company is not under
                any obligation of confidentiality with respect to the Feedback. In the event the
                transfer of the ownership to the Feedback is not possible due to applicable
                mandatory laws, you grant Company and its affiliates an exclusive, transferable,
                irrevocable, free-of-charge, sub-licensable, unlimited and perpetual right to use
                (including copy, modify, create derivative works, publish, distribute and
                commercialize) Feedback in any manner and for any purpose.
              </p>
              <p className="text__gray mb-4">
                The third party sites and tools mentioned above include the following:
              </p>
              <p className="text__gray mb-4">
                <span className="d-inline-block w-100 font-semibold">Bugsnag</span>
                Bugsnag is a platform for monitoring and logging stability of applications provided
                by Bugsnag Inc. Please read their Privacy Policy here:{' '}
                <a className="text__blue">https://docs.bugsnag.com/legal/privacy-policy/</a>
              </p>
              <p className="text__gray mb-4">
                <span className="d-inline-block w-100 font-semibold">Rollbar</span>
                Rollbar is error tracking service provided by Rollbar Inc. Find out more here:
                <a className="text__blue">https://docs.rollbar.com/docs/privacy-policy</a>
              </p>
            </li>
            <li>
              <label className="terms__label">Links To Other Web Sites</label>
              <p className="text__gray mb-4">
                Our Service may contain links to third party web sites or services that are not
                owned or controlled by Dupont Studios, Inc
              </p>
              <p className="text__gray mb-4">
                Dupont Studios, Inc has no control over, and assumes no responsibility for the
                content, privacy policies, or practices of any third party web sites or services. We
                do not warrant the offerings of any of these entities/individuals or their websites.
              </p>
              <p className="text__gray mb-4">
                YOU ACKNOWLEDGE AND AGREE THAT DUPONT STUDIOS, INC SHALL NOT BE RESPONSIBLE OR
                LIABLE, DIRECTLY OR INDIRECTLY, FOR ANY DAMAGE OR LOSS CAUSED OR ALLEGED TO BE
                CAUSED BY OR IN CONNECTION WITH USE OF OR RELIANCE ON ANY SUCH CONTENT, GOODS OR
                SERVICES AVAILABLE ON OR THROUGH ANY SUCH THIRD PARTY WEB SITES OR SERVICES.
              </p>
              <p className="text__gray mb-4">
                WE STRONGLY ADVISE YOU TO READ THE TERMS OF SERVICE AND PRIVACY POLICIES OF ANY
                THIRD PARTY WEB SITES OR SERVICES THAT YOU VISIT.
              </p>
            </li>
            <li>
              <label className="terms__label">Disclaimer Of Warranty</label>
              <p className="text__gray mb-4">
                THESE SERVICES ARE PROVIDED BY COMPANY ON AN “AS IS” AND “AS AVAILABLE” BASIS.
                COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS
                TO THE OPERATION OF THEIR SERVICES, OR THE INFORMATION, CONTENT OR MATERIALS
                INCLUDED THEREIN. YOU EXPRESSLY AGREE THAT YOUR USE OF THESE SERVICES, THEIR
                CONTENT, AND ANY SERVICES OR ITEMS OBTAINED FROM US IS AT YOUR SOLE RISK.
              </p>
              <p className="text__gray mb-4">
                NEITHER COMPANY NOR ANY PERSON ASSOCIATED WITH COMPANY MAKES ANY WARRANTY OR
                REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY,
                ACCURACY, OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER
                COMPANY NOR ANYONE ASSOCIATED WITH COMPANY REPRESENTS OR WARRANTS THAT THE SERVICES,
                THEIR CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE SERVICES WILL BE
                ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED,
                THAT THE SERVICES OR THE SERVER THAT MAKES IT AVAILABLE ARE FREE OF VIRUSES OR OTHER
                HARMFUL COMPONENTS OR THAT THE SERVICES OR ANY SERVICES OR ITEMS OBTAINED THROUGH
                THE SERVICES WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS.
              </p>
              <p className="text__gray mb-4">
                COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
                MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
              </p>
              <p className="text__gray mb-4">
                THE FOREGOING DOES NOT AFFECT ANY WARRANTIES WHICH CANNOT BE EXCLUDED OR LIMITED
                UNDER APPLICABLE LAW.
              </p>
            </li>
            <li>
              <label className="terms__label">Limitation Of Liability</label>
              <p className="text__gray mb-4">
                EXCEPT AS PROHIBITED BY LAW, YOU WILL HOLD US AND OUR OFFICERS, DIRECTORS,
                EMPLOYEES, AND AGENTS HARMLESS FOR ANY INDIRECT, PUNITIVE, SPECIAL, INCIDENTAL, OR
                CONSEQUENTIAL DAMAGE, HOWEVER IT ARISES (INCLUDING ATTORNEYS{"'"} FEES AND ALL
                RELATED COSTS AND EXPENSES OF LITIGATION AND ARBITRATION, OR AT TRIAL OR ON APPEAL,
                IF ANY, WHETHER OR NOT LITIGATION OR ARBITRATION IS INSTITUTED), WHETHER IN AN
                ACTION OF CONTRACT, NEGLIGENCE, OR OTHER TORTIOUS ACTION, OR ARISING OUT OF OR IN
                CONNECTION WITH THIS AGREEMENT, INCLUDING WITHOUT LIMITATION ANY CLAIM FOR PERSONAL
                INJURY OR PROPERTY DAMAGE, ARISING FROM THIS AGREEMENT AND ANY VIOLATION BY YOU OF
                ANY FEDERAL, STATE, OR LOCAL LAWS, STATUTES, RULES, OR REGULATIONS, EVEN IF COMPANY
                HAS BEEN PREVIOUSLY ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. EXCEPT AS PROHIBITED
                BY LAW, IF THERE IS LIABILITY FOUND ON THE PART OF COMPANY, IT WILL BE LIMITED TO
                THE AMOUNT PAID FOR THE PRODUCTS AND/OR SERVICES, AND UNDER NO CIRCUMSTANCES WILL
                THERE BE CONSEQUENTIAL OR PUNITIVE DAMAGES. SOME STATES DO NOT ALLOW THE EXCLUSION
                OR LIMITATION OF PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE PRIOR
                LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
              </p>
            </li>
            <li>
              <label className="terms__label">Termination</label>
              <p className="text__gray mb-4">
                We may terminate or suspend your account and bar access to Service immediately,
                without prior notice or liability, under our sole discretion, for any reason
                whatsoever and without limitation, including but not limited to a breach of Terms.
              </p>
              <p className="text__gray mb-4">
                If you wish to terminate your account, you may simply discontinue using Service.
              </p>
              <p className="text__gray mb-4">
                All provisions of Terms which by their nature should survive termination shall
                survive termination, including, without limitation, ownership provisions, warranty
                disclaimers, indemnity and limitations of liability.
              </p>
            </li>
            <li>
              <label className="terms__label">Governing Law</label>
              <p className="text__gray mb-4">
                These Terms shall be governed and construed in accordance with the laws of State of
                Delaware without regard to its conflict of law provisions.
              </p>
              <p className="text__gray mb-4">
                These Terms shall be governed and construed in accordance with the laws of State of
                Delaware without regard to its conflict of law provisions.
              </p>
            </li>
            <li>
              <label className="terms__label">Changes To Service</label>
              <p className="text__gray mb-4">
                We reserve the right to withdraw or amend our Service, and any service or material
                we provide via Service, in our sole discretion without notice. We will not be liable
                if for any reason all or any part of Service is unavailable at any time or for any
                period. From time to time, we may restrict access to some parts of Service, or the
                entire Service, to users, including registered users.
              </p>
            </li>
            <li>
              <label className="terms__label">Amendments To Terms</label>
              <p className="text__gray mb-4">
                We may amend Terms at any time by posting the amended terms on this site. It is your
                responsibility to review these Terms periodically.
              </p>
              <p className="text__gray mb-4">
                Your continued use of the Platform following the posting of revised Terms means that
                you accept and agree to the changes. You are expected to check this page frequently
                so you are aware of any changes, as they are binding on you.
              </p>
              <p className="text__gray mb-4">
                By continuing to access or use our Service after any revisions become effective, you
                agree to be bound by the revised terms. If you do not agree to the new terms, you
                are no longer authorized to use Service.
              </p>
            </li>
            <li>
              <label className="terms__label">Waiver And Severability</label>
              <p className="text__gray mb-4">
                No waiver by Company of any term or condition set forth in Terms shall be deemed a
                further or continuing waiver of such term or condition or a waiver of any other term
                or condition, and any failure of Company to assert a right or provision under Terms
                shall not constitute a waiver of such right or provision.
              </p>
              <p className="text__gray mb-4">
                If any provision of Terms is held by a court or other tribunal of competent
                jurisdiction to be invalid, illegal or unenforceable for any reason, such provision
                shall be eliminated or limited to the minimum extent such that the remaining
                provisions of Terms will continue in full force and effect.
              </p>
            </li>
            <li>
              <label className="terms__label">Acknowledgement</label>
              <p className="text__gray mb-4">
                BY USING SERVICE OR OTHER SERVICES PROVIDED BY US, YOU ACKNOWLEDGE THAT YOU HAVE
                READ THESE TERMS OF SERVICE AND AGREE TO BE BOUND BY THEM.
              </p>
            </li>
            <li>
              <label className="terms__label">Contact Us</label>
              <p className="text__gray mb-4">
                Please send your feedback, comments, requests for technical support: By email:{' '}
                <a href="mailto:info@hometour.com" className="text__blue">
                  info@hometour.com
                </a>
                .
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;
