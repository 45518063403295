import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import TourOptions from './tourOptions';
import ClientInfo from './clientInfo';
import { TourOption } from '../../../../../state/subdomain/tour/tour.types';
import { TOUR_TYPES } from '../../../../../common/constants';
import { useHistory } from 'react-router';

interface Props {
  setData: (e: TourOption) => void;
}
const SelectTour: React.FC<Props> = props => {
  const history = useHistory();
  const { setData } = props;
  const [option, setOption] = useState('' as TOUR_TYPES);
  const [info, setInfo] = useState('');

  const nextStep = () => {
    setData({
      tourType: option,
      clientInfo: info,
    });
  };

  return (
    <div className="tour--canvas">
      <div className="white--rounded-box">
        <div className="ct--head mb-3">
          <h3 className="dashboard__heading font-semibold mb-0">Create Tour</h3>
          <span className="steps__text">Step 1/2</span>
        </div>
        <p className="para__lg">Determine the tour information & settings</p>
        <div className="d-inline-block w-100 mt-4 pt-2">
          <p className="para__md-dark mb-3 pb-1">What kind of tour would you like to create?</p>
          <TourOptions setType={setOption} />
        </div>
        <div className="d-inline-block w-100 mt-5">
          <p className="para__md-dark mb-3 pb-1">Client’s Information (Optional)</p>
          <ClientInfo setInfo={setInfo} />
          <div
            className="tour__btn-canvas ct__btn-canvas d-flex flex-column-reverse flex-sm-row align-items-center
                 justify-content-between"
          >
            <Button
              type="button"
              className="outline__btn-gray outline__btn-gray-md outline__btn-radius"
              onClick={() => history.push('/dashboard')}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              className="admin__button admin__button-sm admin__button-radius mb-3 mb-sm-0"
              onClick={nextStep}
              disabled={!option.length}
            >
              Continue <i className="icon-arrow-right"></i>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTour;
