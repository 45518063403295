import React, { useEffect } from 'react';
import useForm from 'react-hook-form';
import { Form, Button } from 'react-bootstrap';
import { useLogin, useLogout } from '../../state/login/login.hook';
import { useHistory, useLocation } from 'react-router';
import { LoginFormFields, LoginFormSchema } from '../../state/login/forms/login.form';
import { errorExtractor } from '../../utils/error.utils';
import SmallLoader from '../common/small.loader';
import { showNotification } from '../../utils/misc.utils';
import { useMe } from '../../state/me/me.hook';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import Loader from '../common/loader';

const LoginForm: React.FC = () => {
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const { register, handleSubmit, errors } = useForm({
    validationSchema: LoginFormSchema,
  });
  const history = useHistory();
  const { data: meData, loading: meLoading } = useMe();
  const { logOut } = useLogout();

  useEffect(() => {
    if (meData) {
      if (meData?.role === 'super_admin') {
        showNotification('warning', 'Not Allowed', 'Super Admin login is not allowed');
        logOut();
      } else if (queryParams && queryParams.return_url) {
        try {
          const returnUrl = new URL(queryParams.return_url as string);
          window.location.href = returnUrl.href;
        } catch (e) {
          console.error(e);
          history.push('/dashboard');
        }
      } else {
        history.push('/dashboard');
      }
    }
  }, [meData]);

  const { res: loginResponse, login } = useLogin();

  const onSubmit = (data: any, e: any): void => {
    e.preventDefault();
    login(data);
  };

  useEffect(() => {
    if (!loginResponse.loading && loginResponse.data) {
      if (loginResponse.data?.role === 'super_admin') {
        showNotification('warning', 'Not Allowed', 'Super Admin login is not allowed');
        logOut();
      } else if (queryParams && queryParams.return_url) {
        try {
          const returnUrl = new URL(queryParams.return_url as string);
          window.location.href = returnUrl.href;
        } catch (e) {
          console.error(e);
          history.push('/dashboard');
        }
      } else {
        history.push('/dashboard');
      }
    }
    if (!loginResponse.loading && loginResponse.error) {
      showNotification('error', 'Error', errorExtractor(loginResponse.error));
    }
  }, [loginResponse]);

  return (
    <React.Fragment>
      {meLoading && <Loader />}
      <Form className="mt-sixty" onSubmit={handleSubmit(onSubmit)}>
        <Form.Group className="forms--group">
          <Form.Control
            name={LoginFormFields.EMAIL}
            ref={register}
            type="email"
            placeholder="Email"
            className={errors[LoginFormFields.EMAIL] ? 'error' : ''}
          />
          {errors[LoginFormFields.EMAIL] && (
            <span className="error__msg">{errors[LoginFormFields.EMAIL]?.message}</span>
          )}
        </Form.Group>
        <Form.Group className="forms--group mb-4 pb-1">
          <Form.Control
            name={LoginFormFields.PASSWORD}
            ref={register}
            type="password"
            placeholder="Password"
            className={errors[LoginFormFields.PASSWORD] ? 'error' : ''}
          />
          {errors[LoginFormFields.PASSWORD] && (
            <span className="error__msg">{errors[LoginFormFields.PASSWORD]?.message}</span>
          )}
        </Form.Group>
        <div className="form--option formOpt--terms pt-3">
          <label className="checkbox--set">
            Remember Me
            <input type="checkbox" />
            <span className="checkmark"></span>
          </label>
          <div className="form--opt-r">
            <p className="form__text mb-0 mr-3">Trouble logging in?</p>
            <Link to="/forgot" className="form__link form__link-underline">
              Reset my password
            </Link>
          </div>
        </div>
        <div className="text-sm-right forms--btn-canvas signin--btn-canvas">
          <Button
            type="submit"
            className="app__button underline__button"
            disabled={loginResponse.loading || meLoading}
          >
            {loginResponse.loading ? (
              <React.Fragment>
                Signing in <SmallLoader />
              </React.Fragment>
            ) : (
              <React.Fragment>
                Sign in <i className="icon-user-right ml-3"></i>
              </React.Fragment>
            )}
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default LoginForm;
