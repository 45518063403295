import React, { useEffect, useState } from 'react';
import { Navbar, Form, Button, Nav, Accordion, Card } from 'react-bootstrap';
import useForm from 'react-hook-form';
import InputMask from 'react-input-mask';
import { LandingFormFields, LandingFormSchema } from '../../state/landing/forms/landing.form';
import { useScheduleDemo } from '../../state/landing/landing.hook';
import { showNotification } from '../../utils/misc.utils';
import { errorExtractor } from '../../utils/error.utils';
import { cleanObject } from '../../utils/apiValidatior';
import { ScheduleDemoInterface } from '../../state/landing/landing.types';
import SmallLoader from '../../components/common/small.loader';
import ReactPlayer from 'react-player/lazy';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

const LandingPage: React.FC = () => {
  const history = useHistory();
  const { register, handleSubmit, errors, reset, setValue } = useForm({
    validationSchema: LandingFormSchema,
  });
  const [step, setStep] = useState(1);
  const [playing, setPlaying] = useState(false);
  const { res: scheduleDemoResponse, scheduleDemo } = useScheduleDemo();

  useEffect(() => {
    if (!scheduleDemoResponse.loading && scheduleDemoResponse.data) {
      showNotification('success', 'Demo Request Received', 'Your demo will be scheduled shortly!');
      reset();
    }
    if (!scheduleDemoResponse.loading && scheduleDemoResponse.error) {
      showNotification('error', 'Error', errorExtractor(scheduleDemoResponse.error));
    }
  }, [scheduleDemoResponse]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    scheduleDemo((cleanObject(data) as unknown) as ScheduleDemoInterface);
  };

  return (
    <div className="off--canvas">
      <Navbar sticky="top" expand="lg" className="navbar__header landing__header landing__header-n">
        <div className="container-fluid fluid--expand">
          <Navbar.Brand href="/">
            <Link to="/">
              <img src="/images/HomeTour_Dark_Logo.svg" alt="Logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="toggler--n">
            <span></span>
            <span></span>
            <span></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar__list navbar__list-n">
              <Nav.Link href="#how-it-works">How it works</Nav.Link>
              <Link to="/about" className="nav-link">
                About
              </Link>
            </Nav>
            <div className="navbar--btns">
              <Button
                onClick={() => {
                  // history.push('/login')
                  window.open("https://app.hometour.com/login", "_blank") 
                }}
                className="schedule__btn-outline button__radius"
              >
                Sign In
                <span className="key__span">
                  <i className="icon-login"></i>
                </span>
              </Button>
              {/*<Button onClick={() => history.push('/tenant')} className="schedule__btn-outline">
                For Tenants{' '}
                <span className="key__span">
                  <i className="icon-key"></i>
                </span>
              </Button>*/}
            </div>
          </Navbar.Collapse>
        </div>
      </Navbar>
      <div className="component--top pearl--bg">
        <div className="container container--expand">
          <div className="text-center">
            <h1 className="landing__heading">Live Virtual Tours on Demand.</h1>
            <p className="landing__para mb-4">
              We enable landlord and tenant brokers to meet on a unified virtual platform
            </p>
            <a className="schedule__btn button__radius" href="#demo">
              Schedule a Demo
            </a>
          </div>
          <div className="row vt--demand-content">
            <div className="col-12 col-xl-8">
              <div className="broker--video">
                <div className="video--bar">
                  <img src="/images/browser-bar.svg" alt="Img" />
                </div>

                <div className={step === 1 ? 'video-placement' : 'video-placement d-none'}>
                  <div className="broker--video-placement">
                    <img src="/images/LiveTour_Small.gif" alt="Img" />
                  </div>
                </div>

                <div className={step === 2 ? 'video-placement' : 'video-placement d-none'}>
                  <div className="broker--video-placement">
                    <img src="/images/BetterExperience_Small.gif" alt="Img" />
                  </div>
                </div>

                <div className={step === 3 ? 'video-placement' : 'video-placement d-none'}>
                  <div className="broker--video-placement">
                    <img src="/images/subdomain-3.png" alt="Img" />
                  </div>
                  <div className="vp__box"></div>
                  <div className="vp__logo">
                    <img src="/images/admin-logo-blue.svg" alt="Img" />
                  </div>
                  <div className="vp__secure">
                    <span className="vp__secure-icon">
                      <i className="icon-lock-fill"></i>
                    </span>
                    jbgsmith.hometour.com
                  </div>
                </div>

                {/*<div className={step === 4 ? 'video-placement' : 'video-placement d-none'}>
                  <div className="broker--video-placement">
                    <ReactPlayer
                      url="https://spacetrics.wistia.com/medias/jn0ephoy54"
                      playing={playing}
                      onPlay={() => setPlaying(true)}
                      onPause={() => setPlaying(false)}
                      width="100%"
                      height="100%"
                      config={{
                        wistia: {
                          options: {
                            playerColor: '#15235C',
                          },
                        },
                      }}
                    />
                  </div>
                </div>*/}
              </div>
            </div>
            <div className="col-12 col-xl-4">
              <Accordion defaultActiveKey="0" className="broker--accordian pl-xl-3">
                <Card className="broker--card">
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="0"
                      onClick={() => setStep(1)}
                    >
                      <span className="bc__icon">
                        <img src="/images/check-circle.svg" alt="Img" />
                      </span>
                      Live Broker Virtual Tours
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p className="bc__paragraph">
                        Brokers can host face-to-face virtual tours with prospective clients.
                        Clients meet with the broker virtually without downloading software and with
                        a private unique web link.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="broker--card">
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="1"
                      onClick={() => setStep(2)}
                    >
                      <span className="bc__icon">
                        <img src="/images/check-circle.svg" alt="Img" />
                      </span>
                      Better Experience
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p className="bc__paragraph">
                        With our technology everyone can be on the same page - the broker leads the
                        tour as prospective clients watch, respond and ask any questions.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card className="broker--card">
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="2"
                      onClick={() => setStep(3)}
                    >
                      <span className="bc__icon">
                        <img src="/images/check-circle.svg" alt="Img" />
                      </span>
                      White Labeled
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <p className="bc__paragraph">
                        We will customize your interface to reflect your identity with your branding
                        and attaching relevant collateral. With a SEO optimized subdomain for your
                        commercial portfolio, {`you'll`} be able to target your marketing campaigns
                        for maximum results
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                {/*<Card className="broker--card broker--card-demo">
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey="3"
                      onClick={() => {
                        setStep(4);
                        setPlaying(prevState => !prevState);
                      }}
                    >
                      {!playing && (
                        <span className="bc__icon">
                          <img src="/images/play-circle.svg" alt="Img" />
                        </span>
                      )}
                      {playing && (
                        <span className="bc__icon">
                          <img src="/images/stop-circle.svg" alt="Img" />
                        </span>
                      )}
                      {playing ? 'Stop Playing' : 'Play Video Demo'}
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body></Card.Body>
                  </Accordion.Collapse>
                </Card>*/}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      <div className="info--component">
        <div className="container container--expand" id="how-it-works">
          <h2 className="heading__component heading__component-blue">Close more deals faster.</h2>
          <div className="info--content">
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="info--block">
                  <div className="info--img">
                    <img src="/images/upload-detail.svg" alt="Img" />
                  </div>
                  <h3 className="heading__medium heading__medium-blue">Upload Details & Media</h3>
                  <p className="steps__paragraph">
                    Create a listing with all of your space details. In addition upload photos,
                    floor-plans, renderings & Matterport tours.
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="info--block">
                  <div className="info--img">
                    <img src="/images/submit-review.svg" alt="Img" />
                  </div>
                  <h3 className="heading__medium heading__medium-blue">Submit & Review</h3>
                  <p className="steps__paragraph">
                    After, creating you listing within 3 days you receive an overview video with a
                    professional voice over for you to review.
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="info--block">
                  <div className="info--img">
                    <img src="/images/approve.svg" alt="Img" />
                  </div>
                  <h3 className="heading__medium heading__medium-blue">Approve & Go Live</h3>
                  <p className="steps__paragraph">
                    Tenants now will be able to find the listing, watch the video and schedule a
                    live broker tour on your own premium subdomain.
                  </p>
                </div>
              </div>
            </div>
            <div className="text-center mt-5 pt-4">
              <a className="schedule__btn schedule__btn-n button__radius" href="#demo">
                Schedule a Demo
              </a>
            </div>
          </div>
          <div id="demo"></div>
        </div>
      </div>
      {/*<div className="opinion--component">
        <div className="container container--expand">
          <div className="opinion--canvas">
            <h2 className="heading__component heading__component-white">
              What others in the industry are saying
            </h2>
            <div className="opinion--content">
              <div className="opinion--block">
                <div className="opinion--img">
                  <img src="/images/alex-hancock.jpg" alt="Img" />
                </div>
                <p className="opinion__para">
                  <i className="icon-quote-left"></i>
                  With HomeTour my tenants can tour office space within a platform that encourages
                  robust and informative conversations amongst all parties. I’ve found HomeTour’s
                  platform to be incredibly powerful through it’s simplicity.
                </p>
                <div className="op--info">
                  <h6 className="op--name">Alex Hancock</h6>
                  <h6 className="op--name">Senior Vice President</h6>
                </div>
                <a className="op__logo">
                  <img src="/images/transwestern-logo.png" alt="Img" />
                </a>
              </div>
            </div>
          </div>
          
        </div>
      </div>*/}
      <div className="personalize--component pearl--bg">
        <div className="container container--expand">
          <div className="text-center">
            <div className="personalize--block">
              <h2 className="heading__component heading__component-black">
                Get a personalized quote and demo of the platform
              </h2>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row row--space-10">
                  <div className="col-12 col-md-6">
                    <Form.Group className="forms--group forms--group-radius">
                      <Form.Control
                        type="text"
                        placeholder="First name*"
                        ref={register}
                        name={LandingFormFields.FIRST_NAME}
                        className={errors[LandingFormFields.FIRST_NAME] ? 'error' : ''}
                      />
                      {errors[LandingFormFields.FIRST_NAME] && (
                        <span className="error__msg">
                          {errors[LandingFormFields.FIRST_NAME]?.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Group className="forms--group forms--group-radius">
                      <Form.Control
                        type="text"
                        placeholder="Last name*"
                        ref={register}
                        name={LandingFormFields.LAST_NAME}
                        className={errors[LandingFormFields.LAST_NAME] ? 'error' : ''}
                      />
                      {errors[LandingFormFields.LAST_NAME] && (
                        <span className="error__msg">
                          {errors[LandingFormFields.LAST_NAME]?.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Group className="forms--group forms--group-radius">
                      <Form.Control
                        type="email"
                        placeholder="Email*"
                        ref={register}
                        name={LandingFormFields.EMAIL}
                        className={errors[LandingFormFields.EMAIL] ? 'error' : ''}
                      />
                      {errors[LandingFormFields.EMAIL] && (
                        <span className="error__msg">
                          {errors[LandingFormFields.EMAIL]?.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Group className="forms--group forms--group-radius">
                      <InputMask
                        type="text"
                        placeholder="Phone Number"
                        inputRef={register}
                        name={LandingFormFields.PHONE}
                        onChange={(e: any) => {
                          setValue(LandingFormFields.PHONE, e.target.value, true);
                        }}
                        className={
                          errors[LandingFormFields.PHONE] ? 'form-control error' : 'form-control'
                        }
                        mask="(999) 999-9999"
                        maskChar="_"
                      />
                      {errors[LandingFormFields.PHONE] && (
                        <span className="error__msg">
                          {errors[LandingFormFields.PHONE]?.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Group className="forms--group forms--group-radius">
                      <Form.Control
                        type="text"
                        placeholder="Company Name*"
                        ref={register}
                        name={LandingFormFields.COMPANY_NAME}
                        className={errors[LandingFormFields.COMPANY_NAME] ? 'error' : ''}
                      />
                      {errors[LandingFormFields.COMPANY_NAME] && (
                        <span className="error__msg">
                          {errors[LandingFormFields.COMPANY_NAME]?.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Group className="forms--group forms--group-radius">
                      <Form.Control
                        type="text"
                        placeholder="Total Portfolio SF"
                        ref={register}
                        name={LandingFormFields.TOTAL_PORTFOLIO}
                        className={errors[LandingFormFields.TOTAL_PORTFOLIO] ? 'error' : ''}
                      />
                      {errors[LandingFormFields.TOTAL_PORTFOLIO] && (
                        <span className="error__msg">
                          {errors[LandingFormFields.TOTAL_PORTFOLIO]?.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Group className="forms--group forms--group-radius">
                      <Form.Control
                        type="text"
                        placeholder="Number of Listings"
                        ref={register}
                        name={LandingFormFields.LISTINGS_COUNT}
                        className={errors[LandingFormFields.LISTINGS_COUNT] ? 'error' : ''}
                      />
                      {errors[LandingFormFields.LISTINGS_COUNT] && (
                        <span className="error__msg">
                          {errors[LandingFormFields.LISTINGS_COUNT]?.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-12 col-md-6">
                    <Form.Group className="forms--group forms--group-radius">
                      <Form.Control
                        type="text"
                        placeholder="Number of Brokers"
                        ref={register}
                        name={LandingFormFields.BROKERS_COUNT}
                        className={errors[LandingFormFields.BROKERS_COUNT] ? 'error' : ''}
                      />
                      {errors[LandingFormFields.BROKERS_COUNT] && (
                        <span className="error__msg">
                          {errors[LandingFormFields.BROKERS_COUNT]?.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <Form.Group className="forms--group select--group forms--group-radius">
                      <Form.Control
                        as="select"
                        className={errors[LandingFormFields.MATTER_PORT] ? 'error' : ''}
                        name={LandingFormFields.MATTER_PORT}
                        ref={register}
                      >
                        <option value="">Do you use MATTERPORT?</option>
                        <option value="yes">Yes, I already use MATTERPORT</option>
                        <option value="neutral">No, but I am interested on getting into it</option>
                        <option value="no">I don’t know what MATTERPORT is</option>
                      </Form.Control>
                      <span className="select__caret-down">
                        <i className="icon-caret-down"></i>
                      </span>
                      {errors[LandingFormFields.MATTER_PORT] && (
                        <span className="error__msg">
                          {errors[LandingFormFields.MATTER_PORT]?.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                  <div className="col-12">
                    <button
                      type="submit"
                      className="schedule__btn schedule__btn-n w-100 button__radius"
                      disabled={scheduleDemoResponse.loading}
                    >
                      {scheduleDemoResponse.loading ? 'Submitting' : 'Submit'}
                      {scheduleDemoResponse.loading && <SmallLoader />}
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer footer-n mt-0">
        <div className="container-fluid fluid--expand">
          <div className="footer--top d-flex flex-column flex-md-row align-items-center justify-content-between">
            <div className="ft--f d-flex flex-column flex-sm-row align-items-center">
              <a className="footer__logo d-inline-block mb-3 mb-sm-0">
                <img src="/images/HomeTour_White_Icon.svg" alt="Logo" />
              </a>
              <span className="footer--copyright mb-3 mb-sm-0">
                <i className="icon-copyright"></i> 2020 HomeTour. All Rights Reserved
              </span>
            </div>
            {/*<ul className="social__list social__list-f">
              <li>
                <a
                  href="https://www.instagram.com/officetour/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/office-tour-a551941aa/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon-linkedin-square"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.facebook.com/OfficeTour-107015984357012"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon-facebook-square"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/officetourhq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="icon-twitter"></i>
                </a>
              </li>
            </ul>*/}
          </div>
          <div className="footer--bottom d-flex flex-column flex-md-row align-items-center justify-content-between">
            <ul className="f__list f__list-left">
              <li>
                <a href="#how-it-works">How it works</a>
              </li>
              {/*<li>
                <Link to="/news">News</Link>
              </li>*/}
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <a href="/about#contact">Contact</a>
              </li>
            </ul>
            <ul className="f__list f__list-right">
              <li>
                <Link to="/privacy">Privacy</Link>
              </li>
              <li>
                <Link to="/terms">Terms</Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;
