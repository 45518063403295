import React from 'react';
import * as yup from 'yup';

const requiredField = 'This field is required';

export enum About {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  message = 'message',
}
export const AboutFormSchema = yup.object().shape({
  [About.firstName]: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
    .required(requiredField),
  [About.lastName]: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
    .required(requiredField),
  [About.message]: yup
    .string()
    .trim()
    .required(requiredField),
  [About.email]: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required(requiredField),
});

export const errorMsg = (error: any) => {
  return error ? <p className="error__msg">{error.message}</p> : null;
};
