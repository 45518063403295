import React, { useEffect, useState, useCallback } from 'react';
import { GetListingObject, Building } from '../../../../state/subdomain/listing/listing.types';
import { UploadedMediaInterface } from '../../../../state/media/media.types';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';

interface Props {
  listing: GetListingObject;
  subMarket: (id: string) => string;
}

const TourSingleListing: React.FC<Props> = (props: Props) => {
  const { listing, subMarket } = props;

  const [mainImage, setImage] = useState({} as UploadedMediaInterface);
  const [building, setBuilding] = useState({} as Building);

  const listingTitle = useCallback((listing: GetListingObject): string => {
    const streetAddress = listing.building?.location?.split(',')[0];
    return `${streetAddress} ${listing.suiteNo}`;
  }, []);

  useEffect(() => {
    setBuilding(listing.building);
    if (listing.media) {
      let image = listing.media.find(image => image.isPrimary === true);
      if (!image) image = listing.media[0];
      setImage(image);
    }
  }, [listing]);

  return (
    <div className="ls--box">
      <div className="ls--box-img">
        <img
          className="img--cover"
          src={compileAvatarUrl(listing.media ? mainImage.url : '')}
          alt={mainImage.name}
        />
      </div>
      <div className="ls--box-detail">
        <h6 className="ls__listing-heading">{listingTitle(listing)}</h6>
        <div className="al--listing">
          <span className="al__icon">
            <i className="icon-map-marker-light"></i>
          </span>
          <span className="al__text">
            {building.subMarket ? subMarket((building.subMarket as unknown) as string) : ''}
          </span>
        </div>
        <div className="al--listing-combine d-flex">
          <div className="al--listing">
            <span className="al__icon">
              <i className="icon-size"></i>
            </span>
            <span className="al__text alText__detail">{listing.size} SF</span>
          </div>
          {listing.monthlyRent && (
            <div className="al--listing">
              <span className="al__icon">
                <i className="icon-month"></i>
              </span>
              <span className="al__text alText__detail">${listing.monthlyRent}/SF/YR</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TourSingleListing;
