import * as yup from 'yup';

export enum ProfileInfoFormFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
}

export const ProfileInfoFormSchema = yup.object().shape({
  [ProfileInfoFormFields.FIRST_NAME]: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
    .required('This field is required'),
  [ProfileInfoFormFields.LAST_NAME]: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
    .required('This field is required'),
});
