import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  onSubmit: (e: string) => void;
}
const BrokerSearchBox: React.FC<Props> = props => {
  const { onSubmit: onSearchChange } = props;

  return (
    <Form.Group className="form--group-alt search--group-alt">
      <Form.Control
        type="search"
        placeholder="Type broker name here"
        onChange={e => onSearchChange(e.target.value)}
      />
      <span className="search__icon">
        <i className="icon-search"></i>
      </span>
    </Form.Group>
  );
};

export default BrokerSearchBox;
