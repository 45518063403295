import React from 'react';
import { Dropdown, Form } from 'react-bootstrap';
import { COLOR_OPTIONS } from '../../common/constants';
import { SettingsDataInterface, SettingsErrorInterface } from '../../state/setting/setting.types';

interface Props {
  data: SettingsDataInterface;
  setData: (newData: SettingsDataInterface) => void;
  setSettingsData: (field: string, value: any) => void;
  errors: SettingsErrorInterface;
  setErrors: (newErrors: SettingsErrorInterface) => void;
  setSettingsError: (field: string, value: string) => void;
}

const PlatformInfo: React.FC<Props> = (props: Props) => {
  const onColorSelect = (selectedColor: string, colorType: 'primaryColor' | 'secondaryColor') => {
    props.setSettingsData(colorType, selectedColor);
    if (selectedColor) {
      props.setSettingsError(colorType, '');
    } else {
      props.setSettingsError(colorType, 'This field is required');
    }
  };

  const findColor = (colorCode: string, returnKey: 'name' | 'value' | 'class') => {
    const color = COLOR_OPTIONS.find(color => color.value === colorCode);
    if (color) {
      return color[returnKey];
    }
    return undefined;
  };

  return (
    <div className="adminSettings--row border-bottom-0 mb-0 pb-0">
      <div className="adminSettings--head">
        <span className="adminSettings__icon">
          <i className="icon-platform"></i>
        </span>
        <span className="adminSettings__text">Platform Interface</span>
      </div>
      <div className="row row--space-12">
        <div className="col-12 col-sm-6">
          <Form.Label>Primary Color*</Form.Label>
          <Dropdown className="listing--dropdown listing--dropdown-pad interface--dropdown">
            <Dropdown.Toggle id="dropdown-basic" className="color__item">
              {props.data?.primaryColor && (
                <React.Fragment>
                  {findColor(props.data.primaryColor, 'class') && (
                    <span className={`color__box ${findColor(props.data.primaryColor, 'class')}`}>
                      text
                    </span>
                  )}
                </React.Fragment>
              )}
              {props.data?.primaryColor ? (
                <span className="color__text">
                  {findColor(props.data.primaryColor, 'name')
                    ? findColor(props.data.primaryColor, 'name')
                    : 'Primary Color*'}
                </span>
              ) : (
                'Primary Color *'
              )}
              <span className="select__caret-down">
                <i className="icon-caret-down"></i>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {COLOR_OPTIONS.map((color: any, index: number) => {
                return (
                  <Dropdown.Item
                    key={index}
                    className="color__item"
                    eventKey={color.value}
                    onSelect={(eventKey: string) => onColorSelect(eventKey, 'primaryColor')}
                  >
                    <span className={`color__box ${color.class}`}>text</span>
                    <span className="color__text">{color.name}</span>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="col-12 col-sm-6">
          <Form.Label>Secondary Color*</Form.Label>
          <Dropdown className="listing--dropdown listing--dropdown-pad interface--dropdown">
            <Dropdown.Toggle id="dropdown-basic" className="color__item">
              {props.data?.secondaryColor && (
                <React.Fragment>
                  {findColor(props.data.secondaryColor, 'class') && (
                    <span className={`color__box ${findColor(props.data.secondaryColor, 'class')}`}>
                      text
                    </span>
                  )}
                </React.Fragment>
              )}
              {props.data?.secondaryColor ? (
                <span className="color__text">
                  {findColor(props.data.secondaryColor, 'name')
                    ? findColor(props.data.secondaryColor, 'name')
                    : 'Secondary Color*'}
                </span>
              ) : (
                'Secondary Color *'
              )}
              <span className="select__caret-down">
                <i className="icon-caret-down"></i>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {COLOR_OPTIONS.map((color: any, index: number) => {
                return (
                  <Dropdown.Item
                    key={index}
                    className="color__item"
                    eventKey={color.value}
                    onSelect={(eventKey: string) => onColorSelect(eventKey, 'secondaryColor')}
                  >
                    <span className={`color__box ${color.class}`}>text</span>
                    <span className="color__text">{color.name}</span>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default PlatformInfo;
