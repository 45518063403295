import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { startCase } from 'lodash';
import { AppStateInterface } from '../../../../state';
import { GetListingObject, Building } from '../../../../state/listing/listing.types';
import { showNotification } from '../../../../utils/misc.utils';
import { errorExtractor } from '../../../../utils/error.utils';
import ListingDocs from './pdf';
import ListingVideo from './video';
import ListingDetails from './detail';
import ListingBrokers from './brokers';
import ListingGallery from './gallery';
import BuildingDetail from './building';
import ListingHandlers from './handlers';
import BuildingLocation from './location';
import DeleteWarning from '../../../modals/deleteWarning';
import QuillPad from '../../../common/quillPad';
import { LISTING_STATUS, VIDEO_STATUS } from '../../../../common/constants';
import { useChangeListingStatus } from '../../../../state/listing/listing.hook';
import SmallLoader from '../../../common/small.loader';

interface Props {
  id: string;
}
const ListingDetail: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const listingInfo = useSelector((state: AppStateInterface) => state.listing.singleListing);
  const { res: changeStatusRes, changeStatus } = useChangeListingStatus();

  const [listingId, setListingId] = useState('');
  const [building, setBuilding] = useState({} as Building);
  const [listing, setListing] = useState({} as GetListingObject);
  const [showDelModal, handleDelModal] = useState(false);

  useEffect(() => setListingId(props.id), [props]);
  useEffect(() => {
    if (listingInfo.hasData && !listingInfo.loading) {
      if (listingInfo.data) {
        setListing(listingInfo.data);
        if (listingInfo.data.building) setBuilding(listingInfo.data.building);
      } else if (listingInfo.error) {
        showNotification('error', 'Error', errorExtractor(listingInfo.error));
      }
    }
  }, [listingInfo]);

  const reviewStatus =
    listing.status === LISTING_STATUS.REVIEW &&
    listing.videoStatus === VIDEO_STATUS.VOICEOVER_SUBMITTED;
  const publishStatus = listing.status === LISTING_STATUS.PUBLISHED;
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-12 col-xl-6">
          <div className="listing--head">
            <div className="heading--group position-relative mt-1">
              <h3 className="dashboard__heading mb-0">
                {building?.location?.split(',')[0]} - {listing.suiteNo}
              </h3>
              <a
                className="heading__edit"
                onClick={() => history.push(`/listing/${listingId}/edit`)}
              >
                <i className="icon-edit-alt"></i>
              </a>
              <a className="heading__edit heading__trash" onClick={() => handleDelModal(true)}>
                <i className="icon-trash"></i>
              </a>
            </div>
            <a className={`list__button list__button-${listing.status} ml-auto mt-md-2`}>
              {startCase(listing.status)}
            </a>
          </div>
          {reviewStatus && (
            <p className="small__paragraph description__para">
              This video is being edited with the feedback you have submitted. You will be notified
              when we upload a new version for you to review.
            </p>
          )}
          {publishStatus && (
            <button
              className="outline__button outline__button-sm mb-4 mt-lg-n1"
              onClick={() => changeStatus(listing._id, LISTING_STATUS.REVIEW)}
              disabled={changeStatusRes.loading}
            >
              {changeStatusRes.loading ? 'Unpublishing' : 'Unpublish Listing'}
              {changeStatusRes.loading && <SmallLoader />}
            </button>
          )}
        </div>
      </div>
      <div className="row mb-4 row--space-10">
        <ListingVideo info={listing} />
        <div className={`col-12 col-lg-6 ${(reviewStatus || publishStatus) && 'unpublished--col'}`}>
          <ListingBrokers info={listing} />
          <ListingHandlers info={listing} />
          <ListingDetails info={listing} />
        </div>
      </div>
      <div className="row row--space-10">
        <div className="col-12 col-lg-6">
          <h3 className="heading__medium heading__medium-black mb-4">Gallery</h3>
          <div className="listings listing-component">
            <ListingGallery info={listing} />
          </div>
          <h3 className="heading__medium heading__medium-black mb-4">Description</h3>
          <div className="listing-component">
            <QuillPad
              element="p"
              content={listing.description}
              className="small__paragraph description__para"
            />
          </div>
          <h3 className="heading__medium heading__medium-black mb-4 pb-2">
            Building Specifications
          </h3>
          <div className="spec--component listing-component">
            <BuildingDetail info={building} />
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="listing--location listing-component">
            {building.fullLocation && <BuildingLocation info={building} />}
            {listing.pdfs && listing.pdfs.length > 0 && <ListingDocs info={listing.pdfs} />}
          </div>
        </div>
      </div>
      {showDelModal && (
        <DeleteWarning
          id={listing._id}
          backId={building._id}
          name={`${building?.location?.split(',')[0]} - ${listing.suiteNo}`}
          type="listing"
          handleCancel={() => handleDelModal(false)}
        />
      )}
    </React.Fragment>
  );
};

export default ListingDetail;
