import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useForm from 'react-hook-form';
import { Form, Button } from 'react-bootstrap';
import { AppStateInterface } from '../../../state';
import { JoinTourFields, JoinTourFormSchema } from '../../../state/subdomain/tour/join.form';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import { JOIN_TOUR_WISTIA_ID, NODE_ENV, SEPARATORS } from '../../../common/constants';
import VideoModal from './videoModal';
import env from '../../../utils/env.utils';
import { FullStoryAPI } from 'react-fullstory';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import { IntercomAPI } from 'react-intercom';
import withUnsupportedBrowserAlertForParticipant from './withUnsupportedBrowserAlertForParticipant';
import { BrowserInterface } from '../../../state/subdomain/tour/tour.types';

interface Props {
  title: string;
  onJoin: (name: string, email: string) => void;
  leaveCall: () => void;
  browser: BrowserInterface | null;
}

const JoinTourTenant: React.FC<Props> = (props: Props) => {
  const { title, onJoin } = props;
  const { register, handleSubmit, errors } = useForm({
    validationSchema: JoinTourFormSchema,
  });
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );
  const [showModal, setShowModal] = useState<boolean>(false);

  const onSubmit = (data: any, e: any): void => {
    e.preventDefault();
    if (env.nodeEnv === NODE_ENV.PRODUCTION) {
      FullStoryAPI('identify', false, {
        email: data.email,
        displayName: data.name,
      });
      IntercomAPI('update', {
        name: data.name,
        email: data.email,
      });
    }
    onJoin(data.name, data.email);
  };

  const redirectToSignIn = () => {
    const host = window.location.host.split(SEPARATORS.DOT);
    host.shift();
    const mainHost = host.join(SEPARATORS.DOT);
    const newPath = `${window.location.protocol}//${mainHost}/login?return_url=${window.location.href}`;
    window.location.href = newPath;
  };

  return (
    <div className="virtualTour--combine">
      <div className="container container--large">
        <a className="listing__logo vtl-logo">
          <img
            src={
              company?.settings?.darkLogo
                ? compileAvatarUrl(company.settings.darkLogo.url)
                : '/images/HomeTour_Dark_Logo.svg'
            }
            alt="Logo"
          />
        </a>
      </div>
      <div className="vt--combine">
        <div className="vt--container">
          <div className="vt--combine-main mt-3 mt-lg-0">
            <h3 className="dashboard__heading">{title}</h3>
            <div className="adminSettings--head">
              <span className="adminSettings__icon">
                <i className="icon-join"></i>
              </span>
              <span className="adminSettings__text">Join Live Virtual Tour</span>
            </div>
            <p className="schedule__paragraph text-left">
              To join this live virtual tour, type in your full name and click Join. Make sure to
              enable your camera and microphone after you join.
            </p>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="row row--space-10">
                <div className="col-12 col-md-9">
                  <div className="row row--space-10">
                    <div className="col-12 col-sm-6">
                      <Form.Group className="forms--group mb-4">
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          ref={register}
                          name={JoinTourFields.NAME}
                          className={errors[JoinTourFields.NAME] ? 'error' : ''}
                        />
                        {errors[JoinTourFields.NAME] && (
                          <p className="error__msg">{errors[JoinTourFields.NAME]?.message}</p>
                        )}
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6">
                      <Form.Group className="forms--group mb-4">
                        <Form.Control
                          type="email"
                          placeholder="Email"
                          ref={register}
                          name={JoinTourFields.EMAIL}
                          className={errors[JoinTourFields.EMAIL] ? 'error' : ''}
                        />
                        {errors[JoinTourFields.EMAIL] && (
                          <p className="error__msg">{errors[JoinTourFields.EMAIL]?.message}</p>
                        )}
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                  <Button type="submit" className="admin__button vt__btn w-100">
                    Join
                  </Button>
                </div>
              </div>
            </Form>
            <div className="video--player">
              <a className="video__link" onClick={() => setShowModal(true)}>
                <span className="video__link-icon">
                  <i className="icon-play-circle"></i>
                </span>
                <span className="video__link-text">Play Video Tutorial</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="vt--footer">
        <div className="container container--large">
          <span className="vtFooter__icon">
            <img
              src={
                company?.settings?.whiteIcon
                  ? compileAvatarUrl(company.settings.whiteIcon.url)
                  : '/images/HomeTour_White_Icon.svg'
              }
              alt="Logo"
            />
          </span>
          <span className="vtFooter__text">
            If you are on the {company?.name?.toUpperCase()} team please{' '}
            <a className="text-white" onClick={redirectToSignIn}>
              {' '}
              Sign In
            </a>{' '}
            to join the tour
          </span>
        </div>
      </div>
      <VideoModal
        show={showModal}
        videoId={JOIN_TOUR_WISTIA_ID}
        close={() => setShowModal(false)}
      />
    </div>
  );
};

export default withUnsupportedBrowserAlertForParticipant<Props>(JoinTourTenant);
