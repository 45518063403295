import React from 'react';
import { UploadedMediaInterface } from '../../../../../state/media/media.types';
import { compileAvatarUrl } from '../../../../../utils/avatar.utils';

interface Props {
  src: UploadedMediaInterface;
  onClick: (e: string) => void;
}
const ShowImage: React.FC<Props> = props => {
  const { src: image, onClick } = props;

  return (
    <div className="liveTour--gallery-col" key={image.url}>
      <div className="liveTour--gallery-img" onClick={() => onClick(image.url)}>
        <img src={compileAvatarUrl(image.url)} alt={image.name} className="img--cover" />
      </div>
    </div>
  );
};

export default ShowImage;
