import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../state';
import { startCase } from 'lodash';
import { useUploadMedia } from '../../state/media/media.hook';
import { compileAvatarUrl } from '../../utils/avatar.utils';
import SmallLoader from '../common/small.loader';
import { SettingsDataInterface, SettingsErrorInterface } from '../../state/setting/setting.types';
import useForm from 'react-hook-form';
import {
  ProfileInfoFormFields,
  ProfileInfoFormSchema,
} from '../../state/setting/forms/profileInfo.form';
import ChangePasswordModal from './changePassword';

interface Props {
  data: SettingsDataInterface;
  setData: (newData: SettingsDataInterface) => void;
  setSettingsData: (field: string, value: any) => void;
  errors: SettingsErrorInterface;
  setErrors: (newErrors: SettingsErrorInterface) => void;
  setSettingsError: (field: string, value: string) => void;
}

const ProfileInfo: React.FC<Props> = (props: Props) => {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const { register, errors, setValue } = useForm({
    validationSchema: ProfileInfoFormSchema,
    mode: 'onChange',
  });
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const { res: uploadPhotoRes, uploadSingleMedia: uploadPhoto } = useUploadMedia();

  useEffect(() => {
    setValue(ProfileInfoFormFields.FIRST_NAME, props.data?.firstName, true);
    setValue(ProfileInfoFormFields.LAST_NAME, props.data?.lastName, true);
  }, [props.data]);

  useEffect(() => {
    if (!uploadPhotoRes.loading && uploadPhotoRes.data) {
      props.setSettingsData('avatar', uploadPhotoRes.data.url);
      props.setSettingsError('avatar', '');
    }
  }, [uploadPhotoRes]);

  const onUpload = (e: any) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      uploadPhoto({ name: file.name, data: file });
      props.setSettingsData('avatar', null);
    }
  };

  useEffect(() => {
    props.setSettingsError('firstName', errors?.firstName?.message || '');
    props.setSettingsError('lastName', errors?.lastName?.message || '');
  }, [errors]);

  return (
    <React.Fragment>
      <div className="profile--info mb-48 pt-3 pt-md-0">
        <div className="adminSettings--head">
          <span className="adminSettings__icon">
            <i className="icon-user-circle"></i>
          </span>
          <span className="adminSettings__text">Profile Information</span>
        </div>
        <div className="profile--img mx-auto ml-sm-0">
          <img
            src={
              props.data?.avatar ? compileAvatarUrl(props.data.avatar) : '/images/user-default.svg'
            }
            alt="Avatar"
          />
          <label className="profile--img-upload">
            <input type="file" onChange={onUpload} disabled={uploadPhotoRes.loading} />
            <span className="imgUpload-icon">
              <i className="icon-camera-alt"></i>
            </span>
            <span className="imgUpload-text">
              {uploadPhotoRes.loading ? 'Uploading' : 'Upload Photo'}
              {uploadPhotoRes.loading && <SmallLoader />}
            </span>
          </label>
        </div>
        {props.errors?.avatar && <span className="error__msg">{props.errors?.avatar}</span>}
      </div>
      <div className="adminSettings--row">
        <div className="row row--space-12">
          <div className="col-12 col-sm-6">
            <Form.Group className="forms--group">
              <Form.Label>First Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name *"
                name={ProfileInfoFormFields.FIRST_NAME}
                className={props.errors?.firstName ? 'error' : ''}
                ref={register}
                onChange={e => props.setSettingsData('firstName', e.target.value)}
              />
              {props.errors?.firstName && (
                <span className="error__msg">{props.errors?.firstName}</span>
              )}
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            <Form.Group className="forms--group">
              <Form.Label>Last Name*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name *"
                name={ProfileInfoFormFields.LAST_NAME}
                ref={register}
                className={props.errors?.lastName ? 'error' : ''}
                onChange={e => props.setSettingsData('lastName', e.target.value)}
              />
              {props.errors?.lastName && (
                <span className="error__msg">{props.errors?.lastName}</span>
              )}
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            <Form.Group className="forms--group">
              <Form.Label>Email*</Form.Label>
              <Form.Control type="email" placeholder="Email Address *" disabled value={me?.email} />
            </Form.Group>
          </div>
          <div className="col-12 col-sm-6">
            <Form.Group className="forms--group">
              <Form.Label>Role*</Form.Label>
              <Form.Control
                type="text"
                placeholder="Role *"
                disabled
                value={me?.role ? startCase(me.role) : ''}
              />
            </Form.Group>
          </div>
          <div className="col-12 adminSettings-btn">
            <button
              type="button"
              onClick={() => setShowChangePasswordModal(true)}
              className="outline__button underline__button"
            >
              Change Password
            </button>
            <ChangePasswordModal
              show={showChangePasswordModal}
              setShow={setShowChangePasswordModal}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProfileInfo;
