import React from 'react';
import { times } from 'lodash';

const LoadingMoreShimmer: React.FC = () => {
  return (
    <React.Fragment>
      {times(2, i => (
        <div key={i} className="al--block al--block-lg al--lg-alt alb--lg-shimmer">
          <div className="al--block-img shimmer__style animate"></div>
          <div className="al--block-detail">
            <div className="ald--inner al--adress-component">
              <span className="al__address shimmer__style animate"></span>
              <div className="al--listing-box al--listing-box-sh">
                <div className="al--listing">
                  <span className="al__icon animate"></span>
                  <span className="al__text animate"></span>
                </div>
              </div>
            </div>
            <div className="ald--inner al--listings-component">
              <div className="al--listing-box al--listing-box-sh">
                <div className="al--listing">
                  <span className="al__icon animate"></span>
                  <span className="al__text animate"></span>
                </div>
                <div className="al--listing">
                  <span className="al__icon animate"></span>
                  <span className="al__text animate"></span>
                </div>
                <div className="al--listing">
                  <span className="al__icon animate"></span>
                  <span className="al__text animate"></span>
                </div>
              </div>
            </div>
            <div className="ald--inner al--btn-component">
              <span className="button__shimmer button__shimmer-lg">
                <span className="button__shimmer-text animate--gray"></span>
                <span className="button__shimmer-icon animate--gray"></span>
              </span>
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default LoadingMoreShimmer;
