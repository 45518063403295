import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { showNotification } from '../../../../utils/misc.utils';
import { errorExtractor } from '../../../../utils/error.utils';
import ListingVideo from './media/video';
import ListingGallery from './media/gallery';
import ListingBrokers from './brokers';
import ListingHandlers from './buttons/handlers';
import BuildingLocation from './building/location';
import ListingBasicInfo from './basicInfo';
import ListingFixHeader from './buttons/fixHeader';
import ListingDescription from './description';
import ListingFloorPlan from './media/floorPlan';
import BuildingSpecification from './building/specification';
import BuildingAmenities from './building/amenities';
import ListingRequestForm from './requestForm';
import BuildingAmenitiesMatterPorts from './building/amenitiesMatterport';

const ListingDetail: React.FC = () => {
  const list = useSelector((state: AppStateInterface) => state.subdomain.listing.singleListing);
  const buttonRef = useRef<HTMLDivElement>(null);
  const [showForm, setShowForm] = useState(false);
  const [showHeader, setShowHeader] = useState(false);

  const onScroll = () => {
    const buttons = buttonRef.current;
    if (buttons) setShowHeader(buttons.getBoundingClientRect().top <= 0);
  };

  useEffect(() => {
    if (list.hasData && !list.loading) {
      if (list.error) showNotification('error', 'Error', errorExtractor(list.error));
      else if (list.data) window.addEventListener('scroll', onScroll, false);
    }
    return () => window.removeEventListener('scroll', onScroll, false);
  }, [list]);

  return (
    <div className="subdomain--listing-content">
      <div className="row row--sapce-10">
        <ListingBasicInfo />
        <ListingVideo />
      </div>
      <div className="subdomain--btns-off" ref={buttonRef}>
        <ListingHandlers showForm={() => setShowForm(true)} />
      </div>
      {showHeader && <ListingFixHeader showForm={() => setShowForm(true)} />}
      {list?.data?.users && list?.data?.users?.length > 0 && (
        <div className="team--main team--main-sbl mt-4 pt-2">
          <ListingBrokers />
        </div>
      )}
      <ListingGallery />
      <div className="sdl--combine descrip--combine">
        <ListingDescription />
      </div>
      <ListingFloorPlan />
      <BuildingLocation />
      <BuildingSpecification />
      {list?.data?.building?.buildingAmenities &&
        list?.data?.building?.buildingAmenities?.length > 0 && (
          <div className="sdl--combine floorplan--combine">
            <BuildingAmenities />
          </div>
        )}
      {list?.data?.building?.buildingAmenitiesMatterportLinks &&
        list?.data?.building?.buildingAmenitiesMatterportLinks?.length > 0 && (
          <div className="sdl--combine subTours--combine">
            <BuildingAmenitiesMatterPorts />
          </div>
        )}
      {showForm && <ListingRequestForm hideForm={() => setShowForm(false)} />}
    </div>
  );
};

export default ListingDetail;
