import React, { useState, useEffect } from 'react';
import { GetListingObject, HandlerConditions } from '../../../../state/listing/listing.types';
import { USER_ROLES, VIDEO_STATUS } from '../../../../common/constants';
import { useGetTourUrl, useChangeVideoStatus } from '../../../../state/listing/listing.hook';
import SmallLoader from '../../../common/small.loader';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { showNotification } from '../../../../utils/misc.utils';
import { errorExtractor } from '../../../../utils/error.utils';
import copy from 'copy-to-clipboard';
import { renderHandlers } from '../../listing.component';

interface Props {
  info: GetListingObject;
}
const ListingHandlers: React.FC<Props> = props => {
  const { info: listing } = props;
  const { res: changeVideoStatusRes, changeVideoStatus } = useChangeVideoStatus();

  const { res: getTourUrlResponse, getTourUrl } = useGetTourUrl();
  const [selectedVideoStatus, setSelectedVideoStatus] = useState('');
  const me = useSelector((state: AppStateInterface) => state.me.me.data);

  useEffect(() => {
    if (!getTourUrlResponse.loading) {
      if (getTourUrlResponse.data) {
        const days = getTourUrlResponse.data.expiryInDays > 1 ? 'days' : 'day';
        copy(getTourUrlResponse.data.tourUrl);
        showNotification(
          'success',
          'Link copied to the clipboard',
          `This link will expire in ${getTourUrlResponse.data.expiryInDays} ${days}`,
        );
      }
      if (getTourUrlResponse.error) {
        showNotification('error', 'Error', errorExtractor(getTourUrlResponse.error));
      }
    }
  }, [getTourUrlResponse]);

  const getAcceptButton = (): JSX.Element => {
    return (
      <div className="col-12 col-sm">
        <button
          className="admin__button listing__button w-100"
          onClick={() => {
            setSelectedVideoStatus(VIDEO_STATUS.ACCEPTED);
            changeVideoStatus(listing._id, VIDEO_STATUS.ACCEPTED);
          }}
          disabled={changeVideoStatusRes.loading}
        >
          {changeVideoStatusRes.loading && selectedVideoStatus === VIDEO_STATUS.ACCEPTED
            ? 'Approving'
            : 'Approve Video'}
          {changeVideoStatusRes.loading && selectedVideoStatus === VIDEO_STATUS.ACCEPTED && (
            <SmallLoader />
          )}
        </button>
      </div>
    );
  };
  const getCopyButton = (): JSX.Element => {
    return (
      <div className="col-12 col-sm">
        <button
          className="admin__button listing__button w-100"
          onClick={() => getTourUrl(listing._id)}
          disabled={getTourUrlResponse.loading}
        >
          {getTourUrlResponse.loading ? 'Copying' : 'Copy Tour Link'}
          {getTourUrlResponse.loading && <SmallLoader />}
        </button>
      </div>
    );
  };
  const getRecordButton = (whiteButton: boolean, status: string): JSX.Element => {
    return (
      <div className="col-12 col-sm">
        <button
          className={`${whiteButton ? 'outline' : 'admin'}__button listing__button lb__arrow w-100`}
          onClick={() => {
            window.location.href = `${window.location.origin}/listing/review/${listing._id}`;
          }}
        >
          {status === VIDEO_STATUS.PENDING && 'This Video Has No Audio, Give Feedback'}
          {(status === VIDEO_STATUS.VOICEOVER_SUBMITTED || status === VIDEO_STATUS.ACCEPTED) &&
            'View your Voice-Over Submission'}
          {status === VIDEO_STATUS.UPLOADED && 'Give Feedback'}
          {status === VIDEO_STATUS.PENDING && (
            <span className="arrow__icon">
              <i className="icon-arrow-right"></i>
            </span>
          )}
        </button>
      </div>
    );
  };

  const getButtons = (conds: HandlerConditions): JSX.Element[] => {
    const output = [];
    const elements = [];
    if (conds.record) output.push(getRecordButton(conds.recordWhite, listing.videoStatus));
    if (conds.accept) output.push(getAcceptButton());
    for (let i = 0; i < output.length; i += 2) {
      elements.push(
        <div className="row row--space-10 mt-3 pt-1" key={i}>
          {output[i]}
          {output[i + 1]}
        </div>,
      );
    }
    if (conds.copy) {
      elements.push(
        <div className="row row--space-10 mt-3 pt-1" key={'copy'}>
          {getCopyButton()}
        </div>,
      );
    }

    return elements;
  };

  const placeButtons = listing ? getButtons(renderHandlers(listing)) : [];
  return (
    <React.Fragment>
      {(listing?.users?.find(user => user._id === me?._id) || me?.role === USER_ROLES.ADMIN) &&
        placeButtons}
    </React.Fragment>
  );
};
export default ListingHandlers;
