import React from 'react';
import { Form } from 'react-bootstrap';
import { DatePicker } from 'antd';
import 'antd/lib/date-picker/style/index.css';
import moment from 'moment';
import { suiteOptions, capacityOptions, conditionOptions, errorMsg } from '../listing.component';
import { ListingInterface, ListingErrorInterface } from './../../../state/listing/listing.types';
import { ListingForm as list } from './../../../state/listing/listing.form';
import { DATE_FORMAT } from '../../../common/constants';
import QuillEditor from '../../common/quillEditor';

interface Props {
  register: any;
  errors: any;
  setValue: any;
  data: ListingInterface;
  dataError: ListingErrorInterface;
  setOutput: (key: string, value: string) => void;
  showLabels?: boolean;
  descriptionValue: string;
  setDescription: (...args: any[]) => void;
}
const ListingSpecifications: React.FC<Props> = props => {
  const {
    data,
    errors,
    register,
    dataError,
    setOutput,
    showLabels: edit,
    setValue,
    descriptionValue,
    setDescription,
  } = props;

  const onDescriptionChange = (content: string) => {
    setDescription(content);
    setValue(list.description, content, true);
  };

  return (
    <div className="row row--space-12">
      <div className="col-12 col-sm-6">
        {edit && <Form.Label>Space Use*</Form.Label>}
        <Form.Group className="forms--group select--group mb-28">
          <Form.Control as="select" name={list.spaceUse} ref={register}>
            {suiteOptions()}
          </Form.Control>
          <span className="select__caret-down">
            <i className="icon-caret-down"></i>
          </span>
          {errorMsg(errors[list.spaceUse])}
        </Form.Group>
      </div>
      <div className="col-12 col-sm-6">
        {edit && <Form.Label>Suite #*</Form.Label>}
        <Form.Group className="forms--group">
          <Form.Control type="text" placeholder="Suite #*" name={list.suiteNo} ref={register} />
          {errorMsg(errors[list.suiteNo])}
        </Form.Group>
      </div>
      <div className="col-12 col-sm-6">
        {edit && <Form.Label>Size in SF*</Form.Label>}
        <Form.Group className="forms--group sf--group">
          <Form.Control type="text" placeholder="Size in SF*" name={list.size} ref={register} />
          <span className="sf__text">sf</span>
          {errorMsg(errors[list.size])}
        </Form.Group>
      </div>
      <div className="col-12 col-sm-6">
        {edit && <Form.Label>Monthly Rent</Form.Label>}
        <Form.Group className="forms--group rent--group">
          <Form.Control
            type="text"
            placeholder="Monthly rent"
            name={list.monthlyRent}
            ref={register}
          />
          <span className="rent__text">$/SF/YR</span>
          {errorMsg(errors[list.monthlyRent])}
        </Form.Group>
      </div>
      <div className="col-12 col-sm-6">
        {edit && <Form.Label>Capacity</Form.Label>}
        <Form.Group className="forms--group select--group mb-28">
          <Form.Control as="select" name={list.capacity} ref={register}>
            {capacityOptions()}
          </Form.Control>
          <span className="select__caret-down">
            <i className="icon-caret-down"></i>
          </span>
          {errorMsg(errors[list.capacity])}
        </Form.Group>
      </div>
      <div className="col-12 col-sm-6">
        {edit && <Form.Label>Condition</Form.Label>}
        <Form.Group className="forms--group select--group mb-28">
          <Form.Control as="select" name={list.condition} ref={register}>
            {conditionOptions()}
          </Form.Control>
          <span className="select__caret-down">
            <i className="icon-caret-down"></i>
          </span>
          {errorMsg(errors[list.condition])}
        </Form.Group>
      </div>
      <div className="col-12 col-sm-6">
        {edit && <Form.Label>Metro Proximity*</Form.Label>}
        <Form.Group className="forms--group">
          <Form.Control
            type="text"
            placeholder="Metro Proximity*"
            name={list.metroProximity}
            ref={register}
          />
          {errorMsg(errors[list.metroProximity])}
        </Form.Group>
      </div>
      <div className="col-12 col-sm-6">
        {edit && <Form.Label>Available*</Form.Label>}
        <Form.Group className="forms--group form-group icons--group datePicker--group">
          <DatePicker
            placeholder="Available*"
            format={DATE_FORMAT}
            onChange={(_, dateString: string) => setOutput(list.available, dateString)}
            value={data.available ? moment(data.available) : null}
            className="w-100"
          />
          {errorMsg({}, dataError[list.available])}
        </Form.Group>
      </div>
      <div className="col-12">
        {edit && <Form.Label>Description*</Form.Label>}
        <Form.Group className="forms--group textarea--group quill--group">
          <QuillEditor
            value={descriptionValue}
            onChange={onDescriptionChange}
            placeholder="Description*"
            toolbar={[
              ['bold', 'italic', 'underline', 'strike'], // toggled buttons
              [{ header: 1 }, { header: 2 }], // custom button values
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
              [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }], // dropdown with defaults from theme
              ['link'],
            ]}
          />
          {errorMsg(errors[list.description])}
        </Form.Group>
      </div>
    </div>
  );
};

export default ListingSpecifications;
