import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import {
  BuildingDataErrorInterface,
  BuildingDataInterface,
  BuildingDataPayloadInterface,
  BuildingDataRequiredFieldsInterface,
  UpdateBuildingDataPayloadInterface,
  AmenityMatterPortLink,
  AmenityMatterPortLinkWithError,
} from '../../../state/building/building.types';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import BuildingDetailsForm from './detail';
import BuildingSpecificationsForm from './specification';
import { cleanObject } from '../../../utils/apiValidatior';
import { showNotification } from '../../../utils/misc.utils';
import {
  useAddBuilding,
  useGetBuildingDetails,
  useUpdateBuilding,
} from '../../../state/building/building.hook';
import { errorExtractor } from '../../../utils/error.utils';
import { compact } from 'lodash';
import Loader from '../../common/loader';

const BuildingForm: React.FC = () => {
  const history = useHistory();
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const { buildingId } = useParams();
  const { res: addBuildingResponse, addBuilding } = useAddBuilding();
  const { res: buildingDetailsResponse, getBuildingDetails } = useGetBuildingDetails();
  const { res: updateBuildingResponse, updateBuilding } = useUpdateBuilding();
  const [step, setStep] = useState(1);
  const [locationFilled, setLocationFilled] = useState(false);
  const [buildingData, setBuildingData] = useState({} as BuildingDataInterface);
  const [errors, setErrors] = useState({} as BuildingDataErrorInterface);
  const [requiredFields] = useState({
    averageFloor: true,
    buildingAmenities: false,
    buildingHours: true,
    buildingSize: true,
    columnSpacingHeight: false,
    columnSpacingWidth: false,
    energyStarCertification: false,
    floorsCount: true,
    freightElevators: false,
    fullLocation: false,
    imageUrl: true,
    latitude: true,
    leedCertification: false,
    location: true,
    longitude: true,
    parkingRatio: false,
    passengerElevators: true,
    slabToSlabHeight: false,
    subMarket: true,
    telecomProviders: false,
    yearBuilt: true,
    yearRenovated: true,
    website: false,
    other: false,
  } as BuildingDataRequiredFieldsInterface);
  const [selectedBuilding, setSelectedBuilding] = useState('');

  useEffect(() => {
    if (!updateBuildingResponse.loading) {
      if (updateBuildingResponse.data) {
        showNotification('success', 'Success', 'Saved successfully');
        history.push(`/building/${buildingId}/listings`);
      }
      if (updateBuildingResponse.error) {
        showNotification('error', 'Error', errorExtractor(updateBuildingResponse.error));
      }
    }
  }, [updateBuildingResponse]);

  useEffect(() => {
    if (!buildingDetailsResponse.loading) {
      if (buildingDetailsResponse.data) {
        setBuildingData({
          floorsCount: buildingDetailsResponse.data.floorsCount,
          parkingRatio: buildingDetailsResponse.data.parkingRatio,
          columnSpacingWidth: buildingDetailsResponse.data.columnSpacingWidth,
          columnSpacingHeight: buildingDetailsResponse.data.columnSpacingHeight,
          telecomProviders: buildingDetailsResponse.data.telecomProviders,
          freightElevators: buildingDetailsResponse.data.freightElevators,
          leedCertification: buildingDetailsResponse.data.leedCertification,
          yearRenovated: buildingDetailsResponse.data.yearRenovated,
          location: buildingDetailsResponse.data.location,
          subMarket: buildingDetailsResponse.data.subMarket,
          latitude: buildingDetailsResponse.data.latitude?.toString(),
          longitude: buildingDetailsResponse.data.longitude?.toString(),
          imageUrl: buildingDetailsResponse.data.imageUrl,
          buildingHours: buildingDetailsResponse.data.buildingHours,
          buildingAmenities: buildingDetailsResponse.data.buildingAmenities,
          buildingAmenitiesMatterportLinks:
            buildingDetailsResponse.data.buildingAmenitiesMatterportLinks,
          averageFloor: buildingDetailsResponse.data.averageFloor,
          buildingSize: buildingDetailsResponse.data.buildingSize,
          energyStarCertification: buildingDetailsResponse.data.energyStarCertification,
          fullLocation: buildingDetailsResponse.data.fullLocation,
          passengerElevators: buildingDetailsResponse.data.passengerElevators,
          slabToSlabHeight: buildingDetailsResponse.data.slabToSlabHeight?.toString(),
          yearBuilt: buildingDetailsResponse.data.yearBuilt,
          website: buildingDetailsResponse.data.website,
          other: buildingDetailsResponse.data.other,
        });
      }
      if (buildingDetailsResponse.error) {
        showNotification('error', 'Error', errorExtractor(buildingDetailsResponse));
        history.push('/buildings');
      }
    }
  }, [buildingDetailsResponse]);

  useEffect(() => {
    if (buildingId) {
      getBuildingDetails(buildingId);
    }
  }, [buildingId]);

  useEffect(() => {
    if (!addBuildingResponse.loading) {
      if (addBuildingResponse.data) {
        showNotification('success', 'Success', 'Building created successfully');
        history.push({
          pathname: `/building/${addBuildingResponse.data._id}/listings/create`,
          state: { building: true },
        });
      }
      if (addBuildingResponse.error) {
        showNotification('error', 'Error', errorExtractor(addBuildingResponse.error));
      }
    }
  }, [addBuildingResponse]);

  const setData = (field: string, value: any) => {
    setBuildingData(prevState => ({ ...prevState, [field]: value }));
  };

  const setError = (field: string, value: string) => {
    setErrors(prevState => ({ ...prevState, [field]: value }));
  };

  const onDetailFormSubmit = (e: any) => {
    e.preventDefault();
    if (selectedBuilding) {
      history.push(`/building/${selectedBuilding}/listings/create`);
    } else {
      let error = false;
      if (!buildingData.location || !buildingData.longitude || !buildingData.latitude) {
        if (locationFilled) {
          setError('location', 'This address does not exist. Please select one from suggestions.');
        } else {
          setError('location', 'This field is required');
        }
        error = true;
      }
      if (!buildingData.subMarket) {
        setError('subMarket', 'This field is required');
        error = true;
      }
      if (!buildingData.imageUrl) {
        setError('imageUrl', 'This field is required');
        error = true;
      }
      if (error) {
        return;
      }
      setStep(2);
    }
  };

  const goBack = () => {
    setStep(1);
  };

  const validateAllRequiredFields = () => {
    let error = false;
    for (const key in requiredFields) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (requiredFields[key]) {
        if (
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          !(key in buildingData) ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          (typeof buildingData[key] === 'number' && isNaN(buildingData[key])) ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          (typeof buildingData[key] === 'string' && !buildingData[key]) ||
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          (Array.isArray(buildingData[key]) && !buildingData[key].length)
        ) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          if (!errors[key]) {
            setError(key, 'This field is required');
          }
          error = true;
        }
      }
    }
    if (
      (buildingData.columnSpacingHeight && !buildingData.columnSpacingWidth) ||
      (!buildingData.columnSpacingHeight && buildingData.columnSpacingWidth)
    ) {
      setError('columnSpacing', 'Either fill both height and width or none');
      error = true;
    }
    if (buildingData.parkingRatio) {
      const ratioValues = buildingData.parkingRatio.split(':');
      if (ratioValues.length !== 2) {
        setError('parkingRatio', 'Either fill both ratio values or none');
        error = true;
      } else {
        if ((ratioValues[0] && !ratioValues[1]) || (!ratioValues[0] && ratioValues[1])) {
          setError('parkingRatio', 'Either fill both ratio values or none');
          error = true;
        }
      }
    }
    if (buildingData.buildingAmenitiesMatterportLinks) {
      const links = buildingData.buildingAmenitiesMatterportLinks;
      for (const i in links) {
        if (links[i]?.name?.length && !links[i].url.length) {
          error = true;
          links[i].error = true;
        }
        setBuildingData({
          ...buildingData,
          buildingAmenitiesMatterportLinks: links,
        });
      }
    }
    if (error) {
      return error;
    } else {
      const cleanedErrors = cleanObject(errors);
      return !!Object.keys(cleanedErrors).length;
    }
  };

  const cleanMatterPorts = (e: AmenityMatterPortLinkWithError[]): AmenityMatterPortLink[] => {
    const obj = [] as AmenityMatterPortLink[];
    for (const i in e) if (e[i].url.length) obj.push(e[i] as AmenityMatterPortLink);
    return obj;
  };
  const onSpecificationFormSubmit = (e: any) => {
    e.preventDefault();
    if (validateAllRequiredFields()) {
      showNotification('error', 'Error', 'Some fields have invalid or null values');
      return;
    }
    if (me?._id && me?.company?._id) {
      const cleanedData = cleanObject(buildingData);
      const matterPorts = cleanMatterPorts(buildingData.buildingAmenitiesMatterportLinks || []);
      addBuilding({
        ...cleanedData,
        floorsCount: buildingData.floorsCount,
        company: me.company._id,
        users: [me._id],
        buildingAmenities: Array.isArray(buildingData.buildingAmenities)
          ? compact(buildingData.buildingAmenities)
          : [],
        buildingAmenitiesMatterportLinks: Array.isArray(matterPorts) ? compact(matterPorts) : [],
      } as BuildingDataPayloadInterface);
    }
  };

  const onSave = (e: any) => {
    e.preventDefault();
    let error = false;
    if (
      (!buildingData.location || !buildingData.longitude || !buildingData.latitude) &&
      !errors.location
    ) {
      if (locationFilled) {
        setError('location', 'This address does not exist. Please select one from suggestions.');
      } else {
        setError('location', 'This field is required');
      }
      error = true;
    }
    if (!buildingData.subMarket) {
      setError('subMarket', 'This field is required');
      error = true;
    }
    if (!buildingData.imageUrl) {
      setError('imageUrl', 'This field is required');
      error = true;
    }
    if (error) {
      return;
    }
    if (validateAllRequiredFields()) {
      showNotification('error', 'Error', 'Please fix all errors on both forms!');
      return;
    }
    const matterPorts = cleanMatterPorts(buildingData.buildingAmenitiesMatterportLinks || []);
    updateBuilding(buildingId, {
      ...buildingData,
      floorsCount: buildingData.floorsCount,
      buildingAmenities: Array.isArray(buildingData.buildingAmenities)
        ? compact(buildingData.buildingAmenities)
        : [],
      buildingAmenitiesMatterportLinks: Array.isArray(matterPorts) ? compact(matterPorts) : [],
    } as UpdateBuildingDataPayloadInterface);
  };

  return (
    <div className="dashboard--container">
      {buildingDetailsResponse.loading && <Loader />}
      <div className="dashboard--canvas">
        {step === 1 && (
          <BuildingDetailsForm
            onContinue={onDetailFormSubmit}
            setDataField={setData}
            setBuildingData={setBuildingData}
            data={buildingData}
            selectedBuilding={selectedBuilding}
            setSelectedBuilding={setSelectedBuilding}
            errors={errors}
            setError={setError}
            locationFilled={locationFilled}
            setLocationFilled={setLocationFilled}
            setStep={setStep}
            buildingDetailsResponse={buildingDetailsResponse}
            onSave={onSave}
            updateBuildingResponse={updateBuildingResponse}
          />
        )}
        {step === 2 && (
          <BuildingSpecificationsForm
            goBack={goBack}
            onContinue={onSpecificationFormSubmit}
            setDataField={setData}
            data={buildingData}
            errors={errors}
            setError={setError}
            addBuildingLoading={addBuildingResponse.loading}
            setStep={setStep}
            buildingDetailsResponse={buildingDetailsResponse}
            onSave={onSave}
            updateBuildingResponse={updateBuildingResponse}
          />
        )}
      </div>
    </div>
  );
};

export default BuildingForm;
