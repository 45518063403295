import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { ListingForm as list } from '../../../../state/listing/listing.form';
import { ListingInterface, ListingErrorInterface } from '../../../../state/listing/listing.types';
import { UploadedMediaInterface } from '../../../../state/media/media.types';
import UploadDocs from './uploads/docs';
import SingleMediaTile from '../../../media/singleTile';
import { errorMsg } from '../../listing.component';

interface Props {
  data: ListingInterface;
  dataError: ListingErrorInterface;
  setOutput: (key: string, value: any) => void;
}
const PDFs: React.FC<Props> = props => {
  const [modalDocsShow, setModalDocsShow] = useState(false);
  const { data, dataError, setOutput } = props;

  const handlePDFs = (docs: UploadedMediaInterface[]) => {
    let alreadyDocs = [] as UploadedMediaInterface[];
    if (data.pdfs) alreadyDocs = data.pdfs;
    setOutput(list.pdfs, [...alreadyDocs, ...docs]);
    setModalDocsShow(false);
  };
  const removeDoc = (index: number) => {
    if (data.pdfs && data.pdfs.length) {
      const pdfs = data.pdfs;
      pdfs.splice(index, 1);
      setOutput(list.pdfs, pdfs);
    }
  };

  return (
    <React.Fragment>
      <div className="file--uploaded ">
        {data.pdfs &&
          data.pdfs.map((file, index) => (
            <SingleMediaTile key={index} url={'/images/pdf.png'} remove={() => removeDoc(index)} />
          ))}
      </div>
      <div className="step--upload-block">
        <Button onClick={() => setModalDocsShow(true)} className="upload__button mt-2">
          Upload {data.pdfs?.length > 0 && 'More '}PDF Collateral
          <span className="upload__button-icon">
            <i className="icon-upload"></i>
          </span>
        </Button>
        {modalDocsShow && (
          <UploadDocs
            handleClose={() => setModalDocsShow(false)}
            handleChanges={handlePDFs}
            alreadyFiles={data.pdfs ? data.pdfs.length : 0}
          />
        )}
      </div>
      {errorMsg({}, dataError[list.pdfs])}
    </React.Fragment>
  );
};

export default PDFs;
