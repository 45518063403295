import React from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import { compileAvatarUrl } from '../../../../../utils/avatar.utils';

const ListingFloorPlan: React.FC = () => {
  const info = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing.data,
  );

  return (
    <React.Fragment>
      {info?.floorPlans && info.floorPlans.length > 0 && (
        <div className="sdl--combine floorplan--combine">
          <div className="row row--space-10 sdl--row align-items-center flex-md-row-reverse">
            <div className="col-12 col-md-5 d-flex flex-column align-items-md-end">
              <div className="sdl--l text-center text-md-left">
                <h2 className="dashboard__heading dashboard__heading-alt font-semibold mb-0">
                  Floor-Plan
                </h2>
              </div>
            </div>
            <div className="col-12 col-md-7 d-flex flex-column">
              <div className="sdl--r text-center text-md-left">
                <div className="floorplan--img">
                  <img
                    src={compileAvatarUrl(info.floorPlans[0].url)}
                    alt={info.floorPlans[0].name}
                    className="img--cover"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ListingFloorPlan;
