import * as yup from 'yup';

export enum LandingFormFields {
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  PHONE = 'phone',
  COMPANY_NAME = 'companyName',
  TOTAL_PORTFOLIO = 'totalPortfolio',
  LISTINGS_COUNT = 'listingsCount',
  BROKERS_COUNT = 'brokersCount',
  MATTER_PORT = 'matterPortInfo',
}

export const LandingFormSchema = yup.object().shape({
  [LandingFormFields.EMAIL]: yup
    .string()
    .trim()
    .required('This field is required')
    .email('Please enter a valid email address'),
  [LandingFormFields.FIRST_NAME]: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
    .required('This field is required'),
  [LandingFormFields.LAST_NAME]: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
    .required('This field is required'),
  [LandingFormFields.PHONE]: yup
    .string()
    .trim()
    .matches(/^$|^\([0-9]{3}\)\s[0-9]{3}[-][0-9]{4}$/i, 'Allowed format: (234) 456-7890'),
  [LandingFormFields.COMPANY_NAME]: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
    .required('This field is required'),
  [LandingFormFields.TOTAL_PORTFOLIO]: yup
    .number()
    .min(1, 'Value must be greater than 1')
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .typeError('Value must be a number'),
  [LandingFormFields.LISTINGS_COUNT]: yup
    .number()
    .min(1, 'Value must be greater than 1')
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .typeError('Value must be a number'),
  [LandingFormFields.LISTINGS_COUNT]: yup
    .number()
    .min(1, 'Value must be greater than 1')
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .typeError('Value must be a number'),
  [LandingFormFields.MATTER_PORT]: yup.string(),
});
