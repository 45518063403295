import React from 'react';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../state';
import { compileAvatarUrl } from '../../utils/avatar.utils';
import { Link } from 'react-router-dom';

const NotFoundPage: React.FC = () => {
  const history = useHistory();
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );

  const goBack = () => {
    history.goBack();
  };

  return (
    <div className="notfound sec--vertical sec--vertical-md">
      <div className="container container--large">
        <Link className="listing__logo" to={me ? '/dashboard' : '/'}>
          <img
            src={
              me?.company?.settings?.darkLogo
                ? compileAvatarUrl(me.company.settings.darkLogo.url)
                : company?.settings?.darkLogo
                ? compileAvatarUrl(company.settings.darkLogo.url)
                : '/images/HomeTour_Dark_Logo.svg'
            }
            alt=""
          />
        </Link>
        <div className="notfound--content sec--vertical-middle">
          <div className="container--xs">
            <div className="notfound--detail text-center">
              <span className="notfound__img">404</span>
              <h3 className="heading__medium heading__medium-black font-weight-normal">
                Page not found!
              </h3>
              <p className="notfound__paragraph text__gray">
                Sorry, we couldn’t find the page you are looking for. Please check the URL or{' '}
                <a className="av__link text-nowrap" onClick={goBack}>
                  go back
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
