import React, { useState, useCallback, CSSProperties } from 'react';

interface Props {
  iconClass: string;
  heading: string;
  details: string[];
}

// TODO react to resizing?

const SpecCard: React.FC<Props> = (props: Props) => {
  const { iconClass, heading, details } = props;
  const [isExpandable, setIsExpandable] = useState(details.length > 1);
  const [isExpanded, setIsExpanded] = useState(details.length > 1);

  const elContainer = useCallback((el: HTMLDivElement | null) => {
    if (!el) return;
    if (isExpandable) return;

    // for of was blocked by ts?
    for (let i = 0; i < el.children.length; i++) {
      const child = el.children[i];
      if (
        child.clientWidth < child.scrollWidth ||
        (child.clientHeight < child.scrollHeight && i > 0)
      ) {
        setIsExpandable(true);
        setIsExpanded(true);
        return;
      }
    }
  }, []);

  const extraHeadingStyle: CSSProperties = isExpanded
    ? {
        whiteSpace: 'normal',
      }
    : {};

  const extraDetailStyle: CSSProperties = isExpanded
    ? {
        whiteSpace: 'normal',
      }
    : {
        WebkitLineClamp: 2,
      };

  return (
    <div className="col col-12 col-sm-6 col-xl-4">
      <div className="specs--box">
        <div className="w-100" ref={elContainer}>
          <div className="specsBox__head">
            <span className="specsBox__head-icon">
              <i className={iconClass}></i>
            </span>
            <span className="specsBox__head-text" style={extraHeadingStyle}>
              {heading}
            </span>
          </div>
          {isExpanded ? (
            details.map((detail, idx) => (
              <span className="para__sm-gray" style={extraDetailStyle} key={idx}>
                {detail}
              </span>
            ))
          ) : (
            <span className="para__sm-gray" style={extraDetailStyle}>
              {details[0]}
            </span>
          )}
        </div>
        {isExpandable && (
          <span
            className="specs__arrow"
            onClick={() => {
              setIsExpanded(prevState => !prevState);
            }}
          >
            <i className={isExpanded ? 'icon-chevron-top' : 'icon-chevron-bottom'}></i>
          </span>
        )}
      </div>
    </div>
  );
};

export default SpecCard;
