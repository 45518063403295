import { GetStateInterface } from '../../types/GetStateInterface';

export interface RegisterRequestInterface {
  email: string;
  password: string;
  confirmPassword: string;
  fullName: string;
  avatar: string;
}

export interface SignInRequestInterface {
  email: string;
  password: string;
}

export interface LoginStateInterface {
  user: GetStateInterface;
}

export interface ForgetStateInterface {
  email: string;
}

export interface ResetStateInterface {
  recoveryCode: string;
  password: string;
  memberId: string;
}

export interface ChangePasswordInterface {
  oldPassword: string;
  newPassword: string;
}

export enum LoginActions {
  LOGIN = 'LOGIN',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  RESET_PASSWORD = 'RESET_PASSWORD',
  CHANGE_PASSWORD = 'CHANGE_PASSWORD',
  LOGOUT = 'LOGOUT',
}
