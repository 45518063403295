import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { MatterPortContext } from './index';

const HandleTourMatterPort: React.FC = () => {
  const listing = useSelector((state: AppStateInterface) => state.tour.tourListing);
  const { matterPortLink, setMatterPortLink } = useContext(MatterPortContext);
  useEffect(() => {
    if (listing?.listing) setMatterPortLink(listing.listing.matterPortLink);
  }, [listing]);

  return (
    <div className="tourEdit--top tourEdit--top-home">
      {matterPortLink?.length > 0 && (
        <iframe
          src={matterPortLink + '&play=1'}
          frameBorder={0}
          allowFullScreen
          height="100%"
          width="100%"
        ></iframe>
      )}
    </div>
  );
};

export default HandleTourMatterPort;
