import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import ListingPageNo from './pageNo';
import ListingSpecifications from './specifications';
import ListingMedia from './media/index';
import ListingBrokers from './brokers';
import { times, isEmpty } from 'lodash';
import { noToAlpha, showNotification } from '../../../utils/misc.utils';
import { useHistory, useParams, useLocation } from 'react-router';
import useForm from 'react-hook-form';
import {
  ListingFormSchema,
  ListingForm as list,
  verifyData,
} from './../../../state/listing/listing.form';
import {
  ListingInterface,
  ListingErrorInterface,
  GetListingObject,
} from './../../../state/listing/listing.types';
import { useCreateListing, useGetSingleListing } from '../../../state/listing/listing.hook';
import { errorExtractor } from '../../../utils/error.utils';
import SmallLoader from '../../common/small.loader';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { USER_ROLES } from '../../../common/constants';

const currentPage = 3,
  totalPages = 3,
  totalOptions = 5;

interface Props {
  parent: HTMLDivElement | null;
}
const CreateList: React.FC<Props> = props => {
  const { register, handleSubmit, errors, reset, setValue, getValues, unregister } = useForm({
    validationSchema: ListingFormSchema,
  });
  const { parent: pageStart } = props;
  const history = useHistory();
  const { state } = useLocation();
  const me = useSelector((state: AppStateInterface) => state.me.me);
  const { buildingId: currentBuildingId } = useParams();
  const { buildingId, listingId } = useParams();
  const { res: createListRes, createListing, editListing } = useCreateListing();
  const { res: getListRes, getSingleListing } = useGetSingleListing();

  const [currentListingNo, updateListingNo] = useState(0);
  const [totalListing, setTotalListing] = useState(0);
  const [separateData, updateData] = useState({} as ListingInterface);
  const [separateDataError, updateDataError] = useState({} as ListingErrorInterface);
  const [building, setBuilding] = useState('');
  const [meBroker, setMeBroker] = useState('');
  const [isNew, setNew] = useState(true);
  const [description, setDescription] = useState<any>(null);

  const setValues = (e: GetListingObject) => {
    setDescription(e.description);
    setValue(list.size, e.size);
    setValue(list.suiteNo, e.suiteNo);
    setValue(list.spaceUse, e.spaceUse);
    setValue(list.capacity, e.capacity);
    setValue(list.condition, e.condition);
    setValue(list.description, e.description);
    setValue(list.monthlyRent, e.monthlyRent);
    setValue(list.matterPortLink, e.matterPortLink);
    setValue(list.metroProximity, e.metroProximity);
    setValue(list.videoDescription, e.videoDescription);
  };
  const handleChange = (key: string, value: string) => {
    updateDataError({ ...separateDataError, [key]: '' });
    updateData({ ...separateData, [key]: value });
  };
  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    const dataError = verifyData(separateData);
    if (!isEmpty(dataError)) updateDataError(dataError);
    else {
      const finalData = { ...separateData, ...data, building } as ListingInterface;
      if (!finalData.pdfs) finalData.pdfs = [];
      if (!finalData.floorPlans) finalData.floorPlans = [];
      if (!finalData.renderings) finalData.renderings = [];
      if (!data.monthlyRent) finalData.monthlyRent = null;
      if (isNew) createListing(finalData);
      else if (listingId) editListing(finalData, listingId);
    }
  };

  useEffect(() => {
    register({ name: list.description });
    return () => {
      unregister(list.description);
    };
  }, [register, unregister]);

  useEffect(() => {
    if (!me.loading && me.data) {
      if (me.data.role === USER_ROLES.BROKER) {
        updateData({ ...separateData, [list.users]: [me.data._id] });
        setMeBroker(me.data._id);
      }
    }
  }, [me]);
  useEffect(() => {
    if (listingId) {
      setNew(false);
      setTotalListing(1);
      getSingleListing(listingId);
    }
  }, [listingId]);
  useEffect(() => setBuilding(buildingId || ''), [buildingId]);
  useEffect(() => {
    if (createListRes.hasData && !createListRes.loading) {
      if (createListRes.error)
        showNotification('error', 'Listing Creation Error', errorExtractor(createListRes.error));
      else if (createListRes.data) {
        reset();
        setDescription(null);
        const sepData = {} as ListingInterface;
        if (meBroker.length) sepData.users = [meBroker];
        updateData(sepData);
        updateDataError({} as ListingErrorInterface);
        updateListingNo(currentListingNo + 1);
        if (pageStart) {
          if (pageStart.scrollTo) {
            pageStart.scrollTo(0, 0);
          } else {
            pageStart.scrollTop = 0;
            pageStart.scrollLeft = 0;
          }
        }
      }
    }
  }, [createListRes]);
  useEffect(() => {
    if (getListRes.hasData && !getListRes.loading) {
      if (getListRes.error)
        showNotification('error', 'Fetching Listing Error', errorExtractor(getListRes.error));
      else if (getListRes.data) {
        const e = getListRes.data as GetListingObject;
        setValues(e);
        const users = e.users.map(user => user._id);
        updateData({ ...getListRes.data, users });
        setBuilding(e.building._id);
      }
    }
  }, [getListRes]);
  useEffect(() => {
    const currentState = state as any;
    if (totalListing > 0 && currentListingNo === totalListing) {
      if (isNew) {
        if (currentState?.building) history.push('/greatJob');
        else history.push(`/building/${currentBuildingId}/listings`);
      } else history.push(`/listing/${listingId}`);
    }
  }, [currentListingNo]);

  return (
    <React.Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="dashboard--head editListing--head">
          <h3 className="dashboard__heading d-flex align-items-center">
            {isNew ? 'Add Listings' : 'Edit Listings'}
          </h3>
          {isNew && (
            <ListingPageNo
              current={currentPage + currentListingNo}
              total={totalPages + totalListing - (totalListing ? 1 : 0)}
            />
          )}
          {!isNew && (
            <div className="editListing--head-btn">
              <Button
                className="outline__button underline__button sm__button"
                onClick={() => history.push(`/listing/${listingId}`)}
              >
                Cancel
              </Button>
              <Button
                className="admin__button underline__button sm__button"
                type="submit"
                disabled={createListRes.loading || getListRes.loading}
              >
                {createListRes.loading ? 'Saving' : 'Save'}
                {createListRes.loading && <SmallLoader />}
              </Button>
            </div>
          )}
        </div>
        {isNew && currentListingNo === 0 && (
          <p className="dashboard__paragraph mb-28">
            How many listings would you like to add to this building?
          </p>
        )}
        {isNew && currentListingNo === 0 && (
          <div className="row row--space-12">
            <div className="col-12 col-sm-6">
              <Form.Group className="forms--group select--group mb-28">
                <Form.Control
                  as="select"
                  onChange={e => setTotalListing(parseInt(e.target.value))}
                  value={totalListing || undefined}
                >
                  <option value="0" hidden key={-1} className="placeholder">
                    Select how many
                  </option>
                  {times(totalOptions, i => (
                    <option value={i + 1} key={i}>
                      {i + 1}
                    </option>
                  ))}
                </Form.Control>
                <span className="select__caret-down">
                  <i className="icon-caret-down"></i>
                </span>
              </Form.Group>
            </div>
          </div>
        )}
        {isNew && (
          <p
            className="form__para mb-48"
            onClick={() => history.push(`/building/${buildingId}/listings`)}
          >
            You can{' '}
            <a href="javascript:void(0)" className="link__gray">
              Skip
            </a>{' '}
            this section and continue to add suites from the listings section later on.
          </p>
        )}
        {totalListing > 0 && (
          <React.Fragment>
            <p className="dashboard__paragraph mb-4 pt-2">
              Listing-{noToAlpha(currentListingNo + 1)} Specifications
            </p>
            <ListingSpecifications
              register={register}
              data={separateData}
              setValue={setValue}
              setOutput={handleChange}
              errors={errors}
              dataError={separateDataError}
              showLabels={!isNew}
              descriptionValue={description}
              setDescription={setDescription}
            />
            <p className="dashboard__paragraph mb-4 pt-4">
              Listing-{noToAlpha(currentListingNo + 1)} Media
            </p>
            <ListingMedia
              register={register}
              data={separateData}
              setOutput={handleChange}
              errors={errors}
              dataError={separateDataError}
              showLabels={!isNew}
            />
            <p className="dashboard__paragraph mb-4 pt-4">
              Listing-{noToAlpha(currentListingNo + 1)} Brokers
            </p>
            <ListingBrokers
              data={separateData}
              setOutput={handleChange}
              dataError={separateDataError}
              showLabels={!isNew}
            />
            {isNew && (
              <div className="stepBtn-canvas stepBtn-canvas-3">
                <Button
                  type="submit"
                  className="admin__button underline__button step__button"
                  disabled={createListRes.loading}
                >
                  {createListRes.loading ? 'Creating' : 'Continue'}
                  {createListRes.loading && <SmallLoader />}
                  <i className="icon-right-arrow"></i>
                </Button>
              </div>
            )}
          </React.Fragment>
        )}
      </Form>
    </React.Fragment>
  );
};

export default CreateList;
