import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { debounce } from 'lodash';
import { useQuickSearch } from '../../state/search/search.hook';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';
import SearchResults from './results';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../state';
import SearchLoadingShimmer from './loading';

const Search: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const [search, setSearch] = useState('');
  const { res: quickSearchResponse, quickSearch, reset } = useQuickSearch();
  const { data: searchResults, loading, error } = quickSearchResponse;
  const meLoading = useSelector((state: AppStateInterface) => state.me.me.loading);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSearch(
      decodeURIComponent(
        Array.isArray(queryParams?.query) && queryParams?.query?.length
          ? queryParams?.query[0]
          : typeof queryParams?.query === 'string'
          ? queryParams.query
          : '',
      ),
    );
  }, [queryParams]);

  useEffect(() => {
    if (search) {
      quickSearch(search);
    } else {
      reset();
    }
  }, [search]);

  useLayoutEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  const onSearchChange = debounce((value: string) => {
    if (value) {
      history.push({
        pathname: '/search',
        search: qs.stringify({
          query: value,
        }),
      });
    } else {
      history.push('/search');
    }
  }, 300);

  return (
    <React.Fragment>
      {meLoading ? (
        <div className="white--rounded-box dashboard--bar mb-20">
          <div className="search--shimmer-lg w-100">
            <span className="search__icon animate--gray"></span>
            <span className="search__text animate--gray"></span>
          </div>
        </div>
      ) : (
        <div className="white--rounded-box dashboard--bar mb-20">
          <Form.Group className="form--group-alt search--group-alt search--group-lg mb-0 w-100">
            <Form.Control
              ref={inputRef}
              type="search"
              placeholder="Type an address, client name, colleague, or tenant rep’s name..."
              defaultValue={search}
              onChange={(e: any) => onSearchChange(e.target.value)}
            />
            <span className="search__icon">
              <i className="icon-search"></i>
            </span>
          </Form.Group>
        </div>
      )}
      {loading ? (
        <SearchLoadingShimmer />
      ) : (
        <div className="white--rounded-box dashbaord--search box--expand">
          <h3 className="heading__medium heading__medium-black font-semibold mb-4">
            Search Results
          </h3>
          {searchResults ? (
            <SearchResults results={searchResults} />
          ) : (
            <p className="para__md-gray mb-0">Your search results will be displayed here.</p>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default Search;
