import { GetStateInterface } from '../../types/GetStateInterface';

export interface UsersStateInterface {
  brokers: GetStateInterface<UsersInterface[]>;
}

export interface UsersInterface {
  _id: string;
  avatar: string;
  role: string;
  email: string;
  firstName: string;
  lastName: string;
  isActive: boolean;
}

export enum UsersActions {
  GET_BROKERS = 'GET_BROKERS',
  GET_BROKERS_INIT = 'GET_BROKERS_INIT',
  GET_BROKERS_SUCCESSFUL = 'GET_BROKERS_SUCCESSFUL',
  GET_BROKERS_FAILURE = 'GET_BROKERS_FAILURE',
}
