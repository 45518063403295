import React, { createContext, useState } from 'react';
import TourEditController from './controller';
import HandleTourHome from './home';
import HandleTourDetails from './details';
import { TOUR_SWITCHES as SWITCH } from '../../../../state/tour/tour.types';
import HandleTourGallery from './gallery';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { useChangeTourListing } from '../../../../state/tour/tour.hook';
import HandleTourFloorPlans from './floorPlans';
import HandleTourLocation from './location';
import HandleTourMatterPort from './matterPort';
import HandleTourSpecs from './specs';

export const MatterPortContext = createContext({
  matterPortLink: '',
  setMatterPortLink: (value: string) => {
    return;
  },
});

interface Props {
  showListings: () => void;
}
const HandleTourEdit: React.FC<Props> = props => {
  const { showListings } = props;
  const tour = useSelector((state: AppStateInterface) => state.tour.tourInfo.data);
  const { changeTourListing } = useChangeTourListing();
  const [currentView, switchView] = useState(SWITCH.HOME);
  const [matterPortLink, setMatterPortLink] = useState('');

  const changeListing = (listingId: string) => {
    if (tour?.listings.length) {
      const listing = tour.listings.find(listing => listing?.listing?._id === listingId);
      if (listing) changeTourListing(listing);
    }
  };

  return (
    <div className="white--rounded-box tourEdit--home mb-20">
      <MatterPortContext.Provider value={{ matterPortLink, setMatterPortLink }}>
        {tour?.listings && tour?.listings?.length > 0 && (
          <React.Fragment>
            {currentView === SWITCH.HOME && <HandleTourHome />}
            {currentView === SWITCH.DETAILS && <HandleTourDetails />}
            {currentView === SWITCH.LOCATION && <HandleTourLocation />}
            {currentView === SWITCH.GALLERY && <HandleTourGallery />}
            {currentView === SWITCH.FLOOR_PLANS && <HandleTourFloorPlans />}
            {currentView === SWITCH.TOUR && <HandleTourMatterPort />}
            {currentView === SWITCH.SPECS && <HandleTourSpecs />}
          </React.Fragment>
        )}
        <TourEditController
          changeTab={switchView}
          changeListing={changeListing}
          addListing={showListings}
        />
      </MatterPortContext.Provider>
    </div>
  );
};

export default HandleTourEdit;
