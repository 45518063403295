import React, { useCallback, useEffect, useState } from 'react';

import { GetListingObject } from '../../../state/listing/listing.types';
import { MeResponseInterface } from '../../../state/me/me.types';
import { TourActionTypes, TourStates } from '../../../state/subdomain/tour/tour.types';
import { useTour } from '../../../state/subdomain/tour/tour.hook';
import fetchActions from '../../../utils/state.utils';

import Loader from '../../common/loader';
import JoinTour from './joinTour';
import JoinTourTenant from './joinTourTenant';
import FullVideo from './fullVideo';
import ListingTourView from './listingTourView';
import ParticipantWaiting from './participantWaiting';
import BrokerTourEnded from './brokerTourEnded';
import ParticipantTourEnded from './participantTourEnded';
import ScreenshareInstructions from './ScreenshareInstructions';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import ParticipantStandBy from './participantStandBy';
import { TOUR_TYPES } from '../../../common/constants';
import { checkIfNumber } from '../../../utils/misc.utils';

interface Props {
  roomUrl: string;
  isBroker: boolean;
  me: MeResponseInterface | null;
}

const TourWrapper: React.FC<Props> = (props: Props) => {
  const { roomUrl, isBroker, me } = props;
  const tourInfo = useSelector((state: AppStateInterface) => state.subdomain.tour.tourInfo);
  const videoCall = useTour(roomUrl, tourInfo.tourType);

  const [listing, setListing] = useState({} as GetListingObject);
  const listingInfo = useSelector((state: AppStateInterface) => state.subdomain.tour.tourListing);

  const {
    joinCall,
    startCall,
    leaveCall,
    tourState,
    isHost,
    host,
    reselectTab,
    browser,
  } = videoCall;

  useEffect(() => {
    const listings = tourInfo.listings;
    if (listings?.length) {
      fetchActions.dispatch({
        type: TourActionTypes.SD_CHANGE_TOUR_LISTING,
        payload: listings[0],
      });
    }
  }, [tourInfo]);
  useEffect(() => {
    if (listingInfo?.listing) setListing(listingInfo.listing);
  }, [listingInfo]);

  const listingTitle = useCallback((listing: GetListingObject): string => {
    const streetAddress = listing.building?.location?.split(',')[0];

    return `${streetAddress} - ${checkIfNumber(listing?.suiteNo) ? 'Suite ' : ''}${
      listing.suiteNo
    }`;
  }, []);

  useEffect(() => {
    if (listing) {
      document.title = `${
        tourInfo?.tourType === TOUR_TYPES.LIVE ? 'Live ' : ''
      } Virtual Tour - ${listingTitle(listing)}`;
    }
  }, [listingTitle, listing]);

  if (listing) {
    switch (tourState) {
      case TourStates.ENDED:
        if (isBroker) {
          return <BrokerTourEnded />;
        } else {
          return (
            <ParticipantTourEnded
              listing={listing}
              users={tourInfo?.additionalUsers}
              title={listingTitle(listing)}
            />
          );
        }
      case TourStates.NOT_INITIALIZED:
        return <Loader />;
      case TourStates.NOT_JOINED:
      case TourStates.NOT_JOINED_NOT_STARTED:
        if (isBroker) {
          return (
            <JoinTour
              title={listingTitle(listing)}
              onJoin={(name, email) => joinCall(name, email, true)}
              onStart={startCall}
              showStart={tourState === TourStates.NOT_JOINED_NOT_STARTED}
              me={me!}
            />
          );
        } else {
          return (
            <JoinTourTenant
              title={listingTitle(listing)}
              onJoin={joinCall}
              leaveCall={leaveCall}
              browser={browser}
            />
          );
        }
      case TourStates.STARTED:
        if (
          ((tourInfo?.tourType === TOUR_TYPES.LIVE && isHost) ||
            tourInfo?.tourType === TOUR_TYPES.PRESENT) &&
          listingInfo
        ) {
          return (
            <ListingTourView
              listingData={listingInfo}
              videoCall={videoCall}
              tourType={tourInfo.tourType}
              isBroker={isBroker}
            />
          );
        } else {
          if (host) {
            return <FullVideo videoCall={videoCall} leaveCall={leaveCall} browser={browser} />;
          } else {
            return (
              <ParticipantStandBy
                title={listingTitle(listing)}
                leaveCall={leaveCall}
                browser={browser}
              />
            );
          }
        }
      case TourStates.JOINED_NOT_STARTED:
        if (isHost) {
          return (
            <ScreenshareInstructions
              reselectTab={reselectTab}
              leaveCall={leaveCall}
              browser={browser}
            />
          );
        } else {
          return (
            <ParticipantWaiting
              listing={listing}
              title={listingTitle(listing)}
              users={tourInfo?.additionalUsers}
              leaveCall={leaveCall}
              browser={browser}
            />
          );
        }
    }
  } else return <></>;
};

export default TourWrapper;
