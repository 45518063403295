import { getBrokersAPI } from './users.api';
import { UsersActions } from './users.types';
import { ErrorTypes } from '../../types/error.types';
import fetchActions from '../../utils/state.utils';
import { _dataValid } from '../../utils/apiValidatior';

export const getBrokers = async (companyId: string) => {
  const actionName = UsersActions.GET_BROKERS;

  fetchActions.callCT(actionName);
  fetchActions.init(actionName);

  try {
    const res = await getBrokersAPI(companyId, fetchActions.setCT(actionName));
    if (_dataValid(res.data)) {
      fetchActions.success(actionName, res.data);
      return res.data;
    } else {
      fetchActions.failure(actionName, ErrorTypes.invalidResponse);
      throw ErrorTypes.invalidResponse;
    }
  } catch (error) {
    fetchActions.failure(actionName, error);
  }
};
