import React from 'react';

const AboutGifs: React.FC = () => {
  return (
    <div className="gif--component">
      <div className="container container--expand">
        <div className="row row--space-10 row--gif">
          <div className="col col-6 col-md-4 col-lg-2">
            <div className="gif--box">
              <img src="/images/Zak-GIF_Small.gif" alt="gif" />
            </div>
          </div>
          <div className="col col-6 col-md-4 col-lg-2">
            <div className="gif--box">
              <img src="/images/Austins-GIF_Small.gif" alt="gif" />
            </div>
          </div>
          <div className="col col-6 col-md-4 col-lg-2">
            <div className="gif--box">
              <img src="/images/Hibba-GIF_Small.gif" alt="gif" />
            </div>
          </div>
          <div className="col col-6 col-md-4 col-lg-2">
            <div className="gif--box">
              <img src="/images/Marty-GIF_Small.gif" alt="gif" />
            </div>
          </div>
          <div className="col col-6 col-md-4 col-lg-2">
            <div className="gif--box">
              <img src="/images/Hayley-GIF_Small.gif" alt="gif" />
            </div>
          </div>
          <div className="col col-6 col-md-4 col-lg-2">
            <div className="gif--box">
              <img src="/images/Afzaal-GIF_Small.gif" alt="gif" />
            </div>
          </div>
          <div className="col col-6 col-md-4 col-lg-2">
            <div className="gif--box">
              <img src="/images/Marissas-GIF_Small.gif" alt="gif" />
            </div>
          </div>
          <div className="col col-6 col-md-4 col-lg-2">
            <div className="gif--box">
              <img src="/images/Pablos-GIF_Small.gif" alt="gif" />
            </div>
          </div>
          <div className="col col-6 col-md-4 col-lg-2">
            <div className="gif--box">
              <img src="/images/Georges-GIF_Small.gif" alt="gif" />
            </div>
          </div>
          <div className="col col-6 col-md-4 col-lg-2">
            <div className="gif--box">
              <img src="/images/Stephens-GIF_Small.gif" alt="gif" />
            </div>
          </div>
          <div className="col col-6 col-md-4 col-lg-2">
            <div className="gif--box">
              <img src="/images/Zain-GIF_Small.gif" alt="gif" />
            </div>
          </div>
          <div className="col col-6 col-md-4 col-lg-2">
            <div className="gif--box">
              <img src="/images/Qasim-GIF_Small.gif" alt="gif" />
            </div>
          </div>
          <div className="col col-6 col-md-4 col-lg-2">
            <div className="gif--box">
              <img src="/images/Talha-GIF_Small.gif" alt="gif" />
            </div>
          </div>
          <div className="col col-6 col-md-4 col-lg-2">
            <div className="gif--box">
              <img src="/images/Ali-GIF_Small.gif" alt="gif" />
            </div>
          </div>
          <div className="col col-6 col-md-4 col-lg-2">
            {/*<div className="gif--box">
              <img src="https://media0.giphy.com/media/3o7WTAkv7Ze17SWMOQ/giphy.gif" alt="gif" />
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutGifs;
