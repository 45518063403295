import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { TourListingsSelected } from '../../../../../state/subdomain/tour/tour.types';
import { SEPARATORS } from '../../../../../common/constants';
import { RecentListing } from '../../../../../state/listing/listing.types';
import { compileAvatarUrl } from '../../../../../utils/avatar.utils';
import NewTourAddListingBlockShimmer from './loading';
import { useChangeShadowEffect } from '../../../../../state/tour/tour.hook';
import { checkIfNumber } from '../../../../../utils/misc.utils';

interface Props {
  selected: TourListingsSelected[];
  data: RecentListing[];
  onSelect: (e: RecentListing) => void;
  loading: boolean;
  firstFetch: boolean;
  fetchMore: () => void;
}
const ListingBox: React.FC<Props> = props => {
  const { selected, onSelect, data, loading, firstFetch, fetchMore } = props;
  const [list, setList] = useState([] as RecentListing[]);
  const { containerRef, showShadowEffect } = useChangeShadowEffect('Y');

  const getName = (e: RecentListing): string => {
    return `${e?.building?.location?.split(SEPARATORS.COMMA)[0]} - ${
      checkIfNumber(e?.suiteNo) ? 'Suite ' : ''
    }${e?.suiteNo}`;
  };

  useEffect(() => {
    if (data && Array.isArray(data)) {
      if (!selected.length) setList(data);
      else {
        const arr = data;
        const removableIds = selected.map(e => e.id);
        const res = arr.filter(listing => !removableIds.includes(listing._id));
        if (res.length && res.length < 4) {
          fetchMore();
        }
        setList(res);
      }
    }
  }, [data, selected]);

  return (
    <div
      className={`tourListing--canvas ${
        showShadowEffect ? 'tourListing--canvas-shadow-effect' : ''
      }`}
    >
      <div className="tourListing--scroll" id="listingScrollTour" ref={containerRef}>
        {firstFetch && loading && (
          <React.Fragment>
            <NewTourAddListingBlockShimmer />
            <NewTourAddListingBlockShimmer />
            <NewTourAddListingBlockShimmer />
            <NewTourAddListingBlockShimmer />
          </React.Fragment>
        )}
        {(!loading || !firstFetch) &&
          list?.map((listing, index) => {
            const allMedia = listing.media;
            let primary = allMedia.find(image => image.isPrimary);
            if (!primary) primary = allMedia[0];
            return (
              <div className="tourListing--block" key={index}>
                <span className="tourListing__img">
                  <img
                    src={compileAvatarUrl(primary.url)}
                    alt={primary.name}
                    className="img--cover"
                  />
                </span>
                <div className="tourListing--row">
                  <p className="para__md-dark">{getName(listing)}</p>
                  <Button
                    className="add__btn add__btn-blue ml-sm-2"
                    onClick={() => onSelect(listing)}
                  >
                    Add
                  </Button>
                </div>
              </div>
            );
          })}
        {!firstFetch && loading && (
          <React.Fragment>
            <NewTourAddListingBlockShimmer />
            <NewTourAddListingBlockShimmer />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ListingBox;
