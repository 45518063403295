import * as yup from 'yup';

export enum LoginFormFields {
  EMAIL = 'email',
  PASSWORD = 'password',
}

export const LoginFormSchema = yup.object().shape({
  [LoginFormFields.EMAIL]: yup
    .string()
    .required('This field is required')
    .email('Please enter a valid email address'),
  [LoginFormFields.PASSWORD]: yup.string().required('This field is required'),
});

export enum ForgotPasswordFormFields {
  EMAIL = 'email',
}

export const ForgotPasswordFormSchema = yup.object().shape({
  [ForgotPasswordFormFields.EMAIL]: yup
    .string()
    .required('This field is required')
    .email('Please enter a valid email address'),
});

export enum ResetPasswordFormFields {
  PASSWORD = 'password',
  CONFIRM_PASSWORD = 'confirmPassword',
}

export const ResetPasswordFormSchema = yup.object().shape({
  [ResetPasswordFormFields.PASSWORD]: yup
    .string()
    .test(
      ResetPasswordFormFields.PASSWORD,
      'Password must be of at least 8 characters',
      value => value.length >= 8,
    )
    .required('This field is required'),
  [ResetPasswordFormFields.CONFIRM_PASSWORD]: yup
    .string()
    .test(
      ResetPasswordFormFields.CONFIRM_PASSWORD,
      'Confirm Password must be of at least 8 characters',
      value => value.length >= 8,
    )
    .oneOf([yup.ref(ResetPasswordFormFields.PASSWORD)], 'Confirm password must match your password')
    .required('This field is required'),
});

export enum ChangePasswordFormFields {
  CURRENT_PASSWORD = 'oldPassword',
  NEW_PASSWORD = 'newPassword',
  CONFIRM_NEW_PASSWORD = 'confirmNewPassword',
}

export const ChangePasswordFormSchema = yup.object().shape({
  [ChangePasswordFormFields.CURRENT_PASSWORD]: yup
    .string()
    .test(
      ResetPasswordFormFields.PASSWORD,
      'Password must be of at least 8 characters',
      value => value.length >= 8,
    )
    .required('This field is required'),
  [ChangePasswordFormFields.NEW_PASSWORD]: yup
    .string()
    .test(
      ResetPasswordFormFields.PASSWORD,
      'Password must be of at least 8 characters',
      value => value.length >= 8,
    )
    .notOneOf(
      [yup.ref(ChangePasswordFormFields.CURRENT_PASSWORD)],
      'New password must be different from current password',
    )
    .required('This field is required'),
  [ChangePasswordFormFields.CONFIRM_NEW_PASSWORD]: yup
    .string()
    .test(
      ChangePasswordFormFields.CONFIRM_NEW_PASSWORD,
      'Confirm Password must be of at least 8 characters',
      value => value.length >= 8,
    )
    .oneOf(
      [yup.ref(ChangePasswordFormFields.NEW_PASSWORD)],
      'Confirm password must match your password',
    )
    .required('This field is required'),
});
