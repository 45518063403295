import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';

const ListingDetails: React.FC = () => {
  const data = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing.data,
  );

  const recalculateFontSize = (parentBlockId: string, textId: string) => {
    const blockEl = document.getElementById(parentBlockId);
    if (blockEl) {
      const leftPadding = parseFloat(
        window.getComputedStyle(blockEl).getPropertyValue('padding-left'),
      );
      const rightPadding = parseFloat(
        window.getComputedStyle(blockEl).getPropertyValue('padding-right'),
      );
      const textEl = document.getElementById(textId);
      if (textEl) {
        const textFontSize = parseFloat(
          window.getComputedStyle(textEl).getPropertyValue('font-size'),
        );
        if (leftPadding + textEl.scrollWidth + rightPadding > blockEl.clientWidth) {
          textEl.style.fontSize = `${textFontSize - 1}px`;
          recalculateFontSize(parentBlockId, textId);
        }
      }
    }
  };

  useLayoutEffect(() => {
    if (data) {
      recalculateFontSize('sizeBlock', 'size');
      recalculateFontSize('rentBlock', 'rent');
      recalculateFontSize('metroBlock', 'metro');
    }
  }, [data]);

  return (
    <div className="row row--space-10 pt-1 mb-2 mb-xl-0">
      <div className="col col-12 col-sm-4">
        <div className="key--box key--box-alt" id="sizeBlock">
          <div className="keyBox--head">
            <i className="icon-size"></i>
            <label className="small__heading">Size</label>
          </div>
          <h3 className="heading__medium heading__medium-dark" id="size">
            {data?.size ? data?.size.toLocaleString() : 'N/A'}
          </h3>
          {data?.size && <span className="small__paragraph">SF</span>}
        </div>
      </div>
      <div className="col col-12 col-sm-4">
        <div className="key--box key--box-alt" id="rentBlock">
          <div className="keyBox--head">
            <i className="icon-month"></i>
            <label className="small__heading">Monthly Rent</label>
          </div>
          <h3 className="heading__medium heading__medium-dark" id="rent">
            {data?.monthlyRent ? `$${data?.monthlyRent.toLocaleString()}` : 'N/A'}
          </h3>
          {data?.monthlyRent && <span className="small__paragraph">/SF/YR</span>}
        </div>
      </div>
      <div className="col col-12 col-sm-4">
        <div className="key--box key--box-alt">
          <div className="keyBox--head">
            <i className="icon-users"></i>
            <label className="small__heading">Seats</label>
          </div>
          <h3 className="heading__medium heading__medium-dark">
            {data?.capacity ? data?.capacity : 'N/A'}
          </h3>
          {data?.capacity && <span className="small__paragraph">Employees</span>}
        </div>
      </div>
      <div className="col col-12">
        <div className="key--box key--box-alt" id="metroBlock">
          <div className="keyBox--head">
            <i className="icon-metro"></i>
            <label className="small__heading">Metro Proximity</label>
          </div>
          <span className="small__paragraph" id="metro">
            {data?.metroProximity ? data?.metroProximity : 'N/A'}
          </span>
        </div>
      </div>
    </div>
  );
};
export default ListingDetails;
