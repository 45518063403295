import React from 'react';
import { useLeftSidebarToggle } from '../../state/leftSidebar/leftSidebar.hook';

interface Props {
  className?: string;
}
const HamBurger: React.FC<Props> = props => {
  const { className } = props;
  const { toggleLeftSidebar } = useLeftSidebarToggle();

  return (
    <button
      onClick={toggleLeftSidebar}
      className={className ? `${className} toggle__btn` : 'toggle__btn'}
    >
      <i className="icon-menu"></i>
    </button>
  );
};

export default HamBurger;
