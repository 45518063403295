import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Form } from 'react-bootstrap';
import { useGetAllTours } from '../../state/tour/tour.hook';
import { debounce } from 'lodash';
import { Link } from 'react-router-dom';
import { GetAllToursDataInterface, RoomListingsInterface } from '../../state/tour/tour.types';
import { DEFAULT_LISTING_COUNT_IN_TOUR, SEPARATORS, TOUR_TYPES } from '../../common/constants';
import { checkIfNumber, copyTourLinkToClipboard } from '../../utils/misc.utils';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../state';
import TourBlockShimmer from '../tour/view/loading';

const UpcomingTours: React.FC = () => {
  const [showTours, setShowTours] = useState(true);
  const { res: allToursResponse, getAllUpcomingTours } = useGetAllTours();
  const { loading, hasData, data: tours } = allToursResponse;
  const [search, setSearch] = useState('');
  const [showCount, setShowCount] = useState(DEFAULT_LISTING_COUNT_IN_TOUR);
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const meLoading = useSelector((state: AppStateInterface) => state.me.me.loading);

  useEffect(() => {
    getAllUpcomingTours({
      search,
    });
  }, [search]);

  const onSearchChange = debounce((value: string) => {
    setSearch(value);
  }, 300);

  return (
    <Accordion defaultActiveKey="0" className="dashboard--accordian mb-20">
      <Card className="dashboard--card mb-20">
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey="0"
            onClick={() => setShowTours(prevState => !prevState)}
          >
            Upcoming Tours
            <i className={showTours ? 'icon-chevron-down-solid' : 'icon-chevron-right'}></i>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="mt-3">
            {(!tours || tours?.data?.length > 0 || search || loading) && (
              <React.Fragment>
                {meLoading ? (
                  <div className="row row--space-10 mb-4 pb-2">
                    <div className="col col-12 col-md-4 col-xl-5">
                      <div className="search--shimmer">
                        <span className="searchShimmer__icon animate--gray"></span>
                        <span className="searchShimmer__text animate--gray"></span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row row--space-10 mb-4 pb-2">
                    <div className="col col-12 col-md-4 col-xl-5">
                      <Form.Group className="form--group-alt search--group-alt mb-0 w-100">
                        <Form.Control
                          type="search"
                          placeholder="Search Upcoming Tours"
                          onChange={(e: any) => onSearchChange(e.target.value)}
                        />
                        <span className="search__icon">
                          <i className="icon-search"></i>
                        </span>
                      </Form.Group>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
            {(loading || !tours || tours?.data?.length > 0) && (
              <React.Fragment>
                <div className="upcomingTour--canvas">
                  {loading && (
                    <React.Fragment>
                      <TourBlockShimmer />
                      <TourBlockShimmer />
                      <TourBlockShimmer />
                    </React.Fragment>
                  )}
                  {tours?.data?.map((tour: GetAllToursDataInterface) => (
                    <div className="upcomingTour--block mb-20" key={tour._id}>
                      <div className="upcomingTour--main">
                        <div className="upcomingTour--left">
                          {tour?.tourType === TOUR_TYPES.LIVE && (
                            <React.Fragment>
                              <span className="upComTour__icon">
                                <i className="icon-live-integrate"></i>
                              </span>
                              <span className="upComTour__name">
                                Live Integrated
                                <br /> Video
                              </span>
                            </React.Fragment>
                          )}
                          {tour?.tourType === TOUR_TYPES.PRESENT && (
                            <React.Fragment>
                              <span className="upComTour__icon">
                                <i className="icon-tv-alt"></i>
                              </span>
                              <span className="upComTour__name">
                                Tour Only
                                <br /> (Presentation Style)
                              </span>
                            </React.Fragment>
                          )}
                        </div>
                        <div className="upcomingTour--right">
                          <ul className="upComTour__list">
                            {tour?.clientInfo && tour?.clientInfo?.company && (
                              <li>
                                <span className="upComTour__list-icon">
                                  <i className="icon-schedule-dashboard"></i>
                                </span>
                                <span className="upComTour__list-text">
                                  {tour?.clientInfo?.firstName} {tour?.clientInfo?.lastName}{' '}
                                  {tour?.clientInfo?.brokerCompany
                                    ? `@${tour?.clientInfo?.brokerCompany}, Representing `
                                    : '@'}
                                  {tour?.clientInfo?.company && (
                                    <a className="link__dark">{tour?.clientInfo?.company}</a>
                                  )}
                                </span>
                              </li>
                            )}
                            <li>
                              <span className="upComTour__list-icon">
                                <i className="icon-floorplan"></i>
                              </span>
                              {tour?.listings.length === 0 &&
                                'There are no listings added to this tour'}
                              {tour?.listings.length > 0 &&
                                tour?.listings?.map(
                                  (
                                    listing: RoomListingsInterface,
                                    index: number,
                                    tourListings: RoomListingsInterface[],
                                  ) =>
                                    index < showCount ? (
                                      <span className="upComTour__list-text" key={index}>
                                        {listing?.building?.location?.split(SEPARATORS.COMMA)[0]},{' '}
                                        {checkIfNumber(listing?.suiteNo)
                                          ? `Suite ${listing?.suiteNo}`
                                          : listing?.suiteNo}
                                        {tourListings?.length > showCount &&
                                          index === DEFAULT_LISTING_COUNT_IN_TOUR - 1 && (
                                            <a
                                              className="link__blue-sm ml-2"
                                              onClick={() =>
                                                setShowCount(
                                                  tourListings?.length ||
                                                    DEFAULT_LISTING_COUNT_IN_TOUR,
                                                )
                                              }
                                            >
                                              + {tourListings.length - showCount} More
                                            </a>
                                          )}
                                        {tourListings?.length === showCount &&
                                          tourListings?.length > DEFAULT_LISTING_COUNT_IN_TOUR &&
                                          index + 1 === showCount && (
                                            <a
                                              className="link__blue-sm ml-2"
                                              onClick={() =>
                                                setShowCount(DEFAULT_LISTING_COUNT_IN_TOUR)
                                              }
                                            >
                                              Show Less
                                            </a>
                                          )}
                                      </span>
                                    ) : (
                                      <React.Fragment key={index}></React.Fragment>
                                    ),
                                )}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="upComTour--btn-canvas">
                        <Button
                          className="link__btn link__btn-blue"
                          onClick={() =>
                            copyTourLinkToClipboard(
                              tour._id,
                              tour.expiredAt,
                              me?.company?.domain,
                              tour?.tourUrl,
                            )
                          }
                        >
                          Copy Tour Link
                          <i className="icon-link"></i>
                        </Button>
                        <Link className="link__btn link__btn-gray" to={`/tour/${tour._id}/edit`}>
                          Edit
                          <i className="icon-edit-alt"></i>
                        </Link>
                      </div>
                    </div>
                  ))}
                  {tours?.hasMore && (
                    <div className="pt-2 pb-3">
                      <Link
                        className="link__blue"
                        to={
                          search
                            ? `/tours/active?search=${encodeURIComponent(search)}`
                            : '/tours/active'
                        }
                      >
                        View All Tours
                      </Link>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
            {!loading && tours?.data?.length === 0 && (
              <React.Fragment>
                {search ? (
                  <React.Fragment>
                    <div className="dashboard--notfound notfound--result">
                      <span className="notfound__result-img"></span>
                      <p className="para__lg para__lg-dark">
                        Sorry, there are no tours that match your search
                      </p>
                      <p className="para__md-gray mb-0">Please try again.</p>
                    </div>
                  </React.Fragment>
                ) : hasData ? (
                  <React.Fragment>
                    <div className="dashboard--newAccount">
                      <div className="container--xs">
                        <p className="para__md-gray mb-3 pb-1">
                          There are currently no upcoming tours in your account. You will see more
                          information here once you receive requests, or when you create a tour.
                        </p>
                        <Link className="admin__button button__radius" to="/tour/create">
                          Create Tour
                          <i className="icon-tour ml-2"></i>
                        </Link>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <></>
                )}
              </React.Fragment>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default UpcomingTours;
