import React, { useState } from 'react';
import ListingSpecifications from './specification';
import ListingAmenities from './amenities';

enum SPECS {
  SPECIFICATION = 'specification',
  AMENITIES = 'amenities',
}

const HandleTourSpecs: React.FC = () => {
  const [type, setType] = useState(SPECS.SPECIFICATION);

  return (
    <div className="tourEdit--top tourEdit--top-specs">
      <div className="liveTour--custom liveTour--wide py-0">
        <div className="container liveTour--container">
          <div className="liveTour--building liveTour--amenities tourEdit--specs">
            <div className="liveTour--building-top liveTour--amenities-top d-flex align-items-center mt-0">
              {type === SPECS.SPECIFICATION && (
                <h3 className="dashboard__heading d-flex align-items-center">Specifications</h3>
              )}
              {type === SPECS.AMENITIES && (
                <h3 className="dashboard__heading d-flex align-items-center">Building Amenities</h3>
              )}
              <ul className="specs__list">
                {type === SPECS.AMENITIES && (
                  <li onClick={() => setType(SPECS.SPECIFICATION)}>Specifications</li>
                )}
                {type === SPECS.SPECIFICATION && (
                  <li onClick={() => setType(SPECS.AMENITIES)}>Building Amenities</li>
                )}
              </ul>
            </div>
            {type === SPECS.SPECIFICATION && <ListingSpecifications />}
            {type === SPECS.AMENITIES && <ListingAmenities />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HandleTourSpecs;
