import React from 'react';
import { useParams } from 'react-router';

const TourBlockShimmer: React.FC = () => {
  const { type } = useParams();
  return (
    <div className="upcomingTour--block upcomingTour--shimmer mb-20">
      <div className="upcomingTour--main">
        <div className="upcomingTour--left">
          <span className="upComTour__icon animate"></span>
          <span className="upComTour__name animate"></span>
          <span className="upComTour__name animate"></span>
        </div>
        <div className="upcomingTour--right">
          <ul className="upComTour__list w-100">
            <li>
              <span className="upComTour__list-icon animate"></span>
              <span className="upComTour__list-text animate"></span>
            </li>
            <li>
              <span className="upComTour__list-icon animate"></span>
              <span className="upComTour__list-text animate"></span>
            </li>
            <li>
              <span className="upComTour__list-text animate"></span>
            </li>
          </ul>
        </div>
      </div>
      {type !== 'history' && (
        <div className="upComTour--btn-canvas">
          <span className="btn__span animate mb-20"></span>
          <span className="btn__span animate"></span>
        </div>
      )}
    </div>
  );
};

export default TourBlockShimmer;
