import React from 'react';

const AboutQoute: React.FC = () => {
  return (
    <div className="component--top pearl--bg">
      <div className="container container--expand">
        <div className="row text-center justify-content-center">
          <div className="col-12 col-md-10 col-xl-8">
            <h1 className="landing__heading">About Us</h1>
            <p className="landing__para mb-4">
              We believe that a product is as good as the people working on it. Through partnerships
              and collaboration we have found the right group of people to take our ideas to the
              finish line in a timely manner.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutQoute;
