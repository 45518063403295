import React, { useState, useEffect } from 'react';
import { UploadedMediaInterface } from '../../../state/media/media.types';
import { compact } from 'lodash';

enum Section {
  GALLERY = 'GALLERY',
  FLOOR_PLANS = 'FLOOR_PLANS',
}
interface Props {
  active: boolean;
  changeOptions: (e: boolean) => void;
  showOptions: boolean;
  setActive: () => void;
  index: number;
  changeIndex: (e: number) => void;
  closeExpand: () => void;
  data: UploadedMediaInterface[];
  additionalData: UploadedMediaInterface[];
  name: string;
}

const TourImageHandler: React.FC<Props> = (props: Props) => {
  const {
    active: activeSection,
    changeOptions: setGalleryOptions,
    setActive,
    showOptions: showGalleryOptions,
    index: galleryIndex,
    changeIndex: galleryChangeIndex,
    closeExpand,
    data,
    additionalData,
    name,
  } = props;

  const [media, setMedia] = useState([] as UploadedMediaInterface[]);
  useEffect(() => {
    setMedia(compact([...data, ...additionalData]));
  }, [data, additionalData]);

  return (
    <React.Fragment>
      {media?.length > 0 && (
        <li>
          {!activeSection && (
            <a
              className={`lt__list-box`}
              onClick={() => {
                setActive();
                setGalleryOptions(true);
              }}
            >
              <span className="lt__list_span">
                <span className="lt__list-icon">
                  {name === Section.GALLERY && <i className="icon-gallery"></i>}
                  {name === Section.FLOOR_PLANS && <i className="icon-floorplan"></i>}
                </span>
                {name === Section.GALLERY && <span className="lt__list-text">Gallery</span>}
                {name === Section.FLOOR_PLANS && <span className="lt__list-text">Floor Plan</span>}
              </span>
            </a>
          )}
          {activeSection && (
            <a className={`lt__list-box active`}>
              <span
                className="lt__list_span"
                onClick={() => setGalleryOptions(!showGalleryOptions)}
              >
                <span className="lt__list-icon">
                  {name === Section.GALLERY && <i className="icon-gallery"></i>}
                  {name === Section.FLOOR_PLANS && <i className="icon-floorplan"></i>}
                </span>
                {name === Section.GALLERY && <span className="lt__list-text">Gallery</span>}
                {name === Section.FLOOR_PLANS && <span className="lt__list-text">Floor Plan</span>}
              </span>
              {showGalleryOptions && media?.length > 1 && (
                <div className="tour--gallery-canvas active--content ml-2 flex-column flex-sm-row align-items-sm-center">
                  <div className="tourGallery--btns d-flex align-items-center">
                    <button
                      className="tour__btn gallery__btn gallery__btn-prev"
                      onClick={() => galleryChangeIndex(galleryIndex - 1)}
                      disabled={galleryIndex === -1 || galleryIndex === 0}
                    >
                      <span className="gallery__btn-icon">
                        <i className="icon-chevron-left"></i>
                      </span>
                      Previous
                    </button>
                    <button
                      className="tour__btn gallery__btn gallery__btn-next"
                      onClick={() => galleryChangeIndex(galleryIndex + 1)}
                      disabled={galleryIndex === -1 || galleryIndex === media.length - 1}
                    >
                      Next
                      <span className="gallery__btn-icon">
                        <i className="icon-chevron-right"></i>
                      </span>
                    </button>
                  </div>
                  {galleryIndex > -1 && (
                    <button
                      className="tour__btn gallery__btn gallery__btn-expanded"
                      onClick={() => {
                        galleryChangeIndex(-1);
                        closeExpand();
                      }}
                    >
                      Gallery View
                    </button>
                  )}
                  {galleryIndex === -1 && (
                    <button
                      className="tour__btn gallery__btn gallery__btn-expanded"
                      onClick={() => {
                        galleryChangeIndex(0);
                        closeExpand();
                      }}
                    >
                      Open First
                    </button>
                  )}
                </div>
              )}
            </a>
          )}
        </li>
      )}
    </React.Fragment>
  );
};

export default TourImageHandler;
