import { UploadedMediaInterface } from './../../media/media.types';
import { GetStateInterface } from './../../../types/GetStateInterface';
import { PhotoInterface } from '../../listing/listing.types';
import { AmenityMatterPortLink } from '../../building/building.types';

export interface ListingStateInterface {
  allListings: GetStateInterface<GetAllListingsResponseInterface>;
  singleListing: GetStateInterface<GetListingObject>;
}

export enum ListingActions {
  VIDEO_UPDATE = 'VIDEO_UPDATE',
  SUBMIT_TOUR_FORM = 'SUBMIT_TOUR_FORM',
  GET_TOUR_LISTINGS = 'GET_TOUR_LISTINGS',
  CHANGE_TOUR_LISTING = 'CHANGE_TOUR_LISTING',

  SD_GET_ALL_LISTINGS = 'SD_GET_ALL_LISTINGS',
  SD_GET_ALL_LISTINGS_INIT = 'SD_GET_ALL_LISTINGS_INIT',
  SD_GET_ALL_LISTINGS_CLEAR = 'SD_GET_ALL_LISTINGS_CLEAR',
  SD_GET_ALL_LISTINGS_FAILURE = 'SD_GET_ALL_LISTINGS_FAILURE',
  SD_GET_ALL_LISTINGS_SUCCESSFUL = 'SD_GET_ALL_LISTINGS_SUCCESSFUL',

  SD_GET_SINGLE_LISTING = 'SD_GET_SINGLE_LISTING',
  SD_GET_SINGLE_LISTING_INIT = 'SD_GET_SINGLE_LISTING_INIT',
  SD_GET_SINGLE_LISTING_CLEAR = 'SD_GET_SINGLE_LISTING_CLEAR',
  SD_GET_SINGLE_LISTING_FAILURE = 'SD_GET_SINGLE_LISTING_FAILURE',
  SD_GET_SINGLE_LISTING_SUCCESSFUL = 'SD_GET_SINGLE_LISTING_SUCCESSFUL',
}
export interface User {
  _id: string;
  avatar: string;
  firstName: string;
  lastName: string;
  phone: string;
  role: string;
  email: string;
}
export interface GetListingObject {
  _id: string;
  videoUrl?: string;
  videoStatus: string;
  isActive: boolean;
  status: string;
  users: User[];
  createdBy: CreatedBy;
  available: string;
  media: PhotoInterface[];
  floorPlans: UploadedMediaInterface[];
  renderings: UploadedMediaInterface[];
  videoDescription: string;
  matterPortLink: string;
  description: string;
  metroProximity: string;
  condition: string;
  capacity: string;
  monthlyRent: number | null;
  size: number;
  suiteNo: string;
  spaceUse: string;
  building: Building;
  pdfs: UploadedMediaInterface[];
  createdAt: string;
  updatedAt: string;
}

interface CreatedBy {
  _id: string;
  avatar: string;
  firstName: string;
  lastName: string;
}

export interface GetSubDomainListings {
  _id: string;
  subMarket: string;
  market: string;
  listings: GetListingObject[];
}

export interface GetAllListingsResponseInterface {
  data: GetSubDomainListings[];
  skip: number;
  hasMore: boolean;
}

export interface Building {
  _id: string;
  location: string;
  imageUrl?: string;
  subMarket: SubMarket;
  latitude?: string;
  longitude?: string;
  fullLocation?: any;
  yearBuilt?: number;
  yearRenovated?: number;
  floorsCount?: number;
  buildingSize?: number;
  averageFloor?: number;
  parkingRatio?: string;
  columnSpacingHeight?: number;
  columnSpacingWidth?: number;
  slabToSlabHeight?: string;
  passengerElevators?: string;
  freightElevators?: string;
  telecomProviders?: string;
  buildingHours?: string[];
  energyStarCertification?: string;
  leedCertification?: string;
  buildingAmenities?: string[];
  buildingAmenitiesMatterportLinks?: AmenityMatterPortLink[];
}

interface SubMarket {
  isActive: boolean;
  _id: string;
  name: string;
  market: string;
}

export type sortByTypes =
  | 'updatedAt'
  | 'monthlyRent'
  | 'size'
  | 'availableNow'
  | 'availableInNinety'
  | undefined;

export type orderByTypes = 'ascending' | 'descending' | undefined;

export interface SortAndOrderInterface {
  sortBy: sortByTypes;
  orderBy: orderByTypes;
}

export interface TourFormInterface {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phone: string;
  time: string;
  brokerCompany: string;
  questions: string;
  listingId: string;
}

export interface ListingFilters {
  sortBy: sortByTypes;
  orderBy?: orderByTypes;
}

export interface GetTourListingRequest {
  companyId: string;
  limit: number;
  skip: number;
  search?: string;
  sortBy?: sortByTypes;
  orderBy?: orderByTypes;
}

export interface GetTourListings {
  data: GetListingObject[];
  skip: number;
  hasMore: boolean;
}

export interface GetTourUrlResponseInterface {
  tourUrl: string;
}
