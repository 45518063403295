import * as yup from 'yup';

const INVALID_EMAIL_ERROR = 'Please enter valid email';
const REQUIRED_FIELD = 'This field is required';

export enum JoinTourFields {
  NAME = 'name',
  EMAIL = 'email',
}

export const JoinTourFormSchema = yup.object().shape({
  [JoinTourFields.NAME]: yup.string().required('This field is required'),
  [JoinTourFields.EMAIL]: yup
    .string()
    .email('Please enter valid email address')
    .required('This field is required'),
});
