import { TourOrderBy, TourSortBy } from '../state/tour/tour.types';
import { RecentOrderBy, RecentSortBy } from '../state/listing/listing.types';

export const RESET = 'RESET';

export const MALFORMED_URL_ERROR = `The link you entered does not look like a valid link. If someone gave you this link, you may need to ask them to check that it's correct.`;

export const IMAGE_LIMIT = 48;
export const TOUR_UPLOAD_LIMIT = 16;
export const DOC_LIMIT = 5;
export const DEFAULT_AVATAR = '/images/user-default.svg';
export const DOWNLOAD_GAP = 2;
export enum SEPARATORS {
  DASH = '-',
  COMMA = ',',
  SEMI_COLON = ';',
  SPACE = ' ',
  DOT = '.',
  SLASH = '/',
}
export enum LISTING_STATUS {
  DRAFT = 'draft',
  REVIEW = 'review',
  PUBLISHED = 'published',
}
export enum VIDEO_STATUS {
  PENDING = 'pending',
  REJECTED = 'rejected',
  ACCEPTED = 'accepted',
  UPLOADED = 'uploaded',
  VOICEOVER_SUBMITTED = 'voiceover_submitted',
}

export const DEFAULT_BUILDING_LIMIT = 10;

export const DEFAULT_LISTING_LIMIT = 10;

export enum USER_ROLES {
  ADMIN = 'admin',
  BROKER = 'broker',
}

export const ALLOWED_SUBDOMAINS_MAIN_APP = ['test', 'staging', 'hometour', 'localhost', 'www'];

export const COLOR_OPTIONS = [
  {
    value: '#0638E7',
    class: 'bg-blue',
    name: 'Blue',
  },
  {
    value: '#0099FF',
    class: 'bg-lightBlue',
    name: 'Light Blue',
  },
  {
    value: '#621EB8',
    class: 'bg-purple',
    name: 'Purple',
  },
  {
    value: '#EA5C0C',
    class: 'bg-orange',
    name: 'Orange',
  },
  {
    value: '#ED2C2C',
    class: 'bg-red',
    name: 'Red',
  },
  {
    value: '#219D35',
    class: 'bg-green',
    name: 'Green',
  },
  {
    value: '#C2FFF0',
    class: 'bg-mintGreen',
    name: 'Mint Green',
  },
  {
    value: '#000000',
    class: 'bg-black',
    name: 'Black',
  },
];

export const DEFAULT_LOC = { lat: 47.7511, lng: -120.7401 };

export const ALLOWED_LOGO_IMAGE_MIME_TYPES = [
  'image/png',
  'image/svg+xml',
  'image/jpeg',
  'image/jpg',
];

export const ALLOWED_LOGO_IMAGE_TYPES = 'png, jpeg, jpg & svg';

export const ALLOWED_EMAIL_LOGO_IMAGE_MIME_TYPES = ['image/png', 'image/jpeg', 'image/jpg'];

export const ALLOWED_EMAIL_LOGO_IMAGE_TYPES = 'png, jpeg & jpg';

export const ALLOWED_LOGO_MAX_SIZE_IN_MB = 1;
export const DATE_FORMAT = 'MM/DD/YYYY';

export enum NODE_ENV {
  PRODUCTION = 'production',
  STAGING = 'staging',
  TEST = 'test',
  DEVELOPMENT = 'development',
}

export const MS_BETWEEN_SPEAKER_CHANGE = 350;
export const MS_BETWEEN_NAME_REQUESTS = 2500;

export enum DailyCo {
  APP_MESSAGE = 'app-message',
  START_RECORD = 'recording-started',
  STOP_RECORD = 'recording-stopped',
  MEETING_JOIN = 'joined-meeting',
  PARTICIPANT_JOIN = 'participant-joined',
  PARTICIPANT_UPDATE = 'participant-updated',
  PARTICIPANT_LEFT = 'participant-left',
  MEETING_LEFT = 'left-meeting',
  SPEAKER_CHANGE = 'active-speaker-change',
}
export enum DailyMessage {
  SEND_NAME = 'sendName',
  REQUEST_NAME = 'requestName',
  END_TOUR = 'endTour',
}
export const GALLERY_IMAGES = 6;

export const MAXIMUM_AMENITIES_ALLOWED = 10;

export enum REVIEW_STATE {
  IDLE = 'idle',
  INITIALIZE = 'initialize',
  START = 'start',
  READY = 'ready',
  PAUSE = 'pause',
  RESUME = 'resume',
  STOP = 'stopped',
  FINISH = 'finish',
  DISABLE = 'disable',
  ERROR = 'failed',
}
export enum RECORDING_STATE {
  IDLE = 'idle',
  ACQUIRE = 'acquiring_media',
  READY = 'ready',
  RECORD = 'recording',
  STOP = 'stopped',
  ERROR = 'failed',
}
export const REVIEW_SEGMENTS = 1;
export const VIDEO_TIMER = 5;
export const VIDEO_TIME_INTERVAL = 100;
export const AUDIO_TIME_INVERVAL = 1000;
export const HOST_TOUR_WISTIA_ID = 'ekm8duecux';

export const JOIN_TOUR_WISTIA_ID = 'mrgzejrvc0';

export const COMMENT_REQUIRED_ERROR = 'Either text or image is required';

export const ALLOWED_COMMENT_IMAGE_MIME_TYPES = [
  'image/svg+xml',
  'image/jpeg',
  'image/jpg',
  'image/png',
];

export const ALLOWED_COMMENT_IMAGE_TYPES = 'png, jpeg, jpg & svg';

export const ALLOWED_COMMENT_IMAGE_MAX_SIZE_IN_MB = 10;

export enum TOUR_TYPES {
  LIVE = 'liveIntegratedVideoTour',
  PRESENT = 'presentationStyleTourOnly',
}

export const DEFAULT_UPCOMING_TOURS_LIMIT = 3;

export const DEFAULT_RECENT_LISTINGS_LIMIT = 4;

export const DEFAULT_TOURS_LIMIT = 10;

export const RECENT_LISTINGS_SORTING_OPTIONS = [
  {
    eventKey: 'createdAt/descending',
    value: 'Most Recent',
  },
  {
    eventKey: 'updatedAt/descending',
    value: 'Last Modified',
  },
  {
    eventKey: 'availableNow/ascending',
    value: 'Available Now',
  },
  {
    eventKey: 'availableInNinety/descending',
    value: 'Available in 90 Days',
  },
  {
    eventKey: 'size/descending',
    value: 'SF High to Low',
  },
  {
    eventKey: 'size/ascending',
    value: 'SF Low to High',
  },
  {
    eventKey: 'monthlyRent/descending',
    value: 'Price High to Low',
  },
  {
    eventKey: 'monthlyRent/ascending',
    value: 'Price Low to High',
  },
];

export const TOURS_SORTING_OPTIONS = [
  {
    eventKey: 'updatedAt/descending',
    value: 'Last Modified',
  },
  {
    eventKey: 'tourType/ascending',
    value: 'Live Integrated Video (Descending)',
  },
  {
    eventKey: 'tourType/descending',
    value: 'Tour Only (Descending)',
  },
];

export const DEFAULT_TOUR_OPTION_VALUE = 'Last Modified';

export const DEFAULT_RECENT_LISTINGS_OPTION_VALUE = 'Recently Created';

export const DEFAULT_TOUR_SORT_BY: TourSortBy = 'updatedAt';

export const DEFAULT_TOUR_ORDER_BY: TourOrderBy = 'descending';

export const DEFAULT_RECENT_LISTING_SORT_BY: RecentSortBy = 'createdAt';

export const DEFAULT_RECENT_LISTING_ORDER_BY: RecentOrderBy = 'descending';

export const DEFAULT_INTERCOM_CUSTOM_LAUNCHER_ID = 'needHelp';

export const DEFAULT_LISTING_COUNT_IN_TOUR = 2;

export const ALLOWED_BROWSERS_FOR_PARTICIPANT = ['Chrome', 'Safari', 'Firefox'];

export const ALLOWED_BROWSERS_FOR_HOST = ['Chrome'];
