import React, { useState, useEffect } from 'react';
import { GetListingObject } from '../../../../state/listing/listing.types';
import ReactPlayer from 'react-player';
import { LISTING_STATUS, VIDEO_STATUS, USER_ROLES } from '../../../../common/constants';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import { UploadedMediaInterface } from '../../../../state/media/media.types';
import { useHistory } from 'react-router';
import { AppStateInterface } from '../../../../state';
import { useSelector } from 'react-redux';

interface Props {
  info: GetListingObject;
}
const ListingVideo: React.FC<Props> = props => {
  const { info: listing } = props;
  const history = useHistory();
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const [mainImage, setMainImage] = useState({} as UploadedMediaInterface);
  const [showVideo, updateShow] = useState(false);
  useEffect(() => {
    if (listing.media) {
      const media = listing.media;
      let primaryImage = media.find(image => image.isPrimary === true);
      if (!primaryImage) primaryImage = media[0];
      setMainImage(primaryImage);
    }
  }, [listing]);
  useEffect(() => {
    if (listing.status !== LISTING_STATUS.DRAFT) {
      updateShow(true);
    } else {
      updateShow(false);
    }
  }, [listing]);
  return (
    <div className="col-12 col-lg-6">
      <div className="video--block listing--video mb-4">
        {showVideo && listing.videoUrl && (
          <ReactPlayer
            url={listing.videoUrl}
            width="100%"
            height="100%"
            muted={listing?.videoStatus === VIDEO_STATUS.PENDING}
          />
        )}
        {(!showVideo || !listing.videoUrl) && listing.media?.length > 0 && (
          <img src={compileAvatarUrl(mainImage.url)} alt={mainImage.name} />
        )}
      </div>
      {(listing?.users?.find(user => user._id === me?._id) || me?.role === USER_ROLES.ADMIN) &&
        listing.status === LISTING_STATUS.REVIEW &&
        listing?.videoStatus === VIDEO_STATUS.PENDING &&
        listing.videoUrl && (
          <div className="approve--video">
            <span className="av-mute">
              <i className="icon-mute"></i>
            </span>
            <b className="mr-1">Your video has no audio:</b> you can now{' '}
            <a
              className="av__link d-inline-block ml-1"
              onClick={() => history.push(`review/${listing._id}`)}
            >
              record audio
            </a>
          </div>
        )}
    </div>
  );
};

export default ListingVideo;
