import React from 'react';
import LeftSidebar from '../../components/leftSidebar';
import HamBurger from '../../components/leftSidebar/hamburger';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../state';
import { compileAvatarUrl } from '../../utils/avatar.utils';

function GreatJob() {
  const me = useSelector((state: AppStateInterface) => state.me.me.data);

  return (
    <div className="dashboard--page building--listing">
      <LeftSidebar />
      <div className="dashboard--content listingFinal--content">
        <div className="dashboard--container">
          <div className="dashboard--canvas">
            <div className="dashboard--opt">
              <HamBurger className="menu__toggle" />
            </div>
            <div className="row mx-0">
              <div className="col-12 col-xl-8 offset-xl-2 px-0">
                <div className="listingFinal--detail">
                  <a className="listingFinal__logo">
                    <img
                      src={
                        me?.company?.settings.darkIcon
                          ? compileAvatarUrl(me?.company?.settings.darkIcon.url)
                          : '/images/HomeTour_Dark_Icon.svg'
                      }
                      alt="Img"
                    />
                  </a>
                  <h3 className="dashboard__heading mb-5">Great Job!</h3>
                  <p className="dashboard__paragraph text__gray mb-28">
                    Your listing has been created. If you added a Matterport to your listings we
                    will be sending you a 90 second video tour for each listing in the next 24
                    hours. You will be notified via email once we have uploaded the video.
                  </p>
                  <p className="dashboard__paragraph text__gray mb-0">
                    If you want to publish the listing without a video, go into the listing page and
                    click the button that says {`"`}Publish Without Video{`"`}.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GreatJob;
