import React, { useEffect, useRef, useState } from 'react';
import { Accordion, Card, Button, Dropdown } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import SmallLoader from '../../../../common/small.loader';
import { useGetExistingSubMarketsList } from '../../../../../state/subdomain/submarket/submarket.hook';
import {
  ExistingSubMarketInterface,
  ExistingSubMarketListResponseInterface,
} from '../../../../../state/subdomain/submarket/submarket.types';

interface Props {
  subMarket: string;
  setSubMarket: (subMarket: string) => void;
}

const LocationFilter: React.FC<Props> = (props: Props) => {
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );
  const { res: subMarketsListResponse, getExistingSubMarketsList } = useGetExistingSubMarketsList();
  const dropdownRef = useRef(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [subMarkets, setSubMarkets] = useState<Array<ExistingSubMarketInterface>>([]);

  const handleClickOutsideDropdown = (event: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutsideDropdown);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsideDropdown);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (company?._id) {
      getExistingSubMarketsList(company?._id);
    }
  }, [company]);

  useEffect(() => {
    if (!subMarketsListResponse.loading && subMarketsListResponse.data) {
      let allSubMarkets: Array<ExistingSubMarketInterface> = [];
      subMarketsListResponse.data?.forEach(
        (market: ExistingSubMarketListResponseInterface) =>
          (allSubMarkets = allSubMarkets.concat(market.subMarkets)),
      );
      setSubMarkets(allSubMarkets);
    }
  }, [subMarketsListResponse]);

  const findSubMarket = (subMarketId: string) => {
    return subMarkets.find((subMarket: ExistingSubMarketInterface) => subMarket.id === subMarketId)
      ?.subMarket;
  };

  const onDropdownSelect = (selectedValue: string) => {
    props.setSubMarket(selectedValue);
    setShowDropdown(false);
  };

  return (
    <Dropdown
      ref={dropdownRef}
      show={showDropdown}
      className="listing--dropdown listing--dropdown-update listing--dropdown-pad dropdown--radius mb-3 mb-md-0 spinner--group"
    >
      <Dropdown.Toggle id="dropdown-basic" onClick={() => setShowDropdown(prevState => !prevState)}>
        {props.subMarket && findSubMarket(props.subMarket)
          ? findSubMarket(props.subMarket)
          : 'Select Location (All)'}
        <span className="select__caret-down">
          <i className="icon-caret-down"></i>
        </span>
        {subMarketsListResponse.loading && <SmallLoader />}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <label className="checkbox--set dropdown--checkbox" onClick={() => onDropdownSelect('')}>
          All
          <input type="checkbox" disabled checked={!props.subMarket} />
          <span className="checkmark"></span>
        </label>
        {subMarketsListResponse.data &&
          subMarketsListResponse.data.length > 0 &&
          subMarketsListResponse.data.map(
            (market: ExistingSubMarketListResponseInterface, index: number) => {
              return (
                <React.Fragment key={index}>
                  <Accordion className="listing--accordian">
                    <Card className="listing--card">
                      <Card.Header>
                        <Accordion.Toggle as={Button} variant="link" eventKey={index.toString()}>
                          {`All ${market.market} (${market.subMarkets.length})`}
                          <span className="accordian__icon accrodian__plus">
                            <i className="icon-plus"></i>
                          </span>
                          <span className="accordian__icon accrodian__minus">
                            <i className="icon-minus"></i>
                          </span>
                        </Accordion.Toggle>
                      </Card.Header>
                      <Accordion.Collapse eventKey={index.toString()}>
                        <Card.Body>
                          {market.subMarkets?.map(
                            (subMarket: ExistingSubMarketInterface, index: number) => {
                              return (
                                <Dropdown.Item
                                  key={index}
                                  eventKey={subMarket.id}
                                  onSelect={onDropdownSelect}
                                  active={subMarket.id === props.subMarket}
                                >
                                  {subMarket.subMarket}
                                </Dropdown.Item>
                              );
                            },
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </React.Fragment>
              );
            },
          )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LocationFilter;
