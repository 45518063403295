import React from 'react';
import ListingBackButton from './backButton';
import ListingAddress from './address';
import ListingDetails from './detail';

const ListingBasicInfo: React.FC = () => {
  return (
    <div className="col-12 col-xl-6">
      <ListingBackButton />
      <ListingAddress />
      <ListingDetails />
    </div>
  );
};

export default ListingBasicInfo;
