import React, { useState, useEffect } from 'react';
import { TourListingInterface } from '../../../../../state/tour/tour.types';
import { SEPARATORS } from '../../../../../common/constants';
import { checkIfNumber } from '../../../../../utils/misc.utils';

interface Props {
  data: TourListingInterface;
  close: boolean;
}
const OptionBeltInfo: React.FC<Props> = props => {
  const { data } = props;

  const [suiteNo, setSuiteNo] = useState('');
  const [building, setBuilding] = useState('');
  useEffect(() => {
    if (data) {
      setSuiteNo(data.suiteNo);
      if (data.building?.location) setBuilding(data.building.location.split(SEPARATORS.COMMA)[0]);
    }
  }, [data]);

  return (
    <div className="tel--top">
      <p className="small__paragraph mb-0">
        {!close ? (
          <React.Fragment>
            <i className="icon-floorplan-alt mr-2"></i>
            {building} - {suiteNo}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <i className="icon-floorplan-alt"></i>
            <span className="telTop__span">{building}</span>
            <span className="telTop__span">
              <em className="mx-1">-</em>
              {checkIfNumber(suiteNo) ? 'Suite ' : ''}
              {suiteNo}
            </span>
          </React.Fragment>
        )}
      </p>
    </div>
  );
};

export default OptionBeltInfo;
