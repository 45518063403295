import { useCallback, useState } from 'react';
import { GetState, GetStateInterface } from '../../../types/GetStateInterface';
import { ExistingSubMarketListResponseInterface } from './submarket.types';
import { getExistingSubMarketsListAPI } from './submarket.api';

export const useGetExistingSubMarketsList = () => {
  const [res, setRes] = useState<GetStateInterface<Array<ExistingSubMarketListResponseInterface>>>(
    GetState,
  );

  const getExistingSubMarketsList = useCallback(async (companyId: string) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    try {
      const res = await getExistingSubMarketsListAPI(companyId);
      setRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);

  return { res, getExistingSubMarketsList };
};
