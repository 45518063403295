import React, { useEffect, useState } from 'react';
import { TourListingsSelected } from '../../../../../state/subdomain/tour/tour.types';
import ListingSearchBox from './searchBox';
import { useGetRecentListings } from '../../../../../state/listing/listing.hook';
import { debounce } from 'lodash';
import { DEFAULT_LISTING_LIMIT, LISTING_STATUS, SEPARATORS } from '../../../../../common/constants';
import { RecentListing } from '../../../../../state/listing/listing.types';
import { checkIfNumber, showNotification } from '../../../../../utils/misc.utils';
import { errorExtractor } from '../../../../../utils/error.utils';
import ListingBox from './listingBox';

interface Props {
  selected: TourListingsSelected[];
  onSelect: (e: TourListingsSelected) => void;
}
const AllListings: React.FC<Props> = props => {
  const { selected, onSelect } = props;
  const [list, setList] = useState([] as RecentListing[]);
  const [search, setSearch] = useState('');
  const [localSearch, setLocalSearch] = useState(false);
  const [firstFetch, setFirstFetch] = useState(true);
  const { res: listingRes, getRecentListings } = useGetRecentListings();

  const getName = (e: RecentListing): string => {
    return `${e?.building?.location?.split(SEPARATORS.COMMA)[0]} - ${
      checkIfNumber(e?.suiteNo) ? 'Suite ' : ''
    }${e?.suiteNo}`;
  };

  const addListing = (e: RecentListing) => {
    onSelect({ name: getName(e), id: e._id });
  };

  useEffect(() => {
    if (!localSearch) {
      setFirstFetch(true);
      getRecentListings({
        search,
        limit: DEFAULT_LISTING_LIMIT,
        skip: 0,
        status: LISTING_STATUS.PUBLISHED,
      });
    } else {
      setList(
        listingRes?.data?.data?.filter(
          listing =>
            listing?.suiteNo?.includes(search) || listing?.building?.location?.includes(search),
        ) || [],
      );
    }
  }, [search]);

  const fetchMore = () => {
    if (listingRes?.data?.hasMore) {
      setFirstFetch(false);
      getRecentListings(
        {
          search,
          limit: DEFAULT_LISTING_LIMIT,
          skip: listingRes?.data?.skip || 0,
          status: LISTING_STATUS.PUBLISHED,
        },
        listingRes?.data?.data,
      );
    }
  };

  useEffect(() => {
    const container = document.getElementById('listingScrollTour');
    if (container) {
      container.onscroll = debounce(() => {
        if (
          listingRes.error ||
          listingRes.loading ||
          (listingRes.data && !listingRes?.data?.hasMore)
        )
          return <></>;

        if (
          Math.abs(
            Math.floor(container.scrollHeight - container.scrollTop) - container.clientHeight,
          ) < Math.floor(container.clientHeight * 0.2)
        ) {
          fetchMore();
        }
      }, 100);
    }
  });

  useEffect(() => {
    if (!listingRes.loading && listingRes.hasData) {
      if (listingRes.error)
        showNotification('error', 'Fetching Error', errorExtractor(listingRes.error));
      else if (listingRes.data && Array.isArray(listingRes.data.data))
        setList(listingRes.data.data);
    }
  }, [listingRes]);

  const onSearchChange = (newSearchValue: string) => {
    setSearch(prevState => {
      if (!prevState && listingRes?.data?.hasMore === false && selected?.length) {
        setLocalSearch(true);
      } else if (selected?.length === 0) {
        setLocalSearch(false);
      }
      return newSearchValue;
    });
  };

  return (
    <div className="tourListing--content">
      <ListingSearchBox onSubmit={onSearchChange} />
      <ListingBox
        data={list}
        selected={selected}
        onSelect={addListing}
        loading={listingRes.loading}
        firstFetch={firstFetch}
        fetchMore={fetchMore}
      />
    </div>
  );
};

export default AllListings;
