import React, { useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import useForm from 'react-hook-form';
import {
  ResetPasswordFormFields,
  ResetPasswordFormSchema,
} from '../../state/login/forms/login.form';
import { useForgotAndResetPassword } from '../../state/login/login.hook';
import { useHistory, useParams } from 'react-router';
import SmallLoader from '../common/small.loader';
import { showNotification } from '../../utils/misc.utils';
import { errorExtractor } from '../../utils/error.utils';

const ResetPasswordForm = () => {
  const history = useHistory();
  const { memberId, token } = useParams();
  const { register, handleSubmit, errors } = useForm({
    validationSchema: ResetPasswordFormSchema,
  });
  const { res: resetPasswordRes, resetPassword } = useForgotAndResetPassword();

  useEffect(() => {
    if (!token || !memberId) {
      history.push('/login');
    }
  }, [memberId, token]);

  useEffect(() => {
    if (!resetPasswordRes.loading) {
      if (resetPasswordRes.data) {
        showNotification('success', 'Success', 'Password reset successful');
        history.push('/dashboard');
      }
      if (resetPasswordRes.error) {
        showNotification('error', 'Error', errorExtractor(resetPasswordRes.error));
      }
    }
  }, [resetPasswordRes]);

  const onSubmit = (data: any, e: any) => {
    resetPassword({
      memberId,
      password: data.password,
      recoveryCode: token,
    });
  };

  return (
    <Form className="mt-sixty" onSubmit={handleSubmit(onSubmit)}>
      <Form.Group className="forms--group">
        <Form.Control
          ref={register}
          type="password"
          placeholder="Password"
          name={ResetPasswordFormFields.PASSWORD}
          className={errors[ResetPasswordFormFields.PASSWORD] ? 'error' : ''}
        />
        {errors[ResetPasswordFormFields.PASSWORD] && (
          <span className="error__msg">{errors[ResetPasswordFormFields.PASSWORD]?.message}</span>
        )}
      </Form.Group>
      <Form.Group className="forms--group">
        <Form.Control
          ref={register}
          type="password"
          placeholder="Confirm Password"
          name={ResetPasswordFormFields.CONFIRM_PASSWORD}
          className={errors[ResetPasswordFormFields.CONFIRM_PASSWORD] ? 'error' : ''}
        />
        {errors[ResetPasswordFormFields.CONFIRM_PASSWORD] && (
          <span className="error__msg">
            {errors[ResetPasswordFormFields.CONFIRM_PASSWORD]?.message}
          </span>
        )}
      </Form.Group>
      <div className="text-sm-right forms--btn-canvas forgotPassword--btn-canvas">
        <Button
          type="submit"
          className="app__button underline__button"
          disabled={resetPasswordRes.loading}
        >
          {resetPasswordRes.loading ? (
            <React.Fragment>
              Resetting <SmallLoader />
            </React.Fragment>
          ) : (
            <React.Fragment>
              Reset <i className="icon-user-right ml-3"></i>
            </React.Fragment>
          )}
        </Button>
      </div>
    </Form>
  );
};

export default ResetPasswordForm;
