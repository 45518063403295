import { useSelector } from 'react-redux';
import { AppStateInterface } from '../index';
import { useEffect } from 'react';
import { getMe } from './me.action';
import { MeResponseInterface } from './me.types';
import { GetStateInterface } from '../../types/GetStateInterface';

export const useMe = (): GetStateInterface<MeResponseInterface> => {
  const { data, loading, error, hasData } = useSelector((state: AppStateInterface) => state.me.me);

  useEffect(() => {
    getMe();
  }, []);

  return { data, loading, error, hasData };
};
