import React, { useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { decodeJwt } from '../../utils/jwt.utils';
import Loader from '../../components/common/loader';
import { compileAvatarUrl } from '../../utils/avatar.utils';
import AdminInvitationForm from '../../components/invitation/admin.invitation';
import BrokerInvitationForm from '../../components/invitation/broker.invitation';
import { Link } from 'react-router-dom';

const InvitationPage: React.FC = () => {
  const history = useHistory();
  const { memberId, token } = useParams();
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    if (!token || !memberId) {
      history.push('/login');
    }
  }, []);

  useEffect(() => {
    try {
      setData(decodeJwt(token));
    } catch (e) {
      history.push('/login');
    }
  }, [token]);
  return (
    <div className="form--page adminSignup--page">
      {!data && <Loader />}
      {data && (
        <React.Fragment>
          <div className="form--banner">
            <div className="formBanner--canvas">
              <Navbar.Brand className="form__brand">
                <Link className="sidebar__logo" to={'/'}>
                  <img
                    src={
                      data?.companySettings?.logoUrl
                        ? compileAvatarUrl(data?.companySettings?.logoUrl)
                        : '/images/HomeTour_White_Logo.svg'
                    }
                    alt="Logo"
                  />
                </Link>
              </Navbar.Brand>
              <h1 className="form__heading form__heading-blue">
                A little more
                <br /> information to get
                <br /> you on the
                <span className="text-white">
                  {' '}
                  right
                  <br /> path
                </span>
              </h1>
              <span className="formBanner--img">
                <img src="/images/signup-img-admin.svg" alt="Img" />
              </span>
            </div>
          </div>
          {data.role === 'admin' && (
            <AdminInvitationForm
              email={data.email}
              firstName={data.firstName}
              lastName={data.lastName}
              memberId={memberId}
              role={data.role}
              verificationCode={token}
            />
          )}
          {data.role === 'broker' && (
            <BrokerInvitationForm
              email={data.email}
              firstName={data.firstName}
              lastName={data.lastName}
              verificationCode={token}
              role={data.role}
              memberId={memberId}
              invitingUserName={data.invitingUserName}
            />
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default InvitationPage;
