import React from 'react';
import {
  SearchBuildingInterface,
  SearchBuildingListingInterface,
} from '../../state/search/search.types';
import { compileAvatarUrl } from '../../utils/avatar.utils';
import { SEPARATORS } from '../../common/constants';
import { useHistory } from 'react-router';
import { startCase } from 'lodash';

interface Props {
  buildings: SearchBuildingInterface[];
}

const BuildingSearchResults: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { buildings } = props;

  const goTo = (to: string) => {
    history.push(to);
  };

  return (
    <div className="searchResults--block">
      <h5 className="heading__sm heading__sm-black font-semibold">Buildings & Listings</h5>
      <div className="search--listing mt-4">
        {buildings?.map((building: SearchBuildingInterface) => (
          <React.Fragment key={building._id}>
            <div className="search--bs" onClick={() => goTo(`/building/${building._id}/listings`)}>
              <div className="search--bs-img">
                <img src={compileAvatarUrl(building.imageUrl)} alt="Logo" className="img--cover" />
              </div>
              <div className="search--bs-detail">
                <div className="search--bs-inner">
                  <h4 className="bs__heading bs__heading-dark font-weight-normal">
                    {building?.location?.split(SEPARATORS.COMMA)[0]}
                  </h4>
                  <div className="al--listing mb-0">
                    <span className="al__icon">
                      <i className="icon-map-marker-light"></i>
                    </span>
                    <span className="al__text">
                      {building?.subMarket?.name}, {building?.subMarket?.market}
                    </span>
                  </div>
                </div>
                <span className="bs__next">
                  <i className="icon-arrow-right"></i>
                </span>
              </div>
            </div>
            {building?.listings?.map((listing: SearchBuildingListingInterface) => (
              <div
                className="search--bs search--bs-alt"
                key={listing._id}
                onClick={() => goTo(`/listing/${listing._id}`)}
              >
                <div className="search--bs-detail">
                  <div className="search--bs-inner">
                    <h4 className="bs__heading bs__heading-dark font-weight-normal">
                      {building?.location?.split(SEPARATORS.COMMA)[0]} -{' '}
                      {isNaN(parseInt(listing?.suiteNo || ''))
                        ? listing?.suiteNo
                        : `Suite ${listing?.suiteNo}`}
                    </h4>
                    <span className={`listing__badge listing__badge-${listing?.status}`}>
                      {startCase(listing?.status)}
                    </span>
                  </div>
                  <span className="bs__next">
                    <i className="icon-arrow-right"></i>
                  </span>
                </div>
              </div>
            ))}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default BuildingSearchResults;
