import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { TourListingsSelected } from '../../../../state/subdomain/tour/tour.types';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import { UsersInterface } from '../../../../state/users/users.types';
import { includes } from 'lodash';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import ExistingTourAddBrokerBlockShimmer from './loading';
import { useChangeShadowEffect } from '../../../../state/tour/tour.hook';

interface Props {
  selected: TourListingsSelected[];
  data: UsersInterface[];
  search: string;
  onSelect: (e: UsersInterface) => void;
}
const BrokerBox: React.FC<Props> = props => {
  const { selected, onSelect, data, search } = props;
  const [list, setList] = useState([] as UsersInterface[]);
  const { containerRef, showShadowEffect } = useChangeShadowEffect('Y');
  const brokersLoading = useSelector((state: AppStateInterface) => state.users.brokers.loading);

  useEffect(() => {
    if (data.length > 0) {
      let users = data;
      if (search.length)
        users = users.filter(e =>
          includes(`${e.firstName} ${e.lastName} ${e.email}`.toLowerCase(), search.toLowerCase()),
        );
      if (!selected.length) setList(users);
      else {
        const removableIds = selected.map(e => e.id);
        users = users.filter(listing => !removableIds.includes(listing._id));
        setList(users);
      }
    } else setList([]);
  }, [data, selected, search]);

  return (
    <div
      className={`tourListing--canvas tourListing--canvas-alt ${
        showShadowEffect ? 'tourListing--canvas-shadow-effect' : ''
      }`}
    >
      <div className="tourListing--scroll" id="tourListingPanel" ref={containerRef}>
        {brokersLoading && (
          <React.Fragment>
            <ExistingTourAddBrokerBlockShimmer />
            <ExistingTourAddBrokerBlockShimmer />
            <ExistingTourAddBrokerBlockShimmer />
            <ExistingTourAddBrokerBlockShimmer />
            <ExistingTourAddBrokerBlockShimmer />
            <ExistingTourAddBrokerBlockShimmer />
          </React.Fragment>
        )}
        {!brokersLoading &&
          list?.map((user, index) => {
            return (
              <div
                className="tourListing--block tourListing--block-alt tourListing--block-plus"
                key={index}
              >
                <span className="tourListing__img">
                  <img
                    src={compileAvatarUrl(user.avatar)}
                    alt={user.firstName}
                    className="img--cover"
                  />
                </span>
                <div className="tourListing--row">
                  <p className="para__md-dark">{`${user.firstName} ${user.lastName}`}</p>
                  <Button className="plus__btn ml-sm-2" onClick={() => onSelect(user)}>
                    <i className="icon-plus"></i>
                  </Button>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default BrokerBox;
