import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import {
  VIDEO_TIMER,
  REVIEW_STATE as rev,
  RECORDING_STATE as rec,
  VIDEO_TIME_INTERVAL,
  REVIEW_STATE,
} from '../../../common/constants';
import ReactPlayer from 'react-player';
import { VideoProgress } from '../../../state/review/review.types';
import { numToString, wistiaVideoConfigs } from '../listing.component';
import { changeVideoStatus, changeVideoTimeStamp } from '../../../state/review/review.action';

interface Props {
  setDurations: (e: number) => void;
  setCurrentTime: (e: number) => void;
}
const ReviewVideo: React.FC<Props> = props => {
  const { setCurrentTime, setDurations } = props;

  const listingData = useSelector((state: AppStateInterface) => state.listing.singleListing);
  const { reviewState, isRecording, recordingState: recordState } = useSelector(
    (state: AppStateInterface) => state.review,
  );

  const [timer, setTimer] = useState(0);
  const [videoTime, setVideoTime] = useState(0);
  const [viewTimer, timerHandler] = useState(false);
  const [videoPlay, setVideoPlay] = useState(false);
  const [audioRecording, handleRecording] = useState(false);
  const [listing, setListing] = useState(listingData.data);

  useEffect(() => {
    if (viewTimer) {
      const timerInterval = setTimeout(() => {
        if (timer > 0) setTimer(timer - 1);
        else {
          clearTimeout(timerInterval);
          timerHandler(false);
        }
      }, 1000);
      if (timer === 0) {
        changeVideoStatus(REVIEW_STATE.START);
        changeVideoTimeStamp(videoTime);
      }
    }
  }, [timer]);
  useEffect(() => {
    if (listingData.data) setListing(listingData.data);
  }, [listingData]);
  useEffect(() => {
    if (reviewState === REVIEW_STATE.READY) {
      setTimer(VIDEO_TIMER);
      timerHandler(true);
      handleRecording(true);
    }
    if (reviewState === REVIEW_STATE.STOP) handleRecording(false);
  }, [reviewState]);
  useEffect(() => {
    if (isRecording && recordState === rec.RECORD) {
      if (!videoPlay) setVideoPlay(true);
    } else if (videoPlay) setVideoPlay(false);
  }, [isRecording, recordState]);

  const onProgress = (state: VideoProgress) => {
    const time = state.playedSeconds;
    setCurrentTime(time);
    setVideoTime(time);
    if (reviewState === rev.IDLE) changeVideoStatus(rev.INITIALIZE);
  };
  const onPause = () => {
    if (recordState === rec.RECORD || isRecording) {
      if (isRecording) changeVideoStatus(rev.PAUSE);
    } else if (reviewState === rev.DISABLE) changeVideoStatus(rev.INITIALIZE);
  };
  const onPlay = () => {
    if (recordState === rec.RECORD || isRecording) {
      if (reviewState === rev.PAUSE) changeVideoStatus(rev.RESUME);
    } else changeVideoStatus(rev.DISABLE);
  };

  return (
    <div className="col-12 col-lg-6">
      <div className="video--block listing--video mb-4">
        {listing?.videoUrl && (
          <ReactPlayer
            url={listing.videoUrl}
            width="100%"
            height="100%"
            progressInterval={VIDEO_TIME_INTERVAL}
            onDuration={setDurations}
            onProgress={onProgress}
            onPause={onPause}
            onPlay={onPlay}
            onEnded={() => (recordState === rec.RECORD ? changeVideoStatus(rev.STOP) : null)}
            onSeek={(e: number) => setVideoTime(e)}
            muted
            playing={reviewState === rev.START || reviewState === rev.RESUME}
            config={{ wistia: wistiaVideoConfigs }}
          />
        )}
        {viewTimer && (
          <div className="recording--overlay">
            <div className="recording--overlay-inner">
              <h2 className="recording__heading">Begin Recording in...</h2>
              <div className="recording__timer">{numToString(timer)}</div>
            </div>
          </div>
        )}
        {!viewTimer && audioRecording && <div className={`recording--overlay hidden--overlay`} />}
      </div>
    </div>
  );
};

export default ReviewVideo;
