import React, { useState } from 'react';
import { UploadedMediaInterface } from '../../../../state/media/media.types';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import { DOWNLOAD_GAP } from '../../../../common/constants';
import SmallLoader from '../../../common/small.loader';

interface Props {
  info: UploadedMediaInterface[];
}
const ListingDocs: React.FC<Props> = props => {
  const { info: pdfs } = props;
  const [loader, setLoader] = useState(false);

  const downloadPdfs = () => {
    let i = 0;
    setLoader(true);
    const interval = setInterval(() => {
      document.getElementById(pdfs[i].name)?.click();
      if (++i === pdfs.length) {
        clearInterval(interval);
        setLoader(false);
      }
    }, DOWNLOAD_GAP * 1000);
  };

  return (
    <React.Fragment>
      <button
        disabled={loader}
        className="outline__button pdf__button w-100"
        onClick={downloadPdfs}
      >
        {!loader ? (
          <React.Fragment>
            Download PDF Attachments
            <span className="ml-2">
              <i className="icon-paperclip"></i>
            </span>
          </React.Fragment>
        ) : (
          <React.Fragment>
            Downloading
            <SmallLoader />
          </React.Fragment>
        )}
      </button>
      {pdfs.map(file => (
        <a id={file.name} href={compileAvatarUrl(file.url)} key={file.name} download />
      ))}
    </React.Fragment>
  );
};

export default ListingDocs;
