import { TourActions as actionName, TourStateInterface } from './tour.types';
import { GetState } from '../../types/GetStateInterface';

const defaultState: TourStateInterface = {
  tourInfo: GetState,
  tourListing: null,
  changeTour: true,
};

export default function TourReducer(state = defaultState, action: any) {
  switch (action.type) {
    case actionName.GET_SINGLE_TOUR_INIT:
      return {
        ...state,
        tourInfo: {
          ...state.tourInfo,
          loading: true,
          error: null,
          data: null,
          hasData: false,
        },
      };

    case actionName.GET_SINGLE_TOUR_FAILURE:
      return {
        ...state,
        tourInfo: {
          ...state.tourInfo,
          data: null,
          loading: false,
          error: action.payload,
          hasData: true,
        },
      };

    case actionName.GET_SINGLE_TOUR_SUCCESSFUL:
      return {
        ...state,
        tourInfo: {
          ...state.tourInfo,
          loading: false,
          error: null,
          data: action.payload,
          hasData: true,
        },
        changeTour: true,
      };

    case actionName.CHANGE_TOUR_LISTING:
      return {
        ...state,
        tourListing: action.payload,
      };

    case actionName.DELETE_SINGLE_TOUR:
      return {
        ...state,
        tourListing: null,
        tourInfo: {
          ...state.tourInfo,
          data: null,
        },
      };

    case actionName.CHANGE_TOUR_USERS:
      return {
        ...state,
        tourInfo: {
          ...state.tourInfo,
          data: state.tourInfo.data
            ? {
                ...state.tourInfo.data,
                additionalUsers: action.payload,
              }
            : null,
        },
        changeTour: false,
      };

    case actionName.CHANGE_TOUR_LISTINGS:
      return {
        ...state,
        tourInfo: {
          ...state.tourInfo,
          data: action.payload,
        },
        changeTour: true,
      };

    case actionName.CHANGE_TOUR_TYPE:
      return {
        ...state,
        tourInfo: {
          ...state.tourInfo,
          data: state.tourInfo.data
            ? {
                ...state.tourInfo.data,
                tourType: action.payload,
              }
            : null,
        },
        changeTour: false,
      };

    case actionName.CHANGE_LISTING_IMAGES:
      return {
        ...state,
        tourListing: state.tourListing
          ? {
              ...state.tourListing,
              additionalMedia: action.payload,
            }
          : null,
        tourInfo: {
          ...state.tourInfo,
          data: state.tourInfo.data
            ? {
                ...state.tourInfo.data,
                listings: action.listings,
              }
            : null,
        },
        changeTour: false,
      };

    case actionName.CHANGE_LISTING_PLANS:
      return {
        ...state,
        tourListing: state.tourListing
          ? {
              ...state.tourListing,
              additionalFloorPlans: action.payload,
            }
          : null,
        tourInfo: {
          ...state.tourInfo,
          data: state.tourInfo.data
            ? {
                ...state.tourInfo.data,
                listings: action.listings,
              }
            : null,
        },
        changeTour: false,
      };

    default:
      return state;
  }
}
