import React, { useState, useEffect } from 'react';
import { GetAllListings, Building } from '../../../../state/listing/listing.types';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import { useHistory } from 'react-router';
import { startCase } from 'lodash';
import { DEFAULT_AVATAR } from '../../../../common/constants';
import { UploadedMediaInterface } from '../../../../state/media/media.types';

interface Props {
  listing: GetAllListings;
}
const SingleListing: React.FC<Props> = props => {
  const history = useHistory();
  const { listing: list } = props;
  const [mainImage, setImage] = useState({} as UploadedMediaInterface);
  const [building, setBuilding] = useState({} as Building);
  useEffect(() => {
    setBuilding(list.building);
    if (list.media) {
      let image = list.media.find(image => image.isPrimary === true);
      if (!image) image = list.media[0];
      setImage(image);
    }
  }, [list]);

  return (
    <div className="al--block al--block-sm" onClick={() => history.push(`/listing/${list._id}`)}>
      {list && (
        <div className="al--block-img">
          <img src={compileAvatarUrl(list.media ? mainImage.url : '')} alt={mainImage.name} />
        </div>
      )}
      {list && (
        <div className="al--block-detail">
          <div className="ald--inner al--adress-component">
            <label className="al__address">{list.suiteNo}</label>
            <div className="al--listing-box">
              <div className="al--listing">
                <span className="al__icon">
                  <i className="icon-map-marker-light"></i>
                </span>
                <span className="al__text">{building?.subMarket?.market}</span>
              </div>
            </div>
            <div className="customer--block">
              {list.users &&
                list.users?.length > 0 &&
                list.users?.map((user, index) => {
                  return (
                    <React.Fragment key={user._id}>
                      {index < 4 && (
                        <div className="customer--img">
                          <img
                            src={user?.avatar ? compileAvatarUrl(user.avatar) : DEFAULT_AVATAR}
                            alt="Img"
                          />
                        </div>
                      )}
                    </React.Fragment>
                  );
                })}
              {list.users?.length > 4 && (
                <a className="customer--add">+ {list.users?.length - 4}</a>
              )}
            </div>
          </div>
          <div className="ald--inner al--listings-component">
            <div className="al--listing-box">
              <div className="al--listing">
                <span className="al__icon">
                  <i className="icon-size"></i>
                </span>
                <span className="al__text">Size</span>
                <span className="al__text alText__detail">
                  {list.size ? `${list.size.toLocaleString()} SF` : 'N/A'}
                </span>
              </div>
              <div className="al--listing">
                <span className="al__icon">
                  <i className="icon-month"></i>
                </span>
                <span className="al__text">Rent</span>
                <span className="al__text alText__detail">
                  {list.monthlyRent ? `$${list.monthlyRent.toLocaleString()}/SF/YR` : 'N/A'}
                </span>
              </div>
              <div className="al--listing">
                <span className="al__icon">
                  <i className="icon-users"></i>
                </span>
                <span className="al__text">Seats</span>
                <span className="al__text alText__detail">
                  {list.capacity ? `${list.capacity} Employees` : 'N/A'}
                </span>
              </div>
            </div>
          </div>
          <div className="ald--inner al--btn-component">
            <a className={`list__button list__button-${list.status}`}>{startCase(list.status)}</a>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleListing;
