import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import Quill, { Delta as DeltaInterface, Sources, StringMap } from 'quill';
import 'react-quill/dist/quill.snow.css'; // ES6
/* eslint-disable */
// @ts-ignore
import MarkdownShortcuts from 'quill-markdown-shortcuts';
// @ts-ignore
import MagicUrl from 'quill-magic-url';
// @ts-ignore
export type UnprivilegedEditor = ReactQuill.UnprivilegedEditor;
/* eslint-enable */
import { QuillEnhancers, IndentStyle, Block } from './quillEnhancers';

interface Props {
  defaultValue?: string;
  value?: string;
  onChange: (
    text: string,
    delta: DeltaInterface,
    source: Sources,
    editor: UnprivilegedEditor,
  ) => void;
  placeholder?: string;
  style?: React.CSSProperties;
  className?: string;
  formats?: string[];
  toolbar?: any[][];
  enableMarkdownShortcuts?: boolean;
  enableMagicUrl?: boolean;
}

Block.tagName = 'DIV';
Quill.register(Block, true);

Quill.register(IndentStyle, true);

Quill.register('modules/clipboard', QuillEnhancers, true);

Quill.register('modules/markdownShortcuts', MarkdownShortcuts, true);

Quill.register('modules/magicUrl', MagicUrl, true);

const defaultToolbar = [
  ['bold', 'italic', 'underline', 'strike'], // toggled buttons
  ['blockquote', 'code-block'],

  [{ header: 1 }, { header: 2 }], // custom button values
  [{ list: 'ordered' }, { list: 'bullet' }],
  [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
  [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
  [{ direction: 'rtl' }], // text direction

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
  [{ header: [1, 2, 3, 4, 5, 6, false] }],

  [{ color: [] }, { background: [] }], // dropdown with defaults from theme
  [{ font: [] }],
  [{ align: [] }],

  ['link', 'image', 'video'],

  ['clean'], // remove formatting button
];

const QuillEditor: React.FC<Props> = (props: Props) => {
  const {
    defaultValue,
    toolbar: customToolbar,
    placeholder,
    style,
    className,
    formats,
    value,
  } = props;
  const onChange = (
    content: string,
    delta: DeltaInterface,
    source: Sources,
    editor: UnprivilegedEditor,
  ) => {
    props.onChange(editor.getLength() > 1 ? content : '', delta, source, editor);
  };

  return (
    <ReactQuill
      style={style}
      value={value}
      className={className}
      defaultValue={defaultValue}
      onChange={onChange}
      theme="snow"
      formats={formats}
      modules={{
        toolbar: customToolbar || defaultToolbar,
        markdownShortcuts: props.enableMarkdownShortcuts ?? true,
        magicUrl: props.enableMagicUrl ?? true,
        clipboard: {
          allowed: {
            tags: ['a', 'b', 'strong', 'u', 's', 'i', 'p', 'br', 'ul', 'ol', 'li', 'span'],
            attributes: ['href', 'rel', 'target'],
          },
        },
      }}
      placeholder={placeholder}
    />
  );
};

export default QuillEditor;
