import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  defaultSearch: string;
  onSearchChange: (search: string) => void;
}

const SearchBox: React.FC<Props> = (props: Props) => {
  const { defaultSearch, onSearchChange } = props;
  return (
    <Form.Group className="form--group-alt search--group-alt mb-3 mb-lg-0">
      <Form.Control
        type="search"
        placeholder="Search"
        onChange={(e: any) => onSearchChange(e.target.value)}
        defaultValue={defaultSearch}
      />
      <span className="search__icon">
        <i className="icon-search"></i>
      </span>
    </Form.Group>
  );
};

export default SearchBox;
