import React from 'react';
import LeftSidebar from '../../../components/leftSidebar';
import HamBurger from '../../../components/leftSidebar/hamburger';
import ViewList from '../../../components/listing/view/allListings';
import { useHistory } from 'react-router';

const ListingViewPage: React.FC = () => {
  const history = useHistory();

  const goBack = () => {
    if (history.action === 'PUSH') {
      history.goBack();
    } else {
      history.push('/buildings');
    }
  };

  return (
    <div className="dashboard--page building--listing">
      <LeftSidebar active="buildings" />
      <div className="dashboard--content" id="ListingsParentScrollContainer">
        <div className="dashboard--container">
          <div className="dashboard--canvas">
            <div className="step--content step--content-1">
              <div className="dashboard--opt">
                <a className="back__btn" onClick={goBack}>
                  <span className="back__btn-icon">
                    <i className="icon-chevron-left"></i>
                  </span>
                  Back
                </a>
                <HamBurger className="menu__toggle" />
              </div>
              <ViewList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingViewPage;
