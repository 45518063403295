import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { UploadedMediaInterface } from '../../../../../state/media/media.types';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import ListingImagePanel from './imagePanel';

const arrowIgnore = 10;
const oneImage = 220;
const ListingGallery: React.FC = () => {
  const info = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing.data,
  );
  const imagePanel = useRef<HTMLDivElement>(null);

  const [images, updateImages] = useState([] as UploadedMediaInterface[]);
  const [showLeftIcon, handleLeftIcon] = useState(false);
  const [showRightIcon, handleRightIcon] = useState(false);

  const onScroll = () => {
    if (imagePanel.current) {
      const left = imagePanel.current.scrollLeft;
      const width = imagePanel.current.scrollWidth;
      const cWidth = imagePanel.current.clientWidth;
      if (Math.trunc(left) > arrowIgnore) handleLeftIcon(true);
      else if (Math.trunc(left) <= arrowIgnore) handleLeftIcon(false);
      if (Math.abs(Math.trunc(left + cWidth) - width) > arrowIgnore) handleRightIcon(true);
      else if (Math.abs(Math.trunc(left + cWidth) - width) <= arrowIgnore) handleRightIcon(false);
    }
  };
  const movePanel = (dir: number) => {
    if (imagePanel.current) {
      const left = imagePanel.current.scrollLeft;
      const images = document.getElementsByClassName('block--search-col');
      const imageWidth = images && images?.length ? images[0].scrollWidth : oneImage;
      let moveSpan = imageWidth;
      if (dir === 1 && left % imageWidth !== 0) moveSpan = imageWidth - (left % imageWidth);
      if (dir === -1 && left % imageWidth !== 0) moveSpan = left % imageWidth;
      if (imagePanel.current.scrollTo) {
        imagePanel.current.scrollTo(left + dir * moveSpan, 0);
      } else {
        imagePanel.current.scrollLeft = left + dir * moveSpan;
      }
    }
  };

  useEffect(() => {
    if (info?.media && Array.isArray(info.media)) {
      const files = info.media;
      files.sort((x, y) => (x.isPrimary === y.isPrimary ? 0 : x.isPrimary ? -1 : 1));
      updateImages(files);
    }
  }, [info]);
  useLayoutEffect(() => {
    imagePanel?.current?.addEventListener('scroll', onScroll, false);
    return () => imagePanel?.current?.removeEventListener('scroll', onScroll, false);
  }, []);
  useLayoutEffect(onScroll, [images]);

  return (
    <div className="photos--off">
      {showLeftIcon && (
        <span className="photos__control photos__control-prev" onClick={() => movePanel(-1)}>
          <i className="icon-chevron-left"></i>
        </span>
      )}
      <div className="photos--gallery block-flex">
        <ListingImagePanel images={images} imagePanelRef={imagePanel} />
      </div>
      {showRightIcon && (
        <span className="photos__control photos__control-next" onClick={() => movePanel(1)}>
          <i className="icon-chevron-right"></i>
        </span>
      )}
    </div>
  );
};

export default ListingGallery;
