import React from 'react';
import { Form } from 'react-bootstrap';

interface Props {
  setSearch: (value: string) => void;
  defaultSearch: string;
}

const TourSearch: React.FC<Props> = (props: Props) => {
  const { setSearch, defaultSearch } = props;
  return (
    <Form.Group className="form--group-alt search--group-alt mb-0 w-100">
      <Form.Control
        type="search"
        placeholder="Search Tours"
        onChange={(e: any) => setSearch(e.target.value)}
        defaultValue={defaultSearch}
      />
      <span className="search__icon">
        <i className="icon-search"></i>
      </span>
    </Form.Group>
  );
};

export default TourSearch;
