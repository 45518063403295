import { TOUR_TYPES } from '../../common/constants';
import { UploadedMediaInterface } from '../media/media.types';
import { GetStateInterface } from '../../types/GetStateInterface';

export enum TourActions {
  GET_ALL_TOURS = 'GET_ALL_TOURS',
  GET_SINGLE_TOUR = 'GET_SINGLE_TOUR',
  GET_SINGLE_TOUR_INIT = 'GET_SINGLE_TOUR_INIT',
  GET_SINGLE_TOUR_SUCCESSFUL = 'GET_SINGLE_TOUR_SUCCESSFUL',
  GET_SINGLE_TOUR_FAILURE = 'GET_SINGLE_TOUR_FAILURE',
  CHANGE_TOUR_LISTING = 'CHANGE_TOUR_LISTING',
  DELETE_SINGLE_TOUR = 'DELETE_SINGLE_TOUR',
  CHANGE_TOUR_TYPE = 'CHANGE_TOUR_TYPE',
  CHANGE_LISTING_IMAGES = 'CHANGE_LISTING_IMAGES',
  CHANGE_LISTING_PLANS = 'CHANGE_LISTING_PLANS',
  CHANGE_TOUR_LISTINGS = 'CHANGE_TOUR_LISTINGS',
  CHANGE_TOUR_USERS = 'CHANGE_TOUR_USERS',
}

export type TourSortBy = 'updatedAt' | 'createdAt' | 'tourType';

export type TourOrderBy = 'ascending' | 'descending';

export interface TourSortAndOrder {
  sortBy?: TourSortBy;
  orderBy?: TourOrderBy;
}

export interface GetAllToursQueryInterface extends TourSortAndOrder {
  subMarket?: string;
  search?: string;
  active?: 0 | 1;
  limit?: number;
  skip?: number;
}

export interface SubClientInterface {
  firstName?: string;
  lastName?: string;
  email?: string;
  company?: string;
  brokerCompany?: string;
  phone?: string;
  time?: string;
  questions?: string;
}

export interface RoomBuildingInterface {
  location: string;
}

export interface RoomListingsInterface {
  suiteNo: string;
  building: RoomBuildingInterface;
}

export interface GetAllToursDataInterface {
  _id: string;
  tourType: TOUR_TYPES.LIVE | TOUR_TYPES.PRESENT;
  listings: RoomListingsInterface[];
  clientInfo?: SubClientInterface;
  tourUrl?: string;
  expiredAt: number;
}

export interface GetAllToursResponseInterface {
  hasMore: boolean;
  skip: number;
  data: GetAllToursDataInterface[];
}

export enum TOUR_SWITCHES {
  HOME = 'HOME',
  DETAILS = 'DETAILS',
  LOCATION = 'LOCATION',
  GALLERY = 'GALLERY',
  FLOOR_PLANS = 'FLOOR_PLANS',
  TOUR = 'TOUR',
  SPECS = 'SPECS',
}

export interface TourStateInterface {
  tourInfo: GetStateInterface<GetTourInfo>;
  tourListing: ListingInfo | null;
  changeTour: boolean;
}

export interface GetTourInfo {
  _id: string;
  additionalUsers: AdditionalUser[];
  isActive: boolean;
  dailyCoRoom: any;
  listings: ListingInfo[];
  clientInfo?: ClientInfo;
  tourType: TOUR_TYPES;
  expiredAt: number;
  company: string;
  createdBy: string;
  createdAt: string;
  updatedAt: string;
  tourUrl?: string;
  __v: number;
}

interface ClientInfo {
  _id?: string;
  company?: string;
}

export interface ListingInfo {
  _id?: string;
  listing: TourListingInterface;
  additionalMedia: UploadedMediaInterface[];
  additionalFloorPlans: UploadedMediaInterface[];
  createdAt?: string;
  updatedAt?: string;
}

export interface TourListingInterface {
  _id: string;
  videoUrl?: string;
  videoStatus: string;
  isActive: boolean;
  status: string;
  createdBy: string;
  available: string;
  media: Media[];
  pdfs: UploadedMediaInterface[];
  videoDescription: string;
  matterPortLink: string;
  description: string;
  metroProximity: string;
  condition: string;
  capacity: string;
  monthlyRent?: number;
  size: number;
  suiteNo: string;
  spaceUse: string;
  building: TourBuildingInterface;
  createdAt: string;
  updatedAt: string;
  __v: number;
  floorPlans: UploadedMediaInterface[];
  renderings: any[];
}

export interface TourBuildingInterface {
  _id: string;
  buildingHours: string[];
  buildingAmenities: string[];
  isActive: boolean;
  location: string;
  longitude: string;
  latitude: string;
  fullLocation: any;
  subMarket: string;
  imageUrl: string;
  yearBuilt: number;
  yearRenovated: number;
  floorsCount: number;
  buildingSize: number;
  averageFloor: number;
  parkingRatio: string;
  columnSpacingHeight: number;
  columnSpacingWidth: number;
  passengerElevators: string;
  freightElevators: string;
  telecomProviders: string;
  leedCertification: string;
  energyStarCertification: string;
  company: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
  buildingAmenitiesMatterportLinks: UploadedMediaInterface[];
  other?: string;
  slabToSlabHeight: string;
  website?: string;
}

interface Media extends UploadedMediaInterface {
  isPrimary: boolean;
}

export interface AdditionalUser {
  _id: string;
  avatar: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface EditTourListingMedia {
  listingId: string;
  roomId: string;
  media: UploadedMediaInterface[];
  floorPlans: UploadedMediaInterface[];
}
