import { GetState, GetStateInterface } from '../../types/GetStateInterface';
import { useCallback, useEffect, useState } from 'react';
import {
  addBuildingAPI,
  getBuildingDetailsAPI,
  getBuildingsListAPI,
  updateBuildingAPI,
  deleteBuildingAPI,
} from './building.api';
import {
  BuildingDataPayloadInterface,
  GetBuildingsListResponseInterface,
  UpdateBuildingDataPayloadInterface,
} from './building.types';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../index';
import { clearAllBuildings, getAllBuildings } from './building.actions';
import { DEFAULT_BUILDING_LIMIT } from '../../common/constants';
import { BuildingActions as actionName } from './building.types';
import fetchActions from '../../utils/state.utils';

export const useGetBuildingDetails = () => {
  const [res, setRes] = useState(GetState);

  const getBuildingDetails = useCallback(async (buildingId: string) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    try {
      const res = await getBuildingDetailsAPI(buildingId);
      setRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);

  return { res, getBuildingDetails };
};

export const useGetBuildingsList = () => {
  const [res, setRes] = useState<GetStateInterface<Array<GetBuildingsListResponseInterface>>>(
    GetState,
  );

  const getBuildingsList = useCallback(async (companyId: string) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    try {
      const res = await getBuildingsListAPI(companyId);
      setRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);

  return { res, getBuildingsList };
};

export const useAddBuilding = () => {
  const [res, setRes] = useState<GetStateInterface>(GetState);

  const addBuilding = useCallback(async (data: BuildingDataPayloadInterface) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    try {
      const res = await addBuildingAPI(data);
      setRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);

  return { res, addBuilding };
};

export const useGetAllBuildings = (companyId: string) => {
  const { data, loading, hasData, error } = useSelector(
    (state: AppStateInterface) => state.building.allBuildings,
  );

  useEffect(() => {
    getAllBuildings(companyId, DEFAULT_BUILDING_LIMIT, 0);
  }, [companyId]);

  const fetchMore = () => {
    if (data?.hasMore && !loading) {
      getAllBuildings(companyId, DEFAULT_BUILDING_LIMIT, data?.skip || 0, data?.data || []);
    }
  };

  return { data, loading, hasData, error, fetchMore };
};

export const useUpdateBuilding = () => {
  const [res, setRes] = useState<GetStateInterface<Array<GetBuildingsListResponseInterface>>>(
    GetState,
  );

  const updateBuilding = useCallback(
    async (buildingId: string, data: UpdateBuildingDataPayloadInterface) => {
      setRes(prevState => ({ ...prevState, loading: true }));
      try {
        const res = await updateBuildingAPI(buildingId, data);
        setRes({ data: res.data, hasData: true, loading: false, error: null });
      } catch (err) {
        setRes({ data: null, error: err, hasData: true, loading: false });
      }
    },
    [],
  );
  return { res, updateBuilding };
};

export const useDeleteBuilding = () => {
  const [res, setRes] = useState(GetState);
  const deleteBuilding = useCallback(async (buildingId: string) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(actionName.DELETE_BUILDING);
    try {
      const res = await deleteBuildingAPI(
        buildingId,
        fetchActions.setCT(actionName.DELETE_BUILDING),
      );
      setRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);
  return { res, deleteBuilding };
};

export const useClearAllBuildings = () => {
  return { clearAllBuildings };
};
