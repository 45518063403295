import React, { useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import {
  AdminInvitationFormFields,
  AdminInvitationFormSchema,
} from '../../state/invitation/forms/admin.form';
import useForm from 'react-hook-form';
import { useAcceptInvite } from '../../state/invitation/invitation.hook';
import { showNotification } from '../../utils/misc.utils';
import { errorExtractor } from '../../utils/error.utils';
import { useHistory } from 'react-router';
import SmallLoader from '../common/small.loader';

interface Props {
  firstName: string;
  lastName: string;
  email: string;
  verificationCode: string;
  memberId: string;
  role: string;
}

const AdminInvitationForm: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { register, handleSubmit, errors, setValue } = useForm({
    validationSchema: AdminInvitationFormSchema,
  });
  const { res: acceptInviteResponse, acceptInvite } = useAcceptInvite();

  useEffect(() => {
    setValue(AdminInvitationFormFields.FIRST_NAME, props.firstName);
    setValue(AdminInvitationFormFields.LAST_NAME, props.lastName);
  }, [props]);

  useEffect(() => {
    if (!acceptInviteResponse.loading) {
      if (acceptInviteResponse.data) {
        history.push('/dashboard');
      }
      if (acceptInviteResponse.error) {
        showNotification('error', 'Error', errorExtractor(acceptInviteResponse.error));
      }
    }
  }, [acceptInviteResponse]);

  const onSubmit = (data: any, e: any) => {
    acceptInvite({
      email: props.email,
      memberId: props.memberId,
      verificationCode: props.verificationCode,
      firstName: data.firstName,
      lastName: data.lastName,
      role: props.role,
      password: data.password,
    });
  };

  return (
    <div className="form--content adminSignup--content">
      <div className="adminSignup--canvas container--xs">
        <h1 className="form__heading mb-3">Welcome {props.firstName}</h1>
        <p className="form__para">
          Please go ahead and create a password to finish creating your account
        </p>
        <Form className="mt-sixty" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12">
              <Form.Group className="forms--group">
                <Form.Control
                  name={AdminInvitationFormFields.FIRST_NAME}
                  type="text"
                  placeholder="First name"
                  ref={register}
                  className={errors[AdminInvitationFormFields.FIRST_NAME] ? 'error' : ''}
                />
                {errors[AdminInvitationFormFields.FIRST_NAME] && (
                  <span className="error__msg">
                    {errors[AdminInvitationFormFields.FIRST_NAME]?.message}
                  </span>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group className="forms--group">
                <Form.Control
                  name={AdminInvitationFormFields.LAST_NAME}
                  ref={register}
                  type="text"
                  placeholder="Last name"
                  className={errors[AdminInvitationFormFields.LAST_NAME] ? 'error' : ''}
                />
                {errors[AdminInvitationFormFields.LAST_NAME] && (
                  <span className="error__msg">
                    {errors[AdminInvitationFormFields.LAST_NAME]?.message}
                  </span>
                )}
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group className="forms--group">
                <Form.Control
                  name="email"
                  type="email"
                  placeholder="Email"
                  disabled
                  value={props.email}
                />
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group className="forms--group">
                <Form.Control
                  name={AdminInvitationFormFields.PASSWORD}
                  ref={register}
                  type="password"
                  placeholder="Password"
                  className={errors[AdminInvitationFormFields.PASSWORD] ? 'error' : ''}
                />
                {errors[AdminInvitationFormFields.PASSWORD] && (
                  <span className="error__msg">
                    {errors[AdminInvitationFormFields.PASSWORD]?.message}
                  </span>
                )}
              </Form.Group>
            </div>
          </div>
          <div className="form--option formOpt--terms mb-0 pt-3">
            <label className="checkbox--set">
              I agree to{' '}
              <a className="checkbox__link" href="/terms" target="_blank" rel="noopener noreferrer">
                HomeTour terms.
              </a>
              <input type="checkbox" name={AdminInvitationFormFields.TERMS} ref={register} />
              <span className="checkmark"></span>
              {errors[AdminInvitationFormFields.TERMS] && (
                <span className="error__msg">
                  {errors[AdminInvitationFormFields.TERMS]?.message}
                </span>
              )}
            </label>
            <div className="text-sm-right forms--btn-canvas adminSignup--btn-canvas btn-top">
              <Button
                type="submit"
                className="admin__button underline__button"
                disabled={acceptInviteResponse.loading}
              >
                {acceptInviteResponse.loading ? 'Creating' : 'Create Account'}
                {acceptInviteResponse.loading && <SmallLoader />}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default AdminInvitationForm;
