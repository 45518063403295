import React, { useState, useEffect } from 'react';
import SelectTour from './steps/selectTour';
import SelectListings from './steps/selectListing';
import { TourOption, CreateTourInterface } from '../../../state/subdomain/tour/tour.types';
import { useCreateTour } from '../../../state/subdomain/tour/tour.hook';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { showNotification } from '../../../utils/misc.utils';
import { errorExtractor } from '../../../utils/error.utils';
import { useHistory } from 'react-router';

const CreateTour: React.FC = () => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [tourData, setTourData] = useState({} as TourOption);
  const { res: getTourRes, createTour } = useCreateTour();
  const me = useSelector((state: AppStateInterface) => state.me.me.data);

  const nextStep = (e: TourOption) => {
    setTourData(e);
    setStep(2);
  };
  const submitData = (e: string[]) => {
    if (me?.company?._id) {
      const payload = {
        companyId: me.company._id,
        tourType: tourData.tourType,
        clientCompany: tourData.clientInfo,
        listings: e,
      } as CreateTourInterface;
      createTour(payload);
    }
  };

  useEffect(() => {
    if (!getTourRes.loading && getTourRes.hasData) {
      if (getTourRes.error)
        showNotification('error', 'Tour Creation Error', errorExtractor(getTourRes.error));
      else if (getTourRes.data) {
        showNotification('success', 'Success', `Tour Created Successfully`);
        history.push(`/tour/${getTourRes.data._id}/edit`);
      }
    }
  });

  return (
    <React.Fragment>
      {step === 1 && <SelectTour setData={nextStep} />}
      {step === 2 && <SelectListings setData={submitData} loading={getTourRes.loading} />}
    </React.Fragment>
  );
};

export default CreateTour;
