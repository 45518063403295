import { useState } from 'react';
import { GetState } from '../../types/GetStateInterface';
import { LandingActions, ScheduleDemoInterface } from './landing.types';
import { getPublicListingsAPI, scheduleDemoAPI } from './landing.api';
import fetchActions from '../../utils/state.utils';
import axios from 'axios';
import { DEFAULT_LISTING_LIMIT } from '../../common/constants';

export const useScheduleDemo = () => {
  const [res, setRes] = useState(GetState);

  const scheduleDemo = async (data: ScheduleDemoInterface) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    try {
      const res = await scheduleDemoAPI(data);
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      setRes({ data: null, loading: false, error: error, hasData: true });
    }
  };
  return { scheduleDemo, res };
};

export const useGetPublicListings = () => {
  const [res, setRes] = useState(GetState);

  const getPublicListings = async () => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(LandingActions.GET_PUBLIC_LISTINGS);
    try {
      const res = await getPublicListingsAPI(
        DEFAULT_LISTING_LIMIT,
        0,
        fetchActions.setCT(LandingActions.GET_PUBLIC_LISTINGS),
      );
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  };

  const fetchMore = async () => {
    if (res.data.hasMore) {
      setRes(prevState => ({ ...prevState, loading: true }));
      fetchActions.callCT(LandingActions.GET_PUBLIC_LISTINGS);
      try {
        const response = await getPublicListingsAPI(
          DEFAULT_LISTING_LIMIT,
          res.data.skip,
          fetchActions.setCT(LandingActions.GET_PUBLIC_LISTINGS),
        );
        if (res.data && res.data.data && res.data.data.length > 0) {
          setRes({
            data: {
              ...response.data,
              data: [...res.data.data, ...response.data.data],
            },
            loading: false,
            error: null,
            hasData: true,
          });
        } else {
          setRes({ data: response.data, loading: false, error: null, hasData: true });
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          setRes({ data: null, loading: false, error: error, hasData: true });
        }
      }
    }
  };

  return { res, getPublicListings, fetchMore };
};
