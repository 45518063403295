import React, { useRef, useState, useCallback, CSSProperties, useEffect } from 'react';

interface Props {
  iconClass: string;
  heading: string;
  details: string[];
}

// TODO react to resizing?

const ListingTourSpecCard: React.FC<Props> = (props: Props) => {
  const { iconClass, heading, details } = props;
  const [isExpandable, setIsExpandable] = useState(details.length > 1);
  const [isExpanded, setIsExpanded] = useState(details.length > 1);

  const elContainer = useCallback((el: HTMLDivElement | null) => {
    if (!el) return;
    if (isExpandable) return;

    // for of was blocked by ts?
    for (let i = 0; i < el.children.length; i++) {
      const child = el.children[i];
      if (
        child.clientWidth < child.scrollWidth ||
        (child.clientHeight < child.scrollHeight && i > 0)
      ) {
        setIsExpandable(true);
        setIsExpanded(true);
        return;
      }
    }
  }, []);

  const extraHeadingStyle: CSSProperties = isExpanded
    ? {
        whiteSpace: 'normal',
      }
    : {};

  const extraDetailStyle: CSSProperties = isExpanded
    ? {
        whiteSpace: 'normal',
        // paddingLeft: '5px',
        // textIndent: '-5px',
      }
    : {
        WebkitLineClamp: 2,
      };

  return (
    <div className="col col-6 col-sm-3">
      <div className="building--widget specs--widget">
        <span
          className="bw__icon"
          style={isExpandable ? { paddingTop: '3px', alignSelf: 'flex-start' } : {}}
        >
          <i className={iconClass}></i>
        </span>
        <div className="bw__detail mr-auto" ref={elContainer}>
          <h6 className="bw__heading" style={extraHeadingStyle}>
            {heading}
          </h6>
          {isExpanded ? (
            details.map((detail, idx) => (
              <p className="bw__para" style={extraDetailStyle} key={idx}>
                {detail}
              </p>
            ))
          ) : (
            <p className="bw__para" style={extraDetailStyle}>
              {details[0]}
            </p>
            // I wonder if we need dots if there's > 1 line?
          )}
        </div>
        {isExpandable && (
          <a
            className="bw__arrow ml-1"
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            <i className={isExpanded ? 'icon-chevron-top' : 'icon-chevron-bottom'}></i>
          </a>
        )}
      </div>
    </div>
  );
};

export default ListingTourSpecCard;
