import React, { useLayoutEffect } from 'react';
import { GetListingObject } from '../../../state/listing/listing.types';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import copy from 'copy-to-clipboard';
import { showNotification } from '../../../utils/misc.utils';
import { DEFAULT_AVATAR } from '../../../common/constants';
import withUnsupportedBrowserAlertForParticipant from './withUnsupportedBrowserAlertForParticipant';
import { AdditionalUser, BrowserInterface } from '../../../state/subdomain/tour/tour.types';

interface Props {
  title: string;
  listing: GetListingObject;
  users?: AdditionalUser[];
  leaveCall: () => void;
  browser: BrowserInterface | null;
}

const ParticipantWaiting: React.FC<Props> = props => {
  const { title, listing, users } = props;
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );

  const copyLink = () => {
    copy(window.location.origin + window.location.pathname);
    showNotification('success', 'Link copied to the clipboard', '');
  };

  const recalculateFontSize = (boxId: string, parentId: string, textId: string, unitId: string) => {
    const boxEl = document.getElementById(boxId);
    if (boxEl) {
      const parentEl = document.getElementById(parentId);
      const textEl = document.getElementById(textId);
      if (textEl && parentEl) {
        const textFontSize = parseFloat(
          window.getComputedStyle(textEl).getPropertyValue('font-size'),
        );
        const textLineHeight = parseFloat(
          window.getComputedStyle(textEl).getPropertyValue('line-height'),
        );
        const unitEl = document.getElementById(unitId);
        if (unitEl) {
          const unitFontSize = parseFloat(
            window.getComputedStyle(unitEl).getPropertyValue('font-size'),
          );
          if (parentEl.clientWidth < parentEl.scrollWidth) {
            textEl.style.fontSize = `${textFontSize - 1}px`;
            textEl.style.lineHeight = `${textLineHeight - 1}px`;
            unitEl.style.fontSize = `${unitFontSize - 0.5}px`;
            recalculateFontSize(boxId, parentId, textId, unitId);
          }
        } else {
          if (parentEl.clientWidth < parentEl.scrollWidth) {
            textEl.style.fontSize = `${textFontSize - 1}px`;
            textEl.style.lineHeight = `${textLineHeight - 1}px`;
            recalculateFontSize(boxId, parentId, textId, unitId);
          }
        }
      }
    }
  };

  useLayoutEffect(() => {
    recalculateFontSize('sizeBox', 'sizeParent', 'size', 'sizeUnit');
    recalculateFontSize('rentBox', 'rentParent', 'rent', 'rentUnit');
  }, []);

  return (
    <div className="virtualTour--combine virtualTour--combine-alt">
      <div className="container container--large">
        <a className="listing__logo vtl-logo">
          <img
            src={
              company?.settings?.darkLogo
                ? compileAvatarUrl(company.settings.darkLogo.url)
                : '/images/HomeTour_Dark_Logo.svg'
            }
            alt="Logo"
          />
        </a>
      </div>
      <div className="vt--combine vt--combine-alt m-0">
        <div className="vt--container">
          <div className="vt--combine-main standby--canvas text-center pt-5 pt-lg-4 pb-4">
            <div className="adminSettings--head justify-content-center">
              <span className="adminSettings__text">Your broker will be with you momentarily</span>
            </div>
            <p className="schedule__paragraph mb-4 pb-2">
              The live tour hasn’t started yet. The tour will begin when the broker joins. Standby,
              please.
            </p>
            <h3 className="dashboard__heading mb-4">
              {listing?.building?.location?.split(',')[0]} - {listing?.suiteNo} Details
            </h3>
            <div className="suite--canvas">
              <div className="row row--space-10">
                <div className="col-12 col-md-4">
                  <div className="suite--box" id="sizeBox">
                    <div className="suite--box-f" id="sizeIconParent">
                      <span className="suiteBox__icon" id="sizeIcon">
                        <i className="icon-compress-wide"></i>
                      </span>
                      <label className="suiteBox__label" id="sizeIconLabel">
                        Size
                      </label>
                    </div>
                    <div className="suiteBox__sf" id="sizeParent">
                      {listing?.size ? (
                        <React.Fragment>
                          <span className="suiteBox__no" id="size">
                            {listing?.size?.toLocaleString()}
                          </span>
                          <span className="suiteBox__text" id="sizeUnit">
                            SF
                          </span>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <span className="suiteBox__text" id="sizeUnit">
                            N/A
                          </span>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="suite--box" id="rentBox">
                    <div className="suite--box-f" id="rentIconParent">
                      <span className="suiteBox__icon" id="rentIcon">
                        <i className="icon-month"></i>
                      </span>
                      <label className="suiteBox__label" id="rentIconLabel">
                        Rent
                      </label>
                    </div>
                    <div className="suiteBox__sf" id="rentParent">
                      {listing.monthlyRent ? (
                        <React.Fragment>
                          <span className="suiteBox__no" id="rent">
                            ${listing.monthlyRent.toLocaleString()}
                          </span>
                          <span className="suiteBox__text" id="rentUnit">
                            /SF/YR
                          </span>
                        </React.Fragment>
                      ) : (
                        <span className="suiteBox__text" id="rentUnit">
                          N/A
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-4">
                  <div className="suite--box">
                    <div className="suite--box-f">
                      <span className="suiteBox__icon">
                        <i className="icon-users"></i>
                      </span>
                      <label className="suiteBox__label">Seats</label>
                    </div>
                    <div className="suiteBox__sf">
                      {listing?.capacity ? (
                        <React.Fragment>
                          <span className="suiteBox__no">{listing.capacity}</span>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <span className="suiteBox__text">N/A</span>
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-4 pb-3">
              <p className="copyUrl__para text__gray mb-0">
                <a className="url__link" onClick={copyLink}>
                  Copy Url
                </a>{' '}
                & share to invite others
              </p>
            </div>
            {users && users?.length > 0 && (
              <div className="team--canvas">
                <div className="team--head">
                  <span className="teamHead__img">
                    <img
                      src={
                        company?.settings?.darkIcon
                          ? compileAvatarUrl(company.settings.darkIcon.url)
                          : '/images/HomeTour_Dark_Icon.svg'
                      }
                      alt="Logo"
                    />
                  </span>
                  <h3 className="heading__medium heading__medium-black">Broker Team</h3>
                </div>
                {Array.isArray(users) && users?.length > 0 && (
                  <div className="team--main">
                    <div className="row row--space-10">
                      {users?.map((user: any, index: number) => (
                        <React.Fragment key={index}>
                          <div className="col-12 col-md-4">
                            <div className="team--block">
                              <div className="teamBlock--img">
                                <img
                                  src={
                                    user?.avatar ? compileAvatarUrl(user.avatar) : DEFAULT_AVATAR
                                  }
                                  alt="Img"
                                  className="img--cover"
                                />
                              </div>
                              <h6 className="teamBlock__user">
                                {user?.firstName} {user?.lastName}
                              </h6>
                              {user?.email && (
                                <a
                                  href={`mailto:${
                                    user.email
                                  }?subject=Live%20Tour%20Contact:%20${encodeURIComponent(title)}`}
                                  className="teamBlock__email"
                                >
                                  {user.email}
                                </a>
                              )}
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withUnsupportedBrowserAlertForParticipant<Props>(ParticipantWaiting);
