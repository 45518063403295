import React, { useState } from 'react';
import { compileAvatarUrl } from '../../../../../utils/avatar.utils';
import { LISTING_STATUS, VIDEO_STATUS } from '../../../../../common/constants';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import VideoPanel from './videoPanel';

const ListingVideo: React.FC = () => {
  const listing = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing.data,
  );
  const [openVideo, handleOpen] = useState(false);

  const showPlayButton =
    listing?.videoUrl &&
    listing?.status === LISTING_STATUS.PUBLISHED &&
    listing?.videoStatus === VIDEO_STATUS.ACCEPTED;
  return (
    <div className="col-12 col-xl-6">
      <div className="video--block subListing--video mb-4">
        {listing?.building.imageUrl && (
          <img
            src={compileAvatarUrl(listing?.building.imageUrl)}
            alt="Img"
            className="img--cover"
          />
        )}
        {showPlayButton && (
          <div className="subListing--video-overlay" onClick={() => handleOpen(true)}>
            <a className="play__btn-blue">
              <i className="icon-play ml-1"></i>
            </a>
          </div>
        )}
        {openVideo && <VideoPanel close={() => handleOpen(false)} />}
      </div>
    </div>
  );
};

export default ListingVideo;
