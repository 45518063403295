import { useCallback, useState } from 'react';
import fetchActions from '../../utils/state.utils';
import axios from 'axios';
import { GetState, GetStateInterface } from '../../types/GetStateInterface';
import { quickSearchAPI } from './search.api';
import { QuickSearchResultsInterface, SEARCH_ACTIONS } from './search.types';

export const useQuickSearch = () => {
  const [res, setRes] = useState<GetStateInterface<QuickSearchResultsInterface>>(GetState);

  const quickSearch = useCallback(async (searchTerm: string) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(SEARCH_ACTIONS.QUICK_SEARCH);
    try {
      const response = await quickSearchAPI(
        searchTerm,
        fetchActions.setCT(SEARCH_ACTIONS.QUICK_SEARCH),
      );
      setRes({ data: response.data, hasData: true, loading: false, error: null });
    } catch (err) {
      if (!axios.isCancel(err)) {
        setRes({ hasData: true, loading: false, data: null, error: err });
      }
    }
  }, []);

  const reset = useCallback(() => {
    setRes(GetState);
  }, []);

  return { res, quickSearch, reset };
};
