import React from 'react';

const NewTourAddListingBlockShimmer: React.FC = () => {
  return (
    <div className="tourListing--block tl--shimmer">
      <span className="tourListing__img animate"></span>
      <div className="tourListing--row">
        <p className="para__md-dark animate"></p>
        <span className="addBtn__shimmer animate ml-sm-3"></span>
      </div>
    </div>
  );
};

export default NewTourAddListingBlockShimmer;
