import React from 'react';

interface Props {
  id?: string;
  key?: string;
  element: string;
  className?: string;
  style?: string;
  content: string;
}

const QuillPad: React.FC<Props> = (props: Props) => {
  const { element: Element, className, style, content, id, key } = props;
  return (
    <Element
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      id={id}
      key={key}
      className={className}
      style={style}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default QuillPad;
