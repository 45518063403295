import React, { useState, useEffect } from 'react';
import { GetListingObject } from '../../../../state/subdomain/listing/listing.types';
import { useHistory } from 'react-router';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import { UploadedMediaInterface } from '../../../../state/media/media.types';
import { SEPARATORS } from '../../../../common/constants';
import { checkIfNumber } from '../../../../utils/misc.utils';

interface Props {
  isMapShow: boolean;
  data: GetListingObject;
  market: string;
  subMarket: string;
  setCenter: (id: string) => void;
}
const ListBox: React.FC<Props> = (props: Props) => {
  const history = useHistory();

  const [mainImage, setImage] = useState({} as UploadedMediaInterface);
  useEffect(() => {
    const data = props.data;
    if (data.media) {
      let image = data.media.find(image => image.isPrimary === true);
      if (!image) image = data.media[0];
      setImage(image);
    }
  }, [props.data]);

  const openListing = (e: any) => {
    e.stopPropagation();
    history.push(
      `/listing/${props.data._id}/${props.data?.building?.location
        ?.split(SEPARATORS.COMMA)[0]
        .split(SEPARATORS.SPACE)
        .join('-')}-${
        checkIfNumber(props?.data?.suiteNo) ? 'Suite-' : ''
      }${props?.data?.suiteNo?.split(SEPARATORS.SPACE)?.join(SEPARATORS.DASH)}`,
    );
  };

  return (
    <div
      className={`al--block al--block-${props.isMapShow ? 'sm' : 'lg'} al--${
        props.isMapShow ? 'sm' : 'lg'
      }-alt`}
      onClick={openListing}
      onMouseOver={() => props.setCenter(props.data?._id)}
    >
      <div className="al--block-img">
        <img
          src={
            props.data?.media && props.data?.media?.length > 0
              ? compileAvatarUrl(mainImage.url)
              : props.data?.building?.imageUrl
              ? compileAvatarUrl(props.data.building.imageUrl)
              : '/images/Rectangle-15980.png'
          }
          alt="IMG"
        />
      </div>
      <div className="al--block-detail">
        <div className="ald--inner al--adress-component">
          <label className="al__address">{`${
            props.data?.building?.location?.split(SEPARATORS.COMMA)[0]
          } - ${checkIfNumber(props?.data?.suiteNo) ? 'Suite ' : ''}${
            props?.data?.suiteNo
          }`}</label>
          <div className="al--listing-box">
            <div className="al--listing">
              <span className="al__icon">
                <i className="icon-map-marker-light"></i>
              </span>
              <span className="al__text">{props.subMarket}</span>
            </div>
          </div>
        </div>
        <div className="ald--inner al--listings-component">
          <div className="al--listing-box">
            <div className="al--listing">
              <span className="al__icon">
                <i className="icon-size"></i>
              </span>
              <span className="al__text">Size</span>
              <span className="al__text alText__detail">
                {props.data.size ? `${props.data.size.toLocaleString()} SF` : 'N/A'}
              </span>
            </div>
            <div className="al--listing">
              <span className="al__icon">
                <i className="icon-month"></i>
              </span>
              <span className="al__text">Rent</span>
              <span className="al__text alText__detail">
                {props.data.monthlyRent
                  ? `$${props.data.monthlyRent.toLocaleString()}/SF/YR`
                  : 'N/A'}
              </span>
            </div>
            <div className="al--listing">
              <span className="al__icon">
                <i className="icon-users"></i>
              </span>
              <span className="al__text">Seats</span>
              <span className="al__text alText__detail">
                {props.data.capacity ? `${props.data.capacity} Employees` : 'N/A'}
              </span>
            </div>
          </div>
        </div>
        <div className="ald--inner al--btn-component">
          <a className="admin__button button__radius" onClick={openListing}>
            View {!props.isMapShow && 'Listing'} <i className="icon-arrow-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ListBox;
