import React from 'react';

interface Props {
  current: number;
  total: number;
}
const ListingPageNo: React.FC<Props> = props => {
  return (
    <div className="step--head">
      <span className="step__text">
        Step {props.current}/{props.total}:
      </span>
      <span className="step__info">Add Listing</span>
    </div>
  );
};

export default ListingPageNo;
