import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { AdditionalUser } from '../../../state/tour/tour.types';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import { DEFAULT_AVATAR } from '../../../common/constants';

interface Props {
  showBrokers: () => void;
}
const TourBrokers: React.FC<Props> = props => {
  const { showBrokers } = props;
  const tourInfo = useSelector((state: AppStateInterface) => state.tour.tourInfo.data);
  const [brokers, setBrokers] = useState([] as AdditionalUser[]);

  useEffect(() => {
    if (tourInfo?.additionalUsers) setBrokers(tourInfo.additionalUsers);
  }, [tourInfo]);

  return (
    <div className="white--rounded-box mb-20">
      <div className="d-flex flex-column flex-sm-row align-items-sm-center justify-content-sm-between">
        <h3 className="heading__medium heading__medium-black font-semibold mb-3 mb-sm-0">
          Your Team
        </h3>
        <a className="link__blue" onClick={showBrokers}>
          Add Broker +
        </a>
      </div>
      <div className="team--main team--main-alt mt-4 pt-2">
        <div className="row row--space-10">
          {brokers.map(broker => (
            <div className="col-12 col-md-6 col-xl-4" key={broker._id}>
              <div className="team--block team--block-alt">
                <div className="teamBlock--img">
                  <img
                    src={broker?.avatar ? compileAvatarUrl(broker.avatar) : DEFAULT_AVATAR}
                    alt="Img"
                    className="img--cover"
                  />
                </div>
                <div className="teamBlock--detail text-md-left d-flex flex-column align-items-center align-items-md-start">
                  <h6 className="teamBlock__user">{`${broker.firstName} ${broker.lastName}`}</h6>
                  <a className="teamBlock__email">{broker.email}</a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TourBrokers;
