import React, { useEffect, useState } from 'react';
import SearchBox from './search';
import Filters from './filters';
import ListGroup from './listingGroup';
import ListingMap from './map';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { useGetAllSdListings } from '../../../../state/subdomain/listing/listing.hook';
import {
  GetSubDomainListings,
  SortAndOrderInterface,
} from '../../../../state/subdomain/listing/listing.types';
import { debounce, isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';
import ListingGridShimmer from './shimmer/listingGrid';
import ListingSearchBoxShimmer from './shimmer/searchBox';
import LoadingMoreShimmer from './shimmer/loadingMore';
import { CompanyInfoInterface } from '../../../../state/subdomain/company/company.types';
import ContactLineShimmer from './shimmer/contactLine';
import ContactLine from './contactLine';
import NoListingFound from './noListing';

const SubDomainAllLists: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = qs.parse(location.search);
  const [mapView, setMapView] = useState(false);
  const [search, setSearch] = useState(
    decodeURIComponent(
      Array.isArray(queryParams?.search) && queryParams?.search?.length
        ? queryParams?.search[0]
        : typeof queryParams?.search === 'string'
        ? queryParams.search
        : '',
    ),
  );
  const [firstFetch, setFirstFetch] = useState(true);
  const [centerMap, setCenterMap] = useState('');
  const [company, setCompany] = useState({} as CompanyInfoInterface);
  const [sortAndOrder, setSortAndOrder] = useState<SortAndOrderInterface>({
    orderBy: undefined,
    sortBy: undefined,
  });
  const [subMarket, setSubMarket] = useState('');
  const companyData = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo,
  );
  const { res: allListingsResponse, getAllFilteredListings, fetchMore } = useGetAllSdListings();

  useEffect(() => {
    if (!allListingsResponse.loading && firstFetch && allListingsResponse.data)
      setFirstFetch(false);
  }, [allListingsResponse]);
  useEffect(() => {
    if (!companyData.loading && companyData.hasData && companyData.data)
      setCompany(companyData.data);
  }, [companyData]);
  useEffect(() => {
    if (!isEmpty(company)) {
      setFirstFetch(true);
      getAllFilteredListings(
        company._id,
        search,
        sortAndOrder.sortBy,
        sortAndOrder.orderBy,
        subMarket,
      );
    }
  }, [search, sortAndOrder, subMarket, company]);

  useEffect(() => {
    const container = document.getElementById('SubDomainListingsParentScrollContainer');
    if (container) {
      container.onscroll = debounce(() => {
        if (
          allListingsResponse.error ||
          allListingsResponse.loading ||
          (allListingsResponse.data && !allListingsResponse.data?.hasMore)
        )
          return <></>;

        if (
          Math.abs(
            Math.floor(container.scrollHeight - container.scrollTop) - container.clientHeight,
          ) < Math.floor(container.clientHeight * 0.2)
        ) {
          fetchMore(company._id, search, sortAndOrder.sortBy, sortAndOrder.orderBy, subMarket);
        }
      }, 100);
    }
  });

  const onSearchChange = debounce((value: any) => {
    if (queryParams && queryParams.search && !value) history.push('/');
    setSearch(value);
  }, 300);

  const listRes = allListingsResponse.data?.data;
  return (
    <React.Fragment>
      <div className="available--listing-view">
        {!companyData.loading ? (
          <div className="row row--space-10 row-availableListings">
            <div className="col-12 col-lg-4">
              <SearchBox onSearchChange={onSearchChange} defaultSearch={search} />
            </div>
            <div className="col-12 col-lg-8">
              <Filters
                search={search}
                setSearch={setSearch}
                sortAndOrder={sortAndOrder}
                setSortAndOrder={setSortAndOrder}
                viewBy={{ mapView, setMapView }}
                subMarket={subMarket}
                setSubMarket={setSubMarket}
              />
            </div>
          </div>
        ) : (
          <ListingSearchBoxShimmer />
        )}
      </div>

      {!companyData.loading ? <ContactLine /> : <ContactLineShimmer />}
      {(companyData.loading || (allListingsResponse.loading && firstFetch)) && (
        <ListingGridShimmer />
      )}
      {allListingsResponse.data && (
        <React.Fragment>
          {listRes && Array.isArray(listRes) && listRes?.length > 0 && (
            <div className={`available--listing-cols show--col-${mapView ? 'list' : 'grid'}`}>
              <div
                className={`row row--space-10 row-availableListings ${
                  mapView ? 'flex-lg-row-reverse' : ''
                }`}
              >
                <div
                  className={`col-12 scroll--listing-cols ${mapView ? 'col-lg-8' : ''}`}
                  id="SubDomainListingsParentScrollContainer"
                >
                  {listRes?.map((groupedLists: GetSubDomainListings, index: number) => {
                    return groupedLists.listings && groupedLists.listings.length > 0 ? (
                      <ListGroup
                        key={index}
                        setCenter={(listingId: string) => setCenterMap(listingId)}
                        mapView={mapView}
                        market={groupedLists.market}
                        subMarket={groupedLists.subMarket}
                        listings={groupedLists.listings}
                      />
                    ) : (
                      <React.Fragment key={index}></React.Fragment>
                    );
                  })}
                  {allListingsResponse.loading && !firstFetch && <LoadingMoreShimmer />}
                </div>
                {mapView && (
                  <div className="col-12 col-lg-4">
                    {listRes?.length > 0 && (
                      <ListingMap data={allListingsResponse.data.data} center={centerMap} />
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {listRes && Array.isArray(listRes) && listRes?.length === 0 && <NoListingFound />}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default SubDomainAllLists;
