import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import SelectedTourBrokers from './selectedBrokers';
import BrokerSearchBox from './searchBox';
import BrokerBox from './brokerBox';
import { TourListingsSelected } from '../../../../state/subdomain/tour/tour.types';
import { UsersInterface } from '../../../../state/users/users.types';
import { useParams } from 'react-router';
import { useChangeUserTour } from '../../../../state/tour/tour.hook';
import { showNotification } from '../../../../utils/misc.utils';
import { errorExtractor } from '../../../../utils/error.utils';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { changeTourUsersAction } from '../../../../state/tour/tour.action';
import SmallLoader from '../../../common/small.loader';
import { xor } from 'lodash';

interface Props {
  data: UsersInterface[];
  selected: TourListingsSelected[];
  onSelect: (e: UsersInterface) => void;
  onRemove: (e: string) => void;
  close: () => void;
}
const AddBrokerPanel: React.FC<Props> = props => {
  const { data, selected, onSelect, onRemove, close } = props;
  const brokers = useSelector((state: AppStateInterface) => state.users.brokers);
  const tourInfo = useSelector((state: AppStateInterface) => state.tour.tourInfo.data);
  const [search, setSearch] = useState('');
  const [disableSubmit, handleDisable] = useState(true);
  const { tourId } = useParams();
  const { res: changeRes, changeUsers } = useChangeUserTour();

  const submitData = () => {
    const ids = selected.map(e => e.id);
    changeUsers(tourId, ids);
  };

  useEffect(() => {
    if (!changeRes.loading && changeRes.hasData) {
      if (changeRes.error)
        showNotification('error', 'Add User Error', errorExtractor(changeRes.error));
      else {
        if (changeRes.data) {
          if (brokers?.data?.length) {
            const ids = (changeRes.data as any).userIds;
            const newUsers = brokers.data.filter(user => ids.includes(user._id));
            changeTourUsersAction(newUsers);
            close();
          }
        }
      }
    }
  }, [changeRes]);

  useEffect(() => {
    if (tourInfo?.additionalUsers?.length && selected?.length) {
      const selectedIds = selected.map(e => e.id);
      const alreadyIds = tourInfo.additionalUsers.map(user => user._id);
      handleDisable(
        selectedIds.length === alreadyIds.length && xor(selectedIds, alreadyIds).length === 0,
      );
    }
  }, [tourInfo, selected]);

  return (
    <div className="componentEdit--scrollable">
      <p className="para__md-gray mb-4">
        Select one or multiple brokers from the list, or invite a new one.
      </p>
      <BrokerSearchBox onSubmit={setSearch} />
      <div className="badge--row badge--row-alt d-flex align-items-center flex-wrap">
        {selected && <SelectedTourBrokers data={selected} onRemove={onRemove} />}
      </div>
      <BrokerBox data={data} search={search} selected={selected} onSelect={onSelect} />
      <div className="tourEdit__btn-canvas text-center text-sm-right mt-4">
        <Button
          type="button"
          className="admin__button admin__button-sm admin__button-radius"
          onClick={submitData}
          disabled={changeRes.loading || selected.length === 0 || disableSubmit}
        >
          {changeRes.loading ? (
            <React.Fragment>
              Adding <SmallLoader />
            </React.Fragment>
          ) : (
            <React.Fragment>
              Add Brokers <i className="icon-plus ml-2"></i>
            </React.Fragment>
          )}
        </Button>
      </div>
    </div>
  );
};

export default AddBrokerPanel;
