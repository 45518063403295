import React from 'react';
import { QuickSearchResultsInterface } from '../../state/search/search.types';
import BuildingSearchResults from './buildings';
import MediaSearchResults from './media';
import TourSearchResults from './tours';
import VideoSearchResults from './videos';
import ThreeDTourSearchResults from './3dtours';
import PdfsSearchResults from './pdfs';
import { uniq } from 'lodash';

interface Props {
  results: QuickSearchResultsInterface;
}

const SearchResults: React.FC<Props> = (props: Props) => {
  const { results } = props;
  const { buildings, media, pdfs, renderings, tours, tourUrls, videoUrls } = results;
  const uniqVideoUrls = videoUrls?.length > 0 ? uniq(videoUrls) : [];
  const uniqTourUrls = tourUrls?.length > 0 ? uniq(tourUrls) : [];

  if (
    buildings?.length > 0 ||
    media?.length > 0 ||
    pdfs?.length > 0 ||
    renderings?.length > 0 ||
    tours?.length > 0 ||
    uniqVideoUrls?.length > 0 ||
    uniqTourUrls?.length > 0
  ) {
    return (
      <React.Fragment>
        {buildings?.length > 0 && <BuildingSearchResults buildings={buildings} />}
        {tours?.length > 0 && <TourSearchResults tours={tours} />}
        {(media?.length > 0 || renderings?.length > 0) && (
          <MediaSearchResults
            media={[
              ...(Array.isArray(media) ? media : []),
              ...(Array.isArray(renderings) ? renderings : []),
            ]}
          />
        )}
        {uniqVideoUrls?.length > 0 && <VideoSearchResults videoUrls={uniqVideoUrls} />}
        {uniqTourUrls?.length > 0 && <ThreeDTourSearchResults tourUrls={uniqTourUrls} />}
        {pdfs?.length > 0 && <PdfsSearchResults pdfs={pdfs} />}
      </React.Fragment>
    );
  } else {
    return (
      <div className="dashboard--notfound notfound--result notfound--result-tours mt-auto mb-auto pt-4 pt-md-5">
        <span className="notfound__result-img"></span>
        <p className="para__lg para__lg-dark">
          {"Sorry, there aren't any great matches for your search."}
        </p>
        <p className="para__md-gray mb-0">Please try again.</p>
      </div>
    );
  }
};

export default SearchResults;
