import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import { SEPARATORS } from '../../../../../common/constants';
import { checkIfNumber } from '../../../../../utils/misc.utils';

interface Props {
  showForm: () => void;
}
const ListingHandler: React.FC<Props> = props => {
  const { showForm: show } = props;
  const comp = useSelector((state: AppStateInterface) => state.subdomain.company.companyInfo.data);
  const info = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing.data,
  );

  const [contactHref, setContactHref] = useState<string>();

  const setContactHrefFromEmail = (emails: string) => {
    setContactHref(
      `mailto:${emails}?subject=${info?.building?.location?.split(SEPARATORS.COMMA)[0]}%20-%20${
        checkIfNumber(info?.suiteNo) ? 'Suite%20' : ''
      }${info?.suiteNo}`,
    );
  };

  useEffect(() => {
    const emails = info?.users?.map(user => user.email);
    if (emails?.length) setContactHrefFromEmail(emails.join(SEPARATORS.COMMA));
    else if (comp?.settings?.contactEmail) setContactHrefFromEmail(comp.settings.contactEmail);
    else setContactHref('#');
  }, [info, comp]);

  return (
    <div className="row row--space-10 flex-lg-row-reverse mt-3">
      <div className="col-12 col-lg">
        <button
          onClick={show}
          type="button"
          className="admin__button button__radius w-100 mb-3 mb-lg-0"
        >
          Request a {comp?.name.toUpperCase()} Guided Virtual Tour
          <i className="icon-schedule-alt ml-2"></i>
        </button>
      </div>
      {((info?.users && info?.users?.length > 0) || comp?.settings?.contactEmail) && (
        <div className="col-12 col-lg">
          <button
            className="outline__button button__radius w-100 mb-3 mb-lg-0"
            onClick={() => window.open(contactHref, '_blank')}
          >
            We Are Here to Help, Contact Us Today!
          </button>
        </div>
      )}
    </div>
  );
};
export default ListingHandler;
