import * as yup from 'yup';
import { AdminInvitationFormFields } from './admin.form';

export enum BrokerInvitationFormFields {
  PASSWORD = 'password',
  TERMS = 'terms',
}

export const BrokerInvitationFormSchema = yup.object().shape({
  [BrokerInvitationFormFields.PASSWORD]: yup
    .string()
    .min(8, 'Password must be of at least 8 characters')
    .required('This field is required'),
  [BrokerInvitationFormFields.TERMS]: yup
    .bool()
    .test(AdminInvitationFormFields.TERMS, 'Please accept our terms & conditions', value => !!value)
    .required('Please accept our terms & conditions'),
});
