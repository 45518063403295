import { AxiosPromise, CancelToken } from 'axios';
import api from '../../utils/api.utils';
import {
  SignInRequestInterface,
  ForgetStateInterface,
  ResetStateInterface,
  ChangePasswordInterface,
} from './login.types';

export const loginAPI = (payload: SignInRequestInterface, ct?: CancelToken): AxiosPromise => {
  return api.post('/auth/login', payload, {
    cancelToken: ct,
  });
};

export const forgetPasswordAPI = (
  payload: ForgetStateInterface,
  ct?: CancelToken,
): AxiosPromise => {
  return api.get('/auth/forgot-password', {
    cancelToken: ct,
    params: payload,
  });
};

export const resetPasswordAPI = (payload: ResetStateInterface, ct?: CancelToken): AxiosPromise => {
  return api.post('/auth/reset-password', payload, {
    cancelToken: ct,
  });
};

export const changePasswordAPI = (
  payload: ChangePasswordInterface,
  ct?: CancelToken,
): AxiosPromise => {
  return api.post('/auth/change-password', payload, {
    cancelToken: ct,
  });
};

export const logoutAPI = (ct?: CancelToken): AxiosPromise => {
  return api.delete('/auth/logout', { cancelToken: ct });
};
