import React, { useEffect, useState } from 'react';
import AddListingPanel from './addListingPanel';
import { RecentListing } from '../../../../state/listing/listing.types';
import { useGetRecentListings } from '../../../../state/listing/listing.hook';
import { DEFAULT_LISTING_LIMIT, LISTING_STATUS, SEPARATORS } from '../../../../common/constants';
import { checkIfNumber, showNotification } from '../../../../utils/misc.utils';
import { errorExtractor } from '../../../../utils/error.utils';
import { TourListingsSelected } from '../../../../state/subdomain/tour/tour.types';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';

interface Props {
  close: () => void;
}
const AddListing: React.FC<Props> = props => {
  const { close } = props;
  const tour = useSelector((state: AppStateInterface) => state.tour.tourInfo.data);
  const { res: listingRes, getRecentListings } = useGetRecentListings();

  const [selected, setSelected] = useState([] as TourListingsSelected[]);
  const [list, setList] = useState([] as RecentListing[]);
  const [search, setSearch] = useState('');
  const [localSearch, setLocalSearch] = useState(false);
  const [firstFetch, setFirstFetch] = useState(true);

  const getName = (e: RecentListing): string => {
    return `${e?.building?.location?.split(SEPARATORS.COMMA)[0]} - ${
      checkIfNumber(e?.suiteNo) ? 'Suite ' : ''
    }${e?.suiteNo}`;
  };
  const fetchMore = () => {
    if (listingRes?.data?.hasMore) {
      setFirstFetch(false);
      getRecentListings(
        {
          search,
          limit: DEFAULT_LISTING_LIMIT,
          skip: listingRes?.data?.skip || 0,
          status: LISTING_STATUS.PUBLISHED,
        },
        list,
      );
    }
  };
  const onSelect = (e: RecentListing) => {
    setSelected(prevState => [...prevState, { name: getName(e), id: e._id }]);
  };
  const onRemove = (e: string) => {
    setSelected(prevState => prevState.filter(listing => listing.id !== e));
  };

  useEffect(() => {
    if (tour?.listings) {
      const ids = tour.listings.map(listing => ({
        id: listing?.listing?._id || '',
        name: getName((listing.listing as unknown) as RecentListing),
      }));
      setSelected(ids);
    }
  }, [tour]);
  useEffect(() => {
    if (!localSearch) {
      setFirstFetch(true);
      getRecentListings({
        search,
        limit: DEFAULT_LISTING_LIMIT,
        skip: 0,
        status: LISTING_STATUS.PUBLISHED,
      });
    } else {
      setList(
        listingRes?.data?.data?.filter(
          listing =>
            listing?.suiteNo?.includes(search) || listing?.building?.location?.includes(search),
        ) || [],
      );
    }
  }, [search]);
  useEffect(() => {
    if (!listingRes.loading && listingRes.hasData) {
      if (listingRes.error)
        showNotification('error', 'Fetching Error', errorExtractor(listingRes.error));
      else if (listingRes.data && listingRes.data.data) setList(listingRes.data.data);
    }
  }, [listingRes]);

  const onSearchChange = (newSearchValue: string) => {
    setSearch(prevState => {
      if (!prevState && listingRes?.data?.hasMore === false && selected?.length) {
        setLocalSearch(true);
      } else if (!selected?.length) {
        setLocalSearch(false);
      }
      return newSearchValue;
    });
  };

  return (
    <div className="component--edit-overlay">
      <div className="component--edit">
        <div className="componentEdit--head">
          <div className="d-flex justify-content-between align-items-center mb-0">
            <h3 className="heading__medium heading__medium-black font-semibold mb-0">
              Add Listing
            </h3>
            <span className="ls__cross ml-3" onClick={close}>
              <i className="icon-cross"></i>
            </span>
          </div>
        </div>
        <AddListingPanel
          onSearch={onSearchChange}
          data={list}
          selected={selected}
          loading={listingRes.loading}
          firstFetch={firstFetch}
          listingRes={listingRes}
          fetchMore={fetchMore}
          onSelect={onSelect}
          onRemove={onRemove}
          close={close}
        />
      </div>
    </div>
  );
};
export default AddListing;
