import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { UploadedMediaInterface } from '../../../../state/media/media.types';
import UploadPhotos from '../../../listing/form/media/uploads/media';
import { showNotification } from '../../../../utils/misc.utils';
import { errorExtractor } from '../../../../utils/error.utils';
import Loader from '../../../common/loader';
import { useChangeTourMedia, useChangeTourListingMedia } from '../../../../state/tour/tour.hook';
import ImageZoom from './imageHandler/imageZoom';
import ShowImage from './imageHandler/showImage';
import { EditTourListingMedia } from '../../../../state/tour/tour.types';
import { useParams } from 'react-router';
import { TOUR_UPLOAD_LIMIT } from '../../../../common/constants';

const HandleTourGallery: React.FC = () => {
  const listing = useSelector((state: AppStateInterface) => state.tour.tourListing);
  const { res: listingRes, changeMedia } = useChangeTourListingMedia();
  const { changeTourImages } = useChangeTourMedia();
  const { tourId } = useParams();

  const [media, setMedia] = useState([] as UploadedMediaInterface[]);
  const [showSingle, setSingle] = useState('');
  const [showLarge, setLarge] = useState('');
  const [showUpload, handleUpload] = useState(false);
  const [newMedia, setNewMedia] = useState([] as UploadedMediaInterface[]);

  const handlePhotos = (images: UploadedMediaInterface[]) => {
    const finalData = {
      listingId: listing?.listing._id,
      roomId: tourId,
      media: images,
    } as EditTourListingMedia;
    let photos = images;
    if (listing?.additionalMedia) photos = [...listing.additionalMedia, ...photos];
    setNewMedia(photos);
    changeMedia(finalData);
  };

  useEffect(() => {
    if (!listingRes.loading && listingRes.hasData) {
      if (listingRes.error)
        showNotification('error', 'Update Error', errorExtractor(listingRes.error));
      else if (listingRes.data && listing?._id) changeTourImages(listing._id, newMedia);
    }
  }, [listingRes]);

  useEffect(() => {
    if (listing?.listing) {
      const photos = listing.listing.media;
      const primaryIdx = photos.findIndex(image => image.isPrimary);
      if (primaryIdx !== -1) {
        const primaryImage = photos[primaryIdx];
        photos.splice(primaryIdx, 1);
        photos.unshift(primaryImage);
      }
      if (listing.additionalMedia) setMedia([...photos, ...listing.additionalMedia]);
      else setMedia(photos);
    }
  }, [listing]);

  return (
    <div className="tourEdit--top tourEdit--top-gallery">
      {listingRes.loading && <Loader />}
      <div className="liveTour--gallery liveTour--custom liveTour--wide">
        <div className="container liveTour--container">
          <div className="liveTour--gallery-content">
            <div className="liveTour--gallery-row">
              <div className="liveTour--gallery-col">
                <label
                  className="upload--gallery-img liveTour--gallery-img"
                  onClick={() => handleUpload(true)}
                >
                  <i className="icon-upload"></i>
                  Upload Photos
                </label>
              </div>
              {media.map((image, index) => (
                <ShowImage key={index} src={image} onClick={setSingle} />
              ))}
            </div>
          </div>
          {showSingle.length > 0 && (
            <ImageZoom zoom src={showSingle} onClose={setSingle} onClick={setLarge} />
          )}
        </div>
      </div>
      {showLarge.length > 0 && <ImageZoom extraZoom src={showLarge} onClick={setLarge} />}
      {showUpload && (
        <UploadPhotos
          handleClose={() => handleUpload(false)}
          handleChanges={handlePhotos}
          alreadyFiles={listing?.additionalMedia.length || 0}
          limit={TOUR_UPLOAD_LIMIT}
          title="Photo"
        />
      )}
    </div>
  );
};

export default HandleTourGallery;
