import * as yup from 'yup';
import { ListingInterface, ListingErrorInterface } from './listing.types';

const positiveError = 'must be a positive number';
const requiredField = 'This field is required';

export enum ListingForm {
  spaceUse = 'spaceUse',
  suiteNo = 'suiteNo',
  size = 'size',
  monthlyRent = 'monthlyRent',
  capacity = 'capacity',
  condition = 'condition',
  metroProximity = 'metroProximity',
  description = 'description',
  matterPortLink = 'matterPortLink',
  videoDescription = 'videoDescription',

  available = 'available',
  media = 'media',
  floorPlans = 'floorPlans',
  renderings = 'renderings',
  pdfs = 'pdfs',
  users = 'users',
}

export const ListingFormSchema = yup.object().shape({
  [ListingForm.spaceUse]: yup.string().required(requiredField),
  [ListingForm.suiteNo]: yup.string().required(requiredField),
  [ListingForm.size]: yup
    .number()
    .required(requiredField)
    .typeError('Value must be a number')
    .positive('Value must be greater than 0'),
  [ListingForm.monthlyRent]: yup
    .number()
    .min(0)
    .transform((cv, ov) => {
      return ov === '' ? undefined : cv;
    })
    .typeError('Value must be a number'),
  [ListingForm.metroProximity]: yup.string().required(requiredField),
  [ListingForm.description]: yup.string().required(requiredField),
  [ListingForm.matterPortLink]: yup
    .string()
    .url('Please enter valid url')
    .required(requiredField),
  [ListingForm.videoDescription]: yup.string(),
});

export const verifyData = (data: ListingInterface) => {
  const dataError = {} as ListingErrorInterface;
  if (!data.available) dataError.available = 'This field is required';
  if (!data.media || !data.media.length) dataError.media = 'At least 1 image is required';
  return dataError;
};
