import React from 'react';

const ContactLineShimmer: React.FC = () => {
  return (
    <div className="pb-4">
      <p className="shimmer__md mb-3 d-flex align-items-center">
        <span className="shimmer__md-text shimmer__style animate"></span>
        <span className="shimmer__md-link ml-1 shimmer__style animate"></span>
      </p>
    </div>
  );
};

export default ContactLineShimmer;
