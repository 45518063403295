import React from 'react';
import LeftSidebar from '../../components/leftSidebar';
import HamBurger from '../../components/leftSidebar/hamburger';
import Search from '../../components/search';

const SearchPage = () => {
  return (
    <div className="dashboard--page buildings bg--gray">
      <LeftSidebar active="search" />
      <div className="dashboard--content dashboard--content-new">
        <div className="dashboard--off">
          <div className="dashboard--canvas">
            <div className="dashboard--opt">
              <HamBurger />
            </div>
            <Search />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchPage;
