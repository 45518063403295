import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { VIDEO_STATUS } from './../../../common/constants';

const ReviewSegmentNo: React.FC = () => {
  const listingData = useSelector((state: AppStateInterface) => state.listing.singleListing);
  const [status, setStatus] = useState('');
  useEffect(() => {
    if (listingData.data) setStatus(listingData.data.videoStatus);
  }, [listingData]);

  return (
    <React.Fragment>
      <h3 className="heading__medium heading__medium-black">
        {status === VIDEO_STATUS.PENDING && 'Record Voice-over'}
        {status === VIDEO_STATUS.ACCEPTED && 'Your video has been approved'}
        {status === VIDEO_STATUS.VOICEOVER_SUBMITTED && 'Voice-Over Submitted'}
        {status === VIDEO_STATUS.UPLOADED && 'What would you like to change ?'}
        {/* :{numToString(segmentNo)}/{numToString(REVIEW_SEGMENTS)} */}
      </h3>
      <p className="form__para text__dark">
        {status === VIDEO_STATUS.PENDING &&
          'Here you will have the opportunity to pre-record a self-guided walk-through of the Matterport video for your listing.'}
        {status === VIDEO_STATUS.ACCEPTED &&
          'Would you like to make an editing request? This will not change the status of your listing.'}
        {status === VIDEO_STATUS.VOICEOVER_SUBMITTED &&
          'You have submitted this recording and the comments below. We are now in the process of updating this video. Standby to get the edited video.'}
        {status === VIDEO_STATUS.UPLOADED &&
          'Please let us know what you think about this new version. Add a comment or feel free to upload a new recording.'}
      </p>
    </React.Fragment>
  );
};

export default ReviewSegmentNo;
