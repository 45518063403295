import { useCallback, useState } from 'react';
import fetchActions from '../../../utils/state.utils';
import { GetState } from '../../../types/GetStateInterface';
import { getSubDomainStatusAPI, getSubDomainAvailabilityAPI } from './landing.api';
import { SubDomainActions } from './landing.types';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../..';
import { useHistory } from 'react-router';
import { getDomainFromSubdomain } from '../../../utils/misc.utils';

export const useGetSubDomainStatus = () => {
  const [res, setRes] = useState(GetState);

  const getSubDomainStatus = useCallback(async (subdomain: string) => {
    const actionName = SubDomainActions.SUBDOMAIN_STATUS;
    fetchActions.callCT(actionName);
    setRes(prevState => ({ ...prevState, loading: true }));
    try {
      const res = await getSubDomainStatusAPI(subdomain, fetchActions.setCT(actionName));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);

  const getSubDomainAvailability = useCallback(async (subdomain: string) => {
    const actionName = SubDomainActions.SUBDOMAIN_AVAILABILITY;
    fetchActions.callCT(actionName);
    setRes(prevState => ({ ...prevState, loading: true }));
    try {
      const res = await getSubDomainAvailabilityAPI(subdomain, fetchActions.setCT(actionName));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);

  return { res, getSubDomainStatus, getSubDomainAvailability };
};

export const useRedirectToMainApp = () => {
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );
  const history = useHistory();
  const redirectTo = (path: string) => {
    if (company?.loggedIn) window.location.href = getDomainFromSubdomain(path);
    else history.push('/');
  };
  return { redirectTo };
};
