import React, { useState, useEffect } from 'react';
import { TOUR_TYPES } from '../../../../../common/constants';

interface Props {
  setType: (e: TOUR_TYPES) => void;
}
const TourOptions: React.FC<Props> = props => {
  const { setType } = props;
  const [tourOption, setTourOption] = useState('' as TOUR_TYPES);
  useEffect(() => (setType ? setType(tourOption) : undefined), [tourOption, setType]);

  return (
    <div className="row row--space-10">
      <div className="col col-12 col-md-6">
        <label className="ct--radio-btn">
          <div className="ctRadio--content">
            <div className="ctRadio--head mb-1">
              <span className="ctRadio__icon">
                <i className="icon-live-integrate"></i>
              </span>
              <span className="para__md-dark">Live Integrated Video Tour</span>
            </div>
            <p className="small__paragraph mb-0">
              This type of tour allows you to have a video-call with clients around any number of
              listings all within the same interface
            </p>
          </div>
          <input
            type="radio"
            name="radio"
            onChange={() => setTourOption(TOUR_TYPES.LIVE)}
            checked={tourOption === TOUR_TYPES.LIVE}
          />
          <span className="checkmark"></span>
        </label>
      </div>
      <div className="col col-12 col-md-6">
        <label className="ct--radio-btn">
          <div className="ctRadio--content">
            <div className="ctRadio--head mb-1">
              <span className="ctRadio__icon">
                <i className="icon-tv-alt"></i>
              </span>
              <span className="para__md-dark">Tour Only (Presentation Style)</span>
            </div>
            <p className="small__paragraph mb-0">
              This type of tour exists for instances in which you do not need live video integrated
              within the tour.
            </p>
          </div>
          <input
            type="radio"
            name="radio"
            onChange={() => setTourOption(TOUR_TYPES.PRESENT)}
            checked={tourOption === TOUR_TYPES.PRESENT}
          />
          <span className="checkmark"></span>
        </label>
      </div>
    </div>
  );
};

export default TourOptions;
