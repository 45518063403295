import React from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../../state';
import { SEPARATORS } from '../../../../../common/constants';
import { checkIfNumber } from '../../../../../utils/misc.utils';

const ListingAddress: React.FC = () => {
  const data = useSelector(
    (state: AppStateInterface) => state.subdomain.listing.singleListing.data,
  );

  return (
    <React.Fragment>
      <h2 className="dashboard__heading dashboard__heading-alt font-semibold mt-4 pt-2 mb-2">
        {data?.building?.location?.split(SEPARATORS.COMMA)[0]}
      </h2>
      <h3 className="heading__medium heading__medium-dark font-semibold mb-4">
        {checkIfNumber(data?.suiteNo) ? 'Suite ' : ''}
        {data?.suiteNo}
      </h3>
      <div className="al--listing-box mb-4">
        <div className="al--listing">
          <span className="al__icon">
            <i className="icon-map-marker-light"></i>
          </span>
          <span className="al__text">{data?.building.subMarket.name}</span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListingAddress;
