import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import {
  TOUR_SWITCHES as SWITCH,
  TourListingInterface,
} from '../../../../../state/tour/tour.types';
import OptionsBelt from './optionsBelt';
import { useChangeListingTour, useChangeShadowEffect } from '../../../../../state/tour/tour.hook';
import {
  DragDropContext,
  Droppable,
  DroppableProvided,
  DropResult,
  ResponderProvided,
} from 'react-beautiful-dnd';
import { cloneDeep } from 'lodash';

interface Props {
  onClick: (listingId: string, switchTab: SWITCH) => void;
  listings: TourListingInterface[];
  currentListing: string;
  currentTab: SWITCH;
}
const DraggableListing: React.FC<Props> = props => {
  const { tourId } = useParams();
  const { listings: data } = props;
  const [listings, setListings] = useState([] as TourListingInterface[]);
  const [shouldReOpen, handleReOpen] = useState(true);
  const { showShadowEffect, containerRef } = useChangeShadowEffect('X');
  const {
    res: changeListingsPositionRes,
    changeListing: changeListingsPosition,
  } = useChangeListingTour();

  const onDragEnd = (result: DropResult, provided: ResponderProvided) => {
    if (!result.destination || result?.source?.index === result?.destination?.index) return;
    setListings(prevState => {
      const clonedPrevState = cloneDeep(prevState);
      const [removed] = clonedPrevState.splice(result.source.index, 1);
      clonedPrevState.splice(result.destination?.index || 0, 0, removed);
      changeListingsPosition(
        tourId,
        clonedPrevState.map(listing => listing._id),
      );
      handleReOpen(false);
      return clonedPrevState;
    });
  };

  useEffect(() => {
    setListings(data);
    handleReOpen(true);
  }, [data]);

  return (
    <div
      className={`tourEdit--listing-off ${
        showShadowEffect ? 'tourEdit--listing-shadow-effect' : ''
      }`}
    >
      <DragDropContext enableDefaultSensors onDragEnd={onDragEnd}>
        <Droppable
          droppableId="droppable"
          direction="horizontal"
          isDropDisabled={changeListingsPositionRes.loading}
        >
          {(provided: DroppableProvided, _) => (
            <div
              className="tourEdit--listing-canvas"
              ref={(ref: any) => {
                containerRef.current = ref;
                provided.innerRef(ref);
              }}
              {...provided.droppableProps}
            >
              {listings.map((listing, index) => (
                <OptionsBelt
                  loading={changeListingsPositionRes.loading}
                  listing={listing}
                  key={listing._id}
                  {...props}
                  index={index}
                  shouldOpen={index === 0 && shouldReOpen}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default DraggableListing;
