import React from 'react';

interface Props {
  url: string;
  remove: () => void;
  col?: boolean;
}
const SingleMediaTile: React.FC<Props> = props => {
  return (
    <div
      className={`uploaded--img-${props.col ? 'col' : 'block'} d-flex align-items-center flex-wrap`}
    >
      <div className="uploaded--img">
        <img src={props.url} alt="Img" />
      </div>
      <a className="uploaded-trash" onClick={props.remove}>
        <i className="icon-trash"></i>
      </a>
    </div>
  );
};

export default SingleMediaTile;
