import { BuildingActions, GetAllBuildingsDataInterface } from './building.types';
import fetchActions from '../../utils/state.utils';
import { getAllBuildingsAPI } from './building.api';
import { cloneDeep } from 'lodash';
import axios from 'axios';

export const mergeNewDataIntoPrevious = (
  prev: Array<GetAllBuildingsDataInterface>,
  next: Array<GetAllBuildingsDataInterface>,
): Array<GetAllBuildingsDataInterface> => {
  if (prev.length && next.length) {
    const mergedData: Array<GetAllBuildingsDataInterface> = [...cloneDeep(prev)];
    for (const market of next) {
      const marketExistInPrevious = prev.find(
        (prevMarket: any) => prevMarket.subMarket === market.subMarket,
      );
      if (marketExistInPrevious) {
        const marketIndexInMergedData = mergedData.findIndex(
          (mergedMarket: any) => mergedMarket.subMarket === market.subMarket,
        );
        if (marketIndexInMergedData > -1) {
          mergedData[marketIndexInMergedData].buildings = [
            ...marketExistInPrevious.buildings,
            ...market.buildings,
          ];
        }
      } else {
        mergedData.push(market);
      }
    }
    return mergedData;
  }
  if (prev.length && !next.length) {
    return prev;
  }
  if (!prev.length && next.length) {
    return next;
  }
  return [];
};

export const getAllBuildings = async (
  companyId: string,
  limit: number,
  skip: number,
  prev: Array<GetAllBuildingsDataInterface> = [],
) => {
  const actionName = BuildingActions.GET_ALL_BUILDINGS;

  fetchActions.callCT(actionName);
  fetchActions.init(actionName);

  try {
    const res = await getAllBuildingsAPI(companyId, limit, skip, fetchActions.setCT(actionName));
    if (prev.length) {
      fetchActions.success(actionName, {
        ...res.data,
        data: mergeNewDataIntoPrevious(prev, res.data.data),
      });
    } else {
      fetchActions.success(actionName, res.data);
    }
  } catch (error) {
    if (!axios.isCancel(error)) {
      fetchActions.failure(actionName, error);
    }
  }
};

export const clearAllBuildings = () => {
  fetchActions.dispatch({
    type: BuildingActions.GET_ALL_BUILDINGS_CLEAR,
    payload: {},
  });
};
