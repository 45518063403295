import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import GoogleMaps from './../../../location';

interface Location {
  lat: number;
  lng: number;
}

const HandleTourLocation: React.FC = () => {
  const listing = useSelector((state: AppStateInterface) => state.tour.tourListing);
  const [location, setLocation] = useState({} as Location);

  useEffect(() => {
    if (listing?.listing) {
      const e = listing.listing?.building;
      if (e.latitude && e.longitude)
        setLocation({ lat: parseFloat(e.latitude), lng: parseFloat(e.longitude) });
    }
  }, [listing]);

  return (
    <div className="tourEdit--top tourEdit--top-home">
      <div style={{ width: '100%', height: '100%' }}>
        <GoogleMaps
          location={{
            lat: location.lat,
            lng: location.lng,
          }}
          marker={[
            {
              lat: location.lat,
              lng: location.lng,
            },
          ]}
          selected={0}
        />
      </div>
    </div>
  );
};

export default HandleTourLocation;
