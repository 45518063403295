import React, { useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { TOUR_TYPES as type } from '../../../../common/constants';
import { useChangeTourType } from '../../../../state/tour/tour.hook';
import { useParams } from 'react-router';
import SmallLoader from '../../../common/small.loader';
import { showNotification } from '../../../../utils/misc.utils';
import { errorExtractor } from '../../../../utils/error.utils';

interface Props {
  total: number;
  currentType: type;
}

const HandleTourType: React.FC<Props> = props => {
  const { total, currentType } = props;
  const { tourId } = useParams();
  const { res: typeRes, changeTourType } = useChangeTourType();
  const changeType = (e: type) => changeTourType(tourId, e);
  useEffect(() => {
    if (!typeRes.loading && typeRes.error)
      showNotification('error', 'Type Change Error', errorExtractor(typeRes.error));
  }, [typeRes]);

  return (
    <div className="col col-12 col-md-8 col-lg-7 col-xl-6">
      <div className="d-flex flex-column flex-sm-row align-items-sm-center pr-xl-4">
        <Dropdown className="listing--dropdown listing--dropdown-update listing--dropdown-pad dropdown--radius listing--dropdown-n mb-3 mb-sm-0 w-100 spinner--group">
          <Dropdown.Toggle id="dropdown-basic">
            {currentType === type.LIVE && 'Live Integrated Video Tour'}
            {currentType === type.PRESENT && 'Presentation Style Tour'}
            <span className="select__caret-down">
              <i className="icon-caret-down"></i>
            </span>
            <span className="select__integrate">
              {currentType === type.LIVE && <i className="icon-live-integrate"></i>}
              {currentType === type.PRESENT && <i className="icon-tv-alt"></i>}
            </span>
            {typeRes.loading && <SmallLoader />}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => changeType(type.PRESENT)}
              active={currentType === type.PRESENT}
            >
              Presentation Style Tour
            </Dropdown.Item>
            <Dropdown.Item onClick={() => changeType(type.LIVE)} active={currentType === type.LIVE}>
              Live Integrated Video Tour
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <span className="para__md-gray text-nowrap ml-sm-2 pl-sm-1">{total} Listings</span>
      </div>
    </div>
  );
};

export default HandleTourType;
