import React from 'react';
import PrevReviewAudios from './prevAudios';
import PrevReviewComments from './prevComments';
import {
  AudioVoiceOverInterface,
  CommentsInterface,
} from '../../../../state/listing/listing.types';

interface Props {
  prevAudios: AudioVoiceOverInterface[];
  prevComments: CommentsInterface[];
}
const PrevReviewSubmission: React.FC<Props> = props => {
  const { prevAudios, prevComments } = props;
  return (
    <React.Fragment>
      {(prevAudios?.length > 0 || prevComments?.length > 0) && (
        <div className="key--details key--details-record mb-4 mt-0">
          <h6 className="keyDetails__heading d-flex align-items-center flex-wrap">
            Previous Submission
          </h6>
          {prevAudios?.length > 0 && <PrevReviewAudios audios={prevAudios} />}
          {prevComments?.length > 0 && <PrevReviewComments comments={prevComments} />}
        </div>
      )}
    </React.Fragment>
  );
};

export default PrevReviewSubmission;
