import { useCallback, useState } from 'react';
import { GetState } from '../../types/GetStateInterface';
import fetchActions from '../../utils/state.utils';
import axios from 'axios';
import { putCompanyAPI, putUserAPI } from './setting.api';
import {
  PutCompanyPayloadInterface,
  PutUserPayloadInterface,
  SettingActions,
} from './setting.types';

export const usePutUser = () => {
  const [res, setRes] = useState(GetState);

  const putUser = useCallback(async (payload: PutUserPayloadInterface) => {
    const actionName = SettingActions.PUT_USER;
    fetchActions.callCT(actionName);
    setRes(prevState => ({ ...prevState, loading: true }));
    try {
      const res = await putUserAPI(payload, fetchActions.setCT(actionName));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);

  return { res, putUser };
};

export const usePutCompany = () => {
  const [res, setRes] = useState(GetState);

  const putCompany = useCallback(async (companyId: string, payload: PutCompanyPayloadInterface) => {
    const actionName = SettingActions.PUT_COMPANY;
    fetchActions.callCT(actionName);
    setRes(prevState => ({ ...prevState, loading: true }));
    try {
      const res = await putCompanyAPI(companyId, payload, fetchActions.setCT(actionName));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);

  return { res, putCompany };
};
