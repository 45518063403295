import { useCallback, useEffect, useRef, useState } from 'react';
import { throttle } from 'lodash';
import DailyCo, { CallObject, SupportedBrowserInterface } from '@daily-co/daily-js';
import { GetState, GetStateInterface } from '../../../types/GetStateInterface';
import { createTourAPI, deleteTourAPI, tourAPI } from './tour.api';
import fetchActions from './../../../utils/state.utils';
import axios from 'axios';

import {
  AppMessageTypes,
  BrowserInterface,
  CreateTourInterface,
  Host,
  HostScreenDimensions,
  ParticipantPersonalDetails,
  SimpleParticipant,
  TourStates,
  VideoCallInterface,
} from './tour.types';
import {
  ALLOWED_BROWSERS_FOR_HOST,
  DailyCo as DailyTypes,
  MS_BETWEEN_NAME_REQUESTS,
  MS_BETWEEN_SPEAKER_CHANGE,
  TOUR_TYPES,
} from '../../../common/constants';
import { GetTourUrlResponseInterface } from '../../listing/listing.types';
import { TourActions } from '../../subdomain/tour/tour.types';

const DEFAULT_VIDEO_CONSTRAINTS: { kbps?: number; trackConstraints: MediaTrackConstraints } = {
  kbps: 100,
  trackConstraints: {
    aspectRatio: 1,
    height: 300,
    width: 300,
    // frameRate: { max: 120, min: 10, ideal: 60 },
    frameRate: 60,
  },
};

const MS_BETWEEN_QUALITY_SAMPLES = 5000;
const MIN_FRAME_RATE = 5;
const TARGET_FRAME_RATE_BY_QUALITY = [
  // [<daily co "call quality">, <frame rate above this quality>]
  [0, MIN_FRAME_RATE],
  [15, 10],
  [50, 30],
  [75, 60],
];
function findFrameRateForQuality(quality: number): number {
  let frameRate = MIN_FRAME_RATE;
  for (const [qualityThreshold, thresholdFrameRate] of TARGET_FRAME_RATE_BY_QUALITY) {
    if (quality >= qualityThreshold) {
      frameRate = thresholdFrameRate;
    } else {
      return frameRate;
    }
  }
  return frameRate;
}

interface TourManager {
  initiate: () => void;
  cleanup: () => void;
  joinCall: (name: string, email: string, isBroker?: boolean) => void;
  startCall: (name: string, email: string) => void;
  leaveCall: (endTour?: boolean) => void;
  muteAudio: (enabled?: boolean) => void;
  muteVideo: (enabled?: boolean) => void;
  reselectTab: () => void;
  updateAllHostDimensions: (screenDimensions: HostScreenDimensions) => void;
  tourState: TourStates;
}
interface TourManagerSetters {
  setBrokers: (arg: SimpleParticipant[]) => void;
  setTenants: (arg: SimpleParticipant[]) => void;
  setIsRecording: (arg: boolean) => void;
  setTourState: React.Dispatch<React.SetStateAction<TourStates>>;
  setIsHost: React.Dispatch<React.SetStateAction<boolean>>;
  setHost: React.Dispatch<React.SetStateAction<Host | null>>;
  setBrowser: React.Dispatch<React.SetStateAction<BrowserInterface | null>>;
}

interface DailyMessage {
  fromId: string;
  data: any;
}

enum DailyMeetingState {
  NEW = 'new',
  JOINING = 'joining-meeting',
  JOINED = 'joined-meeting',
  LEFT = 'left-meeting',
  ERROR = 'error',
}

function emptyTourManager() {
  return {
    /* eslint-disable */
    initiate() {}, // @ts-ignore
    cleanup() {}, // @ts-ignore
    joinCall: (name: string, email: string, isBroker?: boolean) => {}, // @ts-ignore
    startCall: (name: string, email: string) => {}, // @ts-ignore
    leaveCall: (endTour?: boolean) => {}, // @ts-ignore
    updateAllHostDimensions: (screenDimensions: HostScreenDimensions) => {}, // @ts-ignore
    tourState: TourStates.NOT_INITIALIZED, // @ts-ignore
    muteAudio: (enabled?: boolean) => {}, // @ts-ignore
    muteVideo: (enabled?: boolean) => {}, // @ts-ignore
    reselectTab: () => {}, // @ts-ignore
  };
}

function simplifyParticipant(
  participant: any,
  participantDetails: any,
  activeSpeakerSessionId?: string,
): SimpleParticipant {
  const {
    video: hasVideo,
    videoTrack,
    audio: hasAudio,
    audioTrack,
    session_id: sessionId,
  } = participant;
  const details = participantDetails[sessionId];
  return {
    hasVideo,
    videoTrack,
    hasAudio,
    audioTrack,
    sessionId,
    userName: details?.name,
    userEmail: details?.email,
    isSpeaking: activeSpeakerSessionId === sessionId,
  };
}

function hostifyParticipant(
  participant: any,
  participantDetails: any,
  activeSpeakerSessionId?: string,
): Host {
  return {
    ...simplifyParticipant(participant, participantDetails, activeSpeakerSessionId),
    screenWidth: 0,
    screenHeight: 0,
    shareableHeight: 0,
    shareableWidth: 0,
    screenTrack: participant.screenVideoTrack,
    hasScreenTrack: participant.screen,
  };
}

function isActive(participant: SimpleParticipant): boolean {
  return Boolean(participant.userEmail && participant.userName);
}

class DailyCoTourManager implements TourManager {
  _roomUrl: string;
  _dailyCoCall: CallObject;
  _dailyInitialized: boolean = false;
  _setters: TourManagerSetters;
  _participantPersonalDetails: ParticipantPersonalDetails = {};
  _computedHostParticipant?: Host;
  _hostDimensions: HostScreenDimensions;
  _screenTrack?: MediaStream;
  _activeSpeakerSessionId = '';
  _mySessionId = '';
  _isHost = false;
  _isEnded = false;
  _frameRate = 60;
  _browser: BrowserInterface | null = null;

  constructor(roomUrl: string, setters: TourManagerSetters) {
    this._roomUrl = roomUrl;
    this._dailyCoCall = {
      /* eslint-disable */
      on: (name: string, ev: any) => {}, // @ts-ignore
      off: (name: string, ev: any) => {}, // @ts-ignore
      join: (arg: any) => {}, // @ts-ignore
      leave: () => {}, // @ts-ignore
      participants: () => {}, // @ts-ignore
      updateParticipant: (sessionId: string, arg: any) => {}, // @ts-ignore
      startScreenShare: () => {}, // @ts-ignore
      stopScreenShare: () => {}, // @ts-ignore
      sendAppMessage: (arg: any) => {}, // @ts-ignore
      meetingState: () => DailyMeetingState.NEW,
      getNetworkStats: () => Promise.resolve(),
      setBandwidth: (a: any) => {},

      /* eslint-enable */
    };
    this._setters = setters;
    this._hostDimensions = {
      screenWidth: -1,
      screenHeight: -1,
      shareableHeight: -1,
      shareableWidth: -1,
    };
  }

  initiate() {
    this._dailyCoCall = DailyCo.createCallObject();
    this._browser = DailyCo.supportedBrowser();
    this._setters.setBrowser(this._browser);
    this._dailyCoCall.join({ url: this._roomUrl });
    this._addListeners();
  }

  cleanup() {
    const updateParticipantEvents = [
      DailyTypes.MEETING_JOIN,
      DailyTypes.PARTICIPANT_JOIN,
      DailyTypes.PARTICIPANT_UPDATE,
      DailyTypes.PARTICIPANT_LEFT,
      DailyTypes.MEETING_LEFT,
    ];
    updateParticipantEvents.forEach(eventName =>
      this._dailyCoCall.off(eventName, this._recalculateParticipants),
    );
    this._dailyCoCall.off(DailyTypes.APP_MESSAGE, this._onAppMessage);
    this._dailyCoCall.off(DailyTypes.SPEAKER_CHANGE, this._onActiveSpeakerChange);
  }

  joinCall = (name: string, email: string, isBroker?: boolean) => {
    const mySession = this._dailyCoCall.participants()['local']?.session_id || 'missing';
    this._participantPersonalDetails.local = { name, email };
    this._participantPersonalDetails[mySession] = { name, email };
    this._dailyCoCall.updateParticipant('local', {
      setAudio: true,
      setVideo: true,
    });
    this._dailyCoCall.setBandwidth({ ...DEFAULT_VIDEO_CONSTRAINTS });
  };

  startCall = (name: string, email: string) => {
    this.joinCall(name, email, true);
    this._isHost = true;
    this._setters.setIsHost(true);
    if (this._browser?.name && ALLOWED_BROWSERS_FOR_HOST.includes(this._browser.name)) {
      this._dailyCoCall.startScreenShare();
      setInterval(() => this._setFrameRate(), MS_BETWEEN_QUALITY_SAMPLES);
    }
  };

  leaveCall = (endTour = false) => {
    if (this._isHost && endTour) {
      this._dailyCoCall.sendAppMessage({ type: AppMessageTypes.END_TOUR });
    }
    this._dailyCoCall.leave();
    this._isEnded = endTour;
  };

  updateAllHostDimensions = (screenDimensions: HostScreenDimensions) => {
    this._hostDimensions = { ...screenDimensions };
    this._dailyCoCall.sendAppMessage({
      type: AppMessageTypes.SCREEN_DETAILS,
      payload: { ...screenDimensions },
    });
  };

  muteAudio = (enabled = false) => {
    this._dailyCoCall.updateParticipant('local', { setAudio: enabled });
  };

  muteVideo = (enabled = false) => {
    this._dailyCoCall.updateParticipant('local', { setVideo: enabled });
  };

  reselectTab = () => {
    this._dailyCoCall.stopScreenShare();
    this._dailyCoCall.startScreenShare();
  };

  _addListeners() {
    const updateParticipantEvents = [
      DailyTypes.MEETING_JOIN,
      DailyTypes.PARTICIPANT_JOIN,
      DailyTypes.PARTICIPANT_UPDATE,
      DailyTypes.PARTICIPANT_LEFT,
      DailyTypes.MEETING_LEFT,
    ];
    updateParticipantEvents.forEach(eventName =>
      this._dailyCoCall.on(eventName, this._recalculateParticipants),
    );
    this._dailyCoCall.on(DailyTypes.APP_MESSAGE, this._onAppMessage);
    this._dailyCoCall.on(DailyTypes.SPEAKER_CHANGE, this._onActiveSpeakerChange);
  }

  _recalculateParticipants = (ev?: any) => {
    if (!this._dailyInitialized && this._dailyCoCall.meetingState() !== DailyMeetingState.JOINED) {
      return;
    }

    this._dailyInitialized = true;
    const participants: { [key: string]: any } = this._dailyCoCall.participants();
    this._mySessionId = participants.local?.session_id;
    this._recalculateHost(participants);
    this._recalculateTenantsAndBrokers(participants);
  };

  _recalculateHost(participants: any) {
    let host: any = Object.values(participants).find((participant: any) => participant.screen);

    // if no screen share, try to use previous host
    if (!host) {
      if (this._isHost) {
        host = this._buildPreviousRawHost(participants);
        if (!host) return;
      } else {
        this._setters.setHost(null);
        return;
      }
    }

    const hostParticipant = hostifyParticipant(
      host,
      this._participantPersonalDetails,
      this._activeSpeakerSessionId,
    );
    if (this._isHost) hostParticipant.isMe = true;
    this._computedHostParticipant = hostParticipant;
    if (!isActive(this._computedHostParticipant)) {
      this._requestPersonalDetails();
    }
    if (this._missingHostDimensions) {
      this._dailyCoCall.sendAppMessage({ type: AppMessageTypes.REQUEST_SCREEN_DETAILS });
    }

    this._setters.setHost(this._computedHost);
  }

  _buildPreviousRawHost(participants: Record<string, any>): Host | false {
    let rawHost;
    const lastHostParticipant = this._computedHostParticipant;
    const lastHostSessionId = lastHostParticipant?.sessionId;
    if (lastHostParticipant && lastHostSessionId) {
      rawHost = Object.values(participants).find(
        (participant: any) => participant.session_id === lastHostSessionId,
      );
    }
    if (rawHost) {
      rawHost.screen = false;
      rawHost.screenVideoTrack = lastHostParticipant!.screenTrack;
      return rawHost;
    } else {
      return false;
    }
  }

  _recalculateTenantsAndBrokers(participants: Record<string, any>) {
    const newTenants: SimpleParticipant[] = [];
    const newBrokers: SimpleParticipant[] = [];
    const hostSessionId = this._computedHostParticipant?.sessionId;
    for (const [sessionId, dailyCoParticipant] of Object.entries(participants)) {
      if (dailyCoParticipant?.session_id === hostSessionId) continue;

      const participant = simplifyParticipant(
        dailyCoParticipant,
        this._participantPersonalDetails,
        this._activeSpeakerSessionId,
      );
      if (sessionId === 'local') {
        participant.isMe = true;
      }
      if (!isActive(participant)) {
        this._requestPersonalDetails();
      } else if (this._isBroker(participant)) {
        newBrokers.push(participant);
      } else {
        newTenants.push(participant);
      }
    }
    this._setters.setTenants(newTenants);
    this._setters.setBrokers(newBrokers);
  }

  _onAppMessage = (message: DailyMessage) => {
    switch (message.data?.type) {
      case AppMessageTypes.REQUEST_PERSONAL_DETAILS:
        this._sendPersonalDetails();
        break;
      case AppMessageTypes.PERSONAL_DETAILS:
        this._participantPersonalDetails[message.fromId] = message.data?.payload;
        this._recalculateParticipants();
        break;
      case AppMessageTypes.END_TOUR:
        this.leaveCall(true);
        break;
      case AppMessageTypes.REQUEST_SCREEN_DETAILS:
        if (this._isHost) {
          this._dailyCoCall.sendAppMessage({
            type: AppMessageTypes.SCREEN_DETAILS,
            payload: { ...this._hostDimensions },
          });
        }
        break;
      case AppMessageTypes.SCREEN_DETAILS:
        this._hostDimensions = message.data?.payload;
        this._setters.setHost(this._computedHost);
        break;
    }
  };

  _setFrameRate() {
    this._dailyCoCall.getNetworkStats().then((stats: any) => {
      const quality = stats?.quality;
      if (!quality) return;

      const frameRate = findFrameRateForQuality(quality);
      if (frameRate !== this._frameRate) {
        this._frameRate = frameRate;
        const newConstraints = {
          ...DEFAULT_VIDEO_CONSTRAINTS,
          trackConstraints: {
            ...DEFAULT_VIDEO_CONSTRAINTS.trackConstraints,
            frameRate,
          },
        };
        this._dailyCoCall.setBandwidth(newConstraints);
      }
    });
  }

  _onActiveSpeakerChange = throttle((ev: any) => {
    this._activeSpeakerSessionId = ev.activeSpeaker?.peerId;
    this._recalculateParticipants();
  }, MS_BETWEEN_SPEAKER_CHANGE);

  _requestPersonalDetails = throttle(() => {
    this._dailyCoCall.sendAppMessage({ type: AppMessageTypes.REQUEST_PERSONAL_DETAILS });
  }, MS_BETWEEN_NAME_REQUESTS);

  get _computedHost(): Host | null {
    if (!this._computedHostParticipant) return null;

    return {
      ...this._computedHostParticipant,
      ...this._hostDimensions,
    };
  }

  get _hostSessionId(): string {
    if (!this._computedHost) {
      return '';
    }

    return this._computedHost.sessionId;
  }

  get _hostDomain(): string {
    if (!this._hostSessionId) return '';

    const email = this._participantPersonalDetails[this._hostSessionId]?.email;
    if (email && email.includes('@')) {
      return email.split('@').pop() || '';
    } else {
      return '';
    }
  }

  get tourState() {
    const hostSessionId = this._hostSessionId;
    if (this._isEnded) {
      return TourStates.ENDED;
    } else if (!this._dailyInitialized) {
      return TourStates.NOT_INITIALIZED;
    } else if (!this._participantPersonalDetails.hasOwnProperty('local')) {
      if (hostSessionId) {
        return TourStates.NOT_JOINED;
      } else {
        return TourStates.NOT_JOINED_NOT_STARTED;
      }
    } else if (!hostSessionId) {
      return TourStates.JOINED_NOT_STARTED;
    } else {
      return TourStates.STARTED;
    }
  }

  _sendPersonalDetails() {
    const myDetails = this._participantPersonalDetails.local;
    if (myDetails) {
      this._dailyCoCall.sendAppMessage({
        type: AppMessageTypes.PERSONAL_DETAILS,
        payload: {
          email: myDetails.email,
          name: myDetails.name,
        },
      });
    }
  }

  _isBroker(participant: SimpleParticipant): boolean {
    if (!this._hostDomain) return false;

    return participant.userEmail.includes(this._hostDomain);
  }

  get _missingHostDimensions(): boolean {
    return (
      this._hostDimensions.screenWidth === -1 ||
      this._hostDimensions.screenHeight === -1 ||
      this._hostDimensions.shareableWidth === -1 ||
      this._hostDimensions.shareableHeight === -1
    );
  }
}

export const useGetTour = () => {
  const [tourRes, setTourRes] = useState(GetState);

  const getTour = useCallback(async (tourId: string) => {
    setTourRes(prevRes => ({ ...prevRes, loading: true }));
    try {
      const res = await tourAPI(tourId);
      setTourRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      setTourRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);

  return { tourRes, getTour };
};

export const useDeleteTour = () => {
  const [tourRes, setTourRes] = useState(GetState);
  const deleteTour = useCallback(async (tourId: string) => {
    setTourRes(prevRes => ({ ...prevRes, loading: true }));
    try {
      const res = await deleteTourAPI(tourId);
      setTourRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      setTourRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);
  return { tourRes, deleteTour };
};

export function useTour(roomUrl: string, tourType: TOUR_TYPES): VideoCallInterface {
  // internal
  const tourManager = useRef<TourManager>(emptyTourManager());

  // returned in video call
  const [isHost, setIsHost] = useState(false);
  const [tourState, setTourState] = useState(TourStates.NOT_INITIALIZED);
  const [isRecording, setIsRecording] = useState(false);
  const [host, setHost] = useState<Host | null>(null);
  const [tenants, setTenants] = useState<SimpleParticipant[]>([]);
  const [brokers, setBrokers] = useState<SimpleParticipant[]>([]);
  const [browser, setBrowser] = useState<BrowserInterface | null>(null);

  if (tourState !== tourManager.current.tourState && tourType === TOUR_TYPES.LIVE) {
    setTourState(tourManager.current.tourState);
  }

  useEffect(() => {
    if (tourType === TOUR_TYPES.PRESENT) {
      setTourState(TourStates.STARTED);
    }
  }, [tourType]);

  // initiate manager
  useEffect(() => {
    if (!roomUrl || !tourType || tourType !== TOUR_TYPES.LIVE) {
      return;
    }

    tourManager.current = new DailyCoTourManager(roomUrl, {
      setBrokers,
      setTenants,
      setIsRecording,
      setTourState,
      setHost,
      setIsHost,
      setBrowser,
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    // window.tourManager = tourManager.current;
    tourManager.current.initiate();
    return () => {
      tourManager.current.cleanup();
    };
  }, [roomUrl, tourType]);

  return {
    joinCall: tourManager.current.joinCall,
    startCall: tourManager.current.startCall,
    leaveCall:
      tourType === TOUR_TYPES.LIVE
        ? tourManager.current.leaveCall
        : () => {
            setTourState(TourStates.ENDED);
          },
    updateAllHostDimensions: tourManager.current.updateAllHostDimensions,
    muteAudio: tourManager.current.muteAudio,
    muteVideo: tourManager.current.muteVideo,
    reselectTab: tourManager.current.reselectTab,
    tenants,
    brokers,
    host,
    tourState,
    isRecording,
    isHost,
    browser,
  };
}

export const useCreateTour = () => {
  const [res, setRes] = useState<GetStateInterface<GetTourUrlResponseInterface>>(GetState);
  const createTour = useCallback(async (payload: CreateTourInterface) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    fetchActions.callCT(TourActions.TOUR_CREATION);
    try {
      const res = await createTourAPI(payload, fetchActions.setCT(TourActions.TOUR_CREATION));
      setRes({ data: res.data, loading: false, error: null, hasData: true });
    } catch (error) {
      if (!axios.isCancel(error)) {
        setRes({ data: null, loading: false, error: error, hasData: true });
      }
    }
  }, []);

  return { res, createTour };
};
