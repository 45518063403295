import React, { useState, useEffect } from 'react';
import {
  TOUR_SWITCHES as SWITCH,
  TourListingInterface,
} from '../../../../../state/tour/tour.types';
import OptionBeltInfo from './basicInfo';
import OptionBox from './optionBox';
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

interface Props {
  index: number;
  onClick: (listingId: string, switchTab: SWITCH) => void;
  listing: TourListingInterface;
  currentListing: string;
  currentTab: SWITCH;
  loading: boolean;
  shouldOpen: boolean;
}
const OptionsBelt: React.FC<Props> = props => {
  const { shouldOpen, listing: data, onClick, currentListing, currentTab, index, loading } = props;

  const [openPanel, handlePanel] = useState(false);
  const handleClick = (type: SWITCH) => {
    onClick(data._id, type);
  };

  useEffect(() => {
    if (shouldOpen) handlePanel(true);
  }, [shouldOpen]);

  return (
    <Draggable
      key={data._id}
      draggableId={data._id}
      index={index}
      disableInteractiveElementBlocking={true}
      isDragDisabled={loading}
    >
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <div
          className={`tourEdit--listing bg-white ${!openPanel ? 'tourEdit--listing-minimize' : ''}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div
            className="tel--cursor"
            style={{
              cursor: loading ? 'progress' : snapshot.isDragging ? 'move' : 'grab',
            }}
            {...provided.dragHandleProps}
          >
            <i className="icon-cursor"></i>
          </div>
          <div className="tel--inner">
            <OptionBeltInfo data={data} close={!openPanel} />
            <div className="tel--bottom">
              <div className="tel--btns-row">
                {Object.keys(SWITCH).map(key => (
                  <OptionBox
                    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                    // @ts-ignore
                    type={SWITCH[key]}
                    key={key}
                    onClick={handleClick}
                    currentListing={currentListing}
                    currentTab={currentTab}
                    listing={data}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="tel--control" onClick={() => handlePanel(prv => !prv)}>
            <i className="icon-chevron-left"></i>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default OptionsBelt;
