import React, { useEffect, useState } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router';
import Layout from './pages/layout/layout';
import Landing from './pages/landing/landing';
import { createBrowserHistory } from 'history';
import LoginPage from './pages/login/login';
import InvitationPage from './pages/invitation';
import Tour from './pages/subdomain/tour';
import ForgotPassword from './pages/login/forgotPassword';
import ResetPassword from './pages/login/resetPassword';
import NotFound from './pages/404/404';
import Privacy from './pages/privacy/privacy';
import About from './pages/about';
import Terms from './pages/terms/terms';

import { useGetSubDomainStatus } from './state/subdomain/landing/landing.hook';
import { useGetCompanyInfo } from './state/subdomain/company/company.hook';
import Loader from './components/common/loader';
import SdListings from './pages/subdomain/listing/allListing';
import SdListingDetail from './pages/subdomain/listing/singleListing';
import { ALLOWED_SUBDOMAINS_MAIN_APP, NODE_ENV } from './common/constants';
import { compileAvatarUrl } from './utils/avatar.utils';
import env from './utils/env.utils';
import { compileGoogleMapsScriptSrc, injectScriptIntoHead } from './utils/misc.utils';
import FullStory from 'react-fullstory';
import Intercom from 'react-intercom';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const authenticated = true;
  return (
    <Route
      {...rest}
      render={props => (authenticated ? <Component {...props} /> : <Redirect to="/login" />)}
    />
  );
};

const App: React.FC = () => {
  const [subdomain] = useState(window.location.hostname.split('.')[0]);
  const { res: getSubDomainStatusRes, getSubDomainStatus } = useGetSubDomainStatus();
  const { res: getCompanyInfoResponse, getCompanyInfo } = useGetCompanyInfo();

  useEffect(() => {
    if (env.nodeEnv === NODE_ENV.PRODUCTION) {
      ReactGA.initialize(env.googleAnalyticsTrackingId);
      TagManager.initialize({
        gtmId: env.googleTagManagerId,
      });
    }
    injectScriptIntoHead(
      compileGoogleMapsScriptSrc(env.googleApiKey),
      true,
      undefined,
      'text/javascript',
    );
  }, []);

  useEffect(() => {
    if (!ALLOWED_SUBDOMAINS_MAIN_APP.includes(subdomain)) {
      getSubDomainStatus(subdomain);
    }
  }, [subdomain]);

  useEffect(() => {
    if (!getCompanyInfoResponse.loading && getCompanyInfoResponse.error) {
      window.location.href = 'https://www.hometour.com';
    }
    if (!getCompanyInfoResponse.loading && getCompanyInfoResponse.data) {
      if (getCompanyInfoResponse.data.name) {
        document.title = getCompanyInfoResponse.data.name;
      }
      if (getCompanyInfoResponse.data.settings?.darkIcon) {
        const link: any =
          document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.rel = 'shortcut icon';
        link.href = compileAvatarUrl(getCompanyInfoResponse.data.settings.darkIcon.url);
        document.getElementsByTagName('head')[0].appendChild(link);
      }
    }
  }, [getCompanyInfoResponse]);

  useEffect(() => {
    if (!getSubDomainStatusRes.loading && getSubDomainStatusRes.data) {
      getCompanyInfo(subdomain);
    }
    if (
      !getSubDomainStatusRes.loading &&
      !getSubDomainStatusRes.data &&
      getSubDomainStatusRes.hasData
    ) {
      if (!ALLOWED_SUBDOMAINS_MAIN_APP.includes(subdomain)) {
        window.location.href = 'https://www.hometour.com';
      }
    }
  }, [getSubDomainStatusRes]);

  return (
    <React.Fragment>
      <React.Suspense fallback={<Loader />}>
        {(getSubDomainStatusRes.loading || getCompanyInfoResponse.loading) && <Loader />}
        {!getSubDomainStatusRes.data && ALLOWED_SUBDOMAINS_MAIN_APP.includes(subdomain) && (
          <div id="main-app">
            <Router history={createBrowserHistory()}>
              <React.Suspense fallback={<Loader />}>
                <Switch>
                  <Route exact path="/" component={Landing} />
                  <Route exact path="/login" component={LoginPage} />
                  <Route exact path="/forgot" component={ForgotPassword} />
                  <Route exact path="/invite/:memberId/:token" component={InvitationPage} />
                  <Route exact path="/reset/:memberId/:token" component={ResetPassword} />
                  <Route exact path="/privacy" component={Privacy} />
                  <Route exact path="/terms" component={Terms} />
                  <Route exact path="/404" component={NotFound} />
                  <Route exact path="/about" component={About} />
                  <PrivateRoute component={Layout} />
                  <Redirect to="/404" />
                </Switch>
              </React.Suspense>
            </Router>
          </div>
        )}
        {getSubDomainStatusRes.data && getCompanyInfoResponse.data && (
          <div id="subdomain-app">
            <Router history={createBrowserHistory()}>
              <React.Suspense fallback={<Loader />}>
                <Switch>
                  <Route exact path="/" component={SdListings} />
                  <Route path="/listing/:listingId" component={SdListingDetail} />
                  <Route exact path="/tour/:tourId" component={Tour} />
                  <Route exact path="/404" component={NotFound} />
                  <Redirect to="/404" />
                </Switch>
              </React.Suspense>
            </Router>
          </div>
        )}
        {env.nodeEnv === NODE_ENV.PRODUCTION && <FullStory org={env.fullStoryOrgId} />}
        {env.nodeEnv === NODE_ENV.PRODUCTION && <Intercom appID={env.intercomAppId} />}
      </React.Suspense>
    </React.Fragment>
  );
};

export default App;
