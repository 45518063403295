import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import HamBurger from '../leftSidebar/hamburger';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../state';
import UpcomingTours from './tours';
import RecentListings from './listings';
import FileDownload from 'js-file-download';
import { useExportListings } from '../../state/listing/listing.hook';
import { showNotification } from '../../utils/misc.utils';
import { errorExtractor } from '../../utils/error.utils';
import SmallLoader from '../common/small.loader';

export const Dashboard: React.FC = () => {
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const meLoading = useSelector((state: AppStateInterface) => state.me.me.loading);
  const { res: exportListingsResponse, exportListings } = useExportListings();
  const { error, data, loading } = exportListingsResponse;

  useEffect(() => {
    if (!loading && error) {
      showNotification('error', 'Error', errorExtractor(error));
    }
    if (!loading && data) {
      FileDownload(data, 'data.csv');
    }
  }, [exportListingsResponse]);

  return (
    <div className="dashboard--content dashboard--content-new" id="DashboardParentScrollContainer">
      <div className="dashboard--off">
        <div className="dashboard--canvas">
          <div className="step--content step--content-1">
            <div className="dashboard--opt">
              <HamBurger />
            </div>
            {meLoading ? (
              <div className="white--rounded-box dashboard--bar dh--shimmer mb-20">
                <div className="w-100 mb-2 pb-1">
                  <span className="dhShimmer__heading animate mb-2 mb-md-3"></span>
                </div>
                <span className="dhShimmer__para animate mb-2 mb-xl-4"></span>
                <div className="row row--space-10 dashboard__btn-row">
                  <div className="col col-12 col-sm-6 col-xl">
                    <div className="db--shimmer">
                      <span className="dbShimmer__text animate--gray"></span>
                      <span className="dbShimmer__icon animate--gray"></span>
                    </div>
                  </div>
                  <div className="col col-12 col-sm-6 col-xl">
                    <div className="db--shimmer">
                      <span className="dbShimmer__text animate--gray"></span>
                      <span className="dbShimmer__icon animate--gray"></span>
                    </div>
                  </div>
                  <div className="col col-12 col-sm-6 col-xl">
                    <div className="db--shimmer">
                      <span className="dbShimmer__text animate--gray"></span>
                      <span className="dbShimmer__icon animate--gray"></span>
                    </div>
                  </div>
                  <div className="col col-12 col-sm-6 col-xl">
                    <div className="db--shimmer">
                      <span className="dbShimmer__text animate--gray"></span>
                      <span className="dbShimmer__icon animate--gray"></span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="white--rounded-box dashboard--bar mb-20">
                <h3 className="dashboard__heading font-semibold mb-2 mb-md-3">
                  Hi {me?.firstName}!
                </h3>
                <p className="para__lg mb-2 mb-xl-4">
                  Here you will find key information and quick access to main actions. What would
                  you like to do next?
                </p>
                <div className="row row--space-10 dashboard__btn-row">
                  <div className="col col-12 col-sm-6 col-xl">
                    <Link className="dashboard__btn w-100" to="/tour/create">
                      Create a Tour
                      <span className="dashboard__btn-icon">
                        <i className="icon-tour"></i>
                      </span>
                    </Link>
                  </div>
                  <div className="col col-12 col-sm-6 col-xl">
                    <Link className="dashboard__btn w-100" to="/building/create">
                      Add a Listing
                      <span className="dashboard__btn-icon">
                        <i className="icon-floorplan"></i>
                      </span>
                    </Link>
                  </div>
                  <div className="col col-12 col-sm-6 col-xl">
                    <Button
                      className="dashboard__btn w-100"
                      disabled={loading}
                      onClick={exportListings}
                    >
                      {loading ? (
                        <React.Fragment>
                          Exporting <SmallLoader />
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          Export Listings
                          <span className="dashboard__btn-icon">
                            <i className="icon-file"></i>
                          </span>
                        </React.Fragment>
                      )}
                    </Button>
                  </div>
                  {me?.company?._id === '5ebe9b1490d0b62f1fc8483b' && (
                    <div className="col col-12 col-sm-6 col-xl">
                      <a
                        className="dashboard__btn w-100"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.notion.so/spacetrics/JBG-SMITH-Pilot-Status-b685891ba4d94cc09dca70522317e9eb"
                      >
                        Pilot Status
                        <span className="dashboard__btn-icon">
                          <i className="icon-tv-check"></i>
                        </span>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )}
            <UpcomingTours />
            <RecentListings />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
