import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { getTourLink, showNotification } from '../../../utils/misc.utils';
import { useParams, useHistory } from 'react-router';
import copy from 'copy-to-clipboard';
import { isEmpty } from 'lodash';

const TourInfo: React.FC = () => {
  const tourInfo = useSelector((state: AppStateInterface) => state.tour.tourInfo.data);
  const listing = useSelector((state: AppStateInterface) => state.tour.tourListing);
  const me = useSelector((state: AppStateInterface) => state.me.me.data);
  const { tourId } = useParams();
  const history = useHistory();

  const [tourLink, setTourLink] = useState('');
  const [tourLinkMsg, setTourLinkMsg] = useState('');
  const [tourName, setTourName] = useState('');
  useEffect(() => {
    if (me?.company?.domain && tourInfo) {
      const { link, expiry, format } = getTourLink(
        tourId,
        tourInfo.expiredAt,
        me.company.domain,
        tourInfo?.tourUrl,
      );
      setTourLinkMsg(`This link will expire in ${expiry} ${format}`);
      setTourLink(link);
    }
    if (tourInfo && tourInfo?.clientInfo?.company) setTourName(tourInfo.clientInfo.company);
  }, [me, tourInfo, tourId]);

  const copyToClipboard = () => {
    copy(tourLink);
    showNotification('success', 'Link copied to the clipboard', tourLinkMsg);
  };
  const startTour = () => {
    window.open(tourLink, '_black');
  };

  return (
    <React.Fragment>
      <div className="white--rounded-box dashboard--bar mb-20">
        <div className="subDashboard--bar">
          <div className="sd--main d-flex flex-column">
            <Breadcrumb className="breadcrumb--custom">
              <Breadcrumb.Item href="#" onClick={() => history.push('/tours/active')}>
                Tours
              </Breadcrumb.Item>
              {tourName && <Breadcrumb.Item active>{tourName}</Breadcrumb.Item>}
            </Breadcrumb>
            {tourName && (
              <div className="d-flex align-items-center flex-wrap">
                <h3 className="heading__medium heading__medium-dark mb-1 mb-sm-0 font-semibold mr-sm-3">
                  {tourName}
                </h3>
                {/*<a className="preview__link d-inline-flex align-items-center">
                Preview <i className="icon-eye-alt ml-10"></i>
              </a>*/}
              </div>
            )}
          </div>
          <div className="subDashboard--top-btns btns-mobile-expand d-flex flex-column flex-sm-row align-items-center justify-content-between mt-3 mt-xl-0 ml-xl-2">
            <Button
              type="button"
              className="outline__btn-gray outline__btn-gray-md outline__btn-radius"
              onClick={copyToClipboard}
              disabled={!listing || isEmpty(listing)}
            >
              Copy Tour Link <i className="icon-link ml-10"></i>
            </Button>
            <Button
              type="button"
              className="admin__button admin__button-sm admin__button-radius ml-sm-20 mt-3 mt-sm-0"
              onClick={startTour}
              disabled={!listing || isEmpty(listing)}
            >
              Start Tour <i className="icon-rocket ml-10"></i>
            </Button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TourInfo;
