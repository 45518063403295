import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useUploadMedia } from '../../state/media/media.hook';
import { compileAvatarUrl } from '../../utils/avatar.utils';
import SmallLoader from '../common/small.loader';
import { SettingsDataInterface, SettingsErrorInterface } from '../../state/setting/setting.types';
import useForm from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  CompanyInfoFormFields,
  CompanyInfoFormSchema,
} from '../../state/setting/forms/companyInfo.form';
import { errorExtractor } from '../../utils/error.utils';
import {
  ALLOWED_EMAIL_LOGO_IMAGE_MIME_TYPES,
  ALLOWED_EMAIL_LOGO_IMAGE_TYPES,
  ALLOWED_LOGO_IMAGE_MIME_TYPES,
  ALLOWED_LOGO_IMAGE_TYPES,
  ALLOWED_LOGO_MAX_SIZE_IN_MB,
} from '../../common/constants';
import { useGetSubDomainStatus } from '../../state/subdomain/landing/landing.hook';

interface Props {
  data: SettingsDataInterface;
  setData: (newData: SettingsDataInterface) => void;
  setSettingsData: (field: string, value: any) => void;
  errors: SettingsErrorInterface;
  setErrors: (newErrors: SettingsErrorInterface) => void;
  setSettingsError: (field: string, value: string) => void;
}

const CompanyInfo: React.FC<Props> = (props: Props) => {
  const { register, errors, setValue } = useForm({
    validationSchema: CompanyInfoFormSchema,
    mode: 'onChange',
  });
  const { res: whiteIconUploadRes, uploadSingleMedia: uploadWhiteIcon } = useUploadMedia();
  const { res: darkIconUploadRes, uploadSingleMedia: uploadDarkIcon } = useUploadMedia();
  const { res: whiteLogoUploadRes, uploadSingleMedia: uploadWhiteLogo } = useUploadMedia();
  const { res: darkLogoUploadRes, uploadSingleMedia: uploadDarkLogo } = useUploadMedia();
  const { res: emailLogoUploadRes, uploadSingleMedia: uploadEmailLogo } = useUploadMedia();
  const { res: subDomainAvailabilityRes, getSubDomainAvailability } = useGetSubDomainStatus();

  useEffect(() => {
    if (
      !subDomainAvailabilityRes.loading &&
      subDomainAvailabilityRes.hasData &&
      !subDomainAvailabilityRes.data
    ) {
      props.setSettingsError('domain', 'Not available. Please choose another');
    }
  }, [subDomainAvailabilityRes]);

  useEffect(() => {
    if (!whiteIconUploadRes.loading && whiteIconUploadRes.data) {
      props.setSettingsData('whiteIcon', whiteIconUploadRes.data);
      props.setSettingsError('whiteIcon', '');
    }
    if (!whiteIconUploadRes.loading && whiteIconUploadRes.error) {
      props.setSettingsError('whiteIcon', errorExtractor(whiteIconUploadRes.error));
    }
  }, [whiteIconUploadRes]);

  useEffect(() => {
    if (!darkIconUploadRes.loading && darkIconUploadRes.data) {
      props.setSettingsData('darkIcon', darkIconUploadRes.data);
      props.setSettingsError('darkIcon', '');
    }
    if (!darkIconUploadRes.loading && darkIconUploadRes.error) {
      props.setSettingsError('darkIcon', errorExtractor(darkIconUploadRes.error));
    }
  }, [darkIconUploadRes]);

  useEffect(() => {
    if (!whiteLogoUploadRes.loading && whiteLogoUploadRes.data) {
      props.setSettingsData('whiteLogo', whiteLogoUploadRes.data);
      props.setSettingsError('whiteLogo', '');
    }
    if (!whiteLogoUploadRes.loading && whiteLogoUploadRes.error) {
      props.setSettingsError('whiteLogo', errorExtractor(whiteLogoUploadRes.error));
    }
  }, [whiteLogoUploadRes]);

  useEffect(() => {
    if (!darkLogoUploadRes.loading && darkLogoUploadRes.data) {
      props.setSettingsData('darkLogo', darkLogoUploadRes.data);
      props.setSettingsError('darkLogo', '');
    }
    if (!darkLogoUploadRes.loading && darkLogoUploadRes.error) {
      props.setSettingsError('darkLogo', errorExtractor(darkLogoUploadRes.error));
    }
  }, [darkLogoUploadRes]);

  useEffect(() => {
    if (!emailLogoUploadRes.loading && emailLogoUploadRes.data) {
      props.setSettingsData('emailLogo', emailLogoUploadRes.data);
      props.setSettingsError('emailLogo', '');
    }
    if (!emailLogoUploadRes.loading && emailLogoUploadRes.error) {
      props.setSettingsError('emailLogo', errorExtractor(emailLogoUploadRes.error));
    }
  }, [emailLogoUploadRes]);

  useEffect(() => {
    setValue(CompanyInfoFormFields.NAME, props.data?.name, true);
    setValue(CompanyInfoFormFields.DOMAIN, props.data?.domain, true);
    setValue(CompanyInfoFormFields.OFFICIAL_ADDRESS, props.data?.officialAddress, true);
    setValue(CompanyInfoFormFields.OFFICIAL_PHONE, props.data?.officialPhone, true);
    setValue(CompanyInfoFormFields.OFFICIAL_WEBSITE, props.data?.officialWebsite, true);
    setValue(CompanyInfoFormFields.CONTACT_EMAIL, props.data?.contactEmail, true);
  }, [props.data]);

  useEffect(() => {
    props.setSettingsError('name', errors?.name?.message || '');
    props.setSettingsError('domain', errors?.domain?.message || '');
    props.setSettingsError('officialAddress', errors?.officialAddress?.message || '');
    props.setSettingsError('officialPhone', errors?.officialPhone?.message || '');
    props.setSettingsError('officialWebsite', errors?.officialWebsite?.message || '');
    props.setSettingsError('contactEmail', errors?.contactEmail?.message || '');
  }, [errors]);

  const onSelectFile = (e: any, field: string) => {
    const files = e.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (field === 'emailLogo' && !ALLOWED_EMAIL_LOGO_IMAGE_MIME_TYPES.includes(file.type)) {
        props.setSettingsError(field, `Allowed Image Types: ${ALLOWED_EMAIL_LOGO_IMAGE_TYPES}`);
        return;
      } else if (!ALLOWED_LOGO_IMAGE_MIME_TYPES.includes(file.type)) {
        props.setSettingsError(field, `Allowed Image Types: ${ALLOWED_LOGO_IMAGE_TYPES}`);
        return;
      }
      const fileSizeInMb = file.size / 1000000;
      if (fileSizeInMb > ALLOWED_LOGO_MAX_SIZE_IN_MB) {
        props.setSettingsError(field, `Max size allowed: ${ALLOWED_LOGO_MAX_SIZE_IN_MB} MB`);
        return;
      }
      switch (field) {
        case 'whiteIcon':
          uploadWhiteIcon({ name: file.name, data: file });
          return;

        case 'darkIcon':
          uploadDarkIcon({ name: file.name, data: file });
          return;

        case 'whiteLogo':
          uploadWhiteLogo({ name: file.name, data: file });
          return;

        case 'darkLogo':
          uploadDarkLogo({ name: file.name, data: file });
          return;

        case 'emailLogo':
          uploadEmailLogo({ name: file.name, data: file });
          return;

        default:
          return;
      }
    }
  };

  const removeLogo = (field: string) => {
    props.setSettingsError(field, 'This field is required');
    props.setSettingsData(field, null);
  };

  const onDomainChange = (e: any) => {
    const value = e.target.value;
    props.setSettingsData('domain', value);
    if (value) {
      getSubDomainAvailability(value);
    }
  };

  return (
    <div className="adminSettings--row">
      <div className="adminSettings--head">
        <span className="adminSettings__icon">
          <i className="icon-building"></i>
        </span>
        <span className="adminSettings__text">Company Information</span>
      </div>
      <div className="row row--space-12">
        <div className="col-12 col-sm-6">
          <Form.Group className="forms--group">
            <Form.Label>Company Name*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Company Name*"
              ref={register}
              name={CompanyInfoFormFields.NAME}
              className={props.errors?.name ? 'error' : ''}
              onChange={e => props.setSettingsData('name', e.target.value)}
            />
            {props.errors?.name && <span className="error__msg">{props.errors?.name}</span>}
          </Form.Group>
        </div>
        <div className="col-12 col-sm-6">
          <Form.Group className="forms--group">
            <Form.Label>Company Site*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Company Site*"
              ref={register}
              name={CompanyInfoFormFields.OFFICIAL_WEBSITE}
              className={props.errors?.officialWebsite ? 'error' : ''}
              onChange={e => props.setSettingsData('officialWebsite', e.target.value)}
            />
            {props.errors?.officialWebsite && (
              <span className="error__msg">{props.errors?.officialWebsite}</span>
            )}
          </Form.Group>
        </div>
        <div className="col-12 col-sm-6">
          <Form.Group className="forms--group">
            <Form.Label>Company Address*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Company Address*"
              ref={register}
              name={CompanyInfoFormFields.OFFICIAL_ADDRESS}
              className={props.errors?.officialAddress ? 'error' : ''}
              onChange={e => props.setSettingsData('officialAddress', e.target.value)}
            />
            {props.errors?.officialAddress && (
              <span className="error__msg">{props.errors?.officialAddress}</span>
            )}
          </Form.Group>
        </div>
        <div className="col-12 col-sm-6">
          <Form.Group className="forms--group">
            <Form.Label>Company Phone*</Form.Label>
            <InputMask
              type="text"
              placeholder="Company Phone*"
              inputRef={register}
              name={CompanyInfoFormFields.OFFICIAL_PHONE}
              value={props.data.officialPhone}
              className={props.errors?.officialPhone ? 'form-control error' : 'form-control'}
              onChange={(e: any) => props.setSettingsData('officialPhone', e.target.value)}
              mask="(999) 999-9999"
              maskChar="_"
            />
            {props.errors?.officialPhone && (
              <span className="error__msg">{props.errors?.officialPhone}</span>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row row--space-12">
        <div className="col-12 col-sm-6">
          <Form.Group className="forms--group">
            <Form.Label>Company Subdomain*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Company Subdomain*"
              ref={register}
              name={CompanyInfoFormFields.DOMAIN}
              className={props.errors?.domain ? 'error' : ''}
              onChange={onDomainChange}
            />
            {subDomainAvailabilityRes.loading && <SmallLoader />}
            {props.errors?.domain && <span className="error__msg">{props.errors?.domain}</span>}
          </Form.Group>
        </div>
        <div className="col-12 col-sm-6">
          <Form.Group className="forms--group">
            <Form.Label>Company Email*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Company Email*"
              ref={register}
              name={CompanyInfoFormFields.CONTACT_EMAIL}
              className={props.errors?.contactEmail ? 'error' : ''}
              onChange={e => props.setSettingsData('contactEmail', e.target.value)}
            />
            {props.errors?.contactEmail && (
              <span className="error__msg">{props.errors?.contactEmail}</span>
            )}
          </Form.Group>
        </div>
      </div>
      <div className="row row--space-12">
        {props.data?.whiteIcon && (
          <div className="col-12 col-sm-6 forms--group form-group">
            <Form.Label>Company Icon (White)*</Form.Label>
            <div className="adminSettings--upload pr-4">
              <label className="adminSettings--upload-item">
                <div className="uploaded--content">
                  <span className="uploadedContent__img">
                    <img src={compileAvatarUrl(props.data.whiteIcon.url)} alt="White Icon" />
                  </span>
                  <span className="uploadedContent__name">{props.data.whiteIcon.name}</span>
                </div>
              </label>
              <a className="adminSettings--upload-del" onClick={() => removeLogo('whiteIcon')}>
                <i className="icon-trash"></i>
              </a>
            </div>
          </div>
        )}
        {!props.data?.whiteIcon && (
          <div className="col-12 col-sm-6 adminSettings--upload--logo forms--group form-group">
            <Form.Label>Company Icon (White)*</Form.Label>
            <div className="adminSettings--upload">
              <label className="adminSettings--upload-item default--upload-item">
                <input
                  type="file"
                  onChange={(e: any) => onSelectFile(e, 'whiteIcon')}
                  disabled={whiteIconUploadRes.loading}
                  accept={ALLOWED_LOGO_IMAGE_MIME_TYPES.toString()}
                />
                <span className="de__text">
                  {whiteIconUploadRes.loading ? 'Uploading' : 'Upload White Icon'}
                </span>
                {whiteIconUploadRes.loading && <SmallLoader />}
              </label>
            </div>
            {props.errors?.whiteIcon && (
              <span className="error__msg">{props.errors?.whiteIcon}</span>
            )}
          </div>
        )}
        {props.data?.darkIcon && (
          <div className="col-12 col-sm-6 forms--group form-group">
            <Form.Label>Company Icon (Dark)*</Form.Label>
            <div className="adminSettings--upload pr-4">
              <label className="adminSettings--upload-item">
                <div className="uploaded--content">
                  <span className="uploadedContent__img">
                    <img src={compileAvatarUrl(props.data.darkIcon.url)} alt="Dark Icon" />
                  </span>
                  <span className="uploadedContent__name">{props.data.darkIcon.name}</span>
                </div>
              </label>
              <a className="adminSettings--upload-del" onClick={() => removeLogo('darkIcon')}>
                <i className="icon-trash"></i>
              </a>
            </div>
          </div>
        )}
        {!props.data?.darkIcon && (
          <div className="col-12 col-sm-6 adminSettings--upload--logo forms--group form-group">
            <Form.Label>Company Icon (Dark)*</Form.Label>
            <div className="adminSettings--upload">
              <label className="adminSettings--upload-item default--upload-item">
                <input
                  type="file"
                  onChange={(e: any) => onSelectFile(e, 'darkIcon')}
                  disabled={darkIconUploadRes.loading}
                  accept={ALLOWED_LOGO_IMAGE_MIME_TYPES.toString()}
                />
                <span className="de__text">
                  {darkIconUploadRes.loading ? 'Uploading' : 'Upload Dark Icon'}
                </span>
                {darkIconUploadRes.loading && <SmallLoader />}
              </label>
            </div>
            {props.errors?.darkIcon && <span className="error__msg">{props.errors?.darkIcon}</span>}
          </div>
        )}
      </div>
      <div className="row row--space-12">
        {props.data?.whiteLogo && (
          <div className="col-12 col-sm-6 forms--group form-group">
            <Form.Label>Company Logo (White)*</Form.Label>
            <div className="adminSettings--upload pr-4">
              <label className="adminSettings--upload-item">
                <div className="uploaded--content">
                  <span className="uploadedContent__img">
                    <img src={compileAvatarUrl(props.data.whiteLogo.url)} alt="Logo" />
                  </span>
                  <span className="uploadedContent__name">{props.data.whiteLogo.name}</span>
                </div>
              </label>
              <a className="adminSettings--upload-del" onClick={() => removeLogo('whiteLogo')}>
                <i className="icon-trash"></i>
              </a>
            </div>
          </div>
        )}
        {!props.data?.whiteLogo && (
          <div className="col-12 col-sm-6 adminSettings--upload--logo forms--group form-group">
            <Form.Label>Company Logo (White)*</Form.Label>
            <div className="adminSettings--upload">
              <label className="adminSettings--upload-item default--upload-item">
                <input
                  type="file"
                  onChange={(e: any) => onSelectFile(e, 'whiteLogo')}
                  disabled={whiteLogoUploadRes.loading}
                  accept={ALLOWED_LOGO_IMAGE_MIME_TYPES.toString()}
                />
                <span className="de__text">
                  {whiteLogoUploadRes.loading ? 'Uploading' : 'Upload White Logo'}
                </span>
                {whiteLogoUploadRes.loading && <SmallLoader />}
              </label>
            </div>
            {props.errors?.whiteLogo && (
              <span className="error__msg">{props.errors?.whiteLogo}</span>
            )}
          </div>
        )}
        {props.data?.darkLogo && (
          <div className="col-12 col-sm-6 forms--group form-group">
            <Form.Label>Company Logo (Dark)*</Form.Label>
            <div className="adminSettings--upload pr-4">
              <label className="adminSettings--upload-item">
                <div className="uploaded--content">
                  <span className="uploadedContent__img">
                    <img src={compileAvatarUrl(props.data.darkLogo.url)} alt="Logo" />
                  </span>
                  <span className="uploadedContent__name">{props.data.darkLogo.name}</span>
                </div>
              </label>
              <a className="adminSettings--upload-del" onClick={() => removeLogo('darkLogo')}>
                <i className="icon-trash"></i>
              </a>
            </div>
          </div>
        )}
        {!props.data?.darkLogo && (
          <div className="col-12 col-sm-6 adminSettings--upload--logo forms--group form-group">
            <Form.Label>Company Logo (Dark)*</Form.Label>
            <div className="adminSettings--upload">
              <label className="adminSettings--upload-item default--upload-item">
                <input
                  type="file"
                  onChange={(e: any) => onSelectFile(e, 'darkLogo')}
                  disabled={darkLogoUploadRes.loading}
                  accept={ALLOWED_LOGO_IMAGE_MIME_TYPES.toString()}
                />
                <span className="de__text">
                  {darkLogoUploadRes.loading ? 'Uploading' : 'Upload Dark Logo'}
                </span>
                {darkLogoUploadRes.loading && <SmallLoader />}
              </label>
            </div>
            {props.errors?.darkLogo && <span className="error__msg">{props.errors?.darkLogo}</span>}
          </div>
        )}
      </div>
      <div className="row row--space-12">
        {props.data?.emailLogo && (
          <div className="col-12 col-sm-6 forms--group form-group">
            <Form.Label>Company Logo (Email)*</Form.Label>
            <div className="adminSettings--upload pr-4">
              <label className="adminSettings--upload-item">
                <div className="uploaded--content">
                  <span className="uploadedContent__img">
                    <img src={compileAvatarUrl(props.data.emailLogo.url)} alt="Logo" />
                  </span>
                  <span className="uploadedContent__name">{props.data.emailLogo.name}</span>
                </div>
              </label>
              <a className="adminSettings--upload-del" onClick={() => removeLogo('emailLogo')}>
                <i className="icon-trash"></i>
              </a>
            </div>
          </div>
        )}
        {!props.data?.emailLogo && (
          <div className="col-12 col-sm-6 adminSettings--upload--logo forms--group form-group">
            <Form.Label>Company Logo (Email)*</Form.Label>
            <div className="adminSettings--upload">
              <label className="adminSettings--upload-item default--upload-item">
                <input
                  type="file"
                  onChange={(e: any) => onSelectFile(e, 'emailLogo')}
                  disabled={emailLogoUploadRes.loading}
                  accept={ALLOWED_EMAIL_LOGO_IMAGE_MIME_TYPES.toString()}
                />
                <span className="de__text">
                  {emailLogoUploadRes.loading ? 'Uploading' : 'Upload Logo'}
                </span>
                {emailLogoUploadRes.loading && <SmallLoader />}
              </label>
            </div>
            {props.errors?.emailLogo && (
              <span className="error__msg">{props.errors?.emailLogo}</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyInfo;
