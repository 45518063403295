import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { UploadedMediaInterface } from '../../../../state/media/media.types';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';

const HandleTourHome: React.FC = () => {
  const listing = useSelector((state: AppStateInterface) => state.tour.tourListing);

  const [primaryImage, setPrimary] = useState({} as UploadedMediaInterface);
  useEffect(() => {
    if (listing) {
      const listingData = listing.listing;
      let primary = listingData.media.find(image => image.isPrimary);
      if (!primary) primary = listingData.media[0];
      setPrimary(primary);
    }
  }, [listing]);

  const recalculateFontSize = (parentBlockId: string, textId: string, unitId?: string) => {
    const blockEl = document.getElementById(parentBlockId);
    if (blockEl) {
      const leftPadding = parseFloat(
        window.getComputedStyle(blockEl).getPropertyValue('padding-left'),
      );
      const rightPadding = parseFloat(
        window.getComputedStyle(blockEl).getPropertyValue('padding-right'),
      );
      const textEl = document.getElementById(textId);
      if (textEl) {
        const textFontSize = parseFloat(
          window.getComputedStyle(textEl).getPropertyValue('font-size'),
        );
        const unitEl = document.getElementById(unitId ? unitId : 'none');
        if (unitEl) {
          const unitFontSize = parseFloat(
            window.getComputedStyle(unitEl).getPropertyValue('font-size'),
          );
          let textWidth = leftPadding + textEl.scrollWidth + rightPadding;
          if (window.innerWidth > 1440 && window.innerHeight > 700 && unitEl.offsetWidth) {
            textWidth += unitEl?.offsetWidth;
          }
          if (textWidth > blockEl.clientWidth) {
            textEl.style.fontSize = `${textFontSize - 1}px`;
            unitEl.style.fontSize = `${unitFontSize - 0.5}px`;
            recalculateFontSize(parentBlockId, textId, unitId);
          }
        } else if (leftPadding + textEl.scrollWidth + rightPadding > blockEl.clientWidth) {
          textEl.style.fontSize = `${textFontSize - 1}px`;
          recalculateFontSize(parentBlockId, textId);
        }
      }
    }
  };

  const recalculateDescriptionFontSize = () => {
    const capacityBlockEl = document.getElementById('capacityBlock');
    const blockEl = document.getElementById('descriptionBlock');
    if (blockEl && capacityBlockEl) {
      const textEl = document.getElementById('description');
      if (textEl) {
        const textFontSize = parseFloat(
          window.getComputedStyle(textEl).getPropertyValue('font-size'),
        );
        if (blockEl.scrollHeight > capacityBlockEl.scrollHeight) {
          textEl.style.fontSize = `${textFontSize - 1}px`;
          recalculateDescriptionFontSize();
        }
      }
    }
  };

  useLayoutEffect(() => {
    recalculateFontSize('buildingSizeBlock', 'buildingSize', 'buildingSizeUnit');
    recalculateFontSize('rentBlock', 'rent', 'rentUnit');
    recalculateFontSize('capacityBlock', 'capacity');
    recalculateDescriptionFontSize();
  }, [listing]);

  return (
    <div className="tourEdit--top tourEdit--top-details">
      <img
        src={compileAvatarUrl(primaryImage.url)}
        alt={primaryImage.name}
        className="img--cover"
      />{' '}
      <div className="liveTour--placement liveTour--details placement--centered">
        <div className="container liveTour--container">
          <div className="row--feature">
            {listing?.listing?.building?.buildingSize && (
              <div className="col--feature">
                <div className="feature--block feature--block-alt" id="buildingSizeBlock">
                  <span className="featureBlock__icon">
                    <i className="icon-compress-wide"></i>
                  </span>
                  <h3 className="featureBlock__heading text__gray">Size</h3>
                  <div className="featureBlock--sf">
                    <span className="sf__digit" id="buildingSize">
                      {listing.listing.building.buildingSize.toLocaleString()}
                    </span>
                    <span className="sf__word" id="buildingSizeUnit">
                      SF
                    </span>
                  </div>
                </div>
              </div>
            )}
            {listing?.listing.monthlyRent && (
              <div className="col--feature">
                <div className="feature--block feature--block-alt" id="rentBlock">
                  <span className="featureBlock__icon">
                    <i className="icon-month"></i>
                  </span>
                  <h3 className="featureBlock__heading text__gray">Rent</h3>
                  <div className="featureBlock--sf">
                    <span className="sf__digit" id="rent">
                      ${listing.listing.monthlyRent.toLocaleString()}
                    </span>
                    <span className="sf__word" id="rentUnit">
                      /SF/YR
                    </span>
                  </div>
                </div>
              </div>
            )}
            {listing?.listing.capacity && (
              <div className="col--feature">
                <div className="feature--block feature--block-alt" id="capacityBlock">
                  <span className="featureBlock__icon">
                    <i className="icon-users"></i>
                  </span>
                  <h3 className="featureBlock__heading text__gray">Seats</h3>
                  <div className="featureBlock--sf">
                    <span className="sf__digit" id="capacity">
                      {listing?.listing.capacity}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {listing?.listing.metroProximity && (
              <div className="col--feature col--feature-address">
                <div
                  className="feature--block feature--block-alt feature--block-address"
                  id="descriptionBlock"
                >
                  <span className="featureBlock__icon">
                    <i className="icon-metro"></i>
                  </span>
                  <h3 className="featureBlock__heading text__gray">Metro Proximity</h3>
                  <h4 className="featureBlock__heading text__dark" id="description">
                    {listing.listing.metroProximity}
                  </h4>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HandleTourHome;
