import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import useForm from 'react-hook-form';
import { AboutFormSchema, About, errorMsg } from './about.component';
import { useContactUs } from '../../state/about/about.hook';
import SmallLoader from '../common/small.loader';
import { showNotification } from '../../utils/misc.utils';
import { errorExtractor } from '../../utils/error.utils';

const AboutForm: React.FC = () => {
  const { res, contact } = useContactUs();
  const { register, handleSubmit, errors, reset } = useForm({
    validationSchema: AboutFormSchema,
  });

  useEffect(() => {
    if (!res.loading && res.hasData) {
      if (res.error) showNotification('error', 'Form Error', errorExtractor(res.error));
      else if (res.data) {
        showNotification('success', 'Success', 'Your message has been sent');
        reset();
      }
    }
  }, [res]);

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    contact(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="row row--space-10">
        <div className="col-12 col-md-6">
          <Form.Group className="forms--group forms--group-radius">
            <Form.Control
              type="text"
              placeholder="First name"
              name={About.firstName}
              ref={register}
            />
            {errorMsg(errors[About.firstName])}
          </Form.Group>
        </div>
        <div className="col-12 col-md-6">
          <Form.Group className="forms--group forms--group-radius">
            <Form.Control
              type="text"
              placeholder="Last name"
              name={About.lastName}
              ref={register}
            />
            {errorMsg(errors[About.lastName])}
          </Form.Group>
        </div>
        <div className="col-12">
          <Form.Group className="forms--group forms--group-radius">
            <Form.Control type="email" placeholder="Email" name={About.email} ref={register} />
            {errorMsg(errors[About.email])}
          </Form.Group>
        </div>
        <div className="col-12">
          <Form.Group className="forms--group textarea--group forms--group-radius">
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="How can we help?"
              name={About.message}
              ref={register}
            />
            {errorMsg(errors[About.message])}
          </Form.Group>
        </div>
        <div className="col-12">
          <button type="submit" className="schedule__btn schedule__btn-n w-100 button__radius">
            Submit
            {res.loading ? (
              <React.Fragment>
                ting <SmallLoader />
              </React.Fragment>
            ) : (
              ''
            )}
          </button>
        </div>
      </div>
    </Form>
  );
};

export default AboutForm;
