import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../../state';
import { AudioVoiceOverInterface } from '../../../../state/listing/listing.types';
import { usePauseRecording } from '../../../../state/review/review.hook';
import SmallLoader from '../../../common/small.loader';
import { convertSecToString } from '../../listing.component';
import ReactAudioPlayer from 'react-audio-player';
import { compileAvatarUrl } from '../../../../utils/avatar.utils';
import { AUDIO_TIME_INVERVAL } from '../../../../common/constants';

enum AudioStates {
  PLAY = 'play',
  LOADING = 'loading',
  PAUSE = 'pause',
}
interface Props {
  audios: AudioVoiceOverInterface[];
}
const PrevReviewAudios: React.FC<Props> = props => {
  const { audios: allAudios } = props;
  const [currentAudio, setCurrentAudio] = useState('');
  const [audioState, setAudioState] = useState(AudioStates.PAUSE);
  const [audioSpan, setAudioSpan] = useState(0);

  const { pauseRecording } = usePauseRecording();
  const playAudio = (e: string) => {
    if (e === currentAudio) setCurrentAudio('');
    else {
      setCurrentAudio(e);
      setAudioState(AudioStates.LOADING);
    }
    setAudioSpan(0);
  };

  return (
    <div className="voiceRecord--block mt-4 pt-1">
      {allAudios.length > 0 &&
        allAudios.map(audio => {
          const active = currentAudio === audio.url;
          return (
            <div className="voiceRecord--box" key={audio._id}>
              <a
                className="record__voice"
                onClick={() => {
                  pauseRecording();
                  playAudio(audio.url);
                }}
              >
                {active && audioState === AudioStates.LOADING && <SmallLoader />}
                {active && audioState === AudioStates.PLAY && <i className="icon-pause"></i>}
                {(!active || (active && audioState === AudioStates.PAUSE)) && (
                  <i className="icon-play"></i>
                )}
              </a>
              <span className="record__time">
                {active ? convertSecToString(audioSpan) : audio.name}
              </span>
            </div>
          );
        })}
      {currentAudio.length > 0 && (
        <ReactAudioPlayer
          src={compileAvatarUrl(currentAudio)}
          autoPlay
          controls={false}
          onCanPlayThrough={() => setAudioState(AudioStates.PLAY)}
          onEnded={() => setCurrentAudio('')}
          onListen={e => setAudioSpan(Math.round(parseFloat(e.toString())))}
          listenInterval={AUDIO_TIME_INVERVAL}
        />
      )}
    </div>
  );
};

export default PrevReviewAudios;
