import React, { useLayoutEffect } from 'react';

import { GetListingObject } from '../../../state/listing/listing.types';
import { compileAvatarUrl } from '../../../utils/avatar.utils';

interface Props {
  listing: GetListingObject;
  className?: string;
}

const ListingTourDetails: React.FC<Props> = (props: Props) => {
  const { listing, className } = props;
  const { building } = listing;

  const primaryImage = listing.media.find(media => media.isPrimary) || listing.media[0];
  const primaryImageSrc = primaryImage
    ? compileAvatarUrl(primaryImage.url)
    : '/images/Rectangle-16035.png';

  const recalculateFontSize = (parentBlockId: string, textId: string, unitId?: string) => {
    const blockEl = document.getElementById(parentBlockId);
    if (blockEl) {
      const leftPadding = parseFloat(
        window.getComputedStyle(blockEl).getPropertyValue('padding-left'),
      );
      const rightPadding = parseFloat(
        window.getComputedStyle(blockEl).getPropertyValue('padding-right'),
      );
      const textEl = document.getElementById(textId);
      if (textEl) {
        const textFontSize = parseFloat(
          window.getComputedStyle(textEl).getPropertyValue('font-size'),
        );
        const unitEl = document.getElementById(unitId ? unitId : 'none');
        if (unitEl) {
          const unitFontSize = parseFloat(
            window.getComputedStyle(unitEl).getPropertyValue('font-size'),
          );
          let textWidth = leftPadding + textEl.scrollWidth + rightPadding;
          if (window.innerWidth > 1440 && window.innerHeight > 700 && unitEl.offsetWidth) {
            textWidth += unitEl?.offsetWidth;
          }
          if (textWidth > blockEl.clientWidth) {
            textEl.style.fontSize = `${textFontSize - 1}px`;
            unitEl.style.fontSize = `${unitFontSize - 0.5}px`;
            recalculateFontSize(parentBlockId, textId, unitId);
          }
        } else if (leftPadding + textEl.scrollWidth + rightPadding > blockEl.clientWidth) {
          textEl.style.fontSize = `${textFontSize - 1}px`;
          recalculateFontSize(parentBlockId, textId);
        }
      }
    }
  };

  const recalculateDescriptionFontSize = () => {
    const capacityBlockEl = document.getElementById('capacityBlock');
    const blockEl = document.getElementById('descriptionBlock');
    if (blockEl && capacityBlockEl) {
      const textEl = document.getElementById('description');
      if (textEl) {
        const textFontSize = parseFloat(
          window.getComputedStyle(textEl).getPropertyValue('font-size'),
        );
        if (blockEl.scrollHeight > capacityBlockEl.scrollHeight) {
          textEl.style.fontSize = `${textFontSize - 1}px`;
          recalculateDescriptionFontSize();
        }
      }
    }
  };

  useLayoutEffect(() => {
    if (!className) {
      recalculateFontSize('buildingSizeBlock', 'buildingSize', 'buildingSizeUnit');
      recalculateFontSize('rentBlock', 'rent', 'rentUnit');
      recalculateFontSize('capacityBlock', 'capacity');
      recalculateDescriptionFontSize();
    }
  }, [className, listing]);

  return (
    <React.Fragment>
      <div className={className} style={{ width: '100%', height: '100%' }}>
        <div className="liveTour--video liveTour--wide">
          <img src={primaryImageSrc} alt="Img" className="img--cover" />
        </div>
        <div className="liveTour--placement liveTour--details placement--centered">
          <div className="container liveTour--container">
            <div className="row--feature">
              {building.buildingSize && building.buildingSize > 0 && (
                <div className="col--feature">
                  <div className="feature--block" id="buildingSizeBlock">
                    <span className="featureBlock__icon">
                      <i className="icon-compress-wide"></i>
                    </span>
                    <h3 className="featureBlock__heading text__gray">Size</h3>
                    <div className="featureBlock--sf">
                      <span className="sf__digit" id="buildingSize">
                        {building.buildingSize.toLocaleString()}
                      </span>
                      <span className="sf__word" id="buildingSizeUnit">
                        SF
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {listing.monthlyRent && (
                <div className="col--feature">
                  <div className="feature--block" id="rentBlock">
                    <span className="featureBlock__icon">
                      <i className="icon-month"></i>
                    </span>
                    <h3 className="featureBlock__heading text__gray">Rent</h3>
                    <div className="featureBlock--sf">
                      <span className="sf__digit" id="rent">
                        ${listing.monthlyRent.toLocaleString()}
                      </span>
                      <span className="sf__word" id="rentUnit">
                        /SF/YR
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {listing.capacity && (
                <div className="col--feature">
                  <div className="feature--block" id="capacityBlock">
                    <span className="featureBlock__icon">
                      <i className="icon-users"></i>
                    </span>
                    <h3 className="featureBlock__heading text__gray">Seats</h3>
                    <div className="featureBlock--sf">
                      <span className="sf__digit" id="capacity">
                        {listing.capacity}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {listing.metroProximity && (
                <div className="col--feature col--feature-address">
                  <div className="feature--block feature--block-address" id="descriptionBlock">
                    <span className="featureBlock__icon">
                      <i className="icon-metro"></i>
                    </span>
                    <h3 className="featureBlock__heading text__gray">Metro Proximity</h3>
                    <h4 className="featureBlock__heading text__dark" id="description">
                      {listing.metroProximity}
                    </h4>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListingTourDetails;
