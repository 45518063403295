import React, { useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { GetListingObject } from '../../../state/listing/listing.types';
import { compileAvatarUrl } from '../../../utils/avatar.utils';

interface Props {
  listing: GetListingObject;
  className?: string;
}

const ListingTourHome: React.FC<Props> = (props: Props) => {
  const { listing, className } = props;

  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );

  const location = listing?.building?.location;
  const primaryImage = listing.media.find(media => media.isPrimary) || listing.media[0];
  const primaryImageSrc = primaryImage
    ? compileAvatarUrl(primaryImage.url)
    : '/images/Rectangle-16035.png';

  // const listingInfo = useSelector(
  //   (state: AppStateInterface) => state.subdomain.listing.singleListing,
  // );
  // const [building, setBuilding] = useState({} as Building);
  // const [listing, setListing] = useState({} as GetListingObject);

  // useEffect(() => {
  //   if (listingInfo.hasData && !listingInfo.loading) {
  //     if (listingInfo.data) {
  //       setListing(listingInfo.data);
  //       if (listingInfo.data.building) setBuilding(listingInfo.data.building);
  //     } else if (listingInfo.error) {
  //     }
  //   }
  // }, [listingInfo]);

  const resizeLocationFontSize = () => {
    const containerEl = document.getElementById('container');
    const blockEl = document.getElementById('placement-block');
    const locationEl = document.getElementById('location');
    if (containerEl && blockEl && locationEl) {
      const textFontSize = parseFloat(
        window.getComputedStyle(locationEl).getPropertyValue('font-size'),
      );
      const textLineHeight = parseFloat(
        window.getComputedStyle(locationEl).getPropertyValue('line-height'),
      );
      if (
        blockEl.scrollHeight + 40 > containerEl.scrollHeight ||
        locationEl.clientWidth < locationEl.scrollWidth
      ) {
        locationEl.style.fontSize = `${textFontSize - 1}px`;
        locationEl.style.lineHeight = `${textLineHeight - 1.2}px`;
        resizeLocationFontSize();
      }
    }
  };

  useLayoutEffect(() => {
    resizeLocationFontSize();
  }, [listing]);

  return (
    <React.Fragment>
      <div className={className} style={{ width: '100%', height: '100%' }}>
        <div className="liveTour--video liveTour--wide">
          <img src={primaryImageSrc} alt="Img" className="img--cover" />
        </div>
        <div className="liveTour--placement placement--centered" id="container">
          <div className="container liveTour--container">
            <div className="placement--block" id="placement-block">
              <a className="placement__logo listing__logo">
                <img
                  src={
                    company?.settings.darkLogo
                      ? compileAvatarUrl(company.settings.darkLogo.url)
                      : '/images/HomeTour_Dark_Logo.svg'
                  }
                  alt="Logo"
                />
              </a>
              <h1 className="placement__heading" id="location">
                {location?.split(',')[0]} {listing.suiteNo}
              </h1>
              <div className="placement--location">
                <span className="placementLocation__icon">
                  <i className="icon-location"></i>
                </span>
                <span className="placementLocation__text">
                  {location
                    .split(',')
                    .slice(1, 3)
                    .join(', ')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ListingTourHome;
