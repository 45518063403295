import React from 'react';

const SubDomainFooterShimmer: React.FC = () => {
  return (
    <div className="subdomain__footer subdomain__footer-alt sdf--shimmer-alt">
      <div className="container container--large">
        <div className="row justify-content-md-between">
          <div className="col-12 col-sm-5 col-lg-5">
            <div className="sd__footer-l">
              <span className="header__logo-shimmer shimmer__style animate mb-4"></span>
              <span className="sdf__para-shimmer shimmer__style animate"></span>
            </div>
          </div>
          <div className="col-12 col-sm-7 col-lg-5">
            <div className="fl--shimmer mb-4 mb-md-5">
              <span className="fl--shimmer-span shimmer__style animate"></span>
              <span className="fl--shimmer-span shimmer__style animate"></span>
              <span className="fl--shimmer-span shimmer__style animate"></span>
            </div>
            <div className="fp__box mb-0 mb-sm-5 pb-sm-1">
              <span className="fp__box-span shimmer__style animate"></span>
              <span className="fp__box-span shimmer__style animate"></span>
              <span className="fp__box-span shimmer__style animate"></span>
              <span className="fp__box-span shimmer__style animate"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubDomainFooterShimmer;
