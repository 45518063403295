import React, { useEffect } from 'react';
import useMediaRecorder from '@wmik/use-media-recorder';
import { REVIEW_STATE as rev, RECORDING_STATE as rec } from './../../../common/constants';

interface Props {
  state: rev;
  onDone: () => void;
  onError: (err: string) => void;
  onStop: (blob: any) => void;
}
const Recorder: React.FC<Props> = props => {
  const {
    error,
    status,
    mediaBlob,
    stopRecording,
    getMediaStream,
    startRecording,
    pauseRecording,
    resumeRecording,
  } = useMediaRecorder({
    recordScreen: false,
    blobOptions: { type: 'audio/wav' },
    mediaStreamConstraints: { audio: true },
  });

  const { state, onStop, onDone, onError } = props;

  useEffect(() => onError(error?.message || ''), [error]);
  useEffect(() => {
    switch (state) {
      case rev.INITIALIZE:
        if (status === rec.IDLE) getMediaStream();
        if (status === rec.READY) onDone();
        break;
      case rev.START:
        if (status !== rec.RECORD) startRecording();
        onDone();
        break;
      case rev.STOP:
        if (status !== rec.STOP) stopRecording();
        if (rec.STOP === status && mediaBlob) {
          onStop(mediaBlob);
        }
        break;
      case rev.PAUSE:
        if (status === rec.RECORD) pauseRecording();
        onDone();
        break;
      case rev.RESUME:
        if (status === rec.RECORD) resumeRecording();
        onDone();
        break;
      default:
        break;
    }
  }, [state, status]);

  return <></>;
};

export default Recorder;

// const [recordingState, updateRecordingState] = useState(REVIEW_STATE.IDLE);
// const [recordingBlob, updateRecordingBlob] = useState();
// const handleBlob = (e: any) => {
//   updateRecordingBlob(e);
//   updateRecordingState(REVIEW_STATE.IDLE);
// };

// {REVIEW_STATE.IDLE !== recordingState && (
//   <Check state={recordingState} onDone={updateRecordingState} onStop={handleBlob} onError={() => {}} />
// )}
// <button onClick={() => updateRecordingState(REVIEW_STATE.INITIALIZE)}>get ready</button>
// <button onClick={() => updateRecordingState(REVIEW_STATE.START)}>start</button>
// <button onClick={() => updateRecordingState(REVIEW_STATE.PAUSE)}>pause</button>
// <button onClick={() => updateRecordingState(REVIEW_STATE.RESUME)}>resume</button>
// <button onClick={() => updateRecordingState(REVIEW_STATE.STOP)}>stop</button>
// {recordingState === REVIEW_STATE.IDLE && recordingBlob && (
//   <audio src={URL.createObjectURL(recordingBlob)} controls={false} autoPlay />
// )}
