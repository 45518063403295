import React from 'react';
import TourBlockShimmer from '../tour/view/loading';

const SearchLoadingShimmer: React.FC = () => {
  return (
    <div className="white--rounded-box dashbaord--search box--expand">
      <h3 className="heading__medium heading__medium-black font-semibold mb-4 pb-1">
        Search Results
      </h3>
      <div className="searchResults--block">
        <h5 className="heading__sm heading__sm-black font-semibold">Buildings & Listings</h5>
        <div className="search--listing mt-4">
          <div className="searchBs--shimmer">
            <span className="sb__shimmer-img animate"></span>
            <div className="search--bs-detail">
              <div className="search--bs-inner">
                <span className="sb__shimmer-heading"></span>
                <div className="al--listing-box al--listing-box-sh">
                  <div className="al--listing mb-0  ">
                    <span className="al__icon animate"></span>
                    <span className="al__text animate"></span>
                  </div>
                </div>
              </div>
              <span className="bs__next">
                <i className="icon-arrow-right"></i>
              </span>
            </div>
          </div>
          <div className="searchBs--shimmer searchBs--shimmer-alt">
            <div className="search--bs-detail">
              <div className="search--bs-inner">
                <span className="sb__shimmer-heading animate"></span>
                <div className="badge--off">
                  <span className="buildingBtn__shimmer animate"></span>
                </div>
              </div>
              <span className="bs__next">
                <i className="icon-arrow-right"></i>
              </span>
            </div>
          </div>
          <div className="searchBs--shimmer searchBs--shimmer-alt">
            <div className="search--bs-detail">
              <div className="search--bs-inner">
                <span className="sb__shimmer-heading animate"></span>
                <div className="badge--off">
                  <span className="buildingBtn__shimmer animate"></span>
                </div>
              </div>
              <span className="bs__next">
                <i className="icon-arrow-right"></i>
              </span>
            </div>
          </div>
          <div className="searchBs--shimmer searchBs--shimmer-alt">
            <div className="search--bs-detail">
              <div className="search--bs-inner">
                <span className="sb__shimmer-heading animate"></span>
                <div className="badge--off">
                  <span className="buildingBtn__shimmer animate"></span>
                </div>
              </div>
              <span className="bs__next">
                <i className="icon-arrow-right"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="searchResults--block">
        <h5 className="heading__sm heading__sm-black font-semibold">Tours</h5>
        <div className="mt-4">
          <TourBlockShimmer />
        </div>
      </div>
      <div className="searchResults--block">
        <h5 className="heading__sm heading__sm-black font-semibold">Photos & Renderings</h5>
        <div className="mt-4">
          <div className="photos--search block-flex block--scroll">
            <div className="block--search-row photos--search-row">
              <div className="block--search-col">
                <div className="photos--box-shimmer animate"></div>
              </div>
              <div className="block--search-col">
                <div className="photos--box-shimmer animate"></div>
              </div>
              <div className="block--search-col">
                <div className="photos--box-shimmer animate"></div>
              </div>
              <div className="block--search-col">
                <div className="photos--box-shimmer animate"></div>
              </div>
              <div className="block--search-col">
                <div className="photos--box-shimmer animate"></div>
              </div>
              <div className="block--search-col">
                <div className="photos--box-shimmer animate"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="searchResults--block">
        <h5 className="heading__sm heading__sm-black font-semibold">Videos</h5>
        <div className="mt-4">
          <div className="video--search block-flex block--scroll">
            <div className="block--search-row videos--search-row">
              <div className="block--search-col">
                <div className="video--box-shimmer animate"></div>
              </div>
              <div className="block--search-col">
                <div className="video--box-shimmer animate"></div>
              </div>
              <div className="block--search-col">
                <div className="video--box-shimmer animate"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="searchResults--block">
        <h5 className="heading__sm heading__sm-black font-semibold">3D Tours</h5>
        <div className="mt-4">
          <div className="video--search block-flex block--scroll">
            <div className="block--search-row videos--search-row">
              <div className="block--search-col">
                <div className="video--box-shimmer animate"></div>
              </div>
              <div className="block--search-col">
                <div className="video--box-shimmer animate"></div>
              </div>
              <div className="block--search-col">
                <div className="video--box-shimmer animate"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="searchResults--block">
        <h5 className="heading__sm heading__sm-black font-semibold">PDFs</h5>
        <div className="mt-4">
          <div className="pdf--search">
            <div className="row psf--search-row">
              <div className="col col-12 col-sm-6 col-md-4 col-xl-3">
                <span className="pdf__btn-shimmer">
                  <span className="pdf__btn-icon animate--gray"></span>
                  <div className="pdf__btn-off">
                    <span className="pdf__btn-text animate--gray"></span>
                    <span className="pdf__btn-text animate--gray"></span>
                  </div>
                </span>
              </div>
              <div className="col col-12 col-sm-6 col-md-4 col-xl-3">
                <span className="pdf__btn-shimmer">
                  <span className="pdf__btn-icon animate--gray"></span>
                  <div className="pdf__btn-off">
                    <span className="pdf__btn-text animate--gray"></span>
                    <span className="pdf__btn-text animate--gray"></span>
                  </div>
                </span>
              </div>
              <div className="col col-12 col-sm-6 col-md-4 col-xl-3">
                <span className="pdf__btn-shimmer">
                  <span className="pdf__btn-icon animate--gray"></span>
                  <div className="pdf__btn-off">
                    <span className="pdf__btn-text animate--gray"></span>
                    <span className="pdf__btn-text animate--gray"></span>
                  </div>
                </span>
              </div>
              <div className="col col-12 col-sm-6 col-md-4 col-xl-3">
                <span className="pdf__btn-shimmer">
                  <span className="pdf__btn-icon animate--gray"></span>
                  <div className="pdf__btn-off">
                    <span className="pdf__btn-text animate--gray"></span>
                    <span className="pdf__btn-text animate--gray"></span>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchLoadingShimmer;
