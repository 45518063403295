import React from 'react';
import { Navbar } from 'react-bootstrap';
import ForgotPasswordForm from '../../components/login/forgotPassword.view';
import { Link } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
  return (
    <div className="form--page signin--page">
      <div className="form--banner">
        <div className="formBanner--canvas">
          <Navbar.Brand className="form__brand">
            <Link to="/">
              <img src="/images/HomeTour_White_Logo.svg" alt="Logo" />
            </Link>
          </Navbar.Brand>
          <h1 className="form__heading form__heading-green">
            {"It's good to"} <span className="form__heading-white">see you back</span>
          </h1>
          <span className="formBanner--img">
            <img src="/images/signin-img.svg" alt="Img" />
          </span>
        </div>
      </div>
      <div className="form--content signin--content">
        <div className="forgotPassword--canvas container--xs">
          <h1 className="form__heading">Trouble logging in?</h1>
          <p className="form__para">Enter your email and we will send you a password reset link.</p>
          <ForgotPasswordForm />
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
