import React, { useState, useEffect } from 'react';
import { GetListingObject } from '../../../../state/listing/listing.types';

interface Props {
  info: GetListingObject;
}
const ListingDetails: React.FC<Props> = props => {
  const [listing, setListing] = useState({} as GetListingObject);
  useEffect(() => {
    setListing(props.info);
  }, [props.info]);

  return (
    <div className="key--details mb-4">
      <h6 className="keyDetails__heading">Key Details</h6>
      <div className="row">
        <div className="col-6 col-sm-4">
          <div className="key--box">
            <div className="keyBox--head">
              <i className="icon-size"></i>
              <label className="small__heading">Size</label>
            </div>
            <p className="small__paragraph">
              {listing.size ? `${listing.size.toLocaleString()} SF` : 'N/A'}
            </p>
          </div>
        </div>
        <div className="col-6 col-sm-4">
          <div className="key--box">
            <div className="keyBox--head">
              <i className="icon-month"></i>
              <label className="small__heading">Monthly Rent</label>
            </div>
            <p className="small__paragraph">
              {listing.monthlyRent ? `$${listing.monthlyRent.toLocaleString()}/SF/YR` : 'N/A'}
            </p>
          </div>
        </div>
        <div className="col-6 col-sm-4">
          <div className="key--box">
            <div className="keyBox--head">
              <i className="icon-users"></i>
              <label className="small__heading">Seats</label>
            </div>
            <p className="small__paragraph">
              {listing.capacity ? `${listing.capacity} Employees` : 'N/A'}
            </p>
          </div>
        </div>
        <div className="col-12 col-sm-12">
          <div className="key--box mb-0">
            <div className="keyBox--head">
              <i className="icon-metro"></i>
              <label className="small__heading">Metro Proximity</label>
            </div>
            <p className="small__paragraph">
              {listing.metroProximity ? listing.metroProximity : 'N/A'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ListingDetails;
