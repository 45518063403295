import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import useForm from 'react-hook-form';
import {
  BrokerInvitationFormFields,
  BrokerInvitationFormSchema,
} from '../../state/invitation/forms/broker.form';
import { UploadMediaInterface } from '../../state/media/media.types';
import { useUploadMedia } from '../../state/media/media.hook';
import { showNotification } from '../../utils/misc.utils';
import { errorExtractor } from '../../utils/error.utils';
import { useAcceptInvite } from '../../state/invitation/invitation.hook';
import { cleanObject } from '../../utils/apiValidatior';
import { AcceptInviteInterface } from '../../state/invitation/invitation.types';
import { useHistory } from 'react-router';
import SmallLoader from '../common/small.loader';

interface Props {
  firstName: string;
  lastName: string;
  email: string;
  verificationCode: string;
  memberId: string;
  role: string;
  invitingUserName: string;
}

const BrokerInvitationForm: React.FC<Props> = (props: Props) => {
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({
    validationSchema: BrokerInvitationFormSchema,
  });
  const [avatar, setAvatar] = useState({ dataView: null, data: null } as UploadMediaInterface);
  const [avatarError, setAvatarError] = useState('');
  const [formData, setFormData] = useState<any>(null);
  const { res: uploadAvatarResponse, uploadSingleMedia } = useUploadMedia();
  const { res: acceptInviteResponse, acceptInvite } = useAcceptInvite();

  useEffect(() => {
    if (!acceptInviteResponse.loading) {
      if (acceptInviteResponse.data) {
        history.push('/dashboard');
      }
      if (acceptInviteResponse.error) {
        showNotification('error', 'Error', errorExtractor(acceptInviteResponse.error));
      }
    }
  }, [acceptInviteResponse]);

  useEffect(() => {
    if (!uploadAvatarResponse.loading) {
      if (uploadAvatarResponse.data) {
        acceptInvite(
          (cleanObject({
            role: props.role,
            firstName: props.firstName,
            lastName: props.lastName,
            verificationCode: props.verificationCode,
            memberId: props.memberId,
            email: props.email,
            password: formData.password,
            avatar: uploadAvatarResponse.data?.url,
          }) as unknown) as AcceptInviteInterface,
        );
      }
      if (uploadAvatarResponse.error) {
        showNotification('error', 'Error', errorExtractor(uploadAvatarResponse.error));
      }
    }
  }, [uploadAvatarResponse]);

  const uploadAvatar = (e: any) => {
    const files = e.target.files;
    if (files && files.length) {
      const file = files[0];
      const reader = new FileReader();
      reader.onloadend = (fileView: any) => {
        setAvatar({
          name: file.name,
          data: file,
          dataView: fileView.target.result,
        });
        setAvatarError('');
      };
      reader.readAsDataURL((file as unknown) as Blob);
    }
  };

  const onSubmit = (data: any, e: any) => {
    if (!avatar?.data) {
      setAvatarError('Profile photo is required');
      return;
    }
    setFormData(data);
    uploadSingleMedia(avatar);
  };

  return (
    <div className="form--content brokerSignup--content">
      <div className="brokerSignup--canvas container--xs">
        <h1 className="form__heading mb-3">Welcome {props.firstName}</h1>
        <p className="form__para">
          {props.invitingUserName} has invited you to join his/her team. Please review the
          information, upload your photo and create a password to access your account.
        </p>
        <Form className="mt-sixty" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-12">
              <Form.Group className="forms--group">
                <Form.Control
                  name="email"
                  value={props.email}
                  disabled
                  type="email"
                  placeholder="Email"
                />
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group className="forms--group">
                <Form.Control
                  name={BrokerInvitationFormFields.PASSWORD}
                  ref={register}
                  type="password"
                  placeholder="Password"
                  className={errors[BrokerInvitationFormFields.PASSWORD] ? 'error' : ''}
                />
                {errors[BrokerInvitationFormFields.PASSWORD] && (
                  <span className="error__msg">
                    {errors[BrokerInvitationFormFields.PASSWORD]?.message}
                  </span>
                )}
              </Form.Group>
            </div>
          </div>
          <div className="text-left mb-5">
            {(!avatar?.data || !avatar?.dataView) && (
              <label className="custom__file">
                <input
                  type="file"
                  accept="image/png,image/jpeg,image/jpg"
                  onChange={uploadAvatar}
                />
                Upload Profile Photo
                <span className="custom__file-icon">
                  <i className="icon-upload"></i>
                </span>
                {avatarError && <span className="error__msg">{avatarError}</span>}
              </label>
            )}
            {avatar?.data && avatar?.dataView && (
              <div className="file--uploaded">
                <div className="uploaded--img-block d-flex align-items-center flex-wrap">
                  <div className="uploaded--img">
                    <img src={avatar.dataView} alt="Img" />
                  </div>
                </div>
                <a
                  onClick={() => {
                    setAvatar({ name: '', data: null, dataView: null });
                    setAvatarError('Profile photo is required');
                  }}
                  className="uploaded-trash"
                >
                  <i className="icon-trash"></i>
                </a>
              </div>
            )}
          </div>
          <div className="form--option formOpt--terms mb-0 pt-3">
            <label className="checkbox--set">
              I agree to{' '}
              <a className="checkbox__link" href="/terms" target="_blank" rel="noopener noreferrer">
                HomeTour terms.
              </a>
              <input type="checkbox" name={BrokerInvitationFormFields.TERMS} ref={register} />
              <span className="checkmark"></span>
              {errors[BrokerInvitationFormFields.TERMS] && (
                <span className="error__msg">
                  {errors[BrokerInvitationFormFields.TERMS]?.message}
                </span>
              )}
            </label>
            <div className="text-sm-right forms--btn-canvas brokerSignup--btn-canvas btn-top">
              <Button
                type="submit"
                className="admin__button underline__button"
                disabled={uploadAvatarResponse.loading || acceptInviteResponse.loading}
              >
                {uploadAvatarResponse.loading && 'Uploading'}
                {acceptInviteResponse.loading && 'Accessing'}
                {!acceptInviteResponse.loading &&
                  !uploadAvatarResponse.loading &&
                  'Access My Account'}
                {(uploadAvatarResponse.loading || acceptInviteResponse.loading) && <SmallLoader />}
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default BrokerInvitationForm;
