import React from 'react';
interface Props {
  matterPortLink: string;
  className?: string;
}

const ListingTourMatterport: React.FC<Props> = (props: Props) => {
  const { matterPortLink, className } = props;

  return (
    <iframe
      src={matterPortLink + '&play=1'}
      frameBorder={0}
      allowFullScreen={false}
      height="100%"
      width="100%"
      className={className}
    ></iframe>
  );
};

export default ListingTourMatterport;
