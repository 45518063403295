import { ReviewActionTypes as actionTypes } from './review.types';
import fetchActions from './../../utils/state.utils';
import { RECORDING_STATE, REVIEW_STATE } from '../../common/constants';
import { AudioVoiceOverInterface } from '../listing/listing.types';
import { convertSecToString } from '../../components/listing/listing.component';

export const recordingChange = (e: boolean) => {
  fetchActions.dispatch({
    type: actionTypes.REVIEW_UPDATE,
    value: e,
  });
};

export const changeVideoStatus = (rev: REVIEW_STATE) => {
  let isRecording = false;
  if (rev === REVIEW_STATE.START || rev === REVIEW_STATE.RESUME) isRecording = true;
  fetchActions.dispatch({
    type: actionTypes.CHANGE_VIDEO_STATUS,
    isRecording,
    reviewStatus: rev,
  });
};

export const changeAudioStatus = (rec: RECORDING_STATE) => {
  fetchActions.dispatch({
    type: actionTypes.CHANGE_AUDIO_STATUS,
    recordingState: rec,
  });
};

export const changeVideoTimeStamp = (time: number) => {
  fetchActions.dispatch({
    type: actionTypes.SET_VIDEO_TIME,
    payload: convertSecToString(time, true),
  });
};

export const saveAudios = (allAudios: AudioVoiceOverInterface[]) => {
  fetchActions.dispatch({
    type: actionTypes.SET_AUDIOS,
    payload: allAudios,
  });
};
