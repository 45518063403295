import axios, { AxiosResponse } from 'axios';
import env from '../utils/env.utils';

const api = axios.create({
  baseURL: env.apiUrl,
  withCredentials: true,
});

api.interceptors.response.use(
  (res: AxiosResponse) => {
    return res;
  },
  e => {
    if (
      e &&
      e.response &&
      e?.response?.status === 401 &&
      !window.location.pathname.startsWith('/login') &&
      !window.location.pathname.startsWith('/signUp') &&
      !window.location.pathname.startsWith('/invite') &&
      !window.location.pathname.startsWith('/tour') &&
      !window.location.pathname.startsWith('/forgot') &&
      !window.location.pathname.startsWith('/reset')
    ) {
      window.location.href = `${window.location.origin}/login?return_url=${encodeURIComponent(
        window.location.href,
      )}`;
    }
    if (
      e &&
      e.response &&
      e?.response?.status === 404 &&
      !window.location.pathname.startsWith('/404') &&
      !window.location.pathname.startsWith('/invite') &&
      !window.location.pathname.startsWith('/forgot') &&
      !window.location.pathname.startsWith('/reset') &&
      !window.location.pathname.startsWith('/tour')
    ) {
      window.location.href = `${window.location.origin}/404`;
    }
    throw e;
  },
);

export default api;
