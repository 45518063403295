import { identity as enhance } from 'lodash';
import React, { useState, useEffect } from 'react';
import { compose, withProps, withHandlers } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import env from './../../utils/env.utils';
import { compileGoogleMapsScriptSrc, silverMapDesign } from '../../utils/misc.utils';

interface Props {
  location: Location;
  marker?: Location[];
  zoom?: number;
  selected?: number;
}
interface Location {
  lat: number;
  lng: number;
}

const MyMapComponent = compose(
  withProps({
    googleMapURL: compileGoogleMapsScriptSrc(env.googleApiKey),
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%`, width: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  withHandlers(() => {
    const refs = {
      map: undefined,
    };

    return {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      onMapMounted: () => ref => {
        refs.map = ref;
      },
      onZoomChanged: ({ onZoomChange }) => () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        onZoomChange(refs.map.getZoom());
      },
    };
  }),
  withScriptjs,
  withGoogleMap,
)(props => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const { location, onMapMounted, zoomValue: zoom, onZoomChanged, marker, selected } = props;

  return (
    <GoogleMap
      onZoomChanged={onZoomChanged}
      defaultZoom={zoom}
      defaultCenter={location}
      center={location}
      zoom={zoom}
      ref={onMapMounted}
      options={{ styles: silverMapDesign }}
      defaultOptions={{ styles: silverMapDesign }}
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      // eslint-disable-next-line no-undef
      defaultAnimation={google.maps.Animation.DROP}
    >
      {marker &&
        marker.map((mark: Location, i: number) => {
          return (
            <Marker
              position={mark}
              key={i}
              icon={`/images/map-marker-${selected === i ? 'blue' : 'grey'}.svg`}
              zIndex={selected === i ? 200 : 100}
            />
          );
        })}
    </GoogleMap>
  );
});

const ReactGoogleMaps: React.FC<Props> = (props: Props) => {
  const [zoom, setZoom] = useState(15);
  useEffect(() => setZoom(props.zoom || 15), [props]);
  const onZoomChange = (e: number) => setZoom(e);
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return <MyMapComponent key="map" {...props} zoomValue={zoom} onZoomChange={onZoomChange} />;
};

export default enhance(ReactGoogleMaps);
