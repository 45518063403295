import React, { useState, useEffect } from 'react';
import LocationFilter from './location';
import AvailableFilter from './available';
import ViewByFilter from './viewBy';
import { SortAndOrderInterface } from '../../../../../state/subdomain/listing/listing.types';

interface Props {
  viewBy: ViewByInterface;
  sortAndOrder: SortAndOrderInterface;
  setSortAndOrder: (sortAndOrder: SortAndOrderInterface) => void;
  search: string;
  setSearch: (search: string) => void;
  subMarket: string;
  setSubMarket: (subMarket: string) => void;
}
interface ViewByInterface {
  mapView: boolean;
  setMapView: (e: boolean) => void;
}

const SubDomainAllLists: React.FC<Props> = (props: Props) => {
  const [viewBy, updateViewBy] = useState({} as ViewByInterface);
  useEffect(() => updateViewBy(props.viewBy), [props.viewBy]);

  return (
    <div className="row row--space-10 align-items-md-center">
      <div className="col-12 col-md-9 col-lg-9">
        <div className="row row--space-10">
          <div className="col-12 col-sm-6 col-md-6">
            <LocationFilter subMarket={props.subMarket} setSubMarket={props.setSubMarket} />
          </div>
          <div className="col-12 col-sm-6 col-md-6">
            <AvailableFilter
              sortAndOrder={props.sortAndOrder}
              setSortAndOrder={props.setSortAndOrder}
            />
          </div>
        </div>
      </div>
      <div className="col-12 col-md-3 col-lg-3">
        <div className="viewBy d-flex align-items-center justify-content-end justify-content-md-start">
          <span className="viewBy__text">View By</span>
          <ViewByFilter showMap={viewBy.mapView} setMapView={viewBy.setMapView} />
        </div>
      </div>
    </div>
  );
};

export default SubDomainAllLists;
