import React, { useEffect, useState } from 'react';
import { Accordion, Button, Card, Form, Dropdown } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import SubMarketFilter from '../common/submarket.filter';
import { debounce, startCase } from 'lodash';
import { useGetRecentListings } from '../../state/listing/listing.hook';
import {
  RecentListing,
  RecentOrderBy,
  RecentSortAndOrder,
  RecentSortBy,
} from '../../state/listing/listing.types';
import SmallLoader from '../common/small.loader';
import Loader from '../common/loader';
import { compileAvatarUrl } from '../../utils/avatar.utils';
import {
  DEFAULT_RECENT_LISTING_ORDER_BY,
  DEFAULT_RECENT_LISTING_SORT_BY,
  DEFAULT_RECENT_LISTINGS_LIMIT,
  DEFAULT_RECENT_LISTINGS_OPTION_VALUE,
  DEFAULT_TOUR_ORDER_BY,
  RECENT_LISTINGS_SORTING_OPTIONS,
  SEPARATORS,
} from '../../common/constants';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../state';
import ListingBlockShimmer from './loading';

const RecentListings: React.FC = () => {
  const history = useHistory();
  const [showListings, setShowListings] = useState(true);
  const [subMarket, setSubMarket] = useState('');
  const [search, setSearch] = useState('');
  const [sortAndOrder, setSortAndOrder] = useState<RecentSortAndOrder>({
    orderBy: DEFAULT_TOUR_ORDER_BY,
    sortBy: DEFAULT_RECENT_LISTING_SORT_BY,
  });
  const [firstFetch, setFirstFetch] = useState(true);
  const { res: recentListingsResponse, getRecentListings } = useGetRecentListings();
  const { loading, error, data: listings, hasData } = recentListingsResponse;
  const [activeKey, setActiveKey] = useState<string>('0');
  const meLoading = useSelector((state: AppStateInterface) => state.me.me.loading);

  useEffect(() => {
    setFirstFetch(true);
    getRecentListings({
      subMarket,
      search,
      limit: DEFAULT_RECENT_LISTINGS_LIMIT,
      skip: 0,
      ...sortAndOrder,
    });
  }, [search, subMarket, sortAndOrder]);

  useEffect(() => {
    const container = document.getElementById('DashboardParentScrollContainer');
    if (container) {
      container.onscroll = debounce(() => {
        if (error || loading || (listings && !listings?.hasMore)) return <></>;

        if (
          Math.abs(
            Math.floor(container.scrollHeight - container.scrollTop) - container.clientHeight,
          ) < Math.floor(container.clientHeight * 0.2) &&
          (search ||
            subMarket ||
            sortAndOrder?.orderBy !== DEFAULT_RECENT_LISTING_ORDER_BY ||
            sortAndOrder?.sortBy !== DEFAULT_RECENT_LISTING_SORT_BY) &&
          !loading
        ) {
          setFirstFetch(false);
          getRecentListings(
            {
              subMarket,
              search,
              limit: 10,
              skip: listings?.skip || 0,
              ...sortAndOrder,
            },
            listings?.data,
          );
        }
      }, 100);
    }
  });

  const onSearchChange = debounce((value: any) => {
    setSearch(value);
  }, 300);

  const onDropdownSelect = (selectedValue: string) => {
    const value = selectedValue.split(SEPARATORS.SLASH);
    if (value.length === 2) {
      setSortAndOrder({
        sortBy: value[0] as RecentSortBy,
        orderBy: value[1] as RecentOrderBy,
      });
    }
  };

  const findSelectedOption = () => {
    return (
      RECENT_LISTINGS_SORTING_OPTIONS?.find(
        option => option.eventKey === `${sortAndOrder?.sortBy}/${sortAndOrder?.orderBy}`,
      )?.value || DEFAULT_RECENT_LISTINGS_OPTION_VALUE
    );
  };

  return (
    <Accordion activeKey={activeKey} className="dashboard--accordian mb-20">
      <Card className="dashboard--card">
        <Card.Header>
          <Accordion.Toggle
            id="accordian-toggle"
            as={Button}
            variant="link"
            eventKey="0"
            onClick={() => {
              setActiveKey(prevState => (prevState === '0' ? 'none' : '0'));
              setShowListings(prevState => !prevState);
            }}
          >
            Recently Created Listings
            <i className={showListings ? 'icon-chevron-down-solid' : 'icon-chevron-right'}></i>
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="mt-3">
            {(loading ||
              !listings ||
              listings?.data?.length > 0 ||
              search ||
              subMarket ||
              sortAndOrder?.sortBy !== DEFAULT_RECENT_LISTING_SORT_BY ||
              sortAndOrder?.orderBy !== DEFAULT_RECENT_LISTING_ORDER_BY) && (
              <React.Fragment>
                {!meLoading ? (
                  <div className="row row--space-10 mb-4 pb-2">
                    <div className="col col-12 col-md-4 col-xl-5">
                      <Form.Group className="form--group-alt search--group-alt mb-0 w-100">
                        <Form.Control
                          type="search"
                          placeholder="Search Recently Created Listings"
                          onChange={(e: any) => onSearchChange(e.target.value)}
                        />
                        <span className="search__icon">
                          <i className="icon-search"></i>
                        </span>
                      </Form.Group>
                    </div>
                    <div className="col col-12 col-md-8 col-xl-7">
                      <div className="row row--space-10">
                        <div className="col col-12 col-sm-6">
                          <SubMarketFilter subMarket={subMarket} setSubMarket={setSubMarket} />
                        </div>
                        <div className="col col-12 col-sm-6">
                          <Dropdown className="listing--dropdown listing--dropdown-update listing--dropdown-pad dropdown--radius mb-3 mb-md-0">
                            <Dropdown.Toggle id="dropdown-basic">
                              {findSelectedOption()}
                              <span className="select__caret-down">
                                <i className="icon-caret-down"></i>
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {RECENT_LISTINGS_SORTING_OPTIONS?.map((option, index) => (
                                <Dropdown.Item
                                  active={
                                    `${sortAndOrder.sortBy}/${sortAndOrder.orderBy}` ===
                                    option.eventKey
                                  }
                                  key={index}
                                  eventKey={option.eventKey}
                                  onSelect={onDropdownSelect}
                                >
                                  {option.value}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row row--space-10 mb-4 pb-2">
                    <div className="col col-12 col-md-4 col-xl-5">
                      <div className="search--shimmer">
                        <span className="searchShimmer__icon animate--gray"></span>
                        <span className="searchShimmer__text animate--gray"></span>
                      </div>
                    </div>
                    <div className="col col-12 col-md-8 col-xl-7">
                      <div className="row row--space-10">
                        <div className="col col-12 col-sm-6">
                          <div className="dropdown--shimmer mt-3 mt-md-0 w-100">
                            <span className="dropdownShimmer__text animate"></span>
                            <span className="dropdownShimmer__arrow">
                              <i className="icon-caret-down"></i>
                            </span>
                          </div>
                        </div>
                        <div className="col col-12 col-sm-6">
                          <div className="dropdown--shimmer mt-3 mt-md-0 w-100">
                            <span className="dropdownShimmer__text animate"></span>
                            <span className="dropdownShimmer__arrow">
                              <i className="icon-caret-down"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
            {(loading || !listings || listings?.data?.length > 0) && (
              <React.Fragment>
                <div className="upcomingTour--canvas">
                  {firstFetch && loading && (
                    <React.Fragment>
                      <ListingBlockShimmer />
                      <ListingBlockShimmer />
                      <ListingBlockShimmer />
                      <ListingBlockShimmer />
                    </React.Fragment>
                  )}
                  {(!loading || !firstFetch) &&
                    listings?.data?.map((listing: RecentListing) => (
                      <div
                        className="al--block al--block-md al--block-alt"
                        onClick={() => history.push(`/listing/${listing._id}`)}
                        key={listing._id}
                      >
                        <div className="al--block-img">
                          <img
                            src={compileAvatarUrl(
                              listing?.media?.find(media => media.isPrimary)?.url ||
                                listing?.media[0]?.url,
                            )}
                            alt="Img"
                          />
                        </div>
                        <div className="al--block-detail">
                          <div className="ald--inner al--adress-component">
                            <label className="al__address">
                              {listing?.building?.location?.split(SEPARATORS.COMMA)[0]}{' '}
                              {isNaN(parseInt(listing?.suiteNo))
                                ? listing.suiteNo
                                : `Suite ${listing.suiteNo}`}
                            </label>
                            <div className="al--listing-box">
                              <div className="al--listing">
                                <span className="al__icon">
                                  <i className="icon-map-marker-light"></i>
                                </span>
                                <span className="al__text">{listing?.subMarket?.name}</span>
                              </div>
                            </div>
                          </div>
                          <div className="ald--inner al--listings-component">
                            <div className="al--listing-box">
                              <div className="al--listing">
                                <span className="al__icon">
                                  <i className="icon-size"></i>
                                </span>
                                <span className="al__text">Size</span>
                                <span className="al__text alText__detail">
                                  {listing?.size ? listing?.size?.toString() + ' SF' : 'N/A'}
                                </span>
                              </div>
                              <div className="al--listing">
                                <span className="al__icon">
                                  <i className="icon-month"></i>
                                </span>
                                <span className="al__text">Rent</span>
                                <span className="al__text alText__detail">
                                  {listing?.monthlyRent
                                    ? '$' + listing?.monthlyRent?.toString() + '/SF/YR'
                                    : 'N/A'}
                                </span>
                              </div>
                              <div className="al--listing">
                                <span className="al__icon">
                                  <i className="icon-users"></i>
                                </span>
                                <span className="al__text">Seats</span>
                                <span className="al__text alText__detail">
                                  {listing?.capacity ? listing?.capacity + ' Employees' : 'N/A'}
                                </span>
                              </div>
                            </div>
                          </div>
                          {listing?.status && (
                            <div className="ald--inner al--btn-component">
                              <a className={`building__btn building__btn-${listing.status}`}>
                                {startCase(listing.status)}
                              </a>
                            </div>
                          )}
                          <span className="buildings__next">
                            <i className="icon-arrow-right"></i>
                          </span>
                        </div>
                      </div>
                    ))}
                  {!firstFetch && loading && (
                    <React.Fragment>
                      <ListingBlockShimmer />
                      <ListingBlockShimmer />
                    </React.Fragment>
                  )}
                  {!loading && listings?.hasMore && (
                    <div className="pt-2 pb-3">
                      <Link className="link__blue" to="/buildings">
                        Go To All Buildings
                      </Link>
                    </div>
                  )}
                </div>
              </React.Fragment>
            )}
            {!loading && listings?.data?.length === 0 && (
              <React.Fragment>
                {search ||
                subMarket ||
                sortAndOrder?.sortBy !== DEFAULT_RECENT_LISTING_SORT_BY ||
                sortAndOrder?.orderBy !== DEFAULT_RECENT_LISTING_ORDER_BY ? (
                  <React.Fragment>
                    <div className="dashboard--notfound notfound--listing pt-sm-4">
                      <span className="notfound__listing-img"></span>
                      <p className="para__lg para__lg-dark">
                        Sorry, there are no listings that match your search
                      </p>
                      <p className="para__md-gray mb-0">Please try again.</p>
                    </div>
                  </React.Fragment>
                ) : hasData ? (
                  <React.Fragment>
                    <div className="dashboard--newAccount">
                      <div className="container--xs">
                        <p className="para__md-gray mb-3 pb-1">
                          There are currently no upcoming tours in your account. You will see more
                          information here once you receive requests, or when you create a tour.
                        </p>
                        <Link className="admin__button button__radius" to="/building/create">
                          Add a Listing
                          <i className="icon-floorplan-alt ml-2"></i>
                        </Link>
                      </div>
                    </div>
                  </React.Fragment>
                ) : (
                  <></>
                )}
              </React.Fragment>
            )}
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default RecentListings;
