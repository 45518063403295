import React from 'react';
import NumberFormat from 'react-number-format';
import { SEPARATORS } from './../../../common/constants';

export const commaSeparated = (e: number) => {
  return <NumberFormat value={e} displayType="text" thousandSeparator />;
};

export const mergeBuildingHours = (
  hours: string[],
  join?: boolean,
  separator?: string,
): string | string[] => {
  const output = [] as string[];
  for (const hour of hours) {
    const parts = hour.split(SEPARATORS.SPACE);
    const dayParts = parts[0].split(SEPARATORS.DASH);
    const timeParts = parts[1].split(SEPARATORS.DASH);
    if (dayParts.length > 1 && dayParts[0] === dayParts[1]) {
      if (timeParts.length > 1 && (timeParts[0] === 'Closed' || timeParts[1] === 'Closed')) {
        output.push(dayParts[1] + SEPARATORS.SPACE + 'Closed');
      } else {
        output.push(dayParts[1] + SEPARATORS.SPACE + parts[1]);
      }
    } else {
      if (timeParts.length > 1 && (timeParts[0] === 'Closed' || timeParts[1] === 'Closed')) {
        output.push(parts[0] + SEPARATORS.SPACE + 'Closed');
      } else {
        output.push(hour);
      }
    }
  }
  return join ? output.join(separator || SEPARATORS.SEMI_COLON + SEPARATORS.SPACE) : output;
};

export const mergeWithAnd = (e: string[]): string => {
  if (e.length <= 1) return e.join('');
  const lastOne = e.pop();
  return e.join(SEPARATORS.COMMA + SEPARATORS.SPACE) + ' and ' + lastOne;
};

export const errorMsg = (error: any, errorMessage?: string, className?: string) => {
  if (errorMessage) return <p className={`error__msg ${className || ``}`}>{errorMessage}</p>;
  return error ? <p className="error__msg">{error.message}</p> : null;
};
