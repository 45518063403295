import React from 'react';
import copy from 'copy-to-clipboard';
import { showNotification } from '../../../utils/misc.utils';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { compileAvatarUrl } from '../../../utils/avatar.utils';

const IOSChromeUnsupportedAlert: React.FC = () => {
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );

  const copyLink = () => {
    copy(window.location.href);
    showNotification('success', 'Link copied to the clipboard', '');
  };
  return (
    <div className="sec--vertical-permanent sec--vertical sec--vertical-md">
      <div className="container container--large">
        <a
          href={company?.settings?.officialWebsite || '#'}
          target={company?.settings?.officialWebsite ? '_blank' : '_self'}
          rel="noopener noreferrer"
          className="listing__logo"
        >
          <img
            src={
              company?.settings?.darkLogo
                ? compileAvatarUrl(company.settings.darkLogo.url)
                : '/images/HomeTour_Dark_Logo.svg'
            }
            alt=""
          />
        </a>
        <div className="linkEx--content linkEx--content-broker sec--vertical-middle">
          <div className="md--canvas">
            <div className="linkEx--detail text-center">
              <span className="linkEx__img">text</span>
              <h3 className="heading__medium heading__medium-black font-weight-normal">
                Sorry, this feature is not supported in Google Chrome for iOS devices
              </h3>
              <p className="em__para text__gray">
                <a className="av__link text-nowrap" onClick={copyLink}>
                  Copy This Link
                </a>{' '}
                and open it in Safari
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IOSChromeUnsupportedAlert;
