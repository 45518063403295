import * as yup from 'yup';

export enum AdminInvitationFormFields {
  PASSWORD = 'password',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  TERMS = 'terms',
}

export const AdminInvitationFormSchema = yup.object().shape({
  [AdminInvitationFormFields.PASSWORD]: yup
    .string()
    .min(8, 'Password must be of at least 8 characters')
    .required('This field is required'),
  [AdminInvitationFormFields.FIRST_NAME]: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
    .required('This field is required'),
  [AdminInvitationFormFields.LAST_NAME]: yup
    .string()
    .trim()
    .matches(/^[a-zA-Z ]*$/, 'Only alphabets & spaces are allowed')
    .required('This field is required'),
  [AdminInvitationFormFields.TERMS]: yup
    .bool()
    .test(AdminInvitationFormFields.TERMS, 'Please accept our terms & conditions', value => !!value)
    .required('Please accept our terms & conditions'),
});
