import { useCallback, useEffect, useState } from 'react';
import { GetState, GetStateInterface } from '../../types/GetStateInterface';
import { SubMarketListResponseInterface } from './submarket.types';
import { getSubMarketsListAPI } from './submarket.api';
import { isEqual } from 'lodash';

export const useGetSubMarketsList = (
  initialState: GetStateInterface<Array<SubMarketListResponseInterface>> = GetState,
) => {
  const [res, setRes] = useState<GetStateInterface<Array<SubMarketListResponseInterface>>>(
    initialState,
  );

  useEffect(() => {
    if (!isEqual(GetState, initialState)) {
      setRes(initialState);
    }
  }, [initialState]);

  const getSubMarketsList = useCallback(async (companyId: string) => {
    setRes(prevState => ({ ...prevState, loading: true }));
    try {
      const res = await getSubMarketsListAPI(companyId);
      setRes({ data: res.data, hasData: true, loading: false, error: null });
    } catch (err) {
      setRes({ data: null, error: err, hasData: true, loading: false });
    }
  }, []);

  return { res, getSubMarketsList };
};
