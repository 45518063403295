import React from 'react';
import { useRedirectToMainApp } from '../../../state/subdomain/landing/landing.hook';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import { compileAvatarUrl } from '../../../utils/avatar.utils';

interface Props {
  listingId?: string;
}

const BrokerTourExpired: React.FC<Props> = (props: Props) => {
  const { listingId } = props;
  const company = useSelector(
    (state: AppStateInterface) => state.subdomain.company.companyInfo.data,
  );
  const { redirectTo } = useRedirectToMainApp();

  const handleLogoClick = () => {
    redirectTo('/dashboard');
  };

  const handleGoBack = () => {
    redirectTo(listingId ? `/listing/${listingId}` : '/dashboard');
  };

  return (
    <div className="link--expired sec--vertical sec--vertical-md">
      <div className="container container--large">
        <a className="listing__logo" onClick={handleLogoClick}>
          <img
            src={
              company?.settings?.darkLogo
                ? compileAvatarUrl(company.settings.darkLogo.url)
                : '/images/HomeTour_Dark_Logo.svg'
            }
            alt="Logo"
          />
        </a>
        <div className="linkEx--content linkEx--content-broker sec--vertical-middle">
          <div className="md--canvas">
            <div className="linkEx--detail text-center">
              <span className="linkEx__img">text</span>
              <h3 className="heading__medium heading__medium-black font-weight-normal">
                Sorry, this URL is no longer available.
              </h3>
              <p className="em__para text__gray">
                <a className="av__link text-nowrap" onClick={handleGoBack}>
                  Go back
                </a>{' '}
                to the listing and get a new link
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrokerTourExpired;
