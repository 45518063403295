import React, { useState, useEffect } from 'react';
import { Building } from '../../../../state/listing/listing.types';
import GoogleMaps from './../../../location';
import { isSchema } from 'yup';

interface Props {
  info: Building;
  onFakeFullscreen?: () => void;
  isMapFullscreen?: boolean;
  showFakeFullscreen?: boolean;
}
interface Location {
  lat: number;
  lng: number;
}

const BuildingLocation: React.FC<Props> = props => {
  const { onFakeFullscreen, showFakeFullscreen, isMapFullscreen } = props;
  const [location, setLocation] = useState({} as Location);

  const mapContainerClasses = 'listing--map listing-sm-map';
  const mapContainerStyle: any = isMapFullscreen
    ? {
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        zIndex: 80,
      }
    : {};

  const fakeFullscreenStyle = {
    height: '45px',
    width: '45px',
    top: '10px',
    zIndex: isMapFullscreen ? 1020 : 50,
  };

  useEffect(() => {
    const e = props.info;
    if (e.latitude && e.longitude)
      setLocation({ lat: parseFloat(e.latitude), lng: parseFloat(e.longitude) });
  }, [props.info]);

  return (
    <React.Fragment>
      <h3 className="heading__medium heading__medium-black mb-4">Location</h3>
      <div className={mapContainerClasses} style={mapContainerStyle}>
        <GoogleMaps
          location={{
            lat: location.lat,
            lng: location.lng,
          }}
          marker={[
            {
              lat: location.lat,
              lng: location.lng,
            },
          ]}
          selected={0}
        />
        {showFakeFullscreen && (
          <a
            className="video__expand harold"
            onClick={onFakeFullscreen}
            style={fakeFullscreenStyle}
          >
            <i className="icon-compress-wide"></i>
          </a>
        )}
      </div>
    </React.Fragment>
  );
};

BuildingLocation.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onFakeFullscreen: () => {},
  isMapFullscreen: false,
  showFakeFullscreen: false,
};

export default BuildingLocation;
