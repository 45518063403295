import api from '../../utils/api.utils';
import { PostBlobInterface } from './review.types';
import { AxiosPromise, CancelToken } from 'axios';
import { AudioVoiceOverInterface, CommentsInterface } from '../listing/listing.types';

export const postBlobAPI = (body: PostBlobInterface): AxiosPromise<AudioVoiceOverInterface[]> => {
  return api.post('/voice-over/audio', body);
};

export const deleteBlobAPI = (listingId: string, blobId: string): AxiosPromise<boolean> => {
  return api.delete(`/voice-over/audio/${listingId}/${blobId}`);
};

export const addCommentAPI = (data: CommentsInterface, listingId: string, ct?: CancelToken) => {
  return api.post(
    '/voice-over/comment',
    { listingId, ...data },
    {
      cancelToken: ct,
    },
  );
};

export const editCommentAPI = (
  listingId: string,
  commentId: string,
  data: CommentsInterface,
  ct?: CancelToken,
) => {
  return api.patch(`/voice-over/comment/${listingId}/${commentId}`, data, {
    cancelToken: ct,
  });
};

export const deleteCommentAPI = (listingId: string, commentId: string, ct?: CancelToken) => {
  return api.delete(`/voice-over/comment/${listingId}/${commentId}`, {
    cancelToken: ct,
  });
};
