import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { AppStateInterface } from '../../../state';
import Loader from '../../common/loader';
import { compileAvatarUrl } from '../../../utils/avatar.utils';
import { showNotification } from '../../../utils/misc.utils';
import { errorExtractor } from '../../../utils/error.utils';
import ReviewBasic from './basicInfo';
import { splitDurations } from '../listing.component';
import ReviewVideo from './video';
import ReviewHandlers from './buttons';
import ReviewSegmentInfo from './segmentInfo';
import ReviewAllAudios from './allAudios';
import ReviewAudioHandler from './audioHandler';
import { saveAudios } from '../../../state/review/review.action';
import Comments from './comments';
import { useParams, useHistory } from 'react-router';
import { useChangeVideoStatus } from '../../../state/listing/listing.hook';
import { VIDEO_STATUS, LISTING_STATUS, USER_ROLES } from '../../../common/constants';
import ReviewSegmentNo from './segmentNo';
import { Link } from 'react-router-dom';
import PrevReviewSubmission from './prevSubmission';
import { CommentsInterface } from '../../../state/listing/listing.types';

function ReviewVideoComponent() {
  const history = useHistory();
  const { listingId } = useParams();

  const { res: submitRes, changeVideoStatus } = useChangeVideoStatus();

  const listingData = useSelector((state: AppStateInterface) => state.listing.singleListing);
  const reviewData = useSelector((state: AppStateInterface) => state.review);
  const me = useSelector((state: AppStateInterface) => state.me.me.data);

  const [segmentDurations, setSegmentDurations] = useState([] as number[]);
  const [loadingState, setLoadingState] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [comments, setComments] = useState<CommentsInterface[]>([]);
  const [canEdit, setCanEdit] = useState(false);

  const setDurations = (e: number) => {
    const durationArray = splitDurations(e);
    setSegmentDurations(durationArray);
  };
  const submitVoiceOver = () => {
    changeVideoStatus(listingId, VIDEO_STATUS.VOICEOVER_SUBMITTED);
  };

  useEffect(() => {
    if (!submitRes.loading && submitRes.hasData) {
      if (submitRes.error)
        showNotification('error', 'Submission Error', errorExtractor(submitRes.error));
      else if (submitRes.data) {
        showNotification('success', 'Successful', 'Review submitted successfully');
        window.location.href = `${window.location.origin}/listing/${listingId}`;
      }
    }
  }, [submitRes]);
  useEffect(() => {
    if (listingData.error) {
      showNotification('error', 'Fetching error', errorExtractor(listingData.error));
      history.push('/buildings');
    } else if (listingData.data) {
      const data = listingData.data;
      if (
        data.status === LISTING_STATUS.DRAFT ||
        (me?.role === USER_ROLES.BROKER && !data?.users?.find(user => user._id === me?._id)) ||
        !data.videoUrl ||
        !data.videoUrl.length
      ) {
        history.push(`/listing/${listingId}`);
        showNotification('error', 'Permission denied', 'You cannot review this listing');
      }
      if (data.audioVoiceOver) saveAudios(data.audioVoiceOver);
      if (data.videoStatus !== VIDEO_STATUS.VOICEOVER_SUBMITTED) setCanEdit(true);
    }
  }, [listingData]);

  const submitEnabled =
    comments?.find(comment => !comment?.isPrevious) ||
    reviewData?.audios?.find(audio => !audio?.isPrevious)
      ? true
      : false;
  return (
    <div className="container container--large">
      {(submitRes.loading || listingData.loading) && <Loader />}
      <Link className="listing__logo d-inline-block" to="/dashboard">
        <img
          src={
            me?.company?.settings?.darkLogo
              ? compileAvatarUrl(me?.company?.settings?.darkLogo.url)
              : '/images/HomeTour_Dark_Logo.svg'
          }
          alt="Logo"
        />
      </Link>
      <div className="listing--content">
        <ReviewBasic
          loadingState={loadingState}
          onSubmit={submitVoiceOver}
          submitEnabled={submitEnabled}
        />
        <div className="row row--space-10 mb-4 mb-lg-0">
          <ReviewVideo setDurations={setDurations} setCurrentTime={setCurrentTime} />
          <div className="col-12 col-lg-6">
            <ReviewSegmentNo />
            <ReviewAllAudios canEdit={canEdit} />
            <ReviewAudioHandler setLoading={setLoadingState} />
            {listingData.data && canEdit && (
              <ReviewHandlers
                loadingState={loadingState}
                onSubmit={submitVoiceOver}
                submitEnabled={submitEnabled}
              />
            )}
          </div>
        </div>
        <div className="row mb-4 row--space-10">
          <div className="col-12 col-lg-6">
            <ReviewSegmentInfo duration={segmentDurations[reviewData.currentSegment]} />
            <PrevReviewSubmission
              prevAudios={reviewData?.audios?.filter(audio => audio?.isPrevious) || []}
              prevComments={
                comments?.filter((comment: CommentsInterface) => comment?.isPrevious) || []
              }
            />
          </div>
          <Comments
            currentTime={currentTime}
            comments={comments?.filter((comment: CommentsInterface) => !comment?.isPrevious) || []}
            setComments={setComments}
            canEdit={canEdit}
          />
        </div>
      </div>
    </div>
  );
}

export default ReviewVideoComponent;
