import React from 'react';
import LeftSidebar from '../../../components/leftSidebar';
import { useParams } from 'react-router';
import ViewTours from '../../../components/tour/view';

const ViewToursPage: React.FC = () => {
  const { type } = useParams();

  return (
    <div className="dashboard--page buildings bg--gray">
      <LeftSidebar active={type === 'history' ? 'toursHistory' : 'activeTours'} />
      <ViewTours />
    </div>
  );
};

export default ViewToursPage;
